import {
  CANCELLED,
  CANCELLED_IN_DEVELOPMENT,
  CANCELLED_IN_PROPOSED,
  CANCELLED_IN_READY_TO_BUY,
} from 'src/constants/productStatus'

import { getStatusIgnoringPendingOdbms } from 'src/service/product/status'
import { ProductStatus } from 'src/types/ProductStatus'

import { getAttributeValues, getSupplierByCode } from 'src/selectors/referenceData/attributes'
import {
  updateListOfDeprecatedProductFields,
  updateListOfInvalidProductFields,
} from 'src/actions/grid/product/update'
import { getHierarchyLookupTable } from 'src/selectors/hierarchy'
import { validateDeprecatedFields, validateRequiredFields } from 'src/service/product'
import { call, put, select } from 'redux-saga/effects'
import { AttributeValue, AttributeValueMap, GridType, Product, RenderSpec } from 'src/types/index'
import { Rules } from 'src/types/DepartmentSpec'
import { LookupHierarchyState } from 'reducers/referenceData/hierarchy/lookupTable'

const CANCELLED_STATUSES = [
  CANCELLED,
  CANCELLED_IN_DEVELOPMENT,
  CANCELLED_IN_PROPOSED,
  CANCELLED_IN_READY_TO_BUY,
]

type ValidationOptions = {
  acceptDeprecatedValues?: boolean
}

export function* runProductValidationsSaga(
  product: Product,
  rules: Rules,
  columnSpecs: Array<RenderSpec>,
  destinationStatus: ProductStatus,
  grid: GridType,
  options: ValidationOptions = {},
) {
  if (CANCELLED_STATUSES.includes(destinationStatus)) {
    return []
  }

  const rulesStatus = getStatusIgnoringPendingOdbms(destinationStatus)
  const mandatoryFields = rules[rulesStatus].mandatory

  const hierarchyLookUpTable: LookupHierarchyState = yield select(getHierarchyLookupTable)
  const hierarchyLevel = hierarchyLookUpTable[product.hierarchySlug].levelName

  const mandatoryColumns = columnSpecs
    .filter(({ property }) => mandatoryFields.includes(property))
    .map(({ property, type }) => ({ property, type }))

  const missingFields: Array<string> = yield call(
    validateRequiredFields,
    product,
    mandatoryColumns,
    hierarchyLevel,
  )

  const supplier: AttributeValue = yield select(
    getSupplierByCode,
    product.supplier ? product.supplier.code : undefined,
  )
  if (!supplier || !supplier.active) {
    missingFields.push('supplier')
  }

  if (missingFields.length > 0) {
    yield put(
      updateListOfInvalidProductFields(
        {
          productSlug: product.slug,
          invalidFields: missingFields,
        },
        grid,
      ),
    )
  }

  if (!options.acceptDeprecatedValues) {
    const attributeValues: AttributeValueMap = yield select(getAttributeValues)
    const deprecatedFields: string[] = yield call(
      validateDeprecatedFields,
      product,
      attributeValues,
    )
    if (deprecatedFields.length > 0) {
      yield put(
        updateListOfDeprecatedProductFields(
          {
            productSlug: product.slug,
            deprecatedFields,
          },
          grid,
        ),
      )
      return missingFields.concat(deprecatedFields)
    }
  }

  return missingFields
}
