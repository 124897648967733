import React from 'react'
import ToggleHierarchy from '../ToggleHierarchy'

import { noHierarchySelected } from './InvalidHierarchySelected.scss'
import { GridType } from 'src/types/index'

type Props = {
  gridName: GridType
  message?: string
}

export default function InvalidHierachySelected(props: Props) {
  return (
    <div className={noHierarchySelected} data-e2e="invalid-hierarchy-selected">
      <p>{props.message || 'Select a hierarchy, use'}</p>

      <ToggleHierarchy gridName={props.gridName} />
    </div>
  )
}
