import { Config } from '../../helpers/config'

function baseUrl(location: Location) {
  let baseUrl = Config().Get('apiBaseUrl')

  if (!baseUrl) {
    baseUrl = `${location.protocol}//${location.hostname}`
  }

  return baseUrl
}

export const getBaseInjectorApiUrl = (location: Location = window.location) => {
  return `${baseUrl(location)}/injector-api/v1`
}

export const getBaseInjectorApiV2Url = (location: Location = window.location) => {
  return `${baseUrl(location)}/injector-api/v2`
}

export const getBaseUrl = (location: Location = window.location) => {
  return `${baseUrl(location)}`
}

export const getSketchCookieRefreshUrl = () => {
  let sketchCookieRefreshUrl = Config().Get('sketchCookieRefreshUrl')

  if (!sketchCookieRefreshUrl) {
    sketchCookieRefreshUrl = `${location.protocol}//${location.hostname}/sketches/cookie`
  }

  return sketchCookieRefreshUrl
}

export const getWholesaleThumbnailsCookieRefreshUrl = () => {
  let wholesaleThumbnailsCookieRefreshUrl = Config().Get('wholesaleThumbnailsCookieRefreshUrl')

  if (!wholesaleThumbnailsCookieRefreshUrl) {
    wholesaleThumbnailsCookieRefreshUrl = `${location.protocol}//${location.hostname}/wholesale-thumbnails/cookie`
  }

  return wholesaleThumbnailsCookieRefreshUrl
}

export const getCookieDomain = (location: Location = window.location) => {
  const envUrlRegexp = /\.(dev|staging|training|prod)\.product\.ri-tech\.io/
  const subdomainMatch = envUrlRegexp.exec(location.hostname)

  return subdomainMatch ? subdomainMatch[0] : location.hostname
}
