import XLSX from 'xlsx'
import moment from 'moment'

export const exportErrors = (errors: Array<Error>) => {
  const excelFile = XLSX.utils.book_new()

  const sheet = XLSX.utils.json_to_sheet(errors)
  XLSX.utils.book_append_sheet(excelFile, sheet, 'Option Library Errors')
  XLSX.writeFile(excelFile, `optionlibraryerrors-${moment().format('DD-MMM-YY-HH.mm')}.xlsx`)
}
