import { ADMIN_PATH } from 'constants/sideList'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { SideListTypes, SideListItem, SideListPaths } from 'src/types/Lists'
import { container, link, active } from './SideList.scss'

interface Props {
  list: SideListItem[]
  rootPath: SideListPaths
  type?: SideListTypes
}
export const SideList = ({ list, type, rootPath }: Props) => (
  <div data-cy="side-list" className={container}>
    <ul>
      {list.map(({ key, displayName }, index) => (
        <li key={index}>
          <NavLink
            activeClassName={active}
            className={link}
            to={rootPath === ADMIN_PATH ? `/admin/${type}/${key}` : `/settings/${key}`}
            key={index}
          >
            {displayName}
          </NavLink>
        </li>
      ))}
    </ul>
  </div>
)
