// @ts-nocheck as the attribute value vs dropdown options differences

import { connect } from 'react-redux'
import DropdownCellRenderer from '../DropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { ProductSupplier } from '../../../../types/Product'
import { getAttributeValues } from '../../../../selectors/referenceData/attributes'
import { AttributeValue } from 'src/types/index'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState) => ({
  // TODO https://trello.com/c/tE0jMRzf [supplier reference data should not be in attribute values state]
  dropdownItems: getAttributeValues(state).supplier.map(({ active, ...supplier }) => ({
    ...supplier,
    deprecated: !active,
  })),
  createPatchItem: (item: AttributeValue) => ({
    apiValue: item.slug,
    storeValue: {
      description: item.value,
      code: item.code,
    },
  }),
})

const formatter = (val: string, code: string | undefined) => (val && code ? `${val} - ${code}` : '')

const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => ({
  ...stateProps,
  ...ownProps,
  createOption: (item: AttributeValue) => ({
    value: item.slug,
    label: formatter(item.value, item.code),
    data: item,
  }),
  displayFormatter: (supplier: ProductSupplier) =>
    supplier ? formatter(supplier.description, supplier.code) : '',
})

export const SupplierDropdownCellRendererContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(DropdownCellRenderer)
