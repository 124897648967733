import { FETCH_REFERENCE_DATA_SUCCESS } from 'src/actions/referenceData/referenceData'
import { Attributes, RequestStatus } from 'src/types/index'
import {
  CREATE_ATTRIBUTE,
  CREATE_ATTRIBUTE_FAILURE,
  CREATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_FAILURE,
  UPDATE_ATTRIBUTE_SUCCESS,
} from 'src/actions/referenceData/attributes'
import { SVPAction } from 'actions/svp'

export type AttributesState = Attributes & {
  savingStatus: RequestStatus
}

const defaultState: AttributesState = {
  list: {},
  values: {},
  discreteValues: {},
  savingStatus: 'uninitialised',
}

export const attributes = (state: AttributesState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return {
        ...action.attributes,
      }
    case CREATE_ATTRIBUTE:
    case UPDATE_ATTRIBUTE:
      return { ...state, savingStatus: 'posting' }
    case CREATE_ATTRIBUTE_SUCCESS:
    case UPDATE_ATTRIBUTE_SUCCESS:
      return { ...state, savingStatus: 'ready' }
    case CREATE_ATTRIBUTE_FAILURE:
    case UPDATE_ATTRIBUTE_FAILURE:
      return { ...state, savingStatus: 'error' }
    default:
      return state
  }
}
