import { AllEffect, CallEffect, PutEffect, SelectEffect, all, spawn } from 'redux-saga/effects'

import imageSaga from './sagas/image'
import productCommentsSaga from './sagas/comments'
import productLinksSaga from './sagas/links'
import hierarchySaga from './sagas/hierarchy'
import referenceDataSaga from './sagas/referenceData'
import attributesSaga from './sagas/attributes'
import departmentSpecsSaga from './sagas/departmentSpecs'
import configSaga from './sagas/config'
import multiSelectSaga from './sagas/multiSelect'
import styleSaga from './sagas/style'
import createProduct from './sagas/product/create'
import fetchProductsSaga from './sagas/product/fetch'
import updateProductSaga from './sagas/product/update'
import updateProductHierarchySaga from './sagas/product/moveHierarchy'
import updateProductStatusSaga from './sagas/product/updateStatus'
import copyProductToHierarchySaga from './sagas/product/copyTo'
import duplicateProductSaga from './sagas/product/duplicate'
import importProductsSaga from './sagas/product/import'
import exportProductsSaga from './sagas/product/export'
import bulkUpdateProductStatusSaga from './sagas/product/bulkUpdateStatus'
import countriesSaga from './sagas/countries'
import fetchSizesSaga from './sagas/product/fetchSizes'
import updateSizesSaga from './sagas/product/updateSizes'
import filteringAndSorting from './sagas/filteringAndSorting'
import lockSaga from './sagas/lock'
import cookieSaga from './sagas/cookies'
import pollingSaga from './sagas/polling/polling'
import eventErrorSaga from './sagas/product/eventErrors'
import reclassSaga from './sagas/product/reclass'
import updateSizesAttributionsSaga from './sagas/product/updateSizesAttributions'
import updateColumnsOrderSaga from './sagas/columns'
import adminDepartmentsSaga from './sagas/admin/departments'
import wholeSaleImagesSaga from './sagas/wholesaleImages'
import commsScreenSaga from './sagas/admin/commsScreen'
import userSettingsSizesSaga from './sagas/userSettings/sizes'
import userSettingsDefaultAttributesSaga from './sagas/userSettings/defaultAttributes'
import { SVPAction } from 'actions/svp'

export type SVPSaga = Generator<CallEffect | PutEffect<SVPAction> | SelectEffect | AllEffect>

export default function* rootSaga(): Generator {
  yield all([
    spawn(configSaga),
    spawn(attributesSaga),
    spawn(departmentSpecsSaga),
    spawn(createProduct),
    spawn(fetchProductsSaga),
    spawn(updateProductSaga),
    spawn(updateProductHierarchySaga),
    spawn(updateProductStatusSaga),
    spawn(bulkUpdateProductStatusSaga),
    spawn(duplicateProductSaga),
    spawn(copyProductToHierarchySaga),
    spawn(importProductsSaga),
    spawn(exportProductsSaga),
    spawn(productCommentsSaga),
    spawn(productLinksSaga),
    spawn(imageSaga),
    spawn(hierarchySaga),
    spawn(referenceDataSaga),
    spawn(multiSelectSaga),
    spawn(styleSaga),
    spawn(countriesSaga),
    spawn(fetchSizesSaga),
    spawn(filteringAndSorting),
    spawn(updateSizesSaga),
    spawn(lockSaga),
    spawn(cookieSaga),
    spawn(pollingSaga),
    spawn(eventErrorSaga),
    spawn(reclassSaga),
    spawn(updateSizesAttributionsSaga),
    spawn(updateColumnsOrderSaga),
    spawn(adminDepartmentsSaga),
    spawn(wholeSaleImagesSaga),
    spawn(commsScreenSaga),
    spawn(userSettingsSizesSaga),
    spawn(userSettingsDefaultAttributesSaga),
  ])
}
