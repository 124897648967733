import { AttributeValue, ToolType } from 'src/types/index'
import { DropdownOption } from 'src/types/Dropdowns'
import {
  BOOLEAN_CELL,
  CURRENCY_CELL,
  LIST_CELL,
  MULTI_LIST_CELL,
  SIZE_CELL,
  TEXT_CELL,
  LONG_TEXT_CELL,
  DATE_TODAY_CELL,
  DATE_CELL,
} from 'src/constants/attributes'

export const getWsReadyWeekValues = (): AttributeValue[] => {
  const weeks = Array.from(Array(53).keys()).map((item) => ({
    value: `${item + 1}`,
    deprecated: false,
  }))
  weeks.push({ value: 'Cancelled', deprecated: false })

  return weeks
}

export const getToolsValues = (): ToolType[] => [
  {
    displayName: 'Country of Origin',
    key: 'countryOfOrigin',
  },
  {
    displayName: 'Create Page',
    key: 'createPage',
  },
  {
    displayName: 'Currencies',
    key: 'currencies',
  },
  {
    displayName: 'Department Shortcodes',
    key: 'departmentShortcodes',
  },
  {
    displayName: 'System Lock',
    key: 'lock',
  },
  {
    displayName: 'Range Product Numbers',
    key: 'rangeProductNumbers',
  },
  {
    displayName: 'Season Calendar',
    key: 'seasonCalendar',
  },
  {
    displayName: 'Selling Price Points',
    key: 'sellingPricePoints',
  },
  {
    displayName: 'Size Matrix',
    key: 'sizeMatrix',
  },
  {
    displayName: 'Comms Screen',
    key: 'commsScreen',
  },
]

export const getUserSettingsValues = (): ToolType[] => [
  {
    displayName: 'Sizes',
    key: 'sizes',
  },
  {
    displayName: 'Default Attributes',
    key: 'defaultAttributes',
  },
]

export const getDropdownAttributeOptions = (): DropdownOption[] => [
  {
    value: TEXT_CELL,
    label: 'Free Text',
    data: { value: TEXT_CELL, label: 'Free Text' },
  },
  {
    value: LONG_TEXT_CELL,
    label: 'Long Free Text',
    data: { value: LONG_TEXT_CELL, label: 'Long Free Text' },
  },
  {
    value: LIST_CELL,
    label: 'Dropdown',
    data: { value: LIST_CELL, label: 'Dropdown' },
  },
  {
    value: MULTI_LIST_CELL,
    label: 'Multi Value',
    data: { value: MULTI_LIST_CELL, label: 'Multi Value' },
  },
  {
    value: CURRENCY_CELL,
    label: 'Currency',
    data: { value: CURRENCY_CELL, label: 'Currency' },
  },
  {
    value: BOOLEAN_CELL,
    label: 'Checkbox',
    data: { value: BOOLEAN_CELL, label: 'Checkbox' },
  },
  {
    value: SIZE_CELL,
    label: 'Size',
    data: { value: SIZE_CELL, label: 'Size' },
  },
  {
    value: DATE_TODAY_CELL,
    label: 'Date Today',
    data: { value: DATE_TODAY_CELL, label: 'Date Today' },
  },
  {
    value: DATE_CELL,
    label: 'Date',
    data: { value: DATE_CELL, label: 'Date' },
  },
]

export type OrderedMonths = {
  [key: string]: number
}

export const getOrderedSellingPeriodMonths = (): OrderedMonths => ({
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
})

export const allOption = {
  value: 'all',
  label: 'All',
}
