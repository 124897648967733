import { connect } from 'react-redux'
import { GridType } from 'src/types/index'
import { selectHierarchies } from 'src/actions/grid/hierarchy'
import { DevTrackerGrid } from './DevTrackerGrid'

const mapDispatchToProps = (dispatch: any) => ({
  selectHierarchies: (hierarchySlugs: string[], grid: GridType) =>
    dispatch(selectHierarchies(hierarchySlugs, grid)),
})

export const DevTrackerGridContainer = connect(null, mapDispatchToProps)(DevTrackerGrid)
