import React from 'react'
import {
  commsScreenToolContainer,
  commsScreenContainer,
  header,
  title,
  subtitle,
  dragAndDropContainer,
  dragAndDropArea,
  dragAndDropMessage,
  metaInfoContainer,
} from './CommsScreenTool.scss'
import { FileRejection, useDropzone } from 'react-dropzone'
import { PdfIcon } from 'assets/icons'
import { CommsDocumentFileMeta } from 'types/CommsDocument'
import moment from 'moment'
interface CommsScreenToolProps {
  fileMeta: CommsDocumentFileMeta | null
  getCommsPdfMeta: () => void
  uploadCommsPdf: (file: File) => void
  showErrorNotification: (message: string) => void
}

export const CommsScreenTool = (props: CommsScreenToolProps) => {
  React.useEffect(() => {
    props.getCommsPdfMeta()
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFile: File[], rejectedFile: FileRejection[]) => {
      if (rejectedFile.length !== 0) {
        props.showErrorNotification('This file type is not accepted')
      } else {
        props.uploadCommsPdf(acceptedFile[0])
      }
    },
  })

  return (
    <div className={commsScreenToolContainer}>
      <div className={commsScreenContainer}>
        <div className={header}>
          <h3 className={title}>Comms Screen</h3>
          <p className={subtitle}> Upload a PDF file to share with the users </p>
        </div>
        {
          <div className={dragAndDropContainer}>
            <div className={dragAndDropArea} {...getRootProps()}>
              <PdfIcon width="150px" height="150px" />
              {!!props.fileMeta?.name && (
                <div className={metaInfoContainer}>
                  <div>{props.fileMeta.name}</div>
                  <div>
                    Last Modified:{' '}
                    {moment(props.fileMeta.lastModified).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                </div>
              )}
              <input {...getInputProps()} />
              <p className={dragAndDropMessage}>Drag and drop, or click to select your file</p>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
