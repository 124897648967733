import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { GetAuth, isAuthenticated } from '@ri-digital/auth0-authenticator'

import Callback from './components/Callback'
import { AppState } from '../../reducers/root'
import { hasUIScope } from '../../selectors/auth0/admin'
import { VIEW_SVP_UI } from '../../constants/scopes'
import { trackEvent } from '../../service/analytics/analytics'

interface Props {
  isAuthenticated: boolean
  hasViewSVPScope: boolean
}

class CallbackPage extends Component<Props> {
  render() {
    const { isAuthenticated, hasViewSVPScope } = this.props

    if (isAuthenticated && !hasViewSVPScope) {
      const auth = GetAuth()
      const profile = auth.getProfile()

      // If the user does not have the View SVP scope we need to add them to the Auth0 role
      // This will track them in GA so we can add them as we go
      trackEvent('Authentication', 'User without SVP permission', profile.nickname)
    }

    if (isAuthenticated) {
      return <Redirect to="/" />
    }

    return <Callback />
  }
}

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: isAuthenticated(state.authentication),
  hasViewSVPScope: hasUIScope(state, VIEW_SVP_UI),
})

export default connect(mapStateToProps)(CallbackPage)
