import React from 'react'

import { buttons, modal } from './CopyToModal.scss'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import { Modal } from '../Modal/Modal'

type Props = {
  productSlug: string
  hierarchySlug: string
  hasWholesaleImages: boolean
  onGoToItem: (productSlug: string, hierarchySlug: string) => void
  onClose: () => void
}

export const CopyToModal = (props: Props): JSX.Element => (
  <Modal modalClass={modal} onCloseFn={props.onClose}>
    <p>The item has been copied successfully</p>
    <p>into Dev Tracker</p>
    {props.hasWholesaleImages && (
      <>
        <p>Please check the Gallery tab in the side panel</p>
        <p>as images have been copied for this product</p>
      </>
    )}

    <div className={buttons}>
      <SecondaryButton
        onClick={(): void => {
          props.onGoToItem(props.productSlug, props.hierarchySlug)
        }}
        dataCy="goToCopiedItemButton"
      >
        Go to copied item
      </SecondaryButton>
      <PrimaryButton onClick={props.onClose} colour="black">
        Continue in Option Library
      </PrimaryButton>
    </div>
  </Modal>
)
