import React from 'react'
import { CheckboxDropdown } from 'components/Dropdown/CheckboxDropdown/CheckboxDropdown'
import { GridType, RenderSpec } from 'types'
import { DropdownOption } from 'types/Dropdowns'
import { columnDisplayContainer } from './ColumnDisplay.scss'
import { allOption } from 'service/product/uiValues'
import { alwaysShownColumns } from 'service/columns/columnFilter'

interface Props {
  columns: RenderSpec[]
  gridName: GridType
  hiddenColumns: DropdownOption[]
  updateHiddenColumns: (columns: DropdownOption[], grid: GridType) => void
}

const getCustomControlStyles = (isColumnSelected: boolean) => ({
  border: 'none',
  borderRadius: '1.2rem',
  width: '13rem',
  margin: 0,
  color: 'white',
  backgroundColor: isColumnSelected ? 'rgba(0,0,0)' : 'rgba(0,0,0, 0.3)',
})

const customIndicatorsContainerStyles = {
  position: 'absolute',
  top: '0.7rem',
  right: '0.7rem',
}

const customDropdownIndicatorStyles = {
  color: '#E4E726',
  backgroundColor: 'rgba(0,0,0, 0.5)',
  borderRadius: '50%',
  padding: '2px',
}

const customInputStyles = {
  color: 'white',
}

const customSingleValueStyles = {
  color: 'white',
}

const customMenuStyles = {
  width: '13rem',
  marginTop: '0.2rem',
  borderRadius: '1rem',
  display: 'flex',
  justifyContent: 'center',
}

const customMenuListStyles = {
  borderRadius: '0.5rem',
  width: '12rem',
}

const customOptionStyles = {
  fontSize: '14px',
  borderBottom: '1px solid #EEE',
}

const customPlaceholderStyles = {
  color: 'white',
}

const createOptionFromColumn = (column: RenderSpec): DropdownOption => ({
  value: column.property,
  label: column.displayName,
})

const getActiveColumns = (columns: RenderSpec[]): RenderSpec[] =>
  columns.filter(
    (column) =>
      !!column.displayName &&
      column?.displayName !== 'STYLE' &&
      !alwaysShownColumns.some((col) => col === column.property),
  )

const createOptionFromIncludedColumn = (
  activeColumns: RenderSpec[],
  hiddenColumns: DropdownOption[],
) =>
  activeColumns
    .filter(
      (activeCol) => !hiddenColumns.some((hiddenCol) => hiddenCol.value === activeCol.property),
    )
    .map(createOptionFromColumn)

export const ColumnDisplay = (props: Props) => {
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(true)
  React.useEffect(() => {
    setIsAllSelected(!hiddenColumns.length)
  }, [])
  const { columns, hiddenColumns } = props
  const activeColumns = getActiveColumns(columns)
  const shownColumns = createOptionFromIncludedColumn(activeColumns, hiddenColumns)

  return (
    <div className={columnDisplayContainer}>
      <CheckboxDropdown
        value={isAllSelected ? [allOption, ...shownColumns] : shownColumns}
        options={activeColumns.map(createOptionFromColumn)}
        includeAllButton
        placeholder="Show columns"
        isValid
        // @ts-ignore
        customControlStyles={getCustomControlStyles(!!hiddenColumns?.length)}
        customIndicatorsContainerStyles={customIndicatorsContainerStyles}
        customDropdownIndicatorStyles={customDropdownIndicatorStyles}
        customInputStyles={customInputStyles}
        customSingleValueStyles={customSingleValueStyles}
        customMenuStyles={customMenuStyles}
        customMenuListStyles={customMenuListStyles}
        customOptionStyles={customOptionStyles}
        customPlaceholderStyles={customPlaceholderStyles}
        onChange={(options: DropdownOption[], shouldSelectAll: boolean) => {
          const newHiddenColumns = createOptionFromIncludedColumn(activeColumns, options)
          props.updateHiddenColumns(newHiddenColumns, props.gridName)
          setIsAllSelected(shouldSelectAll)
        }}
      />
    </div>
  )
}
