import React from 'react'

interface Props {
  className: string
}

export const FilterIcon = ({ className }: Props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 24">
    <title>FilterIcon</title>
    <path d="M20,6H4" fill="none" stroke="#222" strokeLinecap="round" strokeWidth="2" />
    <path d="M17,12H7" fill="none" stroke="#222" strokeLinecap="round" strokeWidth="2" />
    <path d="M14,18H10" fill="none" stroke="#222" strokeLinecap="round" strokeWidth="2" />
  </svg>
)
