import { getModals } from 'src/selectors/modals/modals'
import { AppState } from '../../reducers/root'
import { connect } from 'react-redux'
import { GlobalModal } from './GlobalModal'

const mapStateToProps = (state: AppState) => ({
  modals: getModals(state),
})

export const GlobalModalContainer = connect(mapStateToProps)(GlobalModal)
