import React from 'react'

import { columnsDescription } from './Columns.scss'
import { DraggableList } from 'src/components/DraggableList/DraggableList'
import { ColumnValue } from 'src/types/index'
import { ACTION_COLUMN_PROPERTY_NAME } from 'src/constants/adminRenderSpecs'
import { DraggableListItem } from 'src/types/Lists'

type Props = {
  columns: ColumnValue[]
  updateColumnsOrder: (columns: string[], actionColumn?: ColumnValue) => void
}

type State = {
  actionColumn?: ColumnValue
  orderedListItems: DraggableListItem[]
}

const isActionColumn = (column: ColumnValue): boolean =>
  column.propertyName === ACTION_COLUMN_PROPERTY_NAME
export class Columns extends React.Component<Props, State> {
  state = {
    actionColumn: this.props.columns.find(isActionColumn),
    orderedListItems: this.props.columns
      .filter((column) => !isActionColumn(column))
      .map(({ displayName, slug }) => ({
        elementName: displayName,
        elementKey: slug,
      })),
  }

  onDrop = (reorderedColumns: DraggableListItem[]): void => {
    const reorderedSlugs = reorderedColumns.map(({ elementKey }) => elementKey)
    this.props.updateColumnsOrder(reorderedSlugs, this.state.actionColumn)
    this.setState({ orderedListItems: reorderedColumns })
  }

  render(): JSX.Element {
    return (
      <>
        <DraggableList list={this.state.orderedListItems} onDrop={this.onDrop} />
        <div className={columnsDescription}>
          Click and drag headers to move into a new order in the list
        </div>
      </>
    )
  }
}
