import React from 'react'

interface Props {
  className?: string
}

export const HamburgerMenuIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="448"
    height="382"
    viewBox="0 0 448 382"
  >
    <path
      fill="#FFF"
      d="M15.4285714,48 L416.571429,48 C425.092821,48 432,43.2246667 432,37.3333333 L432,10.6666667 C432,4.77533333 425.092821,0 416.571429,0 L15.4285714,0 C6.90717857,0 0,4.77533333 0,10.6666667 L0,37.3333333 C0,43.2246667 6.90717857,48 15.4285714,48 Z M15.4285714,197 L416.571429,197 C425.092821,197 432,192.224667 432,186.333333 L432,159.666667 C432,153.775333 425.092821,149 416.571429,149 L15.4285714,149 C6.90717857,149 0,153.775333 0,159.666667 L0,186.333333 C0,192.224667 6.90717857,197 15.4285714,197 Z M15.4285714,346 L416.571429,346 C425.092821,346 432,341.224667 432,335.333333 L432,308.666667 C432,302.775333 425.092821,298 416.571429,298 L15.4285714,298 C6.90717857,298 0,302.775333 0,308.666667 L0,335.333333 C0,341.224667 6.90717857,346 15.4285714,346 Z"
      transform="translate(8 18)"
    />
  </svg>
)
