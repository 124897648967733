import React from 'react'
import { AttributesContainer } from 'src/components/Admin/Attributes/AttributesContainer'

interface Props {
  currentAttribute?: string
}

export const AttributesPage = ({ currentAttribute }: Props): JSX.Element => {
  return <AttributesContainer currentAttribute={currentAttribute} />
}
