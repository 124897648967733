import React from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

import {
  primaryNavigation,
  mobileMenuTrigger,
  mobileMenuTriggerText,
  mobileMenuIcon,
  list,
  link,
  linkActive,
  span,
  mobileMenuOpen,
  mobileMenuClosed,
} from './PrimaryNavigation.scss'

import { HamburgerMenuIcon } from '../../assets/icons'

type Props = {
  mobileMenuOpen: boolean
  isAdmin: boolean
  isLoggedIn: boolean
  locked: boolean
}

type State = {
  mobileMenuOpen: boolean
}

export class PrimaryNavigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      mobileMenuOpen: props.mobileMenuOpen,
    }
  }

  toggleMobileMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    })
    e.preventDefault()
  }

  render() {
    const { isLoggedIn, isAdmin, locked } = this.props

    return (
      <nav
        id="PrimaryNavigation"
        className={`${primaryNavigation} ${
          this.state.mobileMenuOpen ? mobileMenuOpen : mobileMenuClosed
        }`}
      >
        <a href="" onClick={this.toggleMobileMenu} className={mobileMenuTrigger}>
          <div className={mobileMenuIcon}>
            <HamburgerMenuIcon />
          </div>
          <span className={mobileMenuTriggerText}>Menu</span>
        </a>
        {isLoggedIn && (
          <ul className={list}>
            {!locked && (
              <li>
                <NavLink
                  activeClassName={linkActive}
                  className={`${link} create-link`}
                  to="/dev-tracker/create"
                >
                  <span data-cy="headerCreate" className={span}>
                    Create
                  </span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                exact
                activeClassName={linkActive}
                className={`${link} products-link`}
                to="/dev-tracker"
              >
                <span className={span}>Dev tracker</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                activeClassName={linkActive}
                className={`${link} option-library-link`}
                to="/option-library"
              >
                <span className={span}>Option library</span>
              </NavLink>
            </li>
            {isAdmin && (
              <li>
                <NavLink activeClassName={linkActive} className={`${link} admin-link`} to="/admin">
                  <span className={span}>Admin</span>
                </NavLink>
              </li>
            )}
            {
              <li>
                <NavLink
                  activeClassName={linkActive}
                  className={`${link} settings-link`}
                  to="/settings"
                >
                  <span className={span}>Settings</span>
                </NavLink>
              </li>
            }
          </ul>
        )}
      </nav>
    )
  }
}

// @ts-ignore
export default withRouter(PrimaryNavigation)
