import { AppState } from '../reducers/root'
import { getGridSlice } from './grid'
import { getAllProducts, getProductsMap } from './product'
import { createSelector } from 'reselect'
import { DEVELOPMENT, PENDING_ODBMS, PROPOSED } from 'src/constants/productStatus'
import { Product } from 'src/types/index'

type MoveableIDs = {
  movable: Array<string>
  movableDevelopmentIds: Array<string>
  notMovable: Array<string>
}

export const getMultiSelectMode = (state: AppState): boolean => getGridSlice(state).multiSelect.mode

export const getSelectAll = (state: AppState): boolean => getGridSlice(state).multiSelect.selectAll

export const getSelectedIds = (state: AppState): string[] =>
  getGridSlice(state).multiSelect.selectedIds

export const getFilteredSelectedIds = createSelector(
  getSelectAll,
  getSelectedIds,
  getAllProducts,
  (selectAll, selectedIds, filteredProducts) =>
    selectAll
      ? filteredProducts.map(({ slug }) => slug)
      : filteredProducts.map(({ slug }) => slug).filter((id) => selectedIds.includes(id)),
)

const isMovable = (product: Product) =>
  [DEVELOPMENT, PROPOSED, PENDING_ODBMS].includes(product.status) ||
  [DEVELOPMENT, PROPOSED].includes(product.statusBeforeCancelled || '')

export const getMovableSelectedIds = createSelector(
  getFilteredSelectedIds,
  getProductsMap,
  (filteredSelectedIds, productsMap) =>
    filteredSelectedIds.reduce(
      (acc: MoveableIDs, productSlug: string) =>
        isMovable(productsMap[productSlug])
          ? {
              movable: [...acc.movable, productSlug],
              movableDevelopmentIds: [
                ...acc.movableDevelopmentIds,
                productsMap[productSlug].developmentId,
              ],
              notMovable: [...acc.notMovable],
            }
          : {
              movable: [...acc.movable],
              movableDevelopmentIds: [...acc.movableDevelopmentIds],
              notMovable: [...acc.notMovable, productSlug],
            },
      {
        movable: [],
        movableDevelopmentIds: [],
        notMovable: [],
      },
    ),
)

export const getSelectedProducts = createSelector(
  getProductsMap,
  getFilteredSelectedIds,
  (productMap, selectedIds): Product[] =>
    Object.entries(productMap)
      .filter(([slug]) => selectedIds.includes(slug))
      .map(([_, product]) => product),
)

export const getIsSelectedIdsEmpty = createSelector(
  getSelectAll,
  getSelectedIds,
  (selectAll, selectedIds) => !selectAll && selectedIds.length === 0,
)
