import { ColumnId } from 'src/types/index'
import { isNotProdOrTraining } from 'src/helpers/envs'
import { DropdownOption } from 'types/Dropdowns'

// To filter out a specific column, add an entry using column id for the key and true as its value
// To enable the filter, either remove the record or set the value to false
const disabledColumns: Record<string, boolean> = {
  testColumn: true,
  sizes: false,
  wsReadyWeek: false,
  styleID: false,
}

export const alwaysShownColumns = [
  'developmentId',
  'productNumber',
  'images',
  'hierarchySlug',
  'styleGroup',
  'productDescription',
  'styleID',
]

export const applyColumnFilter = (
  columns: ColumnId[],
  userHiddenColumns: DropdownOption[] = [],
): ColumnId[] => {
  let fiteredColumns = columns
  if (!isNotProdOrTraining()) {
    fiteredColumns = columns.filter((col) => !disabledColumns[col])
  }

  return fiteredColumns.filter(
    (column) => !userHiddenColumns.some((userHiddenColumn) => userHiddenColumn.value === column),
  )
}
