import {
  ADD_EXACT_FILTER_SUCCESS,
  Filter,
  REMOVE_EXACT_FILTER_SUCCESS,
} from 'src/actions/grid/exactFilters'
import { CLEAR_AND_SELECT_HIERARCHY } from '../../../actions/grid/hierarchy'
import { CLEAR_ALL_FILTERS_SUCCESS } from '../../../actions/grid/filters'
import { REMOVE_EXACT_FILTER } from '../../../actions/grid/exactFilters'
import { SVPAction } from 'actions/svp'
import { PRICE_STATUS } from 'constants/price'

const onlyUniqueValues = (cv: Filter, index: number, arr: Filter[]) => {
  return arr.map((mapObj) => mapObj.value).indexOf(cv.value) === index
}

const removeByValue = (value: string) => (cv: Filter) => cv.value !== value

export type ExactFiltersState = { [key: string]: Filter[] }

export const exactFilters = (state: ExactFiltersState = {}, action: SVPAction) => {
  switch (action.type) {
    case ADD_EXACT_FILTER_SUCCESS:
      return {
        ...state,
        [action.filter.property]:
          action.filter.property === PRICE_STATUS
            ? [action.filter]
            : [...(state[action.filter.property] || []), action.filter].filter(onlyUniqueValues),
      }
    case REMOVE_EXACT_FILTER: // we have to optimistically update the state to allow unique values to refresh
    case REMOVE_EXACT_FILTER_SUCCESS:
      return {
        ...state,
        [action.filter.property]: (state[action.filter.property] || []).filter(
          removeByValue(action.filter.value),
        ),
      }
    case CLEAR_ALL_FILTERS_SUCCESS:
    case CLEAR_AND_SELECT_HIERARCHY:
      return {}
    default:
      return state
  }
}
