import { Config } from 'src/types/index'

// config
export const FETCH_CONFIG = 'FETCH_CONFIG'
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE'

export type FetchConfigActions = ReturnType<
  typeof fetchConfig | typeof fetchConfigSuccess | typeof fetchConfigFailure
>

export const fetchConfig = () =>
  <const>{
    type: FETCH_CONFIG,
  }

export const fetchConfigSuccess = (payload: Config) =>
  <const>{
    type: FETCH_CONFIG_SUCCESS,
    payload,
  }

export const fetchConfigFailure = (error: string) =>
  <const>{
    type: FETCH_CONFIG_FAILURE,
    error,
  }
