import {
  POLLING_MARK_CANCELLED,
  POLLING_MARK_READY_TO_BUY,
  POLLING_SIZES_AFTER_RTB,
  POLLING_SIZE_SKUS,
} from 'src/constants/polling'

export const START_POLLING = 'START_POLLING'
export const STOP_POLLING = 'STOP_POLLING'
export const POLLING_ERROR = 'POLLING_ERROR'
export const ADD_POLLING_ID = 'ADD_POLLING_ID'
export const ADD_POLLING_IDS = 'ADD_POLLING_IDS'
export const RECONCILE_POLLING_ID = 'RECONCILE_POLLING_ID'
export const POLLING_IDS_COMPLETE = 'POLLING_IDS_COMPLETE'

export type PollingTypes =
  | typeof POLLING_MARK_READY_TO_BUY
  | typeof POLLING_SIZES_AFTER_RTB
  | typeof POLLING_SIZE_SKUS
  | typeof POLLING_MARK_CANCELLED
