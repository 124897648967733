import { combineReducers } from 'redux'
import { tree } from './tree'
import { lookupTable, LookupHierarchyState } from './lookupTable'

import {
  departmentsWithChildren,
  DepartmentsWithChildrenState,
} from 'src/reducers/referenceData/hierarchy/departmentsWithChildren'
import { TreeHierarchyItem } from 'src/types/index'

export type HierarchyState = {
  tree: TreeHierarchyItem[]
  lookupTable: LookupHierarchyState
  departmentsWithChildren: DepartmentsWithChildrenState
}

export const reducers = {
  tree,
  lookupTable,
  departmentsWithChildren,
}

// @ts-ignore
export const hierarchy: HierarchyState = combineReducers(reducers)
