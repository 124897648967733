import { FETCH_REFERENCE_DATA_SUCCESS } from '../../actions/referenceData/referenceData'
import { Country } from 'src/types/index'
import { UPDATE_COUNTRY_SUCCESS, UPDATE_COUNTRY_ERROR } from '../../actions/referenceData/countries'
import { SVPAction } from 'actions/svp'
const defaultState: Country[] = []

export const countries = (state: Country[] = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return action.countries
    case UPDATE_COUNTRY_SUCCESS: {
      const { code, payload } = action

      return state.map<Country>((country) => {
        if (country.code === code) {
          return {
            ...country,
            ...payload,
          }
        }
        return country
      })
    }
    case UPDATE_COUNTRY_ERROR:
    default:
      return state
  }
}
