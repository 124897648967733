import React, { useEffect } from 'react'
import { textContainer } from './StyleIdCellRenderer.scss'
import { CANCELLED } from 'constants/productStatus'
import { StyleIdCellRendererProps } from './StyleIdCellRendererContainer'

const shouldShowModal = (status: string, locked: boolean): boolean => {
  return status !== CANCELLED && !locked
}

export const StyleIdCellRenderer = (props: StyleIdCellRendererProps): JSX.Element => {
  useEffect(() => {
    const { editMode, product, locked, openStyleModal, exitEditMode } = props
    if (editMode && shouldShowModal(product.status, locked)) {
      openStyleModal(product.hierarchySlug, product)
      exitEditMode()
    }
  }, [props.editMode, props.product, props.locked, props.openStyleModal, props.exitEditMode])

  return (
    <div className={textContainer}>
      <span data-cy="style-id-cell-renderer-span">{props.product.styleID}</span>
      <span data-cy="style-id-cell-renderer-span">{props.product.styleDescription}</span>
    </div>
  )
}
