import { DropdownOption } from 'types/Dropdowns'
import { Product } from 'types/Product'

export const OPEN_STYLE_MODAL = 'OPEN_STYLE_MODAL'
export const CLOSE_STYLE_MODAL = 'CLOSE_STYLE_MODAL'
export const SET_VIEW_RESULTS = 'SET_VIEW_RESULTS'
export const SET_STYLE_DESCRIPTION = 'SET_STYLE_DESCRIPTION'
export const SET_STYLE_ID = 'SET_STYLE_ID'
export const SET_STYLE_NAME = 'SET_STYLE_NAME'
export const SET_PRODUCT_PRINT = 'SET_PRODUCT_PRINT'
export const SET_STYLE_GROUP = 'SET_STYLE_GROUP'
export const SET_DEPARTMENT = 'SET_DEPARTMENT'

export type StyleModalActions = ReturnType<
  | typeof openStyleModal
  | typeof closeStyleModal
  | typeof setViewResults
  | typeof setStyleDescription
  | typeof setStyleId
  | typeof setStyleName
  | typeof setProductPrint
  | typeof setStyleGroup
  | typeof setDepartment
>

export const openStyleModal = (hierarchySlug: string, product: Product) =>
  <const>{
    type: OPEN_STYLE_MODAL,
    hierarchySlug,
    product,
  }

export const closeStyleModal = () =>
  <const>{
    type: CLOSE_STYLE_MODAL,
  }

export const setViewResults = (shouldViewResults: boolean) =>
  <const>{
    type: SET_VIEW_RESULTS,
    viewResults: shouldViewResults,
  }

export const setStyleDescription = (value: string) =>
  <const>{
    type: SET_STYLE_DESCRIPTION,
    value,
  }

export const setStyleId = (value: string) =>
  <const>{
    type: SET_STYLE_ID,
    value,
  }

export const setStyleName = (value: string) =>
  <const>{
    type: SET_STYLE_NAME,
    value,
  }

export const setProductPrint = (option: DropdownOption) =>
  <const>{
    type: SET_PRODUCT_PRINT,
    option,
  }

export const setStyleGroup = (option: DropdownOption) =>
  <const>{
    type: SET_STYLE_GROUP,
    option,
  }

export const setDepartment = (option: DropdownOption) =>
  <const>{
    type: SET_DEPARTMENT,
    option,
  }
