import { CreateSketchPanel } from './CreateSketchPanel'
import { connect } from 'react-redux'
import { saveSketch } from 'src/actions/images'
import { AppState } from 'src/reducers/root'
import { showErrorNotification } from 'src/actions/notification'
import { isSketchSaving } from 'src/selectors/images'

interface StateProps {
  isSketchSaving: boolean
}

interface DispatchProps {
  saveSketch: typeof saveSketch
  showErrorNotification: typeof showErrorNotification
}

interface PassedProps {
  developmentId: string
  productSlug: string
}

export interface CreateSketchPanelProps extends StateProps, DispatchProps, PassedProps {}

const mapStateToProps = (state: AppState, ownProps: PassedProps): StateProps => ({
  isSketchSaving: isSketchSaving(state, ownProps.productSlug),
})

const mapDispatchToProps: DispatchProps = {
  saveSketch,
  showErrorNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSketchPanel)
