import Cookies from 'js-cookie'

export function isCookieSet(cookieName: string): boolean {
  return !!Cookies.get(cookieName)
}

export function setCookie(name: string, value: string, opts: Cookies.CookieAttributes = {}): void {
  Cookies.set(name, value, opts)
}

export function getCookie(name: string): string {
  return Cookies.get(name) || ''
}
