import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { GetAuth } from '@ri-digital/auth0-authenticator'

import { addBreadcrumb } from '../../helpers/sentry'

class LoggedOutPage extends Component {
  componentDidMount() {
    addBreadcrumb('auth', 'Logging user out')

    const auth = GetAuth()
    auth.clearStoredData()
  }

  render() {
    return <Redirect to="/" />
  }
}

export default LoggedOutPage
