import { updateHiddenColumns } from 'actions/grid/hiddenColumns'
import { connect } from 'react-redux'
import { AppState } from 'reducers/root'
import { getHiddenColumns } from 'selectors/product'
import { ColumnDisplay } from './ColumnDisplay'

const mapStateToProps = (state: AppState) => {
  return {
    hiddenColumns: getHiddenColumns(state),
  }
}

const mapDispatchToProps = {
  updateHiddenColumns,
}

export const ColumnDisplayContainer = connect(mapStateToProps, mapDispatchToProps)(ColumnDisplay)
