import React from 'react'
import {
  notificationBox,
  closeBtn,
  notificationBoxMessage,
  notificationWrapper,
  warn,
  warnIcon,
  notificationInfo,
} from './notification-box.scss'
import { CloseCross } from '../../../assets/icons'
import classNames from 'classnames'
import { WarnIcon } from '../../../assets/icons/WarnIcon'

type Props = {
  message: string
  type: string
  clearNotification: () => void
}

export const NotificationBox = ({ message, clearNotification, type }: Props) => {
  const notificationBoxClassNames = classNames(notificationBox, {
    [warn]: type === 'warn',
  })

  return (
    <div className={notificationWrapper}>
      <div className={notificationBoxClassNames}>
        <div className={notificationInfo}>
          <WarnIcon className={warnIcon} />
          <span className={notificationBoxMessage} data-cy="notificationMessage">
            {message}
          </span>
        </div>
        <button className={closeBtn} onClick={clearNotification} data-cy="closeNotification">
          <CloseCross />
        </button>
      </div>
    </div>
  )
}

export default NotificationBox
