import React from 'react'
import { Modal } from '../../../Modal/Modal'
import { Link } from '../../../Link/Link'
import { CloseCross, ExportIcon } from '../../../../assets/icons'
import {
  modalWrapper,
  closeButton,
  message,
  overlay,
  link,
  linkText,
  downloadIcon,
} from './ImportErrorsModal.scss'

interface Props {
  onClose: () => void
  downloadAndClose: () => void
}

const noop = (): void => {
  // noop
}

export const ImportErrorModal = ({ onClose, downloadAndClose }: Props): JSX.Element => (
  <Modal modalClass={modalWrapper} overlayClass={overlay} onCloseFn={noop}>
    <button className={closeButton} onClick={onClose}>
      <CloseCross />
    </button>
    <div className={message}>
      <p>Unsuccessful import on some attribute values</p>
      <p>Download, amend & reimport</p>
    </div>
    <Link className={link} onClick={downloadAndClose}>
      <ExportIcon className={downloadIcon} />{' '}
      <span className={linkText}>Click here to download</span>
    </Link>
  </Modal>
)
