import React from 'react'
import ReactTable from 'react-table'
import { defaultCellRangeRenderer, GridCellRangeProps } from 'react-virtualized'
import classNames from 'classnames'

import KeyboardNavigation from 'src/components/KeyboardNavigation'
import SomethingHasBroken from '../SomethingHasBroken'
import InvalidHierarchySelected from '../InvalidHierarchySelected'
import { TableBodyWithKeyboardNav } from './TableBody/index'
import TableCell from './TableCell/TableCell'
import TableHead from './TableHead'
import {
  OPTION_LIBRARY,
  ColumnId,
  GridType,
  Product,
  SelectedHierarchy,
  CellType,
  AttributeValueMap,
  IntentionalAny,
  FilterType,
} from 'src/types/index'
import { RowComponent } from './RowComponent'
import FilterableHeader from './TableHead/FilterableHeader/FilterableHeader'
import { getDepartmentByHierarchy } from '../../service/hierarchy/utils'
import { getStatusIgnoringPendingOdbms } from '../../service/product/status'
import {
  PENDING_RTB_SIZES,
  PRODUCT_STATUS_CANCELLED_KEY,
  PROPOSED_PENDING_FOR_RTB,
  RTB_PENDING_CANCELLATION,
} from 'src/constants/productStatus'
import ActionsHeaderContainer from './TableHead/ActionsHeader/ActionsHeaderContainer'
import { isAdmin } from '../../selectors/userProfile'
import { componentRegistryLookup } from '../../registry/componentRegistry'
import { RISpinner } from '../RISpinner/RISpinner'
import { FancyFilterDrawer } from '../FancyFilterDrawer'

import { SortedColumn } from 'src/actions/grid/sortedColumns'
import { PatchPayload } from 'src/actions/grid/product/update'
import { DepartmentSpecs, RenderSpec } from 'src/types/index'

import {
  columnFreeze,
  encapsulatedProductsTable,
  loadingMessage,
  loadingMessageArea,
} from './react-table.scss'
import { tableContainer } from './ProductsTable.scss'
import { ProductStatus } from '../../types/ProductStatus'
import { isEmptyList, isEmptyValue } from 'service/product/validateUtils'
import { getProductEmptyDependencySuggestions } from 'service/product/dependencySuggestions'
import { LIST_CELL, LONG_TEXT_CELL, MULTI_LIST_CELL, TEXT_CELL } from 'constants/attributes'
import { referenceAttributeToApiAttribute } from 'service/mappers/attributes'
import { ClipboardGridDestination, ClipboardGridSource } from 'types/Clipboard'

const LoadingComponent = (props: {
  loading: IntentionalAny
  style: React.CSSProperties | undefined
}): JSX.Element => {
  const loadingMessageClass = classNames(loadingMessageArea, {
    isLoading: props.loading,
  })

  return (
    <div style={props.style} className={loadingMessageClass}>
      <div className={loadingMessage}>
        <RISpinner />
      </div>
    </div>
  )
}

type RenderCellParams = {
  columnIndex: number
  key: string
  rowIndex: number
  style: IntentionalAny
}

type TableProps = {
  products: Product[]
  columns: ColumnId[]
  sortedColumns: SortedColumn[]
  isLoading: boolean
  hasError: boolean
  selectedHierarchy: SelectedHierarchy
  invalidProductFields: { [key: string]: string[] }
  deprecatedProductFields: { [key: string]: string[] }
  updateProduct: (payload: PatchPayload) => void
  updateSortedColumns: (cols: SortedColumn[]) => void
  gridName: GridType
  columnSpec: RenderSpec[]
  departmentsWithChildren: { [key: string]: string[] }
  departmentSpecs: DepartmentSpecs
  loadNextPage: () => void
  lastPage: number | null | undefined
  lastPageLoaded: number | null | undefined
  pageLoading: boolean
  isGridLocked: boolean
  pollingProductSlugs: Array<string>
  allViewColumns: string[]
  showErrorNotification: (message: string) => void
  attributeValueMap: AttributeValueMap
  setClipboardSource: (product: Product, property: string) => void
  clipboardGridSource: ClipboardGridSource
  setClipboardDestination: (productSlug: string, property: string) => void
  clipboardGridDestination: ClipboardGridDestination
}

type ColumnWidth = {
  property: ColumnId
  columnWidth: number
}

type TableState = {
  columnWidths: ColumnWidth[]
  clipboardSourceProduct: Product | null
  clipboardSourceProperty: string
}

export type GridColumnSpec = RenderSpec & {
  id: string
  index: number
  Header: string | (() => JSX.Element) | JSX.Element
}

type RowInfo = { index: number }

type TableThProps = {
  className: string
  columnName: string
  sortedColumn: SortedColumn
  sortable: boolean
  filterPopover: FilterType
  onClick: () => void
}

type TableTbodyProps = {
  numberOfFrozenColumns: number
  columnWidths: number[]
  leftColumnWidths: number[]
  rightColumnWidths: number[]
  rowCount: number
  cellRenderer: ({ columnIndex, key, rowIndex, style }: RenderCellParams) => JSX.Element
  cellRangeRenderer: (props: GridCellRangeProps) => JSX.Element[]
  setGridRef: (node: IntentionalAny) => void
  loadNextPage: () => void
  lastPage: number
  lastPageLoaded: number
  pageLoading: boolean
}

const noDataComponent = (): null => null

const mapColumnsToRenderSpecs = (columnIds: ColumnId[], renderSpecs: RenderSpec[]): RenderSpec[] =>
  columnIds.map((columnId) => renderSpecs.find((spec) => spec.property === columnId) as RenderSpec)

const isOptionLibrary = (gridName: GridType): boolean => gridName === OPTION_LIBRARY

const getHeaderComponent = (spec: RenderSpec, gridName: GridType): JSX.Element | string => {
  if (spec.type === 'actions') {
    const adminOrOl = isAdmin || isOptionLibrary(gridName)

    if (adminOrOl) {
      return <ActionsHeaderContainer gridName={gridName} />
    }
  }

  if (spec.filterType !== 'none') {
    return (
      <FilterableHeader
        key={spec.property}
        property={spec.property}
        displayName={spec.displayName}
        type={spec.filterType}
        attributeType={spec.type}
      />
    )
  }
  return spec.displayName
}

export const getGridColumnsSpecs = (
  columnIds: ColumnId[],
  renderSpecs: RenderSpec[],
  gridName: GridType,
): GridColumnSpec[] =>
  mapColumnsToRenderSpecs(columnIds, renderSpecs)
    .map((spec) => ({
      ...spec,
      Header: getHeaderComponent(spec, gridName),
    }))
    .map((c, index) => ({ ...c, id: c.property, index }))

export const getColumnWidths = (
  columnIds: ColumnId[],
  columnSpecs: RenderSpec[],
  resizedColumns: ColumnWidth[] = [],
): ColumnWidth[] =>
  mapColumnsToRenderSpecs(columnIds, columnSpecs)
    .map(({ property, columnWidth }) => ({ property, columnWidth }))
    .map(
      (columnWidth) =>
        resizedColumns.find(({ property }) => property === columnWidth.property) || columnWidth,
    )

export class ProductsTable extends React.Component<TableProps, TableState> {
  grid?: { recomputeGridSize: () => void }
  state = {
    columnWidths: getColumnWidths(this.props.columns, this.props.columnSpec, []),
    clipboardSourceProduct: null,
    clipboardSourceProperty: '',
  }

  static defaultProps = {
    sortedColumns: [],
    columns: [],
  }

  componentDidUpdate = (): void => {
    this.grid && this.grid.recomputeGridSize()
  }

  static getDerivedStateFromProps = (
    nextProps: TableProps,
    state: TableState,
  ): { columnWidths: ColumnWidth[] } => ({
    columnWidths: getColumnWidths(nextProps.columns, nextProps.columnSpec, state.columnWidths),
  })

  setClipboardSource = (product: Product, property: string, cellType: CellType): void => {
    if ([LIST_CELL, MULTI_LIST_CELL, TEXT_CELL, LONG_TEXT_CELL].includes(cellType)) {
      this.props.setClipboardSource(product, property)
    }
  }

  clipboardPasteProduct = (
    destinationProductSlug: string,
    destinationProperty: string,
    isReadOnly: boolean,
    cellType: CellType,
  ): void => {
    const { attributeValueMap, clipboardGridSource, setClipboardDestination, updateProduct } =
      this.props

    if (clipboardGridSource?.product && clipboardGridSource?.property) {
      if (
        clipboardGridSource.property !== destinationProperty ||
        clipboardGridSource.product?.slug === destinationProductSlug ||
        isReadOnly ||
        ![LIST_CELL, MULTI_LIST_CELL, TEXT_CELL, LONG_TEXT_CELL].includes(cellType)
      ) {
        this.props.showErrorNotification('Cannot paste into this column')
      } else {
        setClipboardDestination(destinationProductSlug, destinationProperty)
        const apiAttributeValue = referenceAttributeToApiAttribute(
          attributeValueMap[destinationProperty],
          clipboardGridSource.product[destinationProperty],
          cellType,
        )
        updateProduct({
          productSlug: destinationProductSlug,
          patchFields: {
            api: {
              [destinationProperty]: apiAttributeValue,
            },
            store: {
              [destinationProperty]: clipboardGridSource.product[destinationProperty],
            },
          },
        })
      }
    }
  }

  render(): JSX.Element {
    const {
      isLoading,
      hasError,
      selectedHierarchy,
      columns,
      products,
      gridName,
      columnSpec,
      allViewColumns,
    } = this.props

    const columnsSpec = getGridColumnsSpecs(columns, columnSpec, gridName)
    const allColumnsSpec = getGridColumnsSpecs(allViewColumns, columnSpec, gridName)
    return (
      <div className={tableContainer}>
        <FancyFilterDrawer
          gridName={gridName}
          selectedHierarchy={selectedHierarchy}
          columns={columnsSpec}
          allColumns={allColumnsSpec}
        />
        {hasError ? (
          <SomethingHasBroken />
        ) : !selectedHierarchy ? (
          <InvalidHierarchySelected gridName={gridName} />
        ) : (
          <KeyboardNavigation
            numberOfRows={products.length}
            numberOfColumns={columnsSpec.length}
            gridName={this.props.gridName}
          >
            <ReactTable
              className={`${encapsulatedProductsTable}`}
              loading={isLoading}
              sortable={false}
              showPagination={false}
              data={[]}
              columns={columnsSpec}
              LoadingComponent={LoadingComponent}
              // @ts-ignore
              getTheadThProps={this.getTheadThProps}
              ThComponent={TableHead}
              getTbodyProps={this.getTbodyProps}
              TbodyComponent={TableBodyWithKeyboardNav}
              NoDataComponent={noDataComponent}
              onResizedChange={this.onResizedChange}
              resized={this.state.columnWidths.map((columnWidth) => ({
                id: columnWidth.property,
                value: columnWidth.columnWidth,
              }))}
            />
          </KeyboardNavigation>
        )}
      </div>
    )
  }

  getTheadThProps = (
    _state: IntentionalAny,
    _rowInfo: RowInfo,
    column: GridColumnSpec,
  ): TableThProps => {
    const { sortedColumns } = this.props
    const isFrozen = column.index < this.getNumberOfFrozenColumns()
    const sortedColumn = sortedColumns.find((c) => c.id === column.property)

    return {
      className: isFrozen ? columnFreeze : '',
      columnName: column.property,
      sortedColumn,
      sortable: column.sortable !== false,
      filterPopover: column.filterType,
      onClick: (): void => {
        column.sortable !== false &&
          this.props.updateSortedColumns([
            {
              id: column.property,
              desc: sortedColumn ? !sortedColumn.desc : false,
            },
          ])
      },
    }
  }

  onResizedChange = (newResized: Array<{ id: ColumnId; value: number }>): void => {
    this.setState((prevState) => {
      const columnWidths = prevState.columnWidths.map((columnWidth) => {
        const newValue = newResized.find(({ id }) => id === columnWidth.property)
        return newValue
          ? {
              property: newValue.id,
              columnWidth: newValue.value,
            }
          : columnWidth
      })
      return { columnWidths }
    })
  }

  getTbodyProps = (): TableTbodyProps => {
    const { products, columns, columnSpec, loadNextPage, lastPage, lastPageLoaded, pageLoading } =
      this.props
    const { columnWidths } = this.state
    const numberOfFrozenColumns = this.getNumberOfFrozenColumns()

    const newColumnWidths: number[] = getColumnWidths(columns, columnSpec, columnWidths).map(
      ({ columnWidth }) => columnWidth,
    )
    const leftColumnWidths: number[] = newColumnWidths.slice(0, numberOfFrozenColumns)
    const rightColumnWidths: number[] = newColumnWidths.slice(numberOfFrozenColumns)
    return {
      numberOfFrozenColumns,
      columnWidths: newColumnWidths,
      leftColumnWidths,
      rightColumnWidths,
      rowCount: products.length,
      cellRenderer: this.cellRenderer,
      cellRangeRenderer: this.cellRangeRenderer,
      setGridRef: this.setGridRef,
      loadNextPage,
      lastPage,
      lastPageLoaded,
      pageLoading,
    }
  }

  cellRenderer = ({ columnIndex, key, rowIndex, style }: RenderCellParams): JSX.Element => {
    const {
      products,
      columns,
      invalidProductFields,
      deprecatedProductFields,
      updateProduct,
      columnSpec,
      departmentSpecs,
      departmentsWithChildren,
      isGridLocked,
    } = this.props
    const { type, property, readOnly, displayName } = mapColumnsToRenderSpecs(columns, columnSpec)[
      columnIndex
    ]
    // Get the renderer for the cell based on the type of cell
    const Renderer = componentRegistryLookup(type).renderer
    const product = products[rowIndex]

    // Check to see if cell is invalid or not
    const invalidFields = invalidProductFields[product.slug]
    let isValid = !invalidFields || !invalidFields.includes(property)

    // Check to see if the system should recommend updating the cell with a value
    const emptyFieldsSuggestions = getProductEmptyDependencySuggestions(product)
    const shouldShowIsEmptyWarning =
      emptyFieldsSuggestions.indexOf(property) > -1 &&
      (isEmptyValue(product[property]) || isEmptyList(product[property]))

    const departmentSlug = getDepartmentByHierarchy(product.hierarchySlug, departmentsWithChildren)
    const departmentSpec = departmentSpecs[departmentSlug]
    // This is a temporary hot-fix for scenario where hierarchies from different departments are rapidly removed from the list of hierarchies
    if (!departmentSpec) {
      return null
    }

    // Check to see if cell is invalid
    let isDeprecated = false
    if (deprecatedProductFields) {
      const deprecatedFields = deprecatedProductFields[product.slug]
      if (deprecatedFields && deprecatedFields.includes(property)) {
        isDeprecated = true
        isValid = false
      }
    }

    const status = getStatusIgnoringPendingOdbms(product.status)
    const statusRules = departmentSpec.rules[status]
    const mandatory =
      status === PRODUCT_STATUS_CANCELLED_KEY ? false : statusRules.mandatory.includes(property)
    const locked =
      status === PRODUCT_STATUS_CANCELLED_KEY ? true : statusRules.locked.includes(property)
    const hasProperty = departmentSpec.allProperties.includes(property)

    const isProductLocked = product.locked || this.productIsLocked(product.slug, product.status)
    const cellIsReadOnly = readOnly || !hasProperty || locked || isGridLocked || isProductLocked

    const hasProductError = !!product.eventErrors && product.eventErrors.length > 0

    return (
      <TableCell
        key={key}
        productSlug={product.slug}
        property={property}
        displayName={displayName}
        style={style}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        isValid={isValid}
        isDeprecated={isDeprecated}
        isReadOnly={cellIsReadOnly}
        isLocked={isProductLocked}
        shouldShowIsEmptyWarning={shouldShowIsEmptyWarning}
        hasProperty={hasProperty}
        cancelled={status === PRODUCT_STATUS_CANCELLED_KEY}
        hasProductError={hasProductError}
        setClipboardSource={(): void => this.setClipboardSource(product, property, type)}
        clipboardPasteProduct={(): void =>
          this.clipboardPasteProduct(product.slug, property, cellIsReadOnly, type)
        }
        clipboardGridSource={this.props.clipboardGridSource}
        clipboardGridDestination={this.props.clipboardGridDestination}
      >
        {hasProperty ? (
          <Renderer
            rowIndex={rowIndex}
            accessor={property}
            product={product}
            updateProduct={updateProduct}
            mandatory={mandatory}
          />
        ) : null}
      </TableCell>
    )
  }

  cellRangeRenderer = (props: GridCellRangeProps): JSX.Element[] => {
    const { products } = this.props

    const children = defaultCellRangeRenderer(props)
    const rows: IntentionalAny[] = []
    const defaultProps = { rowIndex: 0 }

    children.forEach((child) => {
      // @ts-ignore
      const { props = defaultProps } = child

      if (!rows[props.rowIndex]) {
        rows[props.rowIndex] = []
      }

      rows[props.rowIndex].push(child)
    })

    return rows.map((row, index) => {
      const { top, height } = row[0].props.style

      return (
        <RowComponent style={{ top, height }} key={index} productSlug={products[index].slug}>
          {row}
        </RowComponent>
      )
    })
  }

  productIsLocked = (productSlug: string, status: ProductStatus): boolean => {
    if (
      status === PROPOSED_PENDING_FOR_RTB ||
      status === PENDING_RTB_SIZES ||
      status === RTB_PENDING_CANCELLATION
    ) {
      return true
    }

    const { pollingProductSlugs } = this.props

    return pollingProductSlugs.includes(productSlug)
  }

  setGridRef = (node: IntentionalAny): void => {
    this.grid = node
  }

  getNumberOfFrozenColumns = (): number => {
    return 8
  }
}
