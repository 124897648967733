import * as React from 'react'

import Dropdown from 'src/components/Dropdown/index'

import { cell, cellValue, deprecatedValue, dropdownWrapper } from './DropdownCellRenderer.scss'
import { ESC_KEY, TAB_KEY } from 'src/constants/keyCodes'
import { AttributeValue, IntentionalAny, Product } from 'src/types/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import { DropdownOption } from '../../../types/Dropdowns'
import { createEmptyPatchItem } from 'src/service/mappers/uiDropdownItemsMapper'

type PatchItem = {
  apiValue: string
  storeValue: string
}

type CellRendererProps = {
  product: Product
  accessor: string
  dropdownItems: DropdownOption[]
  editMode: boolean
  exitEditMode: (exit: boolean) => void
  updateProduct: (payload: PatchPayload) => void
  createOption: (option: DropdownOption) => DropdownOption
  createPatchItem: (item?: AttributeValue) => PatchItem
  createEmptyPatchItem: () => PatchItem
  noSort: boolean
  displayFormatter?: (arg: IntentionalAny) => string
  customFilterOption?: (option: DropdownOption, name: string) => boolean
  mandatory?: boolean
  locked?: boolean
}

const lookupValue = (
  value: string,
  dropdownItems: DropdownOption[] = [],
): DropdownOption | undefined => {
  return value ? dropdownItems.find((dropdownItem) => dropdownItem.value === value) : undefined
}

const createPatchPayload = (
  product: Product,
  accessor: string,
  { apiValue, storeValue }: PatchItem,
): PatchPayload => ({
  productSlug: product.slug,
  patchFields: {
    api: {
      [accessor]: apiValue,
    },
    store: {
      [accessor]: storeValue,
    },
  },
})

const getIsClearable = (
  mandatory: boolean | undefined,
  locked: boolean | undefined,
  product: Product,
  accessor: string,
): boolean => {
  return product.dynamicAttributesNames?.includes(accessor) && !mandatory && !locked
}

export const DropdownCellRenderer = (props: CellRendererProps): JSX.Element => {
  const {
    editMode,
    accessor,
    product,
    exitEditMode,
    createOption,
    noSort,
    displayFormatter,
    dropdownItems,
    customFilterOption,
    mandatory,
    locked,
  } = props

  const onItemSelected = (selectedItem?: AttributeValue): void => {
    const { product, accessor, updateProduct, exitEditMode, createPatchItem } = props
    if (selectedItem != null) {
      let patchItem
      if (!selectedItem) {
        patchItem = createEmptyPatchItem()
      } else {
        patchItem = createPatchItem(selectedItem)
      }
      updateProduct(createPatchPayload(product, accessor, patchItem))
    }
    exitEditMode(true)
  }

  const selectedItemValue = product[accessor]

  const customIndicatorsContainerStyles = {
    position: 'absolute',
    top: '2px',
    right: '2px',
    border: 'none',
    borderRadius: '100%',
    backgroundColor: '#ddd',
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  }

  const value =
    accessor === 'supplier' && selectedItemValue ? selectedItemValue.description : selectedItemValue

  const selectedItem = lookupValue(value, dropdownItems) || undefined

  const isDeprecated = selectedItem && selectedItem.deprecated

  return (
    <div className={cell}>
      {editMode ? (
        <div className={dropdownWrapper}>
          <Dropdown
            dataCy="dropdown-in-dropdown-cell-renderer"
            items={dropdownItems.filter((item) => !item.deprecated)}
            onItemSelected={onItemSelected}
            selectedItem={selectedItem}
            createOption={createOption}
            name={accessor}
            required
            isValid
            isDeprecated={isDeprecated}
            hasFocus
            onInputKeyDown={(event: KeyboardEvent): void => {
              if (event.key === ESC_KEY || event.key === TAB_KEY) {
                exitEditMode(true)
              }
            }}
            customControlStyles={{
              border: 'none',
              '&:hover': {
                border: 'none',
              },
            }}
            onBlur={(): void => exitEditMode(false)}
            noSort={noSort}
            customFilterOption={customFilterOption}
            isClearable={getIsClearable(mandatory, locked, product, accessor)}
            customIndicatorsContainerStyles={
              selectedItem && getIsClearable(mandatory, locked, product, accessor)
                ? customIndicatorsContainerStyles
                : {}
            }
            mandatory={mandatory}
            locked={locked}
          />
        </div>
      ) : (
        <div
          data-cy="cell-value-div"
          className={`${cellValue} ${
            selectedItem && selectedItem.deprecated ? deprecatedValue : ''
          }`}
        >
          {displayFormatter ? displayFormatter(selectedItemValue) : selectedItemValue}
        </div>
      )}
    </div>
  )
}

export default DropdownCellRenderer
