import React from 'react'
import { DropdownOption, DropdownState } from 'src/types/Dropdowns'
import { codesToPrefixes } from 'src/service/excel/exporter/valueMappers'
import { DOLLAR_KEY, EURO_KEY, POUND_KEY } from 'src/constants/currency'
import Dropdown from '../Dropdown'

import { CurrencyKeys } from 'src/types/index'

type Props = {
  className: string
  name: string
  onItemSelected: (item: any, controlName: string) => void
  selectedItem: CurrencyKeys
}

export default class CurrencyDropdown extends React.Component<Props> {
  createCurrencyOption = (currency: CurrencyKeys): DropdownOption => ({
    value: currency,
    label: codesToPrefixes[currency],
    data: currency,
  })

  render() {
    const customControlStyles = {
      backgroundColor: '#eef0ef',
      borderRadius: '0.5rem',
      margin: '0px',
      width: '50px',
      height: '30px',
      minHeight: 'initial',
      '&:hover': {
        borderColor: 'none',
      },
      customEffects: (state: DropdownState) => ({
        borderRadius: state.menuIsOpen ? '0.5rem 0.5rem 0 0' : '0.5rem',
      }),
    }

    const customMenuStyles = {
      width: '50px',
      minWidth: 'initial',
      boxShadow: 'initial',
      height: 'initial',
      overflow: 'hidden',
    }

    const customInputStyles = {
      display: 'hidden',
    }

    const customOptionStyles = {
      height: '30px',
      backgroundColor: '#eef0ef',
      '&:active': {
        backgroundColor: '#e6e6e6',
      },
      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
      textAlign: 'right',
      paddingRight: '8px',
      borderTop: '1px solid #f5f5f5',
    }

    const customSingleValueStyles = {
      width: '100%',
      textAlign: 'right',
      paddingRight: '12px',
    }

    const customIndicatorsContainerStyles = {
      position: 'relative',
      top: '-50px',
      color: 'blue',
    }

    const customDropdownIndicatorStyles = {
      color: '#26B3AD',
      customEffects: (state: DropdownState) => {
        return {
          transform: state?.selectProps?.menuIsOpen ? 'rotate(180deg)' : '',
        }
      },
    }

    const customContainerStyles = {
      borderRadius: '0.5rem',
    }

    return (
      <Dropdown
        menuPlacement="bottom"
        noSort
        // @ts-ignore
        customControlStyles={customControlStyles}
        customMenuStyles={customMenuStyles}
        className={this.props.className}
        customContainerStyles={customContainerStyles}
        customInputStyles={customInputStyles}
        // @ts-ignore
        customOptionStyles={customOptionStyles}
        customSingleValueStyles={customSingleValueStyles}
        customIndicatorsContainerStyles={customIndicatorsContainerStyles}
        // @ts-ignore
        customDropdownIndicatorStyles={customDropdownIndicatorStyles}
        isSearchable={false}
        name={this.props.name}
        // @ts-ignore
        items={[POUND_KEY, EURO_KEY, DOLLAR_KEY]}
        // @ts-ignore
        createOption={this.createCurrencyOption}
        isValid
        onItemSelected={this.props.onItemSelected}
        // @ts-ignore
        selectedItem={this.props.selectedItem}
        dropdownIndicator
      />
    )
  }
}
