import PrimaryButton from 'components/PrimaryButton'
import { TextFormField } from 'components/TextFormField'
import React from 'react'
import {
  confirmButton,
  editFormContainer,
  primaryButton,
  styleIdContainer,
  styleIdLabel,
  styleIdTitle,
} from './StyleModalEditForm.scss'

interface Props {
  styleID: string
  styleDescription: string
  onConfirm: (styleDescription: string) => void
}

export const StyleModalEditForm = (props: Props): JSX.Element => {
  const [styleDescription, setStyleDescription] = React.useState<string>(
    props.styleDescription || '',
  )
  const [styleDescriptionErrors, setStyleDescriptionErrors] = React.useState<string[]>([])
  const [saveStyleDescriptionButtonDisabled, setSaveStyleDescriptionButtonDisabled] =
    React.useState<boolean>(false)

  const onDescriptionChange = (value: string): void => {
    setStyleDescriptionErrors(validateStyleDescription(value))
    setStyleDescription(value)
  }
  const onConfirm = (): void => {
    if (styleDescriptionErrors.length === 0) {
      props.onConfirm(styleDescription)
    }
  }

  const validateStyleDescription = (styleDescription: string): string[] => {
    if (styleDescription.length > 38) {
      setSaveStyleDescriptionButtonDisabled(true)
      return ['Maximum length 38 characters.']
    }
    if (!/^[\w\s]+$/.test(styleDescription)) {
      setSaveStyleDescriptionButtonDisabled(true)
      return ['Must contain only letters or digits.']
    }
    setSaveStyleDescriptionButtonDisabled(false)
    return []
  }

  return (
    <div className={editFormContainer}>
      <div className={styleIdContainer}>
        <div className={styleIdTitle}>Style ID</div>
        <div className={styleIdLabel}>{props.styleID}</div>
      </div>
      <TextFormField
        name="styleDescription"
        value={styleDescription}
        onChange={onDescriptionChange}
        hasFocus={false}
        header="Style Description"
        required
        validationErrors={styleDescriptionErrors}
        disabled={false}
      />
      <div className={confirmButton}>
        <PrimaryButton
          disabled={saveStyleDescriptionButtonDisabled}
          className={primaryButton}
          onClick={onConfirm}
          colour="green"
          dataCy="saveStyleDescriptionButton"
        >
          Confirm
        </PrimaryButton>
      </div>
    </div>
  )
}
