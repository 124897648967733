import { GridType } from 'src/types/index'
import { getSelectedHierarchies } from 'src/selectors/hierarchy'
import ToggleHierarchy from './ToggleHierarchy'
import { selectHierarchy, deselectHierarchy } from 'src/actions/grid/hierarchy'
import { AppState } from 'src/reducers/root'
import { connect } from 'react-redux'

type OwnProps = {
  gridName: GridType
}

const mapStateToProps = (state: AppState) => ({
  selectedHierarchies: getSelectedHierarchies(state),
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  selectHierarchy: (slug: string) => dispatch(selectHierarchy(slug, ownProps.gridName)),
  deselectHierarchy: (slug: string) => dispatch(deselectHierarchy(slug, ownProps.gridName)),
})

export const ToggleHierarchyContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleHierarchy)
