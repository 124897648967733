import { PollingID } from '../../types/Polling'
import {
  PollingTypes,
  ADD_POLLING_ID,
  RECONCILE_POLLING_ID,
  START_POLLING,
  STOP_POLLING,
  POLLING_ERROR,
  POLLING_IDS_COMPLETE,
  ADD_POLLING_IDS,
} from './types'

export type PollingActions = ReturnType<
  | typeof startPolling
  | typeof stopPolling
  | typeof pollingError
  | typeof addPollingID
  | typeof addPollingIDs
  | typeof reconcilePollingIDs
  | typeof pollingIDComplete
>

export const startPolling = () =>
  <const>{
    type: START_POLLING,
  }

export const stopPolling = () =>
  <const>{
    type: STOP_POLLING,
  }

export const pollingError = (err: Error) =>
  <const>{
    type: POLLING_ERROR,
    error: err,
  }

export const addPollingID = (
  productSlug: string,
  developmentId: string,
  pollingID: string,
  pollType: PollingTypes,
) =>
  <const>{
    type: ADD_POLLING_ID,
    id: pollingID,
    productSlug,
    developmentId,
    pollType,
  }

export interface AddPollingIDsArgs {
  id: string
  productSlug: string
  developmentId: string
  pollType: PollingTypes
}

export const addPollingIDs = (items: AddPollingIDsArgs[]) =>
  <const>{
    type: ADD_POLLING_IDS,
    items: items,
  }

export const reconcilePollingIDs = (pollingIDs: Array<string>) =>
  <const>{
    type: RECONCILE_POLLING_ID,
    ids: pollingIDs,
  }

export const pollingIDComplete = (polls: PollingID[]) =>
  <const>{
    type: POLLING_IDS_COMPLETE,
    polls,
  }
