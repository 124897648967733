import { combineReducers } from 'redux'
import { userSettingsSizes, UserSettingsSizesState } from './sizes'
import {
  userSettingsDefaultAttributes,
  UserSettingsDefaultAttributesState,
} from './defaultAttributes'

export type UserSettingsState = {
  sizes: UserSettingsSizesState
  defaultAttributes: UserSettingsDefaultAttributesState
}

export const reducers = {
  sizes: userSettingsSizes,
  defaultAttributes: userSettingsDefaultAttributes,
}

export const userSettings = combineReducers(reducers)
