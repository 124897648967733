import React from 'react'

import { READY_TO_BUY, PROPOSED } from 'src/constants/productStatus'
import { SkipLink } from 'src/components/Link/index'
import { Modal } from 'src/components/Modal/Modal'
import PrimaryButton from 'src/components/PrimaryButton/index'

import {
  areYouSure,
  bulkStatusChangeModal,
  confirmButton,
  summaryMessage,
} from './BulkChangeStatusModal.scss'

interface Props {
  selectedIDs: string[]
  selectedAll: boolean
  isOptionLibrary: boolean
  onConfirmClick: () => void
  onCloseModalClick: () => void
}

export default function BulkChangeStatusModal({
  isOptionLibrary,
  selectedIDs,
  selectedAll,
  onCloseModalClick,
  onConfirmClick,
}: Props): JSX.Element {
  const numberSelected = selectedAll ? 'All' : selectedIDs.length

  return (
    <Modal modalClass={bulkStatusChangeModal} onCloseFn={onCloseModalClick}>
      <p className={areYouSure}>Are you sure?</p>
      <p className={summaryMessage}>
        {numberSelected} products will be moved to {isOptionLibrary ? READY_TO_BUY : PROPOSED}
      </p>
      <PrimaryButton dataCy="statusConfirm" className={confirmButton} onClick={onConfirmClick}>
        Confirm
      </PrimaryButton>
      <SkipLink onClick={onCloseModalClick}>Cancel</SkipLink>
    </Modal>
  )
}
