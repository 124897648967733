import { Filter } from 'src/actions/grid/exactFilters'
import { PartialFilter } from 'src/actions/grid/partialFilters'
import * as ProductStatuses from 'src/constants/productStatus'
import { ExactFiltersState } from 'src/reducers/common/grid/exactFilters'
import { PartialFiltersState } from 'src/reducers/common/grid/partialFilters'
import { SortedColumnState } from 'src/reducers/common/grid/sortedColumns'
import { DEV_TRACKER, FlatHierarchyItem, GridType } from 'src/types/index'
import { ProductStatus } from 'src/types/ProductStatus'

const devTrackerStatusFilters: ProductStatus[] = [
  ProductStatuses.DEVELOPMENT,
  ProductStatuses.PROPOSED,
  ProductStatuses.READY_TO_BUY,
  ProductStatuses.CANCELLED_IN_DEVELOPMENT,
  ProductStatuses.CANCELLED_IN_PROPOSED,
  ProductStatuses.CANCELLED_IN_READY_TO_BUY,
  ProductStatuses.PROPOSED_PENDING_FOR_RTB,
  ProductStatuses.RTB_PENDING_CANCELLATION,
]

const optionLibraryStatusFilters: ProductStatus[] = [
  ProductStatuses.PROPOSED,
  ProductStatuses.READY_TO_BUY,
  ProductStatuses.CANCELLED_IN_PROPOSED,
  ProductStatuses.CANCELLED_IN_READY_TO_BUY,
  ProductStatuses.PENDING_ODBMS,
  ProductStatuses.PROPOSED_PENDING_FOR_RTB,
  ProductStatuses.PENDING_RTB_SIZES,
  ProductStatuses.RTB_PENDING_CANCELLATION,
]

export const queryParametersBuilder = (
  exactFilters: ExactFiltersState,
  partialFilters: PartialFiltersState,
  sortedColumns: SortedColumnState,
  selectedHierarchies: FlatHierarchyItem[],
  selectedGrid: GridType,
) => {
  const hierarchyParameters = {
    hierarchy_ids: selectedHierarchies.map((h) => h.slug).join(','),
  }

  const statusParameters = {
    statuses:
      selectedGrid === DEV_TRACKER
        ? devTrackerStatusFilters.map((x) => x.toLowerCase()).join(',')
        : optionLibraryStatusFilters.map((x) => x.toLowerCase()).join(','),
  }

  const exactFiltersParameters = transformFilterKeys(
    Object.keys(exactFilters).reduce(
      (accumulator, propertyName) => ({
        ...accumulator,
        ...(exactFilters[propertyName].length > 0
          ? {
              [propertyName]: joinValues(
                translateFilters(exactFilters, propertyName, selectedGrid),
              ),
            }
          : {}),
      }),
      {} as Filter,
    ),
  )
  const partialFiltersParameters = transformFilterKeys(
    Object.keys(partialFilters).reduce(
      (accumulator, propertyName) => ({
        ...accumulator,
        ...(partialFilters[propertyName] && partialFilters[propertyName].saved.length > 0
          ? { [propertyName]: joinValues(partialFilters[propertyName].saved) }
          : {}),
      }),
      {} as PartialFilter,
    ),
  )

  const sortStrings = sortedColumns.map(
    ({ id, desc }) => `${transformSortKey(id)}.${desc ? 'desc' : 'asc'}`,
  )

  const sortParameters = sortStrings.length > 0 ? { sort: sortStrings.join(',') } : {}

  return {
    ...hierarchyParameters,
    ...statusParameters,
    ...exactFiltersParameters,
    ...partialFiltersParameters,
    ...sortParameters,
  }
}

const filterAccessorsToQueryParametersMap: Record<string, string> = {
  status: 'statuses', // TODO https://trello.com/c/nV2AGwqY [Backend paginated endpoint should take "status" instead of statuses, and "hierarchy" instead of "hierarchy_ids"]
  userName: 'createdBy',
}

const sortAccessorsToQueryParametersMap: Record<string, string | undefined> = {
  userName: 'createdBy',
  createdDateDevelopment: 'createdDate',
}

const transformFilterKeys = (obj: Filter | PartialFilter): Record<string, any> =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      // @ts-ignore
      ...{ [filterAccessorsToQueryParametersMap[key] || key]: obj[key] },
    }),
    {} as Record<string, any>,
  )

const transformSortKey = (key: string) => sortAccessorsToQueryParametersMap[key] || key

const joinValues = (exactFilters: PartialFilter[]) =>
  exactFilters.map(({ value }: PartialFilter) => value).join(',')

const translateFilters = (
  exactFilters: ExactFiltersState,
  propertyName: string,
  gridName: GridType,
): PartialFilter[] => {
  const valueTransformations: Record<string, any> = {
    status: translateCancelledStatuses,
  }

  const filters = exactFilters[propertyName]
  const valueTransformation = valueTransformations[propertyName]

  return valueTransformation ? valueTransformation(filters, gridName) : filters
}

const translateCancelledStatuses = (filters: Filter[], gridName: GridType) =>
  filters.reduce(
    (acc, filter) => [
      ...acc,
      ...(filter.value === 'Cancelled' ? cancelledStatusesFiltersFor(gridName) : [filter]),
    ],
    [] as Partial<Filter>[],
  )

const cancelledStatusesFiltersFor = (gridName: GridType): Partial<Filter>[] =>
  gridName === DEV_TRACKER
    ? [
        {
          value: 'cancelled in development',
          label: 'cancelled in development',
          property: 'status',
        },
        {
          value: 'cancelled in proposed',
          label: 'cancelled in proposed',
          property: 'status',
        },
        {
          value: 'cancelled in ready to buy',
          label: 'cancelled in ready ',
          property: 'status',
        },
      ]
    : [
        {
          value: 'cancelled in proposed',
          label: 'cancelled in proposed',
          property: 'status',
        },
        {
          value: 'cancelled in ready to buy',
          label: 'cancelled in ready ',
          property: 'status',
        },
      ]
