import { NotificationTypes } from '../types/Notifications'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export type NotificationActions = ReturnType<typeof showNotification | typeof clearNotification>

export type Notification = {
  type: NotificationTypes
  message: string
}

export const showErrorNotification = (message: string) =>
  showNotification({ type: 'error', message })

export const showNotification = (notification: Notification) =>
  <const>{
    payload: notification,
    type: SHOW_NOTIFICATION,
  }

export const clearNotification = () =>
  <const>{
    type: CLEAR_NOTIFICATION,
  }
