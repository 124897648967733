import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  closeSizeModal,
  fetchSizes,
  fetchSizesSuccess,
  OPEN_SIZE_MODAL,
} from 'src/actions/grid/modal/sizeModal'
import { injectorService } from 'src/service/injector/injectorService'
import { showErrorNotification, showNotification } from 'src/actions/notification'
import {
  getSelectedHierarchySlug,
  getSelectedProductSlug,
  isHierarchyAtRangeLevel,
  isSizeModalOpen,
} from 'src/selectors/modals/sizeModal'
import {
  SizeAttributeAPIResponse,
  SizeDenominationAPIResponse,
  SizeInformationAPIResponse,
} from 'src/types/responses/sizes'
import { POLLING_IDS_COMPLETE } from 'src/actions/polling/types'
import { POLLING_SIZES_AFTER_RTB } from 'src/constants/polling'
import { REFETCH_PRODUCT_SIZES } from 'src/actions/grid/product/sizes'
import { POUND_KEY } from 'src/constants/currency'
import { mapApiSizeAttributionToSizeAttribution } from 'src/service/mappers/sizeAttribution'
import { SizeAttributions } from 'src/types/index'
import { SVPAction } from 'actions/svp'

const sizeDenominationAPI = (hierarchySlug: string): string => `sizes/${hierarchySlug}`
const sizeInformationAPI = (productSlug: string): string => `products/${productSlug}/sizeSkus`
const sizeAttributeAPI = (productSlug: string): string => `products/${productSlug}/sizeAttribution`

export function* fetchProductSizes(
  productSlug: string,
  hierarchySlug: string,
  priceInitiator: boolean,
) {
  yield put(fetchSizes())

  const isRange: boolean = yield select(isHierarchyAtRangeLevel, hierarchySlug)

  if (!isRange) {
    yield put(closeSizeModal())
    const message: string = priceInitiator
      ? 'To enter cost & selling prices, move hierarchy to the correct range'
      : 'Sizes are only available for products at range level'
    yield put(
      showNotification({
        type: 'warn',
        message,
      }),
    )

    return
  }

  try {
    const rangeSizes: SizeDenominationAPIResponse = yield call(
      injectorService.get,
      sizeDenominationAPI(hierarchySlug),
    )

    if (!rangeSizes.sizeDenominations.length) {
      yield put(closeSizeModal())

      yield put(
        showNotification({
          type: 'warn',
          message: 'Product range does not have any sizes',
        }),
      )
      return
    }

    const sizeInformation: SizeInformationAPIResponse = yield call(
      injectorService.get,
      sizeInformationAPI(productSlug),
    )
    const sizeAttributesResponse: SizeAttributeAPIResponse = yield call(
      injectorService.get,
      sizeAttributeAPI(productSlug),
    )

    yield put(
      fetchSizesSuccess({
        rangeSizes: rangeSizes.sizeDenominations,
        sizeInformation: sizeInformation,
        productSizeAttributes: <SizeAttributions>(
          mapApiSizeAttributionToSizeAttribution(sizeAttributesResponse.sizeAttribution)
        ),
        supplierCostCurrency: sizeAttributesResponse.supplierCostCurrency
          ? sizeAttributesResponse.supplierCostCurrency
          : POUND_KEY,
      }),
    )
  } catch (error) {
    yield put(showErrorNotification('Could not find sizes'))
  }
}

export function* fetchSizesSaga(action: SVPAction<typeof OPEN_SIZE_MODAL>) {
  yield call(fetchProductSizes, action.productSlug, action.hierarchySlug, action.priceInitiator)
}

export function* fetchUpdatedSizes(action: SVPAction<typeof POLLING_IDS_COMPLETE>) {
  // We only need to refretch data for sizes after ready to buy
  const productPolls = action.polls.filter((x) => x.pollType === POLLING_SIZES_AFTER_RTB)

  if (productPolls.length === 0) {
    return
  }

  // There should only ever be one
  const poll = productPolls[0]

  const hierarchySlug: string = yield select(getSelectedHierarchySlug)
  const isModalOpen: boolean = yield select(isSizeModalOpen)
  if (!isModalOpen) {
    return
  }

  const currentModalProductSlug: string = yield select(getSelectedProductSlug)

  // If completed polling IDs contain relate to the current product in the modal, ignore the action
  if (!productPolls.some((x) => x.productSlug === currentModalProductSlug)) {
    return
  }

  yield call(fetchProductSizes, poll.productSlug, hierarchySlug, false)
}

export default function* () {
  yield all([takeEvery(OPEN_SIZE_MODAL, fetchSizesSaga)])
  yield all([takeEvery(REFETCH_PRODUCT_SIZES, fetchSizesSaga)])
  yield all([takeEvery(POLLING_IDS_COMPLETE, fetchUpdatedSizes)])
}
