import { fetchProductComments, postProductComment } from 'actions/comments'
import { fetchProductLinks } from 'actions/grid/links'
import { getPreviousProduct, getNextProduct } from 'actions/grid/sidePanelNavigation'
import { fetchStyle } from 'actions/grid/style'
import { connect } from 'react-redux'
import { AppState } from 'reducers/root'
import { getLinksForSlug } from 'reducers/sidePanel/links'
import { getStyle } from 'selectors/style'
import { getEmail } from 'selectors/userProfile'
import { GridType } from 'types'
import { Product } from 'types/Product'
import { SidePanel } from './SidePanel'
import { SidePanelTabs } from 'types/Tabs'
import { getCommentsForSlug, getCommentsStatus } from 'src/reducers/comments'
import { ProductLinksWithStatus } from 'types/Links'
import { StyleWithStatusState } from 'reducers/style'
import { Comment } from 'src/types/index'

type SidePanelContainerPassedProps = {
  product: Product
  products: Product[]
  gridName: GridType
  openingTab: SidePanelTabs
  closeFn: () => void
  sidePanelValue: number
  rowIndex: number
}
interface StateProps {
  comments: Comment[]
  links: ProductLinksWithStatus
  style: StyleWithStatusState
  commentsStatus: string
  userEmail: string
}

interface DispatchProps {
  fetchProductComments: typeof fetchProductComments
  fetchProductLinks: typeof fetchProductLinks
  postProductComment: typeof postProductComment
  fetchStyle: typeof fetchStyle
  getPreviousProduct: typeof getPreviousProduct
  getNextProduct: typeof getNextProduct
}

const mapStateToProps = (state: AppState, ownProps: SidePanelContainerPassedProps): StateProps => ({
  // @ts-ignore the selector takes the comment state not app state so no idea how this works
  comments: getCommentsForSlug(state, ownProps),
  links: getLinksForSlug(state, ownProps.product.slug),
  style: getStyle(state),
  // @ts-ignore the selector takes the comment state not app state so no idea how this works
  commentsStatus: getCommentsStatus(state, ownProps),
  userEmail: getEmail(state) as string,
})

export interface SidePanelProps extends SidePanelContainerPassedProps, StateProps, DispatchProps {}

const mapDispatchToProps: DispatchProps = {
  fetchProductComments,
  fetchProductLinks,
  postProductComment,
  fetchStyle,
  getNextProduct,
  getPreviousProduct,
}

export const SidePanelContainer = connect(mapStateToProps, mapDispatchToProps)(SidePanel)
