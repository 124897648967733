import React from 'react'
import { SketchForm } from './SketchForm/SketchForm'
import { Link } from 'react-router-dom'
import { Sketch } from 'src/types/SketchBundle'

import {
  floatingTitle,
  heading,
  newProductPage,
  pageGrid,
  panel,
  panelAndSketch,
  links,
  skipLink,
} from './CreateSketchPanel.scss'
import Message from 'src/components/Message'
import { CreateSketchPanelProps } from './CreateSketchPanelContainer'

type State = {
  displaySuccessMessage: boolean
}

export class CreateSketchPanel extends React.Component<CreateSketchPanelProps, State> {
  state = {
    displaySuccessMessage: true,
  }

  componentDidMount(): void {
    window.scrollTo(0, 0)
  }

  render(): JSX.Element {
    const { developmentId, productSlug } = this.props

    return (
      <article className={newProductPage}>
        <div className={pageGrid}>
          <h1 className={floatingTitle}>Create product</h1>
          <div className={panelAndSketch}>
            <div className={panel}>
              <div className={heading}>
                {this.state.displaySuccessMessage ? (
                  <Message>
                    <h3 data-cy="createdDevelopmentID">{developmentId}</h3>
                    <div data-cy="createdMessage">Created successfully!</div>
                  </Message>
                ) : (
                  <h3>Product ID {developmentId}</h3>
                )}
              </div>

              <SketchForm
                onChange={(): void => this.setState({ displaySuccessMessage: false })}
                onSubmit={(sketch: Sketch): void => {
                  this.props.saveSketch({
                    sketch,
                    developmentId: developmentId,
                    productSlug: productSlug,
                    redirect: '/dev-tracker/create',
                  })
                }}
                onError={this.props.showErrorNotification}
                developmentId={developmentId}
                isSketchSaving={this.props.isSketchSaving}
              />

              <div className={links}>
                <Link className={skipLink} to="/dev-tracker/create">
                  Skip step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </article>
    )
  }
}
