import { push } from 'connected-react-router'
import { GetAuth } from '@ri-digital/auth0-authenticator'
import { AllEffect, CallEffect, PutEffect, all, call, put, takeEvery } from 'redux-saga/effects'

import { injectorService } from '../../service/injector/injectorService'
import { errorAndNotification } from '../error'
import {
  CREATE_PRODUCT,
  GET_RANGE_DEFAULT_ATTRIBUTES,
  getRangeDefaultAttributesSuccess,
} from '../../actions/grid/product/create'
import { CreateProductResponse } from '../../types/responses/create'
import { trackEvent } from '../../service/analytics/analytics'
import { APIError } from 'types/Api'
import { SVPAction } from 'actions/svp'
import { IntentionalAny } from 'types'
import { showErrorNotification } from 'actions/notification'
import { SVPSaga } from 'sagas'
import { RangeDefaultAttribute } from 'types/UserSettings'
import { logError } from 'service/errors'

const errorMessageFor = (errorType: string): string =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

const CREATE_PRODUCT_ERROR_MESSAGE = errorMessageFor('create product for you')

export function* createProductSaga(
  action: SVPAction<typeof CREATE_PRODUCT>,
): Generator<CallEffect | PutEffect<IntentionalAny>> {
  try {
    const auth = GetAuth()
    const profile = auth.getProfile()

    trackEvent('product', 'Product Created', profile.nickname)

    const { developmentId, slug }: Partial<CreateProductResponse> = yield call(
      injectorService.post,
      'products',
      action.payload,
    )

    yield put(push(`/dev-tracker/create/sketch/${slug}/${developmentId}`))
  } catch (error) {
    yield* errorAndNotification(error as APIError, CREATE_PRODUCT_ERROR_MESSAGE)
  }
}

export function* getRangeDefaultAttributesSaga(
  action: SVPAction<typeof GET_RANGE_DEFAULT_ATTRIBUTES>,
): SVPSaga {
  try {
    const response = (yield call(
      injectorService.get,
      `range/${action.rangeSlug}/attribute-defaults?active=true`,
    )) as RangeDefaultAttribute[]
    yield put(getRangeDefaultAttributesSuccess(response))
  } catch (error) {
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could not get range default attributes'))
  }
}

export default function* (): Generator<AllEffect> {
  yield all([
    takeEvery(CREATE_PRODUCT, createProductSaga),
    takeEvery(GET_RANGE_DEFAULT_ATTRIBUTES, getRangeDefaultAttributesSaga),
  ])
}
