import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { notification, success, error } from './notification.scss'
import NotificationBox from './NotificationBox/NotificationBox'

type Props = {
  message: string
  type?: string
  clearNotification: () => void
}

export const Notification = ({ message, type, clearNotification }: Props) => {
  const showNotification = message.trim().length > 0
  return showNotification ? (
    type === 'warn' ? (
      <NotificationBox message={message} type={type} clearNotification={clearNotification} />
    ) : (
      <Snackbar
        className={`${notification} ${type === 'error' ? error : success}`}
        id="Notification"
        open
        message={message}
        autoHideDuration={4000}
        onClose={clearNotification}
      />
    )
  ) : null
}
