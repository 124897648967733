import React from 'react'

import { Link } from '../Link'
import { skipLink } from './SkipLink.scss'

type Props = {
  onClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  children: React.ReactNode | string | undefined
  dataCy?: string
}

export const SkipLink = ({ onClick, children, dataCy }: Props) => (
  <Link onClick={onClick} className={skipLink} dataCy={dataCy}>
    {children}
  </Link>
)
