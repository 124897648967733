import { AllocatedSizeGroups, Size, SizeAttributions } from 'src/types/Size'
import { CurrencyKeys } from 'src/types/index'

export const OPEN_SIZE_MODAL = 'OPEN_SIZE_MODAL'
export const FETCH_SIZES = 'FETCH_SIZES'
export const FETCH_SIZES_SUCCESS = 'FETCH_SIZES_SUCCESS'
export const CLOSE_SIZE_MODAL = 'CLOSE_SIZE_MODAL'
export const SET_SIZE_MODAL_STATUS_POSTING = 'SET_SIZE_MODAL_STATUS_POSTING'
export const SET_SIZE_MODAL_STATUS_READY = 'SET_SIZE_MODAL_STATUS_READY'

export type SizeModalActions = ReturnType<
  | typeof openSizeModal
  | typeof fetchSizes
  | typeof fetchSizesSuccess
  | typeof closeSizeModal
  | typeof setSizeModalStatusPosting
  | typeof setSizeModalStatusReady
>

export const openSizeModal = (rangeSlug: string, productSlug: string, priceInitiator = false) =>
  <const>{
    type: OPEN_SIZE_MODAL,
    hierarchySlug: rangeSlug,
    productSlug: productSlug,
    priceInitiator,
  }

export const fetchSizes = () =>
  <const>{
    type: FETCH_SIZES,
  }

export interface FetchSizesSuccessPayload {
  rangeSizes: Size[]
  sizeInformation: AllocatedSizeGroups
  productSizeAttributes: SizeAttributions
  supplierCostCurrency: CurrencyKeys
}

export const fetchSizesSuccess = (payload: FetchSizesSuccessPayload) =>
  <const>{
    type: FETCH_SIZES_SUCCESS,
    payload: payload,
  }

export const closeSizeModal = () =>
  <const>{
    type: CLOSE_SIZE_MODAL,
  }

export const setSizeModalStatusPosting = () =>
  <const>{
    type: SET_SIZE_MODAL_STATUS_POSTING,
  }

export const setSizeModalStatusReady = () =>
  <const>{
    type: SET_SIZE_MODAL_STATUS_READY,
  }
