import React from 'react'
import Link from '../../Link'
import { tagLink, closeTag, tagText, tag, filterTag } from './FilterTag.scss'
import { CloseCrossCircle } from '../../SidePanelDrawer/icons/CloseCrossCircle'

type Props = {
  value?: string
  filterKey?: string
  filterValue?: string
  onClose: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  deprecatedClassName?: string
}

export const FilterTag = ({
  value,
  filterKey,
  filterValue,
  onClose,
  deprecatedClassName,
}: Props): JSX.Element => {
  return (
    <div className={`${tag} ${filterTag}`}>
      <Link className={tagLink} onClick={onClose} dataCy="close-filter-tag">
        <CloseCrossCircle className={closeTag} />
      </Link>
      {value && <span className={`${tagText} ${deprecatedClassName || ''}`}>{value}</span>}

      {filterKey && filterValue && (
        <div>
          <span className={tagText}>{filterKey}:</span>
          <span className={`${tagText} ${deprecatedClassName || ''}`}>{filterValue}</span>
        </div>
      )}
    </div>
  )
}
