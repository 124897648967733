import { createSelector } from 'reselect'

import { AppState } from 'src/reducers/root'
import { getHierarchyLookupTable } from '../hierarchy'
import { getProductsMap } from '../product'
import { READY_TO_BUY } from 'src/constants/productStatus'

import { DEFAULT_GROUP, MINI_GROUP, TEEN_GROUP, MINI_EXT_GROUP } from 'src/constants/productSizes'
import {
  AllocatedSize,
  AllocatedSizeGroups,
  Size,
  SizeAttributions,
  SizeGroups,
} from 'src/types/Size'
import { RangeHierachyLevel } from 'src/constants/hierachy'
import { FETCHING_STATUS, POSTING_STATUS } from 'src/constants/requests'
import { CurrencyKeys, Product } from 'src/types/index'
import { isPendingStatus } from 'src/service/product/status'
import {
  SIZE_AFTER_READY_TO_BUY_COMMITMENT_ERROR,
  SIZE_AFTER_READY_TO_BUY_ORDS_ERROR,
} from 'src/constants/errorCodes'
import { ProductEventError } from 'src/types/ProductEventError'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { getModalProduct } from './modals'

export const isSizeModalOpen = (state: AppState): boolean => {
  return state.injectorUI.modals.sizeModal.open
}

export const isSizeModalLoading = (state: AppState): boolean => {
  return (
    state.injectorUI.modals.sizeModal.status === FETCHING_STATUS ||
    state.injectorUI.modals.sizeModal.status === POSTING_STATUS
  )
}

export const getSelectedHierarchySlug = (state: AppState): string => {
  return state.injectorUI.modals.sizeModal.hierarchySlug
}

export const getSelectedProductSlug = (state: AppState): string => {
  return state.injectorUI.modals.sizeModal.productSlug
}

export const getSizeAttribution = (state: AppState): SizeAttributions => {
  return state.injectorUI.modals.sizeModal.productSizeAttributes
}

export const getSupplierCostCurrency = (state: AppState): CurrencyKeys => {
  return state.injectorUI.modals.sizeModal.supplierCostCurrency
}

export const isHierarchyAtRangeLevel = createSelector(
  getHierarchyLookupTable,
  getSelectedHierarchySlug,
  (lookUpTable, selectedHierarchySlug) =>
    lookUpTable[selectedHierarchySlug] &&
    lookUpTable[selectedHierarchySlug].levelName === RangeHierachyLevel,
)

export const isProductInReadyToBuy = createSelector(
  getProductsMap,
  getSelectedProductSlug,
  (productsMap, productSlug) =>
    productsMap[productSlug] && productsMap[productSlug].status === READY_TO_BUY,
)

export const productHasOrdsError = createSelector(
  getProductsMap,
  getSelectedProductSlug,
  (productsMap, productSlug) =>
    productsMap[productSlug] &&
    !!productsMap[productSlug].eventErrors?.some(
      (eventError: ProductEventError) => eventError.code == SIZE_AFTER_READY_TO_BUY_ORDS_ERROR,
    ),
)

export const isProductPending = createSelector(
  getProductsMap,
  getSelectedProductSlug,
  (productsMap, productSlug) =>
    productsMap[productSlug] && isPendingStatus(productsMap[productSlug].status),
)

export const defaultSizeModalData = (): SizeGroups => ({
  [DEFAULT_GROUP]: {
    activeSizes: [],
  },
  [MINI_GROUP]: {
    activeSizes: [],
  },
  [MINI_EXT_GROUP]: {
    activeSizes: [],
  },
  [TEEN_GROUP]: {
    activeSizes: [],
  },
})

export function getSizeModalGroups(state: AppState): SizeGroups {
  const { sizeInformation, productSizeAttributes } = state.injectorUI.modals.sizeModal
  const sizeGroups: SizeGroups = {
    [DEFAULT_GROUP]: {
      activeSizes: [],
      ...productSizeAttributes[DEFAULT_GROUP],
    },
    [MINI_GROUP]: {
      activeSizes: [],
      ...productSizeAttributes[MINI_GROUP],
    },
    [TEEN_GROUP]: {
      activeSizes: [],
      ...productSizeAttributes[TEEN_GROUP],
    },
    [MINI_EXT_GROUP]: {
      activeSizes: [],
      ...productSizeAttributes[MINI_EXT_GROUP],
    },
  }
  const rangeSizesArray = getSizesForRange(state)
  const product = getModalProduct(state, state.injectorUI.modals.sizeModal.productSlug)
  Object.keys(sizeInformation).forEach((sizeInfoKey) => {
    const infoKey = sizeInfoKey as ProductSizeGroups
    const sizes: AllocatedSize[] = sizeInformation[infoKey]
    sizes.forEach((size: AllocatedSize) => {
      const sizeName: string = size.name
      const sizeInfo = getSizeInformation(sizeName, rangeSizesArray, infoKey)

      // Get allocation information for the size
      const allocation = getSizeAllocation(sizeInfo, sizeInformation)

      sizeGroups[sizeInfo.group].activeSizes.push({
        ...sizeInfo,
        ...allocation,
        name: sizeName,
        // If a size is in the productSizes array then it has been allocated
        isAllocated: true,
        eventErrorID: getCommitmentError(product, sizeName),
      })
    })
  })

  // Sort active sizes to make sure they are in the correct sequence
  sizeGroups[DEFAULT_GROUP].activeSizes = sizeGroups[DEFAULT_GROUP].activeSizes.sort(
    (a, b) => a.sequence - b.sequence,
  )
  sizeGroups[MINI_GROUP].activeSizes = sizeGroups[MINI_GROUP].activeSizes.sort(
    (a, b) => a.sequence - b.sequence,
  )
  sizeGroups[TEEN_GROUP].activeSizes = sizeGroups[TEEN_GROUP].activeSizes.sort(
    (a, b) => a.sequence - b.sequence,
  )
  sizeGroups[MINI_EXT_GROUP].activeSizes = sizeGroups[MINI_EXT_GROUP].activeSizes.sort(
    (a, b) => a.sequence - b.sequence,
  )

  return sizeGroups
}

export function getSizesForRange(state: AppState): Size[] {
  const rangeSizes = state.injectorUI.modals.sizeModal.rangeSizes

  return rangeSizes
    .filter((size) => !size.historical)
    .map((size) => {
      return {
        ...size,
        group: size.group || DEFAULT_GROUP,
        isAllocated: false,
      }
    })
}

function getCommitmentError(product: Product, sizeName: string): string {
  return product?.eventErrors?.find(
    (eventError) =>
      eventError.code == SIZE_AFTER_READY_TO_BUY_COMMITMENT_ERROR &&
      eventError.data?.some((size) => size == sizeName),
  )?.id
}

function getSizeInformation(size: string, rangeSizes: Size[], infoKey: ProductSizeGroups): Size {
  const sizeInfoArray =
    rangeSizes.filter((x) => {
      return x.name === size && x.group === infoKey
    }) || []
  const sizeInfo = sizeInfoArray[0] || {}

  return sizeInfo as Size
}

function getSizeAllocation(sizeInfo: Size, sizeInformation: AllocatedSizeGroups): AllocatedSize {
  const allocatedSizeSkuGroup = sizeInformation[sizeInfo.group] || []
  const allocations =
    allocatedSizeSkuGroup.filter((x) => {
      return x.name === sizeInfo.name
    }) || []
  const allocation = allocations[0] || {}

  return allocation as AllocatedSize
}
