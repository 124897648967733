import { connect } from 'react-redux'
import { GridType, IntentionalAny, TreeHierarchyItem } from 'src/types/index'
import { AppState } from 'src/reducers/root'
import { updateExpandedHierarchyNodes } from 'src/actions/grid/hierarchy'
import { Hierarchy } from './Hierarchy'
import {
  getDepartmentsWithChildren,
  getExpandedHierarchyNodes,
  getHierarchy,
} from 'src/selectors/hierarchy'
import { referenceDataStatus } from '../../selectors/referenceData/status'
import { isAdmin } from '../../selectors/userProfile'
import { DepartmentsWithChildrenState } from 'reducers/referenceData/hierarchy/departmentsWithChildren'

interface PassedProps {
  gridName: GridType
  onNodeSelected: (node: TreeHierarchyItem) => void
  nodeIsSelectable: (node: TreeHierarchyItem) => boolean
  onClose?: () => void
}

interface StateProps {
  hierarchy: TreeHierarchyItem[]
  status: string
  departmentsWithChildren: DepartmentsWithChildrenState
  expandedNodes: string[]
  isAdmin: boolean
}

interface DispatchProps {
  updateExpandedHierarchyNodes: (nodes: string[]) => void
}

export interface HierarchyProps extends PassedProps, StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  hierarchy: getHierarchy(state),
  status: referenceDataStatus(state),
  departmentsWithChildren: getDepartmentsWithChildren(state),
  expandedNodes: getExpandedHierarchyNodes(state),
  isAdmin: isAdmin(state),
})

const mapDispatchToProps = (
  dispatch: IntentionalAny,
  { gridName }: PassedProps,
): DispatchProps => ({
  updateExpandedHierarchyNodes: (nodes: string[]): void =>
    dispatch(updateExpandedHierarchyNodes(nodes, gridName)),
})

export const HierarchyContainer = connect(mapStateToProps, mapDispatchToProps)(Hierarchy)
