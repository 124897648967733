import React from 'react'
import { SideList } from 'src/components/SideList/SideList'
import { Tool } from './Tool/Tool'
import { ToolType } from 'src/types/index'
import { ADMIN_PATH, TOOLS_LIST } from 'src/constants/sideList'
import { toolsContainer } from './Tools.scss'

type Props = {
  tools: ToolType[]
  selectedToolKey?: string
}

export const Tools = ({ tools, selectedToolKey }: Props): JSX.Element => {
  const inTools = tools.filter((el) => el.key === selectedToolKey).length !== 0

  return (
    <div className={toolsContainer} data-cy="tools-container">
      <SideList list={tools} type={TOOLS_LIST} rootPath={ADMIN_PATH} />
      {selectedToolKey !== null && inTools && <Tool selectedToolKey={selectedToolKey || ''} />}
    </div>
  )
}
