import { GridType } from 'src/types/index'
import { DropdownOption } from 'types/Dropdowns'

export type HiddenColumnsActions = ReturnType<typeof updateHiddenColumns>

export const UPDATE_HIDDEN_COLUMNS = 'UPDATE_HIDDEN_COLUMNS'

export const updateHiddenColumns = (columns: DropdownOption[], grid: GridType) =>
  <const>{
    type: UPDATE_HIDDEN_COLUMNS,
    columns,
    grid,
  }
