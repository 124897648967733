import { connect } from 'react-redux'
import { AppState } from 'src/reducers/root'
import { getAttributesMap } from '../../../selectors/referenceData/attributes'
import MultiCurrencyCellRenderer from './MultiCurrencyCellRenderer'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  currencies: getAttributesMap(state)[ownProps.accessor]
    ? getAttributesMap(state)[ownProps.accessor].currencies
    : [],
})

export const MultiCurrencyCellRendererContainer =
  connect(mapStateToProps)(MultiCurrencyCellRenderer)
