import { AttributeValue, AttributeValues } from 'src/types/index'

export const listApiToStateMapper = (
  apiValue: any,
  apiName: string,
  attributeValues: AttributeValues,
) => toValue(apiValue, attributeValues[apiName])

export const multiListApiToStateMapper = (
  apiValue: any,
  apiName: string,
  attributeValues: AttributeValues,
) => (Array.isArray(apiValue) ? apiValue.map((v) => toValue(v, attributeValues[apiName])) : [])

export const booleanApiToStateMapper = (apiValue: any) => !!apiValue

export const currencyApiToStateMapper = (apiValue: any) => apiValue

export const defaultApiToStateMapper = (apiValue: any) => apiValue

export const nullApiToStateMapper = () => null

const toValue = (apiValue: any, attributeValue: AttributeValue[]) => {
  const maybeFound = attributeValue
    ? attributeValue.find(({ slug }) => slug === apiValue)
    : undefined
  return maybeFound ? maybeFound.value : apiValue
}
