import { ColumnValue } from 'src/types/index'

export const UPDATE_COLUMNS_ORDER = 'UPDATE_COLUMNS_ORDER'
export type UpdateColumnsOrderAction = ReturnType<typeof updateColumnsOrder>

export const updateColumnsOrder = (orderedColumns: string[], actionColumn?: ColumnValue) =>
  <const>{
    type: UPDATE_COLUMNS_ORDER,
    payload: {
      columns: orderedColumns,
      actionColumn: actionColumn,
    },
  }
