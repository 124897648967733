import React from 'react'
import { textContainer } from './HierarchyCellRenderer.scss'

type Props = {
  title: string
  levelCode: string
}

export const HierarchyCellRenderer = ({ title, levelCode }: Props): JSX.Element => (
  <div className={textContainer}>
    <span data-cy="hierarchy-cell-renderer-span">{title}</span>
    <span data-cy="hierarchy-cell-renderer-span">{levelCode}</span>
  </div>
)
