import React from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import { setFocus } from 'src/service/ref'
import { ComponentSize } from 'src/types/ComponentSizes'
import { DropdownCustomStyles, DropdownOption } from 'src/types/Dropdowns'
import {
  deprecatedValue,
  encapsulatedSimpleDropdown,
  encapsulatedSimpleDropdownMedium,
  encapsulatedSimpleDropdownRounded,
  encapsulatedSimpleDropdownSmall,
} from './SimpleDropdown.scss'
import { IntentionalAny } from 'types'

type Props = DropdownCustomStyles & {
  name: string
  options: DropdownOption[]
  onChange?: (option: DropdownOption, name: string) => void
  onChangeMulti?: (options: DropdownOption[], name: string) => void
  header?: string
  required?: boolean
  selectedOption?: DropdownOption
  selectedOptionMulti?: DropdownOption[]
  hasFocus?: boolean
  size?: ComponentSize
  rounded?: boolean
  multi?: boolean
  onBlur?: (
    payload: IntentionalAny[],
    isValid: IntentionalAny,
    previousPayload: IntentionalAny,
  ) => void
}

const getSizeClass = (size?: ComponentSize): string => {
  switch (size) {
    case 'small':
      return encapsulatedSimpleDropdownSmall
    case 'medium':
      return encapsulatedSimpleDropdownMedium
    default:
      return encapsulatedSimpleDropdownMedium
  }
}

const getRoundedClass = (rounded: boolean): string =>
  rounded ? encapsulatedSimpleDropdownRounded : ''

export class SimpleDropdown extends React.Component<Props> {
  ref: IntentionalAny

  static defaultProps = {
    placeholder: '',
    onBlurResetsInput: false,
    clearable: false,
    arrowRenderer: null,
    openOnFocus: true,
    tabSelectsValue: false,
    inputProps: { autoComplete: 'off' },
  }

  componentDidMount(): void {
    if (this.props.hasFocus) {
      setFocus(this.ref)
    }
  }

  componentDidUpdate = (prevProps: Props): void => {
    if (prevProps.hasFocus !== this.props.hasFocus) {
      if (prevProps.hasFocus) {
        setFocus(this.ref)
      }
    }
  }

  onChange = (selectedOption: DropdownOption): void => {
    this.props.onChange && this.props.onChange(selectedOption, this.props.name)
  }

  onChangeMulti = (selectedOptionMulti: DropdownOption[]): void => {
    const listValue = selectedOptionMulti || []
    this.props.onChangeMulti && this.props.onChangeMulti(listValue, this.props.name)
  }

  render(): JSX.Element {
    const {
      name,
      options,
      header,
      required,
      selectedOption,
      selectedOptionMulti,
      multi,
      size,
      rounded,
      ...rest
    } = this.props

    const defaultFilterOption = ({ label }: DropdownOption, searchString: string): boolean =>
      label.toUpperCase().includes(searchString.toUpperCase())

    const renderValue = (option: DropdownOption): JSX.Element => {
      const deprecatedClass = classNames({
        [deprecatedValue]: option && option.deprecated,
      })

      return <div className={deprecatedClass}>{option.label}</div>
    }

    const multiCustomStyles = {
      control: (provided: IntentionalAny): IntentionalAny => ({
        ...provided,
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
        color: '#222',
        marginBottom: '1.25rem',
        height: '6rem',
        overflowY: 'scroll',
        ...(this.props.customControlStyles ? this.props.customControlStyles : {}),
      }),
      option: (provided: IntentionalAny, state: { isFocused: IntentionalAny }): IntentionalAny => ({
        ...provided,
        color: '#666',
        backgroundColor: state.isFocused ? '#e6e6e6' : '#ffffff',
        '&:active': {
          backgroundColor: '#e6e6e6',
        },
      }),
      menu: (provided: IntentionalAny): IntentionalAny => ({
        ...provided,
        margin: 0,
        borderRadius: 0,
      }),
      menuList: (provided: IntentionalAny): IntentionalAny => ({
        ...provided,
        padding: 0,
      }),
      multiValueRemove: (
        provided: IntentionalAny,
        state: { isFocused: IntentionalAny },
      ): IntentionalAny => ({
        ...provided,
        '&:hover': {
          backgroundColor: '#c7c7c7',
          color: '#383939',
        },
        backgroundColor: state.isFocused ? '#c7c7c7' : provided.backgroundColor,
        color: state.isFocused ? '#383939' : provided.color,
      }),
    }

    const singleCustomStyles = {
      control: (
        provided: IntentionalAny,
        state: { isFocused: IntentionalAny },
      ): IntentionalAny => ({
        ...provided,
        borderColor: state.isFocused ? '#26B3AD' : provided.borderColor,
        boxShadow: 'none',
        height: '2.5rem',
        '&:hover': {
          borderColor: '#26B3AD',
        },
        input: {
          height: 'unset',
        },
        ...(this.props.customControlStyles ? this.props.customControlStyles : {}),
      }),
      option: (
        provided: IntentionalAny,
        state: { isFocused: IntentionalAny; isSelected: IntentionalAny },
      ): IntentionalAny => ({
        ...provided,
        color: '#666',
        backgroundColor: state.isFocused ? '#e6e6e6' : state.isSelected ? '#eef0ef' : '#ffffff',
        minHeight: '40px',
        '&:active': {
          backgroundColor: '#e6e6e6',
        },
      }),
      menu: (provided: IntentionalAny): IntentionalAny => ({
        ...provided,
        margin: 0,
        borderRadius: 0,
      }),
      menuList: (provided: IntentionalAny): IntentionalAny => ({
        ...provided,
        padding: 0,
      }),
    }

    return (
      <div
        data-cy={`simple-dropdown-${name}`}
        className={`${encapsulatedSimpleDropdown} ${name} ${getSizeClass(size)} ${getRoundedClass(
          !!rounded,
        )}`}
      >
        {header && <label htmlFor={name}>{required ? `${header} *` : header}</label>}
        {/* @ts-ignore */}
        <Select
          {...rest}
          ref={(ref): void => {
            this.ref = ref
          }}
          name={name}
          isMulti={multi}
          options={options}
          styles={multi ? multiCustomStyles : singleCustomStyles}
          components={{
            DropdownIndicator: null,
            ClearIndicator: null,
            LoadingIndicator: null,
          }}
          onChange={multi ? this.onChangeMulti : this.onChange}
          value={multi ? selectedOptionMulti : selectedOption}
          valueRenderer={renderValue}
          openMenuOnFocus
          filterOption={defaultFilterOption}
          menuPlacement="auto"
          autosize
        />
      </div>
    )
  }
}
