import {
  ApiProduct,
  Attribute,
  AttributeValues,
  Country,
  IntentionalAny,
  Product,
} from 'src/types/index'
import omit from 'lodash/omit'
import * as ProductStatuses from 'src/constants/productStatus'
import {
  ApiProductPrice,
  LinkGroups,
  OptionalString,
  ProductPrice,
  ProductSupplier,
} from 'src/types/Product'
import { ProductStatus } from 'src/types/ProductStatus'
import { strategyRegistryLookup } from '../../registry/behaviourRegistry'
import * as Columns from '../../constants/columns'
import { Image, ImageTypes } from 'src/types/Images'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { PRICE_MULTIPLIER } from '../../constants/price'
import {
  ERROR_CODE_WHOLESALE_IMAGE_NOT_SET,
  ERROR_CODE_WHOLESALE_READY_WEEK_NO_ACTIVE_SKUS,
  ERROR_CODE_WHOLESALE_READY_WEEK_NO_SELLING_PRICE,
  ERROR_CODE_WHOLESALE_READY_WEEK_SELLING_PERIOD,
} from 'constants/errorCodes'

type NormalisedProductMap = {
  [key: string]: Product
}

type NormalisedProducts = {
  slugs: string[]
  productsMap: NormalisedProductMap
}

export const normaliseProducts = (products: Array<Product>): NormalisedProducts => ({
  slugs: products.map((product) => product.slug),
  productsMap: products.reduce((acc: NormalisedProductMap, product) => {
    acc[product.slug] = product
    return acc
  }, {}),
})

export const createImage = (type: ImageTypes, url?: string): Image => ({
  type,
  url: url || '',
})

export const createImages = (
  images: Array<{ type: ImageTypes; url?: string }>,
): {
  type: ImageTypes
  url: string
}[] =>
  images.reduce(
    (acc: Array<{ type: ImageTypes; url: string }>, image) =>
      image.url && image.url.trim() !== '' ? [...acc, { type: image.type, url: image.url }] : acc,
    [],
  )

const removeUnwantedKeys = <T extends Record<string, unknown>>(
  product: T,
): Record<string, unknown> =>
  omit<T>(product, [
    Columns.SKETCH_URL_COLUMN,
    Columns.SAMPLE_URL_COLUMN,
    Columns.THUMBNAIL_URL_COLUMN,
  ])

const mapApiSupplierToSupplier = (apiProduct: ApiProduct): ProductSupplier | null | undefined => {
  if (!apiProduct.supplierCode) {
    return null
  }
  return {
    description: apiProduct.supplierDescription,
    code: apiProduct.supplierCode,
  }
}

const mapApiPricesToPrices = (apiProductPrice: ApiProductPrice): ProductPrice => {
  const productPrice: ProductPrice = {}
  if (apiProductPrice) {
    Object.keys(apiProductPrice).forEach((sizeGroup) => {
      const sizeGroupKey = sizeGroup as ProductSizeGroups
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const price = apiProductPrice[sizeGroupKey]! / PRICE_MULTIPLIER || 0
      productPrice[sizeGroupKey] = price.toFixed(2)
    })
  }
  return productPrice
}

const statusBeforeCancelledFrom = (status: ProductStatus): ProductStatus | undefined => {
  switch (status) {
    case ProductStatuses.CANCELLED_IN_DEVELOPMENT:
      return ProductStatuses.DEVELOPMENT
    case ProductStatuses.CANCELLED_IN_PROPOSED:
      return ProductStatuses.PROPOSED
    case ProductStatuses.CANCELLED_IN_READY_TO_BUY:
      return ProductStatuses.READY_TO_BUY
    default:
      return undefined
  }
}

const statusMapper: Record<ProductStatus, string> = {
  [ProductStatuses.PENDING_ODBMS]: ProductStatuses.PENDING_ODBMS,
  [ProductStatuses.READY_TO_BUY]: ProductStatuses.READY_TO_BUY,
  [ProductStatuses.DEVELOPMENT]: ProductStatuses.DEVELOPMENT,
  [ProductStatuses.PROPOSED]: ProductStatuses.PROPOSED,
  [ProductStatuses.PROPOSED_PENDING_FOR_RTB]: ProductStatuses.PROPOSED_PENDING_FOR_RTB,
  [ProductStatuses.PENDING_RTB_SIZES]: ProductStatuses.PENDING_RTB_SIZES,
  [ProductStatuses.RTB_PENDING_CANCELLATION]: ProductStatuses.RTB_PENDING_CANCELLATION,
  [ProductStatuses.CANCELLED]: ProductStatuses.CANCELLED,
  [ProductStatuses.CANCELLED_IN_DEVELOPMENT]: ProductStatuses.CANCELLED,
  [ProductStatuses.CANCELLED_IN_PROPOSED]: ProductStatuses.CANCELLED,
  [ProductStatuses.CANCELLED_IN_READY_TO_BUY]: ProductStatuses.CANCELLED,
}

const apiAttributesToAttributes = (
  { attributes = {} }: ApiProduct,
  attributesList: Attribute[],
  attributeValues: AttributeValues,
): Record<string, IntentionalAny> =>
  Object.entries(removeUnwantedKeys(attributes)).reduce(
    (acc: Record<string, IntentionalAny>, [apiName, apiValue]) => {
      const maybeType = attributesList.find(({ name }) => name === apiName)
      const type = maybeType ? maybeType.type : 'error'

      acc[apiName] = strategyRegistryLookup(type).apiToStateMapper(
        apiValue,
        apiName,
        attributeValues,
      )
      return acc
    },
    {},
  )

const mapGroups = (aipProduct: ApiProduct): LinkGroups => aipProduct.groups

const findCountryBy = (countryCode: OptionalString, countries: Country[]): Country =>
  countries && countries.find(({ code }) => countryCode === code)

const apiProductToProduct = (
  apiProduct: ApiProduct,
  attributes: Attribute[],
  attributeValues: AttributeValues,
  countries: Country[],
): Product => {
  const apiAttributesToAttributesResult = apiAttributesToAttributes(
    apiProduct,
    attributes,
    attributeValues,
  )
  const dynamicAttributesNames: string[] = []
  for (const apiAttributesToAttributesResultKey in apiAttributesToAttributesResult) {
    dynamicAttributesNames.push(apiAttributesToAttributesResultKey)
  }

  const product = {
    ...apiAttributesToAttributesResult,
    productDescription: apiAttributesToAttributesResult.productDescription,
    styleDescription: apiProduct.styleDescription,
    slug: apiProduct.slug,
    countryCode: findCountryBy(apiProduct.countryCode, countries),
    seasonCode: apiProduct.seasonCode,
    optionSlug: apiProduct.optionSlug,
    styleSlug: apiProduct.styleSlug,
    hasSupplierLink: apiProduct.hasSupplierLink,
    hasColourLink: apiProduct.hasColourLink,
    hierarchySlug: apiProduct.hierarchySlug,
    productNumber: apiProduct.productNumber ? apiProduct.productNumber : [],
    productNumbers: apiProduct.productNumbers,
    developmentId: apiProduct.developmentId,
    styleID: apiProduct.styleID,
    status: statusMapper[apiProduct.status],
    statusBeforeCancelled: statusBeforeCancelledFrom(apiProduct.status),
    commentCount: apiProduct.commentCount,
    userName: apiProduct.userName,
    createdDateDevelopment: apiProduct.createdDateDevelopment,
    supplier: mapApiSupplierToSupplier(apiProduct),
    images: createImages([
      createImage('sketch', apiProduct.attributes.sketchUrl),
      createImage('sample', apiProduct.attributes.sampleUrl),
    ]),
    currentFullPrice: apiProduct.currentFullPrice / PRICE_MULTIPLIER,
    promoPrice: apiProduct.promoPrice / PRICE_MULTIPLIER,
    sellingPrice: apiProduct.sellingPrice,
    hasOptionLibrary: apiProduct.hasOptionLibrary,
    eventErrors: apiProduct.eventErrors,
    groups: mapGroups(apiProduct),
    sizesSpan: apiProduct.sizesSpan || {
      minSeqSizeName: null,
      maxSeqSizeName: null,
    },
    wsReadyWeek: apiProduct.wsReadyWeek || '',
    supplierCostCurrency: apiProduct.supplierCostCurrency,
    supplierCostView: mapApiPricesToPrices(apiProduct.supplierCostView),
    sellingPriceView: mapApiPricesToPrices(apiProduct.sellingPriceView),
    promoPriceView: mapApiPricesToPrices(apiProduct.promoPriceView),
    landedCostView: mapApiPricesToPrices(apiProduct.landedCostView),
    currentFullPriceView: mapApiPricesToPrices(apiProduct.currentFullPriceView),
    productNumberView: apiProduct.productNumberView,
    dynamicAttributesNames: dynamicAttributesNames,
    markdown: apiProduct.markdown,
    wsPrimaryThumbnail: apiProduct.wsPrimaryThumbnail
      ? `${apiProduct.wsPrimaryThumbnail}?${new Date().getTime()}`
      : '',
  }

  return removeUnwantedKeys(product) as Product
}

export const apiProductToProductWithDefaults = (
  product: ApiProduct,
  attributes: Attribute[],
  attributeValues: AttributeValues,
  countries: Country[],
): Product => apiProductToProduct(product, attributes, attributeValues, countries)

export const getProductPatchErrorMessage = (code: string | undefined): string => {
  const errorMap: Record<string, string> = {
    [ERROR_CODE_WHOLESALE_IMAGE_NOT_SET]:
      'At least one Gallery Image must be uploaded before entering WS Ready Week',
    [ERROR_CODE_WHOLESALE_READY_WEEK_SELLING_PERIOD]:
      'WS Ready week cannot be entered until selling period has been added',
    [ERROR_CODE_WHOLESALE_READY_WEEK_NO_ACTIVE_SKUS]:
      'WS Ready week cannot be entered until sizes and selling prices have been added',
    [ERROR_CODE_WHOLESALE_READY_WEEK_NO_SELLING_PRICE]:
      'WS Ready week cannot be entered until sizes and selling prices have been added',
  }
  return code ? errorMap[code] : ''
}
