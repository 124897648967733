import { columnFreeze } from '../react-table.scss'

export const syncTableHeadersScrollPositionWithGrid = (scrollLeftPosition: number) => {
  const nonFrozenTds = [...document.querySelectorAll('.rt-th')].filter(
    (element) => !element.classList.contains(columnFreeze),
  )
  nonFrozenTds.forEach((element) => {
    // @ts-ignore
    element.style.left = -scrollLeftPosition + 'px'
  })
}
