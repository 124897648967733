import moment from 'moment'

import { getStore } from 'src/store/store'
import { setLockSuccessAction, LOCKED } from '../../../actions/lock'
import { Response } from './root'
import * as sentry from '../../../helpers/sentry'

export const LOCKED_HTTP_STATUS_CODE = 423
export const LOCKED_STATUS_CODE = 16

export const apiLockMiddleware = (response: Response) => {
  if (response.status !== LOCKED_HTTP_STATUS_CODE) {
    return
  }

  if (response.data?.error?.code !== LOCKED_STATUS_CODE) {
    return
  }

  const store = getStore()
  store.dispatch(setLockSuccessAction(LOCKED, moment().format()))

  sentry.addBreadcrumb('lock', 'API Lock Set')
  sentry.setTag('apiLocked', 'true')
}
