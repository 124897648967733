import { UPDATE_COLUMNS_ORDER } from 'src/actions/referenceData/columns'
import { call, takeEvery, all } from 'redux-saga/effects'
import { injectorService } from 'src/service/injector/injectorService'
import { errorAndNotification } from './error'
import { APIError } from 'src/types/Api'
import { SVPAction } from 'actions/svp'

export function* updateColumnsOrderSaga(action: SVPAction<typeof UPDATE_COLUMNS_ORDER>) {
  try {
    const { actionColumn, columns } = action.payload
    yield call(injectorService.put, 'orders/master', [
      actionColumn && actionColumn.slug,
      ...columns,
    ])
  } catch (error) {
    yield* errorAndNotification(
      error as APIError,
      'Sorry your column ordering has failed to save. Please retry',
    )
  }
}

export default function* rootSaga() {
  yield all([takeEvery(UPDATE_COLUMNS_ORDER, updateColumnsOrderSaga)])
}
