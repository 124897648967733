import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import CreateProductPageContainer from '../../../createProduct/CreateProductPageContainer'
import { productsPage } from 'src/components/GridAndControls/GridAndControls.scss'
import CreateSketchPanelContainer from '../../../createProduct/sketch/CreateSketchPanelContainer'
import { DEV_TRACKER } from 'src/types/index'
import { DevTrackerGridContainer } from './DevTrackerGrid/DevTrackerGridContainer'

interface CreateProductRouteProps {
  productSlug: string
  developmentId: string
}

export const DevTrackerRoutes = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <div id="ViewIdeas" className={productsPage}>
      <Switch>
        <Route
          path={`${match.url}/create/sketch/:productSlug/:developmentId`}
          render={(props: RouteComponentProps): JSX.Element => (
            <CreateSketchPanelContainer
              productSlug={
                (props as RouteComponentProps<CreateProductRouteProps>).match.params.productSlug
              }
              developmentId={
                (props as RouteComponentProps<CreateProductRouteProps>).match.params.developmentId
              }
            />
          )}
        />
        <Route
          path={`${match.url}/create`}
          render={(): JSX.Element => <CreateProductPageContainer />}
        />
        <Route
          path={`${match.url}`}
          render={(): JSX.Element => <DevTrackerGridContainer gridName={DEV_TRACKER} />}
        />
      </Switch>
    </div>
  )
}
