import { connect } from 'react-redux'

import { StyleModal } from './StyleModal'
import { closeStyleModal, setViewResults } from 'src/actions/grid/modal/styleModal'
import { AppState } from 'src/reducers/root'
import { getModalViewResults } from 'src/selectors/modals/styleModal'
import { getHierarchyForProduct } from 'src/selectors/hierarchy'
import { showErrorNotification } from 'src/actions/notification'
import {
  saveStyleDescription,
  searchStyle,
  confirmProductStyle,
  fetchStyle,
} from 'actions/grid/style'
import { getStyle } from 'selectors/style'
import { Product } from 'types/Product'
import { FlatHierarchyItem } from 'types'
import { StyleWithStatusState } from 'reducers/style'
import { StyleResult } from 'types/Style'

interface PassedProps {
  product: Product
  isConfirmStyleRequesting: boolean
  isSearchStyleLoading: boolean
  styleResults: StyleResult[]
}

interface DispatchProps {
  onClose: typeof closeStyleModal
  showErrorNotification: typeof showErrorNotification
  saveStyleDescription: typeof saveStyleDescription
  searchStyle: typeof searchStyle
  confirmProductStyle: typeof confirmProductStyle
  fetchStyle: typeof fetchStyle
  setViewResults: typeof setViewResults
}

interface StateProps {
  hierarchy: FlatHierarchyItem
  style?: StyleWithStatusState
  viewResults: boolean
}

export interface StyleModalProps extends PassedProps, StateProps, DispatchProps {}

const mapDispatchToProps: DispatchProps = {
  onClose: closeStyleModal,
  showErrorNotification,
  saveStyleDescription,
  searchStyle,
  confirmProductStyle,
  fetchStyle,
  setViewResults,
}

const mapStateToProps = (state: AppState, ownProps: PassedProps): StateProps => {
  return {
    hierarchy: getHierarchyForProduct(state, ownProps.product.hierarchySlug),
    style: getStyle(state),
    viewResults: getModalViewResults(state),
  }
}

export const StyleModalContainer = connect(mapStateToProps, mapDispatchToProps)(StyleModal)
