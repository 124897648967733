import { AllEffect, CallEffect, all, call, put, select, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { getAttributes, getAttributeValues } from 'src/selectors/referenceData/attributes'
import { getColumnRenderSpecs } from 'src/selectors/referenceData/columns'
import { injectorService } from 'src/service/injector/injectorService'
import { apiProductToProductWithDefaults } from 'src/service/mappers'
import { clearAndSelectHierarchy } from 'src/actions/grid/hierarchy'
import { addHighlightedRow } from 'src/actions/grid/highlightedRows'
import { errorAndNotification } from '../error'
import { runProductValidationsSaga } from './validate'
import { DEVELOPMENT } from 'src/constants/productStatus'
import {
  ApiProduct,
  Attribute,
  AttributeValueMap,
  Country,
  DepartmentSpecs,
  DEV_TRACKER,
  GridType,
  OPTION_LIBRARY,
  Product,
  RenderSpec,
} from 'src/types/index'
import {
  displayCopyToNavigationChoice,
  GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER,
  closeCopyToNavigation,
  COPY_PRODUCT_TO_HIERARCHY,
} from 'src/actions/grid/product/copy'
import { getDepartmentSpecsForGrid } from 'src/selectors/departmentSpecs'
import { getDepartmentsWithChildren } from 'src/selectors/hierarchy'
import { getDepartmentByHierarchy } from 'src/service/hierarchy/utils'
import { getCountries } from 'src/selectors/referenceData/countries'

import { COPY_TO_ENDPOINT } from 'src/constants/apiUrls'
import { trackEvent } from 'src/service/analytics/analytics'
import { DepartmentsWithChildrenState } from 'reducers/referenceData/hierarchy/departmentsWithChildren'
import { APIError } from 'types/Api'
import { reloadProductSaga } from './reload'
import { lockProduct, unlockProduct } from 'actions/grid/product/update'
import { SVPAction } from 'actions/svp'
import { fetchFirstProductsPage } from 'actions/grid/product/fetch'

const VALIDATION_ERROR_MESSAGE = 'Please amend the errors in the grid.'

const errorMessageFor = (errorType: string): string =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

const COPY_PRODUCT_TO_HIERARCHY_ERROR_MESSAGE = errorMessageFor('copy product for you')

export function* addLinksToCopiedProduct(
  link: boolean,
  sourceProduct: Product,
  newProduct: ApiProduct,
  grid: GridType,
): Generator<CallEffect> {
  if (link) {
    if (sourceProduct.groups?.crossDepartmentGroupSlug) {
      yield call(
        injectorService.patch,
        `products/${sourceProduct.slug}/cross-department-products`,
        {
          productToAssociate: newProduct.slug,
        },
      )
    } else {
      yield call(injectorService.post, 'cross-department-products', [
        sourceProduct.slug,
        newProduct.slug,
      ])
      if (grid === OPTION_LIBRARY) {
        yield call(reloadProductSaga, sourceProduct.slug, OPTION_LIBRARY)
      }
    }
  }
}

export function* copyProductToHierarchySaga(action: SVPAction<typeof COPY_PRODUCT_TO_HIERARCHY>) {
  const { grid, payload } = action
  const { sourceProduct, hierarchySlug, email, inheritStyle } = payload

  const departmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecsForGrid)
  const departmentsWithChildren: DepartmentsWithChildrenState = yield select(
    getDepartmentsWithChildren,
  )
  const productDepartment = getDepartmentByHierarchy(
    sourceProduct.hierarchySlug,
    departmentsWithChildren,
  )

  const rules = departmentSpecs[productDepartment].rules

  const attributeValues: AttributeValueMap = yield select(getAttributeValues)
  const columnSpecs: RenderSpec[] = yield select(getColumnRenderSpecs)

  const invalidFields: string[] = yield call(
    runProductValidationsSaga,
    sourceProduct,
    rules,
    columnSpecs,
    DEVELOPMENT,
    grid,
    { acceptDeprecatedValues: true },
  )

  if (invalidFields.length > 0) {
    yield call(errorAndNotification, '', VALIDATION_ERROR_MESSAGE)
    return
  }

  trackEvent('product', 'Product Copied To Hierachy', hierarchySlug)

  try {
    const requestPayload = {
      sourceSlug: sourceProduct.slug,
      newHierarchySlug: hierarchySlug,
      email,
      inheritStyle,
    }

    yield put(lockProduct(sourceProduct.slug, grid))

    const newApiProduct: ApiProduct = yield call(
      injectorService.post,
      COPY_TO_ENDPOINT,
      requestPayload,
    )

    yield call(
      addLinksToCopiedProduct,
      payload.crossDepartmentLink,
      sourceProduct,
      newApiProduct,
      grid,
    )

    yield put(unlockProduct(sourceProduct.slug, grid))

    const attributes: Attribute[] = yield select(getAttributes)
    const countries: Country[] = yield select(getCountries)
    const newProduct = apiProductToProductWithDefaults(
      newApiProduct,
      attributes,
      attributeValues,
      countries,
    )

    if (grid !== OPTION_LIBRARY) {
      yield put(clearAndSelectHierarchy(hierarchySlug, grid))
      yield put(fetchFirstProductsPage(grid))
      yield put(addHighlightedRow(newProduct.slug, grid))
    } else {
      yield put(
        displayCopyToNavigationChoice(
          hierarchySlug,
          newProduct.slug,
          grid,
          !!sourceProduct.wsPrimaryThumbnail,
        ),
      )
    }
  } catch (error) {
    yield put(unlockProduct(sourceProduct.slug, grid))
    yield* errorAndNotification(error as APIError, COPY_PRODUCT_TO_HIERARCHY_ERROR_MESSAGE)
  }
}

export function* goToCopiedProductInDevTrackerSaga(
  action: SVPAction<typeof GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER>,
) {
  yield put(closeCopyToNavigation())
  yield put(push('/dev-tracker'))
  yield put(clearAndSelectHierarchy(action.hierarchySlug, DEV_TRACKER))
  yield put(fetchFirstProductsPage(DEV_TRACKER))
  yield put(addHighlightedRow(action.productSlug, DEV_TRACKER))
}

export default function* (): Generator<AllEffect> {
  yield all([
    takeEvery(COPY_PRODUCT_TO_HIERARCHY, copyProductToHierarchySaga),
    takeEvery(GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER, goToCopiedProductInDevTrackerSaga),
  ])
}
