import React from 'react'
import classNames from 'classnames'

import ProductsTable from '../ProductsTable'
import ToggleHierarchy from '../ToggleHierarchy/index'
import GridControls from '../GridControls'
import {
  aboveTableControls,
  aboveTableControlsAndTable,
  toggleHierarchyAndSearch,
  devTracker,
  optionLibrary,
} from './GridAndControls.scss'
import { PatchPayload } from 'src/actions/grid/product/update'
import {
  ExportPayload,
  Product,
  SelectedHierarchy,
  View,
  GridType,
  AttributeValueMap,
} from 'src/types/index'
import { SortedColumn } from 'src/actions/grid/sortedColumns'
import { DEV_TRACKER, DepartmentSpecs, RenderSpec } from 'src/types/index'
import { applyColumnFilter } from '../../service/columns'
import { GridViews } from '../../types/DepartmentSpec'
import { PRODUCT_VIEW } from 'constants/views'
import { DropdownOption } from 'types/Dropdowns'
import { ClipboardGridDestination, ClipboardGridSource } from 'types/Clipboard'
import { Filter } from 'actions/grid/exactFilters'

type Props = {
  products: Product[]
  sortedColumns: SortedColumn[]
  isLoading: boolean
  hasError: boolean
  selectedHierarchy: SelectedHierarchy
  invalidProductFields: { [key: string]: string[] }
  deprecatedProductFields: { [key: string]: string[] }
  updateSortedColumns: (columns: SortedColumn[]) => void
  updateProduct: (payload: PatchPayload) => void
  refreshDepartmentSpecs: (department: string) => void
  fetchProducts: () => void
  exportProducts: (payload: ExportPayload) => void
  importProducts: (arg: File | null) => void
  gridName: GridType
  gridViews: GridViews
  departmentsWithChildren: { [key: string]: string[] }
  departmentSpecs: DepartmentSpecs
  columnSpec: RenderSpec[]
  loadNextPage: () => void
  lastPage: number | null | undefined
  lastPageLoaded: number | null | undefined
  pageLoading: boolean
  isGridLocked: boolean
  pollingProductSlugs: Array<string>
  hiddenColumns: DropdownOption[]
  showErrorNotification: (message: string) => void
  attributeValueMap: AttributeValueMap
  setClipboardSource: (product: Product, property: string) => void
  clipboardGridSource: ClipboardGridSource
  setClipboardDestination: (productSlug: string, property: string) => void
  clipboardGridDestination: ClipboardGridDestination
  addExactFilter: (filter: Filter) => void
}

type State = {
  view: View
}

const findProductView = (view: View): boolean => view.displayName === PRODUCT_VIEW

const getProductView = (views: GridViews): View =>
  Object.values(views).find(findProductView) as View

export class GridAndControls extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const view = getProductView(this.props.gridViews)
    this.state = { view }
  }

  static getDerivedStateFromProps(props: Props, state: State): { view: View } {
    const { gridViews } = props
    // this ternary is because react router dom tries to re-render comp when back button is clicked (SVP-525) and can not always work out which view it is in
    const view = gridViews[state.view.slug] ? gridViews[state.view.slug] : state.view

    return { view }
  }

  componentDidMount(): void {
    this.loadProducts()
  }

  render(): JSX.Element {
    const { products, gridName, gridViews, importProducts } = this.props

    const gridClassName = classNames(aboveTableControls, {
      [devTracker]: gridName === DEV_TRACKER,
      [optionLibrary]: gridName !== DEV_TRACKER,
    })

    return (
      <div className={aboveTableControlsAndTable}>
        <div className={gridClassName}>
          <div className={toggleHierarchyAndSearch}>
            <ToggleHierarchy gridName={gridName} />
          </div>
          <GridControls
            views={gridViews}
            gridName={gridName}
            selectedView={this.state.view}
            onViewChange={this.onViewChange}
            onRefresh={this.loadProducts}
            onExport={this.exportProducts}
            importProducts={importProducts}
            exportButtonEnabled={products?.length > 0}
            addExactFilter={this.props.addExactFilter}
          />
        </div>
        <ProductsTable
          {...this.props}
          allViewColumns={this.state.view.columns}
          columns={applyColumnFilter(this.state.view.columns, this.props.hiddenColumns)}
        />
      </div>
    )
  }

  onViewChange = (view: View): void => {
    this.setState({ view })
  }

  loadProducts = (): void => {
    if (this.props.selectedHierarchy) {
      this.props.fetchProducts()
      this.props.refreshDepartmentSpecs(this.props.selectedHierarchy.slug)
    }
  }

  exportProducts = (): void => {
    this.props.exportProducts({
      products: this.props.products,
      columns: this.state.view.columns,
    })
  }
}
