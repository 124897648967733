import React from 'react'
import {
  deprecatedValue,
  filterDrawer,
  filterIcon,
  links,
  search,
  tagContainer,
} from './FancyFilterDrawer.scss'
import { Filter } from 'src/actions/grid/exactFilters'
import { PartialFilter } from 'src/actions/grid/partialFilters'
import { GridType, RenderSpec, SelectedHierarchy } from 'src/types/index'
import { SearchBar } from './SearchBar/SearchBar'
import { GridColumnSpec } from '../ProductsTable/ProductsTable'
import { FilterIcon } from '../../assets/icons'
import { FilterTag } from './FilterTag/FilterTag'
import { DropdownOption } from 'src/types/Dropdowns'
import { ColumnDisplayContainer } from 'components/ColumnDisplay/ColumnDisplayContainer'
import { PRICE_STATUS } from 'constants/price'

type Props = {
  filters: Filter[]
  partialFilters: PartialFilter[]
  uniqueValues: {
    [key: string]: DropdownOption[]
  }
  removeExactFilter: (filter: Filter) => void
  removePartialFilter: (filter: PartialFilter) => void
  clearAllFilters: () => void
  selectedHierarchy: SelectedHierarchy
  columns: GridColumnSpec[]
  allColumns: GridColumnSpec[]
  gridName: GridType
  fetchUniqueValues: (property: string, gridName: GridType) => void
  addExactFilter: (filter: Filter) => void
  addPartialFilter: (filter: PartialFilter) => void
}

type State = {
  selectedColumn?: RenderSpec
}

export class FancyFilterDrawer extends React.Component<Props, State> {
  state = {
    selectedColumn: undefined,
  }

  updateColumn = (column: RenderSpec): void => {
    this.setState({ selectedColumn: column })
    this.updateFilterValues(column)
  }

  removeExactFilterFn = (filter: Filter) => (): void => {
    this.props.removeExactFilter(filter)
    this.updateFilterValues(this.state.selectedColumn)
  }

  removePartialFilterFn = (filter: PartialFilter) => (): void => {
    this.props.removePartialFilter(filter)
    this.updateFilterValues(this.state.selectedColumn)
  }

  updateFilterValues = (column?: RenderSpec): void => {
    if (column && column.filterType === 'exact') {
      this.props.fetchUniqueValues(column.property, this.props.gridName)
    }
  }

  onClearAllFilters = (): void => {
    this.setState({
      selectedColumn: undefined,
    })
    this.props.clearAllFilters()
  }

  render(): JSX.Element {
    const {
      filters,
      partialFilters,
      selectedHierarchy,
      columns,
      gridName,
      fetchUniqueValues,
      uniqueValues,
      addExactFilter,
      addPartialFilter,
      allColumns,
    } = this.props

    return selectedHierarchy ? (
      <div id="filterDrawer" className={filterDrawer}>
        <div className={links}>
          <div className={search}>
            <FilterIcon className={filterIcon} />
            <SearchBar
              columns={columns}
              gridName={gridName}
              fetchUniqueValues={fetchUniqueValues}
              uniqueValues={uniqueValues}
              addExactFilter={addExactFilter}
              addPartialFilter={addPartialFilter}
              existingExactFilters={filters}
              existingPartialFilters={partialFilters}
              clearAllFilters={this.onClearAllFilters}
              onColumnSelected={this.updateColumn}
              selectedColumn={this.state.selectedColumn || undefined}
            />
          </div>
          <ColumnDisplayContainer gridName={gridName} columns={allColumns} />
        </div>
        {(filters.length > 0 || partialFilters.length > 0) && (
          <div className={tagContainer}>
            {filters.map(
              (filter, index) =>
                filter.property !== PRICE_STATUS && (
                  <FilterTag
                    key={index}
                    filterKey={filter.type}
                    filterValue={filter.label}
                    deprecatedClassName={`${filter.deprecated ? deprecatedValue : ''}`}
                    onClose={this.removeExactFilterFn(filter)}
                  />
                ),
            )}
            {partialFilters.map((filter, index) => (
              <FilterTag
                key={index}
                filterKey={filter.displayName}
                filterValue={filter.value}
                onClose={this.removePartialFilterFn(filter)}
              />
            ))}
          </div>
        )}
      </div>
    ) : null
  }
}
