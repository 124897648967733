import React from 'react'
import { cancelled, development, proposed, readyToBuy, statusText } from './ProductStatusLabel.scss'
import { ProductStatus } from 'src/types/ProductStatus'
import * as ProductStatuses from 'src/constants/productStatus'

interface Props {
  status: ProductStatus
}

export const statusClassMap: Record<ProductStatus, string> = {
  [ProductStatuses.DEVELOPMENT]: development,
  [ProductStatuses.PROPOSED]: proposed,
  [ProductStatuses.READY_TO_BUY]: readyToBuy,
  [ProductStatuses.CANCELLED]: cancelled,
  [ProductStatuses.CANCELLED_IN_DEVELOPMENT]: cancelled,
  [ProductStatuses.CANCELLED_IN_PROPOSED]: cancelled,
  [ProductStatuses.CANCELLED_IN_READY_TO_BUY]: cancelled,
  [ProductStatuses.PROPOSED_PENDING_FOR_RTB]: proposed,
  [ProductStatuses.RTB_PENDING_CANCELLATION]: proposed,
  [ProductStatuses.PENDING_RTB_SIZES]: proposed,
  [ProductStatuses.PENDING_ODBMS]: '',
}

export const overwriteStatusDisplayValues: Record<string, string> = {
  [ProductStatuses.PROPOSED_PENDING_FOR_RTB]: 'Pending RTB',
  [ProductStatuses.RTB_PENDING_CANCELLATION]: 'Pending Cancel',
}

export const ProductStatusLabel = ({ status }: Props): JSX.Element => (
  <p className={`${statusText} ${statusClassMap[status] || ''}`} data-cy="productStatus">
    {overwriteStatusDisplayValues[status] || status}
  </p>
)
