import { connect } from 'react-redux'
import { KeyboardNavigation } from './KeyboardNavigation'
import { getHighlightedRows } from 'src/selectors/product'
import { clearHighlightedRows } from 'src/actions/grid/highlightedRows'
import { AppState } from '../../reducers/root'
import { GridType } from 'src/types/index'

type OwnProps = {
  gridName: GridType
}

const mapStateToProps = (state: AppState) => ({
  highlightedRows: getHighlightedRows(state),
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  clearHighlightedRows: () => dispatch(clearHighlightedRows(ownProps.gridName)),
})

export const KeyboardNavigationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeyboardNavigation)
