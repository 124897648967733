import { CurrencyKeys, GridType, PartialPriceAttributions, Size } from 'src/types/index'

export type SizesActions = ReturnType<
  | typeof updateSizes
  | typeof updateSizesSuccess
  | typeof updateSizesError
  | typeof refetchSizes
  | typeof updateSizeAttribution
  | typeof updateSizeAttributionSuccess
  | typeof updateSizesAndAttribution
  | typeof updateSizesAndAttributionErrorAction
>

export type ProductSizePayload = {
  sizes: Size[]
  productSlug: string
  developmentId: string
}

export const UPDATE_PRODUCT_SIZES = 'UPDATE_PRODUCT_SIZES'

export const updateSizes = (sizes: Size[], productSlug: string, developmentId: string) =>
  <const>{
    type: UPDATE_PRODUCT_SIZES,
    payload: {
      sizes,
      productSlug,
      developmentId,
    },
  }

export const UPDATE_PRODUCT_SIZES_SUCCESS = 'UPDATE_PRODUCT_SIZES_SUCCESS'

export const updateSizesSuccess = (grid: GridType, payload: ProductSizePayload) =>
  <const>{
    grid,
    type: UPDATE_PRODUCT_SIZES_SUCCESS,
    payload,
  }

export const UPDATE_PRODUCT_SIZES_ERROR = 'UPDATE_PRODUCT_SIZES_ERROR'

export const updateSizesError = () =>
  <const>{
    type: UPDATE_PRODUCT_SIZES_ERROR,
  }

export const REFETCH_PRODUCT_SIZES = 'REFETCH_PRODUCT_SIZES'

export const refetchSizes = (productSlug: string, hierarchySlug: string) =>
  <const>{
    type: REFETCH_PRODUCT_SIZES,
    productSlug,
    hierarchySlug,
  }

export const UPDATE_SIZE_ATTRIBUTION = 'UPDATE_SIZE_ATTRIBUTION'

export const updateSizeAttribution = (
  productSlug: string,
  prices: PartialPriceAttributions,
  supplierCostCurrency: CurrencyKeys,
) =>
  <const>{
    type: UPDATE_SIZE_ATTRIBUTION,
    payload: {
      productSlug,
      prices,
      supplierCostCurrency,
    },
  }

export const UPDATE_SIZE_ATTRIBUTION_SUCCESS = 'UPDATE_SIZE_ATTRIBUTION_SUCCESS'

export const updateSizeAttributionSuccess = (
  prices: PartialPriceAttributions,
  supplierCostCurrency: CurrencyKeys,
) =>
  <const>{
    type: UPDATE_SIZE_ATTRIBUTION_SUCCESS,
    payload: {
      prices,
      supplierCostCurrency,
    },
  }

export const UPDATE_SIZES_AND_ATTRIBUTION = 'UPDATE_SIZES_AND_ATTRIBUTION'

export const updateSizesAndAttribution = (
  productSlug: string,
  developmentId: string,
  sizes: Size[],
  prices: PartialPriceAttributions,
  supplierCostCurrency: CurrencyKeys,
) =>
  <const>{
    type: UPDATE_SIZES_AND_ATTRIBUTION,
    payload: {
      productSlug,
      developmentId,
      sizes,
      prices,
      supplierCostCurrency,
    },
  }

export const UPDATE_SIZES_AND_ATTRIBUTION_ERROR = 'UPDATE_SIZES_AND_ATTRIBUTION_ERROR'

export const updateSizesAndAttributionErrorAction = () =>
  <const>{
    type: UPDATE_SIZES_AND_ATTRIBUTION_ERROR,
  }
