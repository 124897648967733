import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { DISMISS_EVENT_ERROR, dismissEventErrorSuccess } from 'src/actions/grid/product/eventErrors'
import { injectorService } from 'src/service/injector/injectorService'
import { errorAndNotification } from '../error'
import { getGrid } from 'src/selectors/grid'
import { trackEvent } from '../../service/analytics/analytics'
import { SVPAction } from 'actions/svp'
import { GridType } from 'types'
import { APIError } from 'types/Api'

export function* dismissEventErrorSaga({
  productSlug,
  errorID,
}: SVPAction<typeof DISMISS_EVENT_ERROR>) {
  const grid: GridType = yield select(getGrid)
  try {
    trackEvent('product', 'Product Event Error Dismissed')

    yield call(injectorService.patch, `eventerrors/${errorID}/deactivate`, {}, { v2Endpoint: true })
    yield put(dismissEventErrorSuccess({ productSlug, errorID }, grid))
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      `Something went wrong and we couldn't dismiss the error.\nPlease try again later.`,
    )
  }
}

export default function* () {
  yield all([takeEvery(DISMISS_EVENT_ERROR, dismissEventErrorSaga)])
}
