import React from 'react'
import Select, { MenuPlacement, StylesConfig } from 'react-select'
import classNames from 'classnames'
import {
  DropdownCustomStyles,
  DropdownOption,
  DropdownStyle,
  ExtendedDropdownStyle,
} from 'src/types/Dropdowns'
import {
  encapsulatedDropdown,
  encapsulatedDropdownError,
  encapsulatedDropdownLabel,
  encapsulatedDropdownSmall,
  encapsulatedDropdownValidation,
} from './Dropdown.scss'
import { getCustomStyles } from './DropdownStyles'
import { IntentionalAny } from 'types'

type DropdownProps = DropdownCustomStyles & {
  name: string
  header?: string
  required?: boolean
  items: DropdownOption[]
  createOption: (option: DropdownOption | string) => DropdownOption
  validationText?: string
  selectedItem?: DropdownOption | string
  isValid: boolean
  isDeprecated?: boolean
  hasFocus?: boolean
  onItemSelected: (item: IntentionalAny, controlName: string) => void
  onInputKeyDown: (e: KeyboardEvent) => void
  onBlur: (e: React.FocusEvent<HTMLElement>) => void
  small?: boolean
  disabled?: boolean
  noSort?: boolean
  placeholder?: string
  isSearchable?: boolean
  dropdownIndicator?: boolean
  className?: string
  menuPlacement?: MenuPlacement
  dataCy?: string
  customFilterOption?: (option: DropdownOption, name: string) => boolean
  isMulti?: boolean
  isClearable?: boolean
  selectedMultiItems?: DropdownOption[]
  onMultiItemsSelected?: (options: DropdownOption[], name: string) => void
  mandatory?: boolean
  locked?: boolean
}

type State = {
  validationText: string
  errorStyle: Record<string, unknown>
}

const sortOptions = (options: DropdownOption[]): DropdownOption[] =>
  options.slice().sort((a, b) => a.label.localeCompare(b.label))

export default class Dropdown extends React.Component<DropdownProps, State> {
  input: IntentionalAny

  constructor(props: DropdownProps) {
    super(props)
    this.state = {
      validationText: props.validationText || 'this field is mandatory',
      errorStyle: { marginTop: '24px' },
    }
  }

  static defaultProps = {
    onInputKeyDown: (): void => {
      // noop
    },
    onBlur: (): void => {
      // noop
    },
  }

  componentDidMount(): void {
    if (this.props.hasFocus) {
      this.input && this.input.focus()
    }
  }

  /**
     This function was specifically introduced to ensure that react-select scrolls the
     the options list to the top to ensure that the most relevant match is visible
     as the user types
     see https://github.com/JedWatson/react-select/issues/1018
     */
  scrollMenuToTop(): void {
    const menuClassName = `.${encapsulatedDropdown}.${this.props.name} .Select-menu-outer .Select-menu`
    const menu = document.querySelector(menuClassName)
    if (menu != null) {
      menu.scrollTop = 0
    }
  }

  onInputKeyDown = (event: KeyboardEvent): void => {
    this.scrollMenuToTop()
    this.props.onInputKeyDown(event)
  }

  componentDidUpdate = (prevProps: DropdownProps): void => {
    if (prevProps.hasFocus) {
      this.input && this.input.focus()
    }
  }

  onChange = (selectedItem?: DropdownOption): void => {
    const item = selectedItem
      ? selectedItem.data
      : this.props.mandatory && this.props.locked
      ? null
      : ''

    this.props.onItemSelected(item, this.props.name)
  }

  onChangeMulti = (selectedOptionMulti: DropdownOption[]): void => {
    const listValue = selectedOptionMulti || []
    this.props.onMultiItemsSelected && this.props.onMultiItemsSelected(listValue, this.props.name)
  }

  onBlur = (event: React.FocusEvent<HTMLElement>): void => {
    this.props.onBlur(event)
  }

  applyCustomEffects = (
    state: StylesConfig<DropdownStyle, false>,
    styleObject?: ExtendedDropdownStyle,
  ): DropdownStyle =>
    styleObject && styleObject.customEffects ? styleObject.customEffects(state) : {}

  setRef = (input: IntentionalAny): void => {
    this.input = input
  }

  render(): JSX.Element {
    const {
      selectedItem,
      name,
      isValid,
      small,
      header,
      required,
      items,
      createOption,
      disabled,
      noSort,
      placeholder = '',
      isSearchable,
      dropdownIndicator: showDropdownIndicator,
      className,
      menuPlacement,
      customFilterOption,
      dataCy,
      selectedMultiItems,
      isMulti,
      isClearable,
    } = this.props
    const selectedValue = selectedItem ? createOption(selectedItem) : null

    const encapsulatedDropdownClass =
      className ||
      classNames(encapsulatedDropdown, name, {
        [encapsulatedDropdownError]: !isValid,
        [encapsulatedDropdownSmall]: small,
      })

    const customStyles = getCustomStyles(this.props, this.applyCustomEffects.bind(this))
    const options = items.map(createOption)

    const defaultFilterOption = ({ label }: DropdownOption, searchString: string): boolean =>
      label.toUpperCase().includes(searchString.toUpperCase())

    return (
      <div className={encapsulatedDropdownClass} data-cy={dataCy || ''}>
        {header && (
          <label className={encapsulatedDropdownLabel} htmlFor={name}>
            {required ? `${header || ''} *` : header}
          </label>
        )}
        <Select
          data-cy="select"
          placeholder={placeholder}
          menuPlacement={menuPlacement || 'auto'}
          autosize
          options={noSort ? options : sortOptions(options)}
          name={name}
          styles={customStyles}
          // @ts-ignore due to reselect types
          onChange={isMulti ? this.onChangeMulti : this.onChange}
          onBlurResetsInput={false}
          onBlur={this.onBlur}
          value={isMulti ? selectedMultiItems : selectedValue}
          clearable={false}
          arrowRenderer={null}
          openMenuOnFocus
          tabSelectsValue={false}
          components={
            showDropdownIndicator ? { IndicatorSeparator: null } : { DropdownIndicator: null }
          }
          isDisabled={disabled}
          onInputKeyDown={this.onInputKeyDown}
          ref={this.setRef}
          inputProps={{ autoComplete: 'off' }}
          isSearchable={isSearchable !== undefined ? isSearchable : true}
          filterOption={customFilterOption || defaultFilterOption}
          isMulti={isMulti}
          isClearable={isClearable}
        />
        {!isValid ? (
          <div className={`${encapsulatedDropdownValidation} error`}>
            {this.state.validationText}
          </div>
        ) : null}
      </div>
    )
  }
}
