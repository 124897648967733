import { delay } from 'redux-saga'
import { call, take, race, put } from 'redux-saga/effects'
import { constants } from '@ri-digital/auth0-authenticator'

import setCookie from '../service/cookies/set'
import { logError } from '../service/errors'
import { setCookieSuccess, setCookieFailure } from '../actions/cookie'
import { Config } from 'helpers/config'

const defaultInterval = 300000 // 5min
const defaultErrorInterval = 10000 // 10 seconds

export function* processCookie() {
  try {
    const cookieRefreshInterval = Config().Get('cookieRefreshInterval') as number

    yield call(setCookie)
    yield put(setCookieSuccess())
    yield call(delay, cookieRefreshInterval || defaultInterval)
  } catch (err) {
    yield call(logError, err as Error)
    yield put(setCookieFailure())

    // In the event of an error coming back from the API, delay for an interval before retrying
    yield call(delay, defaultErrorInterval)
  }
}

export function* setCookieWorker() {
  while (true) {
    yield processCookie()
  }
}

// The CookieSaga is used to renew the Cookie used for loading images from S3 after a specified interval using a poll
// Once `constants.SET_SCOPES` has been received it will trigger a race between `setCookieWorker` & constants.SET_UNAUTHENTICATED
// The setCookieWorker will make the request to new the cookie and emit an action based on the result. If successful it will delay for the configured period of time.
// The setCookieWorker function contains a while loop that will continue forever.
// If the saga receives the `constants.SET_UNAUTHENTICATED` action it will terminate the worker due to the `race` function that we use. See the Redux Saga docs on how this works
// If an error occurs we dont stop the worker as we rely on the UI to handle the issue. Instead we delay again to allow time for the User to deal with the error
export default function* rootSaga() {
  while (true) {
    // NOTE: we use SetScopes to trigger setCookieWorker instead of SetAuthenticated as it is triggered on both login and page refresh
    yield take(constants.SET_SCOPES)
    yield race({
      task: call(setCookieWorker),
      cancel: take(constants.SET_UNAUTHENTICATED),
    })
  }
}
