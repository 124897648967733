import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { BULK_UPDATE_PRODUCT_STATUS } from 'src/actions/grid/product/bulkStatus'
import { getSelectedProducts } from 'src/selectors/multiSelect'
import { actionLookUp } from 'src/service/product/status'
import { DEVELOPMENT, PROPOSED, READY_TO_BUY } from 'src/constants/productStatus'
import { errorAndNotification } from '../error'
import { getDepartmentSpecsForGrid } from 'src/selectors/departmentSpecs'
import { getColumnRenderSpecs } from 'src/selectors/referenceData/columns'
import { runProductValidationsSaga } from './validate'
import { getDepartmentsWithChildren } from 'src/selectors/hierarchy'
import { getDepartmentByHierarchy } from 'src/service/hierarchy/utils'
import { injectorService } from 'src/service/injector/injectorService'
import { showNotification } from 'src/actions/notification'
import { DepartmentSpecs, OPTION_LIBRARY, Product, RenderSpec } from 'src/types/index'
import { isValidStatusTransition } from 'src/service/product/validate'
import { BULK_MARK_AS_RTB, MARK_AS_PROPOSED } from 'src/constants/apiUrls'
import {
  BulkMarkRTBResponse,
  BulkUpdateStatusResponse,
  UpdateStatusFailure,
} from 'src/types/responses/updateStatus'
import { addPollingIDs, AddPollingIDsArgs, startPolling } from 'src/actions/polling/polling'
import { POLLING_MARK_READY_TO_BUY } from 'src/constants/polling'
import { trackEvent } from 'src/service/analytics/analytics'
import { addBreadcrumb } from 'src/helpers/sentry'
import { PartialFilter } from 'src/actions/grid/partialFilters'
import { PartialFiltersState } from 'src/reducers/common/grid/partialFilters'
import { fetchFilteredProducts, lastPageFrom } from './fetch'
import { fetchProductsPageSuccess } from 'src/actions/grid/product/fetch'
import { DepartmentsWithChildrenState } from 'reducers/referenceData/hierarchy/departmentsWithChildren'
import { FetchProductsResponse } from 'types/Product'
import { APIError } from 'types/Api'
import { SVPAction } from 'actions/svp'

const invalidProductStatusMessage = (status: string) =>
  `Sorry, this option is only available for ${status} products`

const getResponseFailureMap = ({ failures }: BulkUpdateStatusResponse) => {
  if (failures) {
    return new Map(failures.map((x) => [x.slug, true]))
  } else {
    return new Map([])
  }
}

const getPartialErrorMessage = (failures: UpdateStatusFailure[], products: Array<Product>) => {
  const devIds: Array<string> = []

  products.map((product) => {
    if (failures.map((value) => value.slug).includes(product.slug)) {
      devIds.push(product.developmentId)
    }
  })
  return `Something went wrong.\nThe following product(s) can't be moved: ${devIds.join(
    ', ',
  )}.\nPlease, try again later.`
}

export function* bulkUpdateProductStatusSaga({
  payload: { newStatus },
  grid,
}: SVPAction<typeof BULK_UPDATE_PRODUCT_STATUS>) {
  const products: Array<Product> = yield select(getSelectedProducts)

  const productsWithInvalidStatus = products.filter(
    ({ status }) => !isValidStatusTransition(status, newStatus),
  )

  if (productsWithInvalidStatus.length > 0) {
    yield call(
      errorAndNotification,
      '',
      invalidProductStatusMessage(grid === OPTION_LIBRARY ? PROPOSED : DEVELOPMENT),
    )

    return
  }

  const departmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecsForGrid)
  const columnSpecs: RenderSpec[] = yield select(getColumnRenderSpecs)
  const departmentsWithChildren: DepartmentsWithChildrenState = yield select(
    getDepartmentsWithChildren,
  )

  const productsWithInvalidFields: string[] = yield all(
    products.map((product) => {
      const productDepartment = getDepartmentByHierarchy(
        product.hierarchySlug,
        departmentsWithChildren,
      )
      const rules = departmentSpecs[productDepartment].rules

      return call(runProductValidationsSaga, product, rules, columnSpecs, newStatus, grid)
    }),
  )

  if (productsWithInvalidFields.flat().length > 0) {
    yield call(errorAndNotification, '', 'Please, amend the errors in the grid.')
    return
  }

  addBreadcrumb('product', 'Bulk Status Update Triggered')
  trackEvent('product', 'Bulk Status Update Triggered', `Product: ${products.length}`)

  try {
    let response: BulkUpdateStatusResponse
    let successMessage = `Done!\n${products.length} products moved to ${newStatus} successfully.`

    switch (newStatus) {
      case PROPOSED:
        response = yield call(injectorService.put, MARK_AS_PROPOSED, {
          productSlugs: products.map(({ slug }) => slug),
        })
        break
      case READY_TO_BUY:
        response = yield call(handleBulkMarkReadyToBuy, products)
        successMessage = `Done!\n${products.length} products changing status to ${newStatus}`
        break
      default:
        response = yield call(injectorService.put, BULK_MARK_AS_RTB, {
          action: actionLookUp[newStatus],
          productSlugs: products.map(({ slug }) => slug),
        })
        break
    }

    const responseFailureMap = getResponseFailureMap(response)

    if (response.failedProducts > 0) {
      yield put(
        showNotification({
          type: 'warn',
          message: getPartialErrorMessage(response.failures, products),
        }),
      )
    } else {
      yield put(
        showNotification({
          type: 'success',
          message: successMessage,
        }),
      )
    }

    const filters: PartialFilter[] = products
      .filter((product) => !responseFailureMap.get(product.slug))
      .map((x) => ({
        value: x.developmentId,
        property: 'developmentId',
        displayName: 'developmentId',
      }))
    const partialFilters: PartialFiltersState = {
      developmentId: {
        saved: filters,
      },
    }

    const newPoductsResponse: FetchProductsResponse = yield call(
      fetchFilteredProducts,
      0,
      {},
      partialFilters,
      [],
      grid,
    )
    yield put(
      fetchProductsPageSuccess(
        newPoductsResponse.products,
        0,
        lastPageFrom(newPoductsResponse.page?.headers?.link),
        grid,
      ),
    )
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      `Something went wrong and we couldn't mark the products as ${newStatus}.\nPlease try again later.`,
    )
  }
}

function* handleBulkMarkReadyToBuy(products: Product[]) {
  const payload = {
    action: actionLookUp[READY_TO_BUY],
    productSlugs: products.map(({ slug }) => slug),
  }

  const response: BulkMarkRTBResponse = yield call(injectorService.put, BULK_MARK_AS_RTB, payload, {
    v2Endpoint: true,
  })

  // If async not enabled there will be no polling IDs
  if (response && response.pollingIDs) {
    const polls: AddPollingIDsArgs[] = response.pollingIDs.map((item) => {
      const product = products.find((x) => x.slug === item.slug) as Product

      return {
        id: item.pollingID,
        productSlug: product.slug,
        developmentId: product.developmentId,
        pollType: POLLING_MARK_READY_TO_BUY,
      }
    })

    yield put(addPollingIDs(polls))
    yield put(startPolling())
  }

  return response
}

export default function* () {
  yield all([takeEvery(BULK_UPDATE_PRODUCT_STATUS, bulkUpdateProductStatusSaga)])
}
