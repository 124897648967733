import React from 'react'
import {
  sidePanelWrapper,
  sidePanel,
  closeTab,
  closeLink,
  closeIcon,
  tabs,
  title,
  activeTab,
  inactiveTab,
  navigationDiv,
  navArrows,
  description,
  productInfoDiv,
  productStyleDiv,
  colour,
  inactiveButton,
} from './SidePanel.scss'
import { CloseCrossCircle } from '../icons/CloseCrossCircle'
import Link from 'src/components/Link/index'
import { COMMENTS, SKETCH, LINKS } from 'src/types/index'
import { CommentsSection } from '../CommentsSection/CommentsSection'
import { ImagesSectionContainer } from '../../ImagesSection'
import { LinksSectionContainer } from '../LinksSection/LinksSectionContainer'
import { SidePanelTabs, WHOLESALE } from 'src/types/Tabs'
import { WholesaleImagesSectionContainer } from '../WholesaleImagesSection/WholesaleImagesSectionContainer'
import { UpRoundArrow } from 'assets/icons/UpRoundArrow'
import { DownRoundArrow } from 'assets/icons/DownRoundArrow'
import { SidePanelProps } from './SidePanelContainer'

type State = {
  selectedTab: SidePanelTabs
}

export class SidePanel extends React.Component<SidePanelProps, State> {
  constructor(props: SidePanelProps) {
    super(props)
    this.state = {
      selectedTab: this.props.openingTab,
    }
  }

  componentDidMount(): void {
    this.props.fetchProductComments(this.props.product.slug, this.props.gridName)
    this.props.fetchProductLinks(this.props.product.slug)
    this.props.fetchStyle(this.props.product.styleSlug)
  }

  componentDidUpdate(prevProps: SidePanelProps): void {
    if (
      prevProps.product &&
      this.props.product &&
      prevProps.product.slug !== this.props.product.slug
    ) {
      this.props.fetchProductComments(this.props.product.slug, this.props.gridName)
      this.props.fetchProductLinks(this.props.product.slug)
      this.props.fetchStyle(this.props.product.styleSlug)
    }
  }

  canGetPrevious = (): boolean =>
    this.props.rowIndex + this.props.sidePanelValue > 0 ? true : false
  canGetNext = (): boolean =>
    this.props.sidePanelValue + this.props.rowIndex < this.props.products.length - 1 ? true : false
  selectTab = (tabToSelect: SidePanelTabs): void => this.setState({ selectedTab: tabToSelect })
  handlePrevious = (): void => {
    this.canGetPrevious() && this.props.getPreviousProduct()
  }

  handleNext = (): void => {
    this.canGetNext() && this.props.getNextProduct()
  }
  render(): JSX.Element {
    return (
      <div className={sidePanelWrapper}>
        <div className={tabs}>
          <div className={closeTab}>
            <Link className={closeLink} onClick={this.props.closeFn}>
              <CloseCrossCircle className={closeIcon} />
            </Link>
          </div>
          <Link
            className={this.state.selectedTab === LINKS ? activeTab : inactiveTab}
            onClick={(): void => this.selectTab(LINKS)}
          >
            Links
          </Link>

          <Link
            className={this.state.selectedTab === COMMENTS ? activeTab : inactiveTab}
            onClick={(): void => this.selectTab(COMMENTS)}
          >
            Comments
          </Link>

          <Link
            className={this.state.selectedTab === SKETCH ? activeTab : inactiveTab}
            onClick={(): void => this.selectTab(SKETCH)}
          >
            Sketch & Sample
          </Link>
          <Link
            className={this.state.selectedTab === WHOLESALE ? activeTab : inactiveTab}
            onClick={(): void => this.selectTab(WHOLESALE)}
          >
            Gallery
          </Link>
        </div>
        <div className={sidePanel}>
          <div className={navigationDiv}>
            <div onClick={this.handlePrevious}>
              {
                <UpRoundArrow
                  className={`${navArrows} ${this.canGetPrevious() ? '' : inactiveButton}`}
                  width="50px"
                  height="50px"
                />
              }
            </div>
            <div className={productInfoDiv}>
              <h4 className={title}>{this.props.product.developmentId}</h4>
              <div className={productStyleDiv}>
                <h4 className={colour}>{this.props.product.colour}</h4>
                <h4 className={description}>{this.props.product.productDescription}</h4>
              </div>
            </div>

            <div onClick={this.handleNext}>
              {
                <DownRoundArrow
                  className={`${navArrows} ${this.canGetNext() ? '' : inactiveButton}`}
                  width="50px"
                  height="50px"
                />
              }
            </div>
          </div>

          {this.state.selectedTab === COMMENTS && (
            <CommentsSection
              comments={this.props.comments}
              userEmail={this.props.userEmail}
              postComment={this.props.postProductComment}
              status={this.props.commentsStatus}
              grid={this.props.gridName}
              productSlug={this.props.product.slug}
            />
          )}
          {this.state.selectedTab === SKETCH && (
            <ImagesSectionContainer
              developmentId={this.props.product.developmentId}
              productSlug={this.props.product.slug}
              images={this.props.product.images}
              gridName={this.props.gridName}
            />
          )}
          {this.state.selectedTab === LINKS && (
            <LinksSectionContainer
              links={this.props.links}
              style={this.props.style}
              selectedProduct={this.props.product}
            />
          )}
          {this.state.selectedTab === WHOLESALE && (
            <WholesaleImagesSectionContainer
              isWsReadyWeekSet={!!this.props.product.wsReadyWeek}
              productSlug={this.props.product.slug}
              developmentId={this.props.product.developmentId}
            />
          )}
        </div>
      </div>
    )
  }
}
