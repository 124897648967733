import { GridType } from 'src/types/index'

export type EventErrorActions = ReturnType<
  typeof dismissEventError | typeof dismissEventErrorSuccess
>

export const DISMISS_EVENT_ERROR = 'DISMISS_EVENT_ERROR'
export const DISMISS_EVENT_ERROR_SUCCESS = 'DISMISS_EVENT_ERROR_SUCCESS'

export type DismissEventErrorPayload = {
  productSlug: string
  errorID: string
}

export const dismissEventError = (productSlug: string, errorID: string) =>
  <const>{
    type: DISMISS_EVENT_ERROR,
    productSlug,
    errorID,
  }

export const dismissEventErrorSuccess = (payload: DismissEventErrorPayload, grid: GridType) =>
  <const>{
    type: DISMISS_EVENT_ERROR_SUCCESS,
    payload,
    grid,
  }
