import { ProductStatus } from 'src/types/ProductStatus'
import { GridType } from 'src/types/index'

export const BULK_UPDATE_PRODUCT_STATUS = 'BULK_UPDATE_PRODUCT_STATUS'

export type BulkUpdateProductStatusAction = ReturnType<typeof bulkUpdateProductStatus>
export type BulkUpdateProductStatusPayload = {
  newStatus: ProductStatus
}
export const bulkUpdateProductStatus = (newStatus: ProductStatus, grid: GridType) =>
  <const>{
    type: BULK_UPDATE_PRODUCT_STATUS,
    payload: { newStatus },
    grid,
  }
