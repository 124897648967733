import React from 'react'

import { FlatHierarchyItem, Product } from 'src/types/index'
import { CloseCross } from 'src/assets/icons'
import { Image } from 'src/types/Images'
import { SIZE_AFTER_READY_TO_BUY_PRODUCT_ERROR_TYPE } from 'src/constants/errorCodes'
import ErrorList from './ErrorList/ErrorList'

import {
  sizeModalHeader,
  headerBody,
  header,
  headerImage,
  productInfo,
  styleDescription,
  developmentId as developmentIdClass,
  hierarchy as hierarchyClass,
  closeButtonContainer,
  closeButton,
  headerTitle,
} from './SizeModalHeader.scss'

type Props = {
  onClose: () => void
  onDismissError: (errorID: string) => void
  product: Product
  hierarchy: FlatHierarchyItem
  isLoading: boolean
  isPending: boolean
}

const getLastImage = (images?: Image[]): Image | null => (images ? images.slice(-1)[0] : null)

export const SizeModalHeader = ({
  onClose,
  onDismissError,
  product,
  hierarchy,
  isLoading,
  isPending,
}: Props) => {
  const { images, developmentId, productDescription, eventErrors } = product
  const image = getLastImage(images)

  const headerText = isPending
    ? 'Processing Sizes'
    : isLoading
    ? 'Loading Sizes'
    : 'Available Sizes'

  const errors =
    eventErrors?.filter((x) => x.type === SIZE_AFTER_READY_TO_BUY_PRODUCT_ERROR_TYPE) || []
  return (
    <div className={header}>
      <div className={headerBody}>
        <div className={sizeModalHeader}>
          {image && <img className={headerImage} src={image.url} alt={image.type} />}
          <div className={productInfo}>
            {developmentId && <p className={developmentIdClass}>{developmentId}</p>}
            <p className={styleDescription}>{productDescription}</p>
            <p className={hierarchyClass}>{`${hierarchy.levelCode} ${hierarchy.title}`}</p>
          </div>
        </div>
        <div className={closeButtonContainer}>
          <button onClick={onClose} className={closeButton}>
            <CloseCross />
          </button>
        </div>
      </div>
      <h3 className={headerTitle}>{headerText}</h3>
      <ErrorList productErrors={errors} onDismissError={onDismissError} />
    </div>
  )
}
