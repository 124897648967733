import React from 'react'
import { container, title, list } from './ExistingAttributeHelper.scss'

type Props = {
  attributesNames: string[]
}

export const ExistingAttributeHelper = (props: Props) => (
  <div className={container}>
    <div className={title}>Similar Attributes:</div>
    <span className={list}>{props.attributesNames.join(', ')}</span>
  </div>
)
