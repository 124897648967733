import { call, put, takeEvery } from 'redux-saga/effects'
import { injectorService } from '../service/injector/injectorService'
import {
  LOCKED,
  FETCH_LOCK,
  fetchLockFailure,
  fetchLockSuccess,
  SET_LOCK,
  setLockSuccessAction,
  setLockFailureAction,
} from 'src/actions/lock'
import { FETCH_CONFIG_SUCCESS } from 'src/actions/config'
import { LOCK } from 'src/constants/apiUrls'
import { errorAndNotification } from './error'
import * as sentry from 'src/helpers/sentry'
import { Lock } from 'src/types/index'
import { SVPAction } from 'actions/svp'

export function* fetchLock() {
  try {
    const lock: Lock = yield call(injectorService.get, LOCK)
    yield put(fetchLockSuccess(lock))

    sentry.setTag('apiLocked', `${lock.status === LOCKED}`)
  } catch (error) {
    yield put(fetchLockFailure((error as Error).toString()))
  }
}

export function* setLock(action: SVPAction<typeof SET_LOCK>) {
  try {
    const lockPayload = {
      status: action.status,
    }
    const currentDate = Date.now()

    yield call(injectorService.patch, LOCK, lockPayload)
    yield put(setLockSuccessAction(lockPayload.status, `${currentDate}`))

    sentry.setTag('apiLocked', `${lockPayload.status === LOCKED}`)
  } catch (err) {
    const error = err as Error
    yield put(setLockFailureAction(error.toString()))
    yield* errorAndNotification(error, 'System Lock cannot be updated. Please try again later.')
  }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_LOCK, fetchLock)
  // The below fetches Lock after Config is Successful
  yield takeEvery(FETCH_CONFIG_SUCCESS, fetchLock)
  yield takeEvery(SET_LOCK, setLock)
}
