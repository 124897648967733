import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { reloadProductSaga } from './reload'
import {
  UPDATE_PRODUCT_SIZES,
  updateSizesSuccess,
  updateSizesError,
  refetchSizes,
} from '../../actions/grid/product/sizes'
import { DEPRECATED_SIZES_SELECTED_PROPOSED_ERROR } from '../../constants/errorCodes'
import { injectorService } from '../../service/injector/injectorService'
import { errorAndNotification } from '../error'
import { OPTION_LIBRARY } from 'src/types/index'
import { UpdateSizesResponse } from '../../types/responses/updateSizes'
import { addPollingID, startPolling } from '../../actions/polling/polling'
import { POLLING_SIZES_AFTER_RTB } from '../../constants/polling'
import { getSelectedHierarchySlug } from '../../selectors/modals/sizeModal'
import { showNotification } from '../../actions/notification'
import { trackEvent } from '../../service/analytics/analytics'
import { SVPAction } from 'actions/svp'
import { APIError } from 'types/Api'
import { isUseCaseErrorWithCode } from 'helpers/apiErrors'

interface UpdateSizesRequest {
  sizes: string[]
}

export function* updateProductSizesSaga(
  action: SVPAction<typeof UPDATE_PRODUCT_SIZES>,
  hideNotifications?: boolean,
) {
  try {
    const { payload } = action
    const { productSlug, developmentId } = payload

    const sizeNames = action.payload.sizes.map((x) => x.name)

    const body: UpdateSizesRequest = {
      sizes: sizeNames,
    }

    const response: UpdateSizesResponse = yield call(
      injectorService.patch,
      `products/${productSlug}/sizes`,
      body,
      { v2Endpoint: true },
    )
    if (response && response.pollingID) {
      trackEvent('product', 'Update sizes', 'Async')
      // Follow ASYNC Flow
      yield put(
        addPollingID(productSlug, developmentId, response.pollingID, POLLING_SIZES_AFTER_RTB),
      )
      yield put(startPolling())
    } else {
      trackEvent('product', 'Update sizes', 'Sync')
      if (!hideNotifications) {
        const hierarchySlug: string = yield select(getSelectedHierarchySlug)
        yield put(refetchSizes(productSlug, hierarchySlug))
        yield call(reloadProductSaga, productSlug, OPTION_LIBRARY)
        yield put(
          showNotification({
            type: 'success',
            message: 'Sizes successfully updated',
          }),
        )
        yield put(updateSizesSuccess(OPTION_LIBRARY, payload))
      }
    }
  } catch (error) {
    const apiError = error as APIError
    if (apiError?.data) {
      const { status, type, code } = apiError.data
      if (isUseCaseErrorWithCode(status, type, DEPRECATED_SIZES_SELECTED_PROPOSED_ERROR, code)) {
        throw apiError
      }
    }

    yield put(updateSizesError())
    if (!hideNotifications) {
      yield* errorAndNotification(
        error as APIError,
        "Sizes couldn't be saved. Please try again later.",
      )
    }
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_PRODUCT_SIZES, updateProductSizesSaga)])
}
