export const SET_COOKIE_SUCCESS = 'SET_COOKIE_SUCCESS'
export const SET_COOKIE_FAILURE = 'SET_COOKIE_FAILURE'

export type SetCookieActions = ReturnType<typeof setCookieSuccess | typeof setCookieFailure>

export const setCookieSuccess = () =>
  <const>{
    type: SET_COOKIE_SUCCESS,
  }

export const setCookieFailure = () =>
  <const>{
    type: SET_COOKIE_FAILURE,
  }
