import React from 'react'
import classNames from 'classnames'

import {
  linkedProduct,
  productInfo,
  column,
  columnTitle,
  linkedProductContainer,
  selectedProduct,
  imageStyle,
  imageContainer,
  productAndImage,
  xContainer,
  x,
  value,
  modal,
  modalButton,
  linkIconContainer,
  selectedLinkIcon,
  linkIcon,
} from './ProductCard.scss'
import { Camera } from '../../icons/Camera'
import { CloseCross, LinkIndicator } from 'src/assets/icons'
import { Modal } from 'src/components/Modal/Modal'
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton'
import { Link } from 'src/components/Link/Link'
import { skipLink } from 'src/components/Link/SkipLink/SkipLink.scss'
import { Image } from 'src/types/Images'
import { LinkType } from 'src/types/Links'
import { isMobileEnv } from 'helpers/envs'

type Props = {
  developmentId?: string
  productNumber?: string
  productDescription?: string
  additionalField?: { name: string; value: string }
  additionalColumn?: { name: string; value: string }
  images?: Image[]
  selected?: boolean
  onRemove?: () => void
  selectedProductClass?: string
  removeTitle?: string
  removalConfirmationMessage?: string
  linkType?: LinkType
  linkTitle?: string
  wsPrimaryThumbnail?: string
}

type State = {
  showModal: boolean
}

const getLast = (images: Image[]): Image => images[images.length - 1]

export class ProductCard extends React.Component<Props, State> {
  state = {
    showModal: false,
  }

  toggleModal = (): void => {
    this.setState((prev) => ({ showModal: !prev.showModal }))
  }

  getProductCardImage = (): JSX.Element => {
    if (this.props.wsPrimaryThumbnail) {
      return (
        <div className={imageContainer}>
          <img className={imageStyle} src={this.props.wsPrimaryThumbnail} />
        </div>
      )
    } else {
      return (
        <div className={imageContainer}>
          {this.props.images?.length > 0 ? (
            <img
              className={imageStyle}
              src={getLast(this.props.images).url}
              alt={getLast(this.props.images).type}
            />
          ) : (
            <Camera className={imageStyle} />
          )}
        </div>
      )
    }
  }
  render(): JSX.Element {
    const {
      developmentId,
      productNumber,
      productDescription,
      additionalField,
      additionalColumn,
      selected,
      onRemove,
      selectedProductClass,
      removeTitle,
      removalConfirmationMessage,
      linkType,
      linkTitle,
    } = this.props

    return (
      <div
        data-cy="product-card-container"
        className={`${linkedProductContainer} ${
          selected ? selectedProductClass || selectedProduct : ''
        }`}
      >
        <div className={classNames(linkIconContainer, { [selectedLinkIcon]: !selected })}>
          {!!linkType && (
            <LinkIndicator className={linkIcon} linkType={linkType} title={linkTitle || ''} />
          )}
        </div>
        <div className={linkedProduct}>
          <div className={productAndImage}>
            {this.getProductCardImage()}
            <div className={productInfo}>
              <span className={column}>
                <div className={columnTitle}>Dev ID</div>
                <div>{developmentId}</div>
              </span>
              <span className={column}>
                <div className={columnTitle}>Prod No.</div>
                <div>{productNumber}</div>
              </span>
              {!!additionalColumn && (
                <span className={column} style={{ width: '7rem' }}>
                  <div className={columnTitle}>{additionalColumn.name}</div>
                  <div className={value} title={additionalColumn.value}>
                    {additionalColumn.value}
                  </div>
                </span>
              )}
              {!isMobileEnv() && (
                <span className={column}>
                  <div className={columnTitle}>
                    {additionalField ? additionalField.name : 'Product Description'}
                  </div>
                  <div
                    className={value}
                    title={additionalField ? additionalField.value : productDescription}
                  >
                    {additionalField ? additionalField.value : productDescription}
                  </div>
                </span>
              )}
            </div>
            {onRemove && (
              <div
                className={xContainer}
                data-cy="remove-link"
                onClick={selected ? this.toggleModal : onRemove}
              >
                <CloseCross className={x} title={removeTitle} />
              </div>
            )}
            {selected && this.state.showModal && (
              <Modal onCloseFn={this.toggleModal}>
                <div className={modal}>
                  <p>{removalConfirmationMessage}</p>
                  <PrimaryButton
                    className={modalButton}
                    onClick={(): void => {
                      onRemove && onRemove()
                      this.toggleModal()
                    }}
                  >
                    Continue
                  </PrimaryButton>
                  <Link className={skipLink} onClick={this.toggleModal}>
                    Cancel
                  </Link>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    )
  }
}
