import React from 'react'
import * as ReactDOM from 'react-dom'
import classNames from 'classnames'

import { overlay, modal } from './Modal.scss'
import { setFocus } from 'src/service/ref'
import { focusTrap } from 'src/service/focusTrap'

type Props = {
  children: React.ReactNode
  onCloseFn: () => void
  modalClass?: string
  overlayClass?: string
}

const ModalBody = ({ children, overlayClass, modalClass }: Props): JSX.Element => {
  return (
    <div className={classNames(overlay, overlayClass)}>
      <div className={classNames(modal, modalClass)}>{children}</div>
    </div>
  )
}

export class Modal extends React.Component<Props> {
  el: HTMLDivElement

  constructor(props: Props) {
    super(props)
    this.el = document.createElement('div')
    this.el.tabIndex = 0
  }

  componentDidMount(): void {
    const modalRoot = document.querySelector('body')
    modalRoot && this.el && modalRoot.appendChild(this.el)
    setFocus(this.el)

    this.el && this.el.addEventListener('keydown', this.handleKeyPress)
  }

  handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      this.props.onCloseFn()
    }

    if (event.key === 'Tab') {
      focusTrap(this.el, 'a, button', event)
    }
  }

  componentWillUnmount(): void {
    const modalRoot = document.querySelector('body')
    modalRoot && this.el && modalRoot.removeChild(this.el)
    this.el.removeEventListener('keydown', this.handleKeyPress)
  }

  render(): JSX.Element {
    const { overlayClass, onCloseFn, modalClass, children } = this.props
    return this.el
      ? ReactDOM.createPortal(
          <ModalBody
            overlayClass={overlayClass}
            onCloseFn={onCloseFn}
            modalClass={modalClass}
            // eslint-disable-next-line react/no-children-prop
            children={children}
          />,
          this.el,
        )
      : null
  }
}
