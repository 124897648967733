import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPageStatus, Status } from '../../selectors/status'
import { RISpinner } from '../../components/RISpinner/RISpinner'
import { fetchReferenceData } from '../../actions/referenceData/referenceData'
import { AppState } from '../../reducers/root'

type Props = {
  fetchReferenceData: () => void
  dependenciesStatus: Status
  render: () => JSX.Element
}

export class GridPage extends Component<Props> {
  componentDidMount() {
    this.getDependencies()
  }

  render() {
    if (this.props.dependenciesStatus !== 'ready') {
      return <RISpinner colour="white" />
    }

    return this.props.render()
  }

  getDependencies = () => {
    this.props.fetchReferenceData()
  }
}

const mapStateToProps = (state: AppState) => ({
  dependenciesStatus: getPageStatus(state),
})

export default connect(mapStateToProps, {
  fetchReferenceData,
})(GridPage)
