import { connect } from 'react-redux'
import { MoveToModal } from './MoveToModal'
import { AppState } from 'src/reducers/root'
import { getMovableSelectedIds } from 'src/selectors/multiSelect'
import { GridType } from 'src/types/index'
import { reclassification } from 'src/actions/grid/product/reclass'

interface OwnProps {
  gridName: GridType
}

const mapStateToProps = (state: AppState) => ({
  selectedIds: getMovableSelectedIds(state),
})

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  moveProducts: (hierarchySlug: string, idsToReclassify: string[]) =>
    dispatch(reclassification(hierarchySlug, idsToReclassify, gridName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoveToModal)
