import React from 'react'
import { ProductCard } from '../ProductCard/ProductCard'
import { ProductWithDynabutes } from 'src/types/Product'
import { linksGroup, heading, listItem } from './LinksGroup.scss'
import { LinkMember, LinkType } from 'src/types/Links'

type Props = {
  name: string
  linkType?: LinkType
  selectedProduct: ProductWithDynabutes
  linkedProducts: LinkMember[]
  onRemove: (productSlug: string, productToRemove: string) => void
  removeTitle: string
}

export const LinksGroup = ({
  name,
  linkType,
  linkedProducts,
  selectedProduct,
  onRemove,
  removeTitle,
}: Props): JSX.Element => {
  return (
    <div data-cy="links-group" className={linksGroup}>
      <h4 className={heading}>{name}:</h4>
      <ul>
        <li className={listItem} key={selectedProduct.slug}>
          <ProductCard
            developmentId={selectedProduct.developmentId}
            productDescription={selectedProduct.productDescription}
            linkType={linkType}
            linkTitle="Matching Products Link"
            productNumber={
              selectedProduct.productNumbers && selectedProduct.productNumbers.length > 0
                ? selectedProduct.productNumbers[0]
                : ''
            }
            additionalColumn={{
              name: 'Colour',
              value: selectedProduct.colour,
            }}
            images={selectedProduct.images}
            wsPrimaryThumbnail={selectedProduct.wsPrimaryThumbnail}
            selected
            onRemove={(): void => onRemove(selectedProduct.slug, selectedProduct.slug)}
            removalConfirmationMessage={`Removing the selected product will remove it from the ${name} link`}
            removeTitle={removeTitle}
          />
        </li>

        {linkedProducts.map(
          (
            {
              developmentId,
              productDescription,
              productNumber,
              productSlug,
              images,
              colour,
              wsPrimaryThumbnail,
            },
            index,
          ) => (
            <li className={listItem} key={productSlug}>
              <ProductCard
                key={index}
                developmentId={developmentId}
                additionalColumn={{
                  name: 'Colour',
                  value: colour,
                }}
                linkType={linkType}
                linkTitle="Matching Products Link"
                productDescription={productDescription}
                productNumber={productNumber && productNumber.length > 0 ? productNumber[0] : ''}
                images={images}
                wsPrimaryThumbnail={wsPrimaryThumbnail}
                onRemove={(): void => onRemove(selectedProduct.slug, productSlug)}
                removeTitle={removeTitle}
              />
            </li>
          ),
        )}
      </ul>
    </div>
  )
}
