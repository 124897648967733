// adapted from https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js#L87

// @ts-nocheck
import React from 'react'

export const focusTrap = (
  parent: HTMLElement,
  trapQueryString: string,
  event: KeyboardEvent | React.KeyboardEvent,
) => {
  const focusableItems = parent.querySelectorAll(trapQueryString)
  const focusedItem = document.activeElement
  const numberOfFocusableItems = focusableItems.length
  const focusedItemIndex = Array.prototype.indexOf.call(focusableItems, focusedItem)

  if (event.shiftKey) {
    if (focusedItemIndex === 0) {
      focusableItems[numberOfFocusableItems - 1].focus()
      event.preventDefault()
    }
  } else {
    if (focusedItemIndex === numberOfFocusableItems - 1) {
      focusableItems[0].focus()
      event.preventDefault()
    }
  }
}
