import { DepartmentViews, GridViews, Rule } from 'src/types/DepartmentSpec'
import {
  DEVELOPMENT,
  PRODUCT_STATUS_PENDING_ODBMS_KEY,
  PROPOSED,
  READY_TO_BUY,
} from 'src/constants/productStatus'
import { GridType } from 'src/types/index'
import { ProductStatusKey } from 'src/types/ProductStatus'
import { ApiDepartmentAttribute, ApiProperties } from 'src/types/responses/departmentSpec'

const columnsForViewSlug = (
  viewSlug: string,
  attributes: ApiDepartmentAttribute[],
  properties: ApiProperties[],
) =>
  [...attributes, ...properties]
    .filter(({ views }) => views && views.includes(viewSlug))
    .map(({ name }) => name)

const buildGridView = (
  gridViews: GridViews,
  attributes: ApiDepartmentAttribute[],
  properties: ApiProperties[],
) =>
  Object.keys(gridViews).reduce(
    (acc, viewSlug) => ({
      ...acc,
      [viewSlug]: {
        ...gridViews[viewSlug],
        columns: columnsForViewSlug(viewSlug, attributes, properties),
      },
    }),
    {},
  )

export const apiViewsToViews = (
  apiAttributes: ApiDepartmentAttribute[],
  apiProperties: ApiProperties[],
  viewsReferenceData: DepartmentViews,
): DepartmentViews => {
  return Object.keys(viewsReferenceData).reduce(
    (acc, grid) => ({
      ...acc,
      [grid]: buildGridView(viewsReferenceData[grid as GridType], apiAttributes, apiProperties),
    }),
    {} as DepartmentViews,
  )
}

const defaultRules: Record<
  Exclude<ProductStatusKey, typeof PRODUCT_STATUS_PENDING_ODBMS_KEY>,
  Rule
> = {
  development: {
    mandatory: [],
    locked: [],
  } as Rule,
  proposed: {
    mandatory: [],
    locked: [],
  } as Rule,
  readyToBuy: {
    mandatory: [],
    locked: [],
  } as Rule,
  pending: {
    mandatory: [],
    locked: [],
  } as Rule,
  cancelled: {
    mandatory: [],
    locked: [],
  } as Rule,
}

const addRules = (
  { name, rule }: ApiDepartmentAttribute | ApiProperties,
  { mandatory, locked }: Rule,
  statuses: string | any[],
) => ({
  mandatory: [...mandatory, ...(statuses.includes(rule.mandatory) ? [name] : [])],
  locked: [...locked, ...(statuses.includes(rule.locked) ? [name] : [])],
})

export const apiRulesToRules = (
  attributes: ApiDepartmentAttribute[],
  properties: ApiProperties[],
): Record<Exclude<ProductStatusKey, typeof PRODUCT_STATUS_PENDING_ODBMS_KEY>, Rule> =>
  [...attributes, ...properties].reduce(
    (acc, attributeOrProperty) => ({
      development: addRules(attributeOrProperty, acc.development, [DEVELOPMENT]),
      proposed: addRules(attributeOrProperty, acc.proposed, [DEVELOPMENT, PROPOSED]),
      pending: addRules(attributeOrProperty, acc.pending, [DEVELOPMENT, PROPOSED]),
      readyToBuy: addRules(attributeOrProperty, acc.readyToBuy, [
        DEVELOPMENT,
        PROPOSED,
        READY_TO_BUY,
      ]),
      cancelled: {
        mandatory: [],
        locked: [],
      },
    }),
    defaultRules,
  )

export const apiToAllProperties = (
  apiAttributes: ApiDepartmentAttribute[],
  apiProperties: ApiProperties[],
) => [...apiProperties.map(({ name }) => name), ...apiAttributes.map(({ name }) => name)]

export const convertApiSpecs = (
  apiAttributes: ApiDepartmentAttribute[],
  apiProperties: ApiProperties[],
  viewsReferenceData: DepartmentViews,
) => ({
  allProperties: apiToAllProperties(apiAttributes, apiProperties),
  rules: apiRulesToRules(apiAttributes, apiProperties),
  views: apiViewsToViews(apiAttributes, apiProperties, viewsReferenceData),
})
