import { combineReducers } from 'redux'

import { PinnedProductSlugsState, pinnedProductSlugs } from './common/grid/pinnedProductSlugs'
import { ProductsState, products } from './common/grid/product'
import { SortedColumnState, sortedColumns } from './common/grid/sortedColumns'
import { ExactFiltersState, exactFilters } from './common/grid/exactFilters'
import { PartialFiltersState, partialFilters } from './common/grid/partialFilters'
import { HighlightedRowsState, highlightedRows } from './common/grid/highlightedRows'
import { HierarchyReducerState, hierarchy } from './common/grid/hierarchy'
import { createGridSpecificReducer } from './common/createGridSpecificReducer'
import { OPTION_LIBRARY } from 'src/types/index'
import { DepartmentSpecsState, departmentSpecs } from './common/grid/departmentSpecs'
import { MultiSelectState, multiSelect } from './common/grid/multiSelect'
import { UniqueFilterValuesState, uniqueFilterValues } from './common/grid/uniqueFilterValues'
import { hiddenColumns, HiddenColumnState } from './common/grid/hiddenColumns'

export type OptionLibraryState = {
  hierarchy: HierarchyReducerState
  sortedColumns: SortedColumnState
  exactFilters: ExactFiltersState
  partialFilters: PartialFiltersState
  uniqueFilterValues: UniqueFilterValuesState
  pinnedProductSlugs: PinnedProductSlugsState
  highlightedRows: HighlightedRowsState
  multiSelect: MultiSelectState
  products: ProductsState
  departmentSpecs: DepartmentSpecsState
  hiddenColumns: HiddenColumnState
}

export const optionLibrary = combineReducers({
  sortedColumns: createGridSpecificReducer(sortedColumns, OPTION_LIBRARY),
  exactFilters: createGridSpecificReducer(exactFilters, OPTION_LIBRARY),
  partialFilters: createGridSpecificReducer(partialFilters, OPTION_LIBRARY),
  uniqueFilterValues: createGridSpecificReducer(uniqueFilterValues, OPTION_LIBRARY),
  pinnedProductSlugs: createGridSpecificReducer(pinnedProductSlugs, OPTION_LIBRARY),
  highlightedRows: createGridSpecificReducer(highlightedRows, OPTION_LIBRARY),
  multiSelect: createGridSpecificReducer(multiSelect, OPTION_LIBRARY),
  hierarchy: createGridSpecificReducer(hierarchy, OPTION_LIBRARY),
  products: createGridSpecificReducer(products, OPTION_LIBRARY),
  departmentSpecs: createGridSpecificReducer(departmentSpecs, OPTION_LIBRARY),
  hiddenColumns: createGridSpecificReducer(hiddenColumns, OPTION_LIBRARY),
})
