import { FETCH_STYLE, FETCH_STYLE_FAILURE, FETCH_STYLE_SUCCESS } from '../actions/grid/style'
import { UPDATE_PRODUCT_SUCCESS } from '../actions/grid/product/update'
import {
  ERROR_STATUS,
  FETCHING_STATUS,
  READY_STATUS,
  UNINITIALISED_STATUS,
} from '../constants/requests'
import { RequestStatus } from 'src/types/Api'
import { Option, Style } from 'src/types/Style'
import { ProductWithDynabutes } from 'src/types/Product'
import { SVPAction } from 'actions/svp'

export type StyleWithStatusState = {
  status: RequestStatus
  current: Style | null | undefined
}

const defaultState: StyleWithStatusState = {
  status: UNINITIALISED_STATUS,
  current: undefined,
}

const containsUpdatedProduct = (option: Option, updateProductSlug: string) =>
  option.products.find((product) => product.slug === updateProductSlug)

const updateProducts = (
  products: ProductWithDynabutes[],
  updateProductSlug: string,
  patchFields: any,
) =>
  products.map((product) =>
    product.slug === updateProductSlug ? { ...product, ...patchFields } : product,
  )

const updateOptions = (options: Option[], updateProductSlug: string, patchFields: any) =>
  options.map((option) =>
    containsUpdatedProduct(option, updateProductSlug)
      ? {
          ...option,
          products: updateProducts(option.products, updateProductSlug, patchFields),
        }
      : option,
  )

export const style = (state: StyleWithStatusState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_STYLE:
      return {
        status: FETCHING_STATUS,
        current: null,
      }
    case FETCH_STYLE_FAILURE:
      return {
        status: ERROR_STATUS,
        current: null,
      }
    case FETCH_STYLE_SUCCESS:
      return {
        status: READY_STATUS,
        current: action.style,
      }
    case UPDATE_PRODUCT_SUCCESS:
      return state.current
        ? {
            ...state,
            current: {
              ...state.current,
              options: updateOptions(state.current.options, action.productSlug, action.patchFields),
            },
          }
        : state
    default:
      return state
  }
}
