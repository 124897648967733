import { combineReducers } from 'redux'
import { commsScreen, CommsScreenState } from './commsScreen'
import { adminDepartments, AdminDepartmentsState } from './departments'

export type AdminState = {
  departments: AdminDepartmentsState
  commsScreen: CommsScreenState
}

export const reducers = {
  departments: adminDepartments,
  commsScreen: commsScreen,
}

// @ts-ignore
export const admin = combineReducers(reducers)
