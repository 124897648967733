import { GetAuth } from '@ri-digital/auth0-authenticator'
import { Config } from 'helpers/config'
import authHeader from './headers'

const ssoLogin = (redirectUri: string): void => {
  const auth = GetAuth()
  const connection = Config().Get('connection')
  auth.authorize({
    redirectUri,
    connection,
  })
}

export { authHeader, ssoLogin }
