import { ApiOption, ApiStyle, Style } from 'src/types/Style'
import { apiProductToProductWithDefaults } from './product'
import { AttributeValues, Attribute, Country } from 'src/types/index'

export const apiStyleToStyle = (
  apiStyle: ApiStyle,
  attributes: Array<Attribute>,
  attributeValues: AttributeValues,
  countries: Country[],
): Style => {
  return apiStyle.options.reduce(
    (acc: Style, option: ApiOption) => {
      acc.options.push({
        slug: option.slug,
        products: option.products.map((product) =>
          apiProductToProductWithDefaults(product, attributes, attributeValues, countries),
        ),
      })
      return acc
    },
    {
      slug: apiStyle.slug || '',
      styleID: apiStyle.styleID || '',
      styleDescription: apiStyle.styleDescription || '',
      options: [],
    },
  )
}
