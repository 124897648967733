import { AxiosError } from 'axios'
import { logError } from '../../errors/errorLogger'

export function apiErrorInterceptor(
  error: Partial<AxiosError> | undefined,
): Promise<void> | undefined {
  if (!error || !error.response || error.response.status < 500) {
    return Promise.reject(error)
  }

  logError(new Error(error.toString()))
}
