import React, { Component } from 'react'

import Column from 'src/components/SizeModal/Column/Column'
import Section from 'src/components/SizeModal/Section/Section'

import { productNumberSpan } from './ProductNumberColumn.scss'

type Props = {
  group: string
  productNumber?: string
  hideSection?: boolean
  showColumnTitle?: boolean
  isLastSection?: boolean
  isProductReadyToBuy?: boolean
}

export default class ProductNumberColumn extends Component<Props> {
  render() {
    const {
      productNumber,
      group,
      isLastSection,
      showColumnTitle,
      hideSection,
      isProductReadyToBuy,
    } = this.props

    if (hideSection) {
      return (
        <Column title="Product #" showHeader={showColumnTitle} testId="product_number_column" />
      )
    }

    return (
      <Column title="Product #" showHeader={showColumnTitle} testId="product_number_column">
        <Section
          key={group}
          sizeGroup={group}
          isLastSection={isLastSection}
          testId={`product_number_column_${group}_section`}
        >
          {isProductReadyToBuy && productNumber ? (
            <span className={productNumberSpan} key={productNumber}>
              {productNumber}
            </span>
          ) : (
            <></>
          )}
        </Section>
      </Column>
    )
  }
}
