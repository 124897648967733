import React from 'react'
import { AdminHeader } from 'src/components/Admin/AdminHeader/AdminHeader'
import { Routes } from './Routes'
import { RISpinner } from 'src/components/RISpinner/RISpinner'
import { Status } from 'src/selectors/status'

import { admin, adminBody, spinnerContainer } from './Page.scss'

type Props = {
  dependenciesStatus: Status
}

export const Page = ({ dependenciesStatus }: Props) => (
  <div id="Admin" className={admin}>
    <AdminHeader />
    {dependenciesStatus === 'ready' ? (
      <section className={adminBody}>
        <Routes />
      </section>
    ) : (
      <RISpinner colour="black" containerStyle={spinnerContainer} />
    )}
  </div>
)
