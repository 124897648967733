import { connect } from 'react-redux'
import { isAuthenticated } from '@ri-digital/auth0-authenticator'

import { AppState } from 'src/reducers/root'
import { PrimaryNavigation } from './PrimaryNavigation'
import { isAdmin } from '../../selectors/userProfile'
import { isGridLocked } from 'src/selectors/grid'

const mapStateToProps = (state: AppState) => ({
  isAdmin: isAdmin(state),
  isLoggedIn: isAuthenticated(state.authentication),
  locked: isGridLocked(state),
})

export default connect(mapStateToProps)(PrimaryNavigation)
