export const IMAGE_TOO_LARGE_ERROR = 'File exceeds limit of 200Mb, please use a smaller file'

export const IMAGE_FORMAT_ERROR =
  'Incorrect file format, please save document in PDF, AI, JPEG or PNG format'

export const fileSizeLimit = 1024 * 1024 * 200

const isInvalidFile = (file: File) => {
  return file == null || !file.name.match(/\.(pdf|ai|png|jpg|jpeg)$/i)
}

const isFileTooBig = (file: File) => {
  return file.size > fileSizeLimit
}

export const validateImageFile = (file: File): string | undefined => {
  if (isInvalidFile(file)) {
    return IMAGE_FORMAT_ERROR
  } else if (isFileTooBig(file)) {
    return IMAGE_TOO_LARGE_ERROR
  }
}
