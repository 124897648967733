import { logError } from '../errors'
import { extractImage, ImageProcessingError, IMAGE_PROCESSING_ERROR } from './processImage'
import { validateImageFile } from './validate'

export const CORRUPTED_IMAGE_ERROR =
  'There was a problem processing the document. Please check that it is a valid PDF, AI, JPEG or PNG file.'

const GENERAL_ERROR_MESSAGE =
  "Sorry something has broken in the tech and we couldn't process the image, Please retry."

export type OnChange = (extractedImage: string | null) => void
export type OnParsing = (success: boolean, num: number) => void
export type OnError = (message: string) => void

function getErrorMessage(error: ImageProcessingError) {
  return error.code === IMAGE_PROCESSING_ERROR ? CORRUPTED_IMAGE_ERROR : GENERAL_ERROR_MESSAGE
}

export const parseFile = async (
  onChange: OnChange,
  onParsing: OnParsing,
  onError: OnError,
  file: File,
  currentIndex = 0,
) => {
  const validationResult = validateImageFile(file)

  if (validationResult) {
    onError(validationResult)
    return
  }

  onParsing(true, currentIndex)
  onChange(null)

  const extractedImage = await extractImage(file).catch((error) => {
    logError(error)
    onError(getErrorMessage(error))
  })

  extractedImage && onChange(extractedImage)
  onParsing(false, currentIndex)
}
