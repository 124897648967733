import { CreateProductFormFields, ViewProduct, EnrichedViewProduct } from 'types/Product'
import { getDropdownValidationErrors } from './validate'
import { getTextValidationErrors } from './validateUtils'
import { CompanyWideLevel } from 'src/constants/hierachy'
import { TreeHierarchyItem } from 'src/types/index'
import trim from 'lodash/trim'
import { NEW_SUPPLIER_FAKE_SLUG } from '../../pages/createProduct/ProductForm/ProductForm'

const setFocus = (fields: CreateProductFormFields): CreateProductFormFields => {
  Object.keys(fields).some((field) => {
    if (fields[field] && fields[field].validationErrors.length !== 0 && field !== 'hierarchy') {
      fields[field].hasFocus = true
      return true
    }
  })

  return fields
}

const validateFormFields = ({
  hierarchy,
  styleGroup,
  productDescription,
  styleName,
  colour,
  print,
  fabricType,
  season,
  supplier,
  priceArchitecture,
  sustainableReason,
}: CreateProductFormFields): CreateProductFormFields => ({
  hierarchy: {
    ...hierarchy,
    validationErrors: getDropdownValidationErrors(hierarchy.data, hierarchy.isMandatory),
  },
  styleGroup: {
    ...styleGroup,
    validationErrors: getDropdownValidationErrors(styleGroup.data, styleGroup.isMandatory),
  },
  productDescription: {
    ...productDescription,
    validationErrors: getTextValidationErrors(
      productDescription.data,
      productDescription.isMandatory,
    ),
  },
  styleName: {
    ...styleName,
    validationErrors: getTextValidationErrors(styleName.data, styleName.isMandatory),
  },
  colour: {
    ...colour,
    validationErrors: getDropdownValidationErrors(colour.data, colour.isMandatory),
  },
  print: {
    ...print,
    validationErrors: getDropdownValidationErrors(print.data, print.isMandatory),
  },
  fabricType: {
    ...fabricType,
    validationErrors: getDropdownValidationErrors(fabricType.data, fabricType.isMandatory),
  },
  season: {
    ...season,
    validationErrors: getDropdownValidationErrors(season.data, season.isMandatory),
  },
  supplier: {
    ...supplier,
    validationErrors: getDropdownValidationErrors(supplier.data, supplier.isMandatory),
  },
  priceArchitecture: {
    ...priceArchitecture,
    validationErrors: getDropdownValidationErrors(
      priceArchitecture ? priceArchitecture.data : null,
      priceArchitecture ? priceArchitecture.isMandatory : false,
    ),
  },
  sustainableReason: {
    ...sustainableReason,
    validationErrors: getDropdownValidationErrors(
      sustainableReason.data,
      sustainableReason.isMandatory,
    ),
  },
})

const basicFields = {
  isValid: true,
  validationErrors: [],
  hasFocus: false,
  isMandatory: true,
}

const emptyHierarchyFieldData: TreeHierarchyItem = {
  title: '',
  slug: '',
  levelName: CompanyWideLevel,
  levelCode: '',
  children: [],
}

const emptyHierarchyField = {
  data: emptyHierarchyFieldData,
  ...basicFields,
}

const emptyTextField = {
  data: '',
  ...basicFields,
}

const emptyDropDownField = {
  data: {
    value: '',
    slug: '',
    code: '',
    deprecated: false,
  },
  ...basicFields,
}

const emptyDropDownMultiField = {
  data: [],
  ...basicFields,
}

export const emptyFields = (): CreateProductFormFields => ({
  hierarchy: emptyHierarchyField,
  styleGroup: {
    ...emptyDropDownField,
    isMandatory: true,
  },
  productDescription: emptyTextField,
  styleName: {
    ...emptyTextField,
    isMandatory: false,
  },
  colour: emptyDropDownField,
  print: emptyDropDownField,
  fabricType: emptyDropDownField,
  season: emptyDropDownField,
  supplier: emptyDropDownField,
  priceArchitecture: emptyDropDownField,
  sustainableReason: {
    ...emptyDropDownMultiField,
    isMandatory: false,
  },
})

export const formFieldsToProduct = (formFields: CreateProductFormFields): ViewProduct => ({
  hierarchy: formFields.hierarchy.data,
  styleGroup: formFields.styleGroup.data,
  productDescription: formFields.productDescription.data,
  styleName: formFields.styleName.data,
  colour: formFields.colour.data,
  print: formFields.print.data,
  fabricType: formFields.fabricType.data,
  season: formFields.season.data,
  supplier: formFields.supplier.data,
  priceArchitecture: formFields.priceArchitecture
    ? formFields.priceArchitecture.data
    : emptyDropDownField.data,
  sustainableReason: formFields.sustainableReason.data,
})

export const validateAndSetFocus = (fields: CreateProductFormFields): CreateProductFormFields =>
  setFocus(validateFormFields(fields))

export const sanitizeProduct = (product: EnrichedViewProduct) => {
  return {
    colour: product.colour.slug,
    fabricType: product.fabricType.slug,
    seasonCode: product.season.code,
    seasonality: product.seasonality.slug,
    print: product.print.slug,
    styleDescription: trim(product.productDescription),
    productDescription: trim(product.productDescription),
    styleName: trim(product.styleName),
    hierarchy: product.hierarchy.slug,
    supplier: product.supplier.slug === NEW_SUPPLIER_FAKE_SLUG ? null : product.supplier.slug,
    styleGroup: product.styleGroup.slug,
    priceArchitecture: product.priceArchitecture ? product.priceArchitecture.slug : null,
    sustainableReason: product.sustainableReason
      ? product.sustainableReason.map((sr) => sr.value)
      : [],
  }
}
