import React from 'react'
import { ProductWithDynabutes } from '../../../../types/Product'
import { SimpleDropdown } from '../../../Dropdown/SimpleDropdown/SimpleDropdown'
import PrimaryButton from '../../../PrimaryButton/PrimaryButton'

import { TextField } from '../../../TextField/TextField'
import { textAndAddContainer, button } from './AddLink.scss'
import { SMALL } from '../../../../types/ComponentSizes'
import { ENTER_KEY } from '../../../../constants/keyCodes'
import { DropdownOption } from '../../../../types/Dropdowns'

type Props = {
  selectedProduct: ProductWithDynabutes
  addMatchingProductLink: (product: ProductWithDynabutes, val: string) => void
  addCrossDepartmentLink: (product: ProductWithDynabutes, val: string) => void
  addColourVariant: (product: ProductWithDynabutes, val: string) => void
  addOptionLinkLine: (product: ProductWithDynabutes, val: string) => void
}

type State = {
  selectedOption?: DropdownOption
  devIdOrProductNumber: string
}

const styleDropdownOptions: DropdownOption[] = [
  { value: 'crossDepartment', label: 'Cross Department' },
  { value: 'matchingProduct', label: 'Matching Product' },
  { value: 'optionLinkLine', label: 'Option (Link Line)' },
]

const styleLinkTypeAddTo = {
  matchingProduct: (
    props: Props,
    product: ProductWithDynabutes,
    devIdOrProductNumber: string,
  ): void => {
    props.addMatchingProductLink(product, devIdOrProductNumber)
  },
  crossDepartment: (
    props: Props,
    product: ProductWithDynabutes,
    devIdOrProductNumber: string,
  ): void => {
    props.addCrossDepartmentLink(product, devIdOrProductNumber)
  },
  optionLinkLine: (
    props: Props,
    product: ProductWithDynabutes,
    devIdOrProductNumber: string,
  ): void => {
    props.addOptionLinkLine(product, devIdOrProductNumber)
  },
}
export class AddLink extends React.Component<Props, State> {
  state = {
    selectedOption: undefined,
    devIdOrProductNumber: '',
  }

  chooseLinkType = (dropdownOption: DropdownOption): void => {
    this.setState({ selectedOption: dropdownOption })
  }

  inputDevIdOrProductNumber = (devIdOrProductNumber: string): void => {
    devIdOrProductNumber = devIdOrProductNumber.toUpperCase()
    this.setState({ devIdOrProductNumber })
  }

  onClick = (): void => {
    if (this.state.selectedOption && this.state.devIdOrProductNumber) {
      styleLinkTypeAddTo[this.state.selectedOption.value](
        this.props,
        this.props.selectedProduct,
        this.state.devIdOrProductNumber,
      )
      this.setState({
        devIdOrProductNumber: '',
      })
    }
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === ENTER_KEY) {
      this.onClick()
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <SimpleDropdown
          name="linkType"
          placeholder="Select link"
          options={styleDropdownOptions}
          onChange={this.chooseLinkType}
          selectedOption={this.state.selectedOption}
          size={SMALL}
        />
        <div className={textAndAddContainer}>
          <TextField
            name="devIdOrProductNumber"
            placeholder="Dev ID / Product #"
            value={this.state.devIdOrProductNumber}
            onChange={this.inputDevIdOrProductNumber}
            size={SMALL}
            onKeyDown={(event): void => this.onKeyDown(event)}
          />
          <PrimaryButton
            className={button}
            disabled={!this.state.selectedOption || this.state.devIdOrProductNumber === ''}
            onClick={this.onClick}
          >
            Add
          </PrimaryButton>
        </div>
      </div>
    )
  }
}
