import { AttributeRenderSpec, AttributeValue } from 'src/types/index'
import { MIN_DIGITS } from 'src/constants/adminRenderSpecs'
import { DropdownOption } from 'src/types/Dropdowns'

export const cleanAttributeName = (displayName: string) =>
  displayName.replace(/[^a-zA-Z]/g, '').toLowerCase()

export const checkForMinCharacters = (displayName: string) => displayName.length >= MIN_DIGITS

export const alphabeticallySortAttributeValues = (
  current: AttributeValue | DropdownOption,
  prev: AttributeValue | DropdownOption,
) =>
  alphabeticallySortString(
    current.value.toString().toLowerCase(),
    prev.value.toString().toLowerCase(),
  )

export const alphabeticallySortString = (current: string, previous: string) =>
  current.localeCompare(previous)

export const validateAttributeName = (
  displayName: string,
  attributes: AttributeRenderSpec[],
  currentAttribute: AttributeRenderSpec,
) => {
  if (displayName.length === 0) {
    return ['This field is mandatory']
  }

  const alreadyExisting = attributes
    .filter((attribute) => attribute.property !== currentAttribute.property)
    .map(({ displayName }) => cleanAttributeName(displayName))
    .includes(cleanAttributeName(displayName))

  if (alreadyExisting) {
    return ['The attribute already exists. Please try again.']
  }

  return []
}
