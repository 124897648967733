import { FETCH_DROPDOWN_ITEMS_SUCCESS } from '../../../actions/grid/exactFilters'
import { DropdownOption } from '../../../types/Dropdowns'

export type UniqueFilterValuesState = {
  [key: string]: DropdownOption[]
}

export const uniqueFilterValues = (state: UniqueFilterValuesState = {}, action: any) => {
  switch (action.type) {
    case FETCH_DROPDOWN_ITEMS_SUCCESS:
      return { ...state, [action.columnName]: action.items }
    default:
      return state
  }
}
