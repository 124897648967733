import React from 'react'
import { background, camera, image, uploadSketch } from './SketchSection.scss'
import { SketchImage } from '../SketchImage/SketchImage'
import { Camera } from '../SidePanelDrawer/icons/Camera'
import { UploadSpinner } from '../UploadSpinner'

interface Props {
  developmentId: string
  sketchImage?: string | null
  isSketchUploadInProgress: boolean
  className?: boolean
}

export const SketchSection = ({
  developmentId,
  sketchImage,
  isSketchUploadInProgress,
  className,
}: Props) => (
  <section className={`${className || background} ${isSketchUploadInProgress ? uploadSketch : ''}`}>
    {isSketchUploadInProgress ? (
      <UploadSpinner />
    ) : sketchImage ? (
      <SketchImage className={image} src={sketchImage} alt={`image for product ${developmentId}`} />
    ) : (
      <Camera className={camera} />
    )}
  </section>
)
