import { connect } from 'react-redux'
import { AddLink } from './AddLink'
import { addMatchingProductLink, addCrossDepartmentLink } from '../../../../actions/grid/links'
import { addColourVariant, addOptionLinkLine } from '../../../../actions/grid/style'

const mapDispatchToProps = {
  addMatchingProductLink,
  addCrossDepartmentLink,
  addColourVariant,
  addOptionLinkLine,
}

export const AddLinkContainer = connect(null, mapDispatchToProps)(AddLink)
