import React, { Component } from 'react'
import { Lock } from 'src/types/index'
import {
  lockToolContainer,
  header,
  title,
  subtitle,
  lockContainer,
  buttonDiv,
  buttonGroup,
  lockButton,
  modalTitle,
  modalText,
} from './LockTool.scss'
import { Modal } from 'src/components/Modal/Modal'
import { SkipLink } from 'src/components/Link'
import { LoadingButton } from 'src/components/buttons/LoadingButton/LoadingButton'
import { LockIcon } from 'src/assets/icons'
import { LOCKED, UNLOCKED } from 'src/actions/lock'
import moment from 'moment'

type Props = {
  lock: Lock
  isLocked: boolean
  setLock: (status: string) => void
}

type State = {
  shouldOpenModal: boolean
}

export class LockTool extends Component<Props, State> {
  state = {
    shouldOpenModal: false,
  }

  openModal = (): void => this.setState({ shouldOpenModal: true })
  closeModal = (): void => this.setState({ shouldOpenModal: false })

  updateLock = (): void => {
    this.props.setLock(this.props.isLocked ? UNLOCKED : LOCKED)
    this.closeModal()
  }

  lockText =
    'Locking the system temporarily will prevent users from\ncreating products and editing attributes.'
  unlockText = 'Unlocking the system will enable users to\ncreate products and edit attributes.'

  render(): JSX.Element {
    const formattedLockDate = moment(this.props.lock.date).format('DD-MMM-YYYY@HH.mm')
    return (
      <div className={lockToolContainer}>
        <div className={lockContainer}>
          <div className={header}>
            <h3 className={title}>System Lock</h3>
            <div className={subtitle}>
              Press the lock icon below to {this.props.isLocked ? ' unlock' : ' temporarily lock'}
              the entire system.
              <p>The Lock status was last updated on {formattedLockDate}</p>
              <div className={buttonDiv}>
                <button className={lockButton} onClick={this.openModal}>
                  <LockIcon
                    className="lock-icon"
                    borderColor={this.props.isLocked ? '#FF0000' : '#26B3AD'}
                    iconColor="#000"
                  />
                  {this.props.isLocked ? 'Unlock' : 'Lock'} SVP
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.shouldOpenModal && (
          <Modal onCloseFn={this.closeModal}>
            <div className={modalTitle}>Are you sure?</div>
            <div className={modalText}>{this.props.isLocked ? this.unlockText : this.lockText}</div>
            <div className={buttonGroup}>
              <SkipLink onClick={this.closeModal}>Cancel</SkipLink>
              <LoadingButton colour="black" isLoading={false} onClick={this.updateLock}>
                {this.props.isLocked ? 'Unlock' : 'Lock'}
              </LoadingButton>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}
