import XLSX from 'xlsx'

const optionLibrarySheet = 'Option Library'

export const parseFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      // @ts-ignore
      const result = e.target.result
      // @ts-ignore
      const data = new Uint8Array(result)
      const workBook = XLSX.read(data, { type: 'array' })
      const importSheet = workBook.Sheets[optionLibrarySheet]

      importSheet
        ? resolve(XLSX.utils.sheet_to_json(importSheet))
        : reject(new Error('Invalid file'))
    }

    reader.readAsArrayBuffer(file)
  })
}
