import { connect } from 'react-redux'
import { GridType } from 'src/types/index'
import { selectHierarchies } from 'src/actions/grid/hierarchy'
import { OptionLibraryGrid } from './OptionLibraryGrid'

const mapDispatchToProps = (dispatch: any) => ({
  selectHierarchies: (hierarchySlugs: string[], grid: GridType) =>
    dispatch(selectHierarchies(hierarchySlugs, grid)),
})

export const OptionLibraryGridContainer = connect(null, mapDispatchToProps)(OptionLibraryGrid)
