import React from 'react'
import { notAvailable } from './Tool.scss'
import SeasonTool from './SeasonTool'
import CountryOfOriginTool from './CountryOfOriginTool'
import LockTool from './LockTool'
import CommsScreenTool from './CommsScreenTool'

type Props = {
  selectedToolKey: string
}

const renderers: Record<string, React.ComponentType<any>> = {
  countryOfOrigin: CountryOfOriginTool,
  seasonCalendar: SeasonTool,
  lock: LockTool,
  commsScreen: CommsScreenTool,
}

export const Tool = ({ selectedToolKey }: Props): JSX.Element => {
  const Tool = renderers[selectedToolKey]
  return selectedToolKey in renderers ? <Tool /> : <h4 className={notAvailable}>Not Available</h4>
}
