import React from 'react'
import { connect } from 'react-redux'

import { getNotification, NotificationState } from '../../reducers/notification/reducer'
import { clearNotification } from '../../actions/notification'
import { Notification } from './Notification'
import { AppState } from '../../reducers/root'

type Props = {
  notification: NotificationState
  clearNotification: () => void
}

const mapStateToProps = (state: AppState) => ({
  notification: getNotification(state),
})

export const NotificationContainer = ({ notification, clearNotification }: Props) =>
  notification && (
    <Notification
      type={notification.type}
      message={notification.message}
      clearNotification={clearNotification}
    />
  )

export default connect(mapStateToProps, { clearNotification })(NotificationContainer)
