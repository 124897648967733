import React from 'react'
import classNames from 'classnames'

import { addEventListener, setFocus } from 'src/service/ref/index'
import { ENTER_KEY } from 'src/constants/keyCodes'
import {
  checkboxChecked,
  checkboxNotChecked,
  checkBoxLink,
  checkboxDisabled,
  wrapper,
  label,
  littleIcon,
} from './CheckboxButton.scss'
import { CheckIcon } from '../../assets/icons'
import { Link } from '../Link/Link'

type Props = {
  checked: boolean
  onClick: (value: boolean) => void
  hasFocus?: boolean
  onBlur?: (e?: React.FocusEvent<HTMLAnchorElement>) => void
  disabled?: boolean
  withBorder?: boolean
  checkedLabel: string
  uncheckedLabel: string
  dataCy?: string
}

const green = '#41B97A'
const black = '#000000'

export class CheckboxButton extends React.Component<Props> {
  ref: any

  onEnter = (e: KeyboardEvent) => {
    if (e.key === ENTER_KEY && !this.props.disabled) {
      this.props.onClick(!this.props.checked)
    }
  }

  componentDidMount() {
    addEventListener(this.onEnter, 'keydown', this.ref)
  }

  componentDidUpdate() {
    if (this.props.hasFocus) {
      setFocus(this.ref)
    }
  }

  render() {
    const { onBlur, checked, disabled, checkedLabel, uncheckedLabel, dataCy } = this.props

    const checkboxClass = classNames(littleIcon, {
      [checkboxChecked]: checked,
      [checkboxNotChecked]: !checked,
      [checkboxDisabled]: disabled,
    })

    return (
      <Link
        dataCy={dataCy}
        className={checkBoxLink}
        onClick={this.handleOnClick}
        setRef={(node) => (this.ref = node)}
        onBlur={onBlur}
      >
        <div className={wrapper}>
          <CheckIcon className={checkboxClass} color={`${checked ? green : black}`} />
          <label className={label}>{checked ? checkedLabel : uncheckedLabel}</label>
        </div>
      </Link>
    )
  }

  handleOnClick = () => {
    const { onClick, checked, disabled } = this.props

    !disabled && onClick(!checked)
  }
}
