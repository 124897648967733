import { connect } from 'react-redux'
import { ActionsCellRenderer } from './ActionsCellRenderer'
import { getGrid } from '../../../selectors/grid'
import { AppState } from 'src/reducers/root'
import { getMultiSelectMode } from '../../../selectors/multiSelect'

const mapStateToProps = (state: AppState) => ({
  gridName: getGrid(state),
  multiSelect: getMultiSelectMode(state),
})

export const ActionsCellRendererContainer = connect(mapStateToProps)(ActionsCellRenderer)
