import { injectorService } from 'src/service/injector/injectorService'
import { apiProductLinksToProductLinks } from 'src/service/mappers/productLinks'
import { getProductsMap } from 'src/selectors/product'
import { updateProductSuccess } from 'src/actions/grid/product/update'
import { OPTION_LIBRARY, Product } from 'src/types/index'
import { errorAndNotification } from '../error'
import { call, put, select } from 'redux-saga/effects'
import { ApiProductLinks } from 'src/types/responses/links'
import { ProductMap } from 'types/Product'
import { APIError } from 'types/Api'

export function* clearGroupsSaga(product: Product) {
  try {
    const apiProductLinks: ApiProductLinks = yield call(
      injectorService.get,
      `products/${product.slug}/linked-products`,
    )

    const productLinks = apiProductLinksToProductLinks(apiProductLinks)

    const [aMatchingProduct, ...otherMatchingProducts] = productLinks.matchingProducts || []

    let products: ProductMap = yield select(getProductsMap)

    if (aMatchingProduct && otherMatchingProducts.length === 0) {
      yield put(
        updateProductSuccess(
          aMatchingProduct.productSlug,
          {
            groups: {
              ...products[aMatchingProduct.productSlug].groups,
              matchingProductGroupSlug: null,
            },
          },
          OPTION_LIBRARY,
        ),
      )
    }

    const [aCrossDepartmentProduct, ...otherCrossDepartmentProducts] =
      productLinks.crossDepartmentProducts || []

    products = yield select(getProductsMap)

    if (aCrossDepartmentProduct && otherCrossDepartmentProducts.length === 0) {
      yield put(
        updateProductSuccess(
          aCrossDepartmentProduct.productSlug,
          {
            groups: {
              ...products[aCrossDepartmentProduct.productSlug].groups,
              crossDepartmentGroupSlug: null,
            },
          },
          OPTION_LIBRARY,
        ),
      )
    }
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't update linking information. Please refresh the page.",
    )
  }
}
