import React from 'react'
import classNames from 'classnames'

import Link from '../Link'
import { TagTypes } from 'src/types/Tags'

import { tagLink, tagText, tag, hierarchyTag, filterTag, listTag } from './Tag.scss'

type Props = {
  value?: string
  filterKey?: string
  filterValue?: string
  onClose: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  className?: string
  deprecatedClassName?: string | boolean
  tagType?: TagTypes
  dataCy?: string
}

const tagTypeToClassName = {
  hierarchy: hierarchyTag,
  filter: filterTag,
  list: listTag,
}

export const Tag = ({
  value,
  filterKey,
  filterValue,
  onClose,
  className,
  deprecatedClassName,
  tagType = 'filter',
  dataCy,
}: Props): JSX.Element => {
  const tagTypeClass = classNames(tag, className, {
    [tagTypeToClassName[tagType]]: tagType,
  })

  return (
    <div className={tagTypeClass}>
      <Link dataCy={dataCy} className={tagLink} onClick={onClose}>
        <span data-cy={`delete ${value}`}>x</span>
      </Link>
      {value && <span className={`${tagText} ${deprecatedClassName || ''}`}>{value}</span>}

      {filterKey && filterValue && (
        <div>
          <span className={tagText}>{filterKey}:</span>
          <span className={`${tagText} ${deprecatedClassName || ''}`}>{filterValue}</span>
        </div>
      )}
    </div>
  )
}
