import React from 'react'
import { EditIcon } from 'src/assets/icons'
import SecondaryButton from 'src/components/SecondaryButton'
import {
  attributeDisplay,
  attributeDisplayName,
  attributeKey,
  attributeKeyValue,
  deprecatedListItem,
  editIcon,
  editIconButton,
  titleAndEditWrapper,
  value,
  valuesList,
} from './AttributeDisplay.scss'
import classNames from 'classnames'
import { AttributeRenderSpec } from 'src/types/index'
import { Modal } from 'src/components/Modal/Modal'
import { AttributeFormContainer } from 'src/components/Admin/AttributeForm/AttributeFormContainer'
import { INJECTOR_ATTRIBUTE_SOURCE, ODBMS_ATTRIBUTE_SOURCE } from 'src/constants/attributes'

interface AttributeProps {
  attribute: AttributeRenderSpec
}

interface AttributeState {
  showEditModal: boolean
}

const yesNo = (value: boolean): string => (value ? 'Yes' : 'No')

const editableODBMSAttributeNames = [
  'colour',
  'fabricType',
  'season',
  'seasonality',
  'endUseBrandsLooks',
  'distributionProfile',
  'trademarkBrand',
]

export class Attribute extends React.Component<AttributeProps, AttributeState> {
  state = {
    showEditModal: false,
  }

  editClicked = (): void => {
    this.setState({ showEditModal: true })
  }

  closeModal = (): void => {
    this.setState({ showEditModal: false })
  }

  render(): JSX.Element {
    const {
      attribute: {
        source,
        type,
        displayName,
        property,
        sortable,
        columnWidth,
        filterType,
        companyWide,
        values,
        duplicable,
      },
    } = this.props

    const isODBMS = source === ODBMS_ATTRIBUTE_SOURCE
    const isInjector = source === INJECTOR_ATTRIBUTE_SOURCE
    const isInEditableList = editableODBMSAttributeNames.includes(property)
    const isEditableODMBSAttribute = isODBMS && isInEditableList
    const editButtonEnabled = isInjector || isEditableODMBSAttribute

    return (
      <div data-cy="attribute-display">
        <div className={titleAndEditWrapper}>
          <h3 className={attributeDisplayName}>{displayName}</h3>
          <SecondaryButton
            className={editIconButton}
            disabled={!editButtonEnabled}
            onClick={this.editClicked}
          >
            <EditIcon className={editIcon} />
          </SecondaryButton>
        </div>
        <div className={attributeKeyValue}>
          <span data-cy="sortable-key" className={attributeKey}>
            Sortable: <span className={value}>{yesNo(sortable)}</span>
          </span>
          <span data-cy="column-width-key" className={attributeKey}>
            Column width: <span className={value}>{columnWidth}</span>
          </span>
          <span data-cy="filter-type-key" className={attributeKey}>
            Filter type: <span className={value}>{filterType}</span>
          </span>
          <span data-cy="company-wide-key" className={attributeKey}>
            Company wide: <span className={value}>{yesNo(companyWide)}</span>
          </span>
          <span data-cy="duplicable-key">
            Duplicable: <span className={value}>{yesNo(duplicable)}</span>
          </span>
        </div>
        <div data-cy="value-type-key" className={attributeKeyValue}>
          <span>
            Value type: <span className={value}>{type}</span>
          </span>
        </div>

        {values ? (
          <div data-cy="value-list-key" className={attributeKeyValue}>
            <span>Values:</span>
            <div data-cy="value-list" className={valuesList}>
              {values
                .sort((a, b) => a.value.localeCompare(b.value))
                .map((v, i) => (
                  <React.Fragment key={v.value}>
                    <span
                      data-cy="value-item"
                      className={classNames({
                        [deprecatedListItem]: v.deprecated,
                      })}
                    >
                      {v.value}
                    </span>
                    <span>{i !== values.length - 1 ? ', ' : ''}</span>
                  </React.Fragment>
                ))}
            </div>
          </div>
        ) : (
          ''
        )}
        {this.state.showEditModal && (
          <Modal data-cy="attribute-modal" onCloseFn={this.closeModal}>
            <AttributeFormContainer onCancel={this.closeModal} attribute={this.props.attribute} />
          </Modal>
        )}
      </div>
    )
  }
}

export const NoAttributeSelected = (): JSX.Element => <div>Please select an attribute</div>

interface AttributeDisplayProps {
  attribute?: AttributeRenderSpec
}

export const AttributeDisplay = ({ attribute }: AttributeDisplayProps): JSX.Element => (
  <div className={attributeDisplay}>
    {attribute ? <Attribute attribute={attribute} /> : <NoAttributeSelected />}
  </div>
)
