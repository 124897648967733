// HierachyTypes
export const SlugHierachyType = 'hierarchySlug'

// Hierachy Levels
export const DepartmentHierachyLevel = 'Department'
export const DPTHierachyLevel = 'DPT'
export const RangeHierachyLevel = 'Range'
export const RangeGroupHierachyLevel = 'Range Group'
export const SubRangeGroupHierachyLevel = 'Sub Range Group'
export const CompanyWideLevel = ''
export const BEAUTY_AND_BRANDS_DIVISION_NAME = 'Beauty and Brands'
