import React from 'react'
import Dropdown from 'src/components/Dropdown/Dropdown'
import { TextFormField } from 'src/components/TextFormField'
import { CheckboxFormField } from 'src/components/CheckboxFormField/CheckboxFormField'
import { SliderFormField } from 'src/components/SliderFormField/SliderFormField'
import { AttributeHeaderPreview } from './AttributeHeaderPreview/AttributeHeaderPreview'
import { UpdateAttributePayload } from 'src/actions/referenceData/attributes'
import { SkipLink } from 'src/components/Link'
import { ListInput } from './ListInput/ListInput'
import { AttributeValue } from 'src/types/index'
import { LoadingButton } from 'src/components/buttons/LoadingButton/LoadingButton'
import { ExistingAttributeHelper } from './ExistingAttributesHelper/ExistingAttributeHelper'
import { ERROR_STATUS, POSTING_STATUS, READY_STATUS } from 'src/constants/requests'
import { DropdownOption } from 'src/types/Dropdowns'
import { LIST_CELL, MULTI_LIST_CELL } from 'src/constants/attributes'
import {
  MIN_COL_WIDTH,
  MAX_COL_WIDTH,
  MAX_VALUE_DIGITS,
  UNALLOWED_CHARS,
} from 'src/constants/adminRenderSpecs'
import {
  cleanAttributeName,
  checkForMinCharacters,
  alphabeticallySortAttributeValues,
  validateAttributeName,
} from 'src/service/attributes/attributes'

import {
  buttonGroup,
  container,
  checkBox,
  formGroup,
  valueTypeLabel,
  valueTypeWrapper,
} from './AttributeForm.scss'
import { AttributeFormProps } from './AttributeFormContainer'

interface Errors {
  displayName: string[]
  type: boolean
  values: string[]
}
interface State {
  displayName: string
  columnWidth: number
  type: string
  sortable: boolean
  companyWide: boolean
  values: string[]
  existingValues: AttributeValue[]
  errors: Errors
  duplicable: boolean
}
export class AttributeForm extends React.Component<AttributeFormProps, State> {
  state = {
    displayName: this.props.attribute.displayName,
    columnWidth: this.props.attribute.columnWidth,
    type: this.props.attribute.type,
    sortable: this.props.attribute.sortable,
    companyWide: this.props.attribute.companyWide,
    duplicable: this.props.attribute.duplicable,
    values: [],
    existingValues: [...(this.props.attribute.values || [])].sort(
      alphabeticallySortAttributeValues,
    ),
    errors: {
      displayName: [],
      type: false,
      values: [],
    },
  }

  componentDidUpdate(prevProps: AttributeFormProps): void {
    if (
      prevProps.attributeSavingStatus === POSTING_STATUS &&
      this.props.attributeSavingStatus === READY_STATUS
    ) {
      this.props.onCancel()
    }

    if (
      prevProps.attributeSavingStatus === POSTING_STATUS &&
      this.props.attributeSavingStatus === ERROR_STATUS
    ) {
      this.setState({
        displayName: this.props.attribute.displayName,
        sortable: this.props.attribute.sortable,
        columnWidth: this.props.attribute.columnWidth,
        values: [],
        existingValues: [
          ...(this.props.attribute.values || []).sort(alphabeticallySortAttributeValues),
        ],
      })
    }
  }

  isUpdateView = (): boolean => !!this.props.attribute.slug

  getAttributeNameMatchers = (): string[] => {
    const cleanedDisplayName = cleanAttributeName(this.state.displayName)
    return this.props.attributes
      .filter(
        ({ displayName }) =>
          checkForMinCharacters(cleanedDisplayName) &&
          cleanAttributeName(displayName).includes(cleanedDisplayName),
      )
      .map(({ displayName }) => displayName)
  }

  updateAttribute = (): void => {
    const { sortable, columnWidth, displayName, duplicable } = this.state
    const { columns, attribute, updateAttribute } = this.props

    const column = columns.find(
      ({ attributeName }) => attributeName && attributeName === attribute.property,
    )

    const payload: UpdateAttributePayload = {
      newAttributeValues: this.state.values,
      existingAttributeValues: this.state.existingValues,
      columnSlug: column ? column.slug : '',
    }
    if (sortable !== attribute.sortable) {
      payload.sortable = sortable
    }
    if (columnWidth !== attribute.columnWidth) {
      payload.width = columnWidth
    }
    if (displayName !== attribute.displayName) {
      payload.name = displayName
    }
    if (duplicable !== attribute.duplicable) {
      payload.duplicable = duplicable
    }

    updateAttribute(attribute.slug, payload)
  }

  createAttribute = (): void => {
    const { displayName, columnWidth, type, sortable, companyWide, values, duplicable } = this.state
    this.props.createAttribute({
      displayName,
      columnWidth,
      type,
      sortable: type === MULTI_LIST_CELL ? false : sortable,
      companyWide,
      duplicable,
      values: this.typeHasValues() ? values : undefined,
    })
  }

  hasErrors = (): boolean => {
    const { attributes, attribute } = this.props
    const { displayName, type, values, existingValues } = this.state
    const errors: Errors = {
      displayName: validateAttributeName(displayName, attributes, attribute),
      type: (type as string) === '' || !type,
      values:
        this.typeHasValues() && !values.length && !existingValues.length
          ? ['At least one value needed']
          : [],
    }

    this.setState({ errors })
    return errors.displayName.length > 0 || errors.type || errors.values.length > 0
  }

  submitForm = (): void => {
    if (!this.hasErrors()) {
      if (this.isUpdateView()) {
        this.updateAttribute()
      } else {
        this.createAttribute()
      }
    }
  }

  showExistingAttributeHelper(attributeNamesMatchers: string[]): boolean {
    return (
      !this.isUpdateView() &&
      checkForMinCharacters(this.state.displayName) &&
      attributeNamesMatchers.length > 0
    )
  }

  onAttributeNameChange = (displayName: string): void => {
    this.setState({ displayName })
  }

  onAttributeNameBlur = (): void => {
    this.setState({
      errors: {
        ...this.state.errors,
        displayName: validateAttributeName(
          this.state.displayName,
          this.props.attributes,
          this.props.attribute,
        ),
      },
    })
  }

  onValueTypeBlur = (): void => {
    this.setState({
      errors: {
        ...this.state.errors,
        type: !this.state.type,
      },
    })
  }

  onValueTypeSelected = (event?: { value: string }): void => {
    const { value } = event || {}
    this.setState({
      type: value || '',
      errors: {
        ...this.state.errors,
        values: this.typeHasValues() ? this.state.errors.values : [],
      },
    })
  }

  onListInputBlur = (): void => {
    this.setState({
      errors: {
        ...this.state.errors,
        values:
          !this.state.values.length && !this.state.existingValues.length
            ? ['At least one value needed']
            : [],
      },
    })
  }

  onListInputAdd = (value: string): void => {
    const isValidLength = value.length <= MAX_VALUE_DIGITS
    const hasNoUnallowedChars = !value.includes(UNALLOWED_CHARS)
    const insertIf = (cond: boolean, ...els: string[]): string[] => (cond ? els : [])

    this.setState({
      errors: {
        ...this.state.errors,
        values: !isValidLength
          ? ['Value cannot be longer than 30 chars']
          : !hasNoUnallowedChars
          ? ['Value cannot contain comma']
          : [],
      },
      values: [...insertIf(isValidLength && hasNoUnallowedChars, value), ...this.state.values],
    })
  }

  onListInputRemove = (toRemove: string): void => {
    this.setState({
      errors: {
        ...this.state.errors,
        values: this.state.values.length === 1 ? ['At least one value needed'] : [],
      },
      values: this.state.values.filter((value) => value !== toRemove),
    })
  }

  onSortableClick = (sortable: boolean): void => {
    this.setState({ sortable })
  }

  onCompanyWideClick = (companyWide: boolean): void => {
    if (this.isUpdateView()) {
      return
    }
    this.setState({ companyWide })
  }

  onDuplicableClick = (duplicable: boolean): void => {
    this.setState({ duplicable })
  }

  onColumnWidthChange = (columnWidth: number): void => {
    this.setState({ columnWidth })
  }

  getValueType = (): DropdownOption =>
    this.props.typeValues.find(({ value }) => value === this.state.type)

  typeHasValues = (): boolean =>
    this.state.type === LIST_CELL || this.state.type === MULTI_LIST_CELL

  onListInputToggleDeprecated = (toToggleDeprecated: AttributeValue): void => {
    const updatedValues = this.state.existingValues.map((existingValue) => {
      return existingValue.slug === toToggleDeprecated.slug
        ? { ...existingValue, deprecated: !toToggleDeprecated.deprecated }
        : existingValue
    })

    this.setState({
      existingValues: updatedValues,
    })
  }

  onListInputRename = (toRename: AttributeValue): void => {
    const updatedValues = this.state.existingValues.map((existingValue) => {
      return existingValue.slug === toRename.slug
        ? { ...existingValue, value: toRename.value }
        : existingValue
    })

    this.setState({
      existingValues: updatedValues,
    })
  }

  render(): JSX.Element {
    const {
      type,
      sortable,
      displayName,
      columnWidth,
      companyWide,
      values,
      existingValues,
      errors,
      duplicable,
    } = this.state

    const { typeValues } = this.props

    const valueType = this.getValueType()
    const attributeNamesMatchers = this.getAttributeNameMatchers()

    return (
      <div>
        <div data-cy="attribute-form-container" className={container}>
          <div className={formGroup}>
            <TextFormField
              name="attributeName"
              value={displayName}
              onChange={this.onAttributeNameChange}
              hasFocus={false}
              header="Attribute name"
              onBlur={this.onAttributeNameBlur}
              required
              validationErrors={errors.displayName}
              disabled={false}
            />
            {this.showExistingAttributeHelper(attributeNamesMatchers) && (
              <ExistingAttributeHelper attributesNames={attributeNamesMatchers} />
            )}
            {this.isUpdateView() ? (
              <div className={valueTypeWrapper}>
                <label className={valueTypeLabel}>Value type</label>
                <div className="valueType">{valueType ? valueType.label : undefined}</div>
              </div>
            ) : (
              <Dropdown
                name="valueType"
                selectedItem={valueType}
                required
                header="Value type"
                items={typeValues}
                createOption={(item: DropdownOption): DropdownOption => item}
                isValid={!errors.type}
                onBlur={this.onValueTypeBlur}
                onItemSelected={this.onValueTypeSelected}
                disabled={false}
                customControlStyles={{
                  border: '1px solid #eef0ef',
                  '&:hover': {
                    border: '1px solid #26B3AD',
                  },
                }}
              />
            )}

            {this.typeHasValues() && (
              <ListInput
                values={[...values].sort()}
                existingValues={existingValues}
                validationMessage={errors.values.length > 0 ? errors.values[0] : ''}
                onBlur={this.onListInputBlur}
                onAdd={this.onListInputAdd}
                onRemove={this.onListInputRemove}
                onRename={this.onListInputRename}
                onToggleDeprecated={this.onListInputToggleDeprecated}
              />
            )}
          </div>
          <div className={formGroup}>
            <CheckboxFormField
              name="sortable"
              className={checkBox}
              header="Is it sortable?"
              checked={type !== MULTI_LIST_CELL ? sortable : false}
              onClick={this.onSortableClick}
              disabled={type === MULTI_LIST_CELL}
            />
            <CheckboxFormField
              name="companyWide"
              className={checkBox}
              header="Company wide?"
              checked={companyWide}
              onClick={this.onCompanyWideClick}
              disabled={this.isUpdateView()}
            />
            <CheckboxFormField
              name="duplicable"
              className={checkBox}
              header="Is it duplicable?"
              checked={duplicable}
              onClick={this.onDuplicableClick}
            />
            <SliderFormField
              name="columnWidth"
              header="Column width"
              min={MIN_COL_WIDTH}
              max={MAX_COL_WIDTH}
              value={columnWidth}
              onValueChange={this.onColumnWidthChange}
            />
            <AttributeHeaderPreview name={displayName} width={columnWidth} />
            <div className={buttonGroup}>
              <SkipLink onClick={this.props.onCancel}>Cancel</SkipLink>
              <LoadingButton
                colour="black"
                isLoading={this.props.attributeSavingStatus === POSTING_STATUS}
                onClick={this.submitForm}
              >
                {this.isUpdateView() ? 'Save changes' : 'Create attribute'}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
