import React from 'react'
import { connect } from 'react-redux'
import { RIRow } from './RowComponent.scss'
import { highlighted } from '../react-table.scss'
import { getHighlightedRows } from 'src/selectors/product'
import { AppState } from 'src/reducers/root'

type Props = {
  productSlug: string
  children: React.ReactNode
  highlightedRows?: string[]
  className?: string
  style?: Record<string, unknown>
}

export const RowComponent = ({
  highlightedRows,
  productSlug,
  children,
  className,
  style,
}: Props) => (
  <div
    style={style}
    className={`rt-tr ${RIRow} ${
      highlightedRows && highlightedRows.includes(productSlug) ? highlighted : ''
    } ${className || ''}`.trim()}
  >
    {children}
  </div>
)

const mapStateToProps = (state: AppState) => ({
  highlightedRows: getHighlightedRows(state),
})

export const RowComponentContainer = connect(mapStateToProps)(RowComponent)
