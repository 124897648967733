import React from 'react'
import classNames from 'classnames'

import PopoverTrigger from '../Link'
import { ESC_KEY, TAB_KEY } from 'src/constants/keyCodes'
import { focusTrap } from 'src/service/focusTrap'
import { PopoverPosition } from '../../types/Popover'

import {
  menuTrigger,
  popover,
  overlay,
  popoverContainer,
  leftTop,
  rightTop,
  topLeft,
  topRight,
  filterRight,
  filterLeft,
  disabled as disabledMenuTrigger,
} from './Popover.scss'
import { TOP_RIGHT } from '../../constants/popover'

type Props = {
  PopoverItem: React.ReactElement
  position: PopoverPosition
  triggerTabIndex?: number
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  triggerClassName?: string
  triggerOpenClass?: string
  triggerCloseClass?: string
  popoverContainerClass?: string
  children?: React.ReactNode
  disabled?: boolean
  dataCy?: string
}

const positionClassMap = {
  topLeft,
  topRight,
  rightTop,
  leftTop,
  filterRight,
  filterLeft,
}

type State = {
  open: boolean
}

export class Popover extends React.Component<Props, State> {
  viewRef: any

  constructor(props: Props) {
    super(props)
    this.state = {
      open: !!this.props.open,
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.open !== undefined && this.props.open !== prevProps.open) {
      this.props.open ? this.open() : this.close()
    }
  }

  open = (): void => {
    this.props.onOpen && this.props.onOpen()
    this.setState({ open: true })
  }

  close = (): void => {
    this.props.onClose && this.props.onClose()
    this.setState({ open: false })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.viewRef == null) {
      return
    }

    switch (event.key) {
      case ESC_KEY:
        this.close()
        break

      case TAB_KEY:
        focusTrap(this.viewRef, 'a, input:not([type=hidden])', event)
        break
    }
  }

  render(): JSX.Element {
    const { triggerClassName = '', triggerOpenClass = '', triggerCloseClass = '' } = this.props

    const triggerClasses = classNames(menuTrigger, {
      [triggerClassName]: triggerClassName,
      [triggerOpenClass]: triggerOpenClass && this.state.open,
      [triggerCloseClass]: triggerCloseClass && !this.state.open,
      [disabledMenuTrigger]: this.props.disabled,
    })

    return (
      <div className={popover}>
        <PopoverTrigger
          dataCy={this.props.dataCy}
          onClick={this.open}
          disabled={this.props.disabled}
          className={triggerClasses}
          tabIndex={this.props.triggerTabIndex || 0}
        >
          {this.props.children}
        </PopoverTrigger>
        {this.state.open && (
          <div>
            <PopoverTrigger onClick={this.close} className={overlay} />
            <div
              data-cy="popover-trigger-element"
              onKeyDown={this.onKeyDown}
              ref={(ref): HTMLDivElement => (this.viewRef = ref)}
              className={`${popoverContainer} ${
                positionClassMap[this.props.position] || TOP_RIGHT
              } ${this.props.popoverContainerClass || ''}`}
            >
              {React.cloneElement(this.props.PopoverItem, {
                closePopover: this.close,
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}
