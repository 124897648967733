import { ExportPayload, GridType } from 'src/types/index'

export const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS'
export type ExportProductsAction = ReturnType<typeof exportProducts>
export const exportProducts = (payload: ExportPayload, grid: GridType) =>
  <const>{
    type: EXPORT_PRODUCTS,
    payload,
    grid,
  }
