import React from 'react'

interface Props {
  className: string
}

export const NotInUseFolderIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="2"
    viewBox="0 0 24 24"
  >
    <title>icon_FolderSlash</title>
    <path
      d="M10,3H4A2,2,0,0,0,2,5V17a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V7a2,2,0,0,0-2-2H12Z"
      fill="none"
      stroke="#000"
      strokeWidth="1"
    />
    <path d="M23,4.39,1,20" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1" />
  </svg>
)
