import React from 'react'
import classNames from 'classnames'
import { CopyIcon } from 'assets/icons/CopyIcon'
import { DefaultAttributesTableData, SelectedDefaultAttribute } from 'types/UserSettings'
import {
  clipboardEnableElement,
  copyIcon,
  copyIconContainer,
  deprecatedValue,
} from './ReadonlyCell.scss'
import {
  setClipboardAttributeSource,
  updateDefaultAttributeValues,
} from 'actions/userSettings/defaultAttributes'

interface Props {
  style: React.CSSProperties
  className: string
  selectedCell: DefaultAttributesTableData
  copiedAttribute?: SelectedDefaultAttribute
  onSelectCell: () => void
  onDeselectCell: () => void
  onEnterEditMode: () => void
  setClipboardAttributeSource: typeof setClipboardAttributeSource
  updateDefaultAttributeValues: typeof updateDefaultAttributeValues
}

export const ReadonlyCell = ({
  className,
  style,
  selectedCell,
  copiedAttribute,
  onSelectCell,
  onDeselectCell,
  onEnterEditMode,
  setClipboardAttributeSource,
  updateDefaultAttributeValues,
}: Props): JSX.Element => {
  const isCellCopied = (): boolean =>
    copiedAttribute &&
    copiedAttribute.attributeSlug === selectedCell.attributeSlug &&
    copiedAttribute.rangeSlug === selectedCell.rangeSlug

  const onCopyCell = React.useCallback((): void => {
    setClipboardAttributeSource({
      attributeSlug: selectedCell.attributeSlug,
      attributeName: selectedCell.attributeName,
      rangeSlug: selectedCell.rangeSlug,
      defaultValues: selectedCell.values.map((item) => item.value as string),
    })
  }, [copiedAttribute, selectedCell])

  const onPasteCell = React.useCallback((): void => {
    if (
      copiedAttribute &&
      copiedAttribute.rangeSlug !== selectedCell.rangeSlug &&
      copiedAttribute.attributeSlug === selectedCell.attributeSlug &&
      copiedAttribute.defaultValues?.length
    ) {
      updateDefaultAttributeValues(
        copiedAttribute.defaultValues,
        copiedAttribute.attributeName,
        copiedAttribute.attributeSlug,
        selectedCell.rangeSlug,
      )
    }
  }, [copiedAttribute, selectedCell])

  return (
    <div
      tabIndex={0}
      style={style}
      className={className}
      onClick={onSelectCell}
      onBlur={onDeselectCell}
      onDoubleClick={onEnterEditMode}
      onCopy={onCopyCell}
      onPaste={onPasteCell}
    >
      <span className={clipboardEnableElement}>o</span>
      {isCellCopied() && (
        <div className={copyIconContainer}>
          <CopyIcon className={copyIcon} />
        </div>
      )}
      {selectedCell.attributeType === 'list' ? (
        <span
          className={classNames({
            [deprecatedValue]: !!selectedCell.values?.[0]?.deprecated,
          })}
        >
          {selectedCell.values?.[0]?.label}
        </span>
      ) : (
        selectedCell.values.map((selectedValue) => {
          return (
            <span
              key={selectedValue.value as string}
              className={classNames({
                [deprecatedValue]: !!selectedValue.deprecated,
              })}
            >
              {selectedValue.label}
            </span>
          )
        })
      )}
    </div>
  )
}
