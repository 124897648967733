// @ts-nocheck as the season vs dropdown options differences
import { AppState } from 'src/reducers/root'
import { connect } from 'react-redux'
import DropdownCellRenderer from '../DropdownCellRenderer'
import { getCurrentSeasons } from '../../../../selectors/referenceData/seasons'
import { Season } from 'src/types/index'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState) => ({
  dropdownItems: getCurrentSeasons(state),
})

const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  createOption: (item: Season) => ({
    value: item.code,
    label: item.code,
    data: item,
  }),
  createPatchItem: (item: Season) => ({
    apiValue: item.code,
    storeValue: item.code,
  }),
  displayFormatter: (seasonCode: string) => {
    return seasonCode || ''
  },
})

export const SeasonDropdownCellRendererContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(DropdownCellRenderer)
