import React from 'react'

import { AttributeRenderSpec, FlatHierarchyItem } from 'src/types/index'
import { ApiDepartmentSpec, ApiRule } from 'src/types/responses/departmentSpec'
import { RISpinner } from 'src/components/RISpinner/RISpinner'
import { AddDepartmentAttribute } from './AddDepartmentAttribute/AddDepartmentAttribute'
import { DepartmentContainer } from './Department/DepartmentContainer'
import { COMPANY_WIDE_DISPLAY_NAME, COMPANY_WIDE_SLUG } from 'src/constants/attributes'

import { title, section, departmentArea, spinnerContainerStyle } from './DepartmentDisplay.scss'

type Props = {
  currentDepartment?: FlatHierarchyItem
  attributesSpecs: AttributeRenderSpec[]
  ready: boolean
  selectedAttribute?: string
  departmentSpecification?: ApiDepartmentSpec
  getAdminDepartmentsSpecs: (departmentSlug: string) => void
  addDepartmentAttribute: (departmentSlug: string, attributeName: string) => void
  updateDepartmentAttributeRules: (
    departmentSlug: string,
    attributeName: string,
    rules: ApiRule,
  ) => void
  updateDepartmentAttributeViews: (
    departmentSlug: string,
    attributeName: string,
    views: string[],
  ) => void
}

export class DepartmentDisplay extends React.Component<Props> {
  componentDidMount() {
    this.getDepartmentSpecs()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.currentDepartment !== prevProps.currentDepartment) {
      this.getDepartmentSpecs()
    }
  }

  isCompanyWide = (currentDepartment: FlatHierarchyItem) =>
    currentDepartment.slug === COMPANY_WIDE_SLUG

  departmentTitle = (currentDepartment: FlatHierarchyItem) => {
    return this.isCompanyWide(currentDepartment)
      ? COMPANY_WIDE_DISPLAY_NAME
      : `${currentDepartment.title} - ${currentDepartment.levelCode}`
  }

  getDepartmentSpecs = () => {
    const { currentDepartment } = this.props
    currentDepartment && this.props.getAdminDepartmentsSpecs(currentDepartment.slug)
  }

  getAttributesNotOnDepartment = () => {
    const { departmentSpecification, attributesSpecs, ready } = this.props

    return departmentSpecification && ready
      ? attributesSpecs.filter(
          ({ property }) =>
            !departmentSpecification.attributes.find((spec) => spec.name === property),
        )
      : []
  }

  updateAttributeRules = (attributeName: string, rules: ApiRule) => {
    const { currentDepartment, updateDepartmentAttributeRules } = this.props
    currentDepartment &&
      updateDepartmentAttributeRules(currentDepartment.slug, attributeName, rules)
  }

  updateAttributeViews = (attributeName: string, views: string[]) => {
    const { currentDepartment, updateDepartmentAttributeViews } = this.props
    currentDepartment &&
      updateDepartmentAttributeViews(currentDepartment.slug, attributeName, views)
  }

  render() {
    const {
      currentDepartment,
      ready,
      attributesSpecs,
      departmentSpecification,
      selectedAttribute,
      addDepartmentAttribute,
    } = this.props
    return (
      <section data-cy="department-display" className={section}>
        {currentDepartment ? (
          <div className={departmentArea}>
            <h3 className={title}>{this.departmentTitle(currentDepartment)}</h3>
            {!this.isCompanyWide(currentDepartment) && (
              <AddDepartmentAttribute
                addAttribute={addDepartmentAttribute}
                departmentSlug={currentDepartment.slug}
                listOfAttributes={this.getAttributesNotOnDepartment()}
              />
            )}
          </div>
        ) : (
          <div>Please select a department</div>
        )}
        {departmentSpecification &&
          currentDepartment &&
          (ready ? (
            <DepartmentContainer
              currentSelection={selectedAttribute}
              attributesSpecs={attributesSpecs}
              departmentSpecification={departmentSpecification}
              changeRules={this.updateAttributeRules}
              changeViews={this.updateAttributeViews}
            />
          ) : (
            <RISpinner colour="black" containerStyle={spinnerContainerStyle} />
          ))}
      </section>
    )
  }
}
