// @ts-nocheck

import React from 'react'
import { DropdownAndAdd } from 'src/components/DropdownAndAdd/DropdownAndAdd'
import { AttributeRenderSpec } from 'src/types/Attributes'
import { DropdownOption } from 'types/Dropdowns'

type Props = {
  listOfAttributes: AttributeRenderSpec[]
  departmentSlug: string
  addAttribute: (departmentSlug: string, attribute: string) => void
}

const withoutCompanyWide = (attributes: AttributeRenderSpec[]): AttributeRenderSpec[] =>
  attributes.filter(({ companyWide }) => !companyWide)

export const AddDepartmentAttribute = ({
  listOfAttributes,
  departmentSlug,
  addAttribute,
}: Props): JSX.Element => {
  return (
    <DropdownAndAdd
      name="addDepartmentAttribute"
      header="Add attribute to department"
      items={withoutCompanyWide(listOfAttributes)}
      createOption={(option): DropdownOption => ({
        value: option.property,
        label: option.displayName,
        data: option,
      })}
      onAdd={(selectedAttribute): void => addAttribute(departmentSlug, selectedAttribute.property)}
    />
  )
}
