import { all, call, put, takeEvery } from 'redux-saga/effects'
import { injectorService } from '../service/injector/injectorService'
import {
  FETCH_REFERENCE_DATA,
  fetchReferenceDataFailure,
  fetchReferenceDataSuccess,
} from '../actions/referenceData/referenceData'
import { apiAttributesToAttributes } from '../service/mappers'
import { logError } from '../service/errors'
import {
  VIEWS_ENDPOINT,
  HIERARCHY_ENDPOINT,
  ATTRIBUTES_ENDPOINT,
  SUPPLIERS_ENDPOINT,
  SELLING_PRICED_ENDPOINT,
  COLUMNS_ENDPOINT,
  COUNTRIES_ENDPOINT,
  SEASONS_ENDPOINT,
} from '../constants/apiUrls'
import { ViewRefs } from '../types/Views'
import { APIViewRefs } from '../types/responses/views'
import { ApiHierachies } from '../types/responses/hierachy'
import { ApiAttributes } from '../types/responses/attributes'
import { ApiSuppliers } from '../types/responses/supplier'
import { ApiSellingPrices } from '../types/responses/sellingPrice'
import { ApiColumns } from '../types/responses/columns'
import { ApiCountries } from '../types/responses/countries'
import { ApiSeasons } from '../types/responses/season'
import { SVPSaga } from 'sagas'

const apiViewsToViews = (apiViews: APIViewRefs): ViewRefs =>
  apiViews.reduce((acc, view) => {
    acc[view.slug] = view
    return acc
  }, {} as ViewRefs)

export function* fetchReferenceData(): SVPSaga {
  try {
    const [
      apiViews,
      apiHierarchy,
      apiAttributes,
      apiSupplier,
      apiSellingPrices,
      apiColumns,
      apiCountries,
      apiSeasons,
    ] = (yield all([
      call(injectorService.get, VIEWS_ENDPOINT),
      call(injectorService.get, HIERARCHY_ENDPOINT),
      call(injectorService.get, ATTRIBUTES_ENDPOINT),
      call(injectorService.get, SUPPLIERS_ENDPOINT),
      call(injectorService.get, SELLING_PRICED_ENDPOINT),
      call(injectorService.get, COLUMNS_ENDPOINT),
      call(injectorService.get, COUNTRIES_ENDPOINT),
      call(injectorService.get, SEASONS_ENDPOINT),
    ])) as [
      APIViewRefs,
      ApiHierachies,
      ApiAttributes,
      ApiSuppliers,
      ApiSellingPrices,
      ApiColumns,
      ApiCountries,
      ApiSeasons,
    ]

    yield put(
      fetchReferenceDataSuccess(
        apiViewsToViews(apiViews),
        apiHierarchy || [],
        apiAttributesToAttributes(apiAttributes, apiSupplier, apiSellingPrices),
        apiColumns,
        apiCountries,
        apiSeasons,
      ),
    )
  } catch (error) {
    yield call(logError, error as Error)
    yield put(fetchReferenceDataFailure())
  }
}

export default function* (): SVPSaga {
  yield all([takeEvery(FETCH_REFERENCE_DATA, fetchReferenceData)])
}
