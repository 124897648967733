import React from 'react'
import { Comment } from './Comment/Comment'
import { NewComment, Comment as CommentType, GridType } from 'src/types/index'
import {
  commentsPanel,
  postCommentSection,
  commentsSection,
  textArea,
  disabledSaveButton,
  spinner,
} from './CommentsSection.scss'
import classNames from 'classnames'
import { setFocus, scrollTo, addEventListener, removeEventListener } from 'src/service/ref'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { UploadSpinner } from 'components/UploadSpinner'

type Props = {
  comments: CommentType[]
  userEmail: string
  newestFirst?: boolean
  postComment: (comment: NewComment, productSlug: string, grid: GridType) => void
  status: string
  grid: GridType
  productSlug: string
}

type State = {
  newComment: string
}
type SortFuncReturn = (a: CommentType, b: CommentType) => 1 | -1

const sortFunc = (newestFirst: boolean): SortFuncReturn =>
  newestFirst
    ? (a: CommentType, b: CommentType): 1 | -1 => (a.dateTimeCreated < b.dateTimeCreated ? 1 : -1)
    : (a: CommentType, b: CommentType): 1 | -1 => (a.dateTimeCreated > b.dateTimeCreated ? 1 : -1)

export class CommentsSection extends React.Component<Props, State> {
  textareaRef: any
  buttonRef: any
  commentListRef: any

  constructor(props: Props) {
    super(props)
    this.state = {
      newComment: '',
    }
  }

  enterToPost = (event: KeyboardEvent): void => {
    if (event.key === ENTER_KEY) {
      this.postComment()
    }
  }

  enterToAddNewLine = (event: KeyboardEvent): void => {
    if (event.key === ENTER_KEY) {
      event.stopPropagation()
    }
  }

  postComment = (): void => {
    this.state.newComment.trim() !== '' &&
      this.props.status !== 'posting' &&
      this.props.postComment(
        {
          text: this.state.newComment.trim(),
        },
        this.props.productSlug,
        this.props.grid,
      )
  }

  componentDidMount(): void {
    setFocus(this.textareaRef)
    addEventListener(this.enterToPost, 'keydown', this.buttonRef)
    addEventListener(this.enterToAddNewLine, 'keydown', this.textareaRef)
  }

  componentWillUnmount(): void {
    removeEventListener(this.enterToPost, 'keydown', this.buttonRef)
    removeEventListener(this.enterToAddNewLine, 'keydown', this.textareaRef)
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.status === 'fetching' && this.props.status === 'ready') {
      scrollTo(this.commentListRef, !!this.props.newestFirst)
    }
    if (prevProps.status === 'posting' && this.props.status === 'ready') {
      this.setState({ newComment: '' })
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ newComment: event.target.value })
  }

  render(): JSX.Element {
    const { status, userEmail, comments, newestFirst } = this.props
    const isPosting = status === 'posting'

    const disabledBtnClass = classNames({ [disabledSaveButton]: isPosting })

    return (
      <section className={commentsPanel}>
        <ul
          className={commentsSection}
          ref={(node): HTMLUListElement => (this.commentListRef = node)}
          data-cy="sidepanel-comments-section"
        >
          {this.props.status === 'fetching' ? (
            <div className={spinner}>
              <UploadSpinner />
            </div>
          ) : (
            comments
              .sort(sortFunc(!!newestFirst))
              .map((comment, index) => (
                <Comment
                  key={index}
                  text={comment.text}
                  userEmail={comment.userEmail}
                  dateTimeCreated={comment.dateTimeCreated}
                  isCurrentUser={comment.userEmail === userEmail}
                />
              ))
          )}
        </ul>

        <div className={postCommentSection}>
          <textarea
            className={textArea}
            value={this.state.newComment}
            onChange={this.handleChange}
            ref={(node): HTMLTextAreaElement => (this.textareaRef = node)}
          />
          <a
            tabIndex={0}
            onClick={this.postComment}
            ref={(node): HTMLAnchorElement => (this.buttonRef = node)}
            className={disabledBtnClass}
          >
            Save Comment
          </a>
        </div>
      </section>
    )
  }
}
