import * as React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { visit } from 'src/service/hierarchy'
import { TreeItem } from './TreeItem/TreeItem'
import { focusTrap } from 'src/service/focusTrap'
import { isSelectableHierarchyLevel } from 'src/service/hierarchy/utils'
import { TAB_KEY } from 'src/constants/keyCodes'
import { TreeHierarchyItem } from 'src/types/index'

import {
  closeButton,
  closeButtonContainer,
  hierarchyContainer,
  loadingHierarchy,
} from './Hierarchy.scss'
import { FETCHING_STATUS } from '../../constants/requests'
import { isMobileEnv } from 'helpers/envs'
import { CloseCross } from 'assets/icons'
import { HierarchyProps } from './HierarchyContainer'

type HierarchyState = {
  hierarchy: TreeHierarchyItem[]
}

export const getExpandedNodes = (tree: TreeHierarchyItem[]): string[] => {
  const expandedNodes: string[] = []

  tree.forEach((node) => {
    visit(node, (currentNode: TreeHierarchyItem) => {
      if (currentNode.expanded) {
        expandedNodes.push(currentNode.slug)
      }
    })
  })

  return expandedNodes
}

export const updateNode = (
  tree: TreeHierarchyItem[],
  slug: string,
  f: (node: TreeHierarchyItem) => void,
): TreeHierarchyItem[] => {
  const newTree = cloneDeep(tree)

  newTree.forEach((treeNode) => {
    visit(treeNode, (currentNode: TreeHierarchyItem) => {
      if (currentNode.slug === slug) {
        f(currentNode)
      }
    })
  })
  return newTree
}

export const syncExpandedHierarchyState = (
  expandedSlugs: string[],
  hierarchy: Array<TreeHierarchyItem>,
): TreeHierarchyItem[] => {
  const synchedHierarchy = cloneDeep(hierarchy)

  synchedHierarchy.forEach((node) => {
    visit(node, (currentNode: TreeHierarchyItem) => {
      if (expandedSlugs.includes(currentNode.slug)) {
        currentNode.expanded = true
      }
    })
  })
  return synchedHierarchy
}

export class Hierarchy extends React.Component<HierarchyProps, HierarchyState> {
  viewRef: HTMLDivElement

  constructor(props: HierarchyProps) {
    super(props)
    const synchedHierarchy = syncExpandedHierarchyState(props.expandedNodes, props.hierarchy)

    this.state = {
      hierarchy: synchedHierarchy,
    }
  }

  static defaultProps = {
    expandedNodes: [],
  }

  componentDidMount(): void {
    const viewRef = this.viewRef

    if (viewRef) {
      const firstAnchor = viewRef.querySelector('a')
      firstAnchor && firstAnchor.focus()
    }
  }

  componentDidUpdate(prevProps: HierarchyProps): void {
    if (!isEqual(this.props.hierarchy, prevProps.hierarchy)) {
      const synchedHierarchy = syncExpandedHierarchyState(
        prevProps.expandedNodes,
        prevProps.hierarchy,
      )

      this.setState({ hierarchy: synchedHierarchy })
    }
  }

  componentWillUnmount(): void {
    const expandedNodes = getExpandedNodes(this.state.hierarchy)
    this.props.updateExpandedHierarchyNodes(expandedNodes)
  }

  onToggle = (node: TreeHierarchyItem): void => {
    this.setState({
      hierarchy: updateNode(this.state.hierarchy, node.slug, (node) => {
        node.expanded = !node.expanded
      }),
    })
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (this.viewRef == null) {
      return
    }
    const viewRef = this.viewRef
    switch (event.key) {
      case TAB_KEY:
        focusTrap(viewRef, 'a', event)
        break
    }
  }

  isSelectable = (node: TreeHierarchyItem): boolean =>
    this.props.nodeIsSelectable(node) && isSelectableHierarchyLevel(node)

  shouldBeSeen = (node: TreeHierarchyItem): boolean => !node.deprecated || this.props.isAdmin

  render(): JSX.Element {
    const loading = this.props.status === FETCHING_STATUS
    const treeData = this.state.hierarchy || []
    return (
      <div
        id="ViewHierarchy"
        className={hierarchyContainer}
        ref={(ref): HTMLDivElement => (this.viewRef = ref)}
        onKeyDown={this.onKeyDown}
      >
        {loading ? (
          <div className={loadingHierarchy}>Loading Hierarchy...</div>
        ) : (
          <>
            {isMobileEnv() && (
              <div className={closeButtonContainer}>
                <button onClick={this.props.onClose} className={closeButton}>
                  <CloseCross />
                </button>
              </div>
            )}
            {treeData.filter(this.shouldBeSeen).map((node, index) => (
              <TreeItem
                key={index}
                node={node}
                toggleNode={this.onToggle}
                shouldBeSeen={this.shouldBeSeen}
                onNodeSelected={(node): void => {
                  this.props.onNodeSelected(node)
                }}
                isSelectable={this.isSelectable}
              />
            ))}
          </>
        )}
      </div>
    )
  }
}
