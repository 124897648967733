import React from 'react'
import { ProductForm } from './ProductForm'
import { getDropdownValidationErrors } from 'src/service/product/validate'
import { AttributeValue, AttributeValues, Season } from 'src/types/index'
import { getTextValidationErrors } from 'src/service/product/validateUtils'
import { Supplier } from '../../../types/Supplier'
import { CreateProductFormFields, EnrichedViewProduct } from 'types/Product'
import { emptyFields, formFieldsToProduct, validateAndSetFocus } from 'service/product/create'
import { useDispatch, useSelector } from 'react-redux'
import { getRangeDefaultAttributes } from 'actions/grid/product/create'
import { getDefaultAttributesForRangeSelector } from 'selectors/createProduct'
import { AppState } from 'reducers/root'
import isEqual from 'lodash/isEqual'

interface Props {
  seasons: Season[]
  attributes: AttributeValues
  suppliers: Supplier[]
  onSubmit: (product: EnrichedViewProduct) => void
  attributeNameMap: Record<string, string>
}

const enrichProduct = (
  fields: CreateProductFormFields,
  seasonalityValues: AttributeValue[],
): EnrichedViewProduct => ({
  ...formFieldsToProduct(fields),
  seasonality:
    seasonalityValues.find(
      (seasonality) =>
        seasonality.value === (fields.season.data.code && fields.season.data.code.substring(0, 2)),
    ) || ({} as AttributeValue),
})

const allFieldsAreValid = (fields: CreateProductFormFields): boolean => {
  return Object.keys(fields)
    .map((name) => fields[name] && fields[name]?.validationErrors.length === 0)
    .reduce((a, b) => a && b, true)
}

export const ProductFormContainer = (props: Props): JSX.Element => {
  const { attributeNameMap, attributes, suppliers, seasons } = props
  const [fields, setFields] = React.useState<CreateProductFormFields>(emptyFields())
  const dispatch = useDispatch()

  const defaultAttributes = useSelector(
    (state: AppState) => getDefaultAttributesForRangeSelector(state, fields),
    isEqual,
  )

  React.useEffect(() => {
    if (defaultAttributes && Object.keys(defaultAttributes).length) {
      setFields({
        ...fields,
        ...defaultAttributes,
      })
    }
  }, [defaultAttributes])

  const onDropdownChange = (returnValue: AttributeValue | undefined, fieldName: string): void => {
    setFields({
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        data: returnValue,
        hasFocus: false,
        validationErrors: getDropdownValidationErrors(returnValue, fields[fieldName].isMandatory),
      },
    })
    if (fieldName === 'hierarchy') {
      dispatch(getRangeDefaultAttributes(returnValue.slug))
    }
  }

  const onTextFieldChange = (text: string, fieldName: string): void => {
    setFields({
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        data: text,
        validationErrors: getTextValidationErrors(text, fields[fieldName].isMandatory),
      },
    })
  }

  const onTextFieldBlur = (fieldName: string): void => {
    setFields({
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        hasFocus: false,
      },
    })
  }

  const validateFormFields = (): void => {
    setFields(validateAndSetFocus(fields))
  }

  const submitForm = (
    event: React.FormEvent<HTMLFormElement>,
    seasonalityValues: AttributeValue[],
  ): void => {
    event.preventDefault()
    if (allFieldsAreValid(fields)) {
      const enrichedProduct = enrichProduct(fields, seasonalityValues)
      props.onSubmit(enrichedProduct)
      setFields(emptyFields())
    }
  }

  return (
    <ProductForm
      fields={fields}
      items={attributes}
      seasons={seasons}
      suppliers={suppliers}
      onDropdownChange={onDropdownChange}
      onTextFieldChange={onTextFieldChange}
      onTextFieldBlur={onTextFieldBlur}
      validateForm={validateFormFields}
      submitForm={submitForm}
      attributeNameMap={attributeNameMap}
    />
  )
}
