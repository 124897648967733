import React from 'react'
import Checkbox from 'src/components/Checkbox/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import { Product } from 'src/types/index'
import moment from 'moment'
import { validFormatDate } from 'helpers/date'
import { UploadSpinner } from 'components/UploadSpinner'

const createPayload = (product: Product, accessor: string): PatchPayload => ({
  productSlug: product.slug,
  patchFields: {
    store: { [accessor]: moment().toISOString() },
  },
})

type Props = {
  product: Product
  accessor: string
  updateProduct: (payload: PatchPayload) => void
  editMode: boolean
  exitEditMode: (exit: boolean) => void
  locked?: boolean
}

export const DateTodayCellRenderer = ({
  product,
  accessor,
  updateProduct,
  editMode,
  exitEditMode,
  locked,
}: Props): JSX.Element => {
  const [isCellUpdating, setIsCellUpdating] = React.useState(false)
  React.useEffect(() => {
    if (product[accessor]) {
      setIsCellUpdating(false)
    }
  })

  if (isCellUpdating) {
    return <UploadSpinner />
  }

  if (product[accessor]) {
    return <div data-cy="current-date-today">{validFormatDate(product[accessor], 'DD-MMM-YY')}</div>
  }

  return (
    <Checkbox
      disabled={locked}
      checked={product[accessor]}
      hasFocus={editMode}
      onBlur={(): void => exitEditMode(true)}
      onClick={(): void => {
        updateProduct(createPayload(product, accessor))
        setIsCellUpdating(true)
        exitEditMode(true)
      }}
    />
  )
}
