import React from 'react'

import { validationMessage, label, textFormField } from './TextFormField.scss'
import { TextField } from '../TextField/TextField'

type Props = {
  header: string
  name: string
  hasFocus: boolean
  required: boolean
  placeholder?: string
  disabled?: boolean
  value: string
  validationErrors: string[]
  dataCy?: string
  onBlur?: (val: string) => void
  onChange: (val: string, name: string) => void
  onKeyDown?: (e: KeyboardEvent) => void
}

export const TextFormField = (props: Props) => {
  const {
    validationErrors,
    header,
    required,
    name,
    value,
    onBlur,
    onChange,
    placeholder,
    hasFocus,
    onKeyDown,
    disabled,
    dataCy,
  } = props
  const isValid = () => validationErrors.length === 0

  const headerText = `${header}${required ? ' *' : ''}`

  return (
    <div className={textFormField}>
      <label className={label} htmlFor={name}>
        {headerText}
      </label>
      <TextField
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        hasError={!isValid()}
        hasFocus={hasFocus}
        onKeyDown={onKeyDown}
        disabled={disabled}
        dataCy={dataCy}
      />
      {!isValid() && <div className={validationMessage}>{validationErrors.join(' and ')}</div>}
    </div>
  )
}
