import { PutEffect, put } from 'redux-saga/effects'
import { showErrorNotification } from '../actions/notification'
import { APIError } from '../types/Api'
import { IntentionalAny } from 'types'

export function* errorAndNotification(
  error: string | APIError,
  message: string,
): Generator<PutEffect<IntentionalAny>> {
  if (typeof error === 'string') {
    yield put(showErrorNotification(message))
  } else {
    if ((error as APIError)?.response?.data?.error?.code === 13) {
      yield put(showErrorNotification('Reclass in progress, unable to complete this request.'))
    } else {
      yield put(showErrorNotification(message))
    }
  }
}
