import * as React from 'react'
import { retryButton, smallRedoIcon, statusText } from './RetryButton.scss'
import { RefreshIcon } from '../../../../assets/icons'

interface Props {
  onClick: () => void
}

export function RetryButton({ onClick }: Props): JSX.Element {
  return (
    <button className={`${statusText} ${retryButton}`} onClick={onClick}>
      <RefreshIcon className={smallRedoIcon} />
      Retry
    </button>
  )
}
