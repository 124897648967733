import {
  CLEAR_WHOLESALE_IMAGES,
  GET_WHOLESALE_IMAGES_SUCCESS,
  SET_WHOLESALE_IMAGES_LOADING,
  UPDATE_WHOLESALE_IMAGES_SEQUENCE_SUCCESS,
} from 'src/actions/wholesaleImages'
import { WholesaleImage } from 'src/types/WholesaleImages'
import { SHOW_NOTIFICATION } from 'actions/notification'
import { SVPAction } from 'actions/svp'

export type WholesaleImagesState = {
  images: WholesaleImage[]
  isLoading: boolean
}

const defaultState: WholesaleImagesState = {
  images: [],
  isLoading: false,
}

export const wholesaleImages = (state: WholesaleImagesState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case GET_WHOLESALE_IMAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        images: action.images.map((image) => {
          if (action.imagesToRefresh.includes(image.sequence)) {
            return {
              ...image,
              thumbnailURL: `${image.thumbnailURL}?${new Date().getTime()}`,
            }
          } else {
            return image
          }
        }),
      }
    case CLEAR_WHOLESALE_IMAGES:
      return {
        ...state,
        images: [],
      }
    case UPDATE_WHOLESALE_IMAGES_SEQUENCE_SUCCESS:
      return {
        ...state,
        images: state.images
          .map((wsImage) => {
            const changedImage = action.payload.find(
              (resequencedImage) => wsImage.slug === resequencedImage.slug,
            )
            if (changedImage) {
              return {
                ...wsImage,
                sequence: changedImage.sequence,
              }
            } else {
              return wsImage
            }
          })
          .sort((a, b) => a.sequence - b.sequence),
        isLoading: false,
      }
    case SET_WHOLESALE_IMAGES_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
