import { connect } from 'react-redux'
import { AttributeForm } from './AttributeForm'

import { createAttribute, updateAttribute } from 'src/actions/referenceData/attributes'
import { AppState } from 'src/reducers/root'
import { getAttributeSavingStatus, getColumns } from 'src/selectors/referenceData/attributes'
import { getAttributeRenderSpecs } from 'src/selectors/referenceData/columns'
import { getDropdownAttributeOptions } from 'src/service/product/uiValues'
import { AttributeRenderSpec } from 'types/Attributes'
import { ColumnValue } from 'types/Columns'
import { RequestStatus } from 'types/Api'
import { DropdownOption } from 'types/Dropdowns'

interface StateProps {
  attributes: AttributeRenderSpec[]
  columns: ColumnValue[]
  attributeSavingStatus: RequestStatus
  typeValues: DropdownOption[]
}

interface PassedProps {
  attribute: AttributeRenderSpec
  onCancel: () => void
}

interface DispatchProps {
  updateAttribute: typeof updateAttribute
  createAttribute: typeof createAttribute
}

export interface AttributeFormProps extends PassedProps, StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  attributes: getAttributeRenderSpecs(state),
  attributeSavingStatus: getAttributeSavingStatus(state),
  columns: getColumns(state),
  typeValues: getDropdownAttributeOptions(),
})

const mapDispatchToProps: DispatchProps = {
  updateAttribute,
  createAttribute,
}

export const AttributeFormContainer = connect(mapStateToProps, mapDispatchToProps)(AttributeForm)
