import { fetchFirstProductsPage } from 'actions/grid/product/fetch'
import { SVPAction } from 'actions/svp'
import { all, put, takeEvery } from 'redux-saga/effects'
import { DESELECT_HIERARCHY, SELECT_HIERARCHY } from 'src/actions/grid/hierarchy'

export function* reselectedHierarchy(
  action: SVPAction<typeof SELECT_HIERARCHY | typeof DESELECT_HIERARCHY>,
) {
  yield put(fetchFirstProductsPage(action.grid))
}

export default function* hierarchySaga(): Generator {
  yield all([
    takeEvery(SELECT_HIERARCHY, reselectedHierarchy),
    takeEvery(DESELECT_HIERARCHY, reselectedHierarchy),
  ])
}
