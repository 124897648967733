import React from 'react'

interface Props {
  className: string
  color: string
}

export const CheckIcon = ({ className, color }: Props) => (
  <svg
    data-cy="checkbox-icon"
    className={className}
    viewBox="0 0 29 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>CheckIcon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-967.000000, -441.000000)">
        <g transform="translate(967.000000, 441.000000)">
          <circle fill={color || '#41B97A'} cx="14" cy="14" r="14" />
          <polyline
            stroke="#FFFFFF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="7.72413793 12.8136408 12.7422973 20.2758621 26.0689655 4.82758621"
          />
        </g>
      </g>
    </g>
  </svg>
)
