import React from 'react'

import { GridType } from 'src/types/GridTypes'
import { ActionsMenu } from 'src/components/cellRenderers/ActionsCellRenderer/ActionsCell/ActionsMenu/ActionsMenu'

interface Props {
  gridName: GridType
  disabled: boolean
  linkMatchingProducts: (grid: GridType) => void
  linkCrossDepartmentProducts: (grid: GridType) => void
  linkColourProducts: (grid: GridType) => void
  linkMultiSupplierProducts: (grid: GridType) => void
}

export default function MenuItems({
  gridName,
  disabled,
  linkMatchingProducts,
  linkCrossDepartmentProducts,
  linkColourProducts,
  linkMultiSupplierProducts,
}: Props) {
  const itemsStyle = [
    {
      text: 'Matching Products',
      disabled,
      callback: () => linkMatchingProducts(gridName),
    },
    {
      text: 'Cross Dept',
      disabled,
      callback: () => linkCrossDepartmentProducts(gridName),
    },
    {
      text: 'Option (Link Line)',
      disabled,
      callback: () => linkMultiSupplierProducts(gridName),
    },
  ]

  return <ActionsMenu menu={itemsStyle} />
}
