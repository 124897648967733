import React, { Component } from 'react'
import { connect } from 'react-redux'

import { LockIcon } from '../../assets/icons'
import { lockBanner, lockBannerTextContainer } from './Lock.scss'
import { isLocked } from '../../selectors/lock'
import { AppState } from '../../reducers/root'

interface Props {
  isLocked?: boolean
}

export class LockStatus extends Component<Props> {
  render() {
    const { isLocked } = this.props
    if (!isLocked) {
      return <></>
    }

    return (
      <div className={lockBanner}>
        <LockIcon className="lock-icon" borderColor="#ff0000" iconColor="#000" />
        <div className={lockBannerTextContainer}>
          The system is temporarily locked - Read only access
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isLocked: isLocked(state),
})

export default connect(mapStateToProps)(LockStatus)
