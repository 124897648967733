export const GET_PREVIOUS_PRODUCT = 'GET_PREVIOUS_PRODUCT'
export const GET_NEXT_PRODUCT = 'GET_NEXT_PRODUCT'
export const RESET_NAVIGATION_VALUE = 'RESET_NAVIGATION_VALUE'
export type SidePanelNavigationActions = ReturnType<
  typeof getPreviousProduct | typeof getNextProduct | typeof resetNavigationValue
>

export const getPreviousProduct = () =>
  <const>{
    type: GET_PREVIOUS_PRODUCT,
  }

export const getNextProduct = () =>
  <const>{
    type: GET_NEXT_PRODUCT,
  }
export const resetNavigationValue = () =>
  <const>{
    type: RESET_NAVIGATION_VALUE,
  }
