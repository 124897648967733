import { ODBMS_ATTRIBUTE_SOURCE } from '../../../constants/attributes'
import { isString } from '../../../helpers/types'
import { Product } from 'src/types/index'

const PRODUCT_NUMBER_KEY = 'Prod No'
const DEV_ID = 'Dev ID'

const getMultiListValue = (valueFromState: any, rawValue: string, productKey: string) => {
  const slugs = valueFromState.values
    .filter(({ value }: any) =>
      rawValue.toLowerCase().trim().split(', ').includes(value.toLowerCase()),
    )
    .map(({ slug }: any) => slug)

  return slugs.length === rawValue.split(', ').length
    ? slugs
    : { error: { [productKey]: `One or more invalid values: ${rawValue}` } }
}

const getListValue = (valueFromState: any, rawValue: string, productKey: string) =>
  (
    valueFromState.values.find(
      ({ value }: any) => rawValue.toLowerCase().trim() === value.toLowerCase(),
    ) || { slug: { error: { [productKey]: `Invalid value: ${rawValue}` } } }
  ).slug

const removeCurrencySign = (rawValue: string) =>
  isNaN(Number(rawValue[0])) ? rawValue.slice(1) : rawValue

const getCurrencyValue = (rawValue: string | number, productKey: string) => {
  const currency = isString(rawValue)
    ? Number(removeCurrencySign(String(rawValue)))
    : Number(rawValue)

  return isNaN(currency) ? { error: { [productKey]: `Not currency: ${rawValue}` } } : currency
}

const isYesNo = (value: string) => ['yes', 'no'].includes(value)

const getBooleanValue = (rawValue: string, productKey: string) => {
  const value = rawValue.toLowerCase()
  return isYesNo(value) ? value === 'yes' : { error: { [productKey]: `Not yes/no: ${rawValue}` } }
}

const getValue = (rawValue: string, valueFromState: any, productKey: string) => {
  switch (valueFromState.type) {
    case 'list':
      return getListValue(valueFromState, rawValue, productKey)
    case 'multiList':
      return getMultiListValue(valueFromState, rawValue, productKey)
    case 'currency':
      return getCurrencyValue(rawValue, productKey)
    case 'boolean':
      return getBooleanValue(rawValue, productKey)
    default:
      return rawValue
  }
}

const isAttribute = (valuesFromState: any) => (key: string) => valuesFromState[key]

const isValueNonBlank = (rawProduct: any) => (key: string) => rawProduct[key] !== ''

const trimKeys = (rawProduct: any) => {
  const newObject: any = {}
  Object.keys(rawProduct).forEach((key: string) => {
    newObject[key.trim()] = rawProduct[key]
  })
  return newObject
}

const getProductInfo = (rawProduct: any, valuesFromState: any) => {
  rawProduct = trimKeys(rawProduct)
  return Object.keys(rawProduct)
    .filter(isAttribute(valuesFromState))
    .filter(isValueNonBlank(rawProduct))
    .map((productKey) => [
      [valuesFromState[productKey].attributeName],
      getValue(rawProduct[productKey], valuesFromState[productKey], productKey),
    ])
    .reduce(
      (acc, [key, value]) =>
        value.error
          ? {
              ...acc,
              logs: {
                ...acc.logs,
                ...value.error,
              },
            }
          : {
              ...acc,
              attributes: {
                ...acc.attributes,
                [key]: value,
              },
            },
      {
        productNumber: rawProduct[PRODUCT_NUMBER_KEY],
        developmentId: rawProduct[DEV_ID],
        attributes: {},
        logs: {},
      },
    )
}

export const getProductAttributes = (rawProducts: Array<Product>, valuesFromState: any) =>
  rawProducts.reduce(
    (acc, rawProduct) => ({
      ...acc,
      [rawProduct.slug]: getProductInfo(rawProduct, valuesFromState),
    }),
    {},
  )

const convertSpec = (acc: any, spec: any, values: any) => {
  acc[spec.displayName] = {
    attributeName: spec.property,
    type: spec.type,
    values: values[spec.property],
  }
  return acc
}

const filterField = ['productDescription', 'supplierCost', 'sellingPrice']

export const parseState = (attributeValues: any, attributeSpecs: any) => {
  return attributeSpecs
    .filter(
      (spec: any) => spec.source !== ODBMS_ATTRIBUTE_SOURCE && !filterField.includes(spec.property),
    )
    .reduce((acc: any, spec: any) => convertSpec(acc, spec, attributeValues), {})
}
