import React from 'react'
// @ts-ignore
import brokenShoeSVG from './brokenShoe.svg'
import {
  somethingHasBroken,
  errorMessage,
  errorInstructions,
  contentWrapper,
  brokenShoe,
} from './SomethingHasBroken.scss'

export const ErrorMessage = () => (
  <div className={errorMessage}>
    Sorry, something has broken in the tech and we could not get products for you.
  </div>
)

export const ErrorInstructions = () => (
  <div className={errorInstructions}>
    Please try again shortly when we have had some time to fix this, or call us on 02089912888 or
    raise a ticket at
    <a href="https://riverisland.service-now.com"> https://riverisland.service-now.com</a>
  </div>
)

export const BrokenShoe = () => <img className={brokenShoe} src={brokenShoeSVG} />

export default function SomethingHasBroken() {
  return (
    <div className={somethingHasBroken} data-e2e="something-has-broken">
      <div className={contentWrapper}>
        <BrokenShoe />
        <ErrorMessage />
        <ErrorInstructions />
      </div>
    </div>
  )
}
