import {
  CLEAR_MULTI_SELECT,
  SWITCH_MULTI_SELECT_MODE,
  UPDATE_ALL_MULTI_SELECT,
  UPDATE_BATCH_MULTI_SELECT,
  UPDATE_MULTI_SELECT,
} from '../../../actions/grid/multiSelect'
import {
  ADD_EXACT_FILTER_SUCCESS,
  REMOVE_EXACT_FILTER_SUCCESS,
} from '../../../actions/grid/exactFilters'
import {
  ADD_PARTIAL_FILTER_SUCCESS,
  REMOVE_PARTIAL_FILTER_SUCCESS,
} from '../../../actions/grid/partialFilters'
import { SVPAction } from 'actions/svp'

export type MultiSelectState = {
  mode: boolean
  selectAll: boolean
  selectedIds: string[]
}

const defaultState = {
  mode: false,
  selectAll: false,
  selectedIds: [],
}

const getSelectedIds = (
  state: MultiSelectState,
  { payload: { isSelected, productSlug } }: SVPAction<typeof UPDATE_MULTI_SELECT>,
) => {
  return isSelected
    ? [...state.selectedIds, productSlug]
    : [...state.selectedIds.filter((id) => id !== productSlug)]
}

export const multiSelect = (state: MultiSelectState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case UPDATE_MULTI_SELECT:
      return state.mode
        ? {
            ...state,
            selectedIds: getSelectedIds(state, action),
          }
        : state
    case UPDATE_ALL_MULTI_SELECT:
      return {
        ...state,
        selectAll: action.checked,
        selectedIds: [],
      }
    case SWITCH_MULTI_SELECT_MODE:
      return {
        ...defaultState,
        mode: !state.mode,
      }
    case UPDATE_BATCH_MULTI_SELECT:
      return {
        ...state,
        selectAll: false,
        selectedIds: action.selectedIds,
      }
    case ADD_EXACT_FILTER_SUCCESS:
    case REMOVE_EXACT_FILTER_SUCCESS:
    case ADD_PARTIAL_FILTER_SUCCESS:
    case REMOVE_PARTIAL_FILTER_SUCCESS:
    case CLEAR_MULTI_SELECT:
      return {
        ...state,
        selectAll: false,
        selectedIds: [],
      }
    default:
      return state
  }
}
