import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'

import { Page } from './Page'
import { AppState } from 'src/reducers/root'
import { Status } from 'src/selectors/status'
import { fetchReferenceData } from '../../actions/referenceData/referenceData'
import { referenceDataStatus } from '../../selectors/referenceData/status'

type Props = {
  fetchReferenceData: () => void
  dependenciesStatus: Status
}

export class PageContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchReferenceData()
  }

  render() {
    const { dependenciesStatus } = this.props
    return <Page dependenciesStatus={dependenciesStatus} />
  }
}

const mapStateToProps = (state: AppState) => ({
  dependenciesStatus: referenceDataStatus(state),
})

export const ConnectedPageContainer = connect(
  mapStateToProps,
  {
    fetchReferenceData,
  },
  // @ts-ignore
)(PageContainer)

/*
This is needed to stop blocked updates from react router
https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
*/

export const ConnectedPageContainerWithRouter = withRouter(
  // @ts-ignore
  ConnectedPageContainer,
)
