import { visit } from 'src/service/hierarchy'
import { TreeHierarchyItem, FlatHierarchyItem } from 'src/types/index'
import { LookupHierarchyState } from 'src/reducers/referenceData/hierarchy/lookupTable'
import { DropdownOption } from 'types/Dropdowns'

const makeFlattenedHierarchyElement = (treeElement: TreeHierarchyItem): FlatHierarchyItem => ({
  slug: treeElement.slug,
  levelCode: treeElement.levelCode,
  levelName: treeElement.levelName,
  title: treeElement.title,
})

export const flattenHierarchy = (tree: TreeHierarchyItem[]): LookupHierarchyState => {
  const flattened: Record<string, FlatHierarchyItem> = {}

  tree.forEach((treeHierarchyItem: TreeHierarchyItem) =>
    visit(treeHierarchyItem, (node: TreeHierarchyItem) => {
      flattened[node.slug] = makeFlattenedHierarchyElement(node)
    }),
  )

  return flattened
}

export const hierarchyToDropdownOption = (hierarchyItem: FlatHierarchyItem): DropdownOption => ({
  value: hierarchyItem.slug,
  label: `${hierarchyItem.title} - ${hierarchyItem.levelCode}`,
  data: {
    value: hierarchyItem.slug,
    label: `${hierarchyItem.title} - ${hierarchyItem.levelCode}`,
  },
})
