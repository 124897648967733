export type Response = {
  status: number
  data: {
    error?: {
      code?: number
    }
  }
}

type middleware = (res: Response) => void

const serviceMiddleware: Array<middleware> = []

export function registerMiddleware(args: Array<middleware>) {
  serviceMiddleware.push(...args)
}

export function execute(response: Response) {
  serviceMiddleware.forEach((func) => func(response))
}
