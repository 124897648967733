import React from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

import HierarchyDrawer from '../HierarchyDrawer'
import { hierarchyTrigger, toggleHierarchy, hierarchyTag } from './ToggleHierarchy.scss'
import { GridType, SelectedHierarchy, TreeHierarchyItem } from 'src/types/index'
import { PlusIcon } from 'src/assets/icons'
import { Tag } from 'src/components/Tag'
import { getParamsFromQueryString, toQueryString } from 'src/helpers/queryParameters'
import { QueryKeys, QueryParameters } from 'src/types/QueryParameters'
interface Props extends RouteComponentProps {
  selectHierarchy: (hierachy: string) => void
  deselectHierarchy: (hierachy: string) => void
  selectedHierarchies: SelectedHierarchy[]
  classes?: string
  gridName: GridType
}

type State = {
  hierarchyOpen: boolean
}

export class ToggleHierarchy extends React.Component<Props, State> {
  state = {
    hierarchyOpen: false,
  }

  static defaultProps = {
    selectedHierarchies: [],
  }

  openHierarchy = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    this.setState({
      hierarchyOpen: true,
    })
  }

  closeHierarchy = () => {
    this.setState({ hierarchyOpen: false })
  }

  updateQueryParameters = (key: QueryKeys, value: any) => {
    const { history } = this.props
    const params: QueryParameters = getParamsFromQueryString()
    params[key] = value
    history.push(`?${toQueryString(params)}`)
  }

  onHierarchyNodeSelected = (node: TreeHierarchyItem) => {
    const hierarchyIDs = [
      ...this.props.selectedHierarchies.map((hierarchy) => hierarchy.slug),
      node.slug,
    ]
    this.updateQueryParameters('hierarchyIDs', hierarchyIDs)
    this.setState({ hierarchyOpen: false })
    this.props.selectHierarchy(node.slug)
  }

  onDeselectHierarchy = (slug: string) => {
    const hierarchyIDs = this.props.selectedHierarchies
      .filter((hierarchy) => hierarchy.slug !== slug)
      .map((hierarchy) => hierarchy.slug)
    this.updateQueryParameters('hierarchyIDs', hierarchyIDs)
    this.props.deselectHierarchy(slug)
  }

  render() {
    const { selectedHierarchies } = this.props
    return (
      <div id="ToggleHierarchy" className={toggleHierarchy}>
        <a href="" className={hierarchyTrigger} onClick={this.openHierarchy}>
          <PlusIcon title="Select hierarchy" />
        </a>
        {selectedHierarchies.map(({ title, levelCode, slug }, index) => (
          <Tag
            key={index}
            value={`${title} - ${levelCode}`}
            tagType="hierarchy"
            className={hierarchyTag}
            onClose={() => this.onDeselectHierarchy(slug)}
            dataCy={`removeHierarchy-${index}`}
          />
        ))}
        {this.state.hierarchyOpen && (
          <HierarchyDrawer
            onNodeSelected={this.onHierarchyNodeSelected}
            gridName={this.props.gridName}
            onClose={this.closeHierarchy}
          />
        )}
      </div>
    )
  }
}

export default withRouter(ToggleHierarchy)
