import React from 'react'
import { notAvailable, userSettingContainer } from './UserSettings.scss'
import { SizesContainer } from './Sizes/SizesContainer'
import { IntentionalAny } from 'types'
import { DefaultAttributesContainer } from './DefaultAttributes/DefaultAttributesContainer'

interface Props {
  selectedSettingKey: string
}

const renderers: Record<string, React.ComponentType<IntentionalAny>> = {
  sizes: SizesContainer,
  defaultAttributes: DefaultAttributesContainer,
}

export const UserSettings = ({ selectedSettingKey }: Props): JSX.Element => {
  const Setting = renderers[selectedSettingKey]
  return selectedSettingKey && selectedSettingKey in renderers ? (
    <div className={userSettingContainer}>
      <Setting />
    </div>
  ) : (
    <h4 className={notAvailable}>Not Available</h4>
  )
}
