import { AttributeValueMap, CellType, Product } from 'src/types/index'
import { ProductStatus } from 'src/types/ProductStatus'
import {
  CANCELLED,
  CANCELLED_IN_DEVELOPMENT,
  CANCELLED_IN_PROPOSED,
  CANCELLED_IN_READY_TO_BUY,
  DEVELOPMENT,
  PENDING_ODBMS,
  PROPOSED,
  READY_TO_BUY,
} from 'src/constants/productStatus'
import { strategyRegistryLookup } from '../../registry/behaviourRegistry'
import { RangeHierachyLevel, SlugHierachyType } from '../../constants/hierachy'

export const validateRequiredFields = (
  product: Product,
  requiredFields: Array<{ property: string; type: CellType }>,
  hierarchyLevel: string,
): string[] =>
  requiredFields
    .filter(({ property, type }) => {
      const hierarchyNotAtRangeLevel =
        type === SlugHierachyType && hierarchyLevel !== RangeHierachyLevel
      const attributeIsEmpty = strategyRegistryLookup(type).isEmpty(product[property])
      return hierarchyNotAtRangeLevel || attributeIsEmpty
    })
    .map(({ property }) => property)

export const validateDeprecatedFields = (
  product: Product,
  allValues: AttributeValueMap,
): string[] => {
  const deprecatedProductFieldNames: Array<string> = []

  Object.keys(product).forEach((productProperty) => {
    const allPropertyValues = allValues[productProperty]
    if (allPropertyValues) {
      const productPropertyValue = product[productProperty]
      const deprecated = allPropertyValues.filter(
        (v) =>
          productPropertyValue &&
          (v.value === productPropertyValue ||
            (Array.isArray(productPropertyValue) && productPropertyValue.includes(v.value))) &&
          v.deprecated,
      )
      if (deprecated.length > 0) {
        deprecatedProductFieldNames.push(productProperty)
      }
    }
  })
  return deprecatedProductFieldNames
}

export const isValidStatusTransition = (
  sourceStatus: ProductStatus,
  destinationStatus: ProductStatus,
): boolean => {
  const transitionsMap: Record<string, Array<string>> = {
    [DEVELOPMENT]: [PROPOSED, CANCELLED],
    [PROPOSED]: [READY_TO_BUY, CANCELLED],
    [READY_TO_BUY]: [CANCELLED],
    [CANCELLED]: [],
    [CANCELLED_IN_DEVELOPMENT]: [],
    [CANCELLED_IN_PROPOSED]: [],
    [CANCELLED_IN_READY_TO_BUY]: [],
    [PENDING_ODBMS]: [READY_TO_BUY],
  }

  const possibleStatuses = transitionsMap[sourceStatus]

  return possibleStatuses.includes(destinationStatus)
}

export const getDropdownValidationErrors = (value: any, mandatory: boolean): Array<string> =>
  !mandatory || (value && (value.slug || value.code)) ? [] : ['field is mandatory']
