import React from 'react'

interface Props {
  className: string
  colour: string
}

export const SampleImageIcon = ({ className, colour = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" className={className}>
    <title>Sample image</title>
    <path
      d="M12.21,3.74c0,.32,0,.63,0,.94a.31.31,0,0,0,.14.23L14.6,6.16a.39.39,0,0,0,.17.06,7.88,7.88,0,0,1,5.51,1.93c.84.68,1.65,1.41,2.48,2.12.3.26.3.4,0,.68l-3.22,3.22c-.25.25-.39.25-.64,0s-.42-.44-.63-.66l-.08,0V25.38c0,.52-.07.59-.58.59H6.07c-.44,0-.53-.09-.53-.54V13.54l-.09-.06c-.21.23-.41.48-.63.7s-.38.24-.61,0L.93,10.91c-.22-.22-.22-.39,0-.58,1-.84,1.93-1.71,2.95-2.5A7.66,7.66,0,0,1,8.59,6.21a2,2,0,0,0,1-.3c.58-.33,1.15-.66,1.73-1a.33.33,0,0,0,.2-.35c0-.32,0-.64,0-1s0-.48.46-.55a1.13,1.13,0,0,0,.78-1.83A1.08,1.08,0,0,0,11.61.84a1.1,1.1,0,0,0-.85.91l0,.22a.3.3,0,0,1-.33.3.31.31,0,0,1-.32-.34,1.92,1.92,0,0,1,.07-.48A1.81,1.81,0,0,1,12,.14a1.81,1.81,0,0,1,.44,3.52Zm9.94,6.87c-.83-.71-1.64-1.42-2.47-2.1a7.12,7.12,0,0,0-2.82-1.4c-.52-.13-1-.19-1.58-.28A3.45,3.45,0,0,1,12,9.93a3.32,3.32,0,0,1-2.05-.6A3.42,3.42,0,0,1,8.45,6.88H8.18A7.7,7.7,0,0,0,3.85,8.69c-.7.59-1.39,1.2-2.09,1.8l-.17.1,2.94,2.94.19-.18c.28-.28.55-.57.84-.83A.6.6,0,0,1,6,12.37c.21,0,.22.24.22.42a1.62,1.62,0,0,0,0,.22V25.29H17.55V12.84c0-.19,0-.38.19-.48s.36.06.5.2l1,1ZM11.86,9.26a2.74,2.74,0,0,0,2.67-2.09c0-.18,0-.28-.16-.37L12.09,5.52a.36.36,0,0,0-.44,0c-.76.44-1.51.87-2.28,1.29a.32.32,0,0,0-.17.44A2.75,2.75,0,0,0,11.86,9.26Z"
      fill={colour}
    />
    <path
      d="M22.15,10.61l-2.93,2.93-1-1c-.14-.14-.27-.3-.5-.2s-.19.29-.19.48V25.29H6.21V13a1.62,1.62,0,0,1,0-.22c0-.18,0-.39-.22-.42a.6.6,0,0,0-.43.15c-.29.26-.56.55-.84.83l-.19.18L1.59,10.59l.17-.1c.7-.6,1.39-1.21,2.09-1.8A7.7,7.7,0,0,1,8.18,6.88h.27A3.42,3.42,0,0,0,9.92,9.33a3.32,3.32,0,0,0,2.05.6,3.45,3.45,0,0,0,3.31-3.1c.53.09,1.06.15,1.58.28a7.12,7.12,0,0,1,2.82,1.4C20.51,9.19,21.32,9.9,22.15,10.61Z"
      fill="none"
      stroke={colour}
      strokeWidth="0.25"
    />
    <path
      d="M11.86,9.26a2.75,2.75,0,0,1-2.66-2,.32.32,0,0,1,.17-.44c.77-.42,1.52-.85,2.28-1.29a.36.36,0,0,1,.44,0L14.37,6.8c.18.09.2.19.16.37A2.74,2.74,0,0,1,11.86,9.26Z"
      fill="none"
      stroke={colour}
      strokeWidth="0.25"
    />
  </svg>
)
