import ReactGA from 'react-ga'

let shouldTrack = false
const gaCode = 'UA-101889523-14'

interface Config {
  env: string
}

export function init(config: Config, options: any = {}) {
  shouldTrack = config.env === 'prod'

  if (!shouldTrack) {
    return
  }

  ReactGA.initialize(gaCode, {
    ...options,
  })
}

export function trackPage(page: string, location: any, search: string) {
  if (!shouldTrack) {
    return
  }

  ReactGA.set({
    page,
    search,
    location: `${location.origin}${page}`,
  })

  ReactGA.pageview(page)
}

export function trackEvent(category: string, action: string, label?: string) {
  if (!shouldTrack) {
    return
  }

  ReactGA.event({
    category,
    action,
    label,
  })
}
