// @ts-nocheck as the attribute value vs dropdown options differences

import { connect } from 'react-redux'
import DropdownCellRenderer from './DropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { getAttributeValues } from '../../../selectors/referenceData/attributes'
import { AttributeValue } from 'src/types/index'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  dropdownItems: getAttributeValues(state)[ownProps.accessor] as AttributeValue[],
})

const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => ({
  ...stateProps,
  ...ownProps,
  createOption: (item: AttributeValue) => ({
    value: item.slug,
    label: item.value,
    data: item,
  }),
  createPatchItem: (item: AttributeValue) => ({
    apiValue: item.slug,
    storeValue: item.value,
  }),
})

export const DropdownCellRendererContainer = connect(
  mapStateToProps,
  {},
  mergeProps,
)(DropdownCellRenderer)
