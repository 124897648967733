import { injectorService } from '../../service/injector/injectorService'
import { getAttributes, getAttributeValues } from '../../selectors/referenceData/attributes'
import { updateProductSuccess } from '../../actions/grid/product/update'
import { apiProductToProductWithDefaults } from '../../service/mappers'
import { call, put, select } from 'redux-saga/effects'
import { errorAndNotification } from '../error'
import { getCountries } from '../../selectors/referenceData/countries'
import { ApiProduct, Attribute, AttributeValueMap, Country, GridType } from 'src/types/index'
import { APIError } from 'types/Api'
import { SVPSaga } from 'sagas'

export function* reloadProductSaga(productSlug: string, grid: GridType): SVPSaga {
  try {
    const apiProduct = (yield call(injectorService.get, `products/${productSlug}`)) as ApiProduct

    const attributes = (yield select(getAttributes)) as Attribute[]
    const attributeValues = (yield select(getAttributeValues)) as AttributeValueMap
    const countries = (yield select(getCountries)) as Country[]

    yield put(
      updateProductSuccess(
        productSlug,
        apiProductToProductWithDefaults(apiProduct, attributes, attributeValues, countries),
        grid,
      ),
    )
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Something went wrong and we couldn't reload product information.\n Please refresh the page.",
    )
  }
}
