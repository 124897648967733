import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  getWholesaleImagesSuccess,
  GET_WHOLESALE_IMAGES,
  REMOVE_WHOLESALE_IMAGE_FILE,
  REPLACE_WHOLESALE_IMAGE_FILE,
  setWholesaleImagesLoading,
  updateWholesaleImagesSequenceSuccess,
  UPDATE_WHOLESALE_IMAGES_SEQUENCE,
  UPLOAD_WHOLESALE_IMAGE_FILE,
} from 'src/actions/wholesaleImages'
import { showErrorNotification } from 'src/actions/notification'
import { logError } from 'src/service/errors'
import { GetWholesaleImagesResponse } from 'src/types/WholesaleImages'
import { extractBase64FromDataUrl, getImageDataUrl } from 'service/image/processImage'
import { injectorService } from 'src/service/injector/injectorService'
import { WholesaleError } from 'src/types/Api'
import { getEventErrorMessage } from 'src/service/wholesale/eventErrors'
import {
  ERROR_CODE_DELETE_REQUEST,
  ERROR_CODE_REPLACE_REQUEST_PATH,
  ERROR_CODE_UPLOAD_REQUEST_BODY,
  ERROR_CODE_UPLOAD_UPSERT,
} from 'constants/errorCodes'
import { SVPAction } from 'actions/svp'
import { reloadProductSaga } from './product/reload'
import { getGrid } from 'selectors/grid'
import { GridType } from 'types/GridTypes'
import { SVPSaga } from 'sagas'

export function* uploadWholeSaleImageFileSaga(
  action: SVPAction<typeof UPLOAD_WHOLESALE_IMAGE_FILE>,
): SVPSaga {
  let lastAvailableSequence: number = action.sequence
  const imagesToRefresh = [lastAvailableSequence]
  try {
    const grid = (yield select(getGrid)) as GridType
    yield put(setWholesaleImagesLoading())
    for (const file of action.files) {
      try {
        const imageBlobUrl = (yield call(getImageDataUrl, file, true)) as string

        yield call(
          injectorService.post,
          `wholesale-images`,
          {
            productSlug: action.productSlug,
            developmentId: action.developmentId,
            sequence: lastAvailableSequence,
            imageBinary: extractBase64FromDataUrl(imageBlobUrl),
          },
          { rootEndpoint: true },
        )
        imagesToRefresh.push(lastAvailableSequence)
        lastAvailableSequence++
      } catch (error) {
        logError(error as Error)
        const wholesaleError: WholesaleError = error as WholesaleError
        yield put(
          showErrorNotification(
            getEventErrorMessage(wholesaleError?.data?.code ?? ERROR_CODE_UPLOAD_UPSERT),
          ),
        )
        continue
      }
    }

    yield call(getWholesaleImagesSaga, {
      type: GET_WHOLESALE_IMAGES,
      productSlug: action.productSlug,
      imagesToRefresh,
    })
    yield call(reloadProductSaga, action.productSlug, grid)
  } catch (error) {
    yield call(logError, error as Error)
    const wholesaleError: WholesaleError = error as WholesaleError
    yield put(
      showErrorNotification(
        getEventErrorMessage(wholesaleError?.data?.code ?? ERROR_CODE_UPLOAD_REQUEST_BODY),
      ),
    )
  }
}

export function* getWholesaleImagesSaga(action: SVPAction<typeof GET_WHOLESALE_IMAGES>): SVPSaga {
  try {
    yield put(setWholesaleImagesLoading())
    const response =
      ((yield call(
        injectorService.get,
        `wholesale-images/${action.productSlug}`,
      )) as GetWholesaleImagesResponse) || []
    yield put(getWholesaleImagesSuccess(response, action.imagesToRefresh))
  } catch (error) {
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could not find gallery images'))
  }
}

export function* updateWholesaleImagesSequenceSaga(
  action: SVPAction<typeof UPDATE_WHOLESALE_IMAGES_SEQUENCE>,
): SVPSaga {
  try {
    const grid = (yield select(getGrid)) as GridType
    yield put(setWholesaleImagesLoading())
    yield call(injectorService.post, `wholesale-images-resequence`, action.payload)
    yield call(reloadProductSaga, action.productSlug, grid)
    yield put(updateWholesaleImagesSequenceSuccess(action.payload))
  } catch (error) {
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could not update gallery images order'))
  }
}

export function* replaceWholesaleImageFileSaga(
  action: SVPAction<typeof REPLACE_WHOLESALE_IMAGE_FILE>,
): SVPSaga {
  try {
    const grid = (yield select(getGrid)) as GridType
    yield put(setWholesaleImagesLoading())
    const imageBlobUrl = (yield call(getImageDataUrl, action.file, true)) as string
    yield call(
      injectorService.put,
      `wholesale-images/${action.slug}`,
      {
        imageBinary: extractBase64FromDataUrl(imageBlobUrl),
      },
      { rootEndpoint: true },
    )
    yield call(getWholesaleImagesSaga, {
      type: GET_WHOLESALE_IMAGES,
      productSlug: action.productSlug,
      imagesToRefresh: [action.sequence],
    })

    if (action.sequence === 1) {
      yield call(reloadProductSaga, action.productSlug, grid)
    }
  } catch (error) {
    const wholesaleError: WholesaleError = error as WholesaleError
    yield call(logError, error as Error)
    yield put(
      showErrorNotification(
        getEventErrorMessage(wholesaleError?.data?.code ?? ERROR_CODE_REPLACE_REQUEST_PATH),
      ),
    )
  }
}

export function* removeWholesaleImageFileSaga(
  action: SVPAction<typeof REMOVE_WHOLESALE_IMAGE_FILE>,
): SVPSaga {
  try {
    const grid = (yield select(getGrid)) as GridType
    yield put(setWholesaleImagesLoading())
    yield call(injectorService.delete, `wholesale-images/${action.slug}`, {
      rootEndpoint: true,
    })
    yield call(getWholesaleImagesSaga, {
      type: GET_WHOLESALE_IMAGES,
      productSlug: action.productSlug,
      imagesToRefresh: [],
    })
    yield call(reloadProductSaga, action.productSlug, grid)
  } catch (error) {
    yield call(logError, error as Error)
    const wholesaleError: WholesaleError = error as WholesaleError
    yield put(
      showErrorNotification(
        getEventErrorMessage(wholesaleError?.data?.code ?? ERROR_CODE_DELETE_REQUEST),
      ),
    )
  }
}

export default function* (): SVPSaga {
  yield all([
    takeEvery(UPLOAD_WHOLESALE_IMAGE_FILE, uploadWholeSaleImageFileSaga),
    takeEvery(GET_WHOLESALE_IMAGES, getWholesaleImagesSaga),
    takeEvery(UPDATE_WHOLESALE_IMAGES_SEQUENCE, updateWholesaleImagesSequenceSaga),
    takeEvery(REPLACE_WHOLESALE_IMAGE_FILE, replaceWholesaleImageFileSaga),
    takeEvery(REMOVE_WHOLESALE_IMAGE_FILE, removeWholesaleImageFileSaga),
  ])
}
