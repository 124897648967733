import React from 'react'
import { Season } from 'src/types/index'
import {
  seasonToolContainer,
  header,
  title,
  subtitle,
  seasonsContainer,
  seasonsGrid,
  current,
} from './SeasonTool.scss'
import classNames from 'classnames'

type Props = {
  seasons: Season[]
}

export const SeasonTool = ({ seasons }: Props) => (
  <div className={seasonToolContainer}>
    <div className={seasonsContainer}>
      <div className={header}>
        <h3 className={title}>All seasons</h3>
        <p className={subtitle}>
          New seasons will be automatically created by the system at the end of the year
        </p>
      </div>
      <div className={seasonsGrid}>
        {seasons.map(({ code, isCurrent }: Season) => {
          const seasonClassname = classNames({
            [current]: isCurrent,
          })
          return (
            <span data-cy="season" className={seasonClassname} key={code}>
              {code}
            </span>
          )
        })}
      </div>
    </div>
  </div>
)
