import * as EventErrors from 'src/constants/errorCodes'

import {
  ProductEventError,
  ProductEventErrorCodes,
  ProductEventErrorTypes,
} from 'src/types/ProductEventError'

type errorFormatter = (data?: string[]) => string

const messageDelimiter = ', '

const itemTitleMap: Record<ProductEventErrorTypes, string> = {
  [EventErrors.MARK_READY_TO_BUY_PRODUCT_ERROR_TYPE]: 'Mark as Ready To Buy',
  [EventErrors.SIZE_AFTER_READY_TO_BUY_PRODUCT_ERROR_TYPE]: 'Update Sizes After Ready To Buy',
  [EventErrors.MARK_CANCELLED_ERROR_TYPE]: 'Mark as Cancelled',
}

const itemMessageMap: Record<ProductEventErrorCodes, errorFormatter> = {
  [EventErrors.MARK_READY_TO_BUY_GENERIC_ERROR]: () => {
    return 'An error occurred. Please try marking as Ready To Buy again'
  },
  [EventErrors.MARK_READY_TO_BUY_ORDS_ERROR]: () => {
    return 'Error occurred connecting to ODBMS. Please try marking as Ready To Buy later'
  },
  [EventErrors.SIZE_AFTER_READY_TO_BUY_GENERIC_ERROR]: () => {
    return 'An error occurred. Please try updating sizes again'
  },

  [EventErrors.SIZE_AFTER_READY_TO_BUY_ORDS_ERROR]: () => {
    return 'Error occurred updating sizes in ODBMS. Please try updating sizes later'
  },
  [EventErrors.SIZE_AFTER_READY_TO_BUY_COMMITMENT_ERROR]: (data) => {
    return `Unable to update sizes as there’s commitment against sizes: ${
      data?.join(messageDelimiter).toUpperCase() || ''
    }`
  },
  [EventErrors.MARK_CANCELLED_SVP_ERROR]: () => {
    return 'An error occured. Please try marking as Cancelled again'
  },
  [EventErrors.MARK_CANCELLED_ODBMS_ERROR]: () => {
    return 'Error occurred connecting to ODBMS. Please try marking as Cancelled again'
  },
  [EventErrors.MARK_CANCELLED_SKU_IN_USE_ERROR]: () => {
    return 'A purchase order has already been raised for this product'
  },
  [EventErrors.MARK_CANCELLED_ODBMS_AND_ODBMS_ERROR]: () => {
    return 'Error occurred connecting to ODBMS. Please try marking as Cancelled again'
  },
  [EventErrors.MARK_CANCELLED_ODBMS_AND_SKU_IN_USE_ERROR]: () => {
    return 'Error occurred connecting to ODBMS. Please try marking as Cancelled again'
  },
  [EventErrors.MARK_CANCELLED_SKU_IN_USE_AND_ODBMS_ERROR]: () => {
    return 'Error occurred connecting to ODBMS. All SKUs not cancelled. Please try marking as Cancelled again'
  },
  [EventErrors.MARK_CANCELLED_SKU_IN_USE_SKU_IN_USE_ERROR]: () => {
    return 'A purchase order has already been raised for this product'
  },
}

export function getEventErrorTitle(error: ProductEventError) {
  return itemTitleMap[error.type]
}

export function getEventErrorMessage(error: ProductEventError) {
  return itemMessageMap[error.code](error.data)
}

export function isErrorDismissable(error: ProductEventError) {
  const dismissableErrors = [
    EventErrors.SIZE_AFTER_READY_TO_BUY_COMMITMENT_ERROR,
    EventErrors.MARK_CANCELLED_SKU_IN_USE_ERROR,
    EventErrors.MARK_CANCELLED_ODBMS_AND_SKU_IN_USE_ERROR,
    EventErrors.MARK_CANCELLED_SKU_IN_USE_SKU_IN_USE_ERROR,
  ]

  return dismissableErrors.indexOf(error.code.toString()) > -1
}
