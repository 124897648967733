import { delay } from 'redux-saga'
import { call, take, race, put, select } from 'redux-saga/effects'

import { injectorService } from 'src/service/injector/injectorService'
import { logError } from 'src/service/errors'
import { START_POLLING, STOP_POLLING } from 'src/actions/polling/types'
import { POLL } from 'src/constants/apiUrls'
import { PollResponse } from 'src/types/responses/polling'
import {
  pollingError,
  pollingIDComplete,
  reconcilePollingIDs,
  stopPolling,
} from 'src/actions/polling/polling'
import { AppState } from 'src/reducers/root.js'
import { PollingID } from 'src/types/Polling'
import { addBreadcrumb } from 'src/helpers/sentry'

const defaultInterval = 5000 // 5min

export const getStorePollingIDs = (store: AppState): Array<PollingID> =>
  store.injectorUI.polling.pollingIDs

export function* callPoller() {
  try {
    const polls: Array<PollingID> = yield select(getStorePollingIDs)

    // If there are no IDs in the store then stop polling
    if (!polls || polls.length === 0) {
      yield put(stopPolling())
    }

    const pollingIDs = polls.map((poll) => poll.id).join(',')

    addBreadcrumb('polling', `Polling ${pollingIDs}`)

    const params = new URLSearchParams()
    params.append('pollingIDs', pollingIDs)

    const pollResponse: PollResponse = yield call(injectorService.get, POLL, params, {
      v2Endpoint: true,
    })

    yield put(reconcilePollingIDs(pollResponse.pollingIDs))

    const completedPolls = polls.filter((x) => !pollResponse.pollingIDs.includes(x.id))

    if (completedPolls.length > 0) {
      addBreadcrumb('polling', `Polling Completed ${completedPolls.join(', ')}`)

      yield put(pollingIDComplete(completedPolls))
    }

    yield call(delay, defaultInterval)
  } catch (err) {
    yield call(logError, err as Error)
    yield put(pollingError(err as Error))

    // In the event of an error coming back from the API, delay for an interval before retrying
    yield call(delay, defaultInterval)
  }
}

export function* pollWorker() {
  while (true) {
    yield callPoller()
  }
}

export default function* rootSaga() {
  while (true) {
    yield take(START_POLLING)

    yield race({
      task: call(pollWorker),
      cancel: take(STOP_POLLING),
    })
  }
}
