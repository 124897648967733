import { connect } from 'react-redux'
import { AppState } from 'src/reducers/root'
import { DevelopmentIdCellRenderer } from './DevelopmentIdCellRenderer'
import { getGrid } from 'src/selectors/grid'

const mapStateToProps = (state: AppState) => ({
  gridName: getGrid(state),
})

export const DevelopmentIdCellRendererContainer =
  connect(mapStateToProps)(DevelopmentIdCellRenderer)
