import { SELECT_HIERARCHY } from 'src/actions/grid/hierarchy'
import { CLEAR_AND_SELECT_HIERARCHY } from '../../../actions/grid/hierarchy'
import { ADD_EXACT_FILTER_SUCCESS } from '../../../actions/grid/exactFilters'
import { ADD_PARTIAL_FILTER_SUCCESS } from '../../../actions/grid/partialFilters'
import { SVPAction } from 'actions/svp'
import { DUPLICATE_PRODUCT_SUCCESS } from 'actions/grid/product/copy'
import { UPDATE_PRODUCT_SUCCESS } from 'actions/grid/product/update'
import { FETCH_FIRST_PRODUCTS_PAGE } from 'actions/grid/product/fetch'

export type PinnedProductSlugsState = string[]

const defaultState: PinnedProductSlugsState = []

const addPin = (state: PinnedProductSlugsState, devId: string) => [...new Set([...state, devId])]

const clearIfNonBlank = (state: PinnedProductSlugsState, value: string) =>
  value.trim() === '' ? state : defaultState

export const pinnedProductSlugs = (
  state: PinnedProductSlugsState = defaultState,
  action: SVPAction,
) => {
  switch (action.type) {
    case DUPLICATE_PRODUCT_SUCCESS:
      return addPin(state, action.payload.newProduct.slug)
    case UPDATE_PRODUCT_SUCCESS:
      return addPin(state, action.productSlug)
    case ADD_PARTIAL_FILTER_SUCCESS:
      return clearIfNonBlank(state, action.filter.value)
    case FETCH_FIRST_PRODUCTS_PAGE:
    case ADD_EXACT_FILTER_SUCCESS:
    case SELECT_HIERARCHY:
    case CLEAR_AND_SELECT_HIERARCHY:
      return defaultState
    default:
      return state
  }
}
