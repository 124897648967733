import { DropdownStyle, ExtendedDropdownStyle } from 'types/Dropdowns'

export const getCustomStyles = (
  props: any,
  applyCustomEffects: (state: any, style?: ExtendedDropdownStyle) => DropdownStyle,
) => ({
  container: (provided: any) => ({
    ...provided,
    ...props.customContainerStyles,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 0,
    borderColor: !props.isValid ? '#FF586D' : '#eef0ef',
    boxShadow: 'none',
    color: '#222',
    height: `${5.75 * 0.5}rem`,
    zIndex: 1,
    marginBottom: '1.25rem',
    ...props.customControlStyles,
    ...applyCustomEffects(state, props.customControlStyles),
  }),
  menu: (provided: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 1,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    minWidth: 180,
    zIndex: 10,
    margin: 0,
    ...props.customMenuStyles,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    ...props.customMenuListStyles,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textDecoration: props.isDeprecated ? 'line-through' : 'none',
    ...props.customSingleValueStyles,
  }),
  valueContainer: () => ({
    height: 46,
    width: '100%',
    padding: '0 10px',
    ...props.customValueContainerStyles,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    ...props.customMultiValueStyles,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    ...props.customMultiValueRemoveStyles,
  }),
  option: (
    provided: any,
    state: { data: { deprecated: any }; isFocused: any; isSelected: any },
  ) => ({
    ...provided,
    textDecoration: state.data.deprecated ? 'line-through' : 'none',
    color: '#666',
    backgroundColor: state.isFocused ? '#e6e6e6' : state.isSelected ? '#eef0ef' : '#ffffff',
    '&:active': {
      backgroundColor: '#e6e6e6',
    },
    ...props.customOptionStyles,
    ...applyCustomEffects(state, props.customOptionStyles),
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
    padding: 0,
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...props.customInputStyles,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#ddd',
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...props.customPlaceholderStyles,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    ...props.customIndicatorsContainerStyles,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    ...props.customDropdownIndicatorStyles,
    ...applyCustomEffects(state, props.customControlStyles),
  }),
})
