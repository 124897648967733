import React from 'react'
import { container, header, gridView, rowHeader, rowData } from './Views.scss'
import { DepartmentViews } from 'src/types/DepartmentSpec'
import { DEV_TRACKER, OPTION_LIBRARY, GridViews } from 'src/types/index'

import { Checkbox } from 'src/components/Checkbox/Checkbox'

type Props = {
  views: string[]
  referenceData: DepartmentViews
  toggleView: (view: string) => void
}

type GriViewsProps = {
  views: string[]
  gridViews: GridViews
  gridName: string
  toggleView: (view: string) => void
}

export const GridView = ({
  gridName,
  gridViews,
  views,
  toggleView,
}: GriViewsProps): JSX.Element => (
  <div className={gridView}>
    <h5 data-cy="grid-view" className={header}>
      {gridName}
    </h5>
    <table>
      <tbody>
        {Object.keys(gridViews).map((viewSlug, i) => (
          <tr data-cy="admin-department-attribute-grid-view" key={`row-${i}`}>
            <th className={rowHeader}>{gridViews[viewSlug].displayName}</th>
            <td className={rowData} data-cy={gridViews[viewSlug].displayName}>
              <Checkbox
                checked={views.includes(viewSlug)}
                onClick={(): void => toggleView(viewSlug)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export const Views = ({ views, toggleView, referenceData }: Props): JSX.Element => (
  <div className={container} data-cy="attribute-views">
    <GridView
      gridName="Dev tracker"
      views={views}
      gridViews={referenceData[DEV_TRACKER]}
      toggleView={toggleView}
    />
    <GridView
      gridName="Option library"
      views={views}
      gridViews={referenceData[OPTION_LIBRARY]}
      toggleView={toggleView}
    />
  </div>
)
