import { AppState } from 'reducers/root'
import { AttributeWithColumnName } from 'types/Attributes'
import { RangeWithSize, SizesDeprecationMap } from 'types/Hierarchy'
import {
  DefaultAttributesTableData,
  SelectedDefaultAttribute,
  UserSettingsRange,
} from 'types/UserSettings'
import { getAttributeValues } from './referenceData/attributes'
import { DropdownOption } from 'types/Dropdowns'

export const getPersistentSideListSelectedSlug = (state: AppState): string =>
  state.injectorUI.persistentSideList.selectedItemSlug

export const getDepartmentRangesWithSizesSelector = (state: AppState): RangeWithSize[] =>
  state.injectorUI.userSettings.sizes.rangesWithSizes

export const getSizesDeprecationMap = (state: AppState): SizesDeprecationMap =>
  state.injectorUI.userSettings.sizes.sizesDeprecationMap

export const getIsFetchingSizes = (state: AppState): boolean =>
  state.injectorUI.userSettings.sizes.isFetchingSizes

export const getIsFetchingAttributes = (state: AppState): boolean =>
  state.injectorUI.userSettings.defaultAttributes.isFetchingAttributes

const getUnsetDefaultAttributes = (state: AppState): AttributeWithColumnName[] => {
  const unsetAttributes: AttributeWithColumnName[] = []
  const { departmentAttributes, columns } = state.injectorUI.userSettings.defaultAttributes
  Object.keys(departmentAttributes).forEach((attributeWithColumnName) => {
    if (
      !columns.find(
        (column) => column.attributeSlug === departmentAttributes[attributeWithColumnName].slug,
      )
    ) {
      unsetAttributes.push(departmentAttributes[attributeWithColumnName])
    }
  })

  return unsetAttributes
}

export const getUnsetDefaultAttributesDropdownOptions = (state: AppState): DropdownOption[] => {
  return getUnsetDefaultAttributes(state).map((attribute) => ({
    label: attribute.displayName,
    value: attribute.name,
    slug: attribute.slug,
    data: {
      type: attribute.type,
    },
  }))
}

export const getDefaultAttributesTableData = (state: AppState): DefaultAttributesTableData[][] => {
  const { columns, ranges, defaultAttributes } = state.injectorUI.userSettings.defaultAttributes
  const nonDeprecatedAttributeValues = getAttributeValues(state)
  const rangeList: UserSettingsRange[] = []
  Object.keys(ranges).forEach((rangeKey) => {
    if (
      ranges[rangeKey].toLowerCase() !== 'not in use' &&
      ranges[rangeKey].toLowerCase() !== 'other'
    ) {
      rangeList.push({
        slug: rangeKey,
        name: ranges[rangeKey],
      })
    }
  })
  const tableData: DefaultAttributesTableData[][] = [
    [{ name: 'Range Name' }, ...columns.map((column) => ({ name: column.attributeDisplayName }))],
  ]
  rangeList
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((range) => {
      const rangeColumns: DefaultAttributesTableData[] = [{ name: range.name }]
      columns.forEach((column) => {
        const defaultAttributeValues =
          defaultAttributes.find(
            (defaultAttribute) =>
              defaultAttribute.attributeSlug === column.attributeSlug &&
              range.slug === defaultAttribute.rangeSlug,
          )?.defaultValues ?? []
        rangeColumns.push({
          attributeSlug: column.attributeSlug,
          rangeSlug: range.slug,
          attributeName: column.attributeName,
          attributeType: column.attributeType,
          values: defaultAttributeValues.map((defaultValue) => {
            const attributeValues = nonDeprecatedAttributeValues[column.attributeName]
            const foundValue = attributeValues.find(
              (attributeValue) => attributeValue.slug === defaultValue,
            )
            return {
              value: foundValue?.slug,
              label: foundValue?.value,
              deprecated: foundValue?.deprecated,
            }
          }),
        })
      })
      tableData.push(rangeColumns)
    })

  return tableData
}

export const getCopiedAttribute = (state: AppState): SelectedDefaultAttribute =>
  state.injectorUI.userSettings.defaultAttributes.copiedAttribute
