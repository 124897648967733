import React, { Component, ReactElement } from 'react'
import { Product } from 'src/types/index'
import { DEV_TRACKER, OPTION_LIBRARY } from 'src/types/Grid'
import { cancel, confirm, description, modal, statusTitle, title } from './ChangeStatusModal.scss'
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton'
import { UpdateProductStatusPayload } from 'src/actions/grid/product/update'
import { SkipLink } from '../../../Link/index'
import { GridType } from 'src/types/index'
import { CANCELLED, DEVELOPMENT, PROPOSED, READY_TO_BUY } from 'src/constants/productStatus'
import { RadioButtons } from '../../../RadioButtons/RadioButtons'
import { ProductStatus } from 'src/types/ProductStatus'

interface Props {
  updateStatus: (payload: UpdateProductStatusPayload, grid: GridType) => void
  onClose: () => void
  product: Product
  grid: GridType
}

interface State {
  selectedOption?: ProductStatus
}

interface GridOption {
  value: string
  dataCy: string
  render: () => ReactElement
}

export const READY_TO_BUY_DESCRIPTION =
  "Changing the product to 'Ready to Buy' will generate a product number and can not be undone."

export const CANCELLED_DESCRIPTION = "Changing the product to 'Cancelled' can not be undone."

export const PROPOSED_DESCRIPTION = "Changing the product to 'Proposed' can not be undone."

const readyToBuyComponent = (): JSX.Element => {
  return (
    <div>
      <h3 className={statusTitle}>{READY_TO_BUY}</h3>
      <p className={description}>{READY_TO_BUY_DESCRIPTION}</p>
    </div>
  )
}

const proposedComponent = (): JSX.Element => {
  return (
    <div>
      <h3 className={statusTitle}>{PROPOSED}</h3>
      <p className={description}>{PROPOSED_DESCRIPTION}</p>
    </div>
  )
}

const cancelledComponent = (): JSX.Element => {
  return (
    <div>
      <h3 className={statusTitle}>{CANCELLED}</h3>
      <p className={`${description} ${cancel}`}>{CANCELLED_DESCRIPTION}</p>
    </div>
  )
}

export class ChangeStatusModal extends Component<Props, State> {
  state = {
    selectedOption: undefined,
  }

  render(): JSX.Element {
    const { onClose, product, grid } = this.props

    return (
      <div className={modal}>
        <h3 className={title}>Change status to:</h3>
        <RadioButtons
          name="statusOptions"
          options={[...gridBasedOption(grid, product.status), cancelOption]}
          // @ts-ignore no idea how this worked
          checkedButton={this.state.selectedOption}
          // @ts-ignore no idea how this worked
          onChange={(option: ProductStatus): void => {
            this.setState({ selectedOption: option })
          }}
        />

        <PrimaryButton
          dataCy="confirm"
          className={confirm}
          disabled={!this.state.selectedOption}
          onClick={this.handleOnClick}
        >
          Confirm
        </PrimaryButton>

        <SkipLink onClick={onClose} dataCy="cancel">
          Cancel
        </SkipLink>
      </div>
    )
  }

  handleOnClick = (): void => {
    const { updateStatus, onClose, product, grid } = this.props

    if (!this.state.selectedOption) {
      return
    }

    updateStatus(
      {
        product,
        // @ts-ignore
        newStatus: this.state.selectedOption,
      },
      grid,
    )
    onClose()
  }
}

const readyToBuyOption = {
  value: READY_TO_BUY,
  dataCy: 'readyToBuyOption',
  displayName: 'Hello',
  extendedClickArea: true,
  render: readyToBuyComponent,
}

const proposedOption = {
  value: PROPOSED,
  dataCy: 'proposeOption',
  extendedClickArea: true,
  render: proposedComponent,
}

const cancelOption = {
  value: CANCELLED,
  dataCy: 'cancelOption',
  render: cancelledComponent,
}

const gridBasedOption = (grid: string, status: string): Array<GridOption> => {
  if (grid === OPTION_LIBRARY && status === PROPOSED) return [readyToBuyOption]
  if (grid === DEV_TRACKER && status === DEVELOPMENT) return [proposedOption]
  return []
}
