import React from 'react'
import { changeStatus, form } from './ProductForm.scss'
import { HierarchyFormField } from 'src/components/HierarchyFormField'
import Dropdown from 'src/components/Dropdown/Dropdown'
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton'
import { TextFormField } from 'src/components/TextFormField'
import { AttributeValue, AttributeValues, Season } from 'src/types/index'

import { Supplier } from 'types/Supplier'
import { DropdownOption } from 'types/Dropdowns'
import { CreateProductFormFields, FormItem } from 'types/Product'

type Props = {
  fields: CreateProductFormFields
  items: AttributeValues
  onDropdownChange: (returnValue: AttributeValue | undefined, fieldName: string) => void
  onTextFieldChange: (text: string, fieldName: string) => void
  onTextFieldBlur: (controlName: string) => void
  submitForm: (event: React.FormEvent<HTMLFormElement>, seasonalityItems: AttributeValue[]) => void
  validateForm: () => void
  attributeNameMap: Record<string, string>
  seasons: Season[]
  suppliers: Supplier[]
}

export const NEW_SUPPLIER_FAKE_SLUG = 'SLUG - TBC'

const placeholderSupplier = {
  slug: NEW_SUPPLIER_FAKE_SLUG,
  code: 'ZZZZZ',
  value: 'NEW SUPPLIER - TBC',
  active: true,
}

const createOption = (item: DropdownOption): DropdownOption => ({
  value: item.slug,
  label: item.value as string,
  data: item,
})

const createSupplierOption = (item: Supplier): DropdownOption => ({
  value: item.slug,
  label: item.code ? `${item.value} - ${item.code}` : '',
  data: item,
})

const createSeasonOption = (item: Season): DropdownOption => ({
  value: item.code,
  label: item.code,
  data: item,
})

const isValid = (field: FormItem): boolean => (field ? field.validationErrors.length === 0 : false)

export const ProductForm = ({
  fields,
  items,
  seasons,
  suppliers,
  onDropdownChange,
  onTextFieldChange,
  onTextFieldBlur,
  validateForm,
  submitForm,
  attributeNameMap,
}: Props): JSX.Element => {
  const customControlStyles = {
    '&:hover': {
      border: '1px solid #26B3AD',
    },
  }

  const customMultiListControlStyles = {
    '&:hover': {
      border: '1px solid #26B3AD',
    },
    height: '5rem',
  }

  const customValueContainerStyles = {
    display: 'flex',
    flex: '1 1 0%',
    flexWrap: 'wrap',
    overflow: 'auto',
    padding: '2px',
    height: '100%',
    alignItems: 'center',
  }

  const customMultiValueStyles = {
    height: '40%',
    alignItems: 'center',
  }

  const customMultiValueRemoveStyles = {
    height: '100%',
  }

  return (
    <form
      id="NewProduct"
      onSubmit={(event): void => submitForm(event, items.seasonality)}
      className={form}
    >
      <HierarchyFormField
        name="hierarchy"
        required
        isValid={isValid(fields.hierarchy)}
        header="Select Hierarchy"
        validationText="Select valid hierarchy"
        value={fields.hierarchy.data}
        // @ts-ignore
        onChange={onDropdownChange}
      />
      <Dropdown
        name="styleGroup"
        header={attributeNameMap.styleGroup}
        required
        items={items.styleGroup as DropdownOption[]}
        createOption={createOption}
        selectedItem={fields.styleGroup.data as DropdownOption}
        isValid={isValid(fields.styleGroup)}
        hasFocus={fields.styleGroup.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="styleGroupDropdown"
      />
      <TextFormField
        header={attributeNameMap.productDescription}
        name="productDescription"
        hasFocus={fields.productDescription.hasFocus}
        required
        value={fields.productDescription.data}
        onChange={onTextFieldChange}
        onBlur={onTextFieldBlur}
        validationErrors={fields.productDescription.validationErrors}
        dataCy="productDescriptionTextField"
      />
      <TextFormField
        header={attributeNameMap.styleName}
        name="styleName"
        hasFocus={fields.styleName.hasFocus}
        required={false}
        value={fields.styleName.data}
        onChange={onTextFieldChange}
        onBlur={onTextFieldBlur}
        validationErrors={fields.styleName.validationErrors}
        dataCy="styleNameTextField"
      />
      <Dropdown
        name="colour"
        header={attributeNameMap.colour}
        required
        items={items.colour as DropdownOption[]}
        createOption={createOption}
        selectedItem={fields.colour.data as DropdownOption}
        isValid={isValid(fields.colour)}
        hasFocus={fields.colour.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="colourDropdown"
      />
      <Dropdown
        name="print"
        header={attributeNameMap.print}
        required
        items={items.print as DropdownOption[]}
        createOption={createOption}
        selectedItem={fields.print.data as DropdownOption}
        isValid={isValid(fields.print)}
        hasFocus={fields.print.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="printDropdown"
      />
      <Dropdown
        name="fabricType"
        header={attributeNameMap.fabricType}
        required
        items={items.fabricType as DropdownOption[]}
        createOption={createOption}
        selectedItem={fields.fabricType.data as DropdownOption}
        isValid={isValid(fields.fabricType)}
        hasFocus={fields.fabricType.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="fabricTypeDropdown"
      />
      <Dropdown
        name="sustainableReason"
        header={attributeNameMap.sustainableReason}
        required={false}
        isValid={true}
        items={(items.sustainableReason || []) as DropdownOption[]}
        createOption={createOption}
        selectedMultiItems={(fields.sustainableReason.data || []) as DropdownOption[]}
        hasFocus={fields.sustainableReason.hasFocus}
        // @ts-ignore
        onMultiItemsSelected={onDropdownChange}
        customControlStyles={customMultiListControlStyles}
        customValueContainerStyles={customValueContainerStyles}
        customMultiValueStyles={customMultiValueStyles}
        customMultiValueRemoveStyles={customMultiValueRemoveStyles}
        isMulti={true}
        dataCy="sustainableReason"
      />
      {fields.priceArchitecture !== undefined && (
        <Dropdown
          name="priceArchitecture"
          header={attributeNameMap.priceArchitecture}
          required
          items={items.priceArchitecture as DropdownOption[]}
          createOption={createOption}
          selectedItem={fields.priceArchitecture.data as DropdownOption}
          isValid={isValid(fields.priceArchitecture)}
          hasFocus={fields.priceArchitecture.hasFocus}
          onItemSelected={onDropdownChange}
          customControlStyles={customControlStyles}
          dataCy="priceArchitectureDropdown"
        />
      )}
      <Dropdown
        name="season"
        header="Season"
        required
        items={seasons as unknown as DropdownOption[]}
        // @ts-ignore
        createOption={createSeasonOption}
        selectedItem={fields.season.data as DropdownOption}
        isValid={isValid(fields.season)}
        hasFocus={fields.season.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="seasonDropdown"
      />
      <Dropdown
        name="supplier"
        header="Description - Supplier Code"
        required
        items={[...suppliers, placeholderSupplier] as unknown as DropdownOption[]}
        // @ts-ignore
        createOption={createSupplierOption}
        selectedItem={fields.supplier.data as DropdownOption}
        isValid={isValid(fields.supplier)}
        hasFocus={fields.supplier.hasFocus}
        onItemSelected={onDropdownChange}
        customControlStyles={customControlStyles}
        dataCy="supplierDropdown"
      />
      <PrimaryButton
        name="validate"
        className={changeStatus}
        onClick={validateForm}
        dataCy="createButton"
      >
        Create - development
      </PrimaryButton>
    </form>
  )
}
