import { connect } from 'react-redux'
import { SizeCellRenderer } from './SizeCellRenderer'
import { AppState } from 'src/reducers/root'
import { getGrid, isGridLocked } from 'src/selectors/grid'
import { openSizeModal } from 'src/actions/grid/modal/sizeModal'

const mapStateToProps = (state: AppState) => ({
  grid: getGrid(state),
  locked: isGridLocked(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  viewSizes: (hierarchySlug: string, productSlug: string) =>
    dispatch(openSizeModal(hierarchySlug, productSlug)),
})

export const SizeCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SizeCellRenderer)
