import React from 'react'
import { DefaultAttributesTableData } from 'types/UserSettings'
import { SimpleDropdown } from 'components/Dropdown/SimpleDropdown/SimpleDropdown'
import { DropdownOption } from 'types/Dropdowns'
import { MultiDropdown } from 'components/Dropdown/MultiDropdown/MultiDropdown'
import { AttributeValueMap } from 'types'
import { attributeToDropdownOption } from 'service/mappers/attributes'

interface Props {
  style: React.CSSProperties
  className: string
  selectedCell: DefaultAttributesTableData
  allAttributeValues: AttributeValueMap
  isCellSelected: boolean
  onSelectCell: () => void
  onDeselectCell: () => void
  updateListDefaultAttribute: (item: DropdownOption) => void
  updateMultiListDefaultAttribute: (items: DropdownOption[]) => void
}

const emptyAttribute = { value: '', label: '', data: { value: '', label: '' } }

const customControlStyles = {
  '&:hover': {
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
  },
  border: 'none',
  borderRadius: '0',
  height: '78px',
  marginBottom: '0',
  overflowY: 'scroll',
}

const selectedControlStyles = {
  ...customControlStyles,
  borderBottom: '3px solid #26B3AD',
}

export const EditableCell = ({
  className,
  style,
  selectedCell,
  allAttributeValues,
  isCellSelected,
  updateListDefaultAttribute,
  updateMultiListDefaultAttribute,
  onSelectCell,
  onDeselectCell,
}: Props): JSX.Element => {
  const controlStyles = isCellSelected ? selectedControlStyles : customControlStyles
  const options: DropdownOption[] =
    allAttributeValues[selectedCell.attributeName].map(attributeToDropdownOption)

  return (
    <div style={style} className={className} onClick={onSelectCell} onBlur={onDeselectCell}>
      {selectedCell.attributeType === 'list' ? (
        <SimpleDropdown
          hasFocus={true}
          openOnFocus={true}
          selectedOption={selectedCell.values?.[0]}
          name="attribute"
          onChange={updateListDefaultAttribute}
          options={[emptyAttribute, ...options]}
          customControlStyles={controlStyles}
        />
      ) : (
        <MultiDropdown
          hasFocus={true}
          mandatory={false}
          name="multiAttribute"
          options={options}
          onSave={updateMultiListDefaultAttribute}
          selectedOptions={selectedCell.values}
          customControlStyles={controlStyles}
        />
      )}
    </div>
  )
}
