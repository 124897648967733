import React from 'react'
import { WholesaleImage } from 'src/types/WholesaleImages'
import { ImagePlaceholder } from 'src/assets/icons/ImagePlaceholder'
import {
  wsImageContainer,
  wsImage,
  editButtonsContainer,
  editButtonsSeparator,
  editButtonIcon,
  replaceIconContainer,
  removeIconContainer,
  highlightWsImageContainer,
  wsImagePlaceholder,
  // wsImagePlaceholder,
} from './Image.scss'
import { ReloadIcon } from 'src/assets/icons/ReloadIcon'
import { TrashIcon } from 'src/assets/icons/TrashIcon'
import classnames from 'classnames'
interface ImageProps {
  wsImage?: WholesaleImage
  draggedImageSequence: string
  handleDrag: (ev: React.DragEvent<HTMLDivElement>) => void
  handleDrop: (ev: React.DragEvent<HTMLDivElement>) => void
  handleReplace: (wsImageSlug: string, wsImageSequence: number) => void
  handleRemove: (wsImageSlug: string) => void
}
export const Image = (props: ImageProps): JSX.Element => {
  const [highlightDropContainer, setHighlightDropContainer] = React.useState<boolean>(false)
  const handleReplace = (): void => {
    props.handleReplace(props.wsImage?.slug || '', props.wsImage?.sequence || 0)
  }

  const handleRemove = (): void => {
    props.handleRemove(props.wsImage?.slug || '')
  }

  const onDragEnter = (ev: React.DragEvent<HTMLDivElement>): void => {
    if (ev.currentTarget.getAttribute('data-sequence') !== props.draggedImageSequence) {
      setHighlightDropContainer(true)
    }
  }
  const onDragLeave = (): void => setHighlightDropContainer(false)

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>): void => {
    setHighlightDropContainer(false)
    props.handleDrop(ev)
  }

  return (
    <>
      {props.wsImage ? (
        <div
          className={classnames(wsImageContainer, {
            [highlightWsImageContainer]: highlightDropContainer,
          })}
        >
          <div
            data-sequence={props.wsImage.sequence}
            draggable={true}
            className={wsImage}
            style={{
              backgroundImage: `url(${props.wsImage.thumbnailURL})`,
            }}
            onDragStart={props.handleDrag}
            onDragEnter={onDragEnter}
            onDrop={handleDrop}
            onDragLeave={onDragLeave}
          />
          <div className={editButtonsContainer}>
            <div
              className={classnames(editButtonIcon, replaceIconContainer)}
              onClick={handleReplace}
            >
              <ReloadIcon />
            </div>
            <div className={editButtonsSeparator} />
            <div className={classnames(editButtonIcon, removeIconContainer)} onClick={handleRemove}>
              <TrashIcon />
            </div>
          </div>
        </div>
      ) : (
        <ImagePlaceholder className={wsImagePlaceholder} />
      )}
    </>
  )
}
