import { all, call, put, takeEvery } from 'redux-saga/effects'

import { clearMultiSelect } from 'src/actions/grid/multiSelect'
import { injectorService } from 'src/service/injector/injectorService'
import { showNotification } from 'src/actions/notification'
import { errorAndNotification } from '../error'
import { RECLASSIFICATION as reclassificationUrl } from 'src/constants/apiUrls'
import { RECLASSIFICATION } from '../../actions/grid/product/reclass'
import { trackEvent } from '../../service/analytics/analytics'
import { fetchFirstProductsPage } from 'actions/grid/product/fetch'
import { APIError } from 'types/Api'
import { SVPAction } from 'actions/svp'

export function* reclassificationSaga({
  hierarchySlug,
  slugsToReclassify,
  grid,
}: SVPAction<typeof RECLASSIFICATION>) {
  try {
    trackEvent('product', 'Products Reclassified', `${slugsToReclassify.length}`)
    yield call(injectorService.post, reclassificationUrl, {
      newHierarchySlug: hierarchySlug,
      slugs: slugsToReclassify,
    })

    yield put(
      showNotification({
        type: 'success',
        message: 'Development ideas have been successfully moved',
      }),
    )

    yield put(clearMultiSelect(grid))
    yield put(fetchFirstProductsPage(grid))
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      'Reclassification of development ideas failed',
    )
  }
}

export default function* () {
  yield all([takeEvery(RECLASSIFICATION, reclassificationSaga)])
}
