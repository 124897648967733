import React, { ReactPortal } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { GridType, Product } from 'src/types/index'
import { AppState } from 'src/reducers/root'
import { SidePanelTabs } from 'src/types/Tabs'
import { resetNavigationValue } from 'src/actions/grid/sidePanelNavigation'
import { getAllProducts } from 'selectors/product'
import { getSidePanelValue } from 'selectors/sidePanelNavigation'
import { SidePanelDrawer } from './SidePanelDrawer'

export type DrawerContainerProps = {
  rowIndex: number
  open: boolean
  openingTab: SidePanelTabs
  exitEditMode?: (exit: boolean) => void
  gridName: GridType
}

type MapStateType = { products: Product[]; sidePanelValue: number }

const mapStateToProps = (state: AppState): MapStateType => ({
  products: getAllProducts(state),
  sidePanelValue: getSidePanelValue(state),
})

const mapDispatchToProps = {
  resetNavigationValue,
}

export const SidePanelDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidePanelDrawer)

export default (props: DrawerContainerProps): ReactPortal => {
  const appRoot: HTMLElement = document.querySelector('body') as HTMLElement
  return ReactDOM.createPortal(<SidePanelDrawerContainer {...props} />, appRoot)
}
