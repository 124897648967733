import React from 'react'

import { SkipLink } from 'src/components/Link/index'
import { Modal } from 'src/components/Modal/Modal'
import PrimaryButton from 'src/components/PrimaryButton/index'
import { areYouSure, confirmButton, confirmationModal } from './ConfirmationModal.scss'

interface Props {
  text: string
  primaryButtonDisabled: boolean
  onConfirmClick: () => void
  onCloseModalClick: () => void
}

export default function ConfirmationModal(props: Props): JSX.Element {
  return (
    <div data-cy="ws-images-confirmation-modal">
      <Modal modalClass={confirmationModal} onCloseFn={props.onCloseModalClick}>
        <p className={areYouSure}>{props.text}</p>
        <PrimaryButton
          disabled={props.primaryButtonDisabled}
          dataCy="statusConfirm"
          className={confirmButton}
          onClick={props.onConfirmClick}
        >
          Confirm
        </PrimaryButton>
        <SkipLink onClick={props.onCloseModalClick}>Cancel</SkipLink>
      </Modal>
    </div>
  )
}
