import React, { Component } from 'react'

import AvailableSizesColumn from './AvailableSizesColumn/AvailableSizesColumn'
import ProductSizesTable from './ProductSizesTable/ProductSizesTable'
import { DEFAULT_GROUP, MINI_GROUP, MINI_EXT_GROUP, TEEN_GROUP } from 'src/constants/productSizes'
import { Size, SizeGroups } from '../../../types/Size'

import { gridWrapper } from './SizeInformation.scss'
import { PriceTypes } from 'src/types/Price'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { CurrencyKeys, DiscreteValue } from 'src/types/index'

type Props = {
  supplierCostCurrency: CurrencyKeys
  rangeSizes: Size[]
  sizeGroups: SizeGroups
  isProductReadyToBuy?: boolean
  sellingPriceOptions: DiscreteValue[]
  isHierarchyInBeautyAndBrandsDivision: boolean
  onDismissError: (errorID: string) => void
  addSize: (size: Size) => void
  updateSize: (size: Size) => void
  removeSize: (size: Size) => void
  onPriceChange: (group: ProductSizeGroups, priceKey: PriceTypes, priceValue: number) => void
  onCurrencyChange: (currency: CurrencyKeys) => void
}

export default class SizeInformation extends Component<Props> {
  render(): JSX.Element {
    const {
      isProductReadyToBuy,
      sizeGroups,
      supplierCostCurrency,
      sellingPriceOptions,
      isHierarchyInBeautyAndBrandsDivision,
      onPriceChange,
      onCurrencyChange,
    } = this.props
    return (
      <div className={gridWrapper}>
        <AvailableSizesColumn
          availableSizes={this.getAvailableSizes()}
          onSizeChipClicked={this.onAvailableSizeChipClicked}
        />
        <ProductSizesTable
          supplierCostCurrency={supplierCostCurrency}
          isProductReadyToBuy={isProductReadyToBuy}
          sizeGroups={sizeGroups}
          sellingPriceOptions={sellingPriceOptions}
          onSizeChipClicked={this.onProductSizesTableSizeChange}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
        />
      </div>
    )
  }

  getAvailableSizes = (): Size[] => {
    const { rangeSizes, sizeGroups } = this.props

    // Get all active sizes for all groups
    const allSelectedSizes = [
      ...sizeGroups[DEFAULT_GROUP].activeSizes,
      ...sizeGroups[MINI_GROUP].activeSizes,
      ...sizeGroups[MINI_EXT_GROUP].activeSizes,
      ...sizeGroups[TEEN_GROUP].activeSizes,
    ]

    // Return all the sizes that are not in the selected sizes array and aren't deprecated
    return rangeSizes
      .filter((size) => {
        return !allSelectedSizes.some((selectedSize) => {
          return selectedSize.name === size.name
        })
      })
      .filter((size) => {
        return !size.deprecated
      })
  }

  onAvailableSizeChipClicked = (size: Size): void => {
    const { addSize } = this.props

    addSize(size)
  }

  onProductSizesTableSizeChange = (size: Size): void | undefined => {
    const { isAllocated, eventErrorID, skuActive, sku } = size
    const { isProductReadyToBuy, updateSize, removeSize, onDismissError } = this.props

    if (!isProductReadyToBuy || !isAllocated || !sku) {
      // If a Product is not in Ready To Buy or it hasnt been allocated yet
      // or there is no sku against it
      // then remove from the size list And put it back in the Available column
      removeSize(size)
      return
    }

    if (eventErrorID) {
      onDismissError(eventErrorID)
      return
    }

    // Find the modified size, toggle the active state of the size and then store it back in the state
    updateSize({
      ...size,
      skuActive: !skuActive,
    })
  }
}
