import { GridType } from 'src/types/index'

export const UPDATE_MULTI_SELECT = 'UPDATE_MULTI_SELECT'
export const UPDATE_ALL_MULTI_SELECT = 'UPDATE_ALL_MULTI_SELECT'
export const UPDATE_BATCH_MULTI_SELECT = 'UPDATE_BATCH_MULTI_SELECT'
export const CLEAR_MULTI_SELECT = 'CLEAR_MULTI_SELECT'
export const SWITCH_MULTI_SELECT_MODE = 'SWITCH_MULTI_SELECT_MODE'

export type MultiSelectActions = ReturnType<
  | typeof updateMultiSelect
  | typeof updateAllMultiSelect
  | typeof updateBatchMultiSelect
  | typeof clearMultiSelect
  | typeof switchMultiSelectMode
>
export type UpdateMultiSelectPayload = {
  developmentId: string
  productSlug: string
  isSelected: boolean
}

export const updateMultiSelect = (payload: UpdateMultiSelectPayload, grid: GridType) =>
  <const>{
    type: UPDATE_MULTI_SELECT,
    payload,
    grid,
  }

export const updateAllMultiSelect = (checked: boolean, grid: GridType) =>
  <const>{
    type: UPDATE_ALL_MULTI_SELECT,
    checked,
    grid,
  }

export const updateBatchMultiSelect = (selectedIds: string[], grid: GridType) =>
  <const>{
    type: UPDATE_BATCH_MULTI_SELECT,
    selectedIds,
    grid,
  }

export const clearMultiSelect = (grid: GridType) =>
  <const>{
    type: CLEAR_MULTI_SELECT,
    grid,
  }

export const switchMultiSelectMode = (grid: GridType) =>
  <const>{
    type: SWITCH_MULTI_SELECT_MODE,
    grid,
  }
