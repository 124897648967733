import React from 'react'
import { GridType, IntentionalAny, RenderSpec } from 'src/types/index'
import Dropdown from '../../Dropdown'
import { ExactFilterSearchInput } from './ExactFilterSearchInput/ExactFilterSearchInput'
import { Filter } from '../../../actions/grid/exactFilters'
import { PartialFilterSearchInput } from './PartialFilterSearchInput/PartialFilterSearchInput'
import { PartialFilter } from '../../../actions/grid/partialFilters'
import { filterDropdown, filterWrapper, clearAll } from './SearchBar.scss'
import { GridColumnSpec } from '../../ProductsTable/ProductsTable'
import { Link } from '../../Link/Link'
import { DropdownOption } from '../../../types/Dropdowns'

type Props = {
  clearAllFilters: () => void
  columns: GridColumnSpec[]
  gridName: GridType
  fetchUniqueValues: (property: string, grid: GridType) => void
  uniqueValues: {
    [property: string]: DropdownOption[]
  }
  addExactFilter: (filter: Filter) => void
  addPartialFilter: (filter: PartialFilter) => void
  existingExactFilters: Filter[]
  existingPartialFilters: PartialFilter[]
  onColumnSelected: (spec: RenderSpec) => void
  selectedColumn?: RenderSpec
}

const createOption = (item: IntentionalAny): DropdownOption => ({
  value: item.property || '',
  label: item.displayName,
  data: item,
})

const onlyFilterable = (columns: GridColumnSpec[]): GridColumnSpec[] =>
  columns.filter(({ filterType }) => filterType !== 'none')

export const SearchBar = (props: Props): JSX.Element => {
  const {
    addExactFilter,
    addPartialFilter,
    clearAllFilters,
    uniqueValues,
    columns,
    gridName,
    existingExactFilters,
    existingPartialFilters,
    selectedColumn,
    onColumnSelected,
  } = props

  const filterableColumns = onlyFilterable(columns)

  const customControlStyles = {
    border: 'none',
    borderRadius: '1.2rem',
    backgroundColor: selectedColumn ? 'rgba(0,0,0)' : 'rgba(0,0,0, 0.3)',
    width: '13rem',
    margin: 0,
    color: 'white',
  }

  const customIndicatorsContainerStyles = {
    position: 'relative',
    left: '11rem',
    top: '-2.2rem',
  }

  const customDropdownIndicatorStyles = {
    color: '#E4E726',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    borderRadius: '50%',
    padding: '2px',
  }

  const customInputStyles = {
    color: 'white',
  }

  const customSingleValueStyles = {
    color: 'white',
  }

  const customMenuStyles = {
    width: '13rem',
    marginTop: '0.2rem',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
  }

  const customMenuListStyles = {
    borderRadius: '0.5rem',
    width: '12rem',
  }

  const customOptionStyles = {
    fontSize: '13px',
    borderBottom: '1px solid #EEE',
  }

  const customPlaceholderStyles = {
    color: 'white',
  }

  return (
    <div className={filterWrapper}>
      <div className={filterDropdown}>
        <Dropdown
          name="filterableAttributes"
          noSort
          // @ts-ignore as for filterableColumns never matched the required DropdownOption type
          items={filterableColumns}
          createOption={createOption}
          isValid
          // @ts-ignore as for selectedColumn never matched the required DropdownOption type
          selectedItem={selectedColumn}
          onItemSelected={(event): void => {
            event && onColumnSelected(event)
          }}
          placeholder="Add filter"
          // @ts-ignore
          customControlStyles={customControlStyles}
          dropdownIndicator
          customIndicatorsContainerStyles={customIndicatorsContainerStyles}
          customDropdownIndicatorStyles={customDropdownIndicatorStyles}
          customInputStyles={customInputStyles}
          customSingleValueStyles={customSingleValueStyles}
          customMenuStyles={customMenuStyles}
          customMenuListStyles={customMenuListStyles}
          customOptionStyles={customOptionStyles}
          customPlaceholderStyles={customPlaceholderStyles}
          dataCy="search-bar-column"
        />
      </div>
      {selectedColumn && selectedColumn.filterType === 'exact' && (
        <div className={filterDropdown}>
          <ExactFilterSearchInput
            column={selectedColumn}
            uniqueValues={uniqueValues}
            addExactFilter={addExactFilter}
            existingExactFilters={existingExactFilters}
          />
        </div>
      )}
      {selectedColumn && selectedColumn.filterType === 'partial' && (
        <div className={filterDropdown}>
          <PartialFilterSearchInput
            column={selectedColumn}
            addPartialFilter={addPartialFilter}
            gridName={gridName}
          />
        </div>
      )}
      {(existingPartialFilters.length > 0 || existingExactFilters.length > 0) && (
        <div className={clearAll}>
          <Link tabIndex={0} onClick={clearAllFilters} dataCy="search-bar-clear-all">
            Clear all
          </Link>
        </div>
      )}
    </div>
  )
}
