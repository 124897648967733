import { connect } from 'react-redux'
import { AppState } from 'src/reducers/root'
import { ImageCellRenderer } from './ImageCellRenderer'
import { getGrid } from 'src/selectors/grid'
import { GridType, Product } from 'types'

interface PassedProps {
  product: Product
  rowIndex: number
  sidePanelValue: number
  editMode?: boolean
  exitEditMode?: () => void
  gridName: GridType
}

interface StateProps {
  gridName: GridType
}

export interface ImageCellRendererProps extends StateProps, PassedProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  gridName: getGrid(state),
})

export const ImageCellRendererContainer = connect(mapStateToProps)(ImageCellRenderer)
