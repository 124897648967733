import React, { Component } from 'react'

import Column from '../../Column/Column'
import SizeChip from '../../SizeChip/SizeChip'
import Section from '../../Section/Section'
import { Size } from 'src/types/Size'

import { noSizes, availableSizesColumn, availableSizesTitle } from './AvailableSizesColumn.scss'

type Props = {
  availableSizes: Size[]
  onSizeChipClicked: (size: Size) => void
}

export default class AvailableSizesColumn extends Component<Props> {
  render() {
    const { availableSizes } = this.props
    return (
      <Column
        title={`${availableSizes.length} Sizes Available`}
        columnStyle={availableSizesColumn}
        titleStyle={availableSizesTitle}
        testId="available_sizes_column"
        showHeader
      >
        <Section isLastSection testId="available_sizes_column_section">
          {availableSizes.length ? (
            availableSizes.map(this.sizeItem)
          ) : (
            <span className={noSizes}>All added</span>
          )}
        </Section>
      </Column>
    )
  }

  sizeItem = (size: any) => {
    const { onSizeChipClicked } = this.props
    const { name } = size

    return <SizeChip key={name} size={size} addMode onSizeChipClicked={onSizeChipClicked} />
  }
}
