import { GET_COMMS_PDF_META_SUCCESS, GET_COMMS_PDF_SUCCESS } from 'actions/admin/commsScreen'
import { SVPAction } from 'actions/svp'
import { CommsDocumentFileMeta } from 'types/CommsDocument'

export type CommsScreenState = {
  file: File | null
  meta: CommsDocumentFileMeta | null
}

const defaultState: CommsScreenState = {
  file: null,
  meta: null,
}

export const commsScreen = (state: CommsScreenState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case GET_COMMS_PDF_SUCCESS:
      return {
        ...state,
        file: action.file,
        meta: action.meta,
      }
    case GET_COMMS_PDF_META_SUCCESS:
      return {
        ...state,
        meta: action.meta,
      }
    default:
      return state
  }
}
