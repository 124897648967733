import { GET_RANGE_DEFAULT_ATTRIBUTES_SUCCESS } from 'actions/grid/product/create'
import { SVPAction } from 'actions/svp'
import { RangeDefaultAttribute } from 'types/UserSettings'

export interface CreateProductState {
  defaultAttributes: RangeDefaultAttribute[]
}

const defaultCreateProductState: CreateProductState = {
  defaultAttributes: [],
}

export const createProduct = (
  state: CreateProductState = defaultCreateProductState,
  action: SVPAction,
): CreateProductState => {
  switch (action.type) {
    case GET_RANGE_DEFAULT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        defaultAttributes: action.attributes,
      }
    default:
      return state
  }
}
