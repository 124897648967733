import React, { Component } from 'react'
import classNames from 'classnames'

import { CloseCross, PlusIcon } from 'src/assets/icons'
import { DEFAULT_GROUP } from 'src/constants/productSizes'
import { Size } from 'src/types/Size'

import {
  sizeChipButton,
  sizeChip,
  sizeGroupLabel,
  sizeInfoContainer,
  sizeText,
  removeIcon,
  lockedStatus,
  disabledStatus,
  commitmentErrorStatus,
} from './SizeChip.scss'

type Props = {
  size: Size
  addMode?: boolean
  isProductReadyToBuy?: boolean
  onSizeChipClicked: (size: Size) => void
}

export default class SizeChip extends Component<Props> {
  render(): JSX.Element {
    const { size } = this.props

    const { name } = size

    return (
      <div className={this.getChipClassNames()} data-cy={`size_${name}`} onClick={this.onClick}>
        <div className={sizeInfoContainer}>
          {this.sizeGroupSpan()}
          <span className={sizeText}>{name}</span>
        </div>

        <button className={sizeChipButton} data-cy={`size_${name}_${this.getTestIdStatus()}`}>
          {this.buttonIcon()}
        </button>
      </div>
    )
  }

  onClick = (): void => {
    const { size, onSizeChipClicked } = this.props

    onSizeChipClicked(size)
  }

  buttonIcon = (): JSX.Element => {
    const { size, addMode, isProductReadyToBuy } = this.props

    if (addMode) {
      return <PlusIcon width={13} height={13} stroke="#26B3AD" />
    }

    if (!isProductReadyToBuy) {
      return <CloseCross className={removeIcon} />
    }

    if (!size.sku) {
      return <CloseCross className={removeIcon} />
    }

    if (!size.isAllocated) {
      return <CloseCross className={removeIcon} />
    }

    if (size.eventErrorID) {
      return <CloseCross className={removeIcon} />
    }

    if (!size.skuActive) {
      return <PlusIcon width={12} height={12} />
    }

    return <CloseCross className={removeIcon} />
  }

  sizeGroupSpan = (): JSX.Element => {
    const { size } = this.props
    const { group } = size

    if (!group || group === DEFAULT_GROUP) {
      return <></>
    }

    return <span className={sizeGroupLabel}>{group}</span>
  }

  getChipClassNames = (): string => {
    const { size, isProductReadyToBuy } = this.props

    if (!isProductReadyToBuy || !size.isAllocated) {
      return sizeChip
    }

    const { skuActive, eventErrorID } = size

    return classNames(
      sizeChip,
      size.sku
        ? {
            [lockedStatus]: skuActive,
            [disabledStatus]: !skuActive,
            [commitmentErrorStatus]: !!eventErrorID,
          }
        : {},
    )
  }

  getTestIdStatus = (): string => {
    const { size, isProductReadyToBuy } = this.props

    if (!isProductReadyToBuy || !size.isAllocated) {
      return 'added'
    }

    const { skuActive, eventErrorID } = size

    if (eventErrorID) {
      return 'error'
    }

    if (skuActive) {
      return 'active'
    }

    return 'disabled'
  }
}
