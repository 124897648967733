import React from 'react'
import { Link } from 'components/Link/Link'
import { Modal } from 'components/Modal/Modal'
import { StyleModalTabs } from 'constants/style'
import {
  activeTab,
  tabDescription,
  viewContainer,
  modalBody,
  styleModalContainer,
  tab,
  tabs,
  loadingContent,
} from './StyleModal.scss'
import { StyleModalHeader } from './StyleModalHeader/StyleModalHeader'
import { StyleModalEditForm } from './StyleModalEditForm/StyleModalEditForm'
import { StyleModalSearchView } from './StyleModalSearchView/StyleModalSearchView'
import classNames from 'classnames'
import { Option } from 'types/Style'
import { SearchStylePayload } from 'actions/grid/style'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal'
import { StyleModalProps } from './StyleModalContainer'

export const StyleModal = (props: StyleModalProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState<string>(StyleModalTabs.SEARCH_STYLE)
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false)
  const [selectedStyleSlug, setSelectedStyleSlug] = React.useState<string>('')
  const [toBeMovedDevIds, setToBeMovedDevIds] = React.useState<string>('')

  React.useEffect(() => {
    props.fetchStyle(props.product.styleSlug)
  }, [])

  const onConfirmStyleDescription = (newStyleDescription: string): void => {
    if (!newStyleDescription) {
      props.showErrorNotification('Style Description cannot be empty')
    } else {
      props.saveStyleDescription(props.product.styleSlug, newStyleDescription)
    }
  }

  const onSearchStyle = (payload: SearchStylePayload): void => {
    props.searchStyle(payload)
  }

  const getProductOptionFromStyle = (): Option | undefined =>
    props.style?.current?.options?.find((option) => option.slug === props.product.optionSlug)

  const onConfirmStyle = (styleSlug: string): void => {
    if (!styleSlug) {
      props.showErrorNotification('You must select a style')
    } else {
      const option = getProductOptionFromStyle()
      if (!option) {
        props.showErrorNotification('There are no options assigned to the current style')
      } else {
        if (option.products?.length > 1) {
          setSelectedStyleSlug(styleSlug)
          setToBeMovedDevIds(option.products.map((product) => product.developmentId).join(', '))
          setShowConfirmationModal(true)
        } else {
          props.confirmProductStyle(option.slug, option.products ?? [], styleSlug)
        }
      }
    }
  }

  const onConfirmStyleFromModal = (): void => {
    if (selectedStyleSlug) {
      const option = getProductOptionFromStyle()
      props.confirmProductStyle(option.slug, option.products ?? [], selectedStyleSlug)
    }
  }

  const onDismissConfirmationModal = (): void => setShowConfirmationModal(false)

  return (
    <Modal onCloseFn={props.onClose} modalClass={styleModalContainer}>
      {!props.product ? (
        <div className={loadingContent} data-cy="styleModalLoading">
          <CircularProgress size={30} color="primary" />
        </div>
      ) : (
        <>
          <StyleModalHeader
            onClose={props.onClose}
            product={props.product}
            hierarchy={props.hierarchy}
          />
          {!props.style?.current ? (
            <div className={loadingContent} data-cy="styleModalLoading">
              <CircularProgress size={30} color="primary" />
            </div>
          ) : (
            <>
              {' '}
              <div className={tabs}>
                <Link
                  className={classNames(tab, {
                    [activeTab]: selectedTab === StyleModalTabs.SEARCH_STYLE,
                  })}
                  onClick={(): void => setSelectedTab(StyleModalTabs.SEARCH_STYLE)}
                >
                  Move product to an existing Style
                </Link>

                <Link
                  className={classNames(tab, {
                    [activeTab]: selectedTab === StyleModalTabs.EDIT_STYLE_DESCRIPTION,
                  })}
                  onClick={(): void => setSelectedTab(StyleModalTabs.EDIT_STYLE_DESCRIPTION)}
                >
                  Amend Style Description
                </Link>
              </div>
              <div className={modalBody}>
                {selectedTab === StyleModalTabs.EDIT_STYLE_DESCRIPTION && (
                  <>
                    <div className={tabDescription}>
                      In case of error, amend the Style Description here. This will amend the Style
                      Description for all products in this Style.
                    </div>
                    <div className={viewContainer}>
                      <StyleModalEditForm
                        styleID={props.product.styleID}
                        styleDescription={props.product.styleDescription || ''}
                        onConfirm={onConfirmStyleDescription}
                      />
                    </div>
                  </>
                )}
                {selectedTab === StyleModalTabs.SEARCH_STYLE && (
                  <>
                    <div className={tabDescription}>
                      Search for an existing Style using the filters and prompts below. Select the
                      desired style from the results box and click confirm to assign this product to
                      the selected Style
                    </div>
                    <div className={viewContainer}>
                      <StyleModalSearchView
                        isConfirmStyleRequesting={props.isConfirmStyleRequesting}
                        isSearchStyleLoading={props.isSearchStyleLoading}
                        styleResults={props.styleResults}
                        onSearch={onSearchStyle}
                        onConfirmStyle={onConfirmStyle}
                        viewResults={props.viewResults}
                        setViewResults={props.setViewResults}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {showConfirmationModal && (
            <ConfirmationModal
              primaryButtonDisabled={props.isConfirmStyleRequesting}
              text={`This product sits in the same option as one or more other products. Please confirm that all products (${toBeMovedDevIds}) should move to the selected Style and remain as one option.`}
              onCloseModalClick={onDismissConfirmationModal}
              onConfirmClick={onConfirmStyleFromModal}
            />
          )}
        </>
      )}
    </Modal>
  )
}
