export const SUPPLIER_COST = 'supplierCost'
export const LANDED_COST = 'landedCost'
export const SELLING_PRICE = 'sellingPrice'
export const CURRENT_FULL_PRICE = 'currentFullPrice'
export const PROMO_PRICE = 'promoPrice'

export const SUPPLIER_COST_VIEW = 'supplierCostView'
export const LANDED_COST_VIEW = 'landedCostView'
export const SELLING_PRICE_VIEW = 'sellingPriceView'
export const CURRENT_FULL_PRICE_VIEW = 'currentFullPriceView'
export const PROMO_PRICE_VIEW = 'promoPriceView'

export const SUPPLIER_COST_TITLE = 'Supplier Cost'
export const LANDED_COST_TITLE = 'Landed Cost'
export const SELLING_PRICE_TITLE = 'Selling Price'
export const CURRENT_FULL_PRICE_TITLE = 'Current Full Price'
export const PROMO_PRICE_TITLE = 'Promo Price'
export const PRICE_MULTIPLIER = 1000

export const AVAILABLE_PRICES = [
  SUPPLIER_COST_VIEW,
  LANDED_COST_VIEW,
  SELLING_PRICE_VIEW,
  CURRENT_FULL_PRICE_VIEW,
  PROMO_PRICE_VIEW,
]

export const MARKDOWN_ONLY = 'mo'
export const INCLUDED_MARKDOWN = 'im'
export const EXCLUDED_MARKDOWN = 'em'

export const PRICE_STATUS = 'priceStatus'
export const PRICE_STATUS_LABEL = 'Price Status'

export const priceStatusLabel = {
  [MARKDOWN_ONLY]: 'Markdown only',
  [INCLUDED_MARKDOWN]: 'Include Markdown',
  [EXCLUDED_MARKDOWN]: 'Exclude Markdown',
}
