import { GridType } from 'src/types/index'
import { DropdownOption } from 'src/types/Dropdowns'

export const ADD_EXACT_FILTER = 'ADD_EXACT_FILTER'
export const ADD_EXACT_FILTER_SUCCESS = 'ADD_EXACT_FILTER_SUCCESS'
export const REMOVE_EXACT_FILTER = 'REMOVE_EXACT_FILTER'
export const REMOVE_EXACT_FILTER_SUCCESS = 'REMOVE_EXACT_FILTER_SUCCESS'
export const FETCH_DROPDOWN_ITEMS = 'FETCH_DROPDOWN_ITEMS'
export const FETCH_DROPDOWN_ITEMS_SUCCESS = 'FETCH_DROPDOWN_ITEMS_SUCCESS'

export type ExactFiltersActions = ReturnType<
  | typeof addExactFilter
  | typeof addExactFilterSuccess
  | typeof removeExactFilter
  | typeof removeExactFilterSuccess
  | typeof fetchDropdownItems
  | typeof fetchDropdownItemsSuccess
>

export type Filter = {
  value: string
  label: string
  property: string
  type: string
  deprecated: boolean
}

export const addExactFilter = (filter: Filter, grid: GridType) =>
  <const>{
    type: ADD_EXACT_FILTER,
    filter,
    grid,
  }

export const addExactFilterSuccess = (filter: Filter, grid: GridType) =>
  <const>{
    type: ADD_EXACT_FILTER_SUCCESS,
    filter,
    grid,
  }

export const removeExactFilter = (filter: Filter, grid: GridType) =>
  <const>{
    type: REMOVE_EXACT_FILTER,
    filter,
    grid,
  }

export const removeExactFilterSuccess = (filter: Filter, grid: GridType) =>
  <const>{
    type: REMOVE_EXACT_FILTER_SUCCESS,
    filter,
    grid,
  }

export const fetchDropdownItems = (columnName: string, grid: GridType) =>
  <const>{
    type: FETCH_DROPDOWN_ITEMS,
    columnName,
    grid,
  }

export const fetchDropdownItemsSuccess = (
  items: DropdownOption[],
  columnName: string,
  grid: GridType,
) =>
  <const>{
    type: FETCH_DROPDOWN_ITEMS_SUCCESS,
    columnName,
    grid,
    items,
  }
