import { UploadSpinner } from 'components/UploadSpinner'
import React from 'react'

interface Props {
  className: string
  parsing?: boolean
}

export const Camera = ({ className, parsing }: Props): JSX.Element =>
  parsing ? (
    <UploadSpinner />
  ) : (
    <svg
      data-cy="camera-img"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 182.52 140.64"
    >
      <title>camera</title>
      <rect x="4.11" y="24.54" width="175.5" height="112.5" rx="11.25" ry="11.25" fill="none" />
      <polygon
        points="60.86 4.29 124.86 4.29 134.25 24.81 50.36 24.79 60.86 4.29"
        fill="none"
        stroke="#929292"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M168.36,25.29a10.51,10.51,0,0,1,10.5,10.5v90a10.51,10.51,0,0,1-10.5,10.5h-153a10.51,10.51,0,0,1-10.5-10.5v-90a10.51,10.51,0,0,1,10.5-10.5h153m0-1.5h-153a12,12,0,0,0-12,12v90a12,12,0,0,0,12,12h153a12,12,0,0,0,12-12v-90a12,12,0,0,0-12-12Z"
        fill="#929292"
      />
      <circle
        cx="92.36"
        cy="82.79"
        r="40.5"
        fill="none"
        stroke="#929292"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <line
        x1="92.86"
        y1="64.29"
        x2="92.86"
        y2="102.29"
        fill="none"
        stroke="#929292"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <line
        x1="73.86"
        y1="83.29"
        x2="111.86"
        y2="83.29"
        fill="none"
        stroke="#929292"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <circle
        cx="161.36"
        cy="41.79"
        r="8"
        fill="none"
        stroke="#929292"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <rect
        x="12.36"
        y="32.79"
        width="36"
        height="13"
        rx="6.5"
        ry="6.5"
        fill="none"
        stroke="#929292"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  )
