import { SVPAction } from 'actions/svp'
import {
  ADD_DEFAULT_ATTRIBUTE_TO_TABLE,
  GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_SUCCESS,
  SET_CLIPBOARD_ATTRIBUTE_SOURCE,
  UPDATE_DEFAULT_ATTRIBUTE_VALUES_SUCCESS,
} from 'actions/userSettings/defaultAttributes'
import { AttributeWithColumnNameMap } from 'types'
import { DefaultAttributesColumns, SelectedDefaultAttribute } from 'types/UserSettings'

export interface UserSettingsDefaultAttributesState {
  isFetchingAttributes: boolean
  ranges: Record<string, string>
  defaultAttributes: SelectedDefaultAttribute[]
  columns: DefaultAttributesColumns[]
  departmentAttributes: AttributeWithColumnNameMap
  copiedAttribute?: SelectedDefaultAttribute
}

const defaultUserSettingsSizesState: UserSettingsDefaultAttributesState = {
  isFetchingAttributes: false,
  columns: [],
  defaultAttributes: [],
  ranges: {},
  departmentAttributes: {},
}

function updateDefaultAttributeValues(
  stateDefaultAttributes: SelectedDefaultAttribute[],
  valueSlugs: string[],
  attributeName: string,
  attributeSlug: string,
  rangeSlug: string,
): SelectedDefaultAttribute[] {
  const newSelectedAttributes: SelectedDefaultAttribute[] = []
  let foundAttribute = false
  stateDefaultAttributes.forEach((defaultAttribute) => {
    if (
      defaultAttribute.attributeSlug === attributeSlug &&
      defaultAttribute.rangeSlug === rangeSlug
    ) {
      foundAttribute = true
      newSelectedAttributes.push({
        ...defaultAttribute,
        defaultValues: valueSlugs,
      })
    } else {
      newSelectedAttributes.push(defaultAttribute)
    }
  })

  if (!foundAttribute) {
    newSelectedAttributes.push({
      attributeName,
      attributeSlug,
      rangeSlug,
      defaultValues: valueSlugs,
    })
  }

  return newSelectedAttributes
}

export const userSettingsDefaultAttributes = (
  state: UserSettingsDefaultAttributesState = defaultUserSettingsSizesState,
  action: SVPAction,
): UserSettingsDefaultAttributesState => {
  switch (action.type) {
    case GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        columns: action.columns,
        ranges: action.ranges,
        defaultAttributes: action.defaultAttributes,
        departmentAttributes: action.departmentAttributes,
        isFetchingAttributes: false,
      }
    case ADD_DEFAULT_ATTRIBUTE_TO_TABLE:
      return {
        ...state,
        columns: [...state.columns, action.column],
      }
    case UPDATE_DEFAULT_ATTRIBUTE_VALUES_SUCCESS:
      return {
        ...state,
        defaultAttributes: updateDefaultAttributeValues(
          state.defaultAttributes,
          action.valueSlugs,
          action.attributeName,
          action.attributeSlug,
          action.rangeSlug,
        ),
      }
    case SET_CLIPBOARD_ATTRIBUTE_SOURCE:
      return {
        ...state,
        copiedAttribute: action.source,
      }
    default:
      return state
  }
}
