import React from 'react'
import classNames from 'classnames'
import {
  colourLinkIcon,
  supplierLinkIcon,
  matchingProductLinkIcon,
  crossDepartmentLinkIcon,
} from './icons.scss'
import { LinkType } from 'src/types/Links'
import {
  COLOUR_LINK,
  CROSS_DEPARTMENT_LINK,
  MATCHING_PRODUCT_LINK,
  SUPPLIER_LINK,
} from 'src/constants/links'

interface Props {
  linkType?: LinkType
  className?: string
  title: string
}

export const LinkIndicator = ({ className, linkType, title }: Props) => (
  <svg
    className={classNames(className, {
      [colourLinkIcon]: linkType === COLOUR_LINK,
      [supplierLinkIcon]: linkType === SUPPLIER_LINK,
      [matchingProductLinkIcon]: linkType === MATCHING_PRODUCT_LINK,
      [crossDepartmentLinkIcon]: linkType === CROSS_DEPARTMENT_LINK,
    })}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70.87 70.87"
  >
    <title>{title}</title>
    <path d="M35.41.41A35.07,35.07,0,1,1,.32,35.47,35.08,35.08,0,0,1,35.41.41ZM49.1,31.94a2.39,2.39,0,1,0,3.39,3.38l1.69-1.69A12,12,0,0,0,37.26,16.72l-7.57,7.56a12,12,0,0,0,0,16.91,2.39,2.39,0,1,0,3.38-3.38,7.18,7.18,0,0,1,0-10.15l7.56-7.56A7.18,7.18,0,0,1,50.8,30.24ZM21.72,39a2.39,2.39,0,1,0-3.38-3.38l-1.69,1.69A12,12,0,0,0,33.57,54.23l7.56-7.56a12,12,0,0,0,0-16.92,2.39,2.39,0,1,0-3.38,3.39,7.19,7.19,0,0,1,0,10.14l-7.57,7.57A7.18,7.18,0,0,1,20,40.7Z" />
  </svg>
)
