/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

export type SelectedCell = {
  row: number
  column: number
}

export type RITableContext = {
  selected: SelectedCell
  updatingSelected: boolean
  focusInGrid: boolean
  focusAllowedInGrid: boolean
  allowFocusInGrid: (focus: boolean) => void
  selectCell: (cell: SelectedCell) => void
  resetSelection: () => void
  leaveGrid: () => void
}

export const KeyboardNavigationContext = React.createContext({
  selected: {
    row: 0,
    column: 0,
  },
  updatingSelected: false,
  focusAllowedInGrid: true,
  allowFocusInGrid: (focus: boolean) => {},
  focusInGrid: false,
  resetSelection: () => {},
  selectCell: (cell: SelectedCell) => {},
  leaveGrid: () => {},
})
