import { AppState } from '../reducers/root'
import { DepartmentSpecs, GridViews, Rules } from 'src/types/index'
import { getGrid, getGridSlice } from './grid'
import { NO_SELECTED_DEPARTMENT } from '../reducers/common/grid/departmentSpecs'
import { getViewsReferenceData } from './referenceData/views'
import { getColumnRenderSpecs } from './referenceData/columns'

const getDefaultDepartmentSpecs = (state: AppState): DepartmentSpecs => ({
  [NO_SELECTED_DEPARTMENT]: {
    allProperties: [],
    views: getViewsReferenceData(state),
    rules: {} as Rules,
  },
})

export const getDepartmentSpecs = (state: AppState): DepartmentSpecs =>
  getGridSlice(state).departmentSpecs.specs

export const getDepartmentSpecsForGrid = (state: AppState): DepartmentSpecs =>
  Object.keys(getDepartmentSpecs(state)).length > 0
    ? getDepartmentSpecs(state)
    : getDefaultDepartmentSpecs(state)

const sortData = (columns: Array<string>, allProperties: Array<string>) => {
  return allProperties.filter((property) => columns.includes(property))
}

const getViewSetsForAllDepartments = (state: AppState): Array<GridViews> => {
  const departmentSpecs = getDepartmentSpecsForGrid(state)
  const grid = getGrid(state)

  return Object.keys(departmentSpecs).map(
    // TODO https://trello.com/c/tXWc5U4A [getViewSetsForAllDepartments has weird null/undefined check]
    (slug) => (departmentSpecs[slug] ? departmentSpecs[slug].views[grid] : {}),
  )
}

const buildView =
  (viewName: string, allColumnOrder: Array<string>) => (acc: GridViews, viewSet: GridViews) => ({
    [viewName]: {
      slug: viewName,
      displayName: viewSet[viewName].displayName,
      columns: sortData(
        [...new Set([...(acc[viewName] || { columns: [] }).columns, ...viewSet[viewName].columns])],
        allColumnOrder,
      ),
    },
  })

export const getViews = (state: AppState): GridViews => {
  const columnOrder = getColumnRenderSpecs(state).map(({ property }) => property)
  const viewSets = getViewSetsForAllDepartments(state)
  const viewNames = Object.keys(viewSets[0])

  return viewNames.reduce(
    (acc, viewName) => ({
      ...acc,
      ...viewSets.reduce(buildView(viewName, columnOrder), {}),
    }),
    {},
  )
}

export const departmentSpecsHaveError = (state: AppState): boolean =>
  getGridSlice(state).departmentSpecs.status === 'error'

export const isDepartmentSpecsLoading = (state: AppState): boolean =>
  getGridSlice(state).departmentSpecs.status === 'fetching'
