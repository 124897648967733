import { connect } from 'react-redux'
import { FreeTextFilter } from './FreeTextFilter'
import {
  getCurrentPartialFilterForProperty,
  getSavedPartialFiltersForProperty,
} from 'src/selectors/product'
import { GridType } from 'src/types/index'
import { AppState } from 'src/reducers/root'
import {
  addPartialFilter,
  PartialFilter,
  removePartialFilter,
} from '../../../../../actions/grid/partialFilters'

type OwnProps = {
  gridName: GridType
  property: string
  onAdd: () => void
}

const mapStateToProps = (state: AppState, { property }: OwnProps) => ({
  filters: getSavedPartialFiltersForProperty(state, property),
  currentFilter: getCurrentPartialFilterForProperty(state, property),
})

const mapDispatchToProps = (dispatch: any, { gridName, onAdd }: OwnProps) => ({
  addPartialFilter: (filter: PartialFilter) => {
    onAdd()
    dispatch(addPartialFilter(filter, gridName))
  },
  removePartialFilter: (filter: PartialFilter) => dispatch(removePartialFilter(filter, gridName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FreeTextFilter)
