import React from 'react'
import { ENTER_KEY, ESC_KEY } from 'src/constants/keyCodes'
import {
  copyIcon,
  copyIconContainer,
  pasteCellContainer,
  RITableTd,
  RITableTdInner,
  suggestion,
  validation,
} from './TableCell.scss'
import classNames from 'classnames'
import {
  KeyboardNavigationContext,
  SelectedCell,
} from 'src/components/KeyboardNavigation/KeyboardNavigationContext'
import {
  DEVELOPMENT_ID_CELL,
  HIERARCHY_SLUG_CELL,
  SUPPLIER_CELL,
} from '../../../constants/attributes'
import { ClipboardGridDestination, ClipboardGridSource } from 'types/Clipboard'
import { CopyIcon } from 'assets/icons/CopyIcon'
import { UploadSpinner } from 'components/UploadSpinner'

type Props = {
  columnIndex: number
  rowIndex: number
  style: React.CSSProperties
  children: React.ReactNode
  property: string
  displayName: string
  isReadOnly: boolean
  hasProperty: boolean
  cancelled: boolean
  isValid: boolean
  isDeprecated: boolean
  isLocked: boolean
  hasProductError: boolean
  shouldShowIsEmptyWarning: boolean
  setClipboardSource: () => void
  clipboardPasteProduct: () => void
  validationMessage?: string
  clipboardGridSource: ClipboardGridSource
  productSlug: string
  clipboardGridDestination: ClipboardGridDestination
}

type RITableTdWithContextProps = Props & {
  selected: { row: number; column: number }
  focusInGrid: boolean
  selectCell: (cell: SelectedCell) => void
}

const isCurrentCell = (
  rowIndex: number,
  selectedRow: number,
  columnIndex: number,
  selectedColumn: number,
) => rowIndex === selectedRow && columnIndex === selectedColumn

const createValidationMessage = (
  property: string,
  displayName: string,
  isDeprecated: boolean,
): string => {
  switch (property) {
    case HIERARCHY_SLUG_CELL:
      return 'Please assign to the lowest level - range'
    case SUPPLIER_CELL:
      return 'The supplier is no longer available, please amend'
    default:
      return isDeprecated ? 'Please select valid value(s)' : `${displayName} is mandatory`
  }
}

const getTdInnerClass = (editMode: boolean): string =>
  `${RITableTdInner} cellInnerEditing-${editMode.toString()}`

export const TableCell = (props: RITableTdWithContextProps): JSX.Element => {
  const {
    children,
    property,
    style,
    isValid,
    isDeprecated,
    displayName,
    shouldShowIsEmptyWarning,
    rowIndex,
    selected,
    columnIndex,
    focusInGrid,
    isReadOnly,
    cancelled,
    hasProductError,
    hasProperty,
    isLocked,
    clipboardGridSource,
    productSlug,
    clipboardGridDestination,
    selectCell,
    setClipboardSource,
    clipboardPasteProduct,
  } = props

  const [isActive, setIsActive] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)

  const node = React.useRef(null)

  React.useEffect((): void => {
    setIsActive(isCurrentCell(rowIndex, selected.row, columnIndex, selected.column) && focusInGrid)
    setIsEditMode(isCurrentCell(rowIndex, selected.row, columnIndex, selected.column) && isEditMode)
  }, [rowIndex, selected.row, columnIndex, selected.column, focusInGrid])

  React.useEffect((): void => {
    if (isActive && !isEditMode) {
      node?.current?.focus()
    }
  }, [isActive, isEditMode])

  const tdCssClass = (): string => {
    return classNames('rt-td', RITableTd, property, {
      active: isActive,
      readOnly: isReadOnly || property === DEVELOPMENT_ID_CELL,
      cancelled: cancelled,
      hasError: !isValid,
      hasProductError: hasProductError,
      emptyCell: !hasProperty,
      locked: isLocked,
    })
  }

  const childComponents = () => {
    if (React.isValidElement(children)) {
      return React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement, {
          editMode: isEditMode,
          isActive,
          exitEditMode: exitEditMode,
          enterEditMode: enterEditMode,
          locked: isReadOnly,
          hasProductError: hasProductError,
        }),
      )
    }

    return children
  }

  const isEditable = (): boolean => !isReadOnly

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case ENTER_KEY:
        if (event.target === node.current) {
          event.preventDefault()
          if (!isEditMode && isEditable()) {
            setIsEditMode(true)
            setIsActive(true)
          }
        }
        break
      case ESC_KEY:
        if (isEditMode) {
          exitEditMode(true)
        }
        break
    }
  }

  const exitEditMode = (focus: boolean): void => {
    setIsActive(true)
    setIsEditMode(false)

    if (focus) {
      requestAnimationFrame(() => {
        node?.current?.focus()
      })
    }
  }

  const onClick = (): void => {
    if (!isEditMode) {
      selectCell({ row: rowIndex, column: columnIndex })
    }
  }

  const enterEditMode = (): void => {
    if (!isEditMode && isEditable()) {
      setIsEditMode(true)
      setIsActive(true)
    }
  }

  const onCopy = (): void => !isEditMode && setClipboardSource()

  const onPaste = (): void => !isEditMode && clipboardPasteProduct()

  const isCellCopied = (): boolean =>
    clipboardGridSource?.product?.slug === productSlug && clipboardGridSource?.property === property

  const isCellPasted = (): boolean =>
    clipboardGridDestination?.productSlug === productSlug &&
    clipboardGridDestination?.property === property

  return (
    <div
      style={style}
      tabIndex={0}
      ref={node}
      onCopy={onCopy}
      onPaste={onPaste}
      onClick={onClick}
      onDoubleClick={enterEditMode}
      onKeyDown={onKeyDown}
      className={tdCssClass()}
    >
      {isCellCopied() && (
        <div className={copyIconContainer}>
          <CopyIcon className={copyIcon} />
        </div>
      )}
      {isCellPasted() && (
        <div className={pasteCellContainer}>
          <UploadSpinner />
        </div>
      )}
      <div className={getTdInnerClass(isEditMode)}>{childComponents()}</div>
      {!isValid && !isEditMode && (
        <div className={validation}>
          {createValidationMessage(property, displayName, isDeprecated) || ''}
        </div>
      )}
      {shouldShowIsEmptyWarning && !isEditMode && <div className={suggestion} />}
    </div>
  )
}

export default function TableCellWrapper(props: Props) {
  return (
    <KeyboardNavigationContext.Consumer>
      {({ selected, selectCell, focusInGrid }) => (
        <TableCell
          {...props}
          selected={selected}
          selectCell={selectCell}
          focusInGrid={focusInGrid}
        />
      )}
    </KeyboardNavigationContext.Consumer>
  )
}
