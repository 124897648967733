import React from 'react'
import { active, link, linksList, linksListItem } from '../GridControls.scss'
import Link from '../../Link'
import {
  EXCLUDED_MARKDOWN,
  INCLUDED_MARKDOWN,
  MARKDOWN_ONLY,
  priceStatusLabel,
  PRICE_STATUS,
  PRICE_STATUS_LABEL,
} from 'constants/price'
import { Filter } from 'actions/grid/exactFilters'
import { PriceStatus } from 'types/Price'

type Props = {
  addExactFilter: (filter: Filter) => void
}

export const PriceFilterButtons = ({ addExactFilter }: Props): JSX.Element => {
  const [selectedFilter, setSelectedFilter] = React.useState<PriceStatus>(EXCLUDED_MARKDOWN)

  const onClickAddExactFilter = (filterType: PriceStatus): void => {
    setSelectedFilter(filterType)
    addExactFilter({
      label: priceStatusLabel[filterType],
      property: PRICE_STATUS,
      type: PRICE_STATUS_LABEL,
      value: filterType,
      deprecated: false,
    })
  }
  return (
    <ul className={linksList}>
      <li
        key={EXCLUDED_MARKDOWN}
        className={`${linksListItem} ${selectedFilter === EXCLUDED_MARKDOWN ? active : ''}`}
      >
        <Link className={link} onClick={(): void => onClickAddExactFilter(EXCLUDED_MARKDOWN)}>
          {priceStatusLabel[EXCLUDED_MARKDOWN]}
        </Link>
      </li>
      <li
        key={INCLUDED_MARKDOWN}
        className={`${linksListItem} ${selectedFilter === INCLUDED_MARKDOWN ? active : ''}`}
      >
        <Link className={link} onClick={(): void => onClickAddExactFilter(INCLUDED_MARKDOWN)}>
          {priceStatusLabel[INCLUDED_MARKDOWN]}
        </Link>
      </li>
      <li
        key={MARKDOWN_ONLY}
        className={`${linksListItem} ${selectedFilter === MARKDOWN_ONLY ? active : ''}`}
      >
        <Link className={link} onClick={(): void => onClickAddExactFilter(MARKDOWN_ONLY)}>
          {priceStatusLabel[MARKDOWN_ONLY]}
        </Link>
      </li>
    </ul>
  )
}
