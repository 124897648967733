import { connect } from 'react-redux'
import { ActionsHeader } from './ActionsHeader'
import { switchMultiSelectMode } from 'src/actions/grid/multiSelect'
import { getMultiSelectMode } from 'src/selectors/multiSelect'
import { isGridLocked } from 'src/selectors/grid'
import { AppState } from 'src/reducers/root'
import { GridType } from 'src/types/index'

const mapStateToProps = (state: AppState) => ({
  multiSelect: getMultiSelectMode(state),
  locked: isGridLocked(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  updateMultiSelectMode: (grid: GridType) => dispatch(switchMultiSelectMode(grid)),
})

const ActionsHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(ActionsHeader)

export default ActionsHeaderContainer
