export const SKETCH_URL_COLUMN = 'sketchUrl'
export const SAMPLE_URL_COLUMN = 'sampleUrl'
export const THUMBNAIL_URL_COLUMN = 'thumbnailUrl'

export const ATTRIBUTE_COLUMN_CONTENT = 'attribute'
export const PROPERTY_COLUMN_CONTENT = 'property'

export const EXPORT_DEV_ID_COLUMN_NAME = 'Dev ID'
export const EXPORT_EVENT_COLUMN_NAME = 'Event'
export const STYLE_ID_COLUMN_NAME = 'Style ID'

export const HP_HANDOVER_WK = 'hpHandoverWk'
export const HP_HANDOVER_TYPE = 'hpHandoverType'
