import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  card,
  cardContent,
  cardGraphic,
  pageTitle,
  grid,
  gridItem,
  homePage,
  optLibLogo,
  devTrackerLogo,
  wrapper,
  createButton,
  createButtonWrapper,
  devTrackerCard,
  optionLibraryCard,
  cardDescription,
} from './HomePage.scss'

import { DevTrackerLogo, OptionLibraryLogo } from '../../assets/icons'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import BackgroundImage from './BackgroundImage'
import { ssoLogin } from 'service/auth'
import { RISpinner } from 'components/RISpinner/RISpinner'
import { overlay } from 'components/NoAccess/NoAccess.scss'
import { CommsDocumentModal } from './CommsDocumentModal/CommsDocumentModal'
import { getCookie, setCookie } from 'service/clientCookie/clientCookie'
import { COMMS_MARK_AS_READ_COOKIE_NAME } from 'constants/commsScreen'
import { CommsDocumentFileMeta } from 'types/CommsDocument'

type State = {
  isLoading: boolean
  isCommsModalOpen: boolean
}

type Props = {
  isLoggedIn: boolean
  locked: boolean
  commsFile: File | null
  commsFileMeta: CommsDocumentFileMeta | null
  getCommsPdf: () => void
}

export class Home extends React.Component<Props, State> {
  state = {
    isLoading: false,
    isCommsModalOpen: false,
  }

  componentDidMount(): void {
    if (localStorage.getItem('ssoConnection') === 'active') {
      localStorage.removeItem('ssoConnection')
      this.setState({ isLoading: true })
      ssoLogin(`${window.location.origin}/auth`)
    } else {
      this.props.getCommsPdf()
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      !prevProps.commsFile &&
      this.props.commsFile &&
      this.props.commsFileMeta &&
      !this.state.isCommsModalOpen
    ) {
      if (
        (this.props.commsFileMeta?.lastModified || 0) >
        parseInt(getCookie(COMMS_MARK_AS_READ_COOKIE_NAME) || '0', 10)
      ) {
        this.setState({ isCommsModalOpen: true })
      }
    }
  }

  closeCommsDocumentModal = (): void => {
    this.setState({ isCommsModalOpen: false })
  }

  markAsReadHandler = (): void => {
    setCookie(COMMS_MARK_AS_READ_COOKIE_NAME, Date.now().toString(), { expires: 28 })
    this.closeCommsDocumentModal()
  }
  render(): JSX.Element {
    const { isLoggedIn, locked } = this.props
    return (
      <>
        {this.state.isLoading ? (
          <div className={overlay}>
            <RISpinner colour="white" />
          </div>
        ) : (
          <>
            <article className={homePage}>
              <h1 className={pageTitle}>Home</h1>
              <div className={grid}>
                {isLoggedIn ? (
                  <>
                    {this.state.isCommsModalOpen && this.props.commsFile && (
                      <CommsDocumentModal
                        file={this.props.commsFile}
                        onCloseCommsDocumentModal={this.closeCommsDocumentModal}
                        onMarkAsRead={this.markAsReadHandler}
                      />
                    )}
                    <Link to="/dev-tracker" className={`${card} ${devTrackerCard} ${gridItem}`}>
                      <div className={cardContent}>
                        <h2 className="cardTitle">Dev tracker</h2>
                        <p>
                          Creating ideas for products with the required basic attributes and getting
                          ready to propose them.
                        </p>
                      </div>
                      <div className={cardGraphic}>
                        <DevTrackerLogo className={devTrackerLogo} />
                      </div>
                    </Link>
                    <Link
                      to="/option-library"
                      className={`${card} ${optionLibraryCard} ${gridItem}`}
                    >
                      <div className={cardContent}>
                        <h2 className="cardTitle">Option library</h2>
                        <p>
                          Adding additional attributes & generating Product numbers when Ready to
                          Buy.
                        </p>
                      </div>
                      <div className={cardGraphic}>
                        <OptionLibraryLogo className={optLibLogo} />
                      </div>
                    </Link>
                  </>
                ) : (
                  <>
                    <PrimaryButton
                      className={`${card} ${devTrackerCard} ${gridItem}`}
                      onClick={this.logIntoDevTracker}
                    >
                      <div className={wrapper}>
                        <div className={cardContent}>
                          <h2 className="cardTitle">Dev tracker</h2>
                          <p className={cardDescription}>
                            Creating ideas for products with the required basic attributes and
                            getting ready to propose them.
                          </p>
                        </div>
                        <div className={cardGraphic}>
                          <DevTrackerLogo className={devTrackerLogo} />
                        </div>
                      </div>
                    </PrimaryButton>
                    <PrimaryButton
                      className={`${card} ${optionLibraryCard} ${gridItem}`}
                      onClick={this.logIntoOptionLibrary}
                    >
                      <div className={wrapper}>
                        <div className={cardContent}>
                          <h2 className="cardTitle">Option library</h2>
                          <p className={cardDescription}>
                            Adding additional attributes & generating Product numbers when Ready to
                            Buy.
                          </p>
                        </div>
                        <div className={cardGraphic}>
                          <OptionLibraryLogo className={optLibLogo} />
                        </div>
                      </div>
                    </PrimaryButton>
                  </>
                )}
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
                <BackgroundImage />
              </div>
              {isLoggedIn && !locked && (
                <NavLink to="/dev-tracker/create">
                  <div className={createButtonWrapper}>
                    <button
                      data-cy="mobile-menu"
                      aria-label="Create product"
                      type="button"
                      className={createButton}
                    >
                      + Create new development
                    </button>
                  </div>
                </NavLink>
              )}
            </article>
          </>
        )}
      </>
    )
  }

  logIntoDevTracker = (): void => {
    ssoLogin(`${window.location.origin}/auth?path=/dev-tracker`)
  }

  logIntoOptionLibrary = (): void => {
    ssoLogin(`${window.location.origin}/auth?path=/option-library`)
  }
}

export default Home
