import React from 'react'

export const HealthCheck = () => {
  return <div>Health Check details go here</div>
}

export default function HealthCheckPage() {
  return (
    <div>
      <HealthCheck />
    </div>
  )
}
