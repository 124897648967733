import { connect } from 'react-redux'
import { HierarchyCellRenderer } from './HierarchyCellRenderer'
import { AppState } from 'src/reducers/root'
import { Product } from 'src/types/index'
import { getHierarchyForProduct } from 'src/selectors/hierarchy'

type Props = {
  product: Product
}

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const hierarchyInfo = getHierarchyForProduct(state, ownProps.product.hierarchySlug)

  return {
    title: hierarchyInfo.title,
    levelCode: hierarchyInfo.levelCode,
  }
}

export const HierarchyCellRendererContainer = connect(mapStateToProps)(HierarchyCellRenderer)
