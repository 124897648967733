import React, { Component } from 'react'
import { AutoSizer, GridCellRangeRenderer, GridCellRenderer, MultiGrid } from 'react-virtualized'

import { KeyboardNavigationContext } from 'src/components/KeyboardNavigation'
import { NoDataComponent } from '../NoDataComponent'
import { syncTableHeadersScrollPositionWithGrid } from './syncTableHeadersScrollPositionWithGrid'
import { shouldLoadNextPage } from './infiniteScroll'
import { encapsulatedTableBody } from './TableBody.scss'

const ROW_HEIGHT = 100
type Props = {
  numberOfFrozenColumns: number
  columnWidths: number[]
  rowCount: number
  cellRenderer: GridCellRenderer
  setGridRef: (node: any) => void
  cellRangeRenderer: GridCellRangeRenderer
  selected: { row: number; column: number }
  updatingSelected: boolean
  loadNextPage: () => void
  lastPage: number
  lastPageLoaded: number
  pageLoading: boolean
}

export class TableBody extends Component<Props> {
  gridRef: any

  componentDidUpdate = () => {
    this.gridRef && syncTableHeadersScrollPositionWithGrid(this.gridRef.state.scrollLeft)
  }

  render() {
    const {
      rowCount,
      selected,
      updatingSelected,
      cellRenderer,
      cellRangeRenderer,
      columnWidths,
      numberOfFrozenColumns,
    } = this.props

    if (rowCount === 0) {
      return <NoDataComponent />
    }

    return (
      <div className={encapsulatedTableBody}>
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              ref={this.setGridRef}
              height={height}
              width={width}
              rowHeight={ROW_HEIGHT}
              scrollToRow={updatingSelected ? selected.row : -1}
              scrollToColumn={updatingSelected ? selected.column : -1}
              styleBottomLeftGrid={{
                boxShadow: '2px 0 1px -1px #DDDDDD',
                zIndex: 1,
              }}
              onSectionRendered={this.onSectionRendered}
              classNameBottomRightGrid="BottomRightGrid_ScrollWrapper"
              fixedColumnCount={numberOfFrozenColumns}
              fixedRowCount={0}
              onScroll={this.onScroll}
              cellRenderer={cellRenderer}
              cellRangeRenderer={cellRangeRenderer}
              rowCount={rowCount}
              columnCount={columnWidths.length}
              columnWidth={this.columnWidth}
              enableFixedColumnScroll
              hideBottomLeftGridScrollbar
              tabIndex={null}
            />
          )}
        </AutoSizer>
      </div>
    )
  }

  setGridRef = (node: any) => {
    this.gridRef = node
    this.props.setGridRef(node)
  }

  columnWidth = ({ index }: { index: number }) => {
    const { columnWidths } = this.props

    return columnWidths[index]
  }

  /**
   NOTE: we are having to manage synchronisation between the left and right
   Grid ourselves as there is a bug with react-virtualised when fixedRowCount = 0
   See https://github.com/bvaughn/react-virtualized/issues/1155
   We might be able to remove the querySelector below once that issue is addressed
   */

  onScroll = (event: { scrollLeft: number; scrollTop: number }) => {
    syncTableHeadersScrollPositionWithGrid(event.scrollLeft)
    const rightScrollWrapper = document.querySelector('.BottomRightGrid_ScrollWrapper')

    if (rightScrollWrapper) {
      rightScrollWrapper.scrollTop = event.scrollTop
    }
  }

  onSectionRendered = ({ rowStopIndex }: { rowStopIndex: number }) => {
    if (
      !this.props.pageLoading &&
      shouldLoadNextPage({
        lastRowInView: rowStopIndex,
        lastPage: this.props.lastPage,
        lastPageLoaded: this.props.lastPageLoaded,
      })
    ) {
      this.props.loadNextPage()
    }
  }
}

export const TableBodyWithKeyboardNav = (props: Props) => (
  <KeyboardNavigationContext.Consumer>
    {({ selected, updatingSelected }) => (
      <TableBody {...props} selected={selected} updatingSelected={updatingSelected} />
    )}
  </KeyboardNavigationContext.Consumer>
)
