import React from 'react'
import { spinner, spinnerContainer } from './RISpinner.scss'
import { RIIcon } from '../../assets/icons'
import classNames from 'classnames'

interface Props {
  children?: React.ReactNode
  colour?: string
  className?: string
  containerStyle?: string
}

export const RISpinner = ({ children, colour, className, containerStyle }: Props) => {
  const RiIconClass = classNames(spinner, {
    [className as string]: className,
  })

  const containerClass = classNames(spinnerContainer, containerStyle)

  return (
    <div className={containerClass}>
      <div className={RiIconClass}>
        <RIIcon colour={colour} />
      </div>
      {children}
    </div>
  )
}
