import { DepartmentSpecs, RequestStatus } from 'src/types/index'
import {
  UPDATE_DEPARTMENT_SPECS,
  FETCH_DEPARTMENT_SPECS,
  FETCH_DEPARTMENT_SPECS_FAILURE,
} from 'src/actions/grid/departmentSpec'
import { UNINITIALISED_STATUS } from '../../../constants/requests'
import { SVPAction } from 'actions/svp'

export type DepartmentSpecsState = {
  status: RequestStatus
  specs: DepartmentSpecs
  error?: string
}

export const NO_SELECTED_DEPARTMENT = 'noSelectedDepartment'

const defaultState: DepartmentSpecsState = {
  status: UNINITIALISED_STATUS,
  specs: {},
}

export const departmentSpecs = (state = defaultState, action: SVPAction): DepartmentSpecsState => {
  switch (action.type) {
    case UPDATE_DEPARTMENT_SPECS:
      return {
        status: 'ready',
        specs: action.departmentSpecs,
      }
    case FETCH_DEPARTMENT_SPECS:
      return {
        status: 'fetching',
        specs: state.specs,
      }
    case FETCH_DEPARTMENT_SPECS_FAILURE:
      return {
        ...state,
        status: 'error',
        error: action.error,
      }
    default:
      return state
  }
}
