import React from 'react'
import { Link } from 'react-router-dom'
import { container, attributeName, link, attributeInfo } from './DepartmentAttribute.scss'
import { Rules } from './Rules/Rules'
import { Views } from './Views/Views'
import { DepartmentViews } from 'src/types/DepartmentSpec'
import { ApiRule } from 'src/types/responses/departmentSpec'

type Props = {
  name: string
  displayName: string
  rules: ApiRule
  views: string[]
  viewsReferenceData: DepartmentViews
  changeRules: (val: string, rule: ApiRule) => void
  changeViews: (val: string, views: string[]) => void
}

const getNewViews = (views: string[], toggledView: string): string[] =>
  views.includes(toggledView)
    ? views.filter((view) => view !== toggledView)
    : [...views, toggledView]

export const DepartmentAttribute = ({
  displayName,
  name,
  rules,
  views,
  viewsReferenceData,
  changeRules,
  changeViews,
}: Props): JSX.Element => (
  <div className={container}>
    <div className={attributeName}>
      <Link className={link} to={`/admin/attributes/${name}`}>
        {displayName}
      </Link>
    </div>
    <div className={attributeInfo}>
      <Rules
        rules={rules}
        changeRules={(value): void => {
          changeRules(name, value)
        }}
      />
      <Views
        referenceData={viewsReferenceData}
        views={views}
        toggleView={(view): void => {
          changeViews(name, getNewViews(views, view))
        }}
      />
    </div>
  </div>
)
