import { all, call, select, takeEvery } from 'redux-saga/effects'
import { GetAuth } from '@ri-digital/auth0-authenticator'

import { getHierarchyLookupTable, getSelectedHierarchies } from 'src/selectors/hierarchy'
import { getColumnRenderSpecs } from 'src/selectors/referenceData/columns'
import { exportProducts } from 'src/service/excel/exporter/exporter'
import { errorAndNotification } from '../error'
import { queryParametersBuilder } from './utils/queryParametersBuilder'
import { injectorService } from 'src/service/injector/injectorService'
import { getAttributes, getAttributeValues } from 'src/selectors/referenceData/attributes'
import { getCountries } from 'src/selectors/referenceData/countries'
import { apiProductToProductWithDefaults } from 'src/service/mappers'
import {
  getExactFilters,
  getLastPage,
  getPartialFilters,
  getSortedColumns,
} from 'src/selectors/product'
import { getPageSize } from './fetch'
import {
  ApiProduct,
  Attribute,
  AttributeValueMap,
  Country,
  FlatHierarchyItem,
  GridType,
  IntentionalAny,
  RenderSpec,
} from 'src/types/index'
import { EXPORT_PRODUCTS } from 'src/actions/grid/product/export'
import { addBreadcrumb, logError } from 'src/helpers/sentry'
import { trackEvent } from 'src/service/analytics/analytics'
import { LookupHierarchyState } from 'reducers/referenceData/hierarchy/lookupTable'
import { APIError } from 'types/Api'
import { PartialFiltersState } from 'reducers/common/grid/partialFilters'
import { ExactFiltersState } from 'reducers/common/grid/exactFilters'
import { SortedColumnState } from 'reducers/common/grid/sortedColumns'
import { ProductsPageResponse } from 'types/Product'
import { Severity } from '@sentry/browser'
import { SVPAction } from 'actions/svp'
import { SVPSaga } from 'sagas'

export const EXPORT_PRODUCT_ERROR_MESSAGE =
  "Sorry something went wrong and we couldn't export the products for you. Please try and reduce the number of products to be exported. If it still doesn't work contact support"

export const EXPORT_LIMIT = 5000

export function* exportProductsSaga(action: SVPAction<typeof EXPORT_PRODUCTS>) {
  try {
    const hierarchyLookupTable: LookupHierarchyState = yield select(getHierarchyLookupTable)

    const lastPage: number = yield select(getLastPage)
    if ((lastPage + 1) * getPageSize() >= EXPORT_LIMIT) {
      yield call(
        errorAndNotification,
        '',
        `Limit of ${EXPORT_LIMIT} products exceeded. Please filter and try again.`,
      )
      return
    }

    // TODO assign products to a new variable then create a new payload
    // eslint-disable-next-line require-atomic-updates
    action.payload.products = yield call(getProductsForExportSaga, action.grid)

    const columnSpecs: RenderSpec[] = yield select(getColumnRenderSpecs)
    yield call(exportProducts, action.payload, hierarchyLookupTable, columnSpecs, action.grid)
  } catch (error) {
    const apiError = error as APIError
    logError(apiError, { tag: 'products-export', level: Severity.Error })
    yield* errorAndNotification(apiError, EXPORT_PRODUCT_ERROR_MESSAGE)
  }
}

export function* getProductsForExportSaga(gridName: GridType) {
  const partialFilters: PartialFiltersState = yield select(getPartialFilters)
  const exactFilters: ExactFiltersState = yield select(getExactFilters)
  const sortedColumns: SortedColumnState = yield select(getSortedColumns)

  const selectedHierarchies: FlatHierarchyItem[] = yield select(getSelectedHierarchies)

  const queryParameters: Record<string, IntentionalAny> = yield call(
    queryParametersBuilder,
    exactFilters,
    partialFilters,
    sortedColumns,
    selectedHierarchies,
    gridName,
  )

  const pageParameters = {
    page: 0,
    limit: EXPORT_LIMIT,
  }

  const auth = GetAuth()
  const profile = auth.getProfile()

  addBreadcrumb('product', 'Product export triggered')
  trackEvent('product', 'Product export triggered', profile.nickname)

  const fetchedPage: ProductsPageResponse = yield call(injectorService.getWithHeaders, 'products', {
    ...queryParameters,
    ...pageParameters,
  })

  const apiProducts = fetchedPage.data

  const attributes: Attribute[] = yield select(getAttributes)
  const attributeValues: AttributeValueMap = yield select(getAttributeValues)
  const countries: Country[] = yield select(getCountries)
  const result = apiProducts.map((product: ApiProduct) =>
    apiProductToProductWithDefaults(product, attributes, attributeValues, countries),
  )

  return result
}

export default function* (): SVPSaga {
  yield all([takeEvery(EXPORT_PRODUCTS, exportProductsSaga)])
}
