import { GridType } from 'src/types/index'

export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const CLEAR_ALL_FILTERS_SUCCESS = 'CLEAR_ALL_FILTERS_SUCCESS'
export const REFRESH_FILTERS = 'REFRESH_FILTERS'

export type ClearAllFilters = 'CLEAR_ALL_FILTERS'
export type ClearAllFiltersSuccess = 'CLEAR_ALL_FILTERS_SUCCESS'

export type FiltersActions = ReturnType<
  typeof clearAllFilters | typeof clearAllFiltersSuccess | typeof refreshFilters
>

export const clearAllFilters = (grid: GridType) =>
  <const>{
    type: CLEAR_ALL_FILTERS,
    grid,
  }

export const clearAllFiltersSuccess = (grid: GridType) =>
  <const>{
    type: CLEAR_ALL_FILTERS_SUCCESS,
    grid,
  }

export const refreshFilters = (grid: GridType) =>
  <const>{
    type: REFRESH_FILTERS,
    grid,
  }
