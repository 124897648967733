import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getAttributes, getAttributeValues } from 'src/selectors/referenceData/attributes'
import { apiProductToProductWithDefaults } from 'src/service/mappers'
import { logError } from 'src/service/errors'
import { getCountries } from 'src/selectors/referenceData/countries'
import { getSelectedHierarchies } from 'src/selectors/hierarchy'
import { injectorService } from 'src/service/injector/injectorService'
import {
  getExactFilters,
  getLastPageLoaded,
  getPartialFilters,
  getProductsSlugs,
  getSortedColumns,
} from 'src/selectors/product'
import {
  FETCH_NEXT_PRODUCTS_PAGE,
  fetchProductsPageSuccess,
  fetchCompletedPollProductsSuccessful,
  FETCH_FIRST_PRODUCTS_PAGE,
  fetchProductsFailure,
} from 'src/actions/grid/product/fetch'
import { queryParametersBuilder } from './utils/queryParametersBuilder'
import { Config } from 'helpers/config'
import {
  ApiProduct,
  Attribute,
  AttributeValueMap,
  Country,
  FlatHierarchyItem,
  GridType,
  Product,
} from 'src/types/index'
import { ExactFiltersState } from 'src/reducers/common/grid/exactFilters'
import { SortedColumnState } from 'src/reducers/common/grid/sortedColumns'
import { PartialFiltersState } from 'src/reducers/common/grid/partialFilters'
import { POLLING_IDS_COMPLETE } from 'src/actions/polling/types'
import { getGrid } from 'src/selectors/grid'
import {
  POLLING_MARK_READY_TO_BUY,
  POLLING_SIZES_AFTER_RTB,
  POLLING_MARK_CANCELLED,
} from 'src/constants/polling'
import { PartialFilter } from 'src/actions/grid/partialFilters'
import { mapPriceValuesToFilters } from 'src/service/mappers/filters'
import { APIError } from 'types/Api'
import { SVPAction } from 'actions/svp'
import {
  EXCLUDED_MARKDOWN,
  priceStatusLabel,
  PRICE_STATUS,
  PRICE_STATUS_LABEL,
} from 'constants/price'
import { addExactFilterSuccess } from 'actions/grid/exactFilters'

export const getPageSize = (): number => {
  return Config().Get('pageSize') as number
}

export function* fetchCompletedPollProductsSaga(action: SVPAction<typeof POLLING_IDS_COMPLETE>) {
  try {
    // We only need to refretch data for MRTB products or sizes after ready to buy
    const productPolls = action.polls.filter(
      (x) =>
        x.pollType === POLLING_MARK_READY_TO_BUY ||
        x.pollType === POLLING_SIZES_AFTER_RTB ||
        x.pollType === POLLING_MARK_CANCELLED,
    )

    if (productPolls.length === 0) {
      return
    }

    const pageSlugs: string[] = yield select(getProductsSlugs)

    // We only need to refresh products in the current page. Others will be refreshed on next page load
    const pollsInPage = productPolls.filter((x) => pageSlugs.includes(x.productSlug))

    // We cant filter by Slug do we need to filter by Dev ID
    const filters: PartialFilter[] = pollsInPage.map((x) => {
      return {
        value: x.developmentId,
        property: 'developmentId',
        displayName: 'developmentId',
      }
    })

    const partialFilters: PartialFiltersState = {
      developmentId: {
        saved: filters,
      },
    }

    const grid: GridType = yield select(getGrid)

    const { products } = yield call(fetchFilteredProducts, 0, {}, partialFilters, [], grid)

    yield put(fetchCompletedPollProductsSuccessful(products, grid))
  } catch (error) {
    yield call(logError, error as APIError)
  }
}

export function* refetchCurrentPageSaga() {
  const grid: GridType = yield select(getGrid)
  const lastPageLoaded: number = yield select(getLastPageLoaded)
  const partialFilters: PartialFiltersState = yield select(getPartialFilters)
  const exactFilters: ExactFiltersState = yield select(getExactFilters)
  const sortedColumns: SortedColumnState = yield select(getSortedColumns)
  yield call(
    fetchProductsPageSaga,
    lastPageLoaded,
    exactFilters,
    partialFilters,
    sortedColumns,
    grid,
  )
}

export function* fetchFirstPageSaga(action: SVPAction<typeof FETCH_FIRST_PRODUCTS_PAGE>) {
  const partialFilters: PartialFiltersState = yield select(getPartialFilters)
  const exactFilters: ExactFiltersState = yield select(getExactFilters)
  const sortedColumns: SortedColumnState = yield select(getSortedColumns)
  yield call(fetchProductsPageSaga, 0, exactFilters, partialFilters, sortedColumns, action.grid)
}

export function* fetchNextPageSaga(action: SVPAction<typeof FETCH_NEXT_PRODUCTS_PAGE>) {
  const lastPageLoaded: number = yield select(getLastPageLoaded)
  const partialFilters: PartialFiltersState = yield select(getPartialFilters)
  const exactFilters: ExactFiltersState = yield select(getExactFilters)
  const sortedColumns: SortedColumnState = yield select(getSortedColumns)
  yield call(
    fetchProductsPageSaga,
    lastPageLoaded + 1,
    exactFilters,
    partialFilters,
    sortedColumns,
    action.grid,
  )
}

export function* fetchFilteredProducts(
  pageIndex: number,
  exactFilters: ExactFiltersState,
  partialFilters: PartialFiltersState,
  sortedColumns: SortedColumnState,
  gridName: GridType,
) {
  const selectedHierarchies: FlatHierarchyItem[] = yield select(getSelectedHierarchies)

  const queryParameters: Record<string, any> = yield call(
    queryParametersBuilder,
    exactFilters,
    mapPriceValuesToFilters(partialFilters),
    sortedColumns,
    selectedHierarchies,
    gridName,
  )

  const pageParameters = {
    page: pageIndex,
    limit: getPageSize(),
  }

  const fetchedPage: { data: ApiProduct[] } = yield call(
    injectorService.getWithHeaders,
    'products',
    {
      ...queryParameters,
      ...pageParameters,
    },
  )

  const apiProducts = fetchedPage.data

  const attributes: Attribute[] = yield select(getAttributes)
  const attributeValues: AttributeValueMap = yield select(getAttributeValues)
  const countries: Country[] = yield select(getCountries)

  const newProducts: Product[] = apiProducts.map((product) =>
    apiProductToProductWithDefaults(product, attributes, attributeValues, countries),
  )

  return { products: newProducts, page: fetchedPage }
}

export function* fetchProductsPageSaga(
  pageIndex: number,
  exactFilters: ExactFiltersState,
  partialFilters: PartialFiltersState,
  sortedColumns: SortedColumnState,
  gridName: GridType,
) {
  const exactFiltersExtendedState = { ...exactFilters }
  if (!exactFiltersExtendedState[PRICE_STATUS]) {
    exactFiltersExtendedState[PRICE_STATUS] = [
      {
        label: priceStatusLabel[EXCLUDED_MARKDOWN],
        property: PRICE_STATUS,
        type: PRICE_STATUS_LABEL,
        value: EXCLUDED_MARKDOWN,
        deprecated: false,
      },
    ]
    yield put(
      addExactFilterSuccess(
        {
          label: priceStatusLabel[EXCLUDED_MARKDOWN],
          property: PRICE_STATUS,
          type: PRICE_STATUS_LABEL,
          value: EXCLUDED_MARKDOWN,
          deprecated: false,
        },
        gridName,
      ),
    )
  }

  try {
    const { products, page } = yield call(
      fetchFilteredProducts,
      pageIndex,
      exactFiltersExtendedState,
      partialFilters,
      sortedColumns,
      gridName,
    )
    yield put(
      fetchProductsPageSuccess(products, pageIndex, lastPageFrom(page.headers.link), gridName),
    )
  } catch (error) {
    const apiError = error as APIError
    yield call(logError, apiError)
    yield put(fetchProductsFailure(apiError.toString(), gridName))
  }
}

export default function* () {
  yield all([takeEvery(FETCH_FIRST_PRODUCTS_PAGE, fetchFirstPageSaga)])
  yield all([takeEvery(FETCH_NEXT_PRODUCTS_PAGE, fetchNextPageSaga)])
  yield all([takeEvery(POLLING_IDS_COMPLETE, fetchCompletedPollProductsSaga)])
}

export const lastPageFrom = (linkHeader: string) => {
  const rels = linkHeader.split(',')
  const nextRel = rels.find((rel) => rel.endsWith('"last"')) || ''
  const pattern = /^.+page=(\d+).+$/
  const match = pattern.exec(nextRel) || ''
  return parseInt(match[1])
}
