import { DevTrackerState } from '../reducers/devTracker'
import { OptionLibraryState } from '../reducers/optionLibrary'
import { AppState } from '../reducers/root'
import { DEV_TRACKER, GridType, OPTION_LIBRARY } from 'src/types/index'
import { isLocked } from './lock'
import { isAdmin } from './userProfile'

export const getGrid = (state: AppState): GridType =>
  state.router.location.pathname.includes('/dev-tracker') ? DEV_TRACKER : OPTION_LIBRARY

export const getGridSlice = (state: AppState): OptionLibraryState | DevTrackerState =>
  state.injectorUI[getGrid(state)]

export const isGridLocked = (state: AppState): boolean => isLocked(state) && !isAdmin(state)
