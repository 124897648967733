import React, { Component } from 'react'

import { row } from './SizeGroup.scss'
import { SizesColumn } from './SizesColumn/SizesColumn'
import { SkusColumn } from './SkusColumn/SkusColumn'
import ProductNumberColumn from './ProductNumberColumn/ProductNumberColumn'
import { Size, SizeGroup as SizeGroupType } from 'src/types/Size'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { PriceColumn } from './PriceColumn/PriceColumn'
import { PriceTypes } from 'src/types/Price'
import {
  CURRENT_FULL_PRICE,
  CURRENT_FULL_PRICE_TITLE,
  LANDED_COST,
  LANDED_COST_TITLE,
  PROMO_PRICE,
  PROMO_PRICE_TITLE,
  SELLING_PRICE,
  SELLING_PRICE_TITLE,
  SUPPLIER_COST,
  SUPPLIER_COST_TITLE,
} from 'src/constants/price'
import { CurrencyKeys, DiscreteValue } from 'src/types/index'

type Props = {
  supplierCostCurrency: CurrencyKeys
  group: ProductSizeGroups
  groupData: SizeGroupType
  showColumnTitle?: boolean
  isLastSection?: boolean
  isProductReadyToBuy?: boolean
  sellingPriceOptions: DiscreteValue[]
  isHierarchyInBeautyAndBrandsDivision: boolean
  onSizeChipClicked: (size: Size) => void
  onPriceChange: (group: ProductSizeGroups, priceKey: PriceTypes, priceValue: number) => void
  onCurrencyChange?: (currency: CurrencyKeys) => void
}

export default class SizeGroup extends Component<Props> {
  render(): JSX.Element {
    const {
      showColumnTitle,
      group,
      groupData,
      supplierCostCurrency,
      isLastSection,
      isProductReadyToBuy,
      sellingPriceOptions,
      isHierarchyInBeautyAndBrandsDivision,
      onSizeChipClicked,
      onPriceChange,
      onCurrencyChange,
    } = this.props

    return (
      <div className={row} data-cy={`sizegroup_${group}`}>
        <SizesColumn
          sizes={groupData.activeSizes}
          group={group}
          showColumnTitle={showColumnTitle}
          onSizeChipClicked={onSizeChipClicked}
          isLastSection={isLastSection}
          isProductReadyToBuy={isProductReadyToBuy}
        />
        <SkusColumn
          group={group}
          sizes={groupData.activeSizes}
          showColumnTitle={showColumnTitle}
          isLastSection={isLastSection}
        />
        <ProductNumberColumn
          group={group}
          showColumnTitle={showColumnTitle}
          hideSection={!groupData.activeSizes.length}
          productNumber={groupData.productNumber}
          isLastSection={isLastSection}
          isProductReadyToBuy={isProductReadyToBuy}
        />
        <PriceColumn
          sizes={groupData.activeSizes}
          group={group}
          columnTitle={SUPPLIER_COST_TITLE}
          supplierCostCurrency={supplierCostCurrency}
          priceKey={SUPPLIER_COST}
          priceValue={groupData[SUPPLIER_COST] || 0}
          showColumnTitle={showColumnTitle}
          isLastSection={isLastSection}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
        />
        <PriceColumn
          sizes={groupData.activeSizes}
          group={group}
          columnTitle={LANDED_COST_TITLE}
          priceKey={LANDED_COST}
          priceValue={groupData[LANDED_COST] || 0}
          showColumnTitle={showColumnTitle}
          isLastSection={isLastSection}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onPriceChange={onPriceChange}
        />
        <PriceColumn
          sizes={groupData.activeSizes}
          group={group}
          columnTitle={SELLING_PRICE_TITLE}
          priceKey={SELLING_PRICE}
          priceValue={groupData[SELLING_PRICE] || 0}
          showColumnTitle={showColumnTitle}
          isLastSection={isLastSection}
          sellingPriceOptions={sellingPriceOptions}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onPriceChange={onPriceChange}
        />
        {isProductReadyToBuy && (
          <>
            <PriceColumn
              sizes={groupData.activeSizes}
              group={group}
              columnTitle={CURRENT_FULL_PRICE_TITLE}
              priceKey={CURRENT_FULL_PRICE}
              priceValue={groupData[CURRENT_FULL_PRICE] || 0}
              showColumnTitle={showColumnTitle}
              isLastSection={isLastSection}
              onPriceChange={onPriceChange}
              isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
              isReadOnly
            />
            <PriceColumn
              sizes={groupData.activeSizes}
              group={group}
              columnTitle={PROMO_PRICE_TITLE}
              priceKey={PROMO_PRICE}
              priceValue={groupData[PROMO_PRICE] || 0}
              showColumnTitle={showColumnTitle}
              isLastSection={isLastSection}
              onPriceChange={onPriceChange}
              isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
              isReadOnly
            />
          </>
        )}
      </div>
    )
  }
}
