import React from 'react'
import moment from 'moment'

import DatePicker from 'src/components/DatePicker/index'
import { Product } from 'src/types/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import { ESC_KEY } from 'src/constants/keyCodes'

import { datePickerCellRenderer, cellValue } from './DatePickerCellRenderer.scss'
import { validFormatDate } from 'helpers/date'

type Props = {
  editMode: boolean
  accessor: string
  product: Product
  exitEditMode: (exit: boolean) => void
  updateProduct: (payload: PatchPayload) => void
}

export const DatePickerCellRenderer = (props: Props): JSX.Element => {
  const { accessor, product, editMode, updateProduct, exitEditMode } = props
  const value = product[accessor] || ''
  const renderedDate = validFormatDate(value, 'DD-MMM-YY')
  return (
    <div className="cell">
      <div className={`cellInner cellInnerEditing-${editMode.toString()}`}>
        <div className="cellValueAndInputField">
          <div className={cellValue}>{renderedDate}</div>
          {editMode && (
            <DatePicker
              className={datePickerCellRenderer}
              name={accessor}
              hasFocus
              value={value}
              onChange={(date): void => {
                exitEditMode(true)
                updateProduct({
                  // @ts-ignore
                  developmentId: product.developmentId,
                  productSlug: product.slug,
                  patchFields: {
                    store: { [accessor]: moment(date).toISOString() },
                  },
                })
              }}
              onKeyDown={(event): void => {
                event.preventDefault()
                if (event.key === ESC_KEY) {
                  exitEditMode(true)
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DatePickerCellRenderer
