import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { getColumnRenderSpecs } from 'src/selectors/referenceData/columns'
import { runProductValidationsSaga } from './validate'
import { actionLookUp } from 'src/service/product/status'
import { MARK_READY_TO_BUY_DEPRECATED_SIZES_ERROR } from '../../constants/errorCodes'
import { CANCELLED, PROPOSED, READY_TO_BUY } from 'src/constants/productStatus'
import { ProductStatus } from 'src/types/ProductStatus'
import { injectorService } from 'src/service/injector/injectorService'
import { errorAndNotification } from '../error'
import { formatCollection } from 'src/service/messages'
import { clearStyleSaga } from './clearStyle'
import { clearGroupsSaga } from './clearGroups'
import { getDepartmentByHierarchy } from 'src/service/hierarchy/utils'
import { getDepartmentsWithChildren } from 'src/selectors/hierarchy'
import { getDepartmentSpecsForGrid } from 'src/selectors/departmentSpecs'
import { reloadProductSaga } from './reload'
import { showNotification } from 'src/actions/notification'
import { addPollingID, startPolling } from 'src/actions/polling/polling'
import {
  MARK_AS_CANCELLED_ENDPOINT,
  MARK_AS_PROPOSED,
  MARK_AS_RTB_ENDPOINT,
} from 'src/constants/apiUrls'
import { DepartmentSpecs, GridType, Product, RenderSpec } from 'src/types/index'
import { MarkAsyncResponse } from 'src/types/responses/updateStatus'
import { POLLING_MARK_CANCELLED, POLLING_MARK_READY_TO_BUY } from 'src/constants/polling'
import { PollingTypes } from 'src/actions/polling/types'
import { addBreadcrumb } from '../../helpers/sentry'
import { trackEvent } from '../../service/analytics/analytics'
import { SVPAction } from 'actions/svp'
import { updateProductStatusFailure, UPDATE_PRODUCT_STATUS } from 'actions/grid/product/update'
import { DepartmentsWithChildrenState } from 'reducers/referenceData/hierarchy/departmentsWithChildren'
import { APIError } from 'types/Api'
import { isUseCaseErrorWithCode } from 'helpers/apiErrors'

const errorMessageFor = (errorType: string): string =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

const RETRY_ERROR_MESSAGE =
  'Something went wrong.\nPlease, retry changing this development to Ready to Buy.'

const updateStatusErrorMessage = (status: ProductStatus): string =>
  errorMessageFor(`mark as ${status}`)

export const REFRESH_VIEW_ERROR_MESSAGE = 'Admin may have updated the view, please refresh page'

export function* updateProductStatusSaga({
  payload,
  grid,
}: SVPAction<typeof UPDATE_PRODUCT_STATUS>) {
  const { product, newStatus } = payload
  const departmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecsForGrid)
  const departmentsWithChildren: DepartmentsWithChildrenState = yield select(
    getDepartmentsWithChildren,
  )
  const productDepartment = getDepartmentByHierarchy(product.hierarchySlug, departmentsWithChildren)

  const rules = departmentSpecs[productDepartment].rules

  const columnSpecs: RenderSpec[] = yield select(getColumnRenderSpecs)
  const invalidFields: string[] = yield call(
    runProductValidationsSaga,
    product,
    rules,
    columnSpecs,
    newStatus,
    grid,
  )

  if (invalidFields.length !== 0) {
    yield call(
      errorAndNotification,
      'Validation failed',
      `Please amend ${formatCollection(getDisplayNames(invalidFields, columnSpecs))}`,
    )
    yield put(updateProductStatusFailure(product.slug, grid))

    return
  }

  addBreadcrumb('product', 'Status Updated')
  trackEvent('product', 'Status Updated', newStatus)

  try {
    switch (newStatus) {
      case READY_TO_BUY:
        yield call(markProductReadyToBuy, grid, product)

        break
      case PROPOSED:
        yield call(markProductProposed, grid, product)

        break
      case CANCELLED:
        yield call(markProductCancelled, grid, product, newStatus)

        break
      default:
        yield call(updateProductStatus, grid, product, newStatus)

        break
    }
  } catch (error) {
    const apiError = error as APIError
    if (apiError?.data) {
      const { status, type, code } = apiError.data
      if (isUseCaseErrorWithCode(status, type, MARK_READY_TO_BUY_DEPRECATED_SIZES_ERROR, code)) {
        const errMsg = `The Teen size that you have selected no longer exists - 
                Please remove the current Teen size and re-select to continue.`
        yield call(errorAndNotification, apiError, errMsg)
        return
      }
    }

    if (!apiError.response) {
      yield call(errorAndNotification, apiError, REFRESH_VIEW_ERROR_MESSAGE)
      return
    }

    const statusCode = apiError?.response?.data?.statusCode
    const errorCode = apiError?.response?.data?.error ?? apiError?.response?.data?.status
    if (errorCode === 'error') {
      yield call(errorAndNotification, apiError, apiError?.response?.data?.reason ?? '')
    } else if (statusCode === 400 && errorCode == '1') {
      yield call(errorAndNotification, apiError, REFRESH_VIEW_ERROR_MESSAGE)
    } else if (statusCode === 500 && errorCode == '12') {
      yield put(
        showNotification({
          type: 'warn',
          message: RETRY_ERROR_MESSAGE,
        }),
      )
      yield call(reloadProductSaga, product.slug, grid)
    } else {
      yield call(errorAndNotification, apiError, updateStatusErrorMessage(newStatus))
    }
    yield put(updateProductStatusFailure(product.slug, grid))
  }
}

function* markProductProposed(grid: GridType, product: Product) {
  yield call(injectorService.put, MARK_AS_PROPOSED, { productSlugs: [product.slug] })

  yield call(reloadProductSaga, product.slug, grid)
}

function* markProductReadyToBuy(grid: GridType, product: Product) {
  yield call(markStatusAsync, grid, product, MARK_AS_RTB_ENDPOINT, POLLING_MARK_READY_TO_BUY)
}

function* markProductCancelled(grid: GridType, product: Product) {
  yield call(clearStyleSaga, product)
  yield call(clearGroupsSaga, product)

  yield call(markStatusAsync, grid, product, MARK_AS_CANCELLED_ENDPOINT, POLLING_MARK_CANCELLED)
}

function* markStatusAsync(
  grid: GridType,
  product: Product,
  apiEndpoint: string,
  pollingType: PollingTypes,
) {
  const response: MarkAsyncResponse = yield call(
    injectorService.put,
    `products/${product.slug}/${apiEndpoint}`,
    {},
    { v2Endpoint: true },
  )

  if (response && response.pollingID) {
    yield put(addPollingID(product.slug, product.developmentId, response.pollingID, pollingType))
    yield put(startPolling())
  }

  yield call(reloadProductSaga, product.slug, grid)
}

function* updateProductStatus(grid: GridType, product: Product, status: ProductStatus) {
  yield call(injectorService.put, `workflows/${product.slug}`, {
    status: status,
    action: actionLookUp[status],
  })

  yield call(reloadProductSaga, product.slug, grid)
}

export default function* () {
  yield all([takeEvery(UPDATE_PRODUCT_STATUS, updateProductStatusSaga)])
}

const getDisplayNames = (invalidFields: string[], columnSpecs: RenderSpec[]): string[] =>
  invalidFields.map(
    (field) => columnSpecs.find((spec) => spec.property === field)?.displayName || '',
  )
