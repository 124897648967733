import React from 'react'
import { UploadSpinner } from '../UploadSpinner'

type Props = {
  className: string
  src?: string
  alt?: string
  parsing?: boolean
}

export const SketchImage = ({ className, src, alt, parsing }: Props): JSX.Element =>
  parsing ? <UploadSpinner /> : src ? <img className={className} src={src} alt={alt} /> : null
