import { connect } from 'react-redux'
import { MultiDropdownCellRenderer } from './MultiDropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { getAttributeValues } from 'src/selectors/referenceData/attributes'
import { DropdownOption } from 'src/types/Dropdowns'
import { SELLING_PERIOD } from 'src/constants/attributes'
import { getOrderedSellingPeriodMonths, OrderedMonths } from 'src/service/product/uiValues'
import { alphabeticallySortAttributeValues } from 'src/service/attributes/attributes'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  let dropdownItems = getAttributeValues(state)[ownProps.accessor].filter(
    (item) => !item.deprecated,
  ) as DropdownOption[]
  if (ownProps.accessor === SELLING_PERIOD) {
    const orderedPeriodKeys: OrderedMonths = getOrderedSellingPeriodMonths()
    dropdownItems = dropdownItems.sort(
      (a, b) => orderedPeriodKeys[a.value as string] - orderedPeriodKeys[b.value as string],
    )
  } else {
    dropdownItems = dropdownItems.sort(alphabeticallySortAttributeValues)
  }

  return {
    dropdownItems: dropdownItems as DropdownOption[],
  }
}

export const MultiDropdownCellRendererContainer =
  connect(mapStateToProps)(MultiDropdownCellRenderer)
