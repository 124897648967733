import { AttributeValue, Product } from 'src/types/index'
import { AppState } from '../reducers/root'
import getProp from 'lodash/get'
import { createSelector } from 'reselect'
import { getGridSlice } from './grid'
import { SortedColumnState } from '../reducers/common/grid/sortedColumns'
import { PartialFilter } from '../actions/grid/partialFilters'
import { HiddenColumnState } from 'reducers/common/grid/hiddenColumns'
import { HighlightedRowsState } from 'reducers/common/grid/highlightedRows'
import { ProductMap } from 'types/Product'
import { Filter } from 'actions/grid/exactFilters'
import { PartialFiltersState } from 'reducers/common/grid/partialFilters'
import { ExactFiltersState } from 'reducers/common/grid/exactFilters'

export const getDeprecatedPropertyValues = (
  state: AppState,
  property: string,
): AttributeValue[] => {
  const allAttributeValues = state.injectorUI.referenceData.attributes.values[property]
  if (allAttributeValues) {
    return allAttributeValues.filter((value) => value.deprecated)
  }
  return []
}

export const getLastPage = (state: AppState): number | null =>
  getGridSlice(state).products.pagination ? getGridSlice(state).products.pagination.lastPage : null

export const getLastPageLoaded = (state: AppState): number | null =>
  getGridSlice(state).products.pagination
    ? getGridSlice(state).products.pagination.lastPageLoaded
    : null

export const getPageLoading = (state: AppState): boolean =>
  getGridSlice(state).products.pagination
    ? (getGridSlice(state).products.pagination.pageLoading as boolean)
    : false

export const productsHaveError = (state: AppState): boolean =>
  getGridSlice(state).products.status === 'error'

export const isProductsLoading = (state: AppState): boolean =>
  getGridSlice(state).products.status === 'fetching'

export const getHighlightedRows = (state: AppState): HighlightedRowsState =>
  getGridSlice(state).highlightedRows

export const getSortedColumns = (state: AppState): SortedColumnState =>
  getGridSlice(state).sortedColumns

export const getInvalidProductFields = (state: AppState): { [key: string]: string[] } =>
  getGridSlice(state).products.invalidProductFields

export const getDeprecatedProductFields = (state: AppState): { [key: string]: string[] } =>
  getGridSlice(state).products.deprecatedProductFields

export const getProductsSlugs = (state: AppState): string[] => getGridSlice(state).products.slugs

export const getProductsMap = (state: AppState): ProductMap => {
  return getGridSlice(state).products.productsMap
}

export const getProductBySlug = (state: AppState, slug: string): Product => {
  return getGridSlice(state).products.productsMap[slug]
}

export const getCurrentPartialFilterForProperty = (state: AppState, property: string): string =>
  getProp(getGridSlice(state).partialFilters, `${property}.current.value`, '') as string

export const getSavedPartialFiltersForProperty = (
  state: AppState,
  property: string,
): PartialFilter[] =>
  getProp(getGridSlice(state).partialFilters, `${property}.saved`, []) as PartialFilter[]

export const getExactFiltersForProperty = (state: AppState, property: string): Filter[] =>
  getGridSlice(state).exactFilters[property] || []

const createOrderedProductList = (
  slugs: string[],
  productsMap: { [key: string]: Product },
): Product[] => slugs.map((id) => productsMap[id])

export const getAllProducts = createSelector(
  getProductsSlugs,
  getProductsMap,
  createOrderedProductList,
)

export const getPartialFilters = (state: AppState): PartialFiltersState =>
  getGridSlice(state).partialFilters

export const getExactFilters = (state: AppState): ExactFiltersState =>
  getGridSlice(state).exactFilters

export const isProductLocked = (state: AppState, slug: string): boolean => {
  return !!getGridSlice(state)?.products?.productsMap[slug]?.locked
}

export const getHiddenColumns = (state: AppState): HiddenColumnState =>
  getGridSlice(state).hiddenColumns
