import React from 'react'
import isEqual from 'lodash/isEqual'

import { Modal } from '../Modal/Modal'
import {
  CurrencyKeys,
  Product,
  Size,
  SizeGroups,
  PartialPriceAttributions,
  SizeGroup,
} from 'src/types/index'
import { PriceTypes } from 'src/types/Price'
import { SizeModalHeader } from './SizeModalHeader/SizeModalHeader'
import SizeModalFooter from './SizeModalFooter/SizeModalFooter'
import SizeInformation from './SizeInformation/SizeInformation'
import { defaultSizeModalData } from 'src/selectors/modals/sizeModal'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import SizesLoading from './SizesLoading/SizesLoading'
import { POUND_KEY } from 'src/constants/currency'
import {
  CURRENT_FULL_PRICE,
  LANDED_COST,
  PRICE_MULTIPLIER,
  PROMO_PRICE,
  SELLING_PRICE,
  SUPPLIER_COST,
} from 'src/constants/price'
import { getDefaultPriceValues } from 'src/service/product/price'
import { sizeInformation, sizeModal } from './SizeModal.scss'
import { READY_TO_BUY } from 'src/constants/productStatus'
import { DEFAULT_GROUP } from 'src/constants/productSizes'
import { SizeModalProps } from './SizeModalContainer'

interface State {
  supplierCostCurrency: CurrencyKeys
  selectedSizes: SizeGroups
  listModified: boolean
  pricesModified: boolean
}

export class SizeModal extends React.Component<SizeModalProps, State> {
  state = {
    supplierCostCurrency: POUND_KEY as CurrencyKeys,
    selectedSizes: defaultSizeModalData(),
    listModified: false,
    pricesModified: false,
  }

  static getDerivedStateFromProps(props: SizeModalProps, state: State): Partial<State> {
    if (!state.listModified && !state.pricesModified && props.sizeGroups != state.selectedSizes) {
      return {
        selectedSizes: props.sizeGroups,
        supplierCostCurrency: props.supplierCostCurrency,
      }
    } else {
      return null
    }
  }

  render(): JSX.Element {
    const {
      onClose,
      product,
      isReadyToBuy,
      rangeSizes,
      isLoading,
      isPending,
      productHasOrdsError,
      sellingPriceOptions,
      hierarchy,
      isHierarchyInBeautyAndBrandsDivision,
    } = this.props

    const { selectedSizes, supplierCostCurrency, listModified } = this.state

    return (
      <Modal onCloseFn={onClose} modalClass={sizeModal}>
        <div>
          <SizeModalHeader
            onDismissError={this.onDismissError}
            onClose={onClose}
            product={product}
            hierarchy={hierarchy}
            isLoading={isLoading}
            isPending={isPending}
          />
          <div data-cy="sizesInformation" className={sizeInformation}>
            {isLoading ? (
              <SizesLoading />
            ) : (
              <SizeInformation
                rangeSizes={rangeSizes}
                supplierCostCurrency={supplierCostCurrency}
                sizeGroups={selectedSizes}
                isProductReadyToBuy={isReadyToBuy}
                sellingPriceOptions={sellingPriceOptions}
                isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
                addSize={this.addSize}
                updateSize={this.updateSize}
                removeSize={this.removeSize}
                onDismissError={this.onDismissError}
                onPriceChange={this.changePrice}
                onCurrencyChange={this.changeCurrency}
              />
            )}
          </div>
          <SizeModalFooter
            hasSizes={rangeSizes.length > 0}
            displayConfirm={isReadyToBuy && listModified}
            isDisabled={
              !this.state.listModified && !this.state.pricesModified && !productHasOrdsError
            }
            retrySubmit={productHasOrdsError}
            onClose={onClose}
            onSave={this.onSave}
          />
        </div>
      </Modal>
    )
  }

  changePrice = (group: ProductSizeGroups, priceKey: PriceTypes, priceValue: number): void => {
    const newSelectedSizes = { ...this.state.selectedSizes }
    newSelectedSizes[group][priceKey] = priceValue
    this.setState({
      selectedSizes: newSelectedSizes,
      pricesModified: true,
    })
  }

  changeCurrency = (currency: CurrencyKeys): void => {
    this.setState({
      supplierCostCurrency: currency,
      pricesModified: true,
    })
  }

  onDismissError = (errorID: string): void => {
    const { product } = this.props
    this.props.onDismissError(product.slug, errorID)
  }

  addSize = (size: Size): void => {
    const { selectedSizes } = this.state
    const { group } = size

    const newActiveSizes = [...selectedSizes[group].activeSizes]
    newActiveSizes.push(size)
    const shouldAddPrices = !!selectedSizes[DEFAULT_GROUP]
    const sortedActiveSizes = newActiveSizes.sort((a: Size, b: Size) => a.sequence - b.sequence)

    const newGroup = {
      ...selectedSizes[group],
      activeSizes: sortedActiveSizes,
      ...(shouldAddPrices
        ? getDefaultPriceValues(
            0,
            selectedSizes[DEFAULT_GROUP]?.supplierCost,
            selectedSizes[DEFAULT_GROUP]?.landedCost,
            selectedSizes[DEFAULT_GROUP]?.sellingPrice,
          )
        : {}),
    }

    const newSelectedSizes = {
      ...selectedSizes,
      [group]: newGroup,
    }

    this.setState({
      selectedSizes: newSelectedSizes,
      listModified: true,
    })

    if (shouldAddPrices) {
      this.setState({ pricesModified: true })
    }
  }

  updateSize = (size: Size): void => {
    const { name, group } = size
    const { selectedSizes } = this.state

    const objIndex = selectedSizes[group].activeSizes.findIndex((obj) => obj.name === name)

    // Copy the existing array
    const newActiveSizes = [...selectedSizes[group].activeSizes]

    newActiveSizes[objIndex] = size
    const newGroup = {
      ...selectedSizes[group],
      activeSizes: newActiveSizes,
    }

    const newSelectedSizes = {
      ...selectedSizes,
      [group]: newGroup,
    }

    this.setState({
      selectedSizes: newSelectedSizes,
      listModified: true,
    })
  }

  removeSize = (size: Size): void => {
    const { name, group } = size
    const { selectedSizes } = this.state

    const newSizes = selectedSizes[group].activeSizes.filter((size) => {
      return size.name !== name
    })
    const shouldRemovePrices = !newSizes.length

    const newGroup = {
      ...selectedSizes[group],
      activeSizes: newSizes,
    }

    if (shouldRemovePrices) {
      this.removePrices(newGroup)
    }

    const newSelectedSizes = {
      ...selectedSizes,
      [group]: newGroup,
    }

    this.setState({
      selectedSizes: newSelectedSizes,
      listModified: true,
    })

    if (shouldRemovePrices) {
      this.setState({ pricesModified: true })
    }
  }

  removePrices(group: SizeGroup): void {
    delete group[SUPPLIER_COST]
    delete group[LANDED_COST]
    delete group[SELLING_PRICE]
    delete group[PROMO_PRICE]
    delete group[CURRENT_FULL_PRICE]
  }

  updateSizeAttribution = (): void => {
    const { product, updateSizeAttribution } = this.props
    const pricesByGroup = this.getPrices()
    updateSizeAttribution(product.slug, pricesByGroup, this.state.supplierCostCurrency)

    this.setState({
      pricesModified: false,
    })
  }

  onSave = (): void | undefined => {
    const {
      product,
      productHasOrdsError,
      onClose,
      updateProductSizes,
      updateSizesAndAttribution,
      showErrorNotification,
    } = this.props

    const stateSizes = this.getActiveSizes(this.state.selectedSizes).sort()
    const propSizes = this.getActiveSizes(this.props.sizeGroups).sort()

    if (isEqual(stateSizes, propSizes) && !productHasOrdsError) {
      if (this.state.pricesModified) {
        if (this.checkPricesContainsZeroForRTB(this.getPrices(), product)) {
          showErrorNotification('Please fill in all the prices')
          return
        }

        this.updateSizeAttribution()
        this.setState({
          pricesModified: false,
        })
      } else {
        onClose()
      }
      return
    }

    if (this.state.pricesModified) {
      const pricesByGroup = this.getPrices()
      const { supplierCostCurrency } = this.state
      const { slug, developmentId } = product
      if (this.checkPricesContainsZeroForRTB(pricesByGroup, product)) {
        showErrorNotification('Please fill in all the prices')
        return
      }
      updateSizesAndAttribution(
        slug,
        developmentId,
        stateSizes,
        pricesByGroup,
        supplierCostCurrency,
      )
      this.setState({
        listModified: false,
        pricesModified: false,
      })
      return
    }

    updateProductSizes(stateSizes, product.slug, product.developmentId)

    this.setState({
      listModified: false,
    })
  }

  getPrices = (): PartialPriceAttributions => {
    const sizeGroups = this.state.selectedSizes

    const pricesByGroup: PartialPriceAttributions = {}
    Object.keys(sizeGroups).forEach((group) => {
      const sizeGroup = group as ProductSizeGroups
      pricesByGroup[sizeGroup] = {
        ...this.mapExistingPrices(sizeGroups[sizeGroup], SUPPLIER_COST),
        ...(this.shouldUpdateAllPrices(sizeGroups[sizeGroup])
          ? {
              ...this.mapExistingPrices(sizeGroups[sizeGroup], LANDED_COST),
              ...this.mapExistingPrices(sizeGroups[sizeGroup], SELLING_PRICE),
            }
          : {}),
      }
    })

    return pricesByGroup
  }

  mapExistingPrices(
    sizeGroup: SizeGroup,
    price: PriceTypes,
  ): {
    [key: string]: number
  } {
    return sizeGroup.activeSizes.length
      ? { [price]: Math.round((sizeGroup[price] || 0) * PRICE_MULTIPLIER) }
      : {}
  }

  // getActiveSizes returns all active sizes. It will filter any deactived sizes as the API will handle these
  // If proposed, return all
  getActiveSizes = (sizeGroups: SizeGroups): Size[] => {
    const { isReadyToBuy, productHasOrdsError } = this.props

    const sizes: Size[] = []

    Object.keys(sizeGroups).forEach((group) => {
      const activeSizes = sizeGroups[group as ProductSizeGroups].activeSizes

      if (isReadyToBuy) {
        sizes.push(
          ...activeSizes.filter(
            (size) =>
              !size.isAllocated || size.skuActive || (productHasOrdsError && size.isAllocated),
          ),
        )
      } else {
        sizes.push(...activeSizes)
      }
    })

    return sizes
  }

  shouldUpdateAllPrices = (sizeGroup: SizeGroup): boolean => {
    return sizeGroup.activeSizes.every((size) => !size.sku)
  }

  /**
   * Check for 0 price for products in status RTB
   * @param prices
   * @param product
   */
  checkPricesContainsZeroForRTB = (prices: PartialPriceAttributions, product: Product): boolean => {
    let result = false
    if (product.status === READY_TO_BUY) {
      for (const sizeGroup of Object.keys(prices)) {
        const sizeGroupKey = sizeGroup as ProductSizeGroups
        if (
          prices[sizeGroupKey]?.supplierCost === 0 ||
          prices[sizeGroupKey]?.landedCost === 0 ||
          prices[sizeGroupKey]?.sellingPrice === 0
        ) {
          result = true
        }
      }
    }
    return result
  }
}
