import React from 'react'
import { cell, cellValue, currencyDropdown } from './MultiCurrencyCellRenderer.scss'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { IntentionalAny, Product } from 'src/types/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import NumberFormat from 'react-number-format'
import Dropdown from '../../Dropdown'
import { setFocus, setSelected } from '../../../service/ref'
import { MonoCurrencyCellRenderer } from './MonoCurrencyCellRenderer'
import { codesToPrefixes } from '../../../service/excel/exporter/valueMappers'
import { DropdownOption, DropdownStyle } from '../../../types/Dropdowns'

type Props = {
  editMode: boolean
  accessor: string
  product: Product
  updateProduct: (payload: PatchPayload) => void
  exitEditMode: (exit: boolean) => void
  mandatory: boolean
  currencies: string[]
}

type State = {
  draftValue: number
  draftCurrency: string
  currencyHasFocus: boolean
  numberHasFocus: boolean
  isFocused?: boolean
}

function noop(): void {}

export default class MultiCurrencyCellRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const storeValue = props.product[props.accessor]
    this.state = {
      draftValue: props.product[props.accessor] ? storeValue.value : 0,
      draftCurrency: props.product[props.accessor] ? storeValue.currency : 'GBP',
      currencyHasFocus: true,
      numberHasFocus: false,
    }
  }

  getPropsValue = (): number =>
    this.props.product[this.props.accessor] ? this.props.product[this.props.accessor].value : 0

  getPropsCurrency = (): string =>
    this.props.product[this.props.accessor]
      ? this.props.product[this.props.accessor].currency
      : 'GBP'

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.editMode && this.props.editMode) {
      this.setState({
        currencyHasFocus: true,
        numberHasFocus: false,
        draftValue: this.getPropsValue(),
        draftCurrency: this.getPropsCurrency(),
      })
    }
  }

  multipleCurrenciesEnabled = (): boolean =>
    this.props.currencies && this.props.currencies.length > 1

  createCurrencyOption = (currencyCode: string): DropdownOption => ({
    value: currencyCode,
    label: codesToPrefixes[currencyCode],
    data: { currencyCode },
  })

  isValid = (): boolean => this.state.draftValue > 0 || !this.props.mandatory

  onChangeValue = ({ floatValue }: { floatValue: number }): void =>
    this.setState((prevState) => ({
      ...prevState,
      draftValue: floatValue || 0,
    }))

  onChangeCurrency = (item?: { currencyCode: string }): void => {
    this.focusOnNumber()
    this.setState(
      {
        draftCurrency: item ? item.currencyCode : 'GBP',
      },
      this.update,
    )
  }

  anyChange = (): boolean =>
    this.state.draftValue !== this.getPropsValue() ||
    this.state.draftCurrency !== this.getPropsCurrency()

  update = (): void => {
    if (this.anyChange() && this.isValid()) {
      this.props.updateProduct({
        productSlug: this.props.product.slug,
        patchFields: {
          store: {
            [this.props.accessor]: {
              value: this.state.draftValue,
              currency: this.state.draftCurrency,
            },
          },
        },
      })
    }
  }

  currentPrefix = (): string => codesToPrefixes[this.getCurrency()]

  onKeyDownNumber = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ENTER_KEY) {
      e.target instanceof HTMLInputElement && this.update()
      this.exitEditMode()
    }
  }

  focusOnCurrency = (): void => {
    this.setState({
      currencyHasFocus: true,
      numberHasFocus: false,
    })
  }

  focusOnNumber = (): void => {
    this.setState({
      currencyHasFocus: false,
      numberHasFocus: true,
    })
  }

  exitEditMode = (): void => {
    this.focusOnCurrency()
    this.props.exitEditMode(true)
  }

  onBlur = (): void => {
    this.update()
    this.exitEditMode()
  }

  getValue = (): number => {
    if (this.props.editMode) {
      return this.state.draftValue || 0
    } else {
      return this.props.product[this.props.accessor]
        ? this.props.product[this.props.accessor].value
        : 0
    }
  }

  getCurrency = (): string => {
    if (this.props.editMode) {
      return this.state.draftCurrency || 'GBP'
    } else {
      return this.props.product[this.props.accessor]
        ? this.props.product[this.props.accessor].currency
        : 'GBP'
    }
  }

  isAllowed = ({ floatValue }: { floatValue: number }): boolean =>
    floatValue === undefined || floatValue <= MAX_CURRENCY_FIELD_VALUE

  renderText = (value): JSX.Element => <div className={cellValue}>{value}</div>

  getInputRef = (ref: IntentionalAny): void => {
    if (this.props.editMode && this.state.numberHasFocus) {
      setFocus(ref)
      setSelected(ref)
    }
  }

  render(): JSX.Element {
    const customControlStyles = {
      backgroundColor: '#eef0ef',
      borderTopLeftRadius: '10px 10px',
      borderTopRightRadius: '10px 10px',
      width: '50px',
      height: '30px',
      minHeight: 'initial',
      '&:hover': {
        borderColor: 'none',
      },
    }

    const customMenuStyles = {
      width: '50px',
      minWidth: 'initial',
      boxShadow: 'initial',
      height: 'initial',
      overflow: 'hidden',
    }

    const customInputStyles = {
      display: 'hidden',
    }

    const customOptionStyles = {
      height: '30px',
      customBackgroundColor: (state: State): DropdownStyle => ({
        backgroundColor: state.isFocused ? '#e6e6e6' : '#eef0ef',
        '&:active': {
          backgroundColor: '#e6e6e6',
        },
      }),
      textAlign: 'right',
      paddingRight: '8px',
      borderTop: '1px solid #f5f5f5',
    }

    const customSingleValueStyles = {
      width: '100%',
      textAlign: 'right',
      paddingRight: '12px',
    }

    const customIndicatorsContainerStyles = {
      position: 'relative',
      top: '-50px',
      color: 'blue',
    }

    const customDropdownIndicatorStyles = {
      color: '#26B3AD',
    }

    const customContainerStyles = {
      '>div:last-child': {
        borderBottomLeftRadius: '10px 10px',
        borderBottomRightRadius: '10px 10px',
      },
    }

    return (
      <>
        {this.multipleCurrenciesEnabled() ? (
          <div className={cell}>
            {this.props.editMode && (
              <Dropdown
                menuPlacement="bottom"
                customControlStyles={customControlStyles}
                customMenuStyles={customMenuStyles}
                className={currencyDropdown}
                customContainerStyles={customContainerStyles}
                customInputStyles={customInputStyles}
                // @ts-ignore
                customOptionStyles={customOptionStyles}
                customSingleValueStyles={customSingleValueStyles}
                customIndicatorsContainerStyles={customIndicatorsContainerStyles}
                customDropdownIndicatorStyles={customDropdownIndicatorStyles}
                isSearchable={false}
                name=""
                hasFocus={this.state.currencyHasFocus}
                // @ts-ignore no idea how this worked
                items={this.props.currencies.filter((currency) => currency !== this.getCurrency())}
                // @ts-ignore no idea how this worked
                createOption={this.createCurrencyOption}
                isValid
                onItemSelected={this.onChangeCurrency}
                selectedItem={this.getCurrency()}
                onInputKeyDown={noop} // todo
                onBlur={this.focusOnNumber} // todo
                dropdownIndicator
              />
            )}
            <NumberFormat
              data-cy="number-format-component"
              displayType={this.props.editMode ? 'input' : 'text'}
              value={this.getValue()}
              thousandSeparator
              prefix={this.props.editMode ? '' : this.currentPrefix()}
              isAllowed={this.isAllowed}
              decimalScale={2}
              fixedDecimalScale
              allowNegative={false}
              onBlur={this.onBlur}
              onValueChange={this.onChangeValue}
              onKeyDown={this.onKeyDownNumber}
              renderText={this.renderText}
              getInputRef={this.getInputRef}
            />
          </div>
        ) : (
          <MonoCurrencyCellRenderer
            {...this.props}
            currency={
              this.props.currencies && this.props.currencies.length === 1
                ? this.props.currencies[0]
                : 'GBP'
            }
          />
        )}
      </>
    )
  }
}

export const MAX_CURRENCY_FIELD_VALUE = 999999.99
