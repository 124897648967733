import Checkbox from 'components/Checkbox'
import React from 'react'
import { MenuItemOption, MenuItemOptionType } from 'types/Menu'
import {
  menuOptionsContainer,
  menuOptionContainer,
  menuOptionCheckbox,
  menuOptionLink,
} from './MenuOptions.scss'

interface Props {
  options: MenuItemOption[]
  selectedMenuItem: number
  menuItemHeight: number
  parentHeight: number
}

export const MenuOptions = (props: Props) => {
  const menuOptionsTopPosition =
    props.selectedMenuItem === 0
      ? 0
      : Math.round((props.parentHeight - props.selectedMenuItem * props.menuItemHeight) / 10) * 10 +
        5

  return (
    <div className={menuOptionsContainer} style={{ top: menuOptionsTopPosition }}>
      {props.options.map((option, index) => {
        return (
          <div key={`${option.text}-${index}`} className={menuOptionContainer}>
            {option.type === MenuItemOptionType.CHECKBOX ? (
              <div className={menuOptionCheckbox}>
                <div key={`${option.text}-${index}`}>{option.text}</div>
                <Checkbox
                  onClick={option.callback}
                  checked={!!option.selected}
                  disabled={option.isLocked}
                  withBorder
                />
              </div>
            ) : (
              <div className={menuOptionLink} onClick={option.callback}>
                {option.text}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
