import { PartialFilter, REMOVE_PARTIAL_FILTER_SUCCESS } from 'src/actions/grid/partialFilters'
import { CLEAR_AND_SELECT_HIERARCHY } from '../../../actions/grid/hierarchy'
import {
  ADD_PARTIAL_FILTER_SUCCESS,
  REMOVE_PARTIAL_FILTER,
} from '../../../actions/grid/partialFilters'
import { CLEAR_ALL_FILTERS_SUCCESS } from '../../../actions/grid/filters'
import { SVPAction } from 'actions/svp'

const defaultFilters = {}

export type PartialFiltersState = {
  [key: string]: {
    current?: PartialFilter
    saved: PartialFilter[]
  }
}

const hasValue = (filter: PartialFilter) => filter.value.trim().length > 0

const trimValue = (filter: PartialFilter) => ({
  ...filter,
  value: filter.value.trim(),
})

const onlyUniqueValues = (cv: PartialFilter, index: number, arr: PartialFilter[]) =>
  arr.map((mapObj) => mapObj.value).indexOf(cv.value) === index

const addPartialFilter = (state: PartialFiltersState, property: string, filter: PartialFilter) => {
  const existingProperty = state[property] || { saved: [] }
  return hasValue(filter)
    ? {
        ...state,
        [property]: {
          saved: [...(existingProperty.saved || []), trimValue(filter)].filter(onlyUniqueValues),
        },
      }
    : state
}

const removePartialFilter = (state: PartialFiltersState, property: string, filter: PartialFilter) =>
  state[property] && state[property].saved
    ? {
        ...state,
        [property]: {
          ...state[property],
          saved: state[property].saved.filter(
            (partialFilter) => partialFilter.value !== filter.value,
          ),
        },
      }
    : state

export const partialFilters = (state: PartialFiltersState = defaultFilters, action: SVPAction) => {
  switch (action.type) {
    case ADD_PARTIAL_FILTER_SUCCESS:
      return addPartialFilter(state, action.filter.property, action.filter)
    case REMOVE_PARTIAL_FILTER: // we have to optimistically update the state to allow unique values to refresh
    case REMOVE_PARTIAL_FILTER_SUCCESS:
      return removePartialFilter(state, action.filter.property, action.filter)
    case CLEAR_ALL_FILTERS_SUCCESS:
    case CLEAR_AND_SELECT_HIERARCHY:
      return defaultFilters
    default:
      return state
  }
}
