import React from 'react'
import { DepartmentDisplayContainer } from './DepartmentDisplay/DepartmentDisplayContainer'
import { AttributeRenderSpec, FlatHierarchyItem } from 'src/types/index'
import { SideList } from 'src/components/SideList/SideList'
import { COMPANY_WIDE_DISPLAY_NAME, COMPANY_WIDE_SLUG } from 'src/constants/attributes'
import { ADMIN_PATH, DEPARTMENTS_LIST } from 'src/constants/sideList'

type Props = {
  departments: FlatHierarchyItem[]
  attributes: AttributeRenderSpec[]
  currentDepartmentSlug?: string
}

const createList = (departments: FlatHierarchyItem[]) => {
  const sorted = departments
    .map(({ slug, title, levelCode }) => ({
      displayName: `${title} - ${levelCode}`,
      key: slug,
    }))
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
  return [{ displayName: COMPANY_WIDE_DISPLAY_NAME, key: COMPANY_WIDE_SLUG }, ...sorted]
}

const allCompanyDepartment: FlatHierarchyItem = {
  title: COMPANY_WIDE_DISPLAY_NAME,
  slug: COMPANY_WIDE_SLUG,
  levelCode: '',
  levelName: '',
}

const findCurrentDepartment = (
  departments: FlatHierarchyItem[],
  currentDepartmentSlug: string,
): FlatHierarchyItem | undefined => {
  return currentDepartmentSlug === COMPANY_WIDE_SLUG
    ? allCompanyDepartment
    : departments.find(({ slug }) => slug === currentDepartmentSlug)
}

export const Departments = ({
  departments,
  currentDepartmentSlug,
  attributes,
}: Props): JSX.Element => (
  <>
    <SideList list={createList(departments)} type={DEPARTMENTS_LIST} rootPath={ADMIN_PATH} />
    <DepartmentDisplayContainer
      currentDepartment={findCurrentDepartment(departments, currentDepartmentSlug as string)}
      attributesSpecs={attributes}
    />
  </>
)
