import { combineReducers } from 'redux'
import { status } from './status'
import { attributes, AttributesState } from './attributes'
import { views, ViewsState } from './views'
import { hierarchy, HierarchyState } from './hierarchy'
import { columns } from './columns'
import { countries } from './countries'
import { seasons } from './seasons'

import { ColumnValue, RequestStatus, Country, Season } from 'src/types/index'

export type ReferenceDataState = {
  status: RequestStatus
  attributes: AttributesState
  hierarchy: HierarchyState
  views: ViewsState
  columns: ColumnValue[]
  countries: Country[]
  seasons: Season[]
}

export const reducers = {
  status,
  attributes,
  hierarchy,
  views,
  columns,
  countries,
  seasons,
}

// @ts-ignore
export const referenceData = combineReducers(reducers)
