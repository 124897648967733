import { GridType } from 'src/types/index'
import { ProductLinks } from '../../types/Links'
import { ProductWithDynabutes } from '../../types/Product'

export const FETCH_PRODUCT_LINKS = 'FETCH_PRODUCT_LINKS'
export const FETCH_PRODUCT_LINKS_SUCCESS = 'FETCH_PRODUCT_LINKS_SUCCESS'
export const LINK_MATCHING_PRODUCTS = 'LINK_MATCHING_PRODUCTS'
export const LINK_CROSS_DEPT_PRODUCTS = 'LINK_CROSS_DEPT_PRODUCTS'
export const REMOVE_MATCHING_PRODUCT = 'REMOVE_MATCHING_PRODUCT'
export const REMOVE_CROSS_DEPARTMENT_PRODUCT = 'REMOVE_CROSS_DEPARTMENT_PRODUCT'
export const ADD_MATCHING_PRODUCT_LINK = 'ADD_MATCHING_PRODUCT_LINK'
export const ADD_CROSS_DEPARTMENT_LINK = 'ADD_CROSS_DEPARTMENT_LINK'

export type LinksActions = ReturnType<
  | typeof fetchProductLinks
  | typeof fetchProductLinksSuccess
  | typeof linkMatchingProducts
  | typeof linkCrossDepartmentProducts
  | typeof removeMatchingProduct
  | typeof removeCrossDepartmentProduct
  | typeof addMatchingProductLink
  | typeof addCrossDepartmentLink
>

export const fetchProductLinks = (productSlug: string) =>
  <const>{
    type: FETCH_PRODUCT_LINKS,
    productSlug,
  }

export const fetchProductLinksSuccess = (productSlug: string, links: ProductLinks) =>
  <const>{
    type: FETCH_PRODUCT_LINKS_SUCCESS,
    productSlug,
    links,
  }

export const linkMatchingProducts = (grid: GridType) =>
  <const>{
    type: LINK_MATCHING_PRODUCTS,
    grid,
  }

export const linkCrossDepartmentProducts = (grid: GridType) =>
  <const>{
    type: LINK_CROSS_DEPT_PRODUCTS,
    grid,
  }

export const removeMatchingProduct = (productSlug: string, productToRemove: string) =>
  <const>{
    type: REMOVE_MATCHING_PRODUCT,
    productSlug,
    productToRemove,
  }

export const removeCrossDepartmentProduct = (productSlug: string, productToRemove: string) =>
  <const>{
    type: REMOVE_CROSS_DEPARTMENT_PRODUCT,
    productSlug,
    productToRemove,
  }

export const addMatchingProductLink = (currentProduct: ProductWithDynabutes, identifier: string) =>
  <const>{
    type: ADD_MATCHING_PRODUCT_LINK,
    currentProduct,
    identifier,
  }

export const addCrossDepartmentLink = (currentProduct: ProductWithDynabutes, identifier: string) =>
  <const>{
    type: ADD_CROSS_DEPARTMENT_LINK,
    currentProduct,
    identifier,
  }
