import React from 'react'

import { CellType, IntentionalAny } from 'src/types/index'
import { NullCellRenderer } from '../components/cellRenderers/NullCellRenderer/NullCellRenderer'
import { DropdownCellRendererContainer } from '../components/cellRenderers/DropdownCellRenderer/DropdownCellRendererContainer'
import { MultiDropdownCellRendererContainer } from '../components/cellRenderers/MultiDropdownCellRenderer/MultiDropdownCellRendererContainer'
import { TextAreaCellRenderer } from '../components/cellRenderers/TextAreaCellRenderer/TextAreaCellRenderer'
import { LongTextAreaCellRenderer } from '../components/cellRenderers/LongTextAreaCellRenderer/LongTextAreaCellRenderer'
import { DatePickerCellRenderer } from '../components/cellRenderers/DatePickerCellRenderer/DatePickerCellRenderer'
import { CheckboxCellRenderer } from '../components/cellRenderers/CheckboxCellRenderer/CheckboxCellRenderer'
import { CurrencyCellRenderer } from '../components/cellRenderers/CurrencyCellRenderer/CurrencyCellRenderer'
import { ActionsCellRenderer } from '../components/cellRenderers/ActionsCellRenderer'
import { DevelopmentIdCellRendererContainer } from '../components/cellRenderers/DevelopmentIdCellRenderer/DevelopmentIdCellRendererContainer'
import { HierarchyCellRendererContainer } from '../components/cellRenderers/HierarchyCellRenderer'
import { ImageCellRendererContainer } from '../components/cellRenderers/ImageCellRenderer/ImageCellRendererContainer'
import { StatusCellRendererContainer } from '../components/cellRenderers/StatusCellRenderer/StatusCellRendererContainer'
import { CurrencyDropdownCellRendererContainer } from '../components/cellRenderers/DropdownCellRenderer/currency/CurrencyDropdownCellRendererContainer'
import {
  SupplierDropdownCellRenderer,
  WsReadyWeekDropdownCellRenderer,
} from '../components/cellRenderers/DropdownCellRenderer'
import { MultiCurrencyCellRendererContainer } from '../components/cellRenderers/MultiCurrencyCellRenderer/MultiCurrencyCellRendererContainer'
import { CountryCodeDropdownCellRendererContainer } from '../components/cellRenderers/DropdownCellRenderer/countryCode/CountryCodeDropdownCellRendererContainer'
import { SeasonDropdownCellRendererContainer } from '../components/cellRenderers/DropdownCellRenderer/season/SeasonDropdownCellRendererContainer'
import { SizeCellRendererContainer } from '../components/cellRenderers/SizeCellRenderer/SizeCellRendererContainer'
import { PriceCellRendererContainer } from 'src/components/cellRenderers/PriceCellRenderer/PriceCellRendererContainer'
import { StyleIdCellRendererContainer } from '../components/cellRenderers/StyleIdCellRenderer/StyleIdCellRendererContainer'
import { ProductNumberCellRendererContainer } from 'components/cellRenderers/ProductNumberCellRenderer/ProductNumberCellRendererContainer'
import { DateTodayCellRenderer } from 'components/cellRenderers/DateTodayCellRenderer/DateTodayCellRenderer'

export type ComponentRegistryEntry = {
  type: CellType
  renderer: React.ComponentType<IntentionalAny>
}

const listCellRegistryEntry = {
  type: 'list',
  renderer: DropdownCellRendererContainer,
}

const multiListCellRegistryEntry = {
  type: 'multiList',
  renderer: MultiDropdownCellRendererContainer,
}

const textCellRegistryEntry = {
  type: 'text',
  renderer: TextAreaCellRenderer,
}

const longTextCellRegistryEntry = {
  type: 'longText',
  renderer: LongTextAreaCellRenderer,
}

const dateCellRegistryEntry = {
  type: 'date',
  renderer: DatePickerCellRenderer,
}

const booleanCellRegistryEntry = {
  type: 'boolean',
  renderer: CheckboxCellRenderer,
}

const currencyCellRegistryEntry = {
  type: 'currency',
  renderer: MultiCurrencyCellRendererContainer,
}

const actionsCellRegistryEntry = {
  type: 'actions',
  renderer: ActionsCellRenderer,
}

const developmentIdCellRegistryEntry = {
  type: 'developmentId',
  renderer: DevelopmentIdCellRendererContainer,
}

const styleIdCellRegistryEntry = {
  type: 'styleID',
  renderer: StyleIdCellRendererContainer,
}

const productNumberCellRegistryEntry = {
  type: 'productNumber',
  renderer: ProductNumberCellRendererContainer,
}

const hierarchySlugCellRegistryEntry = {
  type: 'hierarchySlug',
  renderer: HierarchyCellRendererContainer,
}

const imagesCellRegistryEntry = {
  type: 'images',
  renderer: ImageCellRendererContainer,
}

const statusCellRegistryEntry = {
  type: 'status',
  renderer: StatusCellRendererContainer,
}

const supplierCellRegistryEntry = {
  type: 'supplier',
  renderer: SupplierDropdownCellRenderer,
}

const userNameCellRegistryEntry = {
  type: 'userName',
  renderer: TextAreaCellRenderer,
}

const sellingPriceCellRegistryEntry = {
  type: 'sellingPrice',
  renderer: CurrencyDropdownCellRendererContainer,
}

const currentFullPriceCellRegistryEntry = {
  type: 'currentFullPrice',
  renderer: CurrencyCellRenderer,
}

const promoPriceCellRegistryEntry = {
  type: 'promoPrice',
  renderer: CurrencyCellRenderer,
}

const countryCodeCellRegistryEntry = {
  type: 'countryCode',
  renderer: CountryCodeDropdownCellRendererContainer,
}

const seasonCellRegistryEntry = {
  type: 'seasonCode',
  renderer: SeasonDropdownCellRendererContainer,
}

const nullCellRegistryEntry = {
  type: 'error',
  renderer: NullCellRenderer,
}

const sizeCellRegistryEntry = {
  type: 'size',
  renderer: SizeCellRendererContainer,
}

const wsReadyWeekRegistryEntry = {
  type: 'wsReadyWeek',
  renderer: WsReadyWeekDropdownCellRenderer,
}

const sellingPriceCellRegistryEntryR3 = {
  type: 'sellingPriceView',
  renderer: PriceCellRendererContainer,
}

const supplierCostRegistryEntry = {
  type: 'supplierCostView',
  renderer: PriceCellRendererContainer,
}

const landedCostRegistryEntry = {
  type: 'landedCostView',
  renderer: PriceCellRendererContainer,
}

const promoPriceCellRegistryEntryR3 = {
  type: 'promoPriceView',
  renderer: PriceCellRendererContainer,
}

const currentFullPriceCellRegistryEntryR3 = {
  type: 'currentFullPriceView',
  renderer: PriceCellRendererContainer,
}

const dateTodayCellRegistryEntry = {
  type: 'dateToday',
  renderer: DateTodayCellRenderer,
}

export const componentRegistryLookup = (type: CellType): ComponentRegistryEntry => {
  const dynabuteCellRegistry = {
    list: listCellRegistryEntry,
    multiList: multiListCellRegistryEntry,
    text: textCellRegistryEntry,
    longText: longTextCellRegistryEntry,
    date: dateCellRegistryEntry,
    boolean: booleanCellRegistryEntry,
    currency: currencyCellRegistryEntry,
    dateToday: dateTodayCellRegistryEntry,
  }

  const entityCellRegistry = {
    actions: actionsCellRegistryEntry,
    developmentId: developmentIdCellRegistryEntry,
    productNumber: productNumberCellRegistryEntry,
    hierarchySlug: hierarchySlugCellRegistryEntry,
    images: imagesCellRegistryEntry,
    styleID: styleIdCellRegistryEntry,
    status: statusCellRegistryEntry,
    supplier: supplierCellRegistryEntry,
    userName: userNameCellRegistryEntry,
    sellingPrice: sellingPriceCellRegistryEntry,
    currentFullPrice: currentFullPriceCellRegistryEntry,
    promoPrice: promoPriceCellRegistryEntry,
    countryCode: countryCodeCellRegistryEntry,
    seasonCode: seasonCellRegistryEntry,
    sizes: sizeCellRegistryEntry,
    wsReadyWeek: wsReadyWeekRegistryEntry,
    supplierCostView: supplierCostRegistryEntry,
    landedCostView: landedCostRegistryEntry,
    sellingPriceView: sellingPriceCellRegistryEntryR3,
    promoPriceView: promoPriceCellRegistryEntryR3,
    currentFullPriceView: currentFullPriceCellRegistryEntryR3,
  }

  const errorCellRegistry = {
    error: nullCellRegistryEntry,
    noType: nullCellRegistryEntry,
  }

  const completeRegistry = {
    ...dynabuteCellRegistry,
    ...entityCellRegistry,
    ...errorCellRegistry,
  }

  // @ts-ignore
  return completeRegistry[type] || nullCellRegistryEntry
}
