import moment from 'moment'
import isNil from 'lodash/isNil'

import { ExcelMapperContext } from 'src/registry/behaviourRegistry'
import { CurrencyTypes, FlatHierarchyItem, IntentionalAny, Product } from 'src/types/index'
import {
  EURO_KEY,
  EURO_CURRENCY,
  POUND_KEY,
  POUND_CURRENCY,
  DOLLAR_KEY,
  DOLLAR_CURRENCY,
} from 'src/constants/currency'
import { READY_TO_BUY } from 'src/constants/productStatus'
import { Image } from 'src/types/Images'
import { DEFAULT_GROUP, MINI_EXT_GROUP, MINI_GROUP, TEEN_GROUP } from 'src/constants/productSizes'
import {
  CURRENT_FULL_PRICE,
  LANDED_COST,
  PROMO_PRICE,
  SELLING_PRICE,
  SUPPLIER_COST,
  SUPPLIER_COST_VIEW,
} from 'src/constants/price'
import { PriceTypes } from 'src/types/Price'

const dateFormat = 'DD-MMM-YY'

export type PriceAttributionWithCurrency = Partial<Record<PriceTypes, string>>

export const defaultExcelMapper = (value: IntentionalAny): string => (isNil(value) ? '' : value)

export const countryCodeExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : value.name

export const imagesExcelMapper = (value: IntentionalAny): string => {
  return isNil(value) ? '' : value.map((image: Image) => image.url).join(', ')
}

export const booleanExcelMapper = (value: IntentionalAny): string => (value ? 'Yes' : 'No')

export const numericExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : `${codesToPrefixes.GBP}${value.toFixed(2)}`

export const shiftedNumericExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : `${codesToPrefixes.GBP}${(value / 1000).toFixed(2)}`

export const dateExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : moment(value).format(dateFormat)

export const listExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : value.join(', ')

export const supplierExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : `${value.description} - ${value.code}`

export const currencyExcelMapper = (value: IntentionalAny): string =>
  isNil(value) ? '' : `${codesToPrefixes[value.currency]}${value.value.toFixed(2)}`

export const hierarchyExcelMapper = (value: IntentionalAny, context: ExcelMapperContext): string =>
  formatHierarchyData(context.hierarchyLookupTable[value])

export const sizesExcelMapper = (_: IntentionalAny, context: ExcelMapperContext): string => {
  const sizesSpan = context.product.sizesSpan
  return isNil(sizesSpan.minSeqSizeName) && isNil(sizesSpan.maxSeqSizeName)
    ? ''
    : sizesSpan.minSeqSizeName === sizesSpan.maxSeqSizeName
    ? sizesSpan.minSeqSizeName
    : `${sizesSpan.minSeqSizeName} - ${sizesSpan.maxSeqSizeName}`
}

export const wsReadyWeekExcelMapper = (_: IntentionalAny, context: ExcelMapperContext): string =>
  context.product.wsReadyWeek || ''

export const productNumberExcelMapper = (
  _: IntentionalAny,
  context: ExcelMapperContext,
): string => {
  const product = context.product
  if (product.status === READY_TO_BUY || product.statusBeforeCancelled === READY_TO_BUY) {
    return extractProductNumber(product)
  }
  return ''
}

export const noMapping = (): string => ''

export const styleIdExcelMapper = (_: IntentionalAny, context: ExcelMapperContext): string =>
  context.product.styleID

export const extractProductNumber = (product: Product): string => {
  const sortedProductNumbers = product.productNumberView
    ? [product.productNumberView.default].filter((pn) => !!pn)
    : []
  return sortedProductNumbers.join(',')
}

export const extractProductNumberExt1 = (product: Product): string => {
  const sortedProductNumbers = product.productNumberView
    ? [product.productNumberView.M, product.productNumberView.T].filter((pn) => !!pn)
    : []

  return sortedProductNumbers.join(',')
}

export const extractProductNumberExt2 = (product: Product): string => {
  const sortedProductNumbers = product.productNumberView
    ? [product.productNumberView.O].filter((pn) => !!pn)
    : []

  return sortedProductNumbers.join(',')
}

const formatHierarchyData = ({ title, levelCode }: FlatHierarchyItem): string =>
  `${title}, ${levelCode}`

export const codesToPrefixes: Record<string, CurrencyTypes> = {
  [EURO_KEY]: EURO_CURRENCY,
  [POUND_KEY]: POUND_CURRENCY,
  [DOLLAR_KEY]: DOLLAR_CURRENCY,
}

export const sizeGroupPriceExcelMapper = (
  value: IntentionalAny = {},
  context: ExcelMapperContext,
  property: string,
): string => {
  if (!Object.keys(value).length) {
    return ''
  }
  const product: Product = context.product
  const currency =
    property === SUPPLIER_COST_VIEW
      ? codesToPrefixes[product.supplierCostCurrency || POUND_KEY]
      : POUND_CURRENCY

  if (value[DEFAULT_GROUP]) {
    return `${currency}${value[DEFAULT_GROUP]}`
  } else {
    return ''
  }
}

export const sizeGroupPriceExcelMapperNew = (product: Product): PriceAttributionWithCurrency => {
  if (
    (product.supplierCostView && product.supplierCostView[MINI_GROUP]) ||
    (product.landedCostView && product.landedCostView[MINI_GROUP]) ||
    (product.sellingPriceView && product.sellingPriceView[MINI_GROUP])
  ) {
    return {
      [SUPPLIER_COST]: `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
        product.supplierCostView[MINI_GROUP]
      }`,
      [LANDED_COST]: `${POUND_CURRENCY}${product.landedCostView[MINI_GROUP]}`,
      [SELLING_PRICE]: `${POUND_CURRENCY}${product.sellingPriceView[MINI_GROUP]}`,
      [CURRENT_FULL_PRICE]: `${POUND_CURRENCY}${product.currentFullPriceView[MINI_GROUP]}`,
      [PROMO_PRICE]: `${POUND_CURRENCY}${product.promoPriceView[MINI_GROUP]}`,
    }
  } else if (
    (product.supplierCostView && product.supplierCostView[TEEN_GROUP]) ||
    (product.landedCostView && product.landedCostView[TEEN_GROUP]) ||
    (product.sellingPriceView && product.sellingPriceView[TEEN_GROUP])
  ) {
    return {
      [SUPPLIER_COST]: `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
        product.supplierCostView[TEEN_GROUP]
      }`,
      [LANDED_COST]: `${POUND_CURRENCY}${product.landedCostView[TEEN_GROUP]}`,
      [SELLING_PRICE]: `${POUND_CURRENCY}${product.sellingPriceView[TEEN_GROUP]}`,
      [CURRENT_FULL_PRICE]: `${POUND_CURRENCY}${product.currentFullPriceView[TEEN_GROUP]}`,
      [PROMO_PRICE]: `${POUND_CURRENCY}${product.promoPriceView[TEEN_GROUP]}`,
    }
  } else {
    return {
      [SUPPLIER_COST]: '',
      [LANDED_COST]: '',
      [SELLING_PRICE]: '',
      [CURRENT_FULL_PRICE]: '',
      [PROMO_PRICE]: '',
    }
  }
}

export const sizeGroupPriceExcelMapperExt1 = (product: Product): PriceAttributionWithCurrency => {
  if (
    (product.supplierCostView && product.supplierCostView[MINI_GROUP]) ||
    (product.landedCostView && product.landedCostView[MINI_GROUP]) ||
    (product.sellingPriceView && product.sellingPriceView[MINI_GROUP])
  ) {
    return {
      [SUPPLIER_COST]: `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
        product.supplierCostView[MINI_GROUP]
      }`,
      [LANDED_COST]: `${POUND_CURRENCY}${product.landedCostView[MINI_GROUP]}`,
      [SELLING_PRICE]: `${POUND_CURRENCY}${product.sellingPriceView[MINI_GROUP]}`,
      [CURRENT_FULL_PRICE]: `${POUND_CURRENCY}${product.currentFullPriceView[MINI_GROUP]}`,
      [PROMO_PRICE]: `${POUND_CURRENCY}${product.promoPriceView[MINI_GROUP]}`,
    }
  } else if (
    (product.supplierCostView && product.supplierCostView[TEEN_GROUP]) ||
    (product.landedCostView && product.landedCostView[TEEN_GROUP]) ||
    (product.sellingPriceView && product.sellingPriceView[TEEN_GROUP])
  ) {
    return {
      [SUPPLIER_COST]: `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
        product.supplierCostView[TEEN_GROUP]
      }`,
      [LANDED_COST]: `${POUND_CURRENCY}${product.landedCostView[TEEN_GROUP]}`,
      [SELLING_PRICE]: `${POUND_CURRENCY}${product.sellingPriceView[TEEN_GROUP]}`,
      [CURRENT_FULL_PRICE]: `${POUND_CURRENCY}${product.currentFullPriceView[TEEN_GROUP]}`,
      [PROMO_PRICE]: `${POUND_CURRENCY}${product.promoPriceView[TEEN_GROUP]}`,
    }
  } else {
    return {
      [SUPPLIER_COST]: '',
      [LANDED_COST]: '',
      [SELLING_PRICE]: '',
      [CURRENT_FULL_PRICE]: '',
      [PROMO_PRICE]: '',
    }
  }
}

export const sizeGroupPriceExcelMapperExt2 = (product: Product): PriceAttributionWithCurrency => {
  if (
    (product.supplierCostView && product.supplierCostView[MINI_EXT_GROUP]) ||
    (product.landedCostView && product.landedCostView[MINI_EXT_GROUP]) ||
    (product.sellingPriceView && product.sellingPriceView[MINI_EXT_GROUP])
  ) {
    return {
      [SUPPLIER_COST]: `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
        product.supplierCostView[MINI_EXT_GROUP]
      }`,
      [LANDED_COST]: `${POUND_CURRENCY}${product.landedCostView[MINI_EXT_GROUP]}`,
      [SELLING_PRICE]: `${POUND_CURRENCY}${product.sellingPriceView[MINI_EXT_GROUP]}`,
      [CURRENT_FULL_PRICE]: `${POUND_CURRENCY}${product.currentFullPriceView[MINI_EXT_GROUP]}`,
      [PROMO_PRICE]: `${POUND_CURRENCY}${product.promoPriceView[MINI_EXT_GROUP]}`,
    }
  } else {
    return {
      [SUPPLIER_COST]: '',
      [LANDED_COST]: '',
      [SELLING_PRICE]: '',
      [CURRENT_FULL_PRICE]: '',
      [PROMO_PRICE]: '',
    }
  }
}
