import { Product, RequestStatus } from 'src/types/index'
import { normaliseProducts } from 'src/service/mappers/index'
import { FETCH_PRODUCT_COMMENTS_SUCCESS } from 'src/actions/comments'
import {
  FETCH_COMPLETED_POLL_PRODUCTS_SUCCESSFUL,
  FETCH_FIRST_PRODUCTS_PAGE,
  FETCH_NEXT_PRODUCTS_PAGE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_PAGE_SUCCESS,
  REFETCH_CURRENT_PRODUCTS_PAGE,
} from 'src/actions/grid/product/fetch'
import { ADD_EXACT_FILTER, REMOVE_EXACT_FILTER } from 'src/actions/grid/exactFilters'
import { ADD_PARTIAL_FILTER, REMOVE_PARTIAL_FILTER } from 'src/actions/grid/partialFilters'
import { UPDATE_SORTED_COLUMNS } from 'src/actions/grid/sortedColumns'
import { CLEAR_ALL_FILTERS, REFRESH_FILTERS } from 'src/actions/grid/filters'
import {
  ERROR_STATUS,
  FETCHING_STATUS,
  READY_STATUS,
  UNINITIALISED_STATUS,
} from 'src/constants/requests'
import { UPDATE_PRODUCT_SIZES_SUCCESS } from 'src/actions/grid/product/sizes'
import { ProductMap } from 'src/types/Product'
import { DISMISS_EVENT_ERROR_SUCCESS } from 'src/actions/grid/product/eventErrors'
import {
  LOCK_PRODUCT,
  UNLOCK_PRODUCT,
  UPDATE_LIST_OF_DEPRECATED_PRODUCT_FIELDS,
  UPDATE_LIST_OF_INVALID_PRODUCT_FIELDS,
  UPDATE_PRODUCT_STATUS,
  UPDATE_PRODUCT_STATUS_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
} from 'actions/grid/product/update'
import { SVPAction } from 'actions/svp'
import { DUPLICATE_PRODUCT_SUCCESS } from 'actions/grid/product/copy'

export type ProductsState = {
  status: RequestStatus
  productsMap: ProductMap
  slugs: string[]
  invalidProductFields: { [key: string]: string[] }
  deprecatedProductFields: { [key: string]: string[] }
  pagination: {
    lastPage?: number
    lastPageLoaded?: number
    pageLoading?: boolean
  }
  error: string
}

const defaultState: ProductsState = {
  status: UNINITIALISED_STATUS,
  productsMap: {},
  slugs: [],
  invalidProductFields: {},
  deprecatedProductFields: {},
  pagination: {},
  error: '',
}

const updateProduct = (
  products: ProductMap,
  identifier: string,
  patchFields: Partial<Product>,
) => ({
  ...products,
  ...(products[identifier]
    ? {
        [identifier]: {
          ...products[identifier],
          ...patchFields,
        },
      }
    : {}),
})

const updateInvalidFields = (
  invalidFields: Record<string, string[]>,
  identifier: string,
  patchFields: Partial<Product>,
) =>
  invalidFields[identifier]
    ? {
        ...invalidFields,
        [identifier]: invalidFields[identifier].filter(
          (invalidField) => !Object.keys(patchFields).includes(invalidField),
        ),
      }
    : invalidFields

const updateDeprecatedFields = (
  deprecatedFields: Record<string, string[]>,
  identifier: string,
  patchFields: Partial<Product>,
) =>
  deprecatedFields && deprecatedFields[identifier]
    ? {
        ...deprecatedFields,
        [identifier]: deprecatedFields[identifier].filter(
          (deprecatedField) => !Object.keys(patchFields).includes(deprecatedField),
        ),
      }
    : deprecatedFields

const updateProductCommentCount = (products: ProductMap, identifier: string, count: number) =>
  updateProduct(products, identifier, { commentCount: count })

const insertIdentifier = (
  identifiers: string[],
  sourceIdentifier: string,
  newIdentifer: string,
) => [
  ...identifiers.slice(0, identifiers.indexOf(sourceIdentifier) + 1),
  newIdentifer,
  ...identifiers.slice(identifiers.indexOf(sourceIdentifier) + 1),
]

const dismissEventError = (products: ProductMap, identifier: string, eventErrorSlug: string) => {
  const eventErrors = products[identifier].eventErrors?.filter(
    (eventError) => eventError.id !== eventErrorSlug,
  )
  return updateProduct(products, identifier, { eventErrors })
}

const uniqueSlugs = (stateSlugs: string[], pageSlugs: string[]) =>
  pageSlugs.filter((pageSlug: string) => stateSlugs.indexOf(pageSlug) === -1)

export const products = (state: ProductsState = defaultState, action: SVPAction): ProductsState => {
  switch (action.type) {
    case ADD_EXACT_FILTER:
    case REMOVE_EXACT_FILTER:
    case ADD_PARTIAL_FILTER:
    case REMOVE_PARTIAL_FILTER:
    case CLEAR_ALL_FILTERS:
    case REFRESH_FILTERS:
    case UPDATE_SORTED_COLUMNS:
    case FETCH_FIRST_PRODUCTS_PAGE:
    case REFETCH_CURRENT_PRODUCTS_PAGE:
      return {
        ...state,
        productsMap: {},
        slugs: [],
        status: FETCHING_STATUS,
      }
    case FETCH_NEXT_PRODUCTS_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageLoading: true,
        },
      }
    case FETCH_PRODUCTS_PAGE_SUCCESS: {
      const pageProducts = normaliseProducts(action.payload)
      const pageSlugs = uniqueSlugs(state.slugs, pageProducts.slugs)
      return {
        ...state,
        pagination: {
          lastPage: action.lastPage,
          lastPageLoaded: action.lastPageLoaded,
          pageLoading: false,
        },
        status: READY_STATUS,
        productsMap: { ...state.productsMap, ...pageProducts.productsMap },
        slugs: [...state.slugs, ...pageSlugs],
      }
    }

    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        status: ERROR_STATUS,
        error: action.error,
      }

    case UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        invalidProductFields: updateInvalidFields(
          state.invalidProductFields,
          action.productSlug,
          action.patchFields,
        ),
        deprecatedProductFields: updateDeprecatedFields(
          state.deprecatedProductFields,
          action.productSlug,
          action.patchFields,
        ),
        productsMap: updateProduct(state.productsMap, action.productSlug, {
          ...action.patchFields,
          statusUpdating: false,
        }),
      }
    }

    case FETCH_COMPLETED_POLL_PRODUCTS_SUCCESSFUL: {
      const pageProducts = normaliseProducts(action.payload)
      return {
        ...state,
        productsMap: { ...state.productsMap, ...pageProducts.productsMap },
      }
    }

    case UPDATE_LIST_OF_INVALID_PRODUCT_FIELDS: {
      return {
        ...state,
        invalidProductFields: {
          ...state.invalidProductFields,
          [action.payload.productSlug]: action.payload.invalidFields,
        },
      }
    }

    case UPDATE_LIST_OF_DEPRECATED_PRODUCT_FIELDS: {
      return {
        ...state,
        deprecatedProductFields: {
          ...state.deprecatedProductFields,
          [action.payload.productSlug]: action.payload.deprecatedFields,
        },
      }
    }

    case FETCH_PRODUCT_COMMENTS_SUCCESS:
      return {
        ...state,
        productsMap: updateProductCommentCount(
          state.productsMap,
          action.productSlug,
          action.comments.length,
        ),
      }

    case DUPLICATE_PRODUCT_SUCCESS: {
      const { newProduct, sourceProductSlug } = action.payload
      return {
        ...state,
        productsMap: { ...state.productsMap, [newProduct.slug]: newProduct },
        slugs: insertIdentifier(state.slugs, sourceProductSlug, newProduct.slug),
      }
    }

    case UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        productsMap: updateProduct(state.productsMap, action.payload.product.slug, {
          statusUpdating: true,
        }),
      }
    case UPDATE_PRODUCT_STATUS_FAILURE:
      return {
        ...state,
        productsMap: updateProduct(state.productsMap, action.productSlug, {
          statusUpdating: false,
        }),
      }
    case UPDATE_PRODUCT_SIZES_SUCCESS:
      return {
        ...state,
        productsMap: updateProduct(state.productsMap, action.payload.productSlug, {}),
      }
    case DISMISS_EVENT_ERROR_SUCCESS:
      return {
        ...state,
        productsMap: dismissEventError(
          state.productsMap,
          action.payload.productSlug,
          action.payload.errorID,
        ),
      }
    case LOCK_PRODUCT:
      return {
        ...state,
        productsMap: updateProduct(state.productsMap, action.slug, {
          locked: true,
        }),
      }
    case UNLOCK_PRODUCT:
      return {
        ...state,
        productsMap: updateProduct(state.productsMap, action.slug, {
          locked: false,
        }),
      }
    default:
      return state
  }
}
