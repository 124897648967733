export const COPY_TO_ENDPOINT = 'products/copy-to'
export const MARK_AS_PROPOSED = 'products/mark-as-proposed'
export const MARK_AS_RTB_ENDPOINT = 'mark-as-ready-to-buy'
export const MARK_AS_CANCELLED_ENDPOINT = 'mark-as-cancelled'
export const BULK_MARK_AS_RTB = 'products/mark-as-ready-to-buy/bulk'
export const LOCK = 'lock'
export const POLL = 'productEvents/pending'
export const RECLASSIFICATION = 'reclassification'
export const VIEWS_ENDPOINT = 'views'
export const HIERARCHY_ENDPOINT = 'hierarchy'
export const ATTRIBUTES_ENDPOINT = 'attributes'
export const SUPPLIERS_ENDPOINT = 'suppliers'
export const SELLING_PRICED_ENDPOINT = 'selling-prices'
export const COLUMNS_ENDPOINT = 'columns'
export const COUNTRIES_ENDPOINT = 'countries'
export const SEASONS_ENDPOINT = 'seasons'
