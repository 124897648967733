import { showErrorNotification } from 'actions/notification'
import { SVPAction } from 'actions/svp'
import {
  GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES,
  UPDATE_DEFAULT_ATTRIBUTE_VALUES,
  getDepartmentRangesWithDefaultAttributesDone,
  getDepartmentRangesWithDefaultAttributesFetching,
  getDepartmentRangesWithDefaultAttributesSuccess,
  updateDefaultAttributeValuesSuccess,
} from 'actions/userSettings/defaultAttributes'
import { AllEffect, all, call, put, select, takeEvery } from 'redux-saga/effects'
import { SVPSaga } from 'sagas'
import { getListAttributesWithColumnName } from 'selectors/referenceData/attributes'
import { logError } from 'service/errors'
import { injectorService } from 'service/injector/injectorService'
import { AttributeWithColumnName, AttributeWithColumnNameMap } from 'types'
import {
  DefaultAttributesColumns,
  RangesWithDefaultAttributesApiResponse,
} from 'types/UserSettings'
import { ApiDepartmentSpec } from 'types/responses/departmentSpec'

export function* getDepartmentRangesWithDefaultAttributesSaga(
  action: SVPAction<typeof GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES>,
): SVPSaga {
  try {
    yield put(getDepartmentRangesWithDefaultAttributesFetching())
    const attributesWithColName = (yield select(getListAttributesWithColumnName)) as Record<
      string,
      AttributeWithColumnName
    >

    const response = (yield call(
      injectorService.get,
      `department/${action.slug}/attribute-defaults`,
    )) as RangesWithDefaultAttributesApiResponse

    const departmentAttributes = (yield call(
      injectorService.get,
      `department-specifications/${action.slug}`,
    )) as ApiDepartmentSpec

    // Get available list/multilist attributes for department
    const departmentAttrsWithColumnName: AttributeWithColumnNameMap = {}
    departmentAttributes.attributes.forEach((attribute) => {
      if (attribute.views?.length && attributesWithColName[attribute.name]) {
        departmentAttrsWithColumnName[attribute.name] = attributesWithColName[attribute.name]
      }
    })

    // Determine existing columns by extracting the unique values from the set attributes.
    const defaultAttributesColumns: DefaultAttributesColumns[] = []
    const { attributes, ranges } = response
    if (attributes.length) {
      const seen: Record<string, boolean> = {}
      attributes.forEach((attribute) => {
        if (!seen[attribute.attributeSlug]) {
          defaultAttributesColumns.push({
            attributeName: attribute.attributeName,
            attributeSlug: attribute.attributeSlug,
            attributeDisplayName: attributesWithColName[attribute.attributeName].displayName || '',
            attributeType: attributesWithColName[attribute.attributeName].type,
          })
          seen[attribute.attributeSlug] = true
        }
      })
    }
    yield put(
      getDepartmentRangesWithDefaultAttributesSuccess(
        response.attributes,
        ranges,
        defaultAttributesColumns,
        departmentAttrsWithColumnName,
      ),
    )
  } catch (error) {
    yield put(getDepartmentRangesWithDefaultAttributesDone())
    yield call(logError, error as Error)
    yield put(
      showErrorNotification('Could not retrieve default attributes for selected department'),
    )
  }
}

export function* updateDefaultAttributeValuesSaga(
  action: SVPAction<typeof UPDATE_DEFAULT_ATTRIBUTE_VALUES>,
): SVPSaga {
  try {
    yield call(injectorService.put, `attribute-defaults-set`, {
      attributeSlug: action.attributeSlug,
      rangeSlug: action.rangeSlug,
      defaultValues: action.valueSlugs,
    })
    yield put(
      updateDefaultAttributeValuesSuccess(
        action.valueSlugs,
        action.attributeName,
        action.attributeSlug,
        action.rangeSlug,
      ),
    )
  } catch (error) {
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could not update attribute with default values'))
  }
}

export default function* (): Generator<AllEffect> {
  yield all([
    takeEvery(
      GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES,
      getDepartmentRangesWithDefaultAttributesSaga,
    ),
    takeEvery(UPDATE_DEFAULT_ATTRIBUTE_VALUES, updateDefaultAttributeValuesSaga),
  ])
}
