import React from 'react'
import { button, green } from './primaryButton.scss'
import { setFocus } from 'src/service/ref'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  type?: 'button' | 'submit'
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
  className?: string
  children: React.ReactNode | string | undefined
  hasFocus?: boolean
  colour?: 'green' | 'black'
  dataCy?: string
  htmlFor?: string
  disabled?: boolean
}

export const PrimaryButton = (props: Props) => {
  const { children, hasFocus, colour = 'black', dataCy, ...rest } = props
  const className = `${button} ${colour === 'green' ? green : ''} ${props.className || ''}`.trim()
  return (
    <button
      data-cy={dataCy}
      {...rest}
      className={className}
      ref={(node) => (hasFocus ? setFocus(node) : '')}
    >
      {children}
    </button>
  )
}

export default PrimaryButton
