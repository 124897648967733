import { connect } from 'react-redux'

import {
  getAdminDepartmentsSpecs,
  addDepartmentAttribute,
  updateDepartmentAttributeRules,
  updateDepartmentAttributeViews,
} from 'src/actions/admin/departments'
import { AppState } from 'src/reducers/root'
import {
  areAdminDepartmentSpecsReady,
  getDepartmentSpecifications,
  getSelectedAttributeForDepartment,
} from 'src/selectors/admin/departments'
import { DepartmentDisplay } from './DepartmentDisplay'

const mapStateToProps = (state: AppState) => ({
  ready: areAdminDepartmentSpecsReady(state),
  selectedAttribute: getSelectedAttributeForDepartment(state),
  departmentSpecification: getDepartmentSpecifications(state),
})

const mapDispatchToProps = {
  getAdminDepartmentsSpecs,
  addDepartmentAttribute,
  updateDepartmentAttributeRules,
  updateDepartmentAttributeViews,
}

export const DepartmentDisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepartmentDisplay)
