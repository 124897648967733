import { GridType, Option } from 'src/types/index'
import { Style, StyleResult } from 'src/types/Style'
import { ProductWithDynabutes } from 'src/types/Product'

export const MERGE_OPTIONS = 'MERGE_OPTIONS'
export const MERGE_STYLES = 'MERGE_STYLES'
export const FETCH_STYLE = 'FETCH_STYLE'
export const FETCH_STYLE_SUCCESS = 'FETCH_STYLE_SUCCESS'
export const FETCH_STYLE_FAILURE = 'FETCH_STYLE_FAILURE'
export const DETACH_PRODUCT = 'DETACH_PRODUCT'
export const DETACH_SELECTED_PRODUCT = 'DETACH_SELECTED_PRODUCT'
export const DETACH_OPTION = 'DETACH_OPTION'
export const DETACH_SELECTED_OPTION = 'DETACH_SELECTED_OPTION'
export const ADD_COLOUR_VARIANT = 'ADD_COLOUR_VARIANT'
export const ADD_OPTION_LINK_LINE = 'ADD_OPTION_LINK_LINE'
export const SAVE_STYLE_DESCRIPTION = 'SAVE_STYLE_DESCRIPTION'
export const SEARCH_STYLE = 'SEARCH_STYLE'
export const SEARCH_STYLE_SUCCESS = 'SEARCH_STYLE_SUCCESS'
export const CONFIRM_PRODUCT_STYLE = 'CONFIRM_PRODUCT_STYLE'
export const SEARCH_STYLE_REQUEST = 'SEARCH_STYLE_REQUEST'
export const CONFIRM_PRODUCT_STYLE_REQUEST = 'CONFIRM_PRODUCT_STYLE_REQUEST'

export type StyleActions = ReturnType<
  | typeof mergeOptions
  | typeof mergeStyles
  | typeof fetchStyle
  | typeof fetchStyleSuccess
  | typeof fetchStyleFailure
  | typeof detachProduct
  | typeof detachSelectedProduct
  | typeof detachOption
  | typeof detachSelectedOption
  | typeof addColourVariant
  | typeof addOptionLinkLine
  | typeof saveStyleDescription
  | typeof searchStyle
  | typeof searchStyleSuccess
  | typeof confirmProductStyle
  | typeof searchStyleRequest
  | typeof confirmProductStyleRequest
>

export const mergeOptions = (grid: GridType) =>
  <const>{
    type: MERGE_OPTIONS,
    grid,
  }

export const mergeStyles = (grid: GridType) =>
  <const>{
    type: MERGE_STYLES,
    grid,
  }

export const fetchStyle = (styleSlug: string) =>
  <const>{
    type: FETCH_STYLE,
    styleSlug,
  }

export const fetchStyleSuccess = (style: Style) =>
  <const>{
    type: FETCH_STYLE_SUCCESS,
    style,
  }

export const fetchStyleFailure = () =>
  <const>{
    type: FETCH_STYLE_FAILURE,
  }

export const detachProduct = (productSlug: string, styleSlug: string) =>
  <const>{
    type: DETACH_PRODUCT,
    productSlug,
    styleSlug,
  }

export const detachSelectedProduct = (productToDetach: ProductWithDynabutes) =>
  <const>{
    type: DETACH_SELECTED_PRODUCT,
    productToDetach,
  }

export const detachOption = (optionSlug: string, styleSlug: string) =>
  <const>{
    type: DETACH_OPTION,
    optionSlug,
    styleSlug,
  }

export const detachSelectedOption = (optionToDetach: Option) =>
  <const>{
    type: DETACH_SELECTED_OPTION,
    optionToDetach,
  }

export const addColourVariant = (currentProduct: ProductWithDynabutes, identifier: string) =>
  <const>{
    type: ADD_COLOUR_VARIANT,
    currentProduct,
    identifier,
  }

export const addOptionLinkLine = (currentProduct: ProductWithDynabutes, identifier: string) =>
  <const>{
    type: ADD_OPTION_LINK_LINE,
    currentProduct,
    identifier,
  }

export const saveStyleDescription = (styleSlug: string, styleDescription: string) =>
  <const>{
    type: SAVE_STYLE_DESCRIPTION,
    styleSlug,
    styleDescription,
  }

export type SearchStylePayload = {
  departmentSlug: string
  styleGroupSlug: string
  styleID: string
  styleDescription: string
  styleName: string
  productPrint: string
}

export const searchStyle = (payload: SearchStylePayload) =>
  <const>{
    type: SEARCH_STYLE,
    payload,
  }

export const searchStyleSuccess = (results: StyleResult[]) =>
  <const>{
    type: SEARCH_STYLE_SUCCESS,
    results,
  }

export const searchStyleRequest = () =>
  <const>{
    type: SEARCH_STYLE_REQUEST,
  }

export const confirmProductStyleRequest = () =>
  <const>{
    type: CONFIRM_PRODUCT_STYLE_REQUEST,
  }

export const confirmProductStyle = (
  optionSlug: string,
  optionProducts: ProductWithDynabutes[],
  styleSlug: string,
) =>
  <const>{
    type: CONFIRM_PRODUCT_STYLE,
    optionSlug,
    optionProducts,
    styleSlug,
  }
