import { WholesaleImage, WholesaleImageResequencePayload } from 'src/types/WholesaleImages'

export const UPLOAD_WHOLESALE_IMAGE_FILE = 'UPLOAD_WHOLESALE_IMAGE_FILE'
export const GET_WHOLESALE_IMAGES = 'GET_WHOLESALE_IMAGES'
export const GET_WHOLESALE_IMAGES_SUCCESS = 'GET_WHOLESALE_IMAGES_SUCCESS'
export const CLEAR_WHOLESALE_IMAGES = 'CLEAR_WHOLESALE_IMAGES'
export const UPDATE_WHOLESALE_IMAGES_SEQUENCE = 'UPDATE_WHOLESALE_IMAGES_SEQUENCE'
export const UPDATE_WHOLESALE_IMAGES_SEQUENCE_SUCCESS = 'UPDATE_WHOLESALE_IMAGES_SEQUENCE_SUCCESS'
export const REPLACE_WHOLESALE_IMAGE_FILE = 'REPLACE_WHOLESALE_IMAGE_FILE'
export const REMOVE_WHOLESALE_IMAGE_FILE = 'REMOVE_WHOLESALE_IMAGE_FILE'
export const SET_WHOLESALE_IMAGES_LOADING = 'SET_WHOLESALE_IMAGES_LOADING'

export type WholesaleImagesActions = ReturnType<
  | typeof uploadWholesaleImageFile
  | typeof getWholesaleImages
  | typeof getWholesaleImagesSuccess
  | typeof clearWholesaleImages
  | typeof updateWholesaleImagesSequence
  | typeof updateWholesaleImagesSequenceSuccess
  | typeof replaceWholesaleImageFile
  | typeof removeWholesaleImageFile
  | typeof setWholesaleImagesLoading
>

export const uploadWholesaleImageFile = (
  productSlug: string,
  developmentId: string,
  files: File[],
  sequence: number,
) =>
  <const>{
    productSlug,
    developmentId,
    files,
    sequence,
    type: UPLOAD_WHOLESALE_IMAGE_FILE,
  }

export const getWholesaleImages = (productSlug: string, imagesToRefresh: number[]) =>
  <const>{
    type: GET_WHOLESALE_IMAGES,
    productSlug,
    imagesToRefresh,
  }

export const getWholesaleImagesSuccess = (images: WholesaleImage[], imagesToRefresh: number[]) =>
  <const>{
    type: GET_WHOLESALE_IMAGES_SUCCESS,
    images,
    imagesToRefresh,
  }

export const clearWholesaleImages = () =>
  <const>{
    type: CLEAR_WHOLESALE_IMAGES,
  }

export const updateWholesaleImagesSequence = (
  productSlug: string,
  payload: WholesaleImageResequencePayload[],
) =>
  <const>{
    type: UPDATE_WHOLESALE_IMAGES_SEQUENCE,
    productSlug,
    payload,
  }

export const updateWholesaleImagesSequenceSuccess = (payload: WholesaleImageResequencePayload[]) =>
  <const>{
    type: UPDATE_WHOLESALE_IMAGES_SEQUENCE_SUCCESS,
    payload,
  }

export const replaceWholesaleImageFile = (
  slug: string,
  productSlug: string,
  sequence: number,
  file: File,
) =>
  <const>{
    type: REPLACE_WHOLESALE_IMAGE_FILE,
    slug,
    productSlug,
    sequence,
    file,
  }

export const removeWholesaleImageFile = (slug: string, productSlug: string) =>
  <const>{
    type: REMOVE_WHOLESALE_IMAGE_FILE,
    slug,
    productSlug,
  }

export const setWholesaleImagesLoading = () =>
  <const>{
    type: SET_WHOLESALE_IMAGES_LOADING,
  }
