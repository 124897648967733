import { ProductStatus } from 'src/types/ProductStatus'

export type UpdateProductActions = ReturnType<
  | typeof updateProduct
  | typeof updateProductSuccess
  | typeof updateProductHierarchy
  | typeof lockProduct
  | typeof unlockProduct
  | typeof updateListOfInvalidProductFields
  | typeof updateListOfDeprecatedProductFields
  | typeof updateProductStatus
  | typeof updateProductStatusFailure
>

import { GridType, Product, TreeHierarchyItem } from 'src/types/index'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'

export type PatchPayload = {
  productSlug: string
  patchFields: {
    api?: { [key: string]: any }
    store: { [key: string]: any }
  }
}

export const updateProduct = (payload: PatchPayload, grid: GridType) =>
  <const>{
    type: UPDATE_PRODUCT,
    payload,
    grid,
  }

export const updateProductSuccess = (
  productSlug: string,
  patchFields: { [key: string]: any },
  grid: GridType,
) =>
  <const>{
    type: UPDATE_PRODUCT_SUCCESS,
    productSlug,
    patchFields,
    grid,
  }

export const UPDATE_PRODUCT_HIERARCHY = 'UPDATE_PRODUCT_HIERARCHY'

export type UpdateProductHierarchyPayload = {
  developmentId: string
  productSlug: string
  hierarchy: TreeHierarchyItem
}

export const updateProductHierarchy = (payload: UpdateProductHierarchyPayload, grid: GridType) =>
  <const>{
    type: UPDATE_PRODUCT_HIERARCHY,
    payload,
    grid,
  }

export const LOCK_PRODUCT = 'LOCK_PRODUCT'

export const lockProduct = (slug: string, grid: GridType) =>
  <const>{
    type: LOCK_PRODUCT,
    slug,
    grid,
  }

export const UNLOCK_PRODUCT = 'UNLOCK_PRODUCT'

export const unlockProduct = (slug: string, grid: GridType) =>
  <const>{
    type: UNLOCK_PRODUCT,
    slug,
    grid,
  }

export const UPDATE_LIST_OF_INVALID_PRODUCT_FIELDS = 'UPDATE_LIST_OF_INVALID_PRODUCT_FIELDS'

type InvalidProductFieldsPayload = {
  productSlug: string
  invalidFields: Array<string>
}

export const updateListOfInvalidProductFields = (
  payload: InvalidProductFieldsPayload,
  grid: GridType,
) =>
  <const>{
    type: UPDATE_LIST_OF_INVALID_PRODUCT_FIELDS,
    payload,
    grid,
  }

export const UPDATE_LIST_OF_DEPRECATED_PRODUCT_FIELDS = 'UPDATE_LIST_OF_DEPRECATED_PRODUCT_FIELDS'

type DeprecatedProductFieldsPayload = {
  productSlug: string
  deprecatedFields: Array<string>
}

export const updateListOfDeprecatedProductFields = (
  payload: DeprecatedProductFieldsPayload,
  grid: GridType,
) =>
  <const>{
    type: UPDATE_LIST_OF_DEPRECATED_PRODUCT_FIELDS,
    payload,
    grid,
  }

export const UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS'
export const UPDATE_PRODUCT_STATUS_FAILURE = 'UPDATE_PRODUCT_STATUS_FAILURE'

export type UpdateProductStatusPayload = {
  newStatus: ProductStatus
  product: Product
}

export const updateProductStatus = (payload: UpdateProductStatusPayload, grid: GridType) =>
  <const>{
    type: UPDATE_PRODUCT_STATUS,
    payload,
    grid,
  }

export const updateProductStatusFailure = (productSlug: string, grid: GridType) =>
  <const>{
    type: UPDATE_PRODUCT_STATUS_FAILURE,
    productSlug,
    grid,
  }
