import {
  SUPPLIER_COST,
  LANDED_COST,
  SELLING_PRICE,
  CURRENT_FULL_PRICE,
  PROMO_PRICE,
} from 'src/constants/price'
import { PriceAttribution } from 'src/types/Size'

export const getDefaultPriceValues = (
  value?: number,
  supplierCost?: number,
  landedCost?: number,
  sellingPrice?: number,
): PriceAttribution => ({
  [SUPPLIER_COST]: supplierCost || value || 0,
  [LANDED_COST]: landedCost || value || 0,
  [SELLING_PRICE]: sellingPrice || value || 0,
  [CURRENT_FULL_PRICE]: value || 0,
  [PROMO_PRICE]: value || 0,
})
