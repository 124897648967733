import React, { Component } from 'react'
import classNames from 'classnames'

import { GridType, Product, DEV_TRACKER, TreeHierarchyItem, LINKS } from 'src/types/index'
import { Ellipses } from 'src/assets/icons/index'
import { Popover } from 'src/components/Popover/index'
import { ActionsMenu } from './ActionsMenu/ActionsMenu'
import HierarchyDrawer from '../../../HierarchyDrawer/index'
import {
  CopyProductToHierarchyPayload,
  DuplicateProductPayload,
} from 'src/actions/grid/product/copy'
import { UpdateProductHierarchyPayload } from 'src/actions/grid/product/update'
import { CANCELLED, READY_TO_BUY } from 'src/constants/productStatus'
import {
  isHierarchyNodeAtRangeLevel,
  isWithinDepartment,
} from '../../../../service/hierarchy/utils'
import { UploadSpinner } from '../../../UploadSpinner/UploadSpinner'
import { DepartmentsWithChildrenState } from 'src/reducers/referenceData/hierarchy/departmentsWithChildren'

import {
  actionCell,
  active,
  ellipses,
  hasError,
  popoverContainer,
  hasProductError as hasProductErrorClass,
} from './ActionsCell.scss'
import { MenuItemOptionType } from 'types/Menu'
import { SidePanelDrawerContainer } from 'components/SidePanelDrawer/SidePanelDrawerContainer'

type Props = {
  product: Product
  rowIndex: number
  editMode: boolean
  isActive: boolean
  isLocked?: boolean
  gridName: GridType
  departmentsWithChildren: DepartmentsWithChildrenState
  email: string
  isProductLocked: boolean
  hasProductError: boolean

  enterEditMode: () => void
  exitEditMode: (exit: boolean) => void
  duplicateProduct: (payload: DuplicateProductPayload) => void
  updateProductHierarchy: (payload: UpdateProductHierarchyPayload) => void
  copyProductToHierarchy: (payload: CopyProductToHierarchyPayload) => void
  openSizeModal: (hierachy: string, slug: string) => void
  onDismissError: (productSlug: string, errorID: string) => void
  openStyleModal: (hierarchySlug: string, product: Product) => void
}

type State = {
  movingHierarchy: boolean
  copyingToHierarchy: boolean
  colourLink: boolean
  supplierLink: boolean
  crossDepartmentLink: boolean
  matchingProductLink: boolean
  copyCrossDepartmentLink: boolean
  inheritStyle: boolean
  copyInheritStyle: boolean
  openSidePanel: boolean
}
export class ActionsCell extends Component<Props, State> {
  state = {
    movingHierarchy: false,
    copyingToHierarchy: false,
    colourLink: false,
    supplierLink: false,
    crossDepartmentLink: false,
    matchingProductLink: false,
    copyCrossDepartmentLink: false,
    inheritStyle: false,
    copyInheritStyle: false,
    openSidePanel: false,
  }

  render = (): JSX.Element => {
    const { isProductLocked, hasProductError } = this.props
    const activeClass = classNames(actionCell, {
      [active]: this.props.editMode,
      [hasError]: hasProductError,
    })

    const popoverClass = classNames(popoverContainer, {
      [hasProductErrorClass]: hasProductError,
    })

    return (
      <div className={activeClass}>
        <Popover
          open={this.props.editMode && this.props.isActive}
          position="leftTop"
          disabled={this.props.isLocked}
          onOpen={this.props.enterEditMode}
          onClose={this.onPopoverClose}
          popoverContainerClass={popoverClass}
          PopoverItem={this.actionMenu()}
          dataCy="actionMenuButton"
        >
          {isProductLocked ? <UploadSpinner /> : <Ellipses className={ellipses} />}
        </Popover>
        {this.state.movingHierarchy && (
          <HierarchyDrawer
            onNodeSelected={this.moveHierarchy}
            onClose={this.cancelHierarchyAction}
            gridName={this.props.gridName}
            nodeIsSelectable={this.isSelectableForMoving}
          />
        )}
        {this.state.copyingToHierarchy && (
          <HierarchyDrawer
            onNodeSelected={this.copyToHierarchy}
            onClose={this.cancelHierarchyAction}
            gridName={this.props.gridName}
            nodeIsSelectable={this.isSelectableForCopying}
          />
        )}
        {this.state.openSidePanel && (
          <SidePanelDrawerContainer
            rowIndex={this.props.rowIndex}
            open={this.state.openSidePanel}
            exitEditMode={this.closeSidePanel}
            openingTab={LINKS}
            gridName={this.props.gridName}
          />
        )}
      </div>
    )
  }

  closeSidePanel = (): void => this.setState({ openSidePanel: false })
  onPopoverClose = (): void => {
    this.resetOptions()
    this.props.exitEditMode(false)
  }

  actionMenu = (): JSX.Element => {
    const { duplicateProduct, product, gridName, exitEditMode, openSizeModal, openStyleModal } =
      this.props
    const itemsStyle = [
      {
        text: 'Duplicate',
        disabled: product.status === CANCELLED,
        options: [
          {
            text: 'Style',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.inheritStyle,
            callback: (): void =>
              this.setState({
                inheritStyle: !this.state.inheritStyle,
              }),
          },
          {
            text: 'Option (Link Line)',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.supplierLink,
            callback: (): void =>
              this.setState({
                supplierLink: !this.state.supplierLink,
              }),
          },
          {
            text: 'Cross Dept. Link',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.crossDepartmentLink,
            callback: (): void =>
              this.setState({
                crossDepartmentLink: !this.state.crossDepartmentLink,
              }),
          },
          {
            text: 'Matching Prod. Link',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.matchingProductLink,
            callback: (): void =>
              this.setState({
                matchingProductLink: !this.state.matchingProductLink,
              }),
          },
          {
            text: 'Confirm',
            type: MenuItemOptionType.LINK,
            callback: (): void => {
              duplicateProduct({
                product,
                colourLink: this.state.colourLink,
                supplierLink: this.state.supplierLink,
                crossDepartmentLink: this.state.crossDepartmentLink,
                matchingProductLink: this.state.matchingProductLink,
                inheritStyle: this.state.inheritStyle,
              })
              this.resetOptions()
              exitEditMode(false)
            },
          },
        ],
      },
      {
        text: 'Move Hierarchy',
        callback: (): void => {
          this.setState({ movingHierarchy: true })
        },
        disabled: product.status === READY_TO_BUY || product.status === CANCELLED,
      },
      {
        text: 'Copy To',
        disabled: product.status === CANCELLED,
        options: [
          {
            text: 'Style',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.copyInheritStyle,
            callback: (): void =>
              this.setState({
                copyInheritStyle: !this.state.copyInheritStyle,
              }),
          },
          {
            text: 'Cross Dept.',
            type: MenuItemOptionType.CHECKBOX,
            selected: this.state.copyCrossDepartmentLink,
            callback: (): void =>
              this.setState({
                copyCrossDepartmentLink: !this.state.copyCrossDepartmentLink,
              }),
          },
          {
            text: 'Confirm',
            type: MenuItemOptionType.LINK,
            callback: (): void => {
              this.setState({ copyingToHierarchy: true })
            },
          },
        ],
      },
      {
        text: 'Sizes',
        disabled: gridName === DEV_TRACKER || product.status === CANCELLED,
        callback: (): void =>
          openSizeModal(this.props.product.hierarchySlug, this.props.product.slug),
      },
      {
        text: 'Side Panel',
        callback: (): void => this.setState({ openSidePanel: true }),
      },
      {
        text: 'Amend Style',
        disabled: product.status === CANCELLED,
        callback: (): void => openStyleModal(this.props.product.hierarchySlug, this.props.product),
      },
    ]

    return (
      <ActionsMenu
        menu={itemsStyle}
        productErrors={product.eventErrors}
        onDismissError={this.onDismissError}
      />
    )
  }

  onDismissError = (errorID: string): void => {
    this.props.onDismissError(this.props.product.slug, errorID)
  }

  cancelHierarchyAction = (): void => {
    this.setState({
      movingHierarchy: false,
      copyingToHierarchy: false,
      copyCrossDepartmentLink: false,
      copyInheritStyle: false,
    })
    this.props.exitEditMode(true)
  }

  moveHierarchy = (node: TreeHierarchyItem): void => {
    this.props.updateProductHierarchy({
      developmentId: this.props.product.developmentId,
      productSlug: this.props.product.slug,
      hierarchy: node,
    })
    this.setState({ movingHierarchy: false })
    this.props.exitEditMode(true)
  }

  copyToHierarchy = (node: TreeHierarchyItem): void => {
    this.props.copyProductToHierarchy({
      sourceProduct: this.props.product,
      hierarchySlug: node.slug,
      email: this.props.email,
      crossDepartmentLink: this.state.copyCrossDepartmentLink,
      inheritStyle: this.state.copyInheritStyle,
    })
    this.setState({
      copyingToHierarchy: false,
      copyCrossDepartmentLink: false,
      copyInheritStyle: false,
    })
    this.props.exitEditMode(true)
  }

  isSelectableForMoving = (node: TreeHierarchyItem): boolean =>
    isWithinDepartment(
      node,
      this.departmentOfCurrentProduct() || '',
      this.props.departmentsWithChildren,
    ) && isHierarchyNodeAtRangeLevel(node)

  isSelectableForCopying = (node: TreeHierarchyItem): boolean =>
    !isWithinDepartment(
      node,
      this.departmentOfCurrentProduct() || '',
      this.props.departmentsWithChildren,
    ) && isHierarchyNodeAtRangeLevel(node)

  departmentOfCurrentProduct = (): string =>
    Object.keys(this.props.departmentsWithChildren).find((dept) => {
      return this.props.departmentsWithChildren[dept].includes(this.props.product.hierarchySlug)
    })

  resetOptions = (): void =>
    this.setState({
      colourLink: false,
      supplierLink: false,
      crossDepartmentLink: false,
      matchingProductLink: false,
    })
}
