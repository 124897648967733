import React from 'react'

interface Props {
  iconColor: string
  className: string
}

export const PriceTagIcon = (props: Props): JSX.Element => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g id="XMLID_1_">
      <path
        fill={props.iconColor}
        id="XMLID_5_"
        d="M486.9,0.5H291.6c-13,0-32.6,7.4-41.9,17.7L7,260c-9.3,9.3-9.3,25.1,0,34.4l210.2,210.2   c9.3,9.3,25.1,9.3,34.4,0l242.7-242.7c9.3-9.3,17.7-27.9,17.7-41.9V24.6C511.1,11.6,499.9,0.5,486.9,0.5z M364.1,195.8   c-27,0-49.3-22.3-49.3-49.3s22.3-49.3,49.3-49.3c27,0,49.3,22.3,49.3,49.3S391.1,195.8,364.1,195.8z"
      />
    </g>
  </svg>
)
