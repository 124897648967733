import {
  FETCH_LOCK,
  FETCH_LOCK_FAILURE,
  FETCH_LOCK_SUCCESS,
  SET_LOCK,
  SET_LOCK_FAILURE,
  SET_LOCK_SUCCESS,
} from 'src/actions/lock'
import { Lock, RequestStatus } from 'src/types/index'
import { UNINITIALISED_STATUS } from '../constants/requests'
import { SVPAction } from 'actions/svp'

export type LockState = {
  status: RequestStatus
  payload: Lock
}

const defaultLock: LockState = {
  status: UNINITIALISED_STATUS,
  payload: {
    status: '',
    date: '',
  },
}

export const lock = (state: LockState = defaultLock, action: SVPAction) => {
  switch (action.type) {
    case FETCH_LOCK:
      return { ...state, status: 'fetching' }
    case FETCH_LOCK_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    case FETCH_LOCK_FAILURE:
      return { ...state, status: 'error', error: action.error }
    case SET_LOCK:
      return { ...state, status: 'posting' }
    case SET_LOCK_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    case SET_LOCK_FAILURE:
      return { ...state, status: 'error', error: action.error }
    default:
      return state
  }
}
