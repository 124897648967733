import { GridType, Product } from 'src/types/index'

export const FETCH_FIRST_PRODUCTS_PAGE = 'FETCH_FIRST_PRODUCTS_PAGE'
export const REFETCH_CURRENT_PRODUCTS_PAGE = 'REFETCH_CURRENT_PRODUCTS_PAGE'
export const FETCH_NEXT_PRODUCTS_PAGE = 'FETCH_NEXT_PRODUCTS_PAGE'
export const FETCH_PRODUCTS_PAGE_SUCCESS = 'FETCH_PRODUCTS_PAGE_SUCCESS'
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE'
export const FETCH_COMPLETED_POLL_PRODUCTS_SUCCESSFUL = 'FETCH_COMPLETED_POLL_PRODUCTS_SUCCESSFUL'

export type FetchProductActions = ReturnType<
  | typeof fetchFirstProductsPage
  | typeof refetchCurrentProductsPage
  | typeof fetchNextProductsPage
  | typeof fetchProductsPageSuccess
  | typeof fetchProductsFailure
  | typeof fetchCompletedPollProductsSuccessful
>

export const fetchFirstProductsPage = (grid: GridType) =>
  <const>{
    type: FETCH_FIRST_PRODUCTS_PAGE,
    grid,
  }

export const refetchCurrentProductsPage = (grid: GridType) =>
  <const>{
    type: REFETCH_CURRENT_PRODUCTS_PAGE,
    grid,
  }

export const fetchNextProductsPage = (grid: GridType) =>
  <const>{
    type: FETCH_NEXT_PRODUCTS_PAGE,
    grid,
  }

export const fetchProductsPageSuccess = (
  payload: Product[],
  lastPageLoaded: number,
  lastPage: number,
  grid: GridType,
) =>
  <const>{
    type: FETCH_PRODUCTS_PAGE_SUCCESS,
    payload,
    lastPageLoaded,
    lastPage,
    grid,
  }

export const fetchProductsFailure = (error: string, grid: GridType) =>
  <const>{
    type: FETCH_PRODUCTS_FAILURE,
    error,
    grid,
  }

export const fetchCompletedPollProductsSuccessful = (payload: Product[], grid: GridType) =>
  <const>{
    type: FETCH_COMPLETED_POLL_PRODUCTS_SUCCESSFUL,
    payload,
    grid,
  }
