import { UPDATE_HIDDEN_COLUMNS } from 'src/actions/grid/hiddenColumns'
import { SVPAction } from 'actions/svp'
import { DropdownOption } from 'types/Dropdowns'

export type HiddenColumnState = DropdownOption[]
const defaultState: HiddenColumnState = []

export const hiddenColumns = (state: HiddenColumnState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case UPDATE_HIDDEN_COLUMNS:
      return [...action.columns]
  }
  return state
}
