import * as Sentry from '../../helpers/sentry'

const errorBlockList = [
  // These are errors coming from the Auth0 SDK
  // These are caused when the user has changed tab from the UI and it tries to refresh the cookie.
  // Filtering these to reduce the noise from Sentry
  'Error: login_required',
  'Error: timeout of 0ms exceeded',
  'Error: Network Error',
  'Error: Validation failed',
]

export const logError = (error: Error) => {
  console.error(error)

  // Filter out specific errors to reduce error tracking noise
  if (!error || errorBlockList.includes(error.toString())) {
    return
  }

  Sentry.logError(error)
}
