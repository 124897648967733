import React from 'react'
import {
  header,
  headerTitle,
  linksSection,
  linksSectionContainer,
  linksSectionWrapper,
  spinner,
} from './LinksSection.scss'
import { LinksGroup } from './LinksGroup/LinksGroup'
import { UploadSpinner } from '../../UploadSpinner'
import { StyleSection } from './StyleSection/StyleSection'
import { AddLinkContainer } from './AddLink/AddLinkContainer'
import { FETCHING_STATUS, READY_STATUS } from 'src/constants/requests'
import { CROSS_DEPARTMENT_LINK, MATCHING_PRODUCT_LINK } from 'src/constants/links'
import { LinkSectionProps } from './LinksSectionContainer'

export const LinksSection = ({
  style,
  links,
  selectedProduct,
  removeMatchingProduct,
  removeCrossDepartmentProduct,
  detachProduct,
  detachSelectedProduct,
  openStyleModal,
}: LinkSectionProps): JSX.Element => (
  <div className={linksSectionWrapper}>
    <div className={header}>
      <h4 className={headerTitle}>Link types</h4>
      <AddLinkContainer selectedProduct={selectedProduct} />
    </div>
    {links.status === FETCHING_STATUS || style.status === FETCHING_STATUS ? (
      <div className={spinner}>
        <UploadSpinner />
      </div>
    ) : (
      <div className={linksSectionContainer}>
        <div className={linksSection}>
          {links.status === READY_STATUS && style.status === READY_STATUS && style.current && (
            <>
              <StyleSection
                style={style.current}
                selectedProduct={selectedProduct}
                detachProduct={detachProduct}
                detachSelectedProduct={detachSelectedProduct}
                openStyleModal={openStyleModal}
              />
              {links.matchingProducts.length > 0 && (
                <LinksGroup
                  name="Matching Products"
                  linkType={MATCHING_PRODUCT_LINK}
                  linkedProducts={links.matchingProducts}
                  selectedProduct={selectedProduct}
                  onRemove={removeMatchingProduct}
                  removeTitle="Remove from matching products link"
                />
              )}
              {links.crossDepartmentProducts.length > 0 && (
                <LinksGroup
                  name="Cross Department Products"
                  linkType={CROSS_DEPARTMENT_LINK}
                  linkedProducts={links.crossDepartmentProducts}
                  selectedProduct={selectedProduct}
                  onRemove={removeCrossDepartmentProduct}
                  removeTitle="Remove from cross department link"
                />
              )}
            </>
          )}
        </div>
      </div>
    )}
  </div>
)
