import { all, call, put, takeEvery } from 'redux-saga/effects'

import { showNotification } from 'src/actions/notification'
import { errorAndNotification } from '../error'
import { injectorService } from 'src/service/injector/injectorService'
import { UPDATE_PRODUCT_HIERARCHY } from 'src/actions/grid/product/update'
import { trackEvent } from 'src/service/analytics/analytics'
import { refetchCurrentPageSaga } from './fetch'
import { refetchCurrentProductsPage } from 'src/actions/grid/product/fetch'
import { SVPAction } from 'actions/svp'
import { APIError } from 'types/Api'

const errorMessageFor = (errorType: string) =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

const UPDATE_PRODUCT_ERROR_MESSAGE = errorMessageFor('update product for you')

export function* updateProductHierarchySaga(action: SVPAction<typeof UPDATE_PRODUCT_HIERARCHY>) {
  try {
    const hierarchy = action.payload.hierarchy

    trackEvent('product', 'Product Moved Hierarchy', hierarchy.title)
    yield put(refetchCurrentProductsPage(action.grid))

    yield call(injectorService.put, `products/${action.payload.productSlug}/location`, {
      targetLocationSlug: hierarchy.slug,
    })
    yield call(refetchCurrentPageSaga)

    yield put(
      showNotification({
        type: 'success',
        message: `Successfully moved to ${hierarchy.title} - ${hierarchy.levelCode}`,
      }),
    )
  } catch (error) {
    yield* errorAndNotification(error as APIError, UPDATE_PRODUCT_ERROR_MESSAGE)
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_PRODUCT_HIERARCHY, updateProductHierarchySaga)])
}
