import React from 'react'
import classNames from 'classnames'
import { GridCellProps } from 'react-virtualized'
import { AttributeValueMap } from 'types'
import { DropdownOption } from 'types/Dropdowns'
import { DefaultAttributesTableData, SelectedDefaultAttribute } from 'types/UserSettings'
import {
  bodyGrid,
  cellSelectedClass,
  columnLeftBorder,
  rangeTitleInfo,
  readOnlyCell,
  rowTopBorder,
} from './CellRenderer.scss'
import {
  setClipboardAttributeSource,
  updateDefaultAttributeValues,
} from 'actions/userSettings/defaultAttributes'
import { ReadonlyCell } from './ReadonlyCell/ReadonlyCell'
import { EditableCell } from './EditableCell/EditableCell'

interface CellRendererProps extends GridCellProps {
  tableData: DefaultAttributesTableData[][]
  allAttributeValues: AttributeValueMap
  copiedAttribute?: SelectedDefaultAttribute
  updateDefaultAttributeValues: typeof updateDefaultAttributeValues
  setClipboardAttributeSource: typeof setClipboardAttributeSource
}

export const CellRenderer = ({
  columnIndex,
  rowIndex,
  style,
  tableData,
  allAttributeValues,
  copiedAttribute,
  updateDefaultAttributeValues,
  setClipboardAttributeSource,
}: CellRendererProps): JSX.Element => {
  const [isCellSelected, setIsCellSelected] = React.useState<boolean>(false)
  const [isCellInEditMode, setIsCellinEditMode] = React.useState<boolean>(false)

  const selectedCell = tableData[rowIndex][columnIndex]
  const updateListDefaultAttribute = (item: DropdownOption): void => {
    updateDefaultAttributeValues(
      item.value ? [item.value as string] : [],
      selectedCell.attributeName,
      selectedCell.attributeSlug,
      selectedCell.rangeSlug,
    )
  }
  const updateMultiListDefaultAttribute = (items: DropdownOption[]): void => {
    updateDefaultAttributeValues(
      items.map((item) => item.value as string),
      selectedCell.attributeName,
      selectedCell.attributeSlug,
      selectedCell.rangeSlug,
    )
  }
  const onSelectCell = (): void => setIsCellSelected(true)
  const onDeselectCell = (): void => {
    setIsCellinEditMode(false)
    setIsCellSelected(false)
  }
  const onEnterEditMode = (): void => setIsCellinEditMode(true)
  const cellContent = (): JSX.Element => {
    // Row headers
    if (columnIndex === 0) {
      return (
        <div
          style={style}
          className={classNames(bodyGrid, readOnlyCell, columnLeftBorder, {
            [rangeTitleInfo]: rowIndex === 0,
            [rowTopBorder]: rowIndex === 0,
          })}
        >
          {tableData[rowIndex][0].name}
        </div>
      )
    }

    // Column headers
    if (rowIndex === 0 && columnIndex > 0) {
      return (
        <div style={style} className={classNames(bodyGrid, readOnlyCell, rowTopBorder)}>
          {tableData[0][columnIndex].name}
        </div>
      )
    }

    const cellClassNames = classNames(bodyGrid, {
      [cellSelectedClass]: isCellSelected,
    })

    if (!isCellInEditMode) {
      return (
        <ReadonlyCell
          copiedAttribute={copiedAttribute}
          selectedCell={selectedCell}
          style={style}
          className={classNames(cellClassNames, readOnlyCell)}
          onSelectCell={onSelectCell}
          onDeselectCell={onDeselectCell}
          onEnterEditMode={onEnterEditMode}
          setClipboardAttributeSource={setClipboardAttributeSource}
          updateDefaultAttributeValues={updateDefaultAttributeValues}
        />
      )
    }

    return (
      <EditableCell
        allAttributeValues={allAttributeValues}
        selectedCell={selectedCell}
        style={style}
        className={cellClassNames}
        onSelectCell={onSelectCell}
        onDeselectCell={onDeselectCell}
        updateListDefaultAttribute={updateListDefaultAttribute}
        updateMultiListDefaultAttribute={updateMultiListDefaultAttribute}
        isCellSelected={isCellSelected}
      />
    )
  }

  return <>{cellContent()}</>
}
