import { connect } from 'react-redux'
import { PriceCellRenderer } from './PriceCellRenderer'
import { AppState } from 'src/reducers/root'
import { getGrid, isGridLocked } from 'src/selectors/grid'
import { openSizeModal } from 'src/actions/grid/modal/sizeModal'
import { Product } from 'src/types/index'
import { codesToPrefixes } from 'src/service/excel/exporter/valueMappers'
import { POUND_CURRENCY } from 'src/constants/currency'

type OwnProps = {
  product: Product
  accessor: string
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const { product, accessor } = ownProps

  return {
    grid: getGrid(state),
    locked: isGridLocked(state),
    currency:
      accessor === 'supplierCostView'
        ? codesToPrefixes[product.supplierCostCurrency]
        : POUND_CURRENCY,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  viewSizes: (hierarchySlug: string, productSlug: string) =>
    dispatch(openSizeModal(hierarchySlug, productSlug, true)),
})

export const PriceCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PriceCellRenderer)
