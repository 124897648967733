import React from 'react'

import { ModalState } from '../../reducers/modals'
import { CopyToModal } from '../CopyToModal'
import { SizeModal } from '../SizeModal'
import { StyleModalContainer } from 'components/StyleModal/StyleModalContainer'

type Props = {
  modals: ModalState
}

const modalLookup: Record<string, React.ReactNode> = {
  copyToModal: CopyToModal,
  sizeModal: SizeModal,
  styleModal: StyleModalContainer,
}

export const GlobalModal = ({ modals }: Props) => {
  const anOpenModal = Object.keys(modals).find((modalName) => modals[modalName].open === true)

  const ModalComponent: React.ReactNode = anOpenModal ? modalLookup[anOpenModal] : null

  return anOpenModal && ModalComponent ? (
    // @ts-ignore
    <ModalComponent {...modals[anOpenModal]} />
  ) : null
}
