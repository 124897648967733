import { Product } from 'src/types/index'

export const SET_CLIPBOARD_GRID_SOURCE = 'SET_CLIPBOARD_GRID_SOURCE'
export const CLEAR_CLIPBOARD_GRID_SOURCE = 'CLEAR_CLIPBOARD_GRID_SOURCE'
export const SET_CLIPBOARD_GRID_DESTINATION = 'SET_CLIPBOARD_GRID_DESTINATION'
export const CLEAR_CLIPBOARD_GRID_DESTINATION = 'CLEAR_CLIPBOARD_GRID_DESTINATION'

export type ClipboardActions = ReturnType<
  | typeof setClipboardGridSource
  | typeof clearClipboardGridSource
  | typeof setClipboardGridDestination
  | typeof clearClipboardGridDestination
>

export const setClipboardGridSource = (product: Product, property: string) =>
  <const>{
    type: SET_CLIPBOARD_GRID_SOURCE,
    product,
    property,
  }

export const clearClipboardGridSource = () =>
  <const>{
    type: CLEAR_CLIPBOARD_GRID_SOURCE,
  }

export const setClipboardGridDestination = (productSlug: string, property: string) =>
  <const>{
    type: SET_CLIPBOARD_GRID_DESTINATION,
    productSlug,
    property,
  }

export const clearClipboardGridDestination = () =>
  <const>{
    type: CLEAR_CLIPBOARD_GRID_DESTINATION,
  }
