export const PRODUCT_STATUS_DEVELOPMENT_KEY = 'development'
export const PRODUCT_STATUS_PROPOSED_KEY = 'proposed'
export const PRODUCT_STATUS_READY_TO_BUY_KEY = 'readyToBuy'
export const PRODUCT_STATUS_PENDING_ODBMS_KEY = 'pendingOdbms'
export const PRODUCT_STATUS_CANCELLED_KEY = 'cancelled'
export const PRODUCT_STATUS_PENDING_KEY = 'pending'

export const READY_TO_BUY = 'Ready to buy'
export const DEVELOPMENT = 'Development'
export const PROPOSED = 'Proposed'
export const CANCELLED = 'Cancelled'
export const CANCELLED_IN_DEVELOPMENT = 'Cancelled in development'
export const CANCELLED_IN_PROPOSED = 'Cancelled in proposed'
export const CANCELLED_IN_READY_TO_BUY = 'Cancelled in ready to buy'
export const PENDING_ODBMS = 'Pending Odbms'
export const PROPOSED_PENDING_FOR_RTB = 'Proposed Pending for RTB'
export const RTB_PENDING_CANCELLATION = 'RTB Pending Cancellation'
export const PENDING_RTB_SIZES = 'RTB Pending Sizes'
