import { AppState } from '../../reducers/root'
import { DepartmentViews } from '../../types/DepartmentSpec'

export const getViewsReferenceData = (state: AppState): DepartmentViews => {
  const views = state.injectorUI.referenceData.views

  return Object.keys(views).reduce(
    (acc: DepartmentViews, key: string) => ({
      ...acc,
      [views[key].grid]: {
        ...acc[views[key].grid],
        [key]: {
          slug: views[key].slug,
          displayName: views[key].name,
          columns: [],
        },
      },
    }),
    {} as DepartmentViews,
  )
}
