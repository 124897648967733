import { FlatHierarchyItem } from 'src/types/index'
import { flattenHierarchy } from 'src/service/mappers'
import { FETCH_REFERENCE_DATA_SUCCESS } from '../../../actions/referenceData/referenceData'
import { SVPAction } from 'actions/svp'

const defaultState = {}

export type LookupHierarchyState = {
  [key: string]: FlatHierarchyItem
}

export const lookupTable = (state: LookupHierarchyState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return flattenHierarchy(action.hierarchy)
    default:
      return state
  }
}
