import { connect } from 'react-redux'
import { WholesaleImagesSection } from './WholesaleImagesSection'
import {
  uploadWholesaleImageFile,
  getWholesaleImages,
  clearWholesaleImages,
  updateWholesaleImagesSequence,
  replaceWholesaleImageFile,
  removeWholesaleImageFile,
} from 'src/actions/wholesaleImages'
import {
  getWholeSaleImagesFromState,
  getWholeSaleImagesLoadingFromState,
} from 'src/selectors/wholesaleImages'
import { AppState } from 'src/reducers/root'
import { showErrorNotification } from 'src/actions/notification'

const mapStateToProps = (state: AppState) => ({
  isLoading: getWholeSaleImagesLoadingFromState(state),
  wholesaleImages: getWholeSaleImagesFromState(state),
})

const mapDispatchToProps = {
  uploadWholesaleImageFile,
  getWholesaleImages,
  clearWholesaleImages,
  showErrorNotification,
  updateWholesaleImagesSequence,
  replaceWholesaleImageFile,
  removeWholesaleImageFile,
}

export const WholesaleImagesSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WholesaleImagesSection)
