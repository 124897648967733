import React from 'react'

import { FlatHierarchyItem, Product } from 'src/types/index'
import { CloseCross } from 'src/assets/icons'
import { Image } from 'src/types/Images'

import {
  styleModalHeader,
  headerBody,
  header,
  headerImage,
  productInfo,
  styleDescription as styleDescriptionClass,
  developmentId as developmentIdClass,
  hierarchy as hierarchyClass,
  closeButtonContainer,
  closeButton,
} from './StyleModalHeader.scss'

type Props = {
  onClose: () => void
  product: Product
  hierarchy: FlatHierarchyItem
}

const getLastImage = (images?: Image[]): Image | null => (images ? images.slice(-1)[0] : null)

export const StyleModalHeader = ({ onClose, product, hierarchy }: Props): JSX.Element => {
  const { images, developmentId, styleDescription } = product
  const image = getLastImage(images)

  return (
    <div className={header}>
      <div className={headerBody}>
        <div className={styleModalHeader}>
          {image && <img className={headerImage} src={image.url} alt={image.type} />}
          <div className={productInfo}>
            {developmentId && <p className={developmentIdClass}>{developmentId}</p>}
            <p className={styleDescriptionClass}>{styleDescription}</p>
            <p className={hierarchyClass}>{`${hierarchy.levelCode} ${hierarchy.title}`}</p>
          </div>
        </div>
        <div className={closeButtonContainer}>
          <button onClick={onClose} className={closeButton}>
            <CloseCross />
          </button>
        </div>
      </div>
    </div>
  )
}
