import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import { drawerContainer, overlay } from './SidePanelDrawer.scss'
import { Product } from 'src/types/index'
import { SidePanelContainer } from './SidePanel/SidePanelContainer'
import { DrawerContainerProps } from './SidePanelDrawerContainer'

type DrawerProps = DrawerContainerProps & {
  products: Product[]
  resetNavigationValue: () => void
  sidePanelValue: number
}
type State = {
  open: boolean
  product: Product
}

export class SidePanelDrawer extends React.Component<DrawerProps, State> {
  constructor(props: DrawerProps) {
    super(props)
    this.state = {
      open: props.open,
      product: props.products[props.rowIndex + props.sidePanelValue],
    }
  }

  handleClose = (): void => {
    this.setState({ open: false })
    this.props.exitEditMode && this.props.exitEditMode(true)
    this.props.resetNavigationValue()
  }

  componentDidUpdate(prevProps: DrawerProps): void {
    if (this.props.open !== prevProps.open) {
      this.setState({ open: this.props.open })
    }

    if (
      prevProps.sidePanelValue !== this.props.sidePanelValue ||
      prevProps.products !== this.props.products
    ) {
      this.setState({
        product: this.props.products[this.props.rowIndex + this.props.sidePanelValue],
      })
    }
  }

  render(): JSX.Element {
    return (
      <Drawer
        classes={{
          root: drawerContainer,
          paper: overlay,
        }}
        onClose={(open: boolean): void => {
          this.setState({ open })
          this.props.exitEditMode && this.props.exitEditMode(true)
          this.props.resetNavigationValue()
        }}
        open={this.state.open}
        anchor="right"
        disableEnforceFocus={true}
      >
        <SidePanelContainer
          product={this.state.product}
          sidePanelValue={this.props.sidePanelValue}
          products={this.props.products}
          rowIndex={this.props.rowIndex}
          openingTab={this.props.openingTab}
          closeFn={this.handleClose}
          gridName={this.props.gridName}
        />
      </Drawer>
    )
  }
}
