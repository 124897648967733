import React from 'react'
import moment from 'moment'

import { Comment as CommentType } from 'src/types/index'

import { comment, userComment, header, dateTime } from './Comment.scss'

type Props = CommentType & {
  isCurrentUser: boolean
}

export const Comment = ({ text, userEmail, dateTimeCreated, isCurrentUser }: Props) => (
  <li className={`${isCurrentUser ? userComment : comment}`}>
    <div className={header}>
      <p>{userEmail}</p>
      <p className={dateTime}>{moment(dateTimeCreated).format('DD/MM/YY HH:mm')}</p>
    </div>
    <p>{text}</p>
  </li>
)
