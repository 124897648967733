import React from 'react'
import PrimaryButton from 'components/PrimaryButton'
import { StyleResult } from 'types/Style'
import {
  button,
  controlButtons,
  resultsContainer,
  selected,
  singleResult,
  styleId,
  styleItem,
  styleRadio,
} from './StyleModalSearchResults.scss'
import classNames from 'classnames'

interface Props {
  results: StyleResult[]
  isConfirmStyleRequesting: boolean
  onBack: () => void
  onConfirm: (styleSlug: string) => void
}

export const StyleModalSearchResults = (props: Props): JSX.Element => {
  const [selectedResult, setSelectedResult] = React.useState<string>('')
  const onResultSelected = (styleSlug: string): void => setSelectedResult(styleSlug)

  const onConfirm = (): void => {
    props.onConfirm(selectedResult)
  }

  return (
    <>
      <div className={resultsContainer}>
        {props.results.map((result) => {
          return (
            <div
              key={result.slug}
              className={classNames(singleResult, { [selected]: result.slug === selectedResult })}
              onClick={(): void => onResultSelected(result.slug)}
            >
              <div className={styleRadio}>
                <input
                  type="radio"
                  value={result.slug}
                  checked={result.slug === selectedResult}
                  onChange={(): void => onResultSelected(result.slug)}
                />
              </div>
              <div className={styleItem}>
                <div className={styleId}>{result.styleID}</div>
                <div>{result.styleDescription}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={controlButtons}>
        <PrimaryButton
          disabled={false}
          className={button}
          onClick={props.onBack}
          colour="black"
          dataCy="backToFormButton"
        >
          Back
        </PrimaryButton>
        <PrimaryButton
          disabled={props.isConfirmStyleRequesting}
          className={button}
          onClick={onConfirm}
          colour="green"
          dataCy="confirmStyleButton"
        >
          Confirm
        </PrimaryButton>
      </div>
    </>
  )
}
