import { HP_HANDOVER_TYPE, HP_HANDOVER_WK } from 'constants/columns'
import { Product } from 'types'

type SuggestionsMap = {
  [key: string]: {
    validateCondition: (description: string) => boolean
    suggestions: string[]
  }
}

const suggestionsMap: SuggestionsMap = {
  supplier: {
    validateCondition: (description: string) => description === 'CMT Vendor for Womens Wear',
    suggestions: [HP_HANDOVER_WK, HP_HANDOVER_TYPE],
  },
}

export const getProductEmptyDependencySuggestions = (product: Product): string[] => {
  let suggestions: string[] = []
  Object.keys(suggestionsMap).forEach((field) => {
    if (product[field] && suggestionsMap[field]?.validateCondition(product[field]?.description)) {
      suggestions = suggestionsMap[field].suggestions
      return
    }
  })

  return suggestions
}
