import React from 'react'

import { ProductEventError } from 'src/types/ProductEventError'
import {
  getEventErrorMessage,
  getEventErrorTitle,
  isErrorDismissable,
} from 'src/service/product/eventErrors'

import { errorMenuList, errorMenuListItem, errorMenuTitle, closeButton } from './ErrorMenu.scss'
import { CloseCross } from 'src/assets/icons'

interface Props {
  productErrors?: ProductEventError[]
  onDismissError?: (errorID: string) => void
}

export default function ErrorMenu({ productErrors, onDismissError }: Props): JSX.Element {
  if (!productErrors) {
    return <></>
  }

  const onClickDismiss = (errorID: string): void => {
    onDismissError && onDismissError(errorID)
  }

  return (
    <ul data-cy="error-menu-ul" className={errorMenuList}>
      {productErrors.map((error) => (
        <li data-cy="error-menu-li" key={error.id} className={errorMenuListItem}>
          <div className={errorMenuTitle}>
            <b>{getEventErrorTitle(error)} failed:</b>
          </div>
          <div data-cy="error-menu-message" className="errorMenuMessage">
            {getEventErrorMessage(error)}
          </div>
          {isErrorDismissable(error) && (
            <button onClick={(): void => onClickDismiss(error.id)} className={closeButton}>
              <CloseCross />
            </button>
          )}
        </li>
      ))}
    </ul>
  )
}
