import {
  CLOSE_SIZE_MODAL,
  FETCH_SIZES,
  FETCH_SIZES_SUCCESS,
  OPEN_SIZE_MODAL,
  SET_SIZE_MODAL_STATUS_POSTING,
  SET_SIZE_MODAL_STATUS_READY,
} from 'src/actions/grid/modal/sizeModal'
import {
  FETCHING_STATUS,
  POSTING_STATUS,
  READY_STATUS,
  UNINITIALISED_STATUS,
} from 'src/constants/requests'
import { RequestStatus } from 'src/types/Api'
import {
  AllocatedSizeGroups,
  PartialPriceAttributions,
  Size,
  SizeAttributions,
} from 'src/types/Size'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import {
  UPDATE_PRODUCT_SIZES,
  UPDATE_PRODUCT_SIZES_ERROR,
  UPDATE_SIZE_ATTRIBUTION_SUCCESS,
  UPDATE_SIZES_AND_ATTRIBUTION,
  UPDATE_SIZES_AND_ATTRIBUTION_ERROR,
} from 'src/actions/grid/product/sizes'
import { CurrencyKeys } from 'src/types/index'
import { POUND_KEY } from 'src/constants/currency'
import { SVPAction } from 'actions/svp'

export interface SizeModalState {
  open: boolean
  status: RequestStatus
  productSlug: string
  hierarchySlug: string

  rangeSizes: Size[]
  supplierCostCurrency: CurrencyKeys
  sizeInformation: AllocatedSizeGroups
  productSizeAttributes: SizeAttributions
}

const defaultSizeModalState = (): SizeModalState => ({
  open: false,
  status: UNINITIALISED_STATUS,
  productSlug: '',
  hierarchySlug: '',

  rangeSizes: [],
  supplierCostCurrency: POUND_KEY,
  sizeInformation: {
    T: [],
    O: [],
    M: [],
    default: [],
  },
  productSizeAttributes: {
    default: {},
    M: {},
    O: {},
    T: {},
  },
})

const updateSizeAttribution = (
  sizeAttribution: SizeAttributions,
  priceAttributions: PartialPriceAttributions,
): SizeAttributions => {
  const newState: SizeAttributions = { ...sizeAttribution }
  Object.keys(priceAttributions).forEach((group) => {
    const sizeGroup = group as ProductSizeGroups
    newState[sizeGroup] = {
      ...newState[sizeGroup],
      ...priceAttributions[sizeGroup],
    }
  })

  return newState
}

export default (
  state: SizeModalState = defaultSizeModalState(),
  action: SVPAction,
): SizeModalState => {
  switch (action.type) {
    case OPEN_SIZE_MODAL:
      return {
        ...state,
        open: true,
        productSlug: action.productSlug,
        hierarchySlug: action.hierarchySlug,
      }
    case FETCH_SIZES:
      return {
        ...state,
        status: FETCHING_STATUS,
      }
    case FETCH_SIZES_SUCCESS:
      return {
        ...state,
        status: READY_STATUS,
        supplierCostCurrency: action.payload.supplierCostCurrency,
        rangeSizes: action.payload.rangeSizes,
        sizeInformation: action.payload.sizeInformation,
        productSizeAttributes: {
          ...state.productSizeAttributes,
          ...action.payload.productSizeAttributes,
        },
      }
    case UPDATE_PRODUCT_SIZES:
      return {
        ...state,
        status: FETCHING_STATUS,
      }
    case UPDATE_SIZE_ATTRIBUTION_SUCCESS:
      return {
        ...state,
        supplierCostCurrency: action.payload.supplierCostCurrency,
        productSizeAttributes: updateSizeAttribution(
          state.productSizeAttributes,
          action.payload.prices,
        ),
      }
    case UPDATE_SIZES_AND_ATTRIBUTION:
      return {
        ...state,
      }
    case SET_SIZE_MODAL_STATUS_POSTING:
      return {
        ...state,
        status: POSTING_STATUS,
      }
    case UPDATE_SIZES_AND_ATTRIBUTION_ERROR:
    case UPDATE_PRODUCT_SIZES_ERROR:
    case SET_SIZE_MODAL_STATUS_READY:
      return {
        ...state,
        status: READY_STATUS,
      }
    case CLOSE_SIZE_MODAL:
      return {
        ...state,
        ...defaultSizeModalState(),
      }
    default:
      return state
  }
}
