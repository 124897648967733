import { Config } from 'src/helpers/config'

export const getEnv = (): string => Config().Get('env') as string

export const isProdOrTraining = (): boolean => {
  const env: string = getEnv()
  return env === 'prod' || env === 'training'
}

export const isNotProdOrTraining = (): boolean => {
  const env: string = getEnv()
  return env !== 'prod' && env !== 'training'
}

export const isNotProd = (): boolean => {
  const env: string = getEnv()
  return env !== 'prod'
}

export const isMobileEnv = (): boolean => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}
