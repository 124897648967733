import React from 'react'
import { RISpinner } from 'components/RISpinner/RISpinner'
import { DefaultAttributesProps } from './DefaultAttributesContainer'
import { spinnerContainer } from '../UserSettings.scss'
import { AutoSizer, GridCellProps, MultiGrid } from 'react-virtualized'
import {
  button,
  defaultAttributesContainer,
  gridExternalContainer,
  modalButtons,
  newDefaultAttributeContainer,
  newDefaultAttributeTrigger,
  primary,
  secondary,
} from './DefaultAttributes.scss'
import { PlusIcon } from 'assets/icons'
import { Modal } from 'components/Modal/Modal'
import Dropdown from 'components/Dropdown'
import { DropdownOption } from 'types/Dropdowns'
import PrimaryButton from 'components/PrimaryButton'
import classNames from 'classnames'
import { CellRenderer } from './CellRenderer/CellRenderer'

const modalCustomControlStyles = {
  border: '1px solid #eef0ef',
  '&:hover': {
    border: '1px solid #26B3AD',
  },
  height: '3rem',
}

export const DefaultAttributes = (props: DefaultAttributesProps): JSX.Element => {
  const [shouldShowAttributeModal, setShouldShowAttributeModal] = React.useState<boolean>(false)
  const [selectedModalAttribute, setSelectedModalAttribute] = React.useState<DropdownOption>(null)
  const {
    tableData,
    selectedSideListSlug,
    isFetchingAttributes,
    allAttributeValues,
    copiedAttribute,
    getDepartmentRangesWithDefaultAttributes,
    updateDefaultAttributeValues,
    setClipboardAttributeSource,
  } = props

  React.useEffect(() => {
    if (selectedSideListSlug) {
      getDepartmentRangesWithDefaultAttributes(selectedSideListSlug)
    }
  }, [selectedSideListSlug])

  if (isFetchingAttributes) {
    return (
      <div className={spinnerContainer}>
        <RISpinner />
      </div>
    )
  }

  if (!selectedSideListSlug) {
    return <div>Please select a department</div>
  }

  const closeModal = (): void => {
    setSelectedModalAttribute(null)
    setShouldShowAttributeModal(false)
  }

  const onChooseAttribute = (item: DropdownOption): void => {
    setSelectedModalAttribute(item)
  }

  const onConfirmAttribute = (): void => {
    if (selectedModalAttribute) {
      props.addDefaultAttributeToTable({
        attributeDisplayName: selectedModalAttribute.label,
        attributeName: selectedModalAttribute.value as string,
        attributeSlug: selectedModalAttribute.slug,
        attributeType: selectedModalAttribute.data.type,
      })
    }
    closeModal()
  }

  const cellRenderer = ({ key, ...gridProps }: GridCellProps): JSX.Element => (
    <CellRenderer
      key={key}
      {...gridProps}
      tableData={tableData}
      allAttributeValues={allAttributeValues}
      copiedAttribute={copiedAttribute}
      updateDefaultAttributeValues={updateDefaultAttributeValues}
      setClipboardAttributeSource={setClipboardAttributeSource}
    />
  )

  const onAddNewAttribute = (): void => setShouldShowAttributeModal(true)

  return (
    <div className={defaultAttributesContainer}>
      <div className={newDefaultAttributeContainer}>
        <button
          data-cy="new-default-attribute-button"
          className={newDefaultAttributeTrigger}
          onClick={onAddNewAttribute}
        >
          <PlusIcon title="Add new default attribute" />
        </button>
        <div>Add new default attribute</div>
      </div>
      <div className={gridExternalContainer}>
        {!!tableData.length && (
          <AutoSizer>
            {({ width, height }): JSX.Element => (
              <MultiGrid
                fixedColumnCount={1}
                fixedRowCount={1}
                enableFixedColumnScroll={true}
                cellRenderer={cellRenderer}
                columnWidth={200}
                columnCount={tableData[0].length}
                height={height}
                rowHeight={80}
                rowCount={tableData.length}
                containerStyle={{ position: 'static' }}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </div>
      {shouldShowAttributeModal && (
        <Modal onCloseFn={closeModal}>
          <div data-cy="add-default-attribute-modal">
            <Dropdown
              selectedItem={selectedModalAttribute}
              isValid={true}
              name="department"
              required={false}
              header="Select Attribute"
              items={props.listAttributes}
              createOption={(item: DropdownOption): DropdownOption => ({
                ...item,
                data: { ...item.data, ...item },
              })}
              onItemSelected={onChooseAttribute}
              disabled={false}
              customControlStyles={modalCustomControlStyles}
            />
          </div>
          <div className={modalButtons}>
            <PrimaryButton
              disabled={false}
              className={classNames(button, secondary)}
              onClick={closeModal}
              colour="black"
              dataCy="searchStylesButton"
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              disabled={false}
              className={classNames(button, primary)}
              onClick={onConfirmAttribute}
              colour="green"
              dataCy="searchStylesButton"
            >
              Add attribute
            </PrimaryButton>
          </div>
        </Modal>
      )}
    </div>
  )
}
