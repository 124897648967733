import React from 'react'

interface Props {
  className: string
  selected: boolean
}

export const MultiSelectIcon = ({ className, selected }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <title>Multi select</title>
    <path
      d="M10.88,4.8h8.64"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeOpacity="0.87"
      strokeWidth="2"
    />
    <path
      d="M10.88,11.45h8.64"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeOpacity="0.87"
      strokeWidth="2"
    />
    <path
      d="M10.88,18.4h8.64"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeOpacity="0.87"
      strokeWidth="2"
    />
    <polyline
      points="4 4.72 5.03 5.9 7.2 2.4"
      fill="none"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <polyline
      points="4 11.92 5.03 13.1 7.2 9.6"
      fill="none"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <polyline
      points="4 18.32 5.03 19.5 7.2 16"
      fill="none"
      stroke={selected ? '#fff' : '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
)
