import React from 'react'

interface Props {
  className?: string
}

export const HelpIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C4.928 0 0 4.928 0 11C0 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 0 11 0ZM12.1 18.7H9.9V16.5H12.1V18.7ZM14.377 10.175L13.387 11.187C12.837 11.748 12.441 12.254 12.243 13.046C12.155 13.398 12.1 13.794 12.1 14.3H9.9V13.75C9.9 13.244 9.988 12.76 10.142 12.309C10.362 11.671 10.725 11.099 11.187 10.637L12.551 9.251C13.057 8.767 13.299 8.041 13.156 7.271C13.013 6.479 12.397 5.808 11.627 5.588C10.406 5.247 9.273 5.94 8.91 6.985C8.778 7.392 8.437 7.7 8.008 7.7H7.678C7.04 7.7 6.6 7.084 6.776 6.468C7.249 4.851 8.624 3.619 10.329 3.355C12.001 3.091 13.596 3.96 14.586 5.335C15.884 7.128 15.499 9.053 14.377 10.175V10.175Z"
      fill="#157EFB"
    />
  </svg>
)
