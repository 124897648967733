import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { getAttributes, getAttributeValues } from 'src/selectors/referenceData/attributes'
import { injectorService } from 'src/service/injector/injectorService'
import { apiProductToProductWithDefaults } from 'src/service/mappers'
import { addHighlightedRow } from 'src/actions/grid/highlightedRows'
import { errorAndNotification } from '../error'
import { getCountries } from 'src/selectors/referenceData/countries'
import { getEmail } from 'src/selectors/userProfile'
import {
  ApiProduct,
  Attribute,
  AttributeValueMap,
  Country,
  OPTION_LIBRARY,
  Product,
} from 'src/types/index'
import { DEVELOPMENT, PROPOSED } from 'src/constants/productStatus'
import { trackEvent } from 'src/service/analytics/analytics'
import { APIError } from 'types/Api'
import { reloadProductSaga } from './reload'
import { lockProduct, unlockProduct } from 'actions/grid/product/update'
import { SVPAction } from 'actions/svp'
import { duplicateProductSuccess, DUPLICATE_PRODUCT } from 'actions/grid/product/copy'
import { showNotification } from 'actions/notification'
import { isHierarchySlugAtRangeLevel } from 'selectors/hierarchy'

type LinksConfig = {
  colourLink: boolean
  supplierLink: boolean
  crossDepartmentLink: boolean
  matchingProductLink: boolean
}

const errorMessageFor = (errorType: string) =>
  `Sorry something has broken in the tech and we couldn't ${errorType}. Please retry.`

const DUPLICATE_PRODUCT_ERROR_MESSAGE = errorMessageFor('duplicate product for you')

export function* addLinksToDuplicatedProduct(
  linksConfig: LinksConfig,
  sourceProduct: Product,
  newProduct: ApiProduct,
) {
  if (linksConfig.supplierLink) {
    yield call(injectorService.put, `option/${sourceProduct.optionSlug}/attach`, [newProduct.slug])
  }
  if (linksConfig.colourLink && !linksConfig.supplierLink) {
    yield call(injectorService.put, `style/${sourceProduct.styleSlug}/attach`, [
      newProduct.optionSlug,
    ])
  }
  if (linksConfig.crossDepartmentLink) {
    if (sourceProduct.groups?.crossDepartmentGroupSlug) {
      yield call(
        injectorService.patch,
        `products/${sourceProduct.slug}/cross-department-products`,
        {
          productToAssociate: newProduct.slug,
        },
      )
    } else {
      yield call(injectorService.post, 'cross-department-products', [
        sourceProduct.slug,
        newProduct.slug,
      ])
    }
  }
  if (linksConfig.matchingProductLink) {
    if (sourceProduct.groups?.matchingProductGroupSlug) {
      yield call(injectorService.patch, `products/${sourceProduct.slug}/matching-products`, {
        productToAssociate: newProduct.slug,
      })
    } else {
      yield call(injectorService.post, 'matching-products', [sourceProduct.slug, newProduct.slug])
    }
  }
}

export function* duplicateProductSaga(action: SVPAction<typeof DUPLICATE_PRODUCT>) {
  const sourceProduct = action.payload.product
  const email: string = yield select(getEmail)

  const isRange: boolean = yield select(isHierarchySlugAtRangeLevel, sourceProduct.hierarchySlug)
  if (!isRange) {
    const message = 'Please move product to range level to be able to duplicate'
    yield put(
      showNotification({
        type: 'warn',
        message,
      }),
    )

    return
  }

  const destinationStatus = action.grid === OPTION_LIBRARY ? PROPOSED : DEVELOPMENT

  const payload = {
    sourceSlug: sourceProduct.slug,
    email: email,
    status: destinationStatus,
    inheritStyle: !!(action.payload.inheritStyle || action.payload.supplierLink),
  }

  const linksConfig = {
    colourLink: !!action.payload.colourLink,
    supplierLink: !!action.payload.supplierLink,
    crossDepartmentLink: !!action.payload.crossDepartmentLink,
    matchingProductLink: !!action.payload.matchingProductLink,
  }

  trackEvent('product', 'Product Duplicated', sourceProduct.slug)

  try {
    yield put(lockProduct(sourceProduct.slug, action.grid))
    const newApiProduct: ApiProduct = yield call(injectorService.post, 'duplicate', payload)
    yield call(addLinksToDuplicatedProduct, linksConfig, sourceProduct, newApiProduct)
    const linkedApiProduct: ApiProduct = yield call(
      injectorService.get,
      `products/${newApiProduct.slug}`,
    )
    yield call(reloadProductSaga, sourceProduct.slug, action.grid)
    const attributeValues: AttributeValueMap = yield select(getAttributeValues)
    const attributes: Attribute[] = yield select(getAttributes)
    const countries: Country[] = yield select(getCountries)
    const newProduct = apiProductToProductWithDefaults(
      linkedApiProduct,
      attributes,
      attributeValues,
      countries,
    )
    yield put(unlockProduct(sourceProduct.slug, action.grid))
    yield put(duplicateProductSuccess(newProduct, sourceProduct.slug, action.grid))
    yield put(addHighlightedRow(newProduct.slug, action.grid))
  } catch (error) {
    yield put(unlockProduct(sourceProduct.slug, action.grid))
    yield* errorAndNotification(error as APIError, DUPLICATE_PRODUCT_ERROR_MESSAGE)
  }
}

export default function* () {
  yield all([takeEvery(DUPLICATE_PRODUCT, duplicateProductSaga)])
}
