import { AxiosRequestConfig } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { setTag } from 'src/helpers/sentry'

const correlationIDHeader = 'x-correlation-id'

export function correlationIDRequestInterceptor(req: AxiosRequestConfig): AxiosRequestConfig {
  const id = uuidv4()

  req.headers[correlationIDHeader] = id

  // Set correlation ID in Sentry logging
  setTag(correlationIDHeader, id)

  return req
}
