import { CLEAR_IMPORT_ERROR, IMPORT_ERROR } from '../../actions/grid/product/import'
import { SVPAction } from 'actions/svp'

export type ImportsState = {
  errors?: Array<Error>
}

const defaultState = {}

export default (state: ImportsState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case IMPORT_ERROR:
      return { errors: action.payload }
    case CLEAR_IMPORT_ERROR:
      return defaultState
  }

  return state
}
