import * as React from 'react'
import { inputFile } from './ImageInput.scss'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { parseFile } from 'src/service/image'
import { OnChange, OnError, OnParsing } from 'src/service/image/parseFile'

type RenderProps = {
  id: string
  isParsing: boolean
  onClick: () => void
  onKeyDown: (e: React.KeyboardEvent) => void
}

type Props = {
  id: string
  currentIndex?: number
  onChange: OnChange
  onParsing?: OnParsing
  onError: OnError
  render: (props: RenderProps) => React.ReactNode
  dataCy?: string
}

type State = {
  isParsing: boolean
}

export class ImageInput extends React.Component<Props, State> {
  inputRef: any

  state = {
    isParsing: false,
  }

  onParsing = (isParsing: boolean, index: number) => {
    this.setState({ isParsing })
    this.props.onParsing && this.props.onParsing(isParsing, index || 0)
  }

  onFileChange =
    (onChange: OnChange, onParsing: OnParsing, onError: OnError) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files as FileList

      if (!files) {
        return
      }

      const file = files[0]

      if (file) {
        parseFile(onChange, onParsing, onError, file, this.props.currentIndex)
      }
    }

  render() {
    const { id, onChange, onError, render, dataCy } = this.props
    const { isParsing } = this.state

    const onClick = () => {
      this.inputRef && this.inputRef.click()
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === ENTER_KEY) {
        e.preventDefault()
        this.inputRef && this.inputRef.click()
      }
    }

    return (
      <>
        <input
          id={id}
          className={inputFile}
          type="file"
          accept=".ai,.pdf,.png,.jpg,.jpeg"
          onChange={this.onFileChange(onChange, this.onParsing, onError)}
          ref={(node) => (this.inputRef = node)}
          data-cy={dataCy || ''}
        />
        {render({ onClick, onKeyDown, id, isParsing })}
      </>
    )
  }
}
