import React, { Component } from 'react'
import classNames from 'classnames'

import Column from 'src/components/SizeModal/Column/Column'
import Section from 'src/components/SizeModal/Section/Section'

import { skuSpan, inactive } from './SkusColumn.scss'
import { Size } from '../../../../../../types/Size'

type Props = {
  sizes: Size[]
  group: string
  showColumnTitle?: boolean
  isLastSection?: boolean
}

export class SkusColumn extends Component<Props> {
  render() {
    const { showColumnTitle } = this.props

    return (
      <Column title="SKU #" showHeader={showColumnTitle} testId="sku_column">
        {this.groups()}
      </Column>
    )
  }

  groups = () => {
    const { sizes = [], group, isLastSection } = this.props

    if (!sizes.length) {
      return <></>
    }

    return (
      <Section
        key={group}
        sizeGroup={group}
        isLastSection={isLastSection}
        testId={`sku_column_${group}_section`}
      >
        {sizes.map((size) => {
          const { sku, skuActive } = size

          const className = classNames(skuSpan, {
            [inactive]: !skuActive,
          })

          return (
            <span className={className} key={`${group}_${size.name}_${sku}`}>
              {sku}
            </span>
          )
        })}
      </Section>
    )
  }
}
