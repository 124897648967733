import { GetAuth, authenticationActions, isTokenValid } from '@ri-digital/auth0-authenticator'

import { getStore } from 'src/store/store'
import { APIError } from 'types/Api'
import { addBreadcrumb, logError } from '../../helpers/sentry'

const injectoryAPITokenName = 'injector'

export const getAuthHeaders = async function () {
  const auth = GetAuth()
  const store = getStore()

  try {
    const idToken = auth.getIDToken()

    // Check to see if the user is still logged in
    if (!isTokenValid(idToken)) {
      addBreadcrumb('auth', 'ID token was invalid when getting auth headers')

      store.dispatch(authenticationActions.setUnauthenticated())
      auth.clearStoredData()

      return
    } else {
      addBreadcrumb('auth', 'ID token is valid')
    }

    let accessToken = auth.getAccessTokensByKey(injectoryAPITokenName)

    // Check to see if the token is still valid
    if (!isTokenValid(accessToken)) {
      addBreadcrumb('auth', 'Renewing injector access token')

      const result = await auth.renewAccessToken(injectoryAPITokenName)
      accessToken = result[`${injectoryAPITokenName}_token`]

      store.dispatch(authenticationActions.setToken(injectoryAPITokenName, accessToken))

      addBreadcrumb('auth', 'Successfully renewed injector access token')
    } else {
      addBreadcrumb('auth', 'Access token is valid')
    }

    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
  } catch (error) {
    addBreadcrumb('auth', 'Error occured getting auth header')
    logError(error as APIError)
    store.dispatch(authenticationActions.setUnauthenticated())
    auth.clearStoredData()
  }
}

export default getAuthHeaders
