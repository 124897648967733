import { AppState } from '../../reducers/root'
import { Season } from 'src/types/index'

export const getSeasons = (state: AppState): Season[] => {
  return state.injectorUI.referenceData.seasons
}

export const getCurrentSeasons = (state: AppState): Season[] => {
  return state.injectorUI.referenceData.seasons.filter((el) => el.isCurrent)
}
