import React from 'react'
import { DeprecatedIcon } from 'assets/icons/DeprecatedIcon'
import { NonDeprecatedIcon } from 'assets/icons/NonDeprecatedIcon'
import { RISpinner } from 'components/RISpinner/RISpinner'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import {
  deprecatedIcon,
  rangeRow,
  rangesContainer,
  rangeSizes,
  rangesNameContainer,
  rangesSizesContainer,
  rangeTitle,
  size,
  sizeIcon,
  sizeName,
} from './Sizes.scss'
import { SizesProps } from './SizesContainer'
import { spinnerContainer } from '../UserSettings.scss'

export const Sizes = (props: SizesProps): JSX.Element => {
  React.useEffect(() => {
    if (props.selectedSideListSlug) {
      props.getDepartmentRangesWithSizes(props.selectedSideListSlug)
    }
  }, [props.selectedSideListSlug])

  const onToggleDeprecated = (idSizeDenominations: number, isDeprecated: boolean): void => {
    props.updateDepartmentRangeSize(idSizeDenominations, isDeprecated)
  }

  if (props.isFetchingSizes) {
    return (
      <div className={spinnerContainer}>
        <RISpinner />
      </div>
    )
  }

  if (!props.selectedSideListSlug) {
    return <div>Please select a department</div>
  }

  if (!props.rangesWithSizes.length) {
    return <div>Department has no ranges</div>
  }

  return (
    <ScrollSync>
      <div className={rangesContainer} data-cy="ranges-container">
        <ScrollSyncPane group="one">
          <div className={rangesNameContainer} data-cy="ranges-names-container">
            {props.rangesWithSizes.map((rangeWithSize, index) => (
              <div key={`${rangeWithSize.rangeSlug}-${index}`} className={rangeRow}>
                <div className={rangeTitle}>{rangeWithSize.rangeTitle}</div>
              </div>
            ))}
          </div>
        </ScrollSyncPane>
        <ScrollSyncPane group="one">
          <div className={rangesSizesContainer} data-cy="ranges-sizes-container">
            {props.rangesWithSizes.map((rangeWithSize, index) => (
              <div key={`${rangeWithSize.rangeSlug}-${index}`} className={rangeRow}>
                <div className={rangeSizes} data-cy="range-sizes">
                  {rangeWithSize.sizes.map((idSizeDenominations) => (
                    <div
                      key={idSizeDenominations}
                      className={size}
                      data-cy="available-size"
                      onClick={(): void =>
                        onToggleDeprecated(
                          idSizeDenominations,
                          !props.sizesDeprecationMap?.[idSizeDenominations]?.deprecated,
                        )
                      }
                    >
                      <div className={sizeIcon}>
                        {props.sizesDeprecationMap?.[idSizeDenominations]?.deprecated ? (
                          <DeprecatedIcon className={deprecatedIcon} />
                        ) : (
                          <NonDeprecatedIcon className={deprecatedIcon} />
                        )}
                      </div>
                      <div className={sizeName}>
                        {props.sizesDeprecationMap?.[idSizeDenominations]?.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </ScrollSyncPane>
      </div>
    </ScrollSync>
  )
}
