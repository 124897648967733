import axios, { AxiosRequestConfig, ResponseType } from 'axios'
import { apiErrorInterceptor } from './interceptors/apiErrors'
import { correlationIDRequestInterceptor } from './interceptors/correlationId'

axios.interceptors.request.use(correlationIDRequestInterceptor)
// Intercept any API errors from the response
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.data) {
      return apiErrorInterceptor(error.response)
    }
    return apiErrorInterceptor(error)
  },
)

export type Header = Record<string, string | number | undefined>

const request = (url: string, options: AxiosRequestConfig) =>
  axios.request({ ...options, url: url }).then(({ data, status }) => ({ data, status }))

const requestWithHeaders = (url: string, options: AxiosRequestConfig) =>
  axios
    .request({ ...options, url: url })
    .then(({ data, headers, status }) => ({ data, headers, status }))

const post = (path: string, data: any, headers: Header) =>
  request(path, { method: 'POST', data, headers })

const get = (
  path: string,
  params?: AxiosRequestConfig,
  headers?: Header,
  responseType?: ResponseType,
) => request(path, { method: 'GET', params, headers, responseType })

const getWithHeaders = (
  path: string,
  params?: AxiosRequestConfig,
  headers?: Header,
  responseType?: ResponseType,
) => requestWithHeaders(path, { method: 'GET', params, headers, responseType })

const patch = (path: string, data: any, headers: Header) =>
  request(path, { method: 'PATCH', data, headers })

const put = (path: string, data: any, headers: Header) =>
  request(path, { method: 'PUT', data, headers })

const doDelete = (path: string, headers: Header) => request(path, { method: 'DELETE', headers })

export default { get, getWithHeaders, post, patch, put, delete: doDelete }
