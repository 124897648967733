import React, { Component } from 'react'
import { Tag } from 'src/components/Tag'
import { container, tag, icon, title, deprecatedValue } from './DropdownFilter.scss'
import Dropdown from 'src/components/Dropdown/Dropdown'
import { FilterIcon } from 'src/assets/icons'
import { setFocus } from 'src/service/ref'
import { Filter } from 'src/actions/grid/exactFilters'
import { AttributeValue, GridType } from 'src/types/index'
import { DropdownOption } from 'src/types/Dropdowns'
import { uiDropdownItemsMapper } from 'src/service/mappers/uiDropdownItemsMapper'

type Props = {
  property: string
  displayName: string
  dropdownItems: DropdownOption[]
  filters: Filter[]
  deprecatedItemValues: AttributeValue[]
  gridName: GridType
  addExactFilter: (filter: Filter) => void
  removeExactFilter: (filter: Filter) => void
  fetchDropdownItems: (property: string, gridName: GridType) => void
}

export class DropdownFilter extends Component<Props> {
  node: any

  componentDidMount() {
    // @ts-ignore it should be setFocus(this.node.current) but will fix these later
    setFocus(this.node)
    this.props.fetchDropdownItems(this.props.property, this.props.gridName)
  }

  render() {
    const {
      property,
      dropdownItems,
      displayName,
      filters,
      removeExactFilter,
      deprecatedItemValues,
    } = this.props

    const filterValues = filters.map((f) => f.value)
    const itemsDropdownMapper = uiDropdownItemsMapper(property)

    const filteredItems = dropdownItems.filter(
      (item) => !filterValues.includes(item.value as string) && item.value !== '',
    )

    filteredItems.forEach((item: DropdownOption) => {
      if (!deprecatedItemValues) {
        return
      }
      if (item.deprecated !== undefined) {
        return
      }
      const foundDeprecatedItem = deprecatedItemValues.find(
        (deprecatedValue) => item.value === deprecatedValue.value,
      )
      item.deprecated = !!foundDeprecatedItem
    })

    return (
      <div className={container} ref={(node) => (this.node = node)} tabIndex={-1}>
        <div className={title}>
          <span>{`Filter ${displayName}`}</span>
          <FilterIcon className={icon} />
        </div>

        {/* @ts-ignore until Dropdown is converted to TS  */}
        <Dropdown
          {...(itemsDropdownMapper ? itemsDropdownMapper.getProps() : {})}
          name={property}
          dataCy={`filter-dropdown-${property}`}
          items={filteredItems}
          onItemSelected={this.addFilter}
          createOption={(item: DropdownOption) => ({ ...item, data: item })}
          isValid
          placeholder={`Select ${displayName}`}
          customControlStyles={{
            '&:hover': {
              borderColor: '#26B3AD',
            },
          }}
        />
        {filters.map((filter, index) => (
          <Tag
            key={index}
            deprecatedClassName={filter.deprecated && deprecatedValue}
            className={tag}
            value={filter.label}
            onClose={() => removeExactFilter(filter)}
          />
        ))}
      </div>
    )
  }

  addFilter = (item?: DropdownOption) => {
    if (!item || item === null) {
      return
    }

    const filter = {
      ...item,
      label: item.label || '',
      value: item.value as string,
      property: this.props.property,
      type: this.props.displayName,
      deprecated: !!item.deprecated,
    }

    this.props.addExactFilter(filter)
  }
}
