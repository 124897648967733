import { Config } from 'src/helpers/config'
import { ScrollUpdate } from 'src/types/Scroll'

const getPageSize = () => {
  return Config().Get('pageSize') as number
}

const getScrollThreshold = () => {
  return Config().Get('infiniteScrollThreshold') as number
}

export const shouldLoadNextPage = (scrollUpdate: ScrollUpdate): boolean => {
  const { lastRowInView, lastPageLoaded, lastPage } = scrollUpdate

  if (lastPageLoaded === lastPage) return false

  const totalProductsLoaded = getPageSize() * (lastPageLoaded + 1)
  const relativeThreshold = totalProductsLoaded - getScrollThreshold()

  return lastRowInView + 1 > relativeThreshold
}
