import React from 'react'
import { list, departmentAttributeListItem } from './Department.scss'
import { DepartmentAttribute } from './DepartmentAttribute/DepartmentAttribute'
import { FocusableListItem } from 'src/components/FocusableListItem/FocusableListItem'
import { AttributeRenderSpec } from 'src/types/index'
import { DepartmentViews } from 'src/types/DepartmentSpec'
import {
  ApiDepartmentSpec,
  ApiRule,
  ApiDepartmentAttribute,
} from 'src/types/responses/departmentSpec'

type Props = {
  attributesSpecs: AttributeRenderSpec[]
  departmentSpecification: ApiDepartmentSpec
  currentSelection?: string
  viewsReferenceData: DepartmentViews
  changeRules: (val: string, rule: ApiRule) => void
  changeViews: (val: string, views: string[]) => void
}

const getDepartmentAttribute = (
  attributesSpecs: AttributeRenderSpec[],
  attribute: ApiDepartmentAttribute,
) => {
  const spec = attributesSpecs.find((spec) => spec.property === attribute.name)
  return {
    property: spec ? spec.property : '',
    displayName: spec ? spec.displayName : '',
    views: attribute.views,
    rule: attribute.rule,
  }
}

export const Department = ({
  departmentSpecification,
  attributesSpecs,
  currentSelection,
  viewsReferenceData,
  changeRules,
  changeViews,
}: Props): JSX.Element => (
  <ul data-cy="department-container" className={list}>
    {departmentSpecification.attributes
      .filter((attribute) => attributesSpecs.find((spec) => spec.property === attribute.name))
      .map((attribute) => getDepartmentAttribute(attributesSpecs, attribute))
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map((spec, index) => (
        <FocusableListItem
          key={index}
          dataCY={spec.displayName}
          className={departmentAttributeListItem}
          hasFocus={spec.property === currentSelection}
        >
          <DepartmentAttribute
            name={spec.property}
            displayName={spec.displayName}
            views={spec.views}
            rules={spec.rule}
            viewsReferenceData={viewsReferenceData}
            changeRules={changeRules}
            changeViews={changeViews}
          />
        </FocusableListItem>
      ))}
  </ul>
)
