import { AppState } from 'src/reducers/root'
import {
  AttributeRenderSpec,
  AttributeValue,
  CellType,
  ColumnValue,
  RenderSpec,
} from 'src/types/index'
import { INJECTOR_ATTRIBUTE_SOURCE } from 'src/constants/attributes'
import { AttributeSource } from 'src/types/Attributes'

export const getColumnsValues = (state: AppState): ColumnValue[] =>
  state.injectorUI.referenceData.columns

export const getColumnRenderSpecs = (state: AppState): RenderSpec[] =>
  state.injectorUI.referenceData.columns.map((column) => {
    return {
      property:
        column.content === 'property'
          ? column.propertyName || 'error'
          : column.attributeName || 'error',
      type: columnTypeFrom(column, state),
      displayName: column.displayName,
      filterType: column.filterType,
      readOnly: column.readOnly,
      sortable: column.sortable,
      columnWidth: column.width,
      companyWide:
        column.content === 'property' ? true : isCompanyWide(state, column.attributeName),
    }
  })

export const getColumnSlug = (state: AppState, accessor: string): string => {
  const foundColumn = state.injectorUI.referenceData.columns.find(
    ({ propertyName, attributeName }) => propertyName === accessor || attributeName === accessor,
  )

  return foundColumn ? foundColumn.slug : ''
}

export const getColumnType = (state: AppState, accessor: string): CellType => {
  const foundColumn = state.injectorUI.referenceData.columns.find(
    ({ propertyName, attributeName }) => propertyName === accessor || attributeName === accessor,
  )

  return foundColumn ? columnTypeFrom(foundColumn, state) : 'error'
}

export const getAttributeRenderSpecs = (state: AppState): AttributeRenderSpec[] => {
  return state.injectorUI.referenceData.columns
    .filter((column) => column.content === 'attribute')
    .map((column) => {
      return {
        property: column.attributeName || 'error',
        type: getAttributeType(state, column.attributeName),
        displayName: column.displayName,
        filterType: column.filterType,
        readOnly: column.readOnly,
        sortable: column.sortable,
        columnWidth: column.width,
        duplicable: isAttributeDuplicable(state, column.attributeName),
        companyWide: isCompanyWide(state, column.attributeName),
        source: getAttributeSource(state, column.attributeName),
        slug: getAttributeSlug(state, column.attributeName),
        values: getAttributeValues(state, column.attributeName),
      }
    })
}

type AttributeName = string | null | undefined

const columnTypeFrom = (column: ColumnValue, state: AppState): CellType =>
  column.content === 'property'
    ? column.propertyName || 'error'
    : getAttributeType(state, column.attributeName)

const getAttributeType = (state: AppState, attributeName: AttributeName): CellType => {
  return attributeName
    ? state.injectorUI.referenceData.attributes.list[attributeName].type
    : 'error'
}

const isAttributeDuplicable = (state: AppState, attributeName: AttributeName): boolean => {
  return attributeName
    ? state.injectorUI.referenceData.attributes.list[attributeName].duplicable
    : false
}

const getAttributeSlug = (state: AppState, attributeName: AttributeName): string =>
  attributeName ? state.injectorUI.referenceData.attributes.list[attributeName].slug : 'error'

const isCompanyWide = (state: AppState, attributeName: AttributeName): boolean =>
  attributeName ? state.injectorUI.referenceData.attributes.list[attributeName].companyWide : false

const getAttributeSource = (state: AppState, attributeName: AttributeName): AttributeSource =>
  attributeName
    ? state.injectorUI.referenceData.attributes.list[attributeName].source
    : INJECTOR_ATTRIBUTE_SOURCE

const getAttributeValues = (state: AppState, attributeName: AttributeName): AttributeValue[] =>
  attributeName ? state.injectorUI.referenceData.attributes.values[attributeName] : []
