// @ts-nocheck as the attribute value vs dropdown options differences

import { connect } from 'react-redux'
import DropdownCellRenderer from '../DropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { AttributeValue } from 'src/types/index'
import { getWsReadyWeekValues } from 'src/service/product'

type OwnProps = {
  accessor: string
}

const mapStateToProps = () => ({
  dropdownItems: getWsReadyWeekValues(),
  createPatchItem: (item: AttributeValue) => ({
    apiValue: item.value,
    storeValue: item.value,
  }),
})

const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => {
  return {
    ...stateProps,
    ...ownProps,
    noSort: true,
    createOption: (item: AttributeValue) => ({
      value: item.value,
      label: item.value,
      data: item,
    }),
  }
}

export const WsReadyWeekDropdownCellRendererContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(DropdownCellRenderer)
