import React from 'react'
import TextArea from 'src/components/TextArea'
import {
  cell,
  inEditMode,
  cellValue,
  scrollWrapper,
  textAreaWrapper,
} from './LongTextAreaCellRenderer.scss'
import classNames from 'classnames'
import { Product } from 'src/types/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import { getLongTextValidationErrors } from 'src/service/product/validateUtils'

type Props = {
  editMode: boolean
  accessor: string
  product: Product
  updateProduct: (payload: PatchPayload) => void
  exitEditMode: (exit: boolean) => void
  mandatory: boolean
}

export const LongTextAreaCellRenderer = ({
  editMode,
  accessor,
  product,
  updateProduct,
  exitEditMode,
  mandatory,
}: Props): JSX.Element => {
  const update = (value: string, isValid: boolean): void => {
    if (isValid) {
      updateProduct({
        productSlug: product.slug,
        patchFields: {
          store: { [accessor]: value },
        },
      })
    }
    exitEditMode(true)
  }

  const value = product[accessor] || ''

  const cellClass = classNames(cell, {
    [inEditMode]: editMode,
  })

  const validator = (value: string): string[] => {
    return getLongTextValidationErrors(value, mandatory)
  }

  return (
    <div className={scrollWrapper}>
      <div className={cellClass}>
        {editMode ? (
          <div className={textAreaWrapper}>
            <TextArea
              name={accessor}
              value={value}
              onSave={update}
              validator={validator}
              isMandatory={mandatory}
            />
          </div>
        ) : (
          <div className={cellValue}>{value}</div>
        )}
      </div>
    </div>
  )
}
