import React from 'react'

interface Props {
  className: string
}

export const CloseCrossCircle = ({ className }: Props): JSX.Element => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 863.02 853.81">
    <title>closeCrossCircle</title>
    <circle
      className={className}
      cx="429.18"
      cy="420.8"
      r="400"
      fill="#ffffff"
      stroke="#000"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
    <line
      x1="304.27"
      y1="301.2"
      x2="558.73"
      y2="555.65"
      fill="none"
      stroke="#505050"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
    <line
      x1="304.27"
      y1="555.65"
      x2="558.73"
      y2="301.2"
      fill="none"
      stroke="#505050"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
  </svg>
)
