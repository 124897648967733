import React, { ReactElement } from 'react'
import classnames from 'classnames'

import {
  defaultRadioButtonClass,
  defaultOptionClass,
  hideInput,
  containerSelectable,
} from './RadioButtons.scss'
import { ENTER_KEY } from '../../constants/keyCodes'

interface GridOption {
  value: string
  dataCy: string
  extendedClickArea?: boolean
  render: () => ReactElement
}

type Props = {
  name: string
  options: GridOption[]
  checkedButton: string
  onChange: (val: string, name?: string) => void
  optionContainerClass?: string
  radioButtonClass?: string
}

const onKeyDown =
  (value: string, onChange: (val: string, name: string) => void, name: string) =>
  (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === ENTER_KEY) onChange(value, name)
  }

export const RadioButtons = ({
  onChange,
  name,
  options,
  checkedButton,
  optionContainerClass = defaultOptionClass,
  radioButtonClass = defaultRadioButtonClass,
}: Props) => (
  <div>
    {options.map((option, index) => {
      const containerClass = classnames(optionContainerClass, {
        [containerSelectable]: option.extendedClickArea,
      })
      return (
        <div
          key={index}
          className={containerClass}
          {...(option.extendedClickArea ? { onClick: () => onChange(option.value, name) } : {})}
        >
          <label>
            <input
              tabIndex={-1}
              className={hideInput}
              type="radio"
              name={name}
              value={option.value}
              checked={checkedButton === option.value}
              onChange={(e) => onChange(e.target.value, name)}
              data-cy={`input-${option.dataCy}`}
            />
            <span
              tabIndex={0}
              data-cy={option.dataCy}
              onKeyDown={onKeyDown(option.value, onChange, name)}
              className={radioButtonClass}
            />
          </label>
          {option.render()}
        </div>
      )
    })}
  </div>
)
