import { connect } from 'react-redux'
import { AppState } from 'src/reducers/root'
import { SeasonTool } from './SeasonTool'
import { getSeasons } from 'src/selectors/referenceData/seasons'

const mapStateToProps = (state: AppState) => ({
  seasons: getSeasons(state),
})

export const SeasonToolContainer = connect(mapStateToProps)(SeasonTool)
