import {
  Attributes,
  GridType,
  TreeHierarchyItem,
  ColumnValue,
  Country,
  Season,
} from 'src/types/index'

export const FETCH_REFERENCE_DATA = 'FETCH_REFERENCE_DATA'
export const FETCH_REFERENCE_DATA_SUCCESS = 'FETCH_REFERENCE_DATA_SUCCESS'
export const FETCH_REFERENCE_DATA_FAILURE = 'FETCH_REFERENCE_DATA_FAILURE'

export type FetchReferenceDataActions = ReturnType<
  typeof fetchReferenceData | typeof fetchReferenceDataSuccess | typeof fetchReferenceDataFailure
>

type ViewRef = {
  slug: string
  grid: GridType
  name: string
}

type ViewRefs = {
  [key: string]: ViewRef
}

export const fetchReferenceData = () =>
  <const>{
    type: FETCH_REFERENCE_DATA,
  }

export const fetchReferenceDataSuccess = (
  views: ViewRefs,
  hierarchy: TreeHierarchyItem[],
  attributes: Attributes,
  columns: ColumnValue[],
  countries: Country[],
  seasons: Season[],
) =>
  <const>{
    type: FETCH_REFERENCE_DATA_SUCCESS,
    views,
    hierarchy,
    attributes,
    columns,
    countries,
    seasons,
  }

export const fetchReferenceDataFailure = () =>
  <const>{
    type: FETCH_REFERENCE_DATA_FAILURE,
  }
