import React, { Component } from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import classNames from 'classnames'

import Column from 'src/components/SizeModal/Column/Column'
import Section from 'src/components/SizeModal/Section/Section'
import Dropdown from 'src/components/Dropdown'
import { PriceTypes } from 'src/types/Price'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { CurrencyKeys, DiscreteValue, Size } from 'src/types/index'
import { SELLING_PRICE, SUPPLIER_COST } from 'src/constants/price'
import { POUND_KEY } from 'src/constants/currency'
import { codesToPrefixes } from 'service/excel/exporter/valueMappers'
import { DropdownOption } from 'src/types/Dropdowns'

import { priceInput, priceDropdown, valueContainer, disabled } from './PriceColumn.scss'

interface Props {
  sizes: Size[]
  priceKey: PriceTypes
  priceValue: number
  supplierCostCurrency?: CurrencyKeys
  columnTitle: string
  group: ProductSizeGroups
  showColumnTitle?: boolean
  isLastSection?: boolean
  isReadOnly?: boolean
  sellingPriceOptions?: DiscreteValue[]
  isHierarchyInBeautyAndBrandsDivision: boolean
  onPriceChange: (group: ProductSizeGroups, priceKey: PriceTypes, priceValue: number) => void
  onCurrencyChange?: (currency: CurrencyKeys) => void
}

export class PriceColumn extends Component<Props> {
  render(): JSX.Element {
    const { showColumnTitle, columnTitle, priceKey, supplierCostCurrency, onCurrencyChange } =
      this.props

    return (
      <Column
        title={columnTitle}
        showHeader={showColumnTitle}
        testId={`price-${priceKey}`}
        {...(priceKey === SUPPLIER_COST
          ? { currency: supplierCostCurrency, onCurrencyChange }
          : {})}
      >
        {this.groups()}
      </Column>
    )
  }

  shouldShowTextPrice(): boolean {
    const { sizes, priceKey, isReadOnly } = this.props
    return (!!sizes.some((size) => !!size.sku) && priceKey !== SUPPLIER_COST) || isReadOnly
  }

  onItemSelected = (priceOption: DiscreteValue): void => {
    const { priceKey, group, onPriceChange } = this.props
    onPriceChange(group, priceKey, priceOption.value)
  }

  onPriceChange = ({ floatValue = 0 }: NumberFormatValues): void => {
    const { priceKey, group, onPriceChange } = this.props
    onPriceChange(group, priceKey, floatValue)
  }

  createOption = (item: DiscreteValue): DropdownOption => ({
    value: item.value,
    label: item.label,
    data: item,
  })

  lookupValue = (value: string): DiscreteValue => {
    const { sellingPriceOptions } = this.props
    return value
      ? sellingPriceOptions?.find((dropdownItem) => dropdownItem.value === value)
      : undefined
  }

  groups = (): JSX.Element => {
    const {
      sizes,
      group,
      isLastSection,
      priceKey,
      priceValue,
      supplierCostCurrency,
      isReadOnly,
      sellingPriceOptions,
      isHierarchyInBeautyAndBrandsDivision,
    } = this.props
    if (!sizes.length) {
      return <></>
    }

    const textClassNames = classNames(valueContainer, {
      [disabled]: isReadOnly,
    })

    const customContainerStyles = {
      border: '1px solid #eef0ef',
      '&:active': {
        borderColor: '#26b3ad',
      },
      '&:focus': {
        borderColor: '#26b3ad',
      },
      '&:hover': {
        borderColor: '#26b3ad',
      },
    }

    const customControlStyles = {
      borderStyle: 'none',
      margin: '0px',
    }

    return (
      <Section
        key={group}
        sizeGroup={group}
        isLastSection={isLastSection}
        testId={`${priceKey}_column_${group}_section`}
      >
        <>
          {this.shouldShowTextPrice() ? (
            <div className={textClassNames}>
              {`${codesToPrefixes[POUND_KEY]}${priceValue.toFixed(2)}`}
            </div>
          ) : priceKey === SELLING_PRICE && !isHierarchyInBeautyAndBrandsDivision ? (
            <div className={classNames(valueContainer, priceDropdown)}>
              <Dropdown
                customControlStyles={customControlStyles}
                customContainerStyles={customContainerStyles}
                // @ts-ignore
                items={sellingPriceOptions}
                onItemSelected={this.onItemSelected}
                // @ts-ignore
                selectedItem={this.lookupValue(priceValue)}
                // @ts-ignore
                createOption={this.createOption}
                name={SELLING_PRICE}
                isValid
                noSort={true}
                customFilterOption={({ value }: DiscreteValue, searchString: string): boolean =>
                  value.toString().includes(searchString)
                }
              />
            </div>
          ) : (
            <div className={classNames(valueContainer, priceInput)}>
              <NumberFormat
                displayType="input"
                value={priceValue}
                thousandSeparator
                prefix={codesToPrefixes[supplierCostCurrency || POUND_KEY]}
                decimalScale={2}
                fixedDecimalScale
                allowNegative={false}
                onValueChange={this.onPriceChange}
                onFocus={(e): void => {
                  e.target.selectionStart = 1
                }}
              />
            </div>
          )}
        </>
      </Section>
    )
  }
}
