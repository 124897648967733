import { connect } from 'react-redux'
import { CopyToModal } from './CopyToModal'
import { closeCopyToNavigation, goToCopiedProductInDevTracker } from 'src/actions/grid/product/copy'

const mapDispatchToProps = (dispatch: any) => ({
  onGoToItem: (productSlug: string, hierarchySlug: string) =>
    dispatch(goToCopiedProductInDevTracker(productSlug, hierarchySlug)),
  onClose: () => dispatch(closeCopyToNavigation()),
})

export const CopyToModalContainer = connect(null, mapDispatchToProps)(CopyToModal)
