import React from 'react'
import { useState } from 'react'
import { Modal } from 'src/components/Modal/Modal'
import {
  commsModalContainer,
  documentContainer,
  exitButtonContainer,
  markAsReadButtonContainer,
  exitModalButton,
  markAsReadButton,
  commsModalContainerNoFile,
} from './CommsDocumentModal.scss'
import { Document, Page } from 'react-pdf'
import PrimaryButton from 'components/PrimaryButton'
import { IntentionalAny } from 'types'
import { isMobileEnv } from 'helpers/envs'

interface Props {
  file: File
  onCloseCommsDocumentModal: () => void
  onMarkAsRead: () => void
}
export const CommsDocumentModal = (props: Props): JSX.Element => {
  const [numPages, setNumPages] = useState(0)

  const onDocumentLoadSuccess = ({ numPages }: IntentionalAny): void => setNumPages(numPages)
  const onClose = (): void => props.onCloseCommsDocumentModal()
  const onMarkAsRead = (): void => {
    props.onCloseCommsDocumentModal()
    props.onMarkAsRead()
  }

  return (
    <Modal
      modalClass={props.file ? commsModalContainer : commsModalContainerNoFile}
      onCloseFn={props.onCloseCommsDocumentModal}
    >
      <div className={exitButtonContainer}>
        <button className={exitModalButton} onClick={onClose}>
          x
        </button>
      </div>

      <div className={documentContainer}>
        <Document
          file={props.file}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from({ length: numPages }, (_, index) => (
            <Page
              scale={isMobileEnv() ? 0.45 : 1}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={true}
              renderTextLayer={true}
            />
          ))}
          <div className={markAsReadButtonContainer}>
            <PrimaryButton colour="green" className={markAsReadButton} onClick={onMarkAsRead}>
              {' '}
              Yes, I`ve read this!{' '}
            </PrimaryButton>
          </div>
        </Document>
      </div>
    </Modal>
  )
}
