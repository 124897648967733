import { SVPAction } from 'actions/svp'
import {
  DESELECT_HIERARCHY,
  SELECT_HIERARCHY,
  UPDATE_EXPANDED_HIERARCHY_NODES,
  CLEAR_AND_SELECT_HIERARCHY,
  SELECT_HIERARCHIES,
} from 'src/actions/grid/hierarchy'

export type HierarchyReducerState = {
  expandedNodes: string[]
  selected: string[]
}

const defaultState = {
  expandedNodes: [],
  selected: [],
}

const selectMultipleHierarchies = (state: HierarchyReducerState, slugs: string[]) => {
  const newSelected: string[] = slugs.filter((slug) => state.selected.indexOf(slug) === -1)
  return { ...state, selected: [...state.selected, ...newSelected] }
}

export const hierarchy = (state: HierarchyReducerState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case UPDATE_EXPANDED_HIERARCHY_NODES:
      return { ...state, expandedNodes: action.payload }
    case SELECT_HIERARCHY:
      if (state.selected.indexOf(action.slug) === -1) {
        return {
          ...state,
          selected: [...state.selected, action.slug],
        }
      }
      return state
    case SELECT_HIERARCHIES:
      return selectMultipleHierarchies(state, action.slugs)
    case DESELECT_HIERARCHY: {
      const index = state.selected.indexOf(action.slug)
      if (index !== -1) {
        const selected = [...state.selected]
        selected.splice(index, 1)
        return { ...state, selected }
      }
      return state
    }
    case CLEAR_AND_SELECT_HIERARCHY:
      return { ...state, selected: [action.slug] }
    default:
      return state
  }
}
