import moment from 'moment'
import XLSX from 'xlsx'

import { ExportPayload, GridType, RenderSpec, DEV_TRACKER } from 'src/types/index'
import { mapToExcelFormat } from './dataMapper'
import { LookupHierarchyState } from 'src/reducers/referenceData/hierarchy/lookupTable'

const dateFormat = 'DD-MMM-YY-HH.mm'

export const exportProducts = (
  exportPayload: ExportPayload,
  hierarchyLookupTable: LookupHierarchyState,
  columnSpec: RenderSpec[],
  grid: GridType,
): void => {
  const [sheetName, fileName] =
    grid === DEV_TRACKER
      ? ['Development Tracker', 'developmenttracker']
      : ['Option Library', 'optionlibrary']

  const sheet = XLSX.utils.aoa_to_sheet(
    mapToExcelFormat(exportPayload, hierarchyLookupTable, columnSpec, grid),
  )
  const excelFile = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(excelFile, sheet, sheetName)
  formatCells(excelFile.Sheets[sheetName])
  XLSX.writeFile(excelFile, `${fileName}-${moment().format(dateFormat)}.xlsx`)
}

export const formatCells = (sheet: XLSX.WorkSheet): void => {
  Object.keys(sheet).forEach((key) => {
    const currentCell = sheet[key]
    if (typeof currentCell.v === 'string') {
      formatCell(currentCell)
    }
  })
}

const isCurrencyFormat = (currentCell: XLSX.CellObject): boolean => {
  const v = currentCell.v as string
  return ['€', '$', '£'].some((prefix) => v?.startsWith(prefix))
}

const getFloatFrom = (currencyValue: string): number => parseFloat(currencyValue?.slice(1))

const getPrefixFrom = (originalValue: string): string => originalValue?.slice(0, 1)

const formatCell = (currentCell: XLSX.CellObject): void => {
  const v = currentCell.v as string
  if (v?.startsWith('http')) {
    currentCell.l = { Target: v, Tooltip: v }
  } else if (isCurrencyFormat(currentCell)) {
    const originalValue = v
    currentCell.v = getFloatFrom(originalValue)
    currentCell.z = `${getPrefixFrom(originalValue)}0.00`
    currentCell.t = 'n'
  }
}
