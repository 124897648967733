import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { parseFile } from '../../service/excel/importer/importer'
import { errorAndNotification } from '../error'
import { getAttributeValues } from '../../selectors/referenceData/attributes'
import { getColumnRenderSpecs } from '../../selectors/referenceData/columns'
import { getProductAttributes, parseState } from '../../service/excel/importer/dataMapper'
import isEmpty from 'lodash/isEmpty'
import { IMPORT_PRODUCTS, importError } from '../../actions/grid/product/import'
import { injectorService } from '../../service/injector/injectorService'
import { showNotification } from '../../actions/notification'
import { AttributeValueMap, OPTION_LIBRARY, Product, RenderSpec } from 'src/types/index'
import { trackEvent } from '../../service/analytics/analytics'
import { GetAuth } from '@ri-digital/auth0-authenticator'
import { fetchFirstProductsPage } from 'actions/grid/product/fetch'
import { APIError } from 'types/Api'
import { SVPAction } from 'actions/svp'

export function* importProductsSaga(action: SVPAction<typeof IMPORT_PRODUCTS>) {
  let rawProducts: Product[]
  try {
    rawProducts = yield call(parseFile, action.file)
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      'Invalid file type. Please use excel format',
    )
    return
  }

  const attributeValues: AttributeValueMap = yield select(getAttributeValues)
  const columnSpecs: RenderSpec[] = yield select(getColumnRenderSpecs)
  const parsedState: Record<string, any> = yield call(parseState, attributeValues, columnSpecs)

  const attributesAndLogs: Record<string, any> = yield call(
    getProductAttributes,
    rawProducts,
    parsedState,
  )

  const productPayload = Object.keys(attributesAndLogs).reduce(
    (previous, current) => ({
      ...previous,
      [current]: attributesAndLogs[current].attributes,
    }),
    {},
  )

  const errorPayload = Object.values(attributesAndLogs)
    .filter((value: any) => !isEmpty(value.logs))
    .map((value: any) => ({
      'Dev ID': value.developmentId,
      'Prod No': value.productNumber,
      ...value.logs,
    }))

  if (!isEmpty(errorPayload)) {
    yield put(importError(errorPayload))
    return
  }

  const auth = GetAuth()
  const profile = auth.getProfile()

  trackEvent('product', 'Import Product Triggered', profile.nickname)

  try {
    yield call(injectorService.post, 'import-additional-attributes', productPayload)

    yield put(
      showNotification({
        type: 'success',
        message:
          'Products file accepted, this process might take some time please review the changes have been applied in a few minutes',
      }),
    )
    yield put(fetchFirstProductsPage(OPTION_LIBRARY))
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't import products for you. Please retry.",
    )
  }
}

export default function* () {
  yield all([takeEvery(IMPORT_PRODUCTS, importProductsSaga)])
}
