import { combineReducers } from 'redux'
import { PinnedProductSlugsState, pinnedProductSlugs } from './common/grid/pinnedProductSlugs'
import { ProductsState, products } from './common/grid/product'
import { SortedColumnState, sortedColumns } from './common/grid/sortedColumns'
import { ExactFiltersState, exactFilters } from './common/grid/exactFilters'
import { PartialFiltersState, partialFilters } from './common/grid/partialFilters'
import { HighlightedRowsState, highlightedRows } from './common/grid/highlightedRows'
import { uniqueFilterValues, UniqueFilterValuesState } from './common/grid/uniqueFilterValues'
import { HierarchyReducerState, hierarchy } from './common/grid/hierarchy'
import { createGridSpecificReducer } from './common/createGridSpecificReducer'
import { DEV_TRACKER } from 'src/types/index'
import { departmentSpecs, DepartmentSpecsState } from './common/grid/departmentSpecs'
import { multiSelect, MultiSelectState } from './common/grid/multiSelect'
import { hiddenColumns, HiddenColumnState } from './common/grid/hiddenColumns'

export type DevTrackerState = {
  hierarchy: HierarchyReducerState
  sortedColumns: SortedColumnState
  exactFilters: ExactFiltersState
  partialFilters: PartialFiltersState
  uniqueFilterValues: UniqueFilterValuesState
  pinnedProductSlugs: PinnedProductSlugsState
  highlightedRows: HighlightedRowsState
  multiSelect: MultiSelectState
  products: ProductsState
  departmentSpecs: DepartmentSpecsState
  hiddenColumns: HiddenColumnState
}

export const devTracker = combineReducers({
  sortedColumns: createGridSpecificReducer(sortedColumns, DEV_TRACKER),
  exactFilters: createGridSpecificReducer(exactFilters, DEV_TRACKER),
  partialFilters: createGridSpecificReducer(partialFilters, DEV_TRACKER),
  uniqueFilterValues: createGridSpecificReducer(uniqueFilterValues, DEV_TRACKER),
  pinnedProductSlugs: createGridSpecificReducer(pinnedProductSlugs, DEV_TRACKER),
  highlightedRows: createGridSpecificReducer(highlightedRows, DEV_TRACKER),
  multiSelect: createGridSpecificReducer(multiSelect, DEV_TRACKER),
  hierarchy: createGridSpecificReducer(hierarchy, DEV_TRACKER),
  products: createGridSpecificReducer(products, DEV_TRACKER),
  departmentSpecs: createGridSpecificReducer(departmentSpecs, DEV_TRACKER),
  hiddenColumns: createGridSpecificReducer(hiddenColumns, DEV_TRACKER),
})
