import React from 'react'
import { Tag } from 'src/components/Tag'
import { container, tag, icon, title, textField } from '../DropdownFilter/DropdownFilter.scss'
import { FilterIcon } from 'src/assets/icons'
import { setFocus, addEventListener, removeEventListener } from 'src/service/ref'
import TextField from 'src/components/TextField'
import { ESC_KEY, ENTER_KEY } from 'src/constants/keyCodes'
import { PartialFilter } from 'src/actions/grid/partialFilters'

type Props = {
  property: string
  displayName: string
  filters: PartialFilter[]
  currentFilter: string
  addPartialFilter: (filter: PartialFilter) => void
  removePartialFilter: (filter: PartialFilter) => void
}

type State = {
  textValue: string
}

export class FreeTextFilter extends React.Component<Props, State> {
  node: any
  state = {
    textValue: this.props.currentFilter,
  }

  containerOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === ESC_KEY) {
      this.setState({ textValue: '' })
    }
  }

  componentDidMount() {
    setFocus(this.node)
    addEventListener(this.containerOnKeyDown, 'keydown', this.node)
  }

  addPartialFilterIfNonBlank = (value: string) => {
    const trimmedValue = value && value.trim()

    if (!trimmedValue) {
      return
    }

    this.props.addPartialFilter({
      value: trimmedValue,
      property: this.props.property,
      displayName: this.props.displayName,
    })
  }

  stripInvalidCharacters = (newValue: string) => {
    return newValue.replace(/[^@A-Za-z0-9\-.,_; ]/g, '').trimLeft()
  }

  onChange = (textValue: string) => {
    this.setState({ textValue: this.stripInvalidCharacters(textValue) })
  }

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key !== ENTER_KEY) {
      return
    }

    this.addPartialFilterIfNonBlank(this.state.textValue)
    this.setState({ textValue: '' })
  }

  componentWillUnmount() {
    removeEventListener(this.containerOnKeyDown, 'keydown', this.node)
  }

  render() {
    const { property, displayName, filters, removePartialFilter } = this.props

    return (
      <div className={container} ref={(node) => (this.node = node)} tabIndex={-1}>
        <div className={title}>
          <span>{`Filter ${displayName}`}</span>
          <FilterIcon className={icon} />
        </div>
        <div className={textField}>
          <TextField
            name={property}
            value={this.state.textValue}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
        </div>
        {filters.map((filter, index) => (
          <Tag
            key={index}
            className={tag}
            value={filter.value}
            onClose={() => removePartialFilter(filter)}
          />
        ))}
      </div>
    )
  }
}
