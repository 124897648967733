import React from 'react'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { GetAuth, Authenticator, SecureRoute } from '@ri-digital/auth0-authenticator'

import Header from './components/Header'
import Notification from './components/Notification'
import { fetchConfig, fetchConfigSuccess } from 'src/actions/config'
import { GlobalModal } from './components/GlobalModal'
import HomePage from 'src/pages/home/HomePage'
import Analytics from './components/Analytics/Route'
import createStore from './store/store'
import LoggedOutPage from './pages/logout/LogoutPage'
import CallbackPage from './pages/callback/CallbackPage'
import * as cookies from 'src/service/cookies'
import { DevTrackerPage, OptionLibraryPage } from 'src/pages/index'
import HealthCheckPage from 'src/pages/healthcheck/HealthCheck'
import { Admin } from 'src/pages/admin'
import { Config, IntentionalAny } from './types/index'
import { VIEW_SVP_UI } from './constants/scopes'
import NoAccess from './components/NoAccess/NoAccess'
import { ConnectedUserSettingsPageContainer } from 'pages/userSettings/UserSettingsPageContainer'

const reduxHistory = createBrowserHistory()
const store = createStore(reduxHistory)
const theme = createTheme()

interface Props {
  config?: Config
  history?: IntentionalAny
}

export class App extends React.Component<Props> {
  componentDidMount(): void {
    if (this.props.config) {
      store.dispatch(fetchConfigSuccess(this.props.config))
    } else {
      store.dispatch(fetchConfig())
    }
  }

  render(): JSX.Element {
    const history = this.props.history || reduxHistory
    const auth = GetAuth()
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <Authenticator auth={auth} onSuccess={cookies.setCookie}>
              <Analytics />
              <Header />
              <Switch>
                <Route exact key="home" path="/" component={HomePage} />
                <Route exact key="logout" path="/logout" component={LoggedOutPage} />
                <Route exact key="callback" path="/auth" component={CallbackPage} />
                <SecureRoute
                  path="/"
                  api="injector"
                  uiScope={VIEW_SVP_UI}
                  component={SecuredComponent}
                  noScopeComponent={NoAccess}
                />
              </Switch>
            </Authenticator>
          </ConnectedRouter>
        </MuiThemeProvider>
      </Provider>
    )
  }
}

const SecuredComponent = (): JSX.Element => (
  <div>
    <Switch>
      <Route key="admin" path="/admin" component={Admin} />,
      <Route key="devTracker" path="/dev-tracker" component={DevTrackerPage} />,
      <Route key="optionLibrary" path="/option-library" component={OptionLibraryPage} />,
      <Route key="healthcheck" exact path="/healthcheck" component={HealthCheckPage} />,
      <Route key="settings" path="/settings" component={ConnectedUserSettingsPageContainer} />
    </Switch>
    <GlobalModal />
    <Notification />
  </div>
)

export default App
