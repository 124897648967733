import React from 'react'
import { noAccessPage, noAccessPanel } from './NoAccess.scss'

export default function NoAccess(): JSX.Element {
  return (
    <>
      (
      <div className={noAccessPage}>
        <div className={noAccessPanel}>
          <h2>Access Denied</h2>
          <div>You do not have permission to access SVP</div>
        </div>
      </div>
      )
    </>
  )
}
