import { connect } from 'react-redux'
import { CreateProductPage } from './CreateProductPage'
import { clearAndSelectHierarchy } from 'src/actions/grid/hierarchy'
import {
  getAttributeNameMap,
  getNonDeprecatedAttributeValues,
} from 'src/selectors/referenceData/attributes'
import { AppState } from 'src/reducers/root'
import { getCurrentSeasons } from 'src/selectors/referenceData/seasons'
import { getActiveSuppliers } from 'src/selectors/referenceData/suppliers'
import { createProduct } from 'actions/grid/product/create'
import { Season } from 'types/Season'
import { Supplier } from 'types/Supplier'
import { AttributeValueMap } from 'types'

interface StateProps {
  seasons: Season[]
  suppliers: Supplier[]
  attributes: AttributeValueMap
  attributeNameMap: Record<string, string>
}

interface DispatchProps {
  createProduct: typeof createProduct
  clearAndSelectHierarchy: typeof clearAndSelectHierarchy
}

export interface CreateProductPageProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  seasons: getCurrentSeasons(state),
  suppliers: getActiveSuppliers(state),
  attributes: getNonDeprecatedAttributeValues(state),
  attributeNameMap: getAttributeNameMap(state),
})

const mapDispatchToProps: DispatchProps = {
  createProduct,
  clearAndSelectHierarchy,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductPage)
