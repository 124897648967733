import { SET_PERSISTENT_SIDE_LIST_ITEM } from 'actions/persistentSideList'
import { SVPAction } from 'actions/svp'

export type PersistentSideListState = {
  selectedItemSlug: string
}

const defaultCopyToState: PersistentSideListState = {
  selectedItemSlug: '',
}

export const persistentSideList = (
  state: PersistentSideListState = defaultCopyToState,
  action: SVPAction,
): PersistentSideListState => {
  switch (action.type) {
    case SET_PERSISTENT_SIDE_LIST_ITEM:
      return {
        ...state,
        selectedItemSlug: action.itemSlug,
      }
    default:
      return state
  }
}
