import React from 'react'
import { IntentionalAny, Option } from 'src/types/index'
import { ProductCard } from '../../ProductCard/ProductCard'
import {
  section,
  selectedOption,
  title,
  header,
  productCard,
  selectedProductClass,
  removeOptionLink,
  listItem,
} from './OptionCard.scss'
import classNames from 'classnames'
import { x } from '../../ProductCard/ProductCard.scss'
import { CloseCross } from 'src/assets/icons'
import { Link } from 'src/components/Link/Link'
import { ProductWithDynabutes, ProductSupplier } from 'src/types/Product'
import { SUPPLIER_LINK } from 'src/constants/links'
import { openStyleModal } from 'actions/grid/modal/styleModal'

type Props = {
  option: Option
  selectedProduct: ProductWithDynabutes
  detachProduct: (slug: string) => void
  detachSelectedProduct: (product: ProductWithDynabutes) => void
  openStyleModal: typeof openStyleModal
  removable: boolean
  selected: boolean
}

const uniqueColours = (current: IntentionalAny, index: number, arr: IntentionalAny[]): boolean =>
  arr.map((colour) => colour).indexOf(current) === index

const buildTitle = (products: ProductWithDynabutes[]): string =>
  products
    .map(({ colour }) => colour)
    .filter(uniqueColours)
    .join(' / ')

const sortProducts =
  (selectedProductSlug: string) =>
  (product: ProductWithDynabutes): number =>
    product.slug === selectedProductSlug ? -1 : 1

const isSelected = (product: ProductWithDynabutes, selectedProductSlug: string): boolean =>
  product.slug === selectedProductSlug
const hasMultipleSuppliers = (option: Option): boolean => option.products.length > 1

const formattedSupplier = (supplier: ProductSupplier | undefined): string =>
  supplier ? `${supplier.description} - ${supplier.code}` : ''

export class OptionCard extends React.Component<Props> {
  state = {
    showModal: false,
  }

  render(): JSX.Element {
    const {
      option,
      selectedProduct,
      detachProduct,
      detachSelectedProduct,
      openStyleModal,
      removable,
      selected,
    } = this.props

    const selectedOptionClass = classNames(section, {
      [selectedOption]: selected,
    })
    return (
      <div data-cy="option-card-container" className={selectedOptionClass}>
        <div className={header}>
          <p className={title}>{`Colour: ${buildTitle(option.products)}`}</p>
          {removable && (
            <Link
              className={removeOptionLink}
              onClick={(): void => {
                openStyleModal(option.products[0].hierarchySlug, option.products[0])
              }}
            >
              <CloseCross className={x} title="Ammend style" />
            </Link>
          )}
        </div>
        <ul>
          {option.products.sort(sortProducts(selectedProduct.slug)).map((product) => (
            <li key={product.slug} className={listItem}>
              <div className={productCard}>
                <ProductCard
                  developmentId={product.developmentId}
                  productNumber={
                    product.productNumbers && product.productNumbers.length > 0
                      ? product.productNumbers[0]
                      : ''
                  }
                  additionalField={{
                    name: 'Supplier',
                    value: formattedSupplier(product.supplier),
                  }}
                  linkType={option.products.length > 1 ? SUPPLIER_LINK : undefined}
                  linkTitle="Supplier Variant"
                  images={product.images}
                  selected={isSelected(product, selectedProduct.slug)}
                  selectedProductClass={selectedProductClass}
                  wsPrimaryThumbnail={product.wsPrimaryThumbnail}
                  onRemove={
                    hasMultipleSuppliers(option)
                      ? isSelected(product, selectedProduct.slug)
                        ? (): void => detachSelectedProduct(product)
                        : (): void => detachProduct(product.slug)
                      : undefined
                  }
                  removalConfirmationMessage="Removing the selected product will clear all variants associated to it"
                  removeTitle="Remove supplier variant"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
