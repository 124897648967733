import { connect } from 'react-redux'
import { ActionButtons } from './ActionButtons'
import {
  getIsSelectedIdsEmpty,
  getMultiSelectMode,
  getSelectAll,
  getSelectedIds,
} from 'src/selectors/multiSelect'
import { AppState } from 'src/reducers/root'
import { updateAllMultiSelect } from 'src/actions/grid/multiSelect'
import { mergeOptions, mergeStyles } from 'src/actions/grid/style'
import { linkCrossDepartmentProducts, linkMatchingProducts } from 'src/actions/grid/links'
import { isAdmin } from 'src/selectors/userProfile'
import { clearImportError } from 'src/actions/grid/product/import'
import { bulkUpdateProductStatus } from 'src/actions/grid/product/bulkStatus'
import { GridType } from 'src/types/index'
import { ProductStatus } from 'src/types/ProductStatus'
import { exportErrors } from 'src/service/excel/exporter/errors/exporter'

type OwnProps = {
  gridName: GridType
}

const mapStateToProps = (state: AppState) => ({
  multiSelect: getMultiSelectMode(state),
  isSelectedIdsEmpty: getIsSelectedIdsEmpty(state),
  isAdmin: isAdmin(state),
  selectedIds: getSelectedIds(state),
  selectAll: getSelectAll(state),
  isSelected: getSelectAll(state),
  importErrors: state.injectorUI.imports.errors,
})

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  exportErrors: exportErrors,
  linkMatchingProducts: (grid: GridType) => dispatch(linkMatchingProducts(grid)),
  linkMultiSupplierProducts: (grid: GridType) => dispatch(mergeOptions(grid)),
  linkColourProducts: (grid: GridType) => dispatch(mergeStyles(grid)),
  onSelectAllClick: (checked: boolean) => dispatch(updateAllMultiSelect(checked, gridName)),
  linkCrossDepartmentProducts: (grid: GridType) => dispatch(linkCrossDepartmentProducts(grid)),
  clearErrors: () => dispatch(clearImportError()),
  bulkUpdateStatus: (status: ProductStatus, gridType: GridType) =>
    dispatch(bulkUpdateProductStatus(status, gridType)),
})

export const ActionButtonsContainer = connect(mapStateToProps, mapDispatchToProps)(ActionButtons)
