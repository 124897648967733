import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'
import {
  authenticationReducer,
  authenticationMiddleware,
  uiScopeReducer,
  scopeReducer,
} from '@ri-digital/auth0-authenticator'

import rootReducer from '../reducers/root'
import rootSaga from '../sagas'
import { History } from 'history'

// @ts-ignore until redux-sentry-middleware type updated
const sentryMiddleware = createSentryMiddleware(Sentry)
const sagaMiddleware = createSagaMiddleware()

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

let store: Store | null = null

export default function (reduxHistory: History) {
  store = createStore(
    // @ts-ignore
    combineReducers({
      router: connectRouter(reduxHistory),
      authentication: authenticationReducer,
      scopes: scopeReducer,
      uiScopes: uiScopeReducer,
      injectorUI: rootReducer,
    }),
    composeEnhancers(
      applyMiddleware(
        authenticationMiddleware,
        sagaMiddleware,
        routerMiddleware(reduxHistory),
        sentryMiddleware,
      ),
    ),
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export function getStore(): Store {
  return store as Store
}
