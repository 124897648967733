import { Lock } from 'src/types/index'

export const FETCH_LOCK = 'FETCH_LOCK'
export const FETCH_LOCK_SUCCESS = 'FETCH_LOCK_SUCCESS'
export const FETCH_LOCK_FAILURE = 'FETCH_LOCK_FAILURE'
export const SET_LOCK = 'SET_LOCK'
export const SET_LOCK_SUCCESS = 'SET_LOCK_SUCCESS'
export const SET_LOCK_FAILURE = 'SET_LOCK_FAILURE'
export const LOCKED = 'LOCKED'
export const UNLOCKED = 'UNLOCKED'

export type FetchLockActions = ReturnType<
  typeof fetchLock | typeof fetchLockSuccess | typeof fetchLockFailure
>

export type SetLockActions = ReturnType<
  typeof setLockSuccessAction | typeof setLockFailureAction | typeof setLock
>

export const fetchLock = () =>
  <const>{
    type: FETCH_LOCK,
  }

export const fetchLockSuccess = (payload: Lock) =>
  <const>{
    type: FETCH_LOCK_SUCCESS,
    payload,
  }

export const fetchLockFailure = (error: string) =>
  <const>{
    type: FETCH_LOCK_FAILURE,
    error,
  }

export const setLockSuccessAction = (status: string, date: string) =>
  <const>{
    type: SET_LOCK_SUCCESS,
    payload: {
      status: status,
      date: date,
    },
  }

export const setLockFailureAction = (error: string) =>
  <const>{
    type: SET_LOCK_FAILURE,
    error,
  }

export const setLock = (status: string) =>
  <const>{
    type: SET_LOCK,
    status: status,
  }
