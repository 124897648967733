import React from 'react'
import { reloadIcon } from './icons.scss'

interface Props {
  className?: string
}

export const ReloadIcon = ({ className }: Props): JSX.Element => (
  <svg className={className || reloadIcon} viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.47187 7.94993V8.95548C9.47187 9.27484 9.53767 9.54278 9.66707 9.75152C9.84579 10.0396 10.1391 10.2047 10.4722 10.2047C10.7985 10.2047 11.1228 10.0503 11.4361 9.74603L14.8543 6.42482C15.6046 5.69561 15.6046 4.50909 14.8541 3.77988L11.4361 0.458676C11.1228 0.154265 10.7985 0 10.4722 0C9.9744 0 9.47187 0.38635 9.47187 1.24923V2.16246C4.619 2.44093 0.601589 6.05953 0.00452033 10.7972C-0.0289293 11.0618 0.125913 11.3151 0.381104 11.4132C0.453342 11.441 0.528565 11.4544 0.602845 11.4544C0.79098 11.4544 0.973461 11.3688 1.08967 11.2145C2.69117 9.08838 5.26255 7.81901 7.96805 7.81901C8.46948 7.81901 8.97342 7.86295 9.47187 7.94993ZM1.74814 8.83539C3.06069 5.57827 6.32745 3.31709 10.0749 3.31709C10.408 3.31709 10.6779 3.05479 10.6779 2.73115V1.37939L14.0014 4.60843C14.2816 4.8808 14.2816 5.32391 14.0014 5.59628L10.6779 8.82532V7.4734C10.6779 7.2018 10.4859 6.96574 10.2137 6.90318C9.4747 6.73335 8.71902 6.64714 7.96805 6.64714C6.41335 6.64714 4.86681 7.01274 3.49553 7.70457C2.87412 8.01798 2.28742 8.39853 1.74814 8.83539Z" />
    <path d="M17.6179 8.58703C17.3627 8.48891 17.0714 8.5707 16.9094 8.78585C15.3079 10.9118 12.7366 12.1812 10.031 12.1812C9.5297 12.1812 9.02576 12.1373 8.52716 12.0503V11.0447C8.52716 10.1819 8.02478 9.79552 7.52681 9.79552C7.20048 9.79552 6.87619 9.94993 6.56305 10.2543L3.1449 13.5754C2.39441 14.3046 2.39441 15.4911 3.1449 16.2205L6.56305 19.5415C6.87619 19.846 7.20048 20.0002 7.52681 20.0002C8.02463 20.0002 8.52716 19.6139 8.52716 18.751V17.8378C13.3802 17.5593 17.3974 13.9407 17.9945 9.20317C18.028 8.93843 17.8731 8.68514 17.6179 8.58703ZM7.92412 16.6831C7.59104 16.6831 7.32108 16.9454 7.32108 17.2691V18.6208L3.99779 15.3919C3.71747 15.1194 3.71747 14.6763 3.99763 14.4041L7.32108 11.1749V12.5268C7.32108 12.7986 7.5133 13.0346 7.78545 13.0972C8.52464 13.2669 9.28017 13.3531 10.031 13.3531C11.5858 13.3531 13.1324 12.9875 14.5035 12.2958C15.1249 11.9822 15.7116 11.6018 16.2509 11.165C14.9383 14.4219 11.6716 16.6831 7.92412 16.6831Z" />
  </svg>
)
