import React from 'react'
import { buttonSection, confirmSketch, sketchSection, wrapper, dropzone } from './SketchForm.scss'
import { SketchSection } from 'src/components/SketchSection/SketchSection'
import { Sketch } from 'src/types/SketchBundle'
import { ImageInput } from 'src/components/ImageInput/ImageInput'
import { LoadingButton } from 'components/buttons/LoadingButton/LoadingButton'
import Dropzone from 'react-dropzone'
import { extractImage } from 'src/service/image/processImage'

type Props = {
  onChange: (sketch: Sketch | null) => void
  onSubmit: (sketch: Sketch) => void
  onError: (message: string) => void
  developmentId: string
  isSketchSaving: boolean
}

type State = {
  isParsing: boolean
  sketch?: Sketch | null
}

export class SketchForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isParsing: false,
      sketch: null,
    }
  }

  onDrop = async (acceptedFiles: File[]): Promise<void> => {
    const previewImage = await extractImage(acceptedFiles[0])
    this.onChange(previewImage)
  }

  onChange = (sketch: Sketch | null): void => {
    this.setState({ sketch })
    this.props.onChange(sketch)
  }

  render(): JSX.Element {
    const { isParsing, sketch } = this.state
    const { developmentId, onSubmit, isSketchSaving } = this.props
    return (
      <div className={wrapper}>
        <div className={sketchSection}>
          <SketchSection
            developmentId={developmentId}
            sketchImage={sketch || null}
            isSketchUploadInProgress={isParsing}
          />
        </div>
        <div className={buttonSection}>
          <ImageInput
            id="sketchUploadInput"
            onChange={this.onChange}
            onParsing={(isParsing): void => this.setState({ isParsing })}
            onError={this.props.onError}
            render={(): JSX.Element => (
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }): JSX.Element => (
                  <section className="container">
                    <div {...getRootProps({ className: dropzone })}>
                      <input {...getInputProps()} />
                      <span>Upload sketch - Drag and drop image here or click to browse</span>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            dataCy="sketchUploadInput"
          />
          {sketch && sketch !== null && (
            <LoadingButton
              colour="green"
              className={confirmSketch}
              isLoading={isSketchSaving}
              hasFocus
              // @ts-ignore
              onClick={(): void => onSubmit(sketch)}
              dataCy="sketchUploadConfirmButton"
            >
              Confirm
            </LoadingButton>
          )}
        </div>
      </div>
    )
  }
}
