import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SideList } from 'components/SideList/SideList'
import { UserSettings } from 'components/UserSettings/UserSettings'
import { USER_SETTINGS_PATH } from 'constants/sideList'
import { getUserSettingsValues } from 'service/product/uiValues'
import { userSettingsPage, userSettingsBody, spinnerContainer } from './UserSettingsPage.scss'
import { PersistentSideListContainer } from 'components/PersistentSideList/PersistentSideListContainer'
import { RISpinner } from 'components/RISpinner/RISpinner'
import { UserSettingsPageProps } from './UserSettingsPageContainer'

export const UserSettingsPage = (props: UserSettingsPageProps): JSX.Element => {
  React.useEffect(() => {
    props.fetchReferenceData()
  }, [])
  return (
    <div className={userSettingsPage}>
      {props.dependenciesStatus === 'ready' ? (
        <section className={userSettingsBody}>
          <SideList list={getUserSettingsValues()} rootPath={USER_SETTINGS_PATH} />
          <PersistentSideListContainer />
          <Switch>
            <Route
              path="/settings/:setting"
              render={({ match }): JSX.Element => (
                <UserSettings selectedSettingKey={match.params.setting} />
              )}
            />
            <Redirect exact from="/settings" to="/settings/sizes" />
          </Switch>
        </section>
      ) : (
        <RISpinner colour="black" containerStyle={spinnerContainer} />
      )}
    </div>
  )
}
