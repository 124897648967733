import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  ADD_COLOUR_VARIANT,
  ADD_OPTION_LINK_LINE,
  DETACH_OPTION,
  DETACH_PRODUCT,
  DETACH_SELECTED_OPTION,
  DETACH_SELECTED_PRODUCT,
  FETCH_STYLE,
  MERGE_OPTIONS,
  MERGE_STYLES,
  SEARCH_STYLE,
  searchStyleSuccess,
  CONFIRM_PRODUCT_STYLE,
  fetchStyleSuccess,
  fetchStyleFailure,
  SAVE_STYLE_DESCRIPTION,
  searchStyleRequest,
  confirmProductStyleRequest,
} from 'src/actions/grid/style'
import {
  GridType,
  Product,
  OPTION_LIBRARY,
  Attribute,
  AttributeValues,
  Country,
} from 'src/types/index'
import { getSelectedProducts } from 'src/selectors/multiSelect'
import { errorAndNotification } from './error'
import { injectorService } from 'src/service/injector/injectorService'
import { showErrorNotification, showNotification } from 'src/actions/notification'
import { clearMultiSelect } from 'src/actions/grid/multiSelect'
import { updateProductSuccess } from 'src/actions/grid/product/update'
import { getAllProducts } from 'src/selectors/product'
import { apiStyleToStyle } from 'src/service/mappers/style'
import { getAttributes, getAttributeValues } from 'src/selectors/referenceData/attributes'
import { getStyle, getStyleOptions, getStyleProducts } from 'src/selectors/style'
import { ProductWithDynabutes } from 'src/types/Product'
import { getCountries } from 'src/selectors/referenceData/countries'
import { DetachStyleResponse } from 'src/types/responses/style'
import { APIError } from 'src/types/Api'
import { ApiStyle, StyleResult } from 'types/Style'
import { logError } from 'service/errors'
import { NOTIFICATION_SUCCESS } from 'constants/notifications'
import { closeStyleModal, setViewResults } from 'actions/grid/modal/styleModal'
import { getGrid } from 'selectors/grid'
import { SVPAction } from 'actions/svp'
import { StyleWithStatusState } from 'reducers/style'
import { SVPSaga } from 'sagas'

const STYLE_LINK_VALIDATION_ERROR_MESSAGE =
  'One or more Products already exists as Supplier or Colour variants. Please amend in side panel.'

const GENERIC_ERROR_MESSAGE =
  "Sorry something has broken in the tech and we couldn't create the link, Please retry."

const GENERIC_ERROR_MESSAGE_STYLE_LINK =
  "Sorry something has broken in the tech and we couldn't show information for the links."

const GENERIC_REMOVE_MESSAGE =
  "Sorry something has broken in the tech and we couldn't remove the product from the link. Please retry."

const CANCELLED_LINKING_VALIDATION_ERROR_MESSAGE = 'Cancelled products can not be linked'

const rejectedStatuses = [
  'Cancelled',
  'Cancelled in development',
  'Cancelled in proposed',
  'Cancelled in ready to buy',
]

type Rule = {
  rule: (product1: ProductWithDynabutes, product2: ProductWithDynabutes) => boolean
  cause: (cause: string) => string
}

type RuleMultipleProducts = {
  rule: (rules: ProductWithDynabutes[], masterProduct?: Product) => boolean
  cause: () => string
}

const partOfSameStyleRule = {
  rule: (currentProduct: Product, retrievedProduct: Product): boolean =>
    currentProduct.styleSlug === retrievedProduct.styleSlug,
  cause: (identifier: string): string =>
    `${identifier} is already marked as a variation of the selected product`,
}

const notPartOfSameStyleRule = {
  rule: (currentProduct: Product, retrievedProduct: Product): boolean =>
    currentProduct.styleSlug !== retrievedProduct.styleSlug,
  cause: (_: string): string =>
    'Development IDs cannot be Option linked if they are in different Styles.Please amend the Style for one or more Development IDs using the Style Modal before continuing.',
}

const hasColourLinkRule = {
  rule: (_: Product, retrievedProduct: Product): boolean => retrievedProduct.hasColourLink,
  cause: (identifier: string): string => `${identifier} is already marked as a Colour Variant`,
}

const hasOptionLinkLineRule = {
  rule: (product: Product, retrievedProduct: Product): boolean => retrievedProduct.hasSupplierLink,
  cause: (identifier: string): string => `${identifier} is already marked as a Supplier Variant`,
}

const allNotPartOfSameStyleRule = {
  rule: (products: Product[], masterProduct?: Product): boolean =>
    products.some((product) => product.styleSlug !== masterProduct.styleSlug),
  cause: (): string =>
    'Development IDs cannot be Option linked if they are in different Styles. Please amend the Style for one or more Development IDs using the Style Modal before continuing.',
}

const alreadyStyleLinkedRule = {
  rule: (products: Product[]): boolean =>
    products.some(({ hasSupplierLink, hasColourLink }) => hasSupplierLink || hasColourLink),
  cause: (): string => STYLE_LINK_VALIDATION_ERROR_MESSAGE,
}

const cancelledProductRule = {
  rule: (products: Product[], masterProduct?: Product): boolean =>
    anyProductIsInCancelledState(products) || rejectedStatuses.includes(masterProduct?.status),
  cause: (): string => 'Unable to create a link with cancelled products',
}

const errorAttachingStyle = (
  rules: Rule[],
  currentProduct: ProductWithDynabutes,
  retrievedProduct: ProductWithDynabutes,
): Rule => rules.find((validator) => validator.rule(currentProduct, retrievedProduct))

const errorMergingStyle = (
  rules: RuleMultipleProducts[],
  products: ProductWithDynabutes[],
  masterProduct?: Product,
): RuleMultipleProducts => rules.find((validator) => validator.rule(products, masterProduct))

const anyProductIsInCancelledState = (products: Product[]): boolean =>
  products.some(({ status }) => rejectedStatuses.includes(status))

const productInCancelledState = {
  rule: (product: Product, retrievedProduct: Product): boolean =>
    anyProductIsInCancelledState([retrievedProduct]),
  cause: (): string => CANCELLED_LINKING_VALIDATION_ERROR_MESSAGE,
}

export function* mergeOptionsSaga({ grid }: SVPAction<typeof MERGE_OPTIONS>): SVPSaga {
  try {
    const products = (yield select(getSelectedProducts)) as Product[]
    const [master, ...others] = products
    const masterOptionSlug = master.optionSlug
    const error = errorMergingStyle(
      [allNotPartOfSameStyleRule, cancelledProductRule],
      others,
      master,
    )
    if (error) {
      yield call(errorAndNotification, `🤦: ${error.cause()}`, error.cause())
      return
    }
    yield call(
      injectorService.put,
      `option/${masterOptionSlug}/attach`,
      others.map(({ slug }) => slug),
    )

    yield put(
      showNotification({
        type: 'success',
        message: `Successfully marked ${products.length} products as Options Link Line! View links in the side panel`,
      }),
    )

    yield put(clearMultiSelect(grid))

    yield all(
      products.map(({ slug }) =>
        put(
          updateProductSuccess(
            slug,
            {
              hasSupplierLink: true,
              optionSlug: master.optionSlug,
              styleSlug: master.styleSlug,
            },
            grid,
          ),
        ),
      ),
    )
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_ERROR_MESSAGE)
  }
}
export function* detachProductSaga({
  productSlug,
  styleSlug,
}: SVPAction<typeof DETACH_PRODUCT>): SVPSaga {
  try {
    const affectedProduct = (yield call(
      injectorService.put,
      `product/${productSlug}/detach`,
    )) as DetachStyleResponse
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully unmarked as Option Link Line',
      }),
    )

    yield call(fetchStyleSaga, { type: FETCH_STYLE, styleSlug })

    const updatedProducts = (yield select(getStyleProducts)) as ProductWithDynabutes[]
    yield all(
      updatedProducts.map(({ slug, hasSupplierLink }) =>
        put(updateProductSuccess(slug, { hasSupplierLink }, OPTION_LIBRARY)),
      ),
    )

    yield put(
      updateProductSuccess(
        productSlug,
        {
          hasSupplierLink: false,
          hasColourLink: false,
          optionSlug: affectedProduct.optionSlug,
          styleSlug: affectedProduct.styleSlug,
        },
        OPTION_LIBRARY,
      ),
    )
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_REMOVE_MESSAGE)
  }
}

export function* detachSelectedProductSaga({
  productToDetach,
}: SVPAction<typeof DETACH_SELECTED_PRODUCT>): SVPSaga {
  try {
    const affectedProduct = (yield call(
      injectorService.put,
      `product/${productToDetach.slug}/detach`,
    )) as DetachStyleResponse
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully unmarked as Option Link Line',
      }),
    )

    const oldStyleProducts = (yield select(getStyleProducts)) as ProductWithDynabutes[]
    yield call(fetchStyleSaga, {
      type: FETCH_STYLE,
      styleSlug: affectedProduct.styleSlug,
    })

    yield put(
      updateProductSuccess(
        affectedProduct.slug,
        {
          hasSupplierLink: false,
          hasColourLink: false,
          optionSlug: affectedProduct.optionSlug,
          styleSlug: affectedProduct.styleSlug,
        },
        OPTION_LIBRARY,
      ),
    )

    const [aProductInSameOption, ...otherProductsInSameOption] = oldStyleProducts.filter(
      ({ optionSlug, slug }) =>
        optionSlug === productToDetach.optionSlug && slug !== productToDetach.slug,
    )
    if (otherProductsInSameOption.length === 0) {
      yield put(
        updateProductSuccess(aProductInSameOption.slug, { hasSupplierLink: false }, OPTION_LIBRARY),
      )
    }
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_REMOVE_MESSAGE)
  }
}
function* mergeStyles(
  currentProduct: ProductWithDynabutes,
  productsToMerge: ProductWithDynabutes[],
  successNotification: string,
  grid: GridType,
): SVPSaga {
  yield call(
    injectorService.put,
    `style/${currentProduct.styleSlug}/attach`,
    productsToMerge.map((product) => product.optionSlug),
  )

  yield put(
    showNotification({
      type: 'success',
      message: successNotification,
    }),
  )
  yield all(
    [currentProduct, ...productsToMerge].map(({ slug }) =>
      put(
        updateProductSuccess(
          slug,
          {
            hasColourLink: true,
            styleSlug: currentProduct.styleSlug,
          },
          grid,
        ),
      ),
    ),
  )
}
export function* mergeStylesSaga({ grid }: SVPAction<typeof MERGE_STYLES>): SVPSaga {
  try {
    const products = (yield select(getSelectedProducts)) as ProductWithDynabutes[]
    const [master, ...others] = products
    const error = errorMergingStyle(
      [alreadyStyleLinkedRule, cancelledProductRule],
      products,
      master,
    )
    if (error) {
      yield call(errorAndNotification, `🤦: ${error.cause()}`, error.cause())
      return
    }
    yield* mergeStyles(
      master,
      others,
      `Successfully marked ${products.length} products as Colour Variants! View links in the side panel`,
      grid,
    )
    yield put(clearMultiSelect(grid))
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_ERROR_MESSAGE)
  }
}
export function* detachOptionSaga({
  optionSlug,
  styleSlug,
}: SVPAction<typeof DETACH_OPTION>): SVPSaga {
  try {
    const productsInDetachedOption = (yield call(
      injectorService.put,
      `option/${optionSlug}/detach`,
    )) as ProductWithDynabutes[]
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully unmarked as Colour Variant',
      }),
    )
    yield call(fetchStyleSaga, { type: FETCH_STYLE, styleSlug })
    const updatedProducts = (yield select(getStyleProducts)) as ProductWithDynabutes[]
    yield all(
      updatedProducts.map(({ slug, hasColourLink }) =>
        put(updateProductSuccess(slug, { hasColourLink }, OPTION_LIBRARY)),
      ),
    )
    yield all(
      productsInDetachedOption.map(({ slug, styleSlug }) =>
        put(
          updateProductSuccess(
            slug,
            {
              hasColourLink: false,
              styleSlug,
            },
            OPTION_LIBRARY,
          ),
        ),
      ),
    )
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_REMOVE_MESSAGE)
  }
}
export function* detachSelectedOptionSaga({
  optionToDetach,
}: SVPAction<typeof DETACH_SELECTED_OPTION>): SVPSaga {
  try {
    const updatedProducts = (yield call(
      injectorService.put,
      `option/${optionToDetach.slug}/detach`,
    )) as ProductWithDynabutes[]
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully unmarked as Colour Variant',
      }),
    )
    const oldStyleOptions = (yield select(getStyleOptions)) as ProductWithDynabutes[]
    yield call(fetchStyleSaga, {
      type: FETCH_STYLE,
      styleSlug: updatedProducts[0].styleSlug,
    })
    const [anOptionInSameStyle, ...otherOptionsInSameStyle] = oldStyleOptions.filter(
      ({ slug }) => slug !== optionToDetach.slug,
    )
    const gridProductsToUpdate: ProductWithDynabutes[] =
      otherOptionsInSameStyle.length === 0
        ? [...anOptionInSameStyle.products, ...updatedProducts]
        : updatedProducts
    yield all(
      gridProductsToUpdate.map(({ slug, styleSlug }) =>
        put(updateProductSuccess(slug, { styleSlug, hasColourLink: false }, OPTION_LIBRARY)),
      ),
    )
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_REMOVE_MESSAGE)
  }
}
export function* fetchStyleSaga({ styleSlug }: SVPAction<typeof FETCH_STYLE>): SVPSaga {
  const attributes = (yield select(getAttributes)) as Attribute[]
  const attributeValues = (yield select(getAttributeValues)) as AttributeValues
  const countries = (yield select(getCountries)) as Country[]
  try {
    const style = apiStyleToStyle(
      (yield call(injectorService.get, `style/${styleSlug}`)) as ApiStyle,
      attributes,
      attributeValues,
      countries,
    )
    yield put(fetchStyleSuccess(style))
  } catch (error) {
    yield call(errorAndNotification, error as APIError, GENERIC_ERROR_MESSAGE_STYLE_LINK)
    yield put(fetchStyleFailure())
  }
}

export function* addColorVariantSaga({
  identifier,
  currentProduct,
}: SVPAction<typeof ADD_COLOUR_VARIANT>): SVPSaga {
  try {
    const retrievedProduct = (yield call(
      injectorService.get,
      `products/${identifier}`,
    )) as ProductWithDynabutes
    const error = errorAttachingStyle(
      [productInCancelledState, partOfSameStyleRule, hasColourLinkRule],
      currentProduct,
      retrievedProduct,
    )
    if (error) {
      yield call(errorAndNotification, `🤦: ${error.cause(identifier)}`, error.cause(identifier))
      return
    }
    yield call(injectorService.put, `style/${currentProduct.styleSlug}/attach`, [
      retrievedProduct.optionSlug,
    ])
    const products = (yield select(getAllProducts)) as Product[]
    const supplierVariantsOfRetrievedProduct = products.filter(
      (product) => product.optionSlug === retrievedProduct.optionSlug,
    )
    const supplierVariantsOfCurrentProduct = products.filter(
      (product) => product.optionSlug === currentProduct.optionSlug,
    )
    yield all(
      [
        currentProduct,
        ...supplierVariantsOfRetrievedProduct,
        ...supplierVariantsOfCurrentProduct,
      ].map(({ slug }) =>
        put(
          updateProductSuccess(
            slug,
            {
              hasColourLink: true,
              styleSlug: currentProduct.styleSlug,
            },
            OPTION_LIBRARY,
          ),
        ),
      ),
    )
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully added product to Colour Variants!',
      }),
    )
    yield put({
      type: FETCH_STYLE,
      styleSlug: currentProduct.styleSlug,
    })
  } catch (error) {
    const errorMessage =
      (error as APIError)?.response?.data?.error?.code === 3
        ? 'Dev ID/ Prod Number not found'
        : GENERIC_ERROR_MESSAGE_STYLE_LINK
    yield call(errorAndNotification, error as APIError, errorMessage)
  }
}

export function* addOptionLinkLineSaga({
  identifier,
  currentProduct,
}: SVPAction<typeof ADD_OPTION_LINK_LINE>): SVPSaga {
  try {
    const retrievedProduct = (yield call(
      injectorService.get,
      `products/${identifier}`,
    )) as ProductWithDynabutes
    const error = errorAttachingStyle(
      [productInCancelledState, notPartOfSameStyleRule, hasOptionLinkLineRule],
      currentProduct,
      retrievedProduct,
    )
    if (error) {
      yield call(errorAndNotification, `🤦: ${error.cause(identifier)}`, error.cause(identifier))
      return
    }
    yield call(injectorService.put, `option/${currentProduct.optionSlug}/attach`, [
      retrievedProduct.slug,
    ])
    yield put(
      updateProductSuccess(
        retrievedProduct.slug,
        {
          hasSupplierLink: true,
          hasColourLink: currentProduct.hasColourLink,
          optionSlug: currentProduct.optionSlug,
          styleSlug: currentProduct.styleSlug,
        },
        OPTION_LIBRARY,
      ),
    )
    yield put(
      updateProductSuccess(
        currentProduct.slug,
        {
          hasSupplierLink: true,
        },
        OPTION_LIBRARY,
      ),
    )
    yield put({
      type: FETCH_STYLE,
      styleSlug: currentProduct.styleSlug,
    })
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully added product to Option Link Line!',
      }),
    )
  } catch (error) {
    const errorMessage =
      (error as APIError)?.response?.data?.error?.code === 3
        ? 'Dev ID/ Prod Number not found'
        : GENERIC_ERROR_MESSAGE_STYLE_LINK
    yield call(errorAndNotification, error as APIError, errorMessage)
  }
}

export function* searchStyleSaga(action: SVPAction<typeof SEARCH_STYLE>): SVPSaga {
  const { payload } = action
  try {
    yield put(searchStyleRequest())
    const results = (yield call(injectorService.get, 'search-style', {
      styleIDPart: payload.styleID ?? '',
      styleDescriptionPart: payload.styleDescription ?? '',
      hierarchySlug: payload.departmentSlug ?? '',
      styleGroupSlug: payload.styleGroupSlug ?? '',
      styleName: payload.styleName ?? '',
      printSlug: payload.productPrint ?? '',
    })) as StyleResult[]

    const filteredResults = (results ?? []).filter(
      (result) => !!result.idStyle && !!result.styleDescription,
    )

    //TODO: to be removed once the BE data is synced
    const seen: Record<string, boolean> = {}
    const uniqueResults: StyleResult[] = []
    filteredResults.forEach((filteredRes) => {
      if (!seen[filteredRes.slug]) {
        uniqueResults.push(filteredRes)
      }
      seen[filteredRes.slug] = true
    })

    if (!uniqueResults.length) {
      yield put(showErrorNotification('No style matches the search criteria'))
      yield put(searchStyleSuccess([]))
      yield put(setViewResults(false))
    } else {
      yield put(searchStyleSuccess(uniqueResults))
      yield put(setViewResults(true))
    }
  } catch (error) {
    const apiError = error as APIError
    yield call(logError, error as APIError)
    if (apiError?.data?.code) {
      yield put(showErrorNotification('Too many results returned. Please refine your search.'))
    } else {
      yield put(showErrorNotification('Error retrieving style results. Please try again.'))
    }
    yield put(searchStyleSuccess([]))
    yield put(setViewResults(false))
  }
}

export function* confirmProductStyleSaga(action: SVPAction<typeof CONFIRM_PRODUCT_STYLE>): SVPSaga {
  const { styleSlug, optionProducts, optionSlug } = action

  try {
    yield put(confirmProductStyleRequest())
    yield call(injectorService.put, `style/${styleSlug}/attach`, [optionSlug])
    yield call(fetchStyleSaga, { type: FETCH_STYLE, styleSlug })
    const style = (yield select(getStyle)) as StyleWithStatusState
    const grid = (yield select(getGrid)) as GridType
    yield all(
      optionProducts.map((product) => {
        return put(
          updateProductSuccess(
            product.slug,
            {
              styleSlug: style.current.slug,
              styleDescription: style.current.styleDescription,
              styleID: style.current.styleID,
            },
            grid,
          ),
        )
      }),
    )
    yield put(
      showNotification({
        type: NOTIFICATION_SUCCESS,
        message: 'Successfuly updated product style',
      }),
    )
    yield put(closeStyleModal())
  } catch (error) {
    yield call(logError, error as APIError)
    yield put(showErrorNotification('Error updating product style. Please try again.'))
  }
}

export function* saveStyleDescriptionSaga(
  action: SVPAction<typeof SAVE_STYLE_DESCRIPTION>,
): SVPSaga {
  const { styleSlug, styleDescription } = action

  try {
    yield call(injectorService.patch, `styles/${styleSlug}`, { styleDescription })
    yield call(fetchStyleSaga, { type: FETCH_STYLE, styleSlug })
    const updatedProducts = (yield select(getStyleProducts)) as ProductWithDynabutes[]

    const grid = (yield select(getGrid)) as GridType
    yield all(
      updatedProducts.map((product) =>
        put(
          updateProductSuccess(
            product.slug,
            {
              styleDescription,
            },
            grid,
          ),
        ),
      ),
    )
    yield put(
      showNotification({
        type: NOTIFICATION_SUCCESS,
        message: 'Successfuly updated style description',
      }),
    )
    yield put(closeStyleModal())
  } catch (error) {
    yield call(logError, error as APIError)
    yield put(showErrorNotification('Error updating style description. Please try again.'))
  }
}

export default function* (): SVPSaga {
  yield all([
    takeEvery(MERGE_OPTIONS, mergeOptionsSaga),
    takeEvery(MERGE_STYLES, mergeStylesSaga),
    takeEvery(DETACH_PRODUCT, detachProductSaga),
    takeEvery(DETACH_SELECTED_PRODUCT, detachSelectedProductSaga),
    takeEvery(DETACH_OPTION, detachOptionSaga),
    takeEvery(DETACH_SELECTED_OPTION, detachSelectedOptionSaga),
    takeEvery(FETCH_STYLE, fetchStyleSaga),
    takeEvery(ADD_COLOUR_VARIANT, addColorVariantSaga),
    takeEvery(ADD_OPTION_LINK_LINE, addOptionLinkLineSaga),
    takeEvery(SEARCH_STYLE, searchStyleSaga),
    takeEvery(CONFIRM_PRODUCT_STYLE, confirmProductStyleSaga),
    takeEvery(SAVE_STYLE_DESCRIPTION, saveStyleDescriptionSaga),
  ])
}
