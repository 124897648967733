import React, { Component } from 'react'
import { GetAuth } from '@ri-digital/auth0-authenticator'

import {
  help,
  link,
  list,
  loginButton,
  menu,
  popoverContainer,
  triggerLink,
  utilityNavigation,
  closeLink,
} from './UtilityNavigation.scss'
import { Menu } from '../Menu'
import { Avatar, ChevronDown, BookmarkIcon, HelpIcon } from '../../assets/icons'
import { Popover } from '../Popover'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import { Modal } from '../Modal/Modal'
import { SkipLink } from '../Link'
import { TOP_RIGHT } from '../../constants/popover'
import { ssoLogin } from 'service/auth'

type Props = {
  name?: string
  pathname: string
  isLoggedIn: boolean
}

type State = {
  showModal: boolean
}

export class UtilityNavigation extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  render() {
    const { name, isLoggedIn } = this.props
    const { showModal } = this.state

    return (
      <nav className={utilityNavigation}>
        <ul className={list}>
          {this.bookmarkLink()}
          <li>
            <a
              id="helpLink"
              className={`${link} ${help}`}
              target="_blank"
              rel="noopener noreferrer"
              href="https://ri365.sharepoint.com/sites/singleviewofproductportal/SitePages/Home.aspx"
            >
              <HelpIcon />
              &nbsp;Help
            </a>
          </li>
          {isLoggedIn ? (
            <li>
              <Popover
                triggerClassName={link}
                position={TOP_RIGHT}
                popoverContainerClass={popoverContainer}
                PopoverItem={
                  <Menu className={menu} menu={this.menuItems()} defaultSelectedIndex={0} />
                }
              >
                <div>
                  <span className={triggerLink}>{name}</span>
                  <ChevronDown />
                  <Avatar />
                </div>
              </Popover>
            </li>
          ) : (
            <li>
              <PrimaryButton
                colour="green"
                onClick={this.onLoginButtonClick}
                className={loginButton}
                dataCy="loginButton"
              >
                Log in
              </PrimaryButton>
            </li>
          )}
        </ul>
        {showModal && (
          <Modal onCloseFn={this.toggleModal}>
            <p>{this.getModalText()}</p>
            <div className={closeLink}>
              <SkipLink onClick={this.toggleModal}>Ok</SkipLink>
            </div>
          </Modal>
        )}
      </nav>
    )
  }

  menuItems = () => {
    const auth = GetAuth()

    return [
      {
        text: 'Sign Out',
        callback: () => auth.signOut(),
      },
    ]
  }

  bookmarkLink = () => {
    if (!this.isBookmarkLinkVisible()) {
      return <></>
    }

    return (
      <li>
        <a
          id="bookmarkLink"
          className={`${link} ${help}`}
          target="_self"
          href="#"
          onClick={this.addBookmark}
        >
          <BookmarkIcon />
          &nbsp;Bookmark
        </a>
      </li>
    )
  }

  onLoginButtonClick = () => {
    ssoLogin(`${window.location.origin}/auth`)
  }

  isBookmarkLinkVisible() {
    return this.props.pathname === '/'
  }

  getModalText = () => {
    return (
      'To add this page to your bookmarks, press ' +
      (navigator.userAgent.toLowerCase().indexOf('mac') >= 0 ? 'Cmd' : 'Ctrl') +
      ' + D on your keyboard.'
    )
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  addBookmark = (event: React.MouseEvent<HTMLAnchorElement>) => {
    this.toggleModal()
    event.preventDefault()
  }
}
