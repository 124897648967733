import { AttributeWithColumnNameMap } from 'types'
import { DefaultAttributesColumns, SelectedDefaultAttribute } from 'types/UserSettings'

export const GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES =
  'GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES'
export const GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_FETCHING =
  'GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_FETCHING'
export const GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_SUCCESS =
  'GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_SUCCESS'
export const GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_DONE =
  'GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_DONE'

export type UserSettingsDefaultAttributesActions = ReturnType<
  | typeof getDepartmentRangesWithDefaultAttributes
  | typeof getDepartmentRangesWithDefaultAttributesFetching
  | typeof getDepartmentRangesWithDefaultAttributesSuccess
  | typeof getDepartmentRangesWithDefaultAttributesDone
  | typeof addDefaultAttributeToTable
  | typeof updateDefaultAttributeValues
  | typeof updateDefaultAttributeValuesSuccess
  | typeof setClipboardAttributeSource
>

export const getDepartmentRangesWithDefaultAttributesFetching = () =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_FETCHING,
  }

export const getDepartmentRangesWithDefaultAttributes = (slug: string) =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES,
    slug,
  }

export const getDepartmentRangesWithDefaultAttributesSuccess = (
  defaultAttributes: SelectedDefaultAttribute[],
  ranges: Record<string, string>,
  columns: DefaultAttributesColumns[],
  departmentAttributes: AttributeWithColumnNameMap,
) =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_SUCCESS,
    defaultAttributes,
    ranges,
    columns,
    departmentAttributes,
  }

export const getDepartmentRangesWithDefaultAttributesDone = () =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_DEFAULT_ATTRIBUTES_DONE,
  }

export const ADD_DEFAULT_ATTRIBUTE_TO_TABLE = 'ADD_DEFAULT_ATTRIBUTE_TO_TABLE'
export const addDefaultAttributeToTable = (column: DefaultAttributesColumns) =>
  <const>{
    type: ADD_DEFAULT_ATTRIBUTE_TO_TABLE,
    column,
  }

export const UPDATE_DEFAULT_ATTRIBUTE_VALUES = 'UPDATE_DEFAULT_ATTRIBUTE_VALUES'
export const updateDefaultAttributeValues = (
  valueSlugs: string[],
  attributeName: string,
  attributeSlug: string,
  rangeSlug: string,
) =>
  <const>{
    type: UPDATE_DEFAULT_ATTRIBUTE_VALUES,
    valueSlugs,
    attributeName,
    attributeSlug,
    rangeSlug,
  }

export const UPDATE_DEFAULT_ATTRIBUTE_VALUES_SUCCESS = 'UPDATE_DEFAULT_ATTRIBUTE_VALUES_SUCCESS'
export const updateDefaultAttributeValuesSuccess = (
  valueSlugs: string[],
  attributeName: string,
  attributeSlug: string,
  rangeSlug: string,
) =>
  <const>{
    type: UPDATE_DEFAULT_ATTRIBUTE_VALUES_SUCCESS,
    valueSlugs,
    attributeName,
    attributeSlug,
    rangeSlug,
  }

export const SET_CLIPBOARD_ATTRIBUTE_SOURCE = 'SET_CLIPBOARD_ATTRIBUTE_SOURCE'
export const setClipboardAttributeSource = (source: SelectedDefaultAttribute) =>
  <const>{
    type: SET_CLIPBOARD_ATTRIBUTE_SOURCE,
    source,
  }
