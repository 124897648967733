import * as ProductStatuses from 'src/constants/productStatus'
import { ProductStatus, ProductStatusKey } from 'src/types/ProductStatus'

// actions
export const MARK_AS_READY_TO_BUY = 'Mark as ready to buy'
export const PROPOSE = 'Propose'
export const CANCEL = 'Cancel'

export const actionLookUp: Record<string, string> = {
  [ProductStatuses.READY_TO_BUY]: MARK_AS_READY_TO_BUY,
  [ProductStatuses.PROPOSED]: PROPOSE,
  [ProductStatuses.CANCELLED]: CANCEL,
}

export const NONE = 'None'

const statusValueLookup: Record<string, ProductStatus> = {
  development: ProductStatuses.DEVELOPMENT,
  proposed: ProductStatuses.PROPOSED,
  readyToBuy: ProductStatuses.READY_TO_BUY,
  cancelled: ProductStatuses.CANCELLED,
}

const statusKeyLookup: Record<ProductStatus, ProductStatusKey> = {
  [ProductStatuses.PENDING_ODBMS]: ProductStatuses.PRODUCT_STATUS_PENDING_ODBMS_KEY,
  [ProductStatuses.DEVELOPMENT]: ProductStatuses.PRODUCT_STATUS_DEVELOPMENT_KEY,
  [ProductStatuses.PROPOSED]: ProductStatuses.PRODUCT_STATUS_PROPOSED_KEY,
  [ProductStatuses.READY_TO_BUY]: ProductStatuses.PRODUCT_STATUS_READY_TO_BUY_KEY,
  [ProductStatuses.CANCELLED]: ProductStatuses.PRODUCT_STATUS_CANCELLED_KEY,
  [ProductStatuses.CANCELLED_IN_DEVELOPMENT]: ProductStatuses.PRODUCT_STATUS_CANCELLED_KEY,
  [ProductStatuses.CANCELLED_IN_PROPOSED]: ProductStatuses.PRODUCT_STATUS_CANCELLED_KEY,
  [ProductStatuses.CANCELLED_IN_READY_TO_BUY]: ProductStatuses.PRODUCT_STATUS_CANCELLED_KEY,
  [ProductStatuses.PROPOSED_PENDING_FOR_RTB]: ProductStatuses.PRODUCT_STATUS_PENDING_KEY,
  [ProductStatuses.PENDING_RTB_SIZES]: ProductStatuses.PRODUCT_STATUS_PENDING_KEY,
  [ProductStatuses.RTB_PENDING_CANCELLATION]: ProductStatuses.PRODUCT_STATUS_PENDING_KEY,
}

export const pendingStatuses = [
  ProductStatuses.PROPOSED_PENDING_FOR_RTB,
  ProductStatuses.PENDING_RTB_SIZES,
  ProductStatuses.RTB_PENDING_CANCELLATION,
]

export const statusKeyToValue = (key: ProductStatusKey): ProductStatus => statusValueLookup[key]

export const statusValueToKey = (value: ProductStatus): ProductStatusKey => statusKeyLookup[value]

export const isPendingStatus = (status: ProductStatus): boolean => pendingStatuses.includes(status)

export const getStatusIgnoringPendingOdbms = (status: ProductStatus) =>
  status === ProductStatuses.PENDING_ODBMS
    ? ProductStatuses.PRODUCT_STATUS_PROPOSED_KEY
    : statusValueToKey(status)
