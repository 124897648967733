import { connect } from 'react-redux'

import { Columns } from './Columns'
import { AppState } from 'src/reducers/root'
import { getColumnsValues } from 'src/selectors/referenceData/columns'
import { updateColumnsOrder } from 'src/actions/referenceData/columns'

const mapStateToProps = (state: AppState) => ({
  columns: getColumnsValues(state),
})

const mapDispatchToProps = {
  updateColumnsOrder,
}

export const ColumnsContainer = connect(mapStateToProps, mapDispatchToProps)(Columns)
