import React from 'react'
import {
  deprecatedIcon,
  deprecatedValue,
  editBox,
  listTag,
  readOnlyTag,
  tag,
  editable,
  tagText,
  tagLink,
} from './EditAttributeTag.scss'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { AttributeValue } from 'src/types/index'
import { Link } from '../Link/Link'
import { DeprecatedIcon } from '../../assets/icons/DeprecatedIcon'
import { NonDeprecatedIcon } from '../../assets/icons/NonDeprecatedIcon'
import classNames from 'classnames'

type Props = {
  attributeValue: AttributeValue
  onChange: (val: AttributeValue) => void
  onToggleDeprecated: (val: AttributeValue) => void
}

type State = {
  inEditMode: boolean
  newName: string
  deprecated: boolean
  timeoutID?: NodeJS.Timeout
}

const SECOND = 1000
const EDIT_TIMEOUT = 3 * SECOND

export class EditAttributeTag extends React.Component<Props, State> {
  state = {
    inEditMode: false,
    newName: this.props.attributeValue.value,
    deprecated: this.props.attributeValue.deprecated,
    timeoutID: undefined,
  }

  componentWillUnmount(): void {
    clearTimeout(this.state.timeoutID)
  }

  render(): JSX.Element {
    const wrapperClasses = classNames(
      {
        [editable]: this.state.inEditMode,
      },
      tag,
      listTag,
      readOnlyTag,
    )

    const editClasses = classNames(tagText, {
      [deprecatedValue]: this.props.attributeValue.deprecated,
    })

    return (
      <div className={wrapperClasses}>
        <Link className={tagLink} onClick={this.handleDeprecatedClick}>
          {this.props.attributeValue.deprecated ? (
            <DeprecatedIcon className={deprecatedIcon} />
          ) : (
            <NonDeprecatedIcon className={deprecatedIcon} />
          )}
        </Link>
        {this.state.inEditMode && (
          <div>
            <input
              className={editBox}
              data-cy="textfield"
              value={this.state.newName}
              type="text"
              autoComplete="off"
              disabled={false}
              onChange={this.handleOnChange}
              onBlur={this.handleOnBlur}
              autoFocus
              onKeyUp={this.handleOnKeyUp}
            />
          </div>
        )}
        {!this.state.inEditMode && (
          <span onDoubleClick={this.openEditBox} className={editClasses}>
            {this.props.attributeValue.value}
          </span>
        )}
      </div>
    )
  }

  handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === ENTER_KEY) {
      event.preventDefault()
      this.exitEditMode()
      clearTimeout(this.state.timeoutID)
    }
  }

  handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    event.preventDefault()

    this.exitEditMode()
    clearTimeout(this.state.timeoutID)
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()

    const { value } = event.target
    this.postponeTimeout()
    this.setState({
      newName: value,
    })
  }

  handleDeprecatedClick = (): void => {
    this.props.onToggleDeprecated(this.props.attributeValue)
  }

  postponeTimeout(): void {
    clearTimeout(this.state.timeoutID)
    this.scheduleDisableEditing()
  }

  exitEditMode(): void {
    this.setState({
      inEditMode: false,
    })
    this.props.onChange({
      ...this.props.attributeValue,
      value: this.state.newName,
    })
  }

  enterEditMode(): void {
    this.setState({
      inEditMode: true,
      newName: this.props.attributeValue.value,
    })
  }

  openEditBox = (): void => {
    this.enterEditMode()
    this.scheduleDisableEditing()
  }

  scheduleDisableEditing(): void {
    const timeoutID = setTimeout(() => {
      this.exitEditMode()
    }, EDIT_TIMEOUT)
    this.setState({
      timeoutID,
    })
  }
}
