import { AppState } from 'src/reducers/root'
import { getGridSlice } from './grid'
import { createSelector } from 'reselect'
import { FlatHierarchyItem, TreeHierarchyItem } from 'src/types/index'
import { BEAUTY_AND_BRANDS_DIVISION_NAME, RangeHierachyLevel } from 'constants/hierachy'
import { getDepartmentByHierarchy } from 'service/hierarchy/utils'

export const getSelectedHierarchies = (state: AppState) =>
  getGridSlice(state).hierarchy.selected.map(
    (sh) => state.injectorUI.referenceData.hierarchy.lookupTable[sh],
  )

export const getSelectedHierarchySlugs = (state: AppState) => getGridSlice(state).hierarchy.selected

export const getExpandedHierarchyNodes = (state: AppState) =>
  getGridSlice(state).hierarchy.expandedNodes

export const getHierarchy = (state: AppState): TreeHierarchyItem[] =>
  state.injectorUI.referenceData.hierarchy.tree

export const getHierarchyLookupTable = (state: AppState) =>
  state.injectorUI.referenceData.hierarchy.lookupTable

export const getDepartmentsWithChildren = (state: AppState) =>
  state.injectorUI.referenceData.hierarchy.departmentsWithChildren

export const getHierarchyForProduct = (state: AppState, hierarchySlug: string): FlatHierarchyItem =>
  state.injectorUI.referenceData.hierarchy.lookupTable[hierarchySlug]

export const getAdminDepartments = createSelector(
  getDepartmentsWithChildren,
  getHierarchyLookupTable,
  (departmentsWithChildren, lookUpTable) =>
    Object.keys(departmentsWithChildren).map((departmentSlug) => lookUpTable[departmentSlug]),
)

export const isHierarchyInBeautyAndBrandsDivision = (
  hierarchySlug: string,
  state: AppState,
): boolean => {
  const beautyAndBrandsDivision =
    state?.injectorUI?.referenceData?.hierarchy?.tree?.find(
      (division: TreeHierarchyItem) => division.title === BEAUTY_AND_BRANDS_DIVISION_NAME,
    ) ?? ({} as TreeHierarchyItem)
  const beautyAndBrandsDepartments: string[] = []
  beautyAndBrandsDivision?.children?.forEach((departmentGroup) => {
    departmentGroup?.children?.forEach((department) => {
      beautyAndBrandsDepartments.push(department?.slug)
    })
  })

  const departmentByHierarchySlug = getDepartmentByHierarchy(
    hierarchySlug,
    state?.injectorUI?.referenceData?.hierarchy?.departmentsWithChildren,
  )

  return beautyAndBrandsDepartments.some(
    (beautyAndBrandDepartment) => beautyAndBrandDepartment === departmentByHierarchySlug,
  )
}

export const isHierarchySlugAtRangeLevel = (state: AppState, hierarchySlug: string) => {
  const lookUpTable = getHierarchyLookupTable(state)
  return lookUpTable[hierarchySlug]?.levelName === RangeHierachyLevel
}
