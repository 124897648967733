import React, { Component } from 'react'
import classNames from 'classnames'

import {
  KeyboardNavigationContext,
  RITableContext,
} from 'src/components/KeyboardNavigation/KeyboardNavigationContext'
import { SortedColumn } from 'src/actions/grid/sortedColumns'
import { TableHeadContext, TableHeadContextType } from './TableHeadContext'

const getSortClass = (sortedColumn?: SortedColumn) =>
  !sortedColumn ? '' : sortedColumn.desc ? '-sort-desc' : '-sort-asc'

type Props = {
  className: string
  style: any
  columnName: string
  sortable: boolean
  filterPopover: boolean
  sortedColumn?: SortedColumn
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  children: React.ReactNode
}

type PropsWithContext = Props & RITableContext

export class TableHead extends Component<PropsWithContext, TableHeadContextType> {
  thRef: React.RefObject<HTMLDivElement>

  constructor(props: PropsWithContext) {
    super(props)
    this.state = {
      isActive: false,
      setActive: this.setActive,
    }

    this.thRef = React.createRef()
  }

  setActive = (isActive: boolean) => {
    this.setState({ isActive })
  }

  render() {
    const { style } = this.props

    return (
      <TableHeadContext.Provider value={this.state}>
        <div
          ref={this.thRef}
          role="heading"
          style={style}
          className={this.tableClass()}
          onClick={this.onClick}
        >
          {this.props.children}
        </div>
      </TableHeadContext.Provider>
    )
  }

  onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { sortable, onClick, resetSelection } = this.props

    if (!sortable || event.target !== this.thRef.current) {
      return
    }

    onClick(event)
    resetSelection()
  }

  tableClass = () => {
    const { className, columnName, sortable, sortedColumn, filterPopover } = this.props

    return classNames('rt-th', className, columnName, getSortClass(sortedColumn), {
      '-cursor-pointer': sortable,
      'rt-th-filterPopover': filterPopover,
      active: this.state.isActive,
    })
  }
}

export default function TableHeadWrapper(props: Props) {
  return (
    <KeyboardNavigationContext.Consumer>
      {(context) => <TableHead {...props} {...context} />}
    </KeyboardNavigationContext.Consumer>
  )
}
