import { createSelector } from 'reselect'

import {
  FETCH_PRODUCT_COMMENTS,
  FETCH_PRODUCT_COMMENTS_SUCCESS,
  FETCH_PRODUCT_COMMENTS_FAILURE,
  POST_PRODUCT_COMMENT,
  POST_PRODUCT_COMMENT_SUCCESS,
  POST_PRODUCT_COMMENT_FAILURE,
  CommentActions,
} from '../actions/comments'
import { Product } from 'src/types/index'
import { ProductComment } from '../types/Comment'
import { AppState } from './root'

export type ProductCommentsState = {
  [key: string]: ProductComment
}

const commentsOrDefault = (state: ProductCommentsState, slug: string) =>
  (state[slug] && state[slug].comments) || []

const statusOrDefault = (state: ProductCommentsState, slug: string) =>
  (state[slug] && state[slug].status) || ''

const getComments = (state: AppState) => state.injectorUI.productComments

export const getCommentsForSlug = createSelector(
  getComments,
  (state: ProductCommentsState, ownProps: { product: Product }) => ownProps.product.slug,
  (productComments, slug) => commentsOrDefault(productComments, slug),
)

export const getCommentsStatus = createSelector(
  getComments,
  (state: ProductCommentsState, ownProps: { product: Product }) => ownProps.product.slug,
  (productComments, slug) => statusOrDefault(productComments, slug),
)

const updateStatus = (state: ProductCommentsState, slug: string, status: string) => ({
  ...state,
  [slug]: {
    comments: commentsOrDefault(state, slug),
    status,
  },
})

const recordError = (state: ProductCommentsState, slug: string, error: string) => ({
  ...state,
  [slug]: {
    comments: commentsOrDefault(state, slug),
    error,
    status: 'error',
  },
})

export const productComments = (state: ProductCommentsState = {}, action: CommentActions) => {
  switch (action.type) {
    case FETCH_PRODUCT_COMMENTS:
      return updateStatus(state, action.productSlug, 'fetching')
    case FETCH_PRODUCT_COMMENTS_SUCCESS:
      return {
        ...state,
        [action.productSlug]: { comments: action.comments, status: 'ready' },
      }
    case FETCH_PRODUCT_COMMENTS_FAILURE:
      return recordError(state, action.productSlug, action.error)
    case POST_PRODUCT_COMMENT:
      return updateStatus(state, action.productSlug, 'posting')
    case POST_PRODUCT_COMMENT_SUCCESS:
      return updateStatus(state, action.productSlug, 'ready')
    case POST_PRODUCT_COMMENT_FAILURE:
      return recordError(state, action.productSlug, action.error)
    default:
      return state
  }
}
