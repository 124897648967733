import get from 'lodash/get'
import { AppState } from '../reducers/root'
import { GridType } from 'src/types/index'

export const getIndexesOfSavingImages = (state: AppState, productSlug: string): number[] =>
  get(state.injectorUI.images, `${productSlug}.indexesOfSavingImages`, []) as number[]

export const isSketchSaving = (state: AppState, productSlug: string): boolean =>
  getIndexesOfSavingImages(state, productSlug).includes(0)

export const getProductImages = (state: AppState, productSlug: string, grid: GridType) =>
  get(state.injectorUI[grid].products.productsMap, `${productSlug}.images`, [])
