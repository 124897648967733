import React, { Component } from 'react'
import { Menu } from 'src/components/Menu/index'
import { menuWrapper, menu as menuClass } from '../ActionsCell.scss'
import { setFocus } from 'src/service/ref/index'
import { RIGHT_KEY, TAB_KEY } from 'src/constants/keyCodes'
import ErrorMenu from './ErrorMenu/ErrorMenu'
import { ProductEventError } from 'src/types/ProductEventError'
import { MenuItem } from 'src/types/Menu'
import { LEFT_TOP } from 'src/constants/popover'
import { IntentionalAny } from 'types'

interface Props {
  menu: MenuItem[]
  onDismissError?: (errorID: string) => void
  productErrors?: ProductEventError[]
  closePopover?: () => void
}

type State = {
  menuFocused: boolean
}

export class ActionsMenu extends Component<Props, State> {
  ref: IntentionalAny
  state = { menuFocused: false }

  componentDidMount(): void {
    setFocus(this.ref)
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === RIGHT_KEY || event.key === TAB_KEY) {
      event.preventDefault()
      this.setState({ menuFocused: true })
    }
  }

  render(): JSX.Element {
    const { menu, productErrors, onDismissError } = this.props
    const defaultSelectedIndex = this.state.menuFocused ? 0 : -1
    return (
      <div
        className={menuWrapper}
        data-cy="actionsMenu"
        tabIndex={-1}
        onKeyDown={this.onKeyDown}
        ref={(node): void => {
          this.ref = node
        }}
      >
        <Menu
          menu={menu}
          closePopover={this.props.closePopover}
          className={menuClass}
          defaultSelectedIndex={defaultSelectedIndex}
          position={LEFT_TOP}
        />
        <ErrorMenu productErrors={productErrors} onDismissError={onDismissError} />
      </div>
    )
  }
}
