import { AttributeValue, Country } from 'src/types/index'
import { Supplier } from 'src/types/Supplier'
import { NO, YES } from '../../constants/boolean'
import { CANCELLED, PENDING_ODBMS } from '../../constants/productStatus'
import { MapperValue } from '../../registry/behaviourRegistry'

export const attributeValuesMapper = (identifiers: string[], referenceData: any): MapperValue[] =>
  identifiers
    .map((slug) => attributeValueFor(slug, referenceData))
    .map(attributeValueToDropdownItem)

export const countriesMapper = (identifiers: string[], referenceData: any): MapperValue[] =>
  identifiers
    .map((countryCode) => countryFor(countryCode, referenceData))
    .map(countryToDropdownItem)

export const seasonsMapper = (identifiers: string[]): MapperValue[] =>
  identifiers.map((seasonCode) => ({
    value: seasonCode,
    label: seasonCode,
    deprecated: false,
  }))

export const suppliersMapper = (identifiers: string[], referenceData: any): MapperValue[] =>
  identifiers
    .map((supplierSlug) => supplierFor(supplierSlug, referenceData))
    .map(supplierToDropdownItem)

export const booleanMapper = (identifiers: string[]): MapperValue[] =>
  identifiers.map((value) => ({
    value: value === 'true',
    label: value === 'true' ? YES : NO,
    deprecated: false,
  }))

export const statusMapper = (identifiers: string[]): MapperValue[] =>
  [
    ...new Set(identifiers.map((status) => (status.startsWith(CANCELLED) ? CANCELLED : status))),
  ].map((status) => ({
    value: status,
    label: status === PENDING_ODBMS ? 'Retry' : status,
    deprecated: false,
  }))

export const userNameMapper = (identifiers: string[]): MapperValue[] =>
  identifiers.map((userName) => ({
    value: userName,
    label: userName,
    deprecated: false,
  }))

const supplierToDropdownItem = (supplier: Supplier | null | undefined): MapperValue =>
  supplier
    ? {
        value: supplier.slug,
        label: `${supplier.value} - ${supplier.code}`,
        deprecated: !supplier.active,
      }
    : { value: '', label: '', deprecated: false }

const supplierFor = (supplierSlug: string, suppliers: Supplier[]) =>
  suppliers.find(({ slug }) => slug === supplierSlug)

const countryToDropdownItem = (country: Country | null | undefined): MapperValue =>
  country
    ? {
        value: country.code,
        label: country.name,
        deprecated: country.deprecated,
      }
    : { value: '', label: '', deprecated: false }

const countryFor = (countryCode: string, countries: Country[]): Country | null | undefined =>
  countries.find(({ code }) => code === countryCode)

const attributeValueToDropdownItem = (
  attributeValue: AttributeValue | null | undefined,
): MapperValue =>
  attributeValue
    ? {
        value: attributeValue.slug || '',
        label: attributeValue.value,
        deprecated: attributeValue.deprecated,
      }
    : { value: '', label: '', deprecated: false }

const attributeValueFor = (
  identifier: string,
  attributeValues: AttributeValue[],
): AttributeValue | null | undefined => attributeValues.find(({ slug }) => slug === identifier)
