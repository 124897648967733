import { DepartmentHierachyLevel } from '../../constants/hierachy'
import { TreeHierarchyItem } from 'src/types/index'
import { visit } from './visit'

export const generateDepartmentSet = (hierarchy: Array<TreeHierarchyItem>) => {
  const departments: Record<string, Array<string>> = {}

  if (!hierarchy || hierarchy.length === 0) {
    return departments
  }

  hierarchy.forEach((h: TreeHierarchyItem) => {
    visit(h, (node: TreeHierarchyItem) => {
      if (node.levelName === DepartmentHierachyLevel) {
        const slugsWithinDepartment = [node.slug]
        node.children &&
          node.children.forEach((child: TreeHierarchyItem) => {
            visit(child, (c: TreeHierarchyItem) => {
              slugsWithinDepartment.push(c.slug)
            })
          })

        departments[node.slug] = slugsWithinDepartment
      }
    })
  })

  return departments
}
