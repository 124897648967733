import React from 'react'
import { avatar } from './icons.scss'

export const Avatar = () => (
  <svg
    className={avatar}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <circle id="avatar-a" cx="16" cy="16" r="16" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <mask id="avatar-b" fill="#fff">
        <use xlinkHref="#avatar-a" />
      </mask>
      <use stroke="#FFF" xlinkHref="#avatar-a" />
      <path
        fill="#FFF"
        d="M13.4222283,21.059667 C11.1713664,20.0680376 9.6,17.8175249 9.6,15.2 C9.6,11.6653776 12.4653776,8.8 16,8.8 C19.5346224,8.8 22.4,11.6653776 22.4,15.2 C22.4,17.8175249 20.8286336,20.0680376 18.5777717,21.059667 C24.4114503,22.2524969 28.8,27.4137481 28.8,33.6 C28.8,40.6692448 23.0692448,46.4 16,46.4 C8.9307552,46.4 3.2,40.6692448 3.2,33.6 C3.2,27.4137481 7.58854967,22.2524969 13.4222283,21.059667 Z"
        mask="url(#avatar-b)"
      />
    </g>
  </svg>
)
