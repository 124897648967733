import { GridType } from 'src/types/index'

export const RECLASSIFICATION = 'RECLASSIFICATION'
export type ReclassificationAction = ReturnType<typeof reclassification>
export const reclassification = (
  hierarchySlug: string,
  idsToReclassify: string[],
  grid: GridType,
) =>
  <const>{
    type: RECLASSIFICATION,
    hierarchySlug,
    slugsToReclassify: idsToReclassify,
    grid,
  }
