import React from 'react'
import { cell, cellValue } from './MonoCurrencyCellRenderer.scss'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { IntentionalAny, Product } from 'src/types/index'
import { PatchPayload } from 'src/actions/grid/product/update'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { setFocus, setSelected } from '../../../service/ref'
import { codesToPrefixes } from '../../../service/excel/exporter/valueMappers'

type Props = {
  editMode: boolean
  accessor: string
  product: Product
  updateProduct: (payload: PatchPayload) => void
  exitEditMode: (exit: boolean) => void
  mandatory: boolean
  currencies: string[]
  currency?: string
}

export const MonoCurrencyCellRenderer = ({
  editMode,
  accessor,
  product,
  updateProduct,
  exitEditMode,
  mandatory,
  currency,
}: Props): JSX.Element => {
  const value = product[accessor] ? product[accessor].value : 0
  const isValid = (value: number): boolean => value > 0 || !mandatory

  let currentValue = value

  const onChange = ({ floatValue }: NumberFormatValues): number => (currentValue = floatValue || 0)

  const update = (): void => {
    if (currentValue !== value && isValid(currentValue)) {
      updateProduct({
        productSlug: product.slug,
        patchFields: {
          store: { [accessor]: { value: currentValue, currency } },
        },
      })
    }
    exitEditMode(true)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === ENTER_KEY) {
      e.target instanceof HTMLInputElement && update()
    }
  }

  const getCurrencyValue = (): IntentionalAny => {
    if (value) {
      return value
    }
    return 0
  }

  return (
    <div className={cell}>
      <NumberFormat
        displayType={editMode ? 'input' : 'text'}
        value={getCurrencyValue()}
        thousandSeparator
        prefix={currency ? codesToPrefixes[currency] : '£'}
        isAllowed={({ floatValue }): boolean =>
          floatValue === undefined || floatValue <= MAX_CURRENCY_FIELD_VALUE
        }
        decimalScale={2}
        fixedDecimalScale
        allowNegative={false}
        onBlur={update}
        onValueChange={onChange}
        onKeyDown={onKeyDown}
        renderText={(value): React.ReactNode => <div className={cellValue}>{value}</div>}
        getInputRef={(ref: any) => {
          if (editMode) {
            setFocus(ref)
            setTimeout(() => {
              setSelected(ref)
            }, 10) // this is due to number format using set timeouts
          }
        }}
      />
    </div>
  )
}

export const MAX_CURRENCY_FIELD_VALUE = 999999.99
