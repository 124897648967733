import React from 'react'

interface Props {
  className: string
}

export const Arrow = ({ className }: Props): JSX.Element => (
  <svg className={className} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09032 16.6377C7.36838 16.9158 8.20253 17.0548 9.03669 16.6377C9.59279 16.0816 9.59279 15.2197 9.03669 14.6636L4.75469 10.3816H20.0197C20.7983 10.3816 21.41 9.76986 21.41 8.99131C21.41 8.21277 20.7983 7.60105 20.0197 7.60105H4.75469L9.06449 3.29125C9.6206 2.73514 9.6206 1.87318 9.06449 1.31708C8.50839 0.760976 7.64643 0.760976 7.09032 1.31708L0.417078 7.99033C0.166831 8.26838 0 8.60204 0 8.99131C0 9.38059 0.166831 9.71425 0.417078 9.96449L7.09032 16.6377Z" />
  </svg>
)
