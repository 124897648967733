import { GridType } from 'src/types/index'

export const ADD_PARTIAL_FILTER = 'ADD_PARTIAL_FILTER'
export const REMOVE_PARTIAL_FILTER = 'REMOVE_PARTIAL_FILTER'
export const ADD_PARTIAL_FILTER_SUCCESS = 'ADD_PARTIAL_FILTER_SUCCESS'
export const REMOVE_PARTIAL_FILTER_SUCCESS = 'REMOVE_PARTIAL_FILTER_SUCCESS'

export type PartialFiltersActions = ReturnType<
  | typeof addPartialFilter
  | typeof addPartialFilterSuccess
  | typeof removePartialFilter
  | typeof removePartialFilterSuccess
>

export type PartialFilter = {
  value: string
  property: string
  displayName: string
}

export const addPartialFilter = (filter: PartialFilter, grid: GridType) =>
  <const>{
    type: ADD_PARTIAL_FILTER,
    filter,
    grid,
  }

export const addPartialFilterSuccess = (filter: PartialFilter, grid: GridType) =>
  <const>{
    type: ADD_PARTIAL_FILTER_SUCCESS,
    filter,
    grid,
  }

export const removePartialFilter = (filter: PartialFilter, grid: GridType) =>
  <const>{
    type: REMOVE_PARTIAL_FILTER,
    filter,
    grid,
  }

export const removePartialFilterSuccess = (filter: PartialFilter, grid: GridType) =>
  <const>{
    type: REMOVE_PARTIAL_FILTER_SUCCESS,
    filter,
    grid,
  }
