import React from 'react'
import { cell, scrollWrapper } from './NullCellRenderer.scss'

export const NullCellRenderer = (): JSX.Element => {
  return (
    <div className={scrollWrapper}>
      <div className={cell}>Failed to display content. Please contact tech support.</div>
    </div>
  )
}
