import React from 'react'

import { Checkbox } from '../Checkbox/Checkbox'
import { label, container } from './CheckboxFormField.scss'

type Props = {
  name: string
  className: string
  header: string
  checked: boolean
  disabled?: boolean
  onClick?: (val: boolean) => void
  onBlur?: (e: React.FocusEvent<HTMLAnchorElement>) => void
}

export const CheckboxFormField = ({ name, header, className, ...rest }: Props) => (
  <div className={`${container} ${className}`}>
    <label className={label} htmlFor={name}>
      {header}
    </label>
    <Checkbox {...rest} />
  </div>
)
