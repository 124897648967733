import { connect } from 'react-redux'
import { CountryOfOriginTool } from './CountryOfOriginTool'
import { AppState } from 'src/reducers/root'
import { getCountries } from 'src/selectors/referenceData/countries'
import {
  updateCountry as updateCountryAction,
  CountryPayload,
} from 'src/actions/referenceData/countries'

const mapStateToProps = (state: AppState) => ({
  countries: getCountries(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  updateCountry: (code: string, payload: CountryPayload) =>
    dispatch(updateCountryAction(code, payload)),
})

export const CountryOfOriginToolContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CountryOfOriginTool)
