import { GridType } from 'src/types/index'

export const ADD_HIGHLIGHTED_ROW = 'ADD_HIGHLIGHTED_ROW'
export const CLEAR_HIGHLIGHTED_ROWS = 'CLEAR_HIGHLIGHTED_ROWS'

export type HighlightedRowActions = ReturnType<
  typeof addHighlightedRow | typeof clearHighlightedRows
>

export const addHighlightedRow = (productSlug: string, grid: GridType) => {
  return <const>{
    type: ADD_HIGHLIGHTED_ROW,
    productSlug,
    grid,
  }
}

export const clearHighlightedRows = (grid: GridType) => {
  return <const>{
    type: CLEAR_HIGHLIGHTED_ROWS,
    grid,
  }
}
