import React from 'react'
import PrimaryNavigation from '../PrimaryNavigation'
import UtilityNavigation from '../UtilityNavigation'
import LockStatus from '../LockStatus'
import { header, logoContainer } from './Header.scss'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { RILogo } from 'components/RILogo/RILogo.scss'

export class Header extends React.Component {
  render(): JSX.Element {
    return (
      <header id="NavBar" className={header}>
        <div className={logoContainer}>
          <Link to="/">
            <RILogo />
          </Link>
        </div>
        <PrimaryNavigation mobileMenuOpen={false} />
        <LockStatus />
        <UtilityNavigation />
      </header>
    )
  }
}

// @ts-ignore
export default withRouter(Header)
