import React from 'react'
import {
  deprecatedIcon,
  deprecatedValue,
  listTag,
  readOnlyTag,
  tag,
  tagLink,
  tagText,
} from '../EditAttributeTag/EditAttributeTag.scss'
import classNames from 'classnames'
import { DeprecatedIcon } from '../../assets/icons/DeprecatedIcon'
import { NonDeprecatedIcon } from '../../assets/icons/NonDeprecatedIcon'

export type TagElement = {
  identifier: string | number
  value: string
  deprecated: boolean
}

type Props = {
  tag: TagElement
  onClick: (tag: TagElement) => void
  className: string
}

export const EditTag = ({ tag: tagElement, onClick, className }: Props) => {
  const onClickHandler = () => {
    onClick(tagElement)
  }

  const customClassName = classNames({
    [className]: className,
  })
  return (
    <div className={`${tag} ${listTag} ${readOnlyTag} ${customClassName}`}>
      <a className={tagLink} onClick={onClickHandler}>
        {tagElement.deprecated ? (
          <DeprecatedIcon className={deprecatedIcon} />
        ) : (
          <NonDeprecatedIcon className={deprecatedIcon} />
        )}
      </a>
      <span
        data-cy="tag-element-text"
        className={classNames(tagText, {
          [deprecatedValue]: tagElement.deprecated,
        })}
      >
        {tagElement.value}
      </span>
    </div>
  )
}
