import { connect } from 'react-redux'

import { Attributes } from './Attributes'
import { AppState } from 'src/reducers/root'
import { getAttributeRenderSpecs } from 'src/selectors/referenceData/columns'

const mapStateToProps = (state: AppState) => {
  const attributes = getAttributeRenderSpecs(state)
  const sortedList = attributes.slice().sort((a, b) => a.displayName.localeCompare(b.displayName))
  return {
    attributes: sortedList,
  }
}

export const AttributesContainer = connect(mapStateToProps)(Attributes)
