/* eslint-disable no-async-promise-executor */

import axios from 'axios'
import authHeader from '../auth/headers'

export const getImage = (imageUrl: string): Promise<string | ArrayBuffer | null> => {
  return new Promise(async (resolve, reject) => {
    const header = await authHeader()

    if (!header) {
      throw new Error('No authentication header')
    }

    const { data } = await axios.get(imageUrl, {
      responseType: 'blob',
      headers: header,
    })

    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(data)
  })
}

export const uploadImage = async (url: string, base64Image: string) => {
  const headers = await authHeader()

  if (!headers) {
    throw new Error('No authentication header')
  }

  return axios.put(url, base64Image, { headers })
}
