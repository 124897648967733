import { PollingState } from './types'
import { ADD_POLLING_ID, RECONCILE_POLLING_ID, ADD_POLLING_IDS } from '../../actions/polling/types'
import { SVPAction } from 'actions/svp'

const defaultState = {
  status: '',
  pollingIDs: [],
}

export default (state: PollingState = defaultState, action: SVPAction): PollingState => {
  switch (action.type) {
    case ADD_POLLING_ID:
      return {
        ...state,
        pollingIDs: [
          ...state.pollingIDs,
          {
            id: action.id,
            productSlug: action.productSlug,
            developmentId: action.developmentId,
            pollType: action.pollType,
          },
        ],
      }
    case ADD_POLLING_IDS:
      return {
        ...state,
        pollingIDs: [...state.pollingIDs, ...action.items],
      }
    case RECONCILE_POLLING_ID:
      return {
        ...state,
        pollingIDs: state.pollingIDs.filter((item) => action.ids.includes(item.id)),
      }
    default:
      return state
  }
}
