export type MenuItem = {
  text: string
  callback?: () => void
  disabled?: boolean
  options?: MenuItemOption[]
}

export type MenuItemOption = {
  text: string
  type: MenuItemOptionType
  selected?: boolean
  isLocked?: boolean
  callback: () => void
}

export enum MenuItemOptionType {
  LINK = 'link',
  CHECKBOX = 'checkbox',
}
