import { GridType, Product } from 'src/types/index'

export type CopyProductActions = ReturnType<
  | typeof duplicateProduct
  | typeof duplicateProductSuccess
  | typeof copyProductToHierarchy
  | typeof displayCopyToNavigationChoice
  | typeof closeCopyToNavigation
  | typeof displayCopyToNavigationChoice
  | typeof closeCopyToNavigation
  | typeof goToCopiedProductInDevTracker
>

export const DUPLICATE_PRODUCT = 'DUPLICATE_PRODUCT'
export const DUPLICATE_PRODUCT_SUCCESS = 'DUPLICATE_PRODUCT_SUCCESS'

export type DuplicateProductPayload = {
  product: Product
  colourLink?: boolean
  supplierLink?: boolean
  crossDepartmentLink?: boolean
  matchingProductLink?: boolean
  inheritStyle: boolean
}

export const duplicateProduct = (payload: DuplicateProductPayload, grid: GridType) =>
  <const>{
    type: DUPLICATE_PRODUCT,
    payload,
    grid,
  }

export const duplicateProductSuccess = (
  payload: Product,
  sourceProductSlug: string,
  grid: GridType,
) =>
  <const>{
    type: DUPLICATE_PRODUCT_SUCCESS,
    payload: {
      newProduct: payload,
      sourceProductSlug,
    },
    grid,
  }

export const COPY_PRODUCT_TO_HIERARCHY = 'COPY_PRODUCT_TO_HIERARCHY'

export type CopyProductToHierarchyPayload = {
  sourceProduct: Product
  hierarchySlug: string
  email: string
  crossDepartmentLink: boolean
  inheritStyle: boolean
}

export const copyProductToHierarchy = (payload: CopyProductToHierarchyPayload, grid: GridType) =>
  <const>{
    type: COPY_PRODUCT_TO_HIERARCHY,
    payload,
    grid,
  }

export const DISPLAY_COPY_TO_NAVIGATION_CHOICE = 'DISPLAY_COPY_TO_NAVIGATION_CHOICE'

export const displayCopyToNavigationChoice = (
  hierarchySlug: string,
  productSlug: string,
  grid: GridType,
  hasWholesaleImages: boolean,
) =>
  <const>{
    type: DISPLAY_COPY_TO_NAVIGATION_CHOICE,
    hierarchySlug,
    productSlug,
    grid,
    hasWholesaleImages,
  }

export const CLOSE_COPY_TO_NAVIGATION = 'CLOSE_COPY_TO_NAVIGATION'

export const closeCopyToNavigation = () =>
  <const>{
    type: CLOSE_COPY_TO_NAVIGATION,
  }

export const GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER = 'GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER'

export const goToCopiedProductInDevTracker = (productSlug: string, hierarchySlug: string) =>
  <const>{
    type: GO_TO_COPIED_PRODUCT_IN_DEV_TRACKER,
    productSlug,
    hierarchySlug,
  }
