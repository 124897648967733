import React from 'react'
import { menuListItem, menuLink, active } from './Menu.scss'
import { MenuItem } from '../../types/Menu'
import MenuLink from '../Link/index'
import { DOWN_KEY, UP_KEY, ENTER_KEY } from 'src/constants/keyCodes'
import { setFocus } from 'src/service/ref'
import { TOP_RIGHT } from '../../constants/popover'
import { MenuOptions } from './MenuOptions/MenuOptions'

type Props = {
  menu: MenuItem[]
  defaultSelectedIndex: number
  className: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLUListElement>) => void
  closePopover?: () => void
}

type State = {
  selectedIndex: number
  menuItemWithOptions: string
}

export class Menu extends React.Component<Props, State> {
  menuItemsRef: any
  menuItemsRefs: (HTMLLIElement | null)[]
  state = { selectedIndex: -1, menuItemWithOptions: '' }

  constructor(props: Props) {
    super(props)
    this.state = { selectedIndex: props.defaultSelectedIndex, menuItemWithOptions: '' }
    this.menuItemsRefs = []
  }

  static defaultProps = {
    position: TOP_RIGHT,
    defaultSelectedIndex: -1,
    className: '',
  }

  componentDidMount() {
    if (this.props.defaultSelectedIndex >= 0) {
      setFocus(this.menuItemsRef)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.defaultSelectedIndex !== this.props.defaultSelectedIndex) {
      this.setState({ selectedIndex: this.props.defaultSelectedIndex })
      setFocus(this.menuItemsRef)
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (this.state.selectedIndex < 0) {
      return
    }
    event.persist()
    event.preventDefault()
    const { menu, onKeyDown } = this.props
    const { selectedIndex } = this.state
    const menuItem = menu[selectedIndex]
    switch (event.key) {
      case DOWN_KEY:
        this.setState((previousState) => {
          return {
            selectedIndex: Math.min(menu.length - 1, previousState.selectedIndex + 1),
          }
        })
        break
      case UP_KEY:
        this.setState((previousState) => {
          return {
            selectedIndex: Math.max(0, previousState.selectedIndex - 1),
          }
        })
        break
      case ENTER_KEY:
        if (menuItem.options?.length) {
          this.setState({ menuItemWithOptions: menuItem.text })
        } else {
          if (menuItem && menuItem.callback && !menuItem.disabled) {
            menuItem.callback()
          }
        }
        break
    }

    onKeyDown && onKeyDown(event)
  }

  onMenuItemClick = (menuItem: MenuItem) => {
    if (menuItem.options?.length) {
      this.setState({ menuItemWithOptions: menuItem.text })
    } else {
      menuItem.callback && menuItem.callback()
      this.props.closePopover && this.props.closePopover()
    }
  }

  render() {
    const { menu, className } = this.props
    return (
      <ul
        role="menu"
        tabIndex={-1}
        className={className}
        onKeyDown={this.onKeyDown}
        ref={(node) => (this.menuItemsRef = node)}
      >
        {menu.map((menuItem, index) => (
          <li
            key={index}
            ref={(ref) => {
              this.menuItemsRefs[index] = ref
            }}
            className={`${menuListItem} ${index === this.state.selectedIndex ? active : ''}`}
          >
            <MenuLink
              dataCy={menuItem.text.replace(/\s/g, '') + 'Button'}
              className={menuLink}
              onClick={(): void => this.onMenuItemClick(menuItem)}
              disabled={menuItem.disabled}
            >
              {menuItem.text}
            </MenuLink>
            {this.state.menuItemWithOptions === menuItem.text && !!menuItem.options && (
              <MenuOptions
                options={menuItem.options}
                menuItemHeight={this.menuItemsRefs[index]?.offsetHeight || 0}
                parentHeight={this.menuItemsRef?.offsetHeight || 0}
                selectedMenuItem={index}
              />
            )}
          </li>
        ))}
      </ul>
    )
  }
}
