import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { AttributesPage } from './attributes/AttributesPage'
import { DepartmentsPage } from './departments/DepartmentsPage'
import { ColumnsPage } from './columns/ColumnsPage'
import { ToolsPage } from './tools/ToolsPage'

export const Routes = () => (
  <Switch>
    <Route path="/admin/attributes" render={() => <AttributesPage />} exact />
    <Route path="/admin/departments" render={() => <DepartmentsPage />} exact />
    <Route path="/admin/columns" render={() => <ColumnsPage />} exact />
    <Route path="/admin/tools" exact render={() => <ToolsPage />} />

    <Route
      path="/admin/attributes/:attribute"
      render={({ match }) => <AttributesPage currentAttribute={match.params.attribute} />}
    />
    <Route
      path="/admin/departments/:department"
      render={({ match }) => <DepartmentsPage currentDepartmentSlug={match.params.department} />}
    />
    <Route
      path="/admin/tools/:tool"
      render={({ match }) => <ToolsPage selectedToolKey={match.params.tool} />}
    />
  </Switch>
)
