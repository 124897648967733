import React from 'react'
import { Option, Style } from 'src/types/index'
import { OptionCard } from './OptionCard/OptionCard'
import { ProductWithDynabutes } from '../../../../types/Product'
import { styleSectionThing, title } from './StyleSection.scss'
import { openStyleModal } from 'actions/grid/modal/styleModal'
import { detachProduct, detachSelectedProduct } from 'actions/grid/style'

type Props = {
  style: Style
  selectedProduct: ProductWithDynabutes
  detachProduct: typeof detachProduct
  detachSelectedProduct: typeof detachSelectedProduct
  openStyleModal: typeof openStyleModal
}

const contains = (products: ProductWithDynabutes[], currentSlug: string): ProductWithDynabutes =>
  products.find(({ slug }) => currentSlug === slug)

const sortOptions =
  (selectedProductSlug: string) =>
  ({ products }: Option): number =>
    contains(products, selectedProductSlug) ? -1 : 1

export const StyleSection = ({
  style,
  selectedProduct,
  detachProduct,
  detachSelectedProduct,
  openStyleModal,
}: Props): JSX.Element => {
  return (
    <div data-cy="style-section" className={styleSectionThing}>
      <h4 className={title}>
        {style.styleID} - {style.styleDescription}
      </h4>
      <ul>
        {style.options.sort(sortOptions(selectedProduct.slug)).map((option, index) => (
          <li key={option.slug}>
            <OptionCard
              selected={index === 0}
              option={option}
              selectedProduct={selectedProduct}
              detachProduct={(productSlug): void => {
                detachProduct(productSlug, style.slug)
              }}
              detachSelectedProduct={detachSelectedProduct}
              openStyleModal={openStyleModal}
              removable={true}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
