import {
  UPDATE_IMAGES,
  SAVE_SKETCH,
  UPDATE_IMAGES_FAILURE,
  UPDATE_IMAGES_SUCCESS,
} from 'src/actions/images'
import { SVPAction } from 'actions/svp'

export type ImagesState = {
  [key: string]: {
    indexesOfSavingImages?: number[]
  }
}

const defaultState = {}

const updateIndexesThatAreSaving =
  (updateFunction: (indexes: number[], index: number) => number[]) =>
  (state: ImagesState, slug: string, index: number) => {
    const currentStateForSlug = state[slug] || {}
    return {
      ...state,
      [slug]: {
        ...currentStateForSlug,
        indexesOfSavingImages: updateFunction(
          currentStateForSlug.indexesOfSavingImages || [],
          index,
        ),
      },
    }
  }

const addIndexThatIsSaving = updateIndexesThatAreSaving(
  (indexes: number[], index: number): number[] => [...indexes, index],
)
const removeIndexThatIsSaving = updateIndexesThatAreSaving(
  (indexes: number[], index: number): number[] => indexes.filter((i: number) => i !== index),
)

export const images = (state: ImagesState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case UPDATE_IMAGES:
      return addIndexThatIsSaving(state, action.payload.productSlug, action.payload.index)
    case SAVE_SKETCH:
      return addIndexThatIsSaving(state, action.payload.productSlug, 0)
    case UPDATE_IMAGES_SUCCESS:
    case UPDATE_IMAGES_FAILURE:
      return removeIndexThatIsSaving(state, action.productSlug, action.index)
    default:
      return state
  }
}
