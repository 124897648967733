import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { Config } from '../types/Config'

let sendToSentry = false
let outputSentryToConsole = false
const sentryDSN = 'https://e7de02900c494a32b22c25111f01fd46@o395598.ingest.sentry.io/5247726'

type SentryErrorScope = {
  tag: string
  level: Severity
}

export function init(config: Config) {
  //TODO remove dev when E2E fixed
  sendToSentry = config.env === 'prod' || config.env === 'staging'
  outputSentryToConsole = !!config.outputSentryToConsole

  if (!sendToSentry) {
    return
  }

  Sentry.init({
    release: config.buildNumber,
    environment: config.env,
    dsn: sentryDSN,
  })
}

export function logMessage(msg: string) {
  if (outputSentryToConsole) {
    console.log(`Sentry Message: ${msg}`)
  }

  if (!sendToSentry) {
    return
  }

  Sentry.captureMessage(msg)
}

export function logError(err: Error, errScope?: SentryErrorScope) {
  if (outputSentryToConsole) {
    console.log(`Sentry Message: ${JSON.stringify(err)}`)
  }

  if (!sendToSentry) {
    return
  }

  if (!(err instanceof Error)) {
    // @ts-ignore
    err = new Error(err.error || err.error_description || err.message || err)
  }

  Sentry.withScope((scope) => {
    if (errScope) {
      scope.setTag(errScope.tag, errScope.tag)
      scope.setLevel(errScope.level)
    }
    Sentry.captureException(err)
  })
}

export function addBreadcrumb(category: string, message: string) {
  if (outputSentryToConsole) {
    console.log(`Sentry Breadcrumb: ${category}: ${message}`)
  }

  if (!sendToSentry) {
    return
  }

  Sentry.addBreadcrumb({
    category: category,
    message: message,
    level: Sentry.Severity.Info,
  })
}

export function setUser(name: string) {
  if (!sendToSentry) {
    return
  }

  Sentry.configureScope((scope) => {
    scope.setUser({ id: name })
  })
}

export function setTag(tag: string, value = '') {
  if (!sendToSentry) {
    return
  }

  Sentry.configureScope((scope) => {
    scope.setTag(tag, value)
  })
}

export function setFeatureFlagTags(config: Config) {
  if (!sendToSentry) {
    return
  }

  if (!config || !config.featureToggles) {
    return
  }

  Object.keys(config.featureToggles).forEach((key) => {
    // @ts-ignore
    setTag(key, config.featureToggles[key])
  })
}
