import { pdfjs, PDFPageProxy } from 'react-pdf'
import loadImage from 'blueimp-load-image'
import { GetAuth } from '@ri-digital/auth0-authenticator'

import { logError } from 'src/service/errors'
import { trackEvent } from '../analytics/analytics'
import { APIError } from 'types/Api'
import { IntentionalAny } from 'types'

export const IMAGE_PROCESSING_ERROR = 'IMAGE_PROCESSING_ERROR'

export class ImageProcessingError extends Error {
  code: string

  constructor(...args: IntentionalAny) {
    super(...args)
    this.code = IMAGE_PROCESSING_ERROR
  }
}

const getScaledViewport = (page: PDFPageProxy, width: number): IntentionalAny => {
  const newScale = width / page.getViewport({ scale: 1 }).width
  return page.getViewport({ scale: newScale })
}

const newCanvasFrom = (width: number, height: number): HTMLCanvasElement => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  return canvas
}

export const extractBase64FromDataUrl = (dataUrl: string): string => {
  return dataUrl.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
}

export const getImageDataUrl = (file: File, convertToJPG = false): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const extension = convertToJPG ? 'image/jpeg' : file.type

      loadImage(
        file,
        (img: HTMLImageElement) => {
          const canvas = newCanvasFrom(img.width, img.height)
          const ctx = canvas.getContext('2d')
          if (ctx) {
            if (convertToJPG) {
              ctx.fillStyle = '#fff' // set white fill style for pngs with transparent BG
              ctx.fillRect(0, 0, canvas.width, canvas.height)
            }
            ctx.drawImage(img as HTMLImageElement, 0, 0)
            resolve(canvas.toDataURL(extension))
          } else {
            reject(new ImageProcessingError('Could not convert image to data url'))
          }
        },
        {},
      )
    } catch (error) {
      reject(new ImageProcessingError(error))
    }
  })
}

const resizePdfToDataUrlPng = (file: File | Blob, width: number): Promise<string> => {
  console.log(file)
  const auth = GetAuth()
  const profile = auth.getProfile()

  trackEvent('image', 'PDF Image Uploaded', profile.nickname)

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = async (): Promise<void> => {
      try {
        const pdfPromise = pdfjs.getDocument(fileReader.result)
        const pdf = await pdfPromise.promise
        const page = await pdf.getPage(1)
        const viewport = getScaledViewport(page as PDFPageProxy, width)
        const canvas = newCanvasFrom(viewport.width, viewport.height)

        const renderTask = page.render({
          canvasContext: canvas.getContext('2d'),
          viewport: viewport,
        })

        await renderTask.promise
        const dataUrl = canvas.toDataURL('image/png')
        resolve(dataUrl)
      } catch (error) {
        console.error('Extract from pdf failed', error)
        logError(error as APIError)
        reject(new ImageProcessingError(error))
      }
    }
    fileReader.onabort = (e): void => {
      console.warn('File read aborted', e)
      reject(e)
    }
    fileReader.onerror = (error: IntentionalAny): void => {
      console.error('File read error', error)
      logError(error)
      reject(error)
    }
    fileReader.readAsArrayBuffer(file)
  })
}

export const resizeImageToDataUrlPng = (
  file: File | Blob | string,
  width: number,
): Promise<string> => {
  trackEvent('image', 'PNG/JPEG Image Uploaded')
  return new Promise((resolve, reject) => {
    try {
      loadImage(file, (canvas: IntentionalAny) => resolve(canvas.toDataURL('image/png')), {
        maxWidth: width,
        canvas: true,
        orientation: true,
        downsamplingRatio: 0.4,
      })
    } catch (error) {
      reject(new ImageProcessingError(error))
    }
  })
}

export const resizeToDataUrlPng = (file: File | Blob, width: number): Promise<string> => {
  return resizeStrategy(file)(file, width)
}

const resizeStrategy = (file: File | Blob): IntentionalAny => {
  switch (file.type) {
    case 'application/pdf':
    case 'application/postscript':
      return resizePdfToDataUrlPng
    case 'image/png':
    case 'image/jpeg':
      return resizeImageToDataUrlPng
    default:
      return (): IntentionalAny =>
        Promise.reject(new ImageProcessingError('Unsupported image type'))
  }
}

const imageSize = 500

export const extractImage = (file: File): Promise<string> => {
  return resizeToDataUrlPng(file, imageSize)
}
