import { FETCH_REFERENCE_DATA_SUCCESS } from '../../actions/referenceData/referenceData'

import { ColumnValue } from 'src/types/index'
import { SVPAction } from 'actions/svp'

const defaultState: ColumnValue[] = []

export const columns = (state: ColumnValue[] = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return action.columns
    default:
      return state
  }
}
