import { SVPAction } from 'actions/svp'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  UPDATE_COUNTRY,
  updateCountryError,
  updateCountrySuccess,
} from '../actions/referenceData/countries'
import { trackEvent } from '../service/analytics/analytics'
import { injectorService } from '../service/injector/injectorService'
import { errorAndNotification } from './error'

export function* updateCountrySaga(action: SVPAction<typeof UPDATE_COUNTRY>) {
  trackEvent('admin', 'Country Updated')

  try {
    yield call(injectorService.patch, `countries/${action.code}`, action.payload)
    yield put(updateCountrySuccess(action.code, action.payload))
  } catch (error) {
    yield put(updateCountryError())
    yield* errorAndNotification(
      error as Error,
      'Country cannot be deprecated. Please try again later.',
    )
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_COUNTRY, updateCountrySaga)])
}
