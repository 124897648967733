import { all, AllEffect, call, put, takeEvery } from 'redux-saga/effects'

import { injectorService } from 'service/injector/injectorService'
import { SVPAction } from 'actions/svp'
import { showErrorNotification } from 'actions/notification'
import { logError } from 'src/service/errors'
import { ApiRangeWithSize, RangeWithSize, SizesDeprecationMap } from 'types/Hierarchy'
import {
  getDepartmentRangesWithSizesFetchDone,
  getDepartmentRangesWithSizesFetching,
  getDepartmentRangesWithSizesSuccess,
  GET_DEPARTMENT_RANGES_WITH_SIZES,
  updateDepartmentRangeSizeSuccess,
  UPDATE_DEPARTMENT_RANGE_SIZE,
} from 'actions/userSettings/sizes'
import { IntentionalAny } from 'types'

type GetDepartmentRangesWithSizesMappedResponse = {
  rangesWithSizes: RangeWithSize[]
  sizesDeprecationMap: SizesDeprecationMap
}

function createMappedRangesAndSizes(
  ranges: ApiRangeWithSize[],
): GetDepartmentRangesWithSizesMappedResponse {
  const rangesWithSizes: RangeWithSize[] = []
  const sizesDeprecationMap: SizesDeprecationMap = {}

  ranges.forEach((range) => {
    rangesWithSizes.push({
      ...range,
      sizes: range.sizes.map((size) => size.idSizeDenominations),
    })
    range.sizes.forEach((size) => {
      sizesDeprecationMap[size.idSizeDenominations] = size
    })
  })

  return { rangesWithSizes, sizesDeprecationMap }
}

export function* getDepartmentRangesWithSizesSaga(
  action: SVPAction<typeof GET_DEPARTMENT_RANGES_WITH_SIZES>,
): IntentionalAny {
  try {
    yield put(getDepartmentRangesWithSizesFetching())
    const response: { ranges: ApiRangeWithSize[] } = yield call(
      injectorService.get,
      `department-sizes/${action.slug}`,
    )
    const { rangesWithSizes, sizesDeprecationMap } = createMappedRangesAndSizes(response.ranges)
    yield put(getDepartmentRangesWithSizesSuccess(rangesWithSizes, sizesDeprecationMap))
  } catch (error) {
    yield put(getDepartmentRangesWithSizesFetchDone())
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could not retrieve sizes for selected department'))
  }
}

export function* updateDepartmentRangeSizeSaga(
  action: SVPAction<typeof UPDATE_DEPARTMENT_RANGE_SIZE>,
): IntentionalAny {
  try {
    yield call(injectorService.patch, `deprecate-sizes`, {
      deprecateSizes: { [action.idSizeDenominations]: action.isDeprecated },
    })
    yield put(updateDepartmentRangeSizeSuccess(action.idSizeDenominations, action.isDeprecated))
  } catch (error) {
    yield put(getDepartmentRangesWithSizesFetchDone())
    yield call(logError, error as Error)
    yield put(showErrorNotification('Could update the selected size'))
  }
}

export default function* (): Generator<AllEffect> {
  yield all([takeEvery(GET_DEPARTMENT_RANGES_WITH_SIZES, getDepartmentRangesWithSizesSaga)])
  yield all([takeEvery(UPDATE_DEPARTMENT_RANGE_SIZE, updateDepartmentRangeSizeSaga)])
}
