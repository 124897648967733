import React from 'react'
import FileFolder from '@material-ui/icons/FolderRounded'
import ArrowDown from '@material-ui/icons/ArrowDropDownOutlined'
import NodeLink from 'src/components/Link'
import {
  arrow,
  folder,
  notInUseFolder,
  levelCode,
  listItem,
  nodeExpander,
  nonInteractiveLabel,
  rotateLeft,
  treeItem,
  treeItemLabel,
  treeItemWrapper,
  leafNode,
} from '../Hierarchy.scss'
import { TreeHierarchyItem } from 'src/types/Hierarchy'
import { NotInUseFolderIcon } from 'src/assets/icons/NotInUseFolderIcon'

type NodeLabelProps = {
  node: TreeHierarchyItem
  onSelected: (tree: TreeHierarchyItem) => void
}

export const NodeLabel = ({ node, onSelected }: NodeLabelProps): JSX.Element => (
  <NodeLink className={`${treeItemLabel} treeItemLabel`} onClick={(): void => onSelected(node)}>
    <span>{`${node.title} - `}</span>
    <span className={levelCode}>{node.levelCode}</span>
  </NodeLink>
)

type TreeItemProps = {
  node: TreeHierarchyItem
  toggleNode: (tree: TreeHierarchyItem) => void
  onNodeSelected: (tree: TreeHierarchyItem) => void
  isSelectable: (tree: TreeHierarchyItem) => boolean
  shouldBeSeen: (tree: TreeHierarchyItem) => boolean
}

const isLeafNode = (visibleChildren: TreeHierarchyItem[]): boolean => visibleChildren.length === 0

export const TreeItem = ({
  node,
  toggleNode,
  onNodeSelected,
  isSelectable,
  shouldBeSeen,
}: TreeItemProps): JSX.Element => {
  let renderedChildren = null
  const { children } = node

  const visibleChildren = children ? children.filter(shouldBeSeen) : []

  if (!isLeafNode(visibleChildren)) {
    renderedChildren = visibleChildren.map((child, index) => {
      return (
        <li
          key={`${child.title}-${index}`}
          className={listItem}
          role="treeitem"
          aria-expanded={child.expanded ? 'true' : false}
        >
          <TreeItem
            node={child}
            toggleNode={toggleNode}
            onNodeSelected={onNodeSelected}
            isSelectable={isSelectable}
            shouldBeSeen={shouldBeSeen}
          />
        </li>
      )
    })
  }

  return (
    <div className={`${treeItemWrapper} treeItemWrapper`}>
      <div className={treeItem}>
        <NodeLink
          className={`${nodeExpander} nodeExpander ${isLeafNode(visibleChildren) ? leafNode : ''}`}
          onClick={(): void => toggleNode(node)}
        >
          {!isLeafNode(visibleChildren) &&
            (node.expanded ? (
              <ArrowDown className={arrow} />
            ) : (
              <ArrowDown className={`${arrow} ${rotateLeft}`} />
            ))}
          {node.deprecated ? (
            <NotInUseFolderIcon className={notInUseFolder} />
          ) : (
            <FileFolder className={folder} />
          )}
        </NodeLink>
        {isSelectable(node) ? (
          <NodeLabel node={node} onSelected={onNodeSelected} />
        ) : (
          <span className={`${nonInteractiveLabel}`}>{node.title}</span>
        )}
      </div>
      {node.expanded && <ul role="group">{renderedChildren}</ul>}
    </div>
  )
}
