import { combineReducers } from 'redux'
import copyToModal, { CopyToModalState } from './copyToModal'
import sizeModal, { SizeModalState } from './sizeModal'
import styleModal, { StyleModalState } from './styleModal'

export type ModalState = {
  [key: string]: CopyToModalState | SizeModalState | StyleModalState

  copyToModal: CopyToModalState
  sizeModal: SizeModalState
  styleModal: StyleModalState
}

export const reducers = {
  copyToModal,
  sizeModal,
  styleModal,
}

// @ts-ignore
export const modals: ModalState = combineReducers(reducers)
