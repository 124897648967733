import {
  GET_NEXT_PRODUCT,
  GET_PREVIOUS_PRODUCT,
  RESET_NAVIGATION_VALUE,
} from 'actions/grid/sidePanelNavigation'
import { SVPAction } from 'actions/svp'

export type SidePanelNavigationState = {
  navigationValue: number
}
const defaultNavigation: SidePanelNavigationState = {
  navigationValue: 0,
}
export const sidePanelNavigation = (
  state: SidePanelNavigationState = defaultNavigation,
  action: SVPAction,
) => {
  switch (action.type) {
    case GET_PREVIOUS_PRODUCT:
      return { navigationValue: state.navigationValue - 1 }
    case GET_NEXT_PRODUCT:
      return { navigationValue: state.navigationValue + 1 }
    case RESET_NAVIGATION_VALUE:
      return { navigationValue: 0 }
    default:
      return state
  }
}
