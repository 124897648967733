import React from 'react'

interface Props {
  className: string
}

export const ImportIcon = ({ className }: Props): JSX.Element => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>Upload_into_SVP</title>
    <path
      d="M5,14v4.7a.27.27,0,0,0,.3.3H18.7a.27.27,0,0,0,.3-.3V14"
      fill="none"
      stroke="#000"
      strokeWidth="2"
    />
    <path d="M13,6.8v6.1a1,1,0,0,1-2,0V6.8L9.9,7.9A1,1,0,1,1,8.5,6.5l2.8-2.8a1,1,0,0,1,1.4,0l2.8,2.8a1,1,0,1,1-1.4,1.4Z" />
  </svg>
)
