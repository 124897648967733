import { SVPAction } from 'actions/svp'
import { ViewRefs } from 'src/types/index'
import { FETCH_REFERENCE_DATA_SUCCESS } from '../../actions/referenceData/referenceData'

export type ViewsState = ViewRefs

const defaultState = {}

export const views = (state: ViewsState = defaultState, action: SVPAction): ViewsState => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return action.views
    default:
      return state
  }
}
