import { SVPAction } from 'actions/svp'
import { generateDepartmentSet } from 'src/service/hierarchy/generateDepartmentSet'
import { FETCH_REFERENCE_DATA_SUCCESS } from '../../../actions/referenceData/referenceData'

export type DepartmentsWithChildrenState = {
  [key: string]: Array<string>
}

export const departmentsWithChildren = (
  state: DepartmentsWithChildrenState = {},
  action: SVPAction,
) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return generateDepartmentSet(action.hierarchy)
    default:
      return state
  }
}
