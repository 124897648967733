import React from 'react'
import { trashIcon } from './icons.scss'

interface Props {
  className?: string
}

export const TrashIcon = ({ className }: Props): JSX.Element => (
  <svg className={className || trashIcon} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0838 2.4559H11.9623V2.14844C11.9623 0.963745 10.9118 0 9.62052 0H6.37931C5.08801 0 4.03755 0.963745 4.03755 2.14844V2.4559H1.91599C0.859534 2.4559 0 3.24448 0 4.21371V5.49759C0 5.82123 0.285901 6.08353 0.638663 6.08353H1.15176L2.00879 19.4485C2.02859 19.7581 2.30817 20 2.64629 20H13.3537C13.6918 20 13.9714 19.7581 13.9912 19.4485L14.8481 6.08353H15.3613C15.7139 6.08353 16 5.82123 16 5.49759V4.21371C15.9998 3.24448 15.1403 2.4559 14.0838 2.4559ZM5.31487 2.14844C5.31487 1.60995 5.79237 1.17188 6.37931 1.17188H9.62052C10.2075 1.17188 10.685 1.60995 10.685 2.14844V2.4559H5.31487V2.14844ZM1.27733 4.21371C1.27733 3.89053 1.56389 3.62778 1.91599 3.62778H14.0838C14.4361 3.62778 14.7225 3.89053 14.7225 4.21371V4.91165H1.27733V4.21371ZM12.7515 18.8281H3.24853L2.43124 6.08353H13.5686L12.7515 18.8281Z" />
    <path d="M8.00097 17.6172C8.35373 17.6172 8.63963 17.3549 8.63963 17.0313V7.85156C8.63963 7.52792 8.35373 7.26562 8.00097 7.26562C7.64837 7.26562 7.3623 7.52792 7.3623 7.85156V17.0313C7.3623 17.3549 7.64837 17.6172 8.00097 17.6172Z" />
    <path d="M10.5295 17.6167C10.5386 17.6171 10.5479 17.6173 10.5571 17.6173C10.8972 17.6173 11.1801 17.371 11.1947 17.0562L11.6205 7.87649C11.6355 7.55316 11.3619 7.27987 11.0095 7.26614C10.6575 7.25271 10.3593 7.50341 10.3443 7.82675L9.91857 17.0065C9.90343 17.3298 10.177 17.6029 10.5295 17.6167Z" />
    <path d="M5.44277 17.6173C5.45191 17.6173 5.46123 17.6171 5.47054 17.6167C5.8228 17.6029 6.0964 17.3298 6.08143 17.0065L5.65565 7.82676C5.64069 7.50343 5.34298 7.25257 4.99038 7.26615C4.63795 7.27988 4.36452 7.55317 4.37949 7.8765L4.80527 17.0562C4.81974 17.3712 5.10265 17.6173 5.44277 17.6173Z" />
  </svg>
)
