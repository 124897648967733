import { connect } from 'react-redux'

import { SizeModal } from './SizeModal'
import { closeSizeModal } from 'src/actions/grid/modal/sizeModal'
import { AppState } from 'src/reducers/root'
import {
  isProductInReadyToBuy,
  getSizeModalGroups,
  getSizesForRange,
  isSizeModalLoading,
  isProductPending,
  productHasOrdsError,
  getSupplierCostCurrency,
} from 'src/selectors/modals/sizeModal'
import {
  updateSizeAttribution,
  updateSizes,
  updateSizesAndAttribution,
} from 'src/actions/grid/product/sizes'
import { dismissEventError } from 'src/actions/grid/product/eventErrors'
import { SELLING_PRICE } from 'src/constants/price'
import {
  getHierarchyForProduct,
  isHierarchyInBeautyAndBrandsDivision,
} from 'src/selectors/hierarchy'
import { apiDiscreteValueToSellingPriceOptions } from 'src/service/mappers/attributes'
import { showErrorNotification } from 'src/actions/notification'
import { getModalProduct } from 'selectors/modals/modals'
import { Product } from 'types/Product'
import { FlatHierarchyItem } from 'types/Hierarchy'
import { Size, SizeGroups } from 'types/Size'
import { CurrencyKeys } from 'types/Currency'
import { DiscreteValue } from 'types/Attributes'

interface StateProps {
  product: Product
  hierarchy: FlatHierarchyItem
  rangeSizes: Size[]
  supplierCostCurrency: CurrencyKeys
  sizeGroups: SizeGroups
  isReadyToBuy: boolean
  productHasOrdsError: boolean
  isPending: boolean
  isLoading: boolean
  sellingPriceOptions: DiscreteValue[]
  isHierarchyInBeautyAndBrandsDivision: boolean
}

interface DispatchProps {
  updateProductSizes: typeof updateSizes
  onDismissError: typeof dismissEventError
  updateSizesAndAttribution: typeof updateSizesAndAttribution
  updateSizeAttribution: typeof updateSizeAttribution
  onClose: typeof closeSizeModal
  showErrorNotification: typeof showErrorNotification
}

const mapDispatchToProps = {
  updateProductSizes: updateSizes,
  updateSizesAndAttribution: updateSizesAndAttribution,
  updateSizeAttribution: updateSizeAttribution,
  onClose: closeSizeModal,
  onDismissError: dismissEventError,
  showErrorNotification: showErrorNotification,
}

export interface SizeModalProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => {
  const product = getModalProduct(state, state.injectorUI.modals.sizeModal.productSlug)
  const hierarchy = getHierarchyForProduct(state, product.hierarchySlug)

  return {
    hierarchy,
    sellingPriceOptions: state.injectorUI.referenceData.attributes.discreteValues[
      SELLING_PRICE
    ].map(apiDiscreteValueToSellingPriceOptions),
    product,
    rangeSizes: getSizesForRange(state),
    supplierCostCurrency: getSupplierCostCurrency(state),
    sizeGroups: getSizeModalGroups(state),
    isReadyToBuy: isProductInReadyToBuy(state),
    productHasOrdsError: productHasOrdsError(state),
    isLoading: isSizeModalLoading(state),
    isPending: isProductPending(state),
    isHierarchyInBeautyAndBrandsDivision: isHierarchyInBeautyAndBrandsDivision(
      hierarchy.slug,
      state,
    ),
  }
}

export const SizeModalContainer = connect(mapStateToProps, mapDispatchToProps)(SizeModal)
