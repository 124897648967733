import { StyleResult } from 'types/Style'
import { AppState } from '../reducers/root'

export const getStyle = (state: AppState) => state.injectorUI.style

export const getStyleProducts = (state: AppState) => {
  const currentStyle = getStyle(state).current
  return currentStyle ? currentStyle.options.flatMap(({ products }) => products) : []
}

export const getStyleOptions = (state: AppState) =>
  getStyle(state).current ? getStyle(state).current?.options : []

export const getStyleResults = (state: AppState): StyleResult[] =>
  state.injectorUI.modals.styleModal.styleResults
