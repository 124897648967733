import React from 'react'

import { columnsForTaskLinks, linksButtons, priceFilters } from './GridControls.scss'
import { View, GridViews, GridType } from 'src/types/index'
import { ViewButtons } from './ViewButtons/ViewButtons'
import { ActionButtonsContainer } from './ActionButtons/ActionButtonsContainer'
import { Filter } from 'actions/grid/exactFilters'
import { PriceFilterButtons } from './PriceFilterButtons/PriceFilterButtons'

type Props = {
  views: GridViews
  gridName: GridType
  onViewChange: (view: View) => void
  selectedView: View
  onRefresh: () => void
  onExport: () => void
  importProducts: (file: File | null) => void
  exportButtonEnabled: boolean
  addExactFilter: (filter: Filter) => void
}

export const GridControls = ({
  onViewChange,
  onRefresh,
  onExport,
  importProducts,
  addExactFilter,
  views,
  gridName,
  selectedView,
  exportButtonEnabled,
}: Props): JSX.Element => (
  <section className={columnsForTaskLinks}>
    <div className={linksButtons}>
      <ViewButtons views={views} onViewChange={onViewChange} selectedView={selectedView} />
      <div className={priceFilters}>
        <PriceFilterButtons addExactFilter={addExactFilter} />
      </div>
    </div>
    <ActionButtonsContainer
      gridName={gridName}
      onExport={onExport}
      importProducts={importProducts}
      exportButtonEnabled={exportButtonEnabled}
      onRefresh={onRefresh}
    />
  </section>
)
