import { connect } from 'react-redux'
import { StyleIdCellRenderer } from './StyleIdCellRenderer'
import { AppState } from 'src/reducers/root'
import { isGridLocked } from 'src/selectors/grid'
import { openStyleModal } from 'actions/grid/modal/styleModal'
import { Product } from 'types/Product'

interface PassedProps {
  locked: boolean
  editMode: boolean
  product: Product
  exitEditMode: (focus?: boolean) => void
}

interface StateProps {
  locked: boolean
}

interface DispatchProps {
  openStyleModal: typeof openStyleModal
}

export interface StyleIdCellRendererProps extends PassedProps, StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  locked: isGridLocked(state),
})

const mapDispatchToProps = {
  openStyleModal,
}

export const StyleIdCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyleIdCellRenderer)
