import React from 'react'
import { disabledFill, disabledStroke, defaultStroke, defaultFill, exportIcon } from './icons.scss'

type Props = {
  className?: string
  disabled?: boolean
}

export const ExportIcon = ({ disabled, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`${className || exportIcon}`}
  >
    <title>Export to xlsx</title>
    <path
      d="M5,14v4.68a.32.32,0,0,0,.31.33H18.69a.32.32,0,0,0,.31-.33V14"
      fill="none"
      strokeWidth="2"
      className={disabled ? disabledStroke : defaultStroke}
    />
    <path
      className={disabled ? disabledFill : defaultFill}
      d="M11,10.49V4.41a1,1,0,0,1,2,0v6.08l1.12-1.13a1,1,0,0,1,1.42,1.42l-2.83,2.83a1,1,0,0,1-1.42,0L8.46,10.78A1,1,0,0,1,9.88,9.36Z"
    />
  </svg>
)
