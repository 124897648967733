import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'

import PrimaryButton from '../../PrimaryButton/PrimaryButton'

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children: React.ReactNode | string | undefined
  isLoading: boolean
  disabled?: boolean
  className?: string
  loadingClass?: string
  type?: 'button' | 'submit'
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  htmlFor?: string
  dataCy?: string
  hasFocus?: boolean
  colour?: 'green' | 'black'
}

export const LoadingButton = ({
  isLoading,
  loadingClass = '',
  className,
  disabled,
  children,
  ...rest
}: Props) => {
  const loadingBtnClass = classNames(className, {
    [loadingClass]: isLoading,
  })

  return (
    <PrimaryButton {...rest} disabled={isLoading || !!disabled} className={loadingBtnClass}>
      {isLoading ? <CircularProgress size={18} color="primary" /> : children}
    </PrimaryButton>
  )
}
