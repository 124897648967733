import React from 'react'

import { ExportIcon, ImportIcon, RefreshIcon } from 'src/assets/icons'
import Link from '../../../Link'

import {
  disableClick,
  exportIcon,
  icon,
  importButton,
  importIcon,
  inputFile,
  link,
  linksListItem,
  refreshIcon,
} from '../../GridControls.scss'

interface Props {
  isOptionLibrary: boolean
  isExportButtonEnabled: boolean
  isAdmin: boolean
  importProducts: (file: File) => void
  onExport: () => void
  onRefresh: () => void
}

export default function SingleSelectActions({
  isAdmin,
  isOptionLibrary,
  importProducts,
  onExport,
  onRefresh,
  isExportButtonEnabled,
}: Props): JSX.Element {
  return (
    <>
      {isAdmin && isOptionLibrary && (
        <li id="uploadButton" className={linksListItem}>
          <input
            id="upload-input"
            type="file"
            className={inputFile}
            onChange={(event): void => {
              if (event && event.target && event.target.files) {
                importProducts(event.target.files[0])
              }
              event.target.value = ''
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, xls"
          />
          <label htmlFor="upload-input">
            <figure className={`${link} ${icon} ${importButton}`}>
              <ImportIcon className={importIcon} />
            </figure>
          </label>
        </li>
      )}
      <li id="exportButton" className={linksListItem}>
        <Link
          className={`${link} ${icon} ${isExportButtonEnabled ? '' : disableClick}`}
          onClick={onExport}
        >
          <ExportIcon className={exportIcon} disabled={!isExportButtonEnabled} />
        </Link>
      </li>
      <li id="refreshButton" className={linksListItem}>
        <Link className={`${link} ${icon}`} onClick={onRefresh}>
          <RefreshIcon className={refreshIcon} />
        </Link>
      </li>
    </>
  )
}
