import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  newAttributeContainer,
  navigationLinksContainer,
  attributesButton,
  departmentsButton,
  columnsButton,
  otherToolsButton,
  newAttributeTrigger,
  newAttributeCallout,
  adminHeader,
  active,
} from './AdminHeader.scss'
import { Modal } from 'src/components/Modal/Modal'
import { PlusIcon } from 'src/assets/icons'
import { AttributeFormContainer } from 'src/components/Admin/AttributeForm/AttributeFormContainer'
import { AttributeRenderSpec } from 'src/types/index'

type State = {
  shouldOpenModal: boolean
}

export class AdminHeader extends React.Component<unknown, State> {
  state = {
    shouldOpenModal: false,
  }

  openModal = (): void => this.setState({ shouldOpenModal: true })
  closeModal = (): void => this.setState({ shouldOpenModal: false })

  render(): JSX.Element {
    const MIN_COL_WIDTH = 105
    const EMPTY_ATTRIBUTE: AttributeRenderSpec = {
      displayName: '',
      columnWidth: MIN_COL_WIDTH,
      // @ts-ignore
      type: '',
      sortable: true,
      companyWide: false,
      duplicable: true,
      values: [],
    }
    return (
      <div className={adminHeader}>
        <div className={navigationLinksContainer}>
          <NavLink
            data-cy="attributes-link"
            activeClassName={active}
            to="/admin/attributes"
            className={attributesButton}
          >
            Attributes
          </NavLink>
          <NavLink
            data-cy="departments-link"
            activeClassName={active}
            to="/admin/departments"
            className={departmentsButton}
          >
            Departments
          </NavLink>
          <NavLink
            data-cy="columns-link"
            activeClassName={active}
            to="/admin/columns"
            className={columnsButton}
          >
            Columns
          </NavLink>
          <NavLink
            data-cy="settings-link"
            activeClassName={active}
            to="/admin/tools"
            className={otherToolsButton}
          >
            Settings
          </NavLink>
        </div>
        <div className={newAttributeContainer}>
          <div className={newAttributeCallout}>Create new attribute</div>
          <button
            data-cy="new-attribute-button"
            className={newAttributeTrigger}
            onClick={this.openModal}
          >
            <PlusIcon title="Create new attribute" />
          </button>
        </div>
        {this.state.shouldOpenModal && (
          <Modal onCloseFn={this.closeModal}>
            <div data-cy="admin-header-modal">
              <AttributeFormContainer attribute={EMPTY_ATTRIBUTE} onCancel={this.closeModal} />
            </div>
          </Modal>
        )}
      </div>
    )
  }
}
