import { AppState } from '../reducers/root'
import { hasAdminScope } from 'src/selectors/auth0/admin'

export const userName = (state: AppState) =>
  state.injectorUI.userProfile && state.injectorUI.userProfile.nickname

export const getEmail = (state: AppState) =>
  state.injectorUI.userProfile && state.injectorUI.userProfile.email

export const isAdmin = (state: AppState) => {
  return !!(state.authentication && hasAdminScope(state))
}
