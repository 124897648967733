import React from 'react'

interface Props {
  className: string
}

export const CommentIndicator = ({ className }: Props): JSX.Element => (
  <svg
    viewBox="0 0 27 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>talkbubble</title>
    <g id="Main-Stage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="COMMENTS-INDICATOR-1" transform="translate(-49.000000, -466.000000)">
        <g id="Comment-Indicator-2" transform="translate(48.000000, 465.000000)">
          <g id="Talk-bubble" transform="translate(3.000000, 3.000000)">
            <path
              d="M19.7501866,12.5436334 C19.7330031,12.5541915 19.724448,12.559222 19.6949716,12.5765544 C18.7291724,13.1378155 15.7307487,14.9105737 10.7062744,17.8909739 L6.75451642,20.2350639 L11.4701003,13.4420155 L6.73636387,13.4420155 C2.75684498,13.4420155 -0.4691925,10.215978 -0.4691925,6.23645908 C-0.4691925,2.25694018 2.75684498,-0.969097296 6.73636387,-0.969097296 L16.2636361,-0.969097296 C20.243155,-0.969097296 23.4691925,2.25694018 23.4691925,6.23645908 C23.4691925,8.88662574 22.0253694,11.2837182 19.7501866,12.5436334 Z"
              id="Combined-Shape"
              stroke="#26B3AD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g id="Dots" transform="translate(5.000000, 5.000000)" fill="#26B3AD">
              <circle id="Oval-2" cx="1.5" cy="1.5" r="1.5" />
              <circle id="Oval-2-Copy" cx="6.5" cy="1.5" r="1.5" />
              <circle id="Oval-2-Copy-2" cx="11.5" cy="1.5" r="1.5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const CommentIndicatorThin = ({ className }: Props): JSX.Element => (
  <svg
    viewBox="0 0 25 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title>talkbubble</title>
    <g
      id="Main-Stage"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="COMMENTS-INDICATOR-1" transform="translate(-50.000000, -467.000000)" stroke="#26B3AD">
        <g id="Comment-Indicator-2" transform="translate(48.000000, 465.000000)">
          <g id="Talk-bubble" transform="translate(3.000000, 3.000000)">
            <path
              d="M19.498125,12.1116604 C19.4775364,12.1243807 19.472888,12.1271142 19.443745,12.1442522 C18.4760415,12.70662 15.4774266,14.4794913 10.4511874,17.4609383 L8.47530839,18.6329833 L12.4258523,12.9420155 L6.73636387,12.9420155 C3.03298735,12.9420155 0.0308075004,9.9398356 0.0308075004,6.23645908 C0.0308075004,2.53308255 3.03298735,-0.469097296 6.73636387,-0.469097296 L16.2636361,-0.469097296 C19.9670127,-0.469097296 22.9691925,2.53308255 22.9691925,6.23645908 C22.9691925,8.70674632 21.6214997,10.9404203 19.498125,12.1116604 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
