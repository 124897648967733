import React from 'react'
import { TextField } from '../../../TextField/TextField'
import { PartialFilter } from '../../../../actions/grid/partialFilters'
import { ENTER_KEY } from '../../../../constants/keyCodes'
import { GridType, RenderSpec } from 'src/types/index'
import { partialFilterInput } from './PartialFilterSearchInput.scss'

type Props = {
  column: RenderSpec
  addPartialFilter: (filter: PartialFilter) => void
  gridName: GridType
}

type State = {
  value: string
}

export class PartialFilterSearchInput extends React.Component<Props, State> {
  state = {
    value: '',
  }

  render() {
    return (
      <div className={partialFilterInput}>
        <TextField
          name="partiallyFilterableValues"
          placeholder="Type value"
          size="medium"
          onChange={this.onChange}
          value={this.state.value}
          onKeyDown={this.onKeyDown}
          dataCy="search-bar-partial-filter"
        />
      </div>
    )
  }

  stripInvalidCharacters = (newValue: string) => {
    return newValue.replace(/[^@A-Za-z0-9\-.,_; ]/g, '').trimLeft()
  }

  onChange = (newValue: string) => {
    this.setState({ value: this.stripInvalidCharacters(newValue) })
  }

  onKeyDown = (event: { key: string }) => {
    if (event.key === ENTER_KEY && this.state.value) {
      this.props.addPartialFilter({
        value: this.state.value.trim(),
        property: this.props.column.property,
        displayName: this.props.column.displayName,
      })

      this.setState({
        value: '',
      })
    }
  }
}
