import React from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { RegisterErrorLogger, RegisterAnalytics, GetAuth } from '@ri-digital/auth0-authenticator'

import App from './App'
import * as injectorMiddleware from './service/injector/middleware/root'
import { apiLockMiddleware } from './service/injector/middleware/lockMiddleware'
import * as Analytics from './service/analytics/analytics'
import * as Sentry from './helpers/sentry'
import { Config } from './helpers/config'
import './index.scss'
import { IntentionalAny } from 'types'
import { pdfjs } from 'react-pdf'

declare global {
  interface Window {
    __injectorUIConfig: IntentionalAny
  }
}

Analytics.init(window.__injectorUIConfig)
RegisterAnalytics(Analytics)
Sentry.init(window.__injectorUIConfig)
RegisterErrorLogger(Sentry)
Sentry.setFeatureFlagTags(window.__injectorUIConfig)

GetAuth(window.__injectorUIConfig)
Config(window.__injectorUIConfig)

injectorMiddleware.registerMiddleware([apiLockMiddleware])

ReactDOM.render(<App />, document.getElementById('root'))
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
