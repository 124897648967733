import React from 'react'
import Dropdown from '../../../Dropdown'
import { Filter } from '../../../../actions/grid/exactFilters'
import { IntentionalAny, RenderSpec } from 'src/types/index'
import { DropdownOption } from 'src/types/Dropdowns'
import { uiDropdownItemsMapper } from 'src/service/mappers/uiDropdownItemsMapper'

type Props = {
  column: RenderSpec
  uniqueValues: {
    [key: string]: DropdownOption[]
  }
  addExactFilter: (filter: Filter) => void
  existingExactFilters: Filter[]
}

const createOption = (item: IntentionalAny): DropdownOption => ({
  value: item.value,
  label: item.label,
  deprecated: item.deprecated,
  data: item,
})

export class ExactFilterSearchInput extends React.Component<Props> {
  addFilter = (item: IntentionalAny): void => {
    if (item) {
      this.props.addExactFilter({
        ...item,
        property: this.props.column.property,
        type: this.props.column.displayName,
      })
    }
  }

  uniqueValuesForColumn = (): DropdownOption[] => {
    const uniqueValues = this.props.uniqueValues[this.props.column.property] || []

    const existingFilterValues = this.props.existingExactFilters.map((f) => f.value)

    const filteredItems = uniqueValues.filter(
      (item) => !existingFilterValues.includes(item.value as string) && item.value !== '',
    )

    return filteredItems
  }

  render(): JSX.Element {
    const { column } = this.props
    const itemsDropdownMapper = uiDropdownItemsMapper(column.property)

    const customControlStyles = {
      border: 'none',
      borderRadius: '1.2rem',
      backgroundColor: 'rgba(0,0,0, 0.3)',
      width: '13rem',
      margin: 0,
      color: 'white',
    }

    const customIndicatorsContainerStyles = {
      position: 'relative',
      left: '11rem',
      top: '-2.2rem',
    }

    const customDropdownIndicatorStyles = {
      color: '#E4E726',
      backgroundColor: 'rgba(0,0,0, 0.5)',
      borderRadius: '50%',
      padding: '2px',
    }

    const customInputStyles = {
      color: 'white',
    }

    const customSingleValueStyles = {
      color: 'white',
    }

    const customMenuStyles = {
      width: '13rem',
      marginTop: '0.2rem',
      borderRadius: '1rem',
      display: 'flex',
      justifyContent: 'center',
    }

    const customMenuListStyles = {
      borderRadius: '0.5rem',
      width: '12rem',
    }

    const customOptionStyles = {
      fontSize: '13px',
      borderBottom: '1px solid #EEE',
    }

    const customPlaceholderStyles = {
      color: 'white',
    }

    return (
      <Dropdown
        {...(itemsDropdownMapper ? itemsDropdownMapper.getProps() : {})}
        name="exactlyFilterableValues"
        items={this.uniqueValuesForColumn()}
        createOption={createOption}
        isValid
        selectedItem={undefined}
        onItemSelected={this.addFilter}
        placeholder="Select value"
        // @ts-ignore
        customControlStyles={customControlStyles}
        dropdownIndicator
        customIndicatorsContainerStyles={customIndicatorsContainerStyles}
        customDropdownIndicatorStyles={customDropdownIndicatorStyles}
        customInputStyles={customInputStyles}
        customSingleValueStyles={customSingleValueStyles}
        customMenuStyles={customMenuStyles}
        customMenuListStyles={customMenuListStyles}
        customOptionStyles={customOptionStyles}
        customPlaceholderStyles={customPlaceholderStyles}
        dataCy="search-bar-exact-filter"
      />
    )
  }
}
