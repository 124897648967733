import { CommsDocumentFileMeta } from 'types/CommsDocument'

export const UPLOAD_COMMS_PDF = 'UPLOAD_COMMS_PDF'
export const GET_COMMS_PDF = 'GET_COMMS_PDF'
export const GET_COMMS_PDF_SUCCESS = 'GET_COMMS_PDF_SUCCESS'
export const GET_COMMS_PDF_META = 'GET_COMMS_PDF_META'
export const GET_COMMS_PDF_META_SUCCESS = 'GET_COMMS_PDF_META_SUCCESS'

export type CommsScreenActions = ReturnType<
  | typeof uploadCommsPdf
  | typeof getCommsPdf
  | typeof getCommsPdfSuccess
  | typeof getCommsPdfMeta
  | typeof getCommsPdfMetaSuccess
>

export const uploadCommsPdf = (file: File) =>
  <const>{
    file,
    type: UPLOAD_COMMS_PDF,
  }

export const getCommsPdf = () =>
  <const>{
    type: GET_COMMS_PDF,
  }

export const getCommsPdfSuccess = (file: File, meta: CommsDocumentFileMeta) =>
  <const>{
    file,
    meta,
    type: GET_COMMS_PDF_SUCCESS,
  }

export const getCommsPdfMeta = () =>
  <const>{
    type: GET_COMMS_PDF_META,
  }

export const getCommsPdfMetaSuccess = (meta: CommsDocumentFileMeta) =>
  <const>{
    meta,
    type: GET_COMMS_PDF_META_SUCCESS,
  }
