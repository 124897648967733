import { combineReducers } from 'redux'
import { ProductLinksState, productLinks } from './links'
import { sidePanelNavigation, SidePanelNavigationState } from './sidePanelNavigation'

export type SidePanelState = {
  productLinks: ProductLinksState
  sidePanelNavigation: SidePanelNavigationState
}

// @ts-ignore
export const sidePanel: SidePanelState = combineReducers({
  productLinks,
  sidePanelNavigation,
})
