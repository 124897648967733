import React, { Component } from 'react'
import classnames from 'classnames'

import { MINI_GROUP, TEEN_GROUP, MINI_EXT_GROUP } from 'src/constants/productSizes'

import { section, lastSection, teenGroupBox, miniGroupBox, miniExtGroupBox } from './Section.scss'
import { IntentionalAny } from 'types'

type Props = {
  children: IntentionalAny
  sizeGroup?: string
  isLastSection?: boolean
  testId?: string
}

export default class Section extends Component<Props> {
  render(): JSX.Element {
    const { children, isLastSection, sizeGroup, testId } = this.props

    const style = classnames(section, {
      [lastSection]: isLastSection,
      [teenGroupBox]: sizeGroup === TEEN_GROUP,
      [miniExtGroupBox]: sizeGroup === MINI_EXT_GROUP,
      [miniGroupBox]: sizeGroup === MINI_GROUP,
    })

    const cyID = testId ? testId : `section_${sizeGroup}`

    return (
      <div className={style} data-cy={cyID}>
        {children}
      </div>
    )
  }
}
