import React from 'react'
import {
  developmentIdCell,
  commentCountSection,
  commentIndicator,
  iconWrapper,
  linkIcon,
  priceTagIcon,
} from './DevelopmentIdCell.scss'
import { CommentIndicatorThin } from './CommentIndicator'
import { LinkIndicator } from 'src/assets/icons'
import { CROSS_DEPARTMENT_LINK, MATCHING_PRODUCT_LINK, SUPPLIER_LINK } from 'src/constants/links'
import { PriceTagIcon } from 'assets/icons/PriceTag'

interface Props {
  developmentId: string
  commentCount: number
  hasCrossDepartmentLink: boolean
  hasMatchingProductLink: boolean
  hasSupplierLink: boolean
  isMarkdown: boolean
}

export const DevelopmentIdCell = ({
  developmentId,
  commentCount,
  hasCrossDepartmentLink,
  hasMatchingProductLink,
  hasSupplierLink,
  isMarkdown,
}: Props): JSX.Element => {
  return (
    <div className={developmentIdCell}>
      <div>
        <span>{developmentId}</span>
      </div>
      <div className={iconWrapper}>
        {hasSupplierLink && (
          <span>
            <LinkIndicator title="Supplier Variant" className={linkIcon} linkType={SUPPLIER_LINK} />
          </span>
        )}
        {hasMatchingProductLink && (
          <span>
            <LinkIndicator
              title="Matching Products Link"
              className={linkIcon}
              linkType={MATCHING_PRODUCT_LINK}
            />
          </span>
        )}
        {hasCrossDepartmentLink && (
          <span>
            <LinkIndicator
              title="Cross Department Link"
              className={linkIcon}
              linkType={CROSS_DEPARTMENT_LINK}
            />
          </span>
        )}
      </div>
      <div className={iconWrapper}>
        {commentCount > 0 && (
          <span className={commentCountSection}>
            <CommentIndicatorThin className={commentIndicator} />
            <span>{commentCount}</span>
          </span>
        )}
        {isMarkdown && (
          <span>
            <PriceTagIcon className={priceTagIcon} iconColor="red" />
          </span>
        )}
      </div>
    </div>
  )
}
