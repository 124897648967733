import { connect } from 'react-redux'
import { getDepartmentsWithChildren } from 'src/selectors/hierarchy'
import { ActionsCell } from './ActionsCell'
import { AppState } from 'src/reducers/root'
import { GridType, Product } from 'src/types/index'
import { copyProductToHierarchy, duplicateProduct } from 'src/actions/grid/product/copy'
import { openSizeModal } from 'src/actions/grid/modal/sizeModal'
import { getEmail } from 'src/selectors/userProfile'
import { isProductCurrentlyPolling } from 'src/selectors/polling'
import {
  updateProductHierarchy,
  UpdateProductHierarchyPayload,
} from 'src/actions/grid/product/update'
import {
  CopyProductToHierarchyPayload,
  DuplicateProductPayload,
} from 'src/actions/grid/product/copy'
import { dismissEventError } from 'src/actions/grid/product/eventErrors'
import { isProductLocked } from 'selectors/product'
import { openStyleModal } from 'actions/grid/modal/styleModal'

type OwnProps = {
  gridName: GridType
  product: Product
}

const mapStateToProps =
  (state: AppState, { product }: OwnProps) =>
  () => {
    return {
      email: getEmail(state) as string,
      departmentsWithChildren: getDepartmentsWithChildren(state),
      isProductLocked:
        isProductLocked(state, product.slug) || isProductCurrentlyPolling(product.slug, state),
    }
  }

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  updateProductHierarchy: (payload: UpdateProductHierarchyPayload) =>
    dispatch(updateProductHierarchy(payload, gridName)),
  copyProductToHierarchy: (payload: CopyProductToHierarchyPayload) =>
    dispatch(copyProductToHierarchy(payload, gridName)),
  duplicateProduct: (payload: DuplicateProductPayload) =>
    dispatch(duplicateProduct(payload, gridName)),
  openSizeModal: (hierarchySlug: string, productSlug: string) =>
    dispatch(openSizeModal(hierarchySlug, productSlug)),
  onDismissError: (productSlug: string, errorID: string) =>
    dispatch(dismissEventError(productSlug, errorID)),
  openStyleModal: (hierarchySlug: string, product: Product) =>
    dispatch(openStyleModal(hierarchySlug, product)),
})

export const ActionsCellContainer = connect(mapStateToProps, mapDispatchToProps)(ActionsCell)
