import React from 'react'
import { ActionsCellContainer } from './ActionsCell/ActionsCellContainer'
import { GridType, Product } from 'src/types/index'
import { MultiSelectCellContainer } from './MultiSelectCell/MultiSelectCellContainer'
import { cell } from './ActionsCellRenderer.scss'

type Props = {
  product: Product
  rowIndex: number
  editMode: boolean
  isActive: boolean
  locked?: boolean
  exitEditMode: (exit: boolean) => void
  enterEditMode: () => void
  gridName: GridType
  multiSelect: boolean
  hasProductError: boolean
}

export const ActionsCellRenderer = ({
  product,
  rowIndex,
  editMode,
  isActive,
  exitEditMode,
  enterEditMode,
  gridName,
  multiSelect,
  locked,
  hasProductError,
}: Props) => (
  <div className={cell}>
    {multiSelect ? (
      <MultiSelectCellContainer
        product={product}
        gridName={gridName}
        isLocked={locked}
        hasProductError={hasProductError}
      />
    ) : (
      <ActionsCellContainer
        product={product}
        rowIndex={rowIndex}
        editMode={editMode}
        isActive={isActive}
        exitEditMode={exitEditMode}
        enterEditMode={enterEditMode}
        gridName={gridName}
        isLocked={locked}
        hasProductError={hasProductError}
      />
    )}
  </div>
)
