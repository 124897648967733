import React from 'react'
import ProductForm from 'pages/createProduct/ProductForm'
import {
  newProductPage,
  floatingTitle,
  pageGrid,
  panel,
  panelAndSketch,
} from './CreateProductPage.scss'
import { DEV_TRACKER } from 'src/types/index'
import { CreateProductPageProps } from './CreateProductPageContainer'
import { EnrichedViewProduct } from 'types/Product'

export const CreateProductPage = (props: CreateProductPageProps): JSX.Element => {
  const {
    attributes,
    seasons,
    suppliers,
    attributeNameMap,
    createProduct,
    clearAndSelectHierarchy,
  } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = (product: EnrichedViewProduct): void => {
    createProduct(product)
    clearAndSelectHierarchy(product.hierarchy.slug, DEV_TRACKER)
  }

  return (
    <article className={newProductPage}>
      <div className={pageGrid}>
        <h1 className={floatingTitle}>Create product</h1>
        <div className={panelAndSketch}>
          <div className={panel}>
            <ProductForm
              attributes={attributes}
              seasons={seasons}
              suppliers={suppliers}
              onSubmit={onSubmit}
              attributeNameMap={attributeNameMap}
            />
          </div>
        </div>
      </div>
    </article>
  )
}
