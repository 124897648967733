import React from 'react'
type Props = {
  width: string
  height: string
  className: string
}
export const UpRoundArrow = ({ className, width, height }: Props) => (
  <svg
    className={className}
    width={width}
    height={height}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <line
      color="green"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      strokeMiterlimit="10"
      x1="16"
      y1="11"
      x2="16"
      y2="23"
    />
    <polyline
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      strokeMiterlimit="10"
      points="10.3,16 16,10.3 21.7,16 "
    />
    <circle
      color="green"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      strokeMiterlimit="10"
      cx="16"
      cy="16"
      r="12"
    />
  </svg>
)
