import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { loadingPanel } from '../SizeModal.scss'

export default function SizesLoading(): JSX.Element {
  return (
    <div className={loadingPanel} data-cy="sizeModalLoading">
      <CircularProgress size={30} color="primary" />
    </div>
  )
}
