import React from 'react'

import { ProductEventError } from 'src/types/ProductEventError'
import { getEventErrorMessage, isErrorDismissable } from 'src/service/product/eventErrors'

import { errorList, errorListItem, errorText, closeButton } from './ErrorList.scss'
import { CloseCross } from 'src/assets/icons'

interface Props {
  productErrors?: ProductEventError[]
  onDismissError?: (errorID: string) => void
}

export default function ErrorList({ productErrors, onDismissError }: Props) {
  if (!productErrors) {
    return <></>
  }

  const onClickDismiss = (errorID: string) => {
    onDismissError && onDismissError(errorID)
  }

  return (
    <div className={errorList}>
      {productErrors.map((error: ProductEventError) => (
        <div key={error.id} className={errorListItem}>
          <div className={errorText}>{getEventErrorMessage(error)}</div>
          {isErrorDismissable(error) && (
            <button onClick={() => onClickDismiss(error.id)} className={closeButton}>
              <CloseCross />
            </button>
          )}
        </div>
      ))}
    </div>
  )
}
