import axios from 'axios'
import authHeader from '../auth/headers'
import { getSketchCookieRefreshUrl, getWholesaleThumbnailsCookieRefreshUrl } from '../injector/url'

export const setCookie = async () => {
  const url = getSketchCookieRefreshUrl() as string
  const wholesaleThumbnailsUrl = getWholesaleThumbnailsCookieRefreshUrl() as string
  const header = await authHeader()

  if (!header) {
    return
  }
  await axios.request({
    url,
    method: 'GET',
    headers: header,
    withCredentials: true,
  })

  await axios.request({
    url: wholesaleThumbnailsUrl,
    method: 'GET',
    headers: header,
    withCredentials: true,
  })
}

export default setCookie
