import React from 'react'
import Link from '../../Link'
import { link, dot } from './QuickLinks.scss'
import { SampleImageIcon, SketchImageIcon } from 'src/assets/icons'

type Props = {
  currentIndex: number
  onSelection: (index: number) => void
}

export const QuickLinks = ({ onSelection, currentIndex }: Props): JSX.Element => (
  <div>
    {
      <>
        <Link key={0} className={link} onClick={(): void => onSelection(0)}>
          <SketchImageIcon className={dot} colour={currentIndex === 0 ? '#000' : '#c7c7c7'} />
        </Link>
        <Link key={1} className={link} onClick={(): void => onSelection(1)}>
          <SampleImageIcon className={dot} colour={currentIndex === 1 ? '#000' : '#c7c7c7'} />
        </Link>
      </>
    }
  </div>
)
