import React from 'react'
import ReactSelect, { components } from 'react-select'
import { allOption } from 'service/product/uiValues'
import {
  DropdownCustomStyles,
  DropdownOption,
  DropdownStyle,
  ExtendedDropdownStyle,
} from 'types/Dropdowns'
import { getCustomStyles } from '../DropdownStyles'
import {
  checkboxInput,
  checkboxOptionContainer,
  dropdownSelectTitle,
} from './CheckboxDropdown.scss'

type Props = DropdownCustomStyles & {
  value: DropdownOption[]
  options: DropdownOption[]
  includeAllButton: boolean
  placeholder: string
  onChange: (selected: DropdownOption[], isAllSelected: boolean) => void
}

const applyCustomEffects = (state: any, styleObject?: ExtendedDropdownStyle): DropdownStyle =>
  styleObject?.customEffects ? styleObject.customEffects(state) : {}

const isAllActive = (selectedOptions: DropdownOption[]): boolean =>
  selectedOptions &&
  selectedOptions.some((selectedOption) => selectedOption.value === allOption.value)

const Option = (props: any) => {
  const { isSelected, label, value } = props

  return (
    <div>
      <components.Option {...props}>
        <div className={checkboxOptionContainer}>
          <input
            className={checkboxInput}
            type="checkbox"
            name={value}
            checked={isSelected}
            onChange={() => null}
          />{' '}
          <span>{label}</span>
        </div>
      </components.Option>
    </div>
  )
}

const MultiValue = () => {
  return null
}

const Control = ({ children, ...props }: any) => {
  const { selectProps } = props

  let selectTitle = `${selectProps?.value?.length} Shown`
  if (isAllActive(selectProps.value)) {
    selectTitle = 'All Shown'
  }

  return (
    <components.Control {...props}>
      {!!selectProps?.value?.length && <div className={dropdownSelectTitle}>{selectTitle}</div>}
      {children}
    </components.Control>
  )
}

export const CheckboxDropdown = (props: Props) => {
  const { includeAllButton, options, onChange, value, placeholder } = props

  const customStyles = getCustomStyles(props, applyCustomEffects)
  const allOptions = includeAllButton && options.length ? [allOption, ...options] : options

  return (
    <ReactSelect
      value={value}
      options={allOptions}
      isMulti
      components={{
        Option,
        MultiValue,
        Control,
        IndicatorSeparator: null,
      }}
      placeholder={placeholder}
      isClearable={false}
      isSearchable={false}
      styles={customStyles}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      // @ts-ignore due to reselect types
      onChange={(selected: DropdownOption[], meta: any) => {
        if (meta?.action === 'deselect-option' && meta?.option?.value === allOption.value) {
          return onChange([], false)
        }
        if (meta?.action === 'select-option' && meta?.option?.value === allOption.value) {
          return onChange(options, true)
        }
        const selectedWithoutAll = selected.filter(
          (selectedOption) => selectedOption.value !== allOption.value,
        )
        if (selectedWithoutAll.length === options.length) {
          return onChange(selected, true)
        }
        return onChange(selected, false)
      }}
    />
  )
}
