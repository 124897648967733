import React from 'react'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { tagContainer, container } from './ListInput.scss'
import { TextFormField } from 'src/components/TextFormField'
import { Tag } from 'src/components/Tag'
import { AttributeValue } from 'src/types/index'
import { EditAttributeTag } from 'src/components/EditAttributeTag/EditAttributeTag'
import { MAX_VALUE_DIGITS } from 'src/constants/adminRenderSpecs'

interface Props {
  values: string[]
  existingValues: AttributeValue[]
  validationMessage?: string
  onAdd: (val: string) => void
  onBlur: () => void
  onRemove: (val: string) => void
  onRename: (val: AttributeValue) => void
  onToggleDeprecated: (val: AttributeValue) => void
}

interface State {
  inputValue: string
}

const lowerCaseList = (list: string[]) => list.map((v) => v.toLowerCase())
const isValidLength = (value: string) => value.length <= MAX_VALUE_DIGITS

export class ListInput extends React.Component<Props, State> {
  state = {
    inputValue: '',
  }

  updateInputValue = (newValue: string) => {
    this.setState({ inputValue: newValue })
  }

  addValue(value: string) {
    const { values, existingValues } = this.props
    const lowerCaseValues = lowerCaseList(values)
    const lowerCaseExistingValues = lowerCaseList(existingValues.map(({ value }) => value))

    if (
      value !== '' &&
      !lowerCaseValues.includes(value.toLowerCase()) &&
      !lowerCaseExistingValues.includes(value.toLowerCase())
    ) {
      this.props.onAdd(value)
    }
  }

  resetTextField(value: string) {
    if (value !== '' && this.props.validationMessage === '') {
      this.setState({
        inputValue: '',
      })
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault()
      const formattedValue = this.state.inputValue.trim()

      this.addValue(formattedValue)
      this.resetTextField(formattedValue)
    }
  }

  isUnique(value: string) {
    const { values, existingValues } = this.props
    const lowerCasedValue = value.toLowerCase()
    const lowerCaseNewValues = lowerCaseList(values)
    const lowerCaseExistingValues = lowerCaseList(existingValues.map(({ value }) => value))

    return (
      !lowerCaseNewValues.includes(lowerCasedValue) &&
      !lowerCaseExistingValues.includes(lowerCasedValue)
    )
  }

  onTagChange = (attributeValue: AttributeValue) => {
    const valueForValidation = attributeValue.value.trim()
    if (
      valueForValidation !== '' &&
      this.isUnique(valueForValidation) &&
      isValidLength(valueForValidation)
    ) {
      this.props.onRename({
        ...attributeValue,
        value: attributeValue.value.trim(),
      })
    }
  }

  render() {
    const { values = [], existingValues = [], onRemove, validationMessage, onBlur } = this.props
    return (
      <div data-cy="list-input-container" className={container}>
        <TextFormField
          header="Values"
          name="listValues"
          value={this.state.inputValue}
          placeholder="Add dropdown items..."
          onChange={this.updateInputValue}
          onBlur={onBlur}
          onKeyDown={this.onKeyDown}
          required
          validationErrors={validationMessage ? [validationMessage] : []}
          hasFocus={false}
        />
        {!!(values.length || existingValues.length) && (
          <div data-cy="tag-container" className={tagContainer}>
            {values.map((value, index) => (
              <Tag key={index} value={value} tagType="list" onClose={() => onRemove(value)} />
            ))}
            {existingValues.map((existingValue, index) => (
              <EditAttributeTag
                key={index}
                attributeValue={existingValue}
                onChange={this.onTagChange}
                onToggleDeprecated={this.props.onToggleDeprecated}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}
