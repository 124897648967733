import React from 'react'
import { ToolsContainer } from 'src/components/Admin/Tools/ToolsContainer'

type Props = {
  selectedToolKey?: string
}

export const ToolsPage = ({ selectedToolKey }: Props) => {
  return <ToolsContainer selectedToolKey={selectedToolKey} />
}
