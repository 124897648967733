import React from 'react'

interface Props {
  title?: string
  width?: number
  height?: number
  stroke?: string
  strokeWidth?: string
}

export const PlusIcon = ({
  title,
  width = 20,
  height = 21,
  stroke = '#383939',
  strokeWidth = '2',
}: Props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 21">
    <title>{title}</title>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="square"
      strokeWidth={strokeWidth}
      transform="translate(1 1)"
    >
      <path d="M9 0L9 18.6333333M18 9L0 9" />
    </g>
  </svg>
)
