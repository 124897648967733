import React from 'react'
import Dropdown from 'src/components/Dropdown/Dropdown'
import { container, button } from './DropdownAndAdd.scss'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import { DropdownOption } from '../../types/Dropdowns'

type Props = {
  name: string
  header?: string
  items: DropdownOption[]
  createOption: (option: DropdownOption) => DropdownOption
  onAdd: (selectedAttribute?: any) => void
}

type State = {
  selectedOption?: any
}

export class DropdownAndAdd extends React.Component<Props, State> {
  state = {
    selectedOption: undefined,
  }

  onAdd = (selectedOption: any): void => {
    if (selectedOption) {
      this.props.onAdd(selectedOption)
      this.setState({ selectedOption: undefined })
    }
  }

  render(): JSX.Element {
    const customControlStyles = {
      '&:hover': {
        borderColor: '#26B3AD',
      },
    }
    return (
      <div data-cy="dropdown-and-add" className={container}>
        <Dropdown
          name={this.props.name}
          header={this.props.header}
          items={this.props.items}
          isValid
          onItemSelected={(value): void => {
            value && this.setState({ selectedOption: value })
          }}
          onInputKeyDown={(): void =>
            this.state.selectedOption && this.setState({ selectedOption: undefined })
          }
          customControlStyles={customControlStyles}
          createOption={this.props.createOption}
          selectedItem={this.state.selectedOption}
          small
        />
        <PrimaryButton
          disabled={!this.state.selectedOption}
          className={button}
          onClick={(): void => this.onAdd(this.state.selectedOption)}
        >
          Add
        </PrimaryButton>
      </div>
    )
  }
}
