import React from 'react'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { SketchImage } from '../SketchImage/SketchImage'
import {
  image,
  carouselContainer,
  imageAndArrowsContainer,
  imageContainer,
  arrow,
  arrowLink,
  camera,
} from './Carousel.scss'
import Link from '../Link'
import { QuickLinks } from './QuickLinks/QuickLinks'
import { setFocus } from '../../service/ref'
import { LEFT_KEY, RIGHT_KEY } from 'src/constants/keyCodes'
import { Camera } from '../SidePanelDrawer/icons/Camera'
import { Image } from '../../types/Images'

interface Change {
  currentImage: Image
  currentIndex: number
}

type Props = {
  images: Image[]
  onChange?: (val: Change) => void
  initialIndex?: number
  indexesThatAreLoading: number[]
}

type State = {
  currentIndex: number
  imageCount: number
  images?: Image[]
}

export class Carousel extends React.Component<Props, State> {
  ref: HTMLDivElement
  state = {
    currentIndex: this.props.initialIndex,
    imageCount: this.props.images.length,
    images: this.props.images,
  }

  componentDidMount(): void {
    setFocus(this.ref)
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (props.images.length > state.imageCount) {
      return {
        imageCount: props.images.length,
        currentIndex: props.images.length - 1,
      }
    } else {
      return null
    }
  }

  switchPosition = (): void =>
    this.setState((currentState) => ({
      currentIndex: currentState.currentIndex === 0 ? 1 : 0,
    }))

  keyDown = (key: string): void => {
    if (key === RIGHT_KEY || key === LEFT_KEY) this.switchPosition()
  }

  selectImage = (index: number): void => this.setState({ currentIndex: index })

  componentDidUpdate(_prevProps: Props, prevState: State): void {
    if (prevState.currentIndex !== this.state.currentIndex) {
      this.setState({ imageCount: this.state.images?.length || 0 })
      this.props.onChange &&
        this.props.onChange({
          currentImage: this.props.images[this.state.currentIndex],
          currentIndex: this.state.currentIndex,
        })
    }
    if (_prevProps.images !== this.props.images) {
      this.setState({ images: this.props.images })
    }
  }

  render(): JSX.Element {
    const { images } = this.state

    if (!images?.length) {
      return (
        <div className={imageContainer}>
          <Camera
            className={camera}
            parsing={this.props.indexesThatAreLoading.includes(this.state.currentIndex)}
          />
        </div>
      )
    }
    return (
      <div
        className={carouselContainer}
        tabIndex={-1}
        onKeyDown={({ key }): void => this.keyDown(key)}
        ref={(node): HTMLDivElement => (this.ref = node)}
      >
        <div className={imageAndArrowsContainer}>
          <Link onClick={this.switchPosition} className={arrowLink}>
            <ChevronLeft className={arrow} />
          </Link>
          {this.state.currentIndex === images.length ? (
            <div className={imageContainer}>
              <Camera
                className={camera}
                parsing={this.props.indexesThatAreLoading.includes(this.state.currentIndex)}
              />
            </div>
          ) : (
            <div data-cy="carousel-img-container" className={imageContainer}>
              <SketchImage
                className={image}
                src={
                  images[this.state.currentIndex]
                    ? images[this.state.currentIndex].url
                    : images[images.length - 1].url
                }
                alt={`${
                  images[this.state.currentIndex]
                    ? images[this.state.currentIndex].type
                    : images[images.length - 1].type
                } image`}
                parsing={this.props.indexesThatAreLoading.includes(this.state.currentIndex)}
              />
            </div>
          )}
          <Link onClick={this.switchPosition} className={arrowLink}>
            <ChevronRight className={arrow} />
          </Link>
        </div>
        <QuickLinks currentIndex={this.state.currentIndex} onSelection={this.selectImage} />
      </div>
    )
  }
}
