import { connect } from 'react-redux'

import { Departments } from './Departments'
import { AppState } from 'src/reducers/root'
import { getAttributeRenderSpecs } from 'src/selectors/referenceData/columns'
import { getAdminDepartments } from 'src/selectors/hierarchy'

const mapStateToProps = (state: AppState) => ({
  departments: getAdminDepartments(state),
  attributes: getAttributeRenderSpecs(state),
})

export const DepartmentsContainer = connect(mapStateToProps)(Departments)
