import React from 'react'
import { button } from './secondaryButton.scss'

type Props = {
  className?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
  disabled?: boolean
  dataCy?: string
}
export const SecondaryButton = (props: Props) => (
  <button
    className={`${button} ${props.className || ''}`}
    onClick={props.onClick}
    disabled={!!props.disabled}
    data-cy={props.dataCy}
  >
    {props.children}
  </button>
)

export default SecondaryButton
