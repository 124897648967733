import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  getCommsPdfMetaSuccess,
  getCommsPdfSuccess,
  GET_COMMS_PDF,
  GET_COMMS_PDF_META,
  UPLOAD_COMMS_PDF,
} from 'actions/admin/commsScreen'
import { injectorService } from 'service/injector/injectorService'
import { SVPAction } from 'actions/svp'
import { errorAndNotification } from 'sagas/error'
import { APIError } from 'types/Api'
import { showNotification } from 'actions/notification'
import { CommsDocumentFileMeta } from 'types/CommsDocument'
import { logError } from 'src/service/errors'

export function* uploadPdfSaga(action: SVPAction<typeof UPLOAD_COMMS_PDF>) {
  try {
    const pdfMetaInfo: CommsDocumentFileMeta = { lastModified: Date.now(), name: action.file.name }
    yield all([
      call(injectorService.put, 'comms-documents', action.file, {
        rootEndpoint: true,
        headers: { 'Content-Type': 'application/pdf' },
      }),
      call(injectorService.put, 'comms-documents/meta', JSON.stringify(pdfMetaInfo), {
        rootEndpoint: true,
      }),
    ])
    yield call(getPdfSaga)
    yield put(
      showNotification({
        type: 'success',
        message: 'Document uploaded sucessfully',
      }),
    )
  } catch (error) {
    yield call(logError, error as Error)
    yield* errorAndNotification(
      error as APIError,
      'There was a problem with the upload. Please contact support!',
    )
  }
}

export function* getPdfSaga() {
  try {
    const [response, metaResponse]: [string, CommsDocumentFileMeta] = yield all([
      call(
        injectorService.get,
        'comms-documents',
        {},
        { rootEndpoint: true, responseType: 'arraybuffer' },
      ),
      call(injectorService.get, 'comms-documents/meta', {}, { rootEndpoint: true }),
    ])

    const file = new File([new Blob([response], { type: 'application/pdf' })], 'file.pdf')
    yield put(getCommsPdfSuccess(file, metaResponse))
  } catch (error) {
    yield call(logError, error as Error)
  }
}

export function* getPdfMetaSaga() {
  try {
    const metaResponse: CommsDocumentFileMeta = yield call(
      injectorService.get,
      'comms-documents/meta',
      {},
      { rootEndpoint: true },
    )
    yield put(getCommsPdfMetaSuccess(metaResponse))
  } catch (error) {
    yield call(logError, error as Error)
    console.error('Unable to get Comms File', error)
  }
}

export default function* () {
  yield all([
    takeEvery(UPLOAD_COMMS_PDF, uploadPdfSaga),
    takeEvery(GET_COMMS_PDF, getPdfSaga),
    takeEvery(GET_COMMS_PDF_META, getPdfMetaSaga),
  ])
}
