import {
  GET_DEPARTMENT_RANGES_WITH_SIZES_FETCHING,
  GET_DEPARTMENT_RANGES_WITH_SIZES_FETCH_DONE,
  GET_DEPARTMENT_RANGES_WITH_SIZES_SUCCESS,
  UPDATE_DEPARTMENT_RANGE_SIZE_SUCCESS,
} from 'actions/userSettings/sizes'
import { SVPAction } from 'actions/svp'
import { RangeWithSize, SizesDeprecationMap } from 'types/Hierarchy'

export type UserSettingsSizesState = {
  isFetchingSizes: boolean
  rangesWithSizes: RangeWithSize[]
  sizesDeprecationMap: SizesDeprecationMap
}

const defaultUserSettingsSizesState: UserSettingsSizesState = {
  isFetchingSizes: false,
  rangesWithSizes: [],
  sizesDeprecationMap: {},
}

export const userSettingsSizes = (
  state: UserSettingsSizesState = defaultUserSettingsSizesState,
  action: SVPAction,
): UserSettingsSizesState => {
  switch (action.type) {
    case GET_DEPARTMENT_RANGES_WITH_SIZES_FETCHING:
      return {
        ...state,
        isFetchingSizes: true,
      }
    case GET_DEPARTMENT_RANGES_WITH_SIZES_FETCH_DONE:
      return {
        ...state,
        isFetchingSizes: false,
      }
    case GET_DEPARTMENT_RANGES_WITH_SIZES_SUCCESS:
      return {
        isFetchingSizes: false,
        rangesWithSizes: action.rangesWithSizes,
        sizesDeprecationMap: action.sizesDeprecationMap,
      }
    case UPDATE_DEPARTMENT_RANGE_SIZE_SUCCESS:
      return {
        ...state,
        sizesDeprecationMap: {
          ...state.sizesDeprecationMap,
          [action.idSizeDenominations]: {
            ...state.sizesDeprecationMap[action.idSizeDenominations],
            deprecated: action.isDeprecated,
          },
        },
      }
    default:
      return state
  }
}
