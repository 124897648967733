import { connect } from 'react-redux'

import { StyleModalSearchForm } from './StyleModalSearchForm'
import { AppState } from 'src/reducers/root'
import { getAdminDepartments } from 'src/selectors/hierarchy'
import { hierarchyToDropdownOption } from 'service/mappers/hierarchy'
import {
  getAttributeValues,
  getNonDeprecatedAttributeValues,
} from 'selectors/referenceData/attributes'
import { attributeToDropdownOption } from 'service/mappers/attributes'
import {
  setDepartment,
  setProductPrint,
  setStyleDescription,
  setStyleGroup,
  setStyleId,
  setStyleName,
} from 'actions/grid/modal/styleModal'
import { DropdownOption } from 'types/Dropdowns'
import { SearchStylePayload } from 'actions/grid/style'

interface PassedProps {
  hasResults: boolean
  onSearch: (payload: SearchStylePayload) => void
  onResults: () => void
}

interface StateProps {
  isSearchStyleLoading: boolean
  departments: DropdownOption[]
  styleGroups: DropdownOption[]
  prints: DropdownOption[]
  department: DropdownOption
  styleGroup: DropdownOption
  styleDescription: string
  styleID: string
  styleName: string
  productPrint: DropdownOption
}

interface DispatchProps {
  setStyleDescription: typeof setStyleDescription
  setStyleId: typeof setStyleId
  setStyleName: typeof setStyleName
  setProductPrint: typeof setProductPrint
  setStyleGroup: typeof setStyleGroup
  setDepartment: typeof setDepartment
}

export interface StyleModalSearchFormProps extends PassedProps, DispatchProps, StateProps {}

const emptyDropdown: DropdownOption = {
  value: '',
  label: '',
  data: {
    value: '',
    label: '',
  },
}

const mapStateToProps = (state: AppState): StateProps => ({
  isSearchStyleLoading: state.injectorUI?.modals?.styleModal?.isSearchStyleLoading,
  departments: [emptyDropdown, ...getAdminDepartments(state)?.map(hierarchyToDropdownOption)],
  styleGroups: [
    emptyDropdown,
    ...getNonDeprecatedAttributeValues(state)?.styleGroup?.map(attributeToDropdownOption),
  ],
  prints: [emptyDropdown, ...getAttributeValues(state)?.print?.map(attributeToDropdownOption)],
  department: state.injectorUI.modals?.styleModal?.department,
  styleGroup: state.injectorUI.modals?.styleModal?.styleGroup,
  styleDescription: state.injectorUI.modals?.styleModal?.styleDescription,
  styleID: state.injectorUI.modals?.styleModal?.styleID,
  styleName: state.injectorUI.modals?.styleModal?.styleName,
  productPrint: state.injectorUI.modals?.styleModal?.productPrint,
})

const mapDispatchToProps: DispatchProps = {
  setStyleDescription,
  setStyleId,
  setStyleName,
  setProductPrint,
  setStyleGroup,
  setDepartment,
}

export const StyleModalSearchFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyleModalSearchForm)
