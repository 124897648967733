import React from 'react'
import classnames from 'classnames'
import CurrencyDropdown from 'src/components/Dropdown/CurrencyDropdown/CurrencyDropdown'
import { CurrencyKeys } from 'src/types/index'

import { headerTitle, currencyContainer } from './ColumnHeader.scss'

type Props = {
  title: string
  className?: string
  hide?: boolean
  currency?: CurrencyKeys
  onCurrencyChange?: (currency: CurrencyKeys) => void
}

export const ColumnHeader = ({
  title,
  className = '',
  hide,
  currency,
  onCurrencyChange,
}: Props): JSX.Element => {
  if (hide) {
    return <></>
  }

  const onItemSelected = (currencyOption: CurrencyKeys): void => {
    onCurrencyChange && onCurrencyChange(currencyOption)
  }

  const headerStyles = classnames(className, headerTitle)
  return (
    <div className={headerStyles}>
      {currency && (
        <CurrencyDropdown
          className={currencyContainer}
          name="currencySupplierCost"
          selectedItem={currency}
          onItemSelected={onItemSelected}
        />
      )}
      <span>{title}</span>
    </div>
  )
}
