import { SVPAction } from 'actions/svp'
import { TreeHierarchyItem } from 'src/types/index'
import { FETCH_REFERENCE_DATA_SUCCESS } from '../../../actions/referenceData/referenceData'

const defaultPayload = [
  {
    slug: '',
    levelName: '',
    levelCode: '',
    title: '',
    children: [],
  } as TreeHierarchyItem,
]

export const tree = (state: TreeHierarchyItem[] = defaultPayload, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return action.hierarchy
    default:
      return state
  }
}
