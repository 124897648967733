import { hasUiScope } from '@ri-digital/auth0-authenticator'
import { AppState } from 'src/reducers/root'
import { ADMIN_UI } from 'src/constants/scopes'

export function hasUIScope(state: AppState, uiScope: string): boolean {
  return hasUiScope(state.uiScopes, uiScope)
}

export function hasAdminScope(state: AppState): boolean {
  return hasUiScope(state.uiScopes, ADMIN_UI)
}
