import { combineReducers } from 'redux'
import {
  AuthenticationState,
  ScopesState,
  UIScopeState,
  usersReducer,
} from '@ri-digital/auth0-authenticator'

import notification, { NotificationState } from './notification/reducer'
import imports, { ImportsState } from './imports/imports'
import { productComments, ProductCommentsState } from './comments'
import { config, ConfigState } from './config'
import { UserProfile } from 'src/types/index'
import { devTracker, DevTrackerState } from './devTracker'
import { optionLibrary, OptionLibraryState } from './optionLibrary'
import { images, ImagesState } from './images'
import { referenceData, ReferenceDataState } from './referenceData'
import { sidePanel, SidePanelState } from './sidePanel'
import { ModalState, modals } from './modals'
import { lock, LockState } from './lock'
import { PollingState } from './polling/types'
import polling from './polling/polling'
import { admin, AdminState } from './admin'
import { wholesaleImages, WholesaleImagesState } from './wholesaleImages'
import { style, StyleWithStatusState } from './style'
import { persistentSideList, PersistentSideListState } from './persistentSideList'
import { UserSettingsState, userSettings } from './userSettings'
import { clipboard, ClipboardState } from './clipboard'
import { createProduct, CreateProductState } from './createProduct'
export type AppState = {
  injectorUI: {
    config: ConfigState
    lock: LockState
    referenceData: ReferenceDataState
    notification: NotificationState
    modals: ModalState
    imports: ImportsState
    images: ImagesState
    productComments: ProductCommentsState
    sidePanel: SidePanelState
    userProfile?: UserProfile
    devTracker: DevTrackerState
    optionLibrary: OptionLibraryState
    polling: PollingState
    admin: AdminState
    wholesaleImages: WholesaleImagesState
    style: StyleWithStatusState
    persistentSideList: PersistentSideListState
    userSettings: UserSettingsState
    clipboard: ClipboardState
    createProduct: CreateProductState
  }
  authentication: AuthenticationState
  scopes: ScopesState
  uiScopes: UIScopeState
  router: {
    location: {
      pathname: string
    }
  }
}

export const reducers = {
  config,
  userProfile: usersReducer,
  lock,
  productComments,
  sidePanel,
  referenceData,
  notification,
  imports,
  images,
  devTracker,
  optionLibrary,
  modals,
  polling,
  admin,
  wholesaleImages,
  style,
  persistentSideList,
  userSettings,
  clipboard,
  createProduct,
}

// @ts-ignore
export default combineReducers(reducers)
