import { connect } from 'react-redux'
import { isAuthenticated } from '@ri-digital/auth0-authenticator'
import { getCommsPdf } from 'actions/admin/commsScreen'
import { AppState } from 'src/reducers/root'
import Home from './Home'
import { isGridLocked } from '../../selectors/grid'
import { getCommsFileMeta, getCommsPdfFile } from 'selectors/commsScreen'

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: isAuthenticated(state.authentication),
  locked: isGridLocked(state),
  commsFile: getCommsPdfFile(state),
  commsFileMeta: getCommsFileMeta(state),
})

const mapDispatchToProps = {
  getCommsPdf,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
