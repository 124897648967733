import React, { Component } from 'react'
import { GridAndControlsContainer } from 'src/components/GridAndControls/GridAndControlsContainer'
import { getParamsFromQueryString } from 'src/helpers/queryParameters'
import { GridType } from 'src/types/index'
import { QueryParameters } from 'src/types/QueryParameters'

interface Props {
  selectHierarchies: (slugs: string[], grid: GridType) => void
  gridName: GridType
}

export class DevTrackerGrid extends Component<Props> {
  componentDidMount() {
    const queryParams: QueryParameters = getParamsFromQueryString()
    const slugs: string[] = queryParams.hierarchyIDs || []
    if (slugs.length) {
      this.props.selectHierarchies(slugs, this.props.gridName)
    }
  }

  render() {
    return <GridAndControlsContainer gridName={this.props.gridName} />
  }
}
