import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authenticationSelectors } from '@ri-digital/auth0-authenticator'

import { RISpinner } from '../../../components/RISpinner/RISpinner'
import { callbackHelp } from './callback.scss'
import { AppState } from '../../../reducers/root'

// Three seconds
const timeoutDuration = 3000

type Props = {
  hasError: boolean
}

type State = {
  showHelp: boolean
}

class Callback extends Component<Props, State> {
  helpTimer?: NodeJS.Timer

  state = {
    showHelp: false,
  }

  componentDidUpdate({ hasError: prevHasError }: Props) {
    const { hasError } = this.props

    if (hasError === prevHasError) {
      return
    }

    if (!hasError) {
      return
    }

    this.helpTimer = setTimeout(() => {
      this.setState({
        showHelp: true,
      })
    }, timeoutDuration)
  }

  componentWillUnmount = () => {
    if (this.helpTimer) {
      clearTimeout(this.helpTimer)
    }
  }

  render() {
    const { showHelp } = this.state

    return (
      <RISpinner colour="white">
        {showHelp && (
          <div className={callbackHelp}>
            If you see this for more than 5 seconds please ensure you are using the latest version
            of Chrome, <br /> allow all cookies & contact Service Desk if necessary
          </div>
        )}
      </RISpinner>
    )
  }
}

const mapStateToProps = ({ authentication }: AppState) => ({
  hasError: authenticationSelectors.hasAuthenticationError(authentication),
})

export default connect(mapStateToProps)(Callback)
