import React from 'react'
import { sketchImage, sketchImageContainer } from './ImageCellRenderer.scss'
import SketchImage from '../../SketchImage'
import SidePanelDrawerContainer from '../../SidePanelDrawer/SidePanelDrawerContainer'
import { Image } from '../../../types/Images'
import { WHOLESALE, SKETCH } from 'types/Tabs'
import { ImageCellRendererProps } from './ImageCellRendererContainer'

const getLast = (images: Image[]): Image => images.slice(-1)[0]
const getUrl = (image?: Image): string => (image ? image.url : '')
const getType = (image?: Image): string => (image ? image.type : '')

export const ImageCellRenderer = ({
  product,
  rowIndex,
  editMode,
  exitEditMode,
  gridName,
}: ImageCellRendererProps): JSX.Element => (
  <div className={sketchImageContainer}>
    <SketchImage
      className={sketchImage}
      src={product.wsPrimaryThumbnail || getUrl(getLast(product.images))}
      alt={`thumbnail of ${
        product.wsPrimaryThumbnail ? 'wholesale' : getType(getLast(product.images))
      } image for product ${product.developmentId}`}
    />
    {editMode && (
      <SidePanelDrawerContainer
        rowIndex={rowIndex}
        open={editMode}
        exitEditMode={exitEditMode}
        openingTab={product.wsPrimaryThumbnail ? WHOLESALE : SKETCH}
        gridName={gridName}
      />
    )}
  </div>
)
