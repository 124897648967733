import { connect } from 'react-redux'
import { FancyFilterDrawer } from './FancyFilterDrawer'
import { addExactFilter, fetchDropdownItems, Filter } from 'src/actions/grid/exactFilters'
import { AppState } from 'src/reducers/root'
import { PartialFilter } from 'src/actions/grid/partialFilters'
import { GridType } from 'src/types/index'
import { removeExactFilter } from '../../actions/grid/exactFilters'
import { addPartialFilter, removePartialFilter } from '../../actions/grid/partialFilters'
import { clearAllFilters } from '../../actions/grid/filters'
import { getAllFilterValues } from '../../selectors/filter'
import { DropdownOption } from '../../types/Dropdowns'

type OwnProps = {
  gridName: GridType
}

const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): {
  filters: Filter[]
  partialFilters: PartialFilter[]
  uniqueValues: {
    [key: string]: DropdownOption[]
  }
} => ({
  filters: [
    ...Object.keys(state.injectorUI[ownProps.gridName].exactFilters).reduce(
      (acc, key) => [...acc, ...state.injectorUI[ownProps.gridName].exactFilters[key]],
      [] as Filter[],
    ),
  ],
  partialFilters: [
    ...Object.keys(state.injectorUI[ownProps.gridName].partialFilters).reduce(
      (acc, key) => [
        ...acc,
        ...(state.injectorUI[ownProps.gridName].partialFilters[key].saved || []),
      ],
      [] as PartialFilter[],
    ),
  ],
  uniqueValues: getAllFilterValues(state, ownProps.gridName),
})

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  removeExactFilter: (filter: Filter): void => dispatch(removeExactFilter(filter, gridName)),
  clearAllFilters: (): void => dispatch(clearAllFilters(gridName)),
  removePartialFilter: (filter: PartialFilter): void =>
    dispatch(removePartialFilter(filter, gridName)),
  fetchUniqueValues: (columnName: string, gridName: GridType): void =>
    dispatch(fetchDropdownItems(columnName, gridName)),
  addExactFilter: (filter: Filter): void => dispatch(addExactFilter(filter, gridName)),
  addPartialFilter: (filter: PartialFilter): void => dispatch(addPartialFilter(filter, gridName)),
})

export const FancyFilterDrawerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FancyFilterDrawer)
