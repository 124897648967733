import React, { Component } from 'react'

import { trackPage } from '../../service/analytics/analytics'

interface Props {
  location: {
    pathname: string
    search: string
  }
}

export default class Analytics extends Component<Props> {
  componentDidMount(): void {
    const { location } = this.props

    this.logPageChange(location.pathname, location.search)
  }

  componentDidUpdate({ location: prevLocation }: Props): void {
    const {
      location: { pathname, search },
    } = this.props

    const isDifferentPathname = pathname !== prevLocation.pathname

    if (!isDifferentPathname) {
      return
    }

    this.logPageChange(pathname, search)
  }

  logPageChange = (pathname: string, search = ''): void => {
    const { location } = window
    const path = `${location.origin}${pathname}`

    trackPage(pathname, path, search)
  }

  render(): JSX.Element {
    return <></>
  }
}
