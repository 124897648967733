import { connect } from 'react-redux'
import { AppState } from 'src/reducers/root'
import { LockTool } from './LockTool'
import { getLock, isLocked } from 'src/selectors/lock'
import { setLock as setLockAction } from 'src/actions/lock'

const mapStateToProps = (state: AppState) => ({
  lock: getLock(state),
  isLocked: isLocked(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  setLock: (status: string) => dispatch(setLockAction(status)),
})

export const LockToolContainer = connect(mapStateToProps, mapDispatchToProps)(LockTool)
