import { FileError } from 'react-dropzone'
import {
  ERROR_CODE_FILE_INVALID_TYPE,
  ERROR_CODE_FILE_TOO_LARGE,
  ERROR_CODE_FILE_TOO_SMALL,
  ERROR_CODE_FILES_TOO_MANY,
  ERROR_MESSAGE_CORRUPTED_IMAGE,
} from 'src/constants/errorCodes'

export interface LocalFile {
  file: File
  width: number
  height: number
}

export const validateWholesaleImage = (image: LocalFile): FileError | null => {
  if (image.file.size > 5000000) {
    return {
      code: ERROR_CODE_FILE_TOO_LARGE,
      message: `File ${image.file.name} is too large. Maximum size is 5MB`,
    }
  } else if (image.width < 500 || image.height < 500) {
    return {
      code: ERROR_CODE_FILE_TOO_SMALL,
      message: `File ${image.file.name} is too small. Minimum image dimensions are 500x500 pixels`,
    }
  } else if (
    image.file.type !== 'image/jpeg' &&
    image.file.type !== 'image/jpg' &&
    image.file.type !== 'image/png'
  ) {
    return {
      code: ERROR_CODE_FILE_INVALID_TYPE,
      message: `File ${image.file.name} must have type jpg or png.`,
    }
  } else {
    return null
  }
}

export const validateFilesCount = (images: File[], availableSlots: number): FileError | null => {
  if (images.length > availableSlots) {
    return {
      code: ERROR_CODE_FILES_TOO_MANY,
      message: `Too many images selected`,
    }
  } else {
    return null
  }
}

export const getLocalFile = async (files: File[]): Promise<Array<LocalFile>> => {
  const promises = []
  for (let index = 0; files && index < files.length; index++) {
    const file = files[index]
    const promise: Promise<LocalFile> = new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = function () {
        const tmpLocalFile: LocalFile = {
          width: image.width,
          height: image.height,
          file: file,
        }
        resolve(tmpLocalFile)
      }
      image.onerror = function () {
        reject({
          code: ERROR_CODE_FILE_INVALID_TYPE,
          message: ERROR_MESSAGE_CORRUPTED_IMAGE,
        })
      }
      image.src = URL.createObjectURL(file)
    })
    promises.push(promise)
  }
  return await Promise.all(promises)
}
