import { SVPAction } from 'actions/svp'
import { ADD_HIGHLIGHTED_ROW, CLEAR_HIGHLIGHTED_ROWS } from 'src/actions/grid/highlightedRows'

export type HighlightedRowsState = string[]

const defaultState: HighlightedRowsState = []

export const highlightedRows = (state: HighlightedRowsState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case ADD_HIGHLIGHTED_ROW:
      return [...new Set([...state, action.productSlug])]
    case CLEAR_HIGHLIGHTED_ROWS:
      return defaultState
    default:
      return state
  }
}
