import { ApiDepartmentSpec } from 'src/types/responses/departmentSpec'
import {
  UPDATE_ADMIN_DEPARTMENT_SPECS,
  UPDATE_DEPARTMENTS_READY_STATUS,
  UPDATE_SELECTED_ATTRIBUTE,
} from 'src/actions/admin/departments'
import { SVPAction } from 'actions/svp'

export type AdminDepartmentsState = {
  ready: boolean
  departmentSpecification?: ApiDepartmentSpec
  selectedAttribute?: string
}

const defaultState: AdminDepartmentsState = {
  ready: true,
}

export const adminDepartments = (
  state: AdminDepartmentsState = defaultState,
  action: SVPAction,
) => {
  switch (action.type) {
    case UPDATE_SELECTED_ATTRIBUTE:
      return {
        ...state,
        selectedAttribute: action.attributeName,
      }
    case UPDATE_ADMIN_DEPARTMENT_SPECS:
      return {
        ...state,
        departmentSpecification: action.departmentSpecification,
      }
    case UPDATE_DEPARTMENTS_READY_STATUS:
      return {
        ...state,
        ready: action.ready,
      }
    default:
      return state
  }
}
