import { getDepartmentRangesWithSizes, updateDepartmentRangeSize } from 'actions/userSettings/sizes'
import { connect } from 'react-redux'
import { AppState } from 'reducers/root'
import {
  getDepartmentRangesWithSizesSelector,
  getIsFetchingSizes,
  getPersistentSideListSelectedSlug,
  getSizesDeprecationMap,
} from 'selectors/userSettings'
import { Sizes } from './Sizes'
import { RangeWithSize, SizesDeprecationMap } from 'types/Hierarchy'

interface StateProps {
  isFetchingSizes: boolean
  rangesWithSizes: RangeWithSize[]
  selectedSideListSlug: string
  sizesDeprecationMap: SizesDeprecationMap
}

interface DispatchProps {
  getDepartmentRangesWithSizes: typeof getDepartmentRangesWithSizes
  updateDepartmentRangeSize: typeof updateDepartmentRangeSize
}

export interface SizesProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  isFetchingSizes: getIsFetchingSizes(state),
  rangesWithSizes: getDepartmentRangesWithSizesSelector(state),
  selectedSideListSlug: getPersistentSideListSelectedSlug(state),
  sizesDeprecationMap: getSizesDeprecationMap(state),
})

const mapDispatchToProps: DispatchProps = {
  getDepartmentRangesWithSizes,
  updateDepartmentRangeSize,
}

export const SizesContainer = connect(mapStateToProps, mapDispatchToProps)(Sizes)
