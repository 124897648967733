import React from 'react'
import classNames from 'classnames'

import HierarchyDrawer from '../HierarchyDrawer'
import { FolderIcon } from './FolderIcon'
import { DEV_TRACKER, TreeHierarchyItem } from 'src/types/index'

import {
  validationMessage,
  textField,
  hasError,
  inputIcon,
  inputWrapper,
} from './HierarchyFormField.scss'
import { isHierarchyNodeAtRangeLevel } from 'service/hierarchy/utils'

type Props = {
  header: string
  name: string
  required?: boolean
  value: TreeHierarchyItem
  validationText?: string
  isValid: boolean
  validationFunction?: (key: string) => boolean
  onChange: (selectedHierarchy: TreeHierarchyItem, controlName: string) => void
}

type State = {
  validationText: string
  hierarchyOpen: boolean
}

const hierarchyText = (node: TreeHierarchyItem): string => {
  return node.title === '' && node.levelCode === '' ? '' : `${node.levelCode} - ${node.title}`
}

export class HierarchyFormField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      validationText: this.props.validationText || `${this.props.header} is needed`,
      hierarchyOpen: false,
    }
  }

  onHierarchySelected = (node: TreeHierarchyItem): void => {
    this.props.onChange && this.props.onChange(node, this.props.name)
    this.setState({ hierarchyOpen: false })
  }

  openHierarchy = (): void => {
    this.setState({
      hierarchyOpen: true,
    })
  }

  closeHierarchy = (): void => {
    this.setState({ hierarchyOpen: false })
  }

  render(): JSX.Element {
    const hierarchyFieldClass = classNames(textField, 'e2e-HierarchyField', {
      [hasError]: !this.props.isValid,
    })

    const validationErrorClass = classNames({
      error: !this.props.isValid,
    })

    const errorMessage = (
      <div className={`${validationMessage} error`}>{this.state.validationText}</div>
    )
    const headerText = this.props.required ? `${this.props.header} *` : this.props.header

    return (
      <div className={hierarchyFieldClass}>
        <label htmlFor={this.props.header}>{headerText}</label>
        <div className={inputWrapper}>
          <input
            className={validationErrorClass}
            name={this.props.header}
            type="text"
            readOnly
            value={hierarchyText(this.props.value)}
            onClick={this.openHierarchy}
            data-cy="hierarchyDropdown"
          />
          <FolderIcon className={inputIcon} />
        </div>
        {!this.props.isValid ? errorMessage : null}
        {this.state.hierarchyOpen && (
          <HierarchyDrawer
            onNodeSelected={this.onHierarchySelected}
            onClose={this.closeHierarchy}
            gridName={DEV_TRACKER}
            nodeIsSelectable={isHierarchyNodeAtRangeLevel}
          />
        )}
      </div>
    )
  }
}
