import React from 'react'
import classNames from 'classnames'
import { FlatHierarchyItem } from 'types'
import { container, link, active } from './PersistentSideList.scss'

interface Props {
  selectedSideListSlug?: string
  departments: FlatHierarchyItem[]
  setPersistentSideListItem: (slug: string) => void
}

export const PersistentSideList = (props: Props): JSX.Element => {
  const onClickSideListItem = (slug: string): void => props.setPersistentSideListItem(slug)
  return (
    <div className={container}>
      <ul>
        {props.departments.map(({ slug, title, levelCode }, index) => (
          <li key={slug} onClick={(): void => onClickSideListItem(slug)}>
            <div
              className={classNames(link, { [active]: slug === props.selectedSideListSlug })}
              key={`${slug}-${index}`}
            >
              {`${title} - ${levelCode}`}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
