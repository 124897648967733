import React, { Component } from 'react'
import { Popover } from 'src/components/Popover'
import {
  downTriangle,
  filterLink,
  filterLinkClosed,
  filterLinkOpened,
  header,
  headerText,
  popoverContainer,
} from './FilterableHeader.scss'
import { DropdownFilter } from './DropdownFilter'
import { KeyboardNavigationContext } from 'src/components/KeyboardNavigation'
import { FreeTextFilter } from './FreeTextFilter'
import { TableHeadContext, TableHeadContextType } from '../TableHeadContext'
import { FilterType, GridType } from 'src/types/index'
import { getGrid } from 'src/selectors/grid'
import { AppState } from 'src/reducers/root'
import { connect } from 'react-redux'
import { CellType } from 'src/types/index'
import { FILTER_LEFT, FILTER_RIGHT } from '../../../../constants/popover'

type Props = {
  type: FilterType
  attributeType: CellType
  property: string
  displayName: string
}

type PropsWithContext = Props &
  TableHeadContextType & {
    leaveGrid: () => void
    resetSelection: () => void
    allowFocusInGrid: (focus: boolean) => void
    gridName: GridType
  }

type State = {
  popoverOpen: boolean
}

export class FilterableHeader extends Component<PropsWithContext, State> {
  state = {
    popoverOpen: false,
  }

  render() {
    const { displayName } = this.props
    const { popoverOpen } = this.state

    return (
      <div className={header}>
        <span className={headerText}>{displayName}</span>

        <div onClick={(e) => e.stopPropagation()}>
          <Popover
            open={popoverOpen}
            triggerClassName={filterLink}
            triggerOpenClass={filterLinkOpened}
            triggerCloseClass={filterLinkClosed}
            onOpen={this.onPopoverOpen}
            onClose={this.onPopoverClose}
            position={this.popoverPoisiton()}
            PopoverItem={this.popoverItem()}
            popoverContainerClass={popoverContainer}
          >
            <div className={downTriangle} />
          </Popover>
        </div>
      </div>
    )
  }

  onPopoverOpen = () => {
    const { setActive, leaveGrid, resetSelection, allowFocusInGrid } = this.props

    leaveGrid()
    setActive(true)
    allowFocusInGrid(false)
    resetSelection()
    this.openPopover()
  }

  onPopoverClose = () => {
    const { setActive, allowFocusInGrid } = this.props

    setActive(false)
    allowFocusInGrid(true)
    this.closePopover()
  }

  closePopover = () => {
    this.setState({
      popoverOpen: false,
    })
  }

  openPopover = () => {
    this.setState({
      popoverOpen: true,
    })
  }

  popoverPoisiton = () => {
    const { property } = this.props

    if (property === 'developmentId' || property === 'productDescription') {
      return FILTER_LEFT
    }

    return FILTER_RIGHT
  }

  popoverItem = () => {
    const { type, property, displayName, gridName } = this.props

    if (type === 'exact') {
      return (
        <DropdownFilter
          property={property}
          displayName={displayName}
          gridName={gridName}
          onAdd={this.closePopover}
        />
      )
    }

    return (
      <FreeTextFilter
        property={property}
        displayName={displayName}
        gridName={gridName}
        onAdd={this.closePopover}
      />
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  gridName: getGrid(state),
})

const FilterableHeaderContainer = connect(mapStateToProps)(FilterableHeader)

export default function FilterableHeaderWrapper(props: Props) {
  return (
    <KeyboardNavigationContext.Consumer>
      {(keyboardNavContext) => (
        <TableHeadContext.Consumer>
          {(tableHeadContext) => (
            <FilterableHeaderContainer {...props} {...keyboardNavContext} {...tableHeadContext} />
          )}
        </TableHeadContext.Consumer>
      )}
    </KeyboardNavigationContext.Consumer>
  )
}
