import React, { Component } from 'react'
import classNames from 'classnames'

import { Product, GridType, OPTION_LIBRARY } from 'src/types/index'
import { Arrow } from 'src/assets/icons/index'
import { CANCELLED } from 'src/constants/productStatus'
import { sizesSpan, arrowsContainer, arrow, back, forward } from './SizeCellRenderer.scss'

interface Props {
  grid: GridType
  product: Product
  locked: boolean
  editMode: boolean
  exitEditMode: (exit: boolean) => void
  viewSizes: (hierachy: string, slug: string) => void
}

export class SizeCellRenderer extends Component<Props> {
  componentDidUpdate(): void {
    const { editMode, exitEditMode, product, grid, locked, viewSizes } = this.props
    if (editMode && this.shouldShowModal(product.status, grid, locked)) {
      viewSizes(product.hierarchySlug, product.slug)
      exitEditMode(true)
    }
  }

  shouldShowModal(status: string, grid: GridType, locked: boolean): boolean {
    return grid === OPTION_LIBRARY && status !== CANCELLED && !locked
  }

  shouldNotRenderContent = (): boolean => {
    const { minSeqSizeName, maxSeqSizeName } = this.props.product.sizesSpan

    return minSeqSizeName === null && maxSeqSizeName === null
  }

  equalSizes = (): boolean => {
    const { minSeqSizeName, maxSeqSizeName } = this.props.product.sizesSpan

    return minSeqSizeName === maxSeqSizeName
  }

  render(): JSX.Element {
    const { product } = this.props

    if (this.shouldNotRenderContent()) {
      return <></>
    }

    return (
      <div className={sizesSpan}>
        {this.equalSizes() ? (
          <div data-cy="minSize">{product.sizesSpan.minSeqSizeName}</div>
        ) : (
          <>
            <div data-cy="minSize">{product.sizesSpan.minSeqSizeName}</div>
            <div className={arrowsContainer}>
              <Arrow className={classNames(arrow, back)} />
              <Arrow className={classNames(arrow, forward)} />
            </div>
            <div data-cy="maxSize">{product.sizesSpan.maxSeqSizeName}</div>
          </>
        )}
      </div>
    )
  }
}
