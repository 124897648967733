import { getColumnRenderSpecs } from 'src/selectors/referenceData/columns'
import { getAttributeValues } from 'src/selectors/referenceData/attributes'
import { apiStyleToStyle } from 'src/service/mappers/style'
import { injectorService } from 'src/service/injector/injectorService'
import { updateProductSuccess } from 'src/actions/grid/product/update'
import { Attribute, AttributeValueMap, Country, OPTION_LIBRARY, Product } from 'src/types/index'
import { errorAndNotification } from '../error'
import { all, call, put, select } from 'redux-saga/effects'
import { getCountries } from 'src/selectors/referenceData/countries'
import { ProductWithDynabutes } from 'src/types/Product'
import { ApiStyle } from 'types/Style'
import { APIError } from 'types/Api'

export function* clearStyleSaga(product: Product) {
  const columnSpec: Attribute[] = yield select(getColumnRenderSpecs)
  const attributesList: AttributeValueMap = yield select(getAttributeValues)
  const countries: Country[] = yield select(getCountries)
  try {
    const apiStyle: ApiStyle = yield call(injectorService.get, `style/${product.styleSlug}`)
    const style = apiStyleToStyle(apiStyle, columnSpec, attributesList, countries)

    const styleProducts: Array<ProductWithDynabutes> = style.options.flatMap(
      ({ products }) => products,
    )

    const [aProductInSameOption, ...otherProductsInSameOption] = styleProducts.filter(
      ({ optionSlug, slug }) => optionSlug === product.optionSlug && slug !== product.slug,
    )

    if (aProductInSameOption && otherProductsInSameOption.length === 0) {
      yield put(
        updateProductSuccess(aProductInSameOption.slug, { hasSupplierLink: false }, OPTION_LIBRARY),
      )
    }

    const [anOptionInSameStyle, ...otherOptionsInSameStyle] = style.options.filter(
      ({ slug }) => slug !== product.optionSlug,
    )

    if (otherOptionsInSameStyle.length === 0 && anOptionInSameStyle && !aProductInSameOption) {
      yield all(
        anOptionInSameStyle.products.map(({ slug }) =>
          put(updateProductSuccess(slug, { hasColourLink: false }, OPTION_LIBRARY)),
        ),
      )
    }
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't update linking information. Please refresh the page.",
    )
  }
}
