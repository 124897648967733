import { AppState } from 'reducers/root'
import { CreateProductFormFields } from 'types/Product'
import { getAttributeValues, getAttributesMap } from './referenceData/attributes'
import { AttributeValue } from 'types'
import { createSelector } from 'reselect'
import { emptyFields } from 'service/product/create'

function getDefaultAttributesForRange(
  state: AppState,
  fields: CreateProductFormFields,
): Partial<CreateProductFormFields> {
  const defaultSetFormFields: Partial<CreateProductFormFields> = {}
  const { defaultAttributes } = state.injectorUI.createProduct
  const attributesList = getAttributesMap(state)
  const attributesValues = getAttributeValues(state)
  const fieldsWithDefaults = emptyFields()

  Object.keys(fields).forEach((fieldName) => {
    const setDefaultAttribute = defaultAttributes.find(
      (defaultAttribute) => defaultAttribute.attributeName === fieldName,
    )
    if (setDefaultAttribute) {
      let data: AttributeValue | AttributeValue[] | undefined
      const attributeValues = attributesValues[setDefaultAttribute.attributeName].filter(
        (attrValue) =>
          setDefaultAttribute.defaultValues.find((valueSlug) => valueSlug === attrValue.slug),
      )
      if (attributeValues.length) {
        if (attributesList[setDefaultAttribute.attributeName]?.type === 'list') {
          data = {
            slug: attributeValues[0].slug,
            value: attributeValues[0].value,
            deprecated: attributeValues[0].deprecated,
          }
        } else {
          data = attributeValues.map((attributeValue) => {
            return {
              slug: attributeValue.slug,
              value: attributeValue.value,
              deprecated: attributeValue.deprecated,
            }
          })
        }
        defaultSetFormFields[fieldName] = {
          hasFocus: false,
          isMandatory: fields[fieldName].isMandatory,
          isValid: true,
          validationErrors: [],
          data,
        }
      }
    } else {
      if (fieldName !== 'hierarchy') {
        defaultSetFormFields[fieldName] = {
          ...fieldsWithDefaults[fieldName],
        }
      }
    }
  })

  return defaultSetFormFields
}

export const getDefaultAttributesForRangeSelector = createSelector(
  getDefaultAttributesForRange,
  (defaultSetFormFields: Partial<CreateProductFormFields>) => defaultSetFormFields,
)
