import { connect } from 'react-redux'
import { StatusCellRenderer } from './StatusCellRenderer'
import { AppState } from 'src/reducers/root'
import { getGrid, isGridLocked } from '../../../selectors/grid'
import { GridType } from 'src/types/index'
import { updateProductStatus, UpdateProductStatusPayload } from 'actions/grid/product/update'

const mapStateToProps = (state: AppState) => ({
  grid: getGrid(state),
  locked: isGridLocked(state),
})

const mapDispatchToProps = (dispatch: any) => ({
  updateStatus: (payload: UpdateProductStatusPayload, grid: GridType) =>
    dispatch(updateProductStatus(payload, grid)),
})

export const StatusCellRendererContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatusCellRenderer)
