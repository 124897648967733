import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { uploadImage as uploadImageService } from 'src/service/image/index'
import { generateImageId } from 'src/service/image/imageId'
import { showNotification } from 'src/actions/notification'
import { extractBase64FromDataUrl } from 'src/service/image/processImage'
import { push } from 'connected-react-router'
import { updateProduct } from './product/update'
import { GridType } from 'src/types/index'
import { errorAndNotification } from './error'
import {
  SAVE_SKETCH,
  UPDATE_IMAGES,
  updateImagesFailure,
  updateImagesSuccess,
} from '../actions/images'
import { getProductImages } from '../selectors/images'

import { Config } from '../helpers/config'
import { ImageTypes, Image } from '../types/Images'
import { APIError } from 'src/types/Api'
import { SVPAction } from 'actions/svp'

const IMAGE_FAILED_TO_SAVE = 'Sorry your image has failed to save. Please retry'

export function* uploadImage(sketchesApiUrl: string, imageInBase64: string) {
  if (imageInBase64 !== '') {
    const imageId: string = yield call(generateImageId, imageInBase64)

    const uploadedImageUrl = `${sketchesApiUrl}/${imageId}.png`
    yield call(uploadImageService, uploadedImageUrl, imageInBase64)

    return uploadedImageUrl
  }
  return ''
}

export function* saveImage(
  image: string,
  slug: string,
  grid: GridType,
  type: ImageTypes,
  index: number,
) {
  const sketchesApiUrl = Config().Get('sketchesApiUrl')

  const imageUrl: string = yield call(
    uploadImage,
    sketchesApiUrl as string,
    extractBase64FromDataUrl(image),
  )

  const apiPatch =
    type === 'sketch' ? { sketchUrl: imageUrl, thumbnailUrl: imageUrl } : { sampleUrl: imageUrl }

  const currentImages: Image[] = yield select(getProductImages, slug, grid)

  const updatedImages = [...currentImages]
  updatedImages[index] = { type, url: imageUrl }

  yield* updateProduct(
    {
      productSlug: slug,
      patchFields: {
        api: apiPatch,
        store: { images: updatedImages },
      },
    },
    grid,
  )

  yield put(
    showNotification({
      type: 'success',
      message: `${type === 'sketch' ? 'Sketch' : 'Sample'} added successfully!`,
    }),
  )
}

export function* saveFirstSketch({
  payload: { redirect, sketch, productSlug },
  grid,
}: SVPAction<typeof SAVE_SKETCH>) {
  const INDEX_OF_FIRST_IMAGE = 0

  try {
    yield call(saveImage, sketch, productSlug, grid, 'sketch', INDEX_OF_FIRST_IMAGE)
    yield put(updateImagesSuccess(INDEX_OF_FIRST_IMAGE, productSlug))

    if (redirect) {
      yield put(push(redirect))
    }
  } catch (error) {
    yield* errorAndNotification(error as APIError, IMAGE_FAILED_TO_SAVE)
    yield put(updateImagesFailure(INDEX_OF_FIRST_IMAGE, productSlug))
  }
}

export function* updateImagesSaga({ payload, grid }: SVPAction<typeof UPDATE_IMAGES>) {
  const { file, productSlug, type, index } = payload

  try {
    yield call(saveImage, file, productSlug, grid, type, index)
    yield put(updateImagesSuccess(index, productSlug))
  } catch (error) {
    yield* errorAndNotification(error as APIError, IMAGE_FAILED_TO_SAVE)
    yield put(updateImagesFailure(index, productSlug))
  }
}

export default function* () {
  yield all([takeEvery(SAVE_SKETCH, saveFirstSketch), takeEvery(UPDATE_IMAGES, updateImagesSaga)])
}
