import React from 'react'

export const BookmarkIcon = () => (
  <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1429 0H2.85714C1.28571 0 0 1.6 0 3.55556V32L10 26.6667L20 32V3.55556C20 1.6 18.7143 0 17.1429 0Z"
      fill="#157EFB"
    />
  </svg>
)
