import React, { Component } from 'react'

import SizeGroup from './SizeGroupRow/SizeGroup'
import { MINI_GROUP, TEEN_GROUP, DEFAULT_GROUP, MINI_EXT_GROUP } from 'src/constants/productSizes'
import { Size, SizeGroups } from 'src/types/Size'
import { PriceTypes } from 'src/types/Price'
import { ProductSizeGroups } from 'src/types/ProductSizes'
import { CurrencyKeys, DiscreteValue } from 'src/types/index'

type Props = {
  supplierCostCurrency: CurrencyKeys
  sizeGroups: SizeGroups
  isProductReadyToBuy?: boolean
  sellingPriceOptions: DiscreteValue[]
  isHierarchyInBeautyAndBrandsDivision: boolean
  onSizeChipClicked: (size: Size) => void
  onPriceChange: (group: ProductSizeGroups, priceKey: PriceTypes, priceValue: number) => void
  onCurrencyChange: (currency: CurrencyKeys) => void
}

export default class ProductSizesTable extends Component<Props> {
  render(): JSX.Element {
    const {
      sizeGroups,
      isProductReadyToBuy,
      supplierCostCurrency,
      sellingPriceOptions,
      isHierarchyInBeautyAndBrandsDivision,
      onSizeChipClicked,
      onPriceChange,
      onCurrencyChange,
    } = this.props
    return (
      <div>
        <SizeGroup
          showColumnTitle
          group={DEFAULT_GROUP}
          groupData={sizeGroups[DEFAULT_GROUP]}
          isProductReadyToBuy={isProductReadyToBuy}
          supplierCostCurrency={supplierCostCurrency}
          sellingPriceOptions={sellingPriceOptions}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onSizeChipClicked={onSizeChipClicked}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
        />
        <SizeGroup
          group={MINI_GROUP}
          groupData={sizeGroups[MINI_GROUP]}
          isProductReadyToBuy={isProductReadyToBuy}
          supplierCostCurrency={supplierCostCurrency}
          sellingPriceOptions={sellingPriceOptions}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onSizeChipClicked={onSizeChipClicked}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
        />
        <SizeGroup
          group={MINI_EXT_GROUP}
          groupData={sizeGroups[MINI_EXT_GROUP]}
          isProductReadyToBuy={isProductReadyToBuy}
          supplierCostCurrency={supplierCostCurrency}
          sellingPriceOptions={sellingPriceOptions}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onSizeChipClicked={onSizeChipClicked}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
        />
        <SizeGroup
          group={TEEN_GROUP}
          groupData={sizeGroups[TEEN_GROUP]}
          isProductReadyToBuy={isProductReadyToBuy}
          supplierCostCurrency={supplierCostCurrency}
          sellingPriceOptions={sellingPriceOptions}
          isHierarchyInBeautyAndBrandsDivision={isHierarchyInBeautyAndBrandsDivision}
          onSizeChipClicked={onSizeChipClicked}
          onPriceChange={onPriceChange}
          onCurrencyChange={onCurrencyChange}
          isLastSection
        />
      </div>
    )
  }
}
