import React from 'react'

interface Props {
  className: string
  colour: string
}

export const SketchImageIcon = ({ className, colour = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" className={className}>
    <title>Sketch image</title>
    <path
      d="M14.77,6.19a7.88,7.88,0,0,1,5.51,1.93c.84.68,1.65,1.41,2.48,2.12.3.26.3.4,0,.68l-3.22,3.22c-.25.25-.39.25-.64,0s-.42-.44-.63-.66l-.08,0V25.35c0,.52-.07.59-.58.59H6.07c-.44,0-.53-.09-.53-.54V13.52l-.09-.07c-.21.23-.41.48-.63.7s-.38.24-.61,0L.93,10.88c-.22-.22-.22-.39,0-.58,1-.84,1.93-1.71,2.95-2.5A7.66,7.66,0,0,1,8.59,6.18m13.56,4.4c-.83-.71-1.64-1.42-2.47-2.1a7.12,7.12,0,0,0-2.82-1.4c-.52-.13-1-.19-1.58-.28A3.45,3.45,0,0,1,12,9.9a3.32,3.32,0,0,1-2.05-.6A3.42,3.42,0,0,1,8.45,6.85H8.18A7.7,7.7,0,0,0,3.85,8.66c-.7.59-1.39,1.2-2.09,1.8l-.17.1L4.53,13.5l.19-.18c.28-.28.55-.57.84-.83A.6.6,0,0,1,6,12.34c.21,0,.22.24.22.42a1.62,1.62,0,0,0,0,.22V25.26H17.55V12.81c0-.19,0-.38.19-.48s.36.06.5.2l1,1ZM9.06,6.74c.37,1.42,1.22,2.5,2.8,2.49s2.31-.93,2.72-2.49"
      fill={colour}
      strokeWidth="0.25"
    />
    <line
      x1="11.82"
      y1="2.04"
      x2="11.82"
      y2="3.04"
      fill="none"
      stroke={colour}
      strokeLinecap="round"
      strokeWidth="0.5"
    />
    <line
      x1="11.82"
      y1="4.93"
      x2="11.82"
      y2="26.64"
      fill="none"
      stroke={colour}
      strokeLinecap="round"
      strokeWidth="0.5"
      strokeDasharray="1.89 1.89"
    />
    <line
      x1="11.82"
      y1="27.59"
      x2="11.82"
      y2="28.59"
      fill="none"
      stroke={colour}
      strokeLinecap="round"
      strokeWidth="0.5"
    />
  </svg>
)
