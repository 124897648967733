import { SVPAction } from 'actions/svp'
import { all, put, select, takeEvery } from 'redux-saga/effects'
import { UPDATE_MULTI_SELECT, updateBatchMultiSelect } from '../actions/grid/multiSelect'
import { getFilteredSelectedIds, getSelectAll } from '../selectors/multiSelect'

export function* updateBatchMultiSelectSaga({
  payload,
  grid,
}: SVPAction<typeof UPDATE_MULTI_SELECT>) {
  if (!payload.isSelected) {
    const shouldSelectAll: boolean = yield select(getSelectAll)
    if (shouldSelectAll) {
      const allIds: string[] = yield select(getFilteredSelectedIds)

      yield put(
        updateBatchMultiSelect(
          allIds.filter((id: string) => id !== payload.productSlug),
          grid,
        ),
      )
    }
  }
}

export default function* () {
  yield all([takeEvery(UPDATE_MULTI_SELECT, updateBatchMultiSelectSaga)])
}
