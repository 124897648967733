import React from 'react'
import FileFolder from '@material-ui/icons/FolderRounded'

import { Modal } from '../../../Modal/Modal'
import PrimaryButton from '../../../PrimaryButton/PrimaryButton'
import { SkipLink } from '../../../Link'
import { TreeHierarchyItem } from 'src/types/index'
import {
  title,
  button,
  productNumber,
  moveToHierarchy,
  confirm,
  actionsGroup,
  note,
  container,
  folder,
} from './MoveToModal.scss'

interface Props {
  hierarchyNode: TreeHierarchyItem
  onClose: () => void
  moveProducts: (hierachy: string, products: string[]) => void
  selectedIds: {
    movable: string[]
    movableDevelopmentIds: string[]
    notMovable: string[]
  }
}

const getProduct = (count: number): string => (count === 1 ? 'product' : 'products')

export const MoveToModal = ({
  onClose,
  moveProducts,
  selectedIds,
  hierarchyNode,
}: Props): JSX.Element => (
  <Modal onCloseFn={onClose}>
    <div className={container}>
      <h3 className={title}>
        <span className={productNumber}>{selectedIds.movable.length}</span>
        {` ${getProduct(selectedIds.movable.length)} will be moved to:`}
      </h3>

      <span className={moveToHierarchy}>
        <FileFolder className={folder} /> {`${hierarchyNode.title} ${hierarchyNode.levelCode}`}
      </span>

      {selectedIds.notMovable.length > 0 && (
        <>
          <h3 className={title}>
            <span className={productNumber}>{selectedIds.notMovable.length}</span>
            {` ${getProduct(selectedIds.notMovable.length)} will not be moved`}
          </h3>
          <p className={note}>Products with product numbers can not be moved between departments</p>
        </>
      )}
    </div>
    <p className={confirm}>Do you wish to proceed?</p>
    <div className={actionsGroup}>
      <PrimaryButton
        className={button}
        onClick={(): void => {
          moveProducts(hierarchyNode.slug, selectedIds.movable)
          onClose()
        }}
        disabled={selectedIds.movable.length === 0}
      >
        Move to new location
      </PrimaryButton>
      <SkipLink onClick={onClose}>Cancel</SkipLink>
    </div>
  </Modal>
)
