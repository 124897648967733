import { LinkMember, ProductLinks } from 'src/types/Links'
import { ApiLinkMember, ApiProductLinks } from '../../types/responses/links'
import { createImage, createImages } from './product'

const images = (apiLinkMember: ApiLinkMember): LinkMember => ({
  developmentId: apiLinkMember.developmentId,
  productNumber:
    apiLinkMember.productNumber && apiLinkMember.productNumber.length > 0
      ? apiLinkMember.productNumber
      : apiLinkMember.productNumber
      ? [String(apiLinkMember.productNumber)]
      : [],
  productDescription: apiLinkMember.productDescription,
  productSlug: apiLinkMember.productSlug,
  images: createImages([
    createImage('sketch', apiLinkMember.sketchUrl),
    createImage('sample', apiLinkMember.sampleUrl),
  ]),
  colour: apiLinkMember.colour || '',
  wsPrimaryThumbnail: apiLinkMember.wsPrimaryThumbnail,
})

export const apiProductLinksToProductLinks = (apiProductLinks: ApiProductLinks): ProductLinks => ({
  matchingProducts: apiProductLinks.matchingProducts.map(images),
  crossDepartmentProducts: apiProductLinks.crossDepartmentProducts.map(images),
})
