import { connect } from 'react-redux'

import { Tools } from './Tools'
import { getToolsValues } from 'src/service/product/uiValues'

const mapStateToProps = () => ({
  tools: getToolsValues(),
})

export const ToolsContainer = connect(mapStateToProps)(Tools)
