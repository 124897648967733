export const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS'
export const IMPORT_ERROR = 'IMPORT_ERROR'
export const CLEAR_IMPORT_ERROR = 'CLEAR_IMPORT_ERROR'

export type ImportActions = ReturnType<
  typeof importProducts | typeof importError | typeof clearImportError
>

export const importProducts = (file: any) =>
  <const>{
    type: IMPORT_PRODUCTS,
    file,
  }

export const importError = (payload: any) =>
  <const>{
    type: IMPORT_ERROR,
    payload,
  }

export const clearImportError = () =>
  <const>{
    type: CLEAR_IMPORT_ERROR,
  }
