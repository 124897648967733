import React from 'react'
import { chevronDown } from './icons.scss'

export const ChevronDown = () => (
  <svg
    className={chevronDown}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="10"
    viewBox="0 0 17 10"
  >
    <polygon
      fill="#FFF"
      fillRule="evenodd"
      stroke="#FFF"
      points="4.5 -1 4.5 -3 13 5 4.5 13 4.5 11 10.5 5"
      transform="rotate(90 8.75 5)"
    />
  </svg>
)
