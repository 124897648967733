import cloneDeep from 'lodash/cloneDeep'
import { PartialFiltersState } from 'src/reducers/common/grid/partialFilters'
import { AVAILABLE_PRICES, PRICE_MULTIPLIER } from 'src/constants/price'

export const mapPriceValuesToFilters = (partialFilters: PartialFiltersState) => {
  const clonedPartialFilters = cloneDeep(partialFilters)

  Object.keys(clonedPartialFilters).forEach((partialFilter) => {
    if (AVAILABLE_PRICES.indexOf(partialFilter) > -1) {
      clonedPartialFilters[partialFilter].saved.forEach((filterValue) => {
        filterValue.value = `${parseFloat(filterValue.value) * PRICE_MULTIPLIER}`
      })
    }
  })

  return clonedPartialFilters
}
