import React, { Component } from 'react'
import PrimaryButton from '../../PrimaryButton/PrimaryButton'

import { footer, saveButton, cancelButton } from './SizeModalFooter.scss'

type Props = {
  hasSizes: boolean
  displayConfirm: boolean
  isDisabled: boolean
  retrySubmit: boolean
  onClose: () => void
  onSave: () => void
}

export default class SizeModalFooter extends Component<Props> {
  render() {
    const { hasSizes, displayConfirm, onSave, onClose, isDisabled, retrySubmit } = this.props

    return (
      <div className={footer}>
        <PrimaryButton
          onClick={onClose}
          colour="black"
          className={cancelButton}
          dataCy="CancelButton"
        >
          Close
        </PrimaryButton>
        {hasSizes && (
          <PrimaryButton
            disabled={isDisabled}
            onClick={onSave}
            colour="green"
            className={saveButton}
            dataCy="OKButton"
          >
            {retrySubmit ? 'Retry' : displayConfirm ? 'Confirm' : 'OK'}
          </PrimaryButton>
        )}
      </div>
    )
  }
}
