import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  ADD_EXACT_FILTER,
  addExactFilterSuccess,
  FETCH_DROPDOWN_ITEMS,
  fetchDropdownItemsSuccess,
  REMOVE_EXACT_FILTER,
  removeExactFilterSuccess,
  Filter,
} from 'src/actions/grid/exactFilters'
import { injectorService } from 'src/service/injector/injectorService'
import { getColumnSlug, getColumnType } from 'src/selectors/referenceData/columns'
import { getSelectedHierarchies } from 'src/selectors/hierarchy'
import { MapperValue, strategyRegistryLookup } from 'src/registry/behaviourRegistry'
import { errorAndNotification } from './error'
import { getExactFilters, getPartialFilters, getSortedColumns } from 'src/selectors/product'
import { fetchProductsPageSaga } from './product/fetch'
import {
  ADD_PARTIAL_FILTER,
  addPartialFilterSuccess,
  REMOVE_PARTIAL_FILTER,
  removePartialFilterSuccess,
  PartialFilter,
} from 'src/actions/grid/partialFilters'
import { UPDATE_SORTED_COLUMNS, updateSortedColumnsSuccess } from 'src/actions/grid/sortedColumns'
import {
  CLEAR_ALL_FILTERS,
  REFRESH_FILTERS,
  clearAllFiltersSuccess,
} from 'src/actions/grid/filters'
import { queryParametersBuilder } from './product/utils/queryParametersBuilder'
import omit from 'lodash/omit'
import { uiDropdownItemsMapper } from 'src/service/mappers/uiDropdownItemsMapper'
import { AttributeValue, CellType, FlatHierarchyItem, IntentionalAny } from 'src/types/index'
import { ExactFiltersState } from 'src/reducers/common/grid/exactFilters'
import { PartialFiltersState } from 'src/reducers/common/grid/partialFilters'
import { ApiUniqueColumnValues } from 'src/types/responses/columns'
import { SortedColumnState } from 'src/reducers/common/grid/sortedColumns'
import { SVPAction } from 'actions/svp'
import { PRICE_STATUS } from 'constants/price'

export function* fetchDropdownItemsSaga(action: SVPAction<typeof FETCH_DROPDOWN_ITEMS>) {
  try {
    const uiValuesMapper = uiDropdownItemsMapper(action.columnName)
    if (uiValuesMapper) {
      const dropdownItems = uiValuesMapper.getValues()
      yield put(fetchDropdownItemsSuccess(dropdownItems, action.columnName, action.grid))

      return
    }
    const columnSlug: string = yield select(getColumnSlug, action.columnName)
    const selectedHierarchies: FlatHierarchyItem[] = yield select(getSelectedHierarchies)
    const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
    const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)

    const exactFiltersWithoutColumn = omit(currentExactFilters, action.columnName)
    const partialFiltersWithoutColumn = omit(currentPartialFilters, action.columnName)

    const queryParams: Record<string, IntentionalAny> = yield call(
      queryParametersBuilder,
      exactFiltersWithoutColumn,
      partialFiltersWithoutColumn,
      [],
      selectedHierarchies,
      action.grid,
    )

    const requestUrl = `columns/${columnSlug}/unique-values`
    const identifiersPayload: ApiUniqueColumnValues = yield call(
      injectorService.get,
      requestUrl,
      queryParams,
    )

    const columnType: CellType = yield select(getColumnType, action.columnName)
    const columnReferenceData: AttributeValue[] | null = yield select(
      strategyRegistryLookup(columnType).referenceDataSelector,
      action.columnName,
    )

    const dropdownItems: MapperValue[] = yield call(
      strategyRegistryLookup(columnType).apiIdentifiersToDropdownItemsMapper,
      identifiersPayload.identifiers || [],
      columnReferenceData,
    )

    yield put(fetchDropdownItemsSuccess(dropdownItems, action.columnName, action.grid))
  } catch (error) {
    yield call(
      errorAndNotification,
      error as Error,
      "Something went wrong and we can't display filterable values for this column.\n Please try again.",
    )
  }
}

export function* addExactFilterSaga(action: SVPAction<typeof ADD_EXACT_FILTER>) {
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)

  const updatedExactFilters = {
    ...currentExactFilters,
    [action.filter.property]:
      action.filter.property === PRICE_STATUS
        ? [action.filter]
        : [...(currentExactFilters[action.filter.property] || []), action.filter],
  }

  yield call(
    fetchProductsPageSaga,
    0,
    updatedExactFilters,
    currentPartialFilters,
    currentSortedColumns,
    action.grid,
  )

  yield put(addExactFilterSuccess(action.filter, action.grid))
}

export function* removeExactFilterSaga(action: SVPAction<typeof REMOVE_EXACT_FILTER>) {
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)

  const updatedExactFilters = {
    ...currentExactFilters,
    [action.filter.property]: (currentExactFilters[action.filter.property] || []).filter(
      removeByValue(action.filter.value),
    ),
  }

  yield call(
    fetchProductsPageSaga,
    0,
    updatedExactFilters,
    currentPartialFilters,
    currentSortedColumns,
    action.grid,
  )

  yield put(removeExactFilterSuccess(action.filter, action.grid))
}

export function* addPartialFilterSaga(action: SVPAction<typeof ADD_PARTIAL_FILTER>) {
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)
  const filterValues =
    action.filter.value.includes(',') || action.filter.value.includes(';')
      ? action.filter.value.split(/[,;]+/).map((filterValue) => filterValue.trim())
      : [action.filter.value]

  const splitFilterValues = filterValues.map((filterValue) => ({
    value: filterValue,
    property: action.filter.property,
    displayName: action.filter.displayName,
  }))

  const updatedPartialFilters = {
    ...currentPartialFilters,
    [action.filter.property]: {
      saved: [
        ...(currentPartialFilters[action.filter.property]
          ? currentPartialFilters[action.filter.property].saved
          : []),
        ...splitFilterValues,
      ],
    },
  }

  yield call(
    fetchProductsPageSaga,
    0,
    currentExactFilters,
    updatedPartialFilters,
    currentSortedColumns,
    action.grid,
  )
  for (const filter of splitFilterValues) {
    yield put(addPartialFilterSuccess(filter, action.grid))
  }
}

export function* removePartialFilterSaga(action: SVPAction<typeof REMOVE_PARTIAL_FILTER>) {
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)

  const updatedPartialfilters = {
    ...currentPartialFilters,
    [action.filter.property]: {
      saved: (currentPartialFilters[action.filter.property]
        ? currentPartialFilters[action.filter.property].saved
        : []
      ).filter(removeByValue(action.filter.value)),
    },
  }

  yield call(
    fetchProductsPageSaga,
    0,
    currentExactFilters,
    updatedPartialfilters,
    currentSortedColumns,
    action.grid,
  )

  yield put(removePartialFilterSuccess(action.filter, action.grid))
}

export function* sortSaga(action: SVPAction<typeof UPDATE_SORTED_COLUMNS>) {
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)

  yield call(
    fetchProductsPageSaga,
    0,
    currentExactFilters,
    currentPartialFilters,
    action.payload,
    action.grid,
  )

  yield put(updateSortedColumnsSuccess(action.payload, action.grid))
}

export function* clearAllFiltersSaga(action: SVPAction<typeof CLEAR_ALL_FILTERS>) {
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)

  yield call(fetchProductsPageSaga, 0, {}, {}, currentSortedColumns, action.grid)

  yield put(clearAllFiltersSuccess(action.grid))
}

export function* refreshFiltersSaga(action: SVPAction<typeof REFRESH_FILTERS>) {
  const currentPartialFilters: PartialFiltersState = yield select(getPartialFilters)
  const currentExactFilters: ExactFiltersState = yield select(getExactFilters)
  const currentSortedColumns: SortedColumnState = yield select(getSortedColumns)

  yield call(
    fetchProductsPageSaga,
    0,
    currentExactFilters,
    currentPartialFilters,
    currentSortedColumns,
    action.grid,
  )
}

const removeByValue = (value: string) => (cv: PartialFilter | Filter) => cv.value !== value

export default function* () {
  yield all([takeEvery(FETCH_DROPDOWN_ITEMS, fetchDropdownItemsSaga)])
  yield all([takeEvery(ADD_EXACT_FILTER, addExactFilterSaga)])
  yield all([takeEvery(REMOVE_EXACT_FILTER, removeExactFilterSaga)])
  yield all([takeEvery(ADD_PARTIAL_FILTER, addPartialFilterSaga)])
  yield all([takeEvery(REMOVE_PARTIAL_FILTER, removePartialFilterSaga)])
  yield all([takeEvery(UPDATE_SORTED_COLUMNS, sortSaga)])
  yield all([takeEvery(CLEAR_ALL_FILTERS, clearAllFiltersSaga)])
  yield all([takeEvery(REFRESH_FILTERS, refreshFiltersSaga)])
}
