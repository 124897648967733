import React from 'react'
import { GridType, OPTION_LIBRARY, Product } from 'src/types/index'
import { CANCELLED, READY_TO_BUY } from 'src/constants/productStatus'
import { multiProductNumber } from './ProductNumberCellRenderer.scss'

type Props = {
  product: Product
  grid: GridType
  locked: boolean
  editMode: boolean
  viewSizes: (hierarchy: string, slug: string) => void
  exitEditMode: (focus?: boolean) => void
}

//TODO need to add here the sort of
const getProductNumbers = ({
  status,
  statusBeforeCancelled,
  productNumberView,
}: Product): string[] => {
  const sortedProductNumbers = productNumberView
    ? [
        productNumberView.default,
        productNumberView.M,
        productNumberView.O,
        productNumberView.T,
      ].filter((pn) => !!pn)
    : []

  return sortedProductNumbers && (status === READY_TO_BUY || statusBeforeCancelled === READY_TO_BUY)
    ? sortedProductNumbers
    : []
}

export const ProductNumberCellRenderer = ({
  editMode,
  product,
  grid,
  viewSizes,
  exitEditMode,
  locked,
}: Props): JSX.Element => {
  const shouldShowModal = (status: string, grid: GridType, locked: boolean): boolean =>
    grid === OPTION_LIBRARY && status !== CANCELLED && !locked

  React.useEffect(() => {
    if (editMode && shouldShowModal(product.status, grid, locked)) {
      viewSizes(product.hierarchySlug, product.slug)
      exitEditMode()
    }
  }, [editMode, product, grid, locked, viewSizes, exitEditMode])

  return (
    <div className={multiProductNumber}>
      {getProductNumbers(product).map((pn, ids) => (
        <div data-cy="product-number-value" key={ids}>
          {pn}
        </div>
      ))}
    </div>
  )
}
