import { connect } from 'react-redux'
import { removeCrossDepartmentProduct, removeMatchingProduct } from '../../../actions/grid/links'
import { LinksSection } from './LinksSection'
import { detachProduct, detachSelectedProduct } from '../../../actions/grid/style'
import { openStyleModal } from 'actions/grid/modal/styleModal'
import { StyleWithStatusState } from 'reducers/style'
import { ProductLinksWithStatus } from 'types/Links'
import { ProductWithDynabutes } from 'types/Product'

interface PassedProps {
  style: StyleWithStatusState
  links: ProductLinksWithStatus
  selectedProduct: ProductWithDynabutes
}

interface DispatchProps {
  detachProduct: typeof detachProduct
  detachSelectedProduct: typeof detachSelectedProduct
  removeMatchingProduct: typeof removeMatchingProduct
  removeCrossDepartmentProduct: typeof removeCrossDepartmentProduct
  openStyleModal: typeof openStyleModal
}

export interface LinkSectionProps extends PassedProps, DispatchProps {}

const mapDispatchToProps: DispatchProps = {
  detachProduct,
  detachSelectedProduct,
  removeMatchingProduct,
  removeCrossDepartmentProduct,
  openStyleModal,
}

export const LinksSectionContainer = connect(null, mapDispatchToProps)(LinksSection)
