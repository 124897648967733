import React from 'react'
import { setFocus } from '../../service/ref'
import { listItem } from './FocusableListItem.scss'

type Props = {
  dataCY?: string
  className: string
  hasFocus: boolean
  children: React.ReactNode
}

export class FocusableListItem extends React.Component<Props> {
  ref: React.RefObject<HTMLLIElement>

  constructor(props: Props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    this.props.hasFocus && setFocus(this.ref.current)
  }

  render() {
    return (
      <li
        data-cy={this.props.dataCY}
        className={`${this.props.className} ${listItem}`}
        tabIndex={0}
        ref={this.ref}
      >
        {this.props.children}
      </li>
    )
  }
}
