import { Config as ConfigOptions } from 'src/types/index'
import { logError } from './sentry'

class UIConfig {
  config: ConfigOptions

  constructor(opts: ConfigOptions = {}) {
    this.config = opts

    if (this.config.pageSize === undefined) {
      logError(new Error('pageSize is not set'))
    }
    if (this.config.infiniteScrollThreshold === undefined) {
      logError(new Error('infiniteScrollThreshold is not set'))
    }
    if (this.config.apiBaseUrl === undefined) {
      logError(new Error('apiBaseUrl is not set'))
    }
    if (this.config.sketchCookieRefreshUrl === undefined) {
      logError(new Error('sketchCookieRefreshUrl is not set'))
    }
  }

  Get = (key: string): Record<string, string> | string | number | boolean | undefined => {
    return this.config[key]
  }
}

let config: UIConfig | null = null

export const Config = (opts?: ConfigOptions) => {
  if (!config) {
    config = new UIConfig(opts)
  }

  return config
}
