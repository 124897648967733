import React from 'react'

interface Props {
  className: string
}

export const Ellipses = ({ className }: Props): JSX.Element => (
  <svg
    data-cy="ellipses-icon"
    className={className}
    viewBox="0 0 31 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>More Actions</title>
    <g id="Main-Stage" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval" fill="#FFFFFF" cx="15.5" cy="15.5" r="15.5" />
      <path
        id="..."
        d="M10.02,15.95 C10.02,16.4700026 9.8300019,16.929998 9.45,17.33 C9.0699981,17.730002 8.5900029,17.93 8.01,17.93 C7.4499972,17.93 6.97500195,17.7350019 6.585,17.345 C6.19499805,16.9549981 6,16.5000026 6,15.98 C6,15.4399973 6.1899981,14.9750019 6.57,14.585 C6.9500019,14.194998 7.4299971,14 8.01,14 C8.5900029,14 9.0699981,14.1899981 9.45,14.57 C9.8300019,14.9500019 10.02,15.4099973 10.02,15.95 Z M17.82,15.95 C17.82,16.4700026 17.6300019,16.929998 17.25,17.33 C16.8699981,17.730002 16.3900029,17.93 15.81,17.93 C15.2499972,17.93 14.7750019,17.7350019 14.385,17.345 C13.994998,16.9549981 13.8,16.5000026 13.8,15.98 C13.8,15.4399973 13.9899981,14.9750019 14.37,14.585 C14.7500019,14.194998 15.2299971,14 15.81,14 C16.3900029,14 16.8699981,14.1899981 17.25,14.57 C17.6300019,14.9500019 17.82,15.4099973 17.82,15.95 Z M25.62,15.95 C25.62,16.4700026 25.4300019,16.929998 25.05,17.33 C24.6699981,17.730002 24.1900029,17.93 23.61,17.93 C23.0499972,17.93 22.5750019,17.7350019 22.185,17.345 C21.794998,16.9549981 21.6,16.5000026 21.6,15.98 C21.6,15.4399973 21.7899981,14.9750019 22.17,14.585 C22.5500019,14.194998 23.0299971,14 23.61,14 C24.1900029,14 24.6699981,14.1899981 25.05,14.57 C25.4300019,14.9500019 25.62,15.4099973 25.62,15.95 Z"
        fill="#878787"
      />
    </g>
  </svg>
)
