import { connect } from 'react-redux'
import { Department } from './Department'
import { getViewsReferenceData } from 'src/selectors/referenceData/views'
import { AppState } from 'src/reducers/root'

const mapStateToProps = (state: AppState) => ({
  viewsReferenceData: getViewsReferenceData(state),
})

export const DepartmentContainer = connect(mapStateToProps)(Department)
