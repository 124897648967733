import React from 'react'
import ReactDOM from 'react-dom'
import Hierarchy from '../Hierarchy'
import Drawer from '@material-ui/core/Drawer'
import { drawerContainer, overlay } from './HierarchyDrawer.scss'
import { GridType, TreeHierarchyItem } from 'src/types/index'

type Props = {
  onNodeSelected: (node: TreeHierarchyItem) => void
  onClose?: () => void
  gridName: GridType
  nodeIsSelectable?: (node: TreeHierarchyItem) => boolean
}

const everyNodeSelectable = (): boolean => true

export const HierarchyDrawer = (props: Props): JSX.Element => {
  return (
    <Drawer
      open
      onClose={props.onClose}
      classes={{
        root: drawerContainer,
        paper: overlay,
      }}
    >
      <Hierarchy
        onClose={props.onClose}
        onNodeSelected={props.onNodeSelected}
        gridName={props.gridName}
        nodeIsSelectable={props.nodeIsSelectable || everyNodeSelectable}
      />
    </Drawer>
  )
}

export default (props: Props): React.ReactPortal => {
  const appRoot: HTMLBodyElement = document.querySelector('body') as HTMLBodyElement

  return ReactDOM.createPortal(<HierarchyDrawer {...props} />, appRoot)
}
