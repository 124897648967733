import React from 'react'
import { LINKS, Product } from 'src/types/index'
import { developmentIdCell } from './DevelopmentIdCellRenderer.scss'
import { DevelopmentIdCell } from './DevelopmentIdCell/DevelopmentIdCell'
import SidePanelDrawerContainer from '../../SidePanelDrawer/SidePanelDrawerContainer'
import { GridType } from 'src/types/index'

type Props = {
  product: Product
  rowIndex: number
  editMode?: boolean
  exitEditMode?: () => void
  gridName: GridType
}

const hasMatchingProductLink = ({ groups }: Product): boolean => !!groups.matchingProductGroupSlug
const hasCrossDepartmentLink = ({ groups }: Product): boolean => !!groups.crossDepartmentGroupSlug

export const DevelopmentIdCellRenderer = ({
  product,
  rowIndex,
  editMode,
  exitEditMode,
  gridName,
}: Props): JSX.Element => (
  <div className={developmentIdCell}>
    <DevelopmentIdCell
      isMarkdown={product.markdown}
      developmentId={product.developmentId}
      commentCount={product.commentCount}
      hasMatchingProductLink={hasMatchingProductLink(product)}
      hasCrossDepartmentLink={hasCrossDepartmentLink(product)}
      hasSupplierLink={product.hasSupplierLink}
    />
    {editMode && (
      <SidePanelDrawerContainer
        rowIndex={rowIndex}
        open={editMode}
        exitEditMode={exitEditMode}
        openingTab={LINKS}
        gridName={gridName}
      />
    )}
  </div>
)
