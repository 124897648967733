// @ts-nocheck
import React from 'react'
import classNames from 'classnames'
import { IntentionalAny, Product } from 'src/types/index'
import { MultiDropdown } from '../../Dropdown/MultiDropdown/MultiDropdown'
import { readOnly, deprecatedValue } from './MultiDropdownCellRenderer.scss'
import { DropdownOption } from '../../../types/Dropdowns'
import { PatchPayload } from '../../../actions/grid/product/update'
import { SELLING_PERIOD } from 'src/constants/attributes'

type Props = {
  product: Product
  accessor: string
  editMode: boolean
  updateProduct: (payload: PatchPayload) => void
  dropdownItems: DropdownOption[]
  mandatory: boolean
  exitEditMode: (exit: boolean) => void
}

const getSelectedOption = (values: any, dropdownOptions: DropdownOption[]): IntentionalAny =>
  values.map((value: any) => {
    const option = dropdownOptions.find((option) => option.value === value)
    return {
      label: value,
      value: option?.slug as string,
      deprecated: option?.deprecated as boolean,
    }
  })

const getOptions = ({ slug, value }: DropdownOption): DropdownOption => ({
  value: slug as string,
  label: value as string,
})

const isDeprecated = (dropdownItems: DropdownOption[], value: string): boolean => {
  const dropdownItem = dropdownItems.find((item) => item.value === value)

  return dropdownItem ? (dropdownItem.deprecated as boolean) : true
}

const payloadContainsDeprecatedValues = (
  payload: { label: string; value: string }[],
  dropdownItems: DropdownOption[],
): boolean => {
  return payload.some(function (element) {
    return isDeprecated(dropdownItems, element.label)
  })
}

const updateNotNeeded = (prev, cur, items): boolean => {
  if (prev === cur) {
    return true
  }
  return payloadContainsDeprecatedValues(cur, items)
}

export const MultiDropdownCellRenderer = ({
  product,
  accessor,
  editMode,
  dropdownItems,
  updateProduct,
  mandatory,
  exitEditMode,
}: Props): JSX.Element => {
  const values = product[accessor] || []

  const update = (payload: any[], isValid: any, previousPayload: any): void => {
    if (updateNotNeeded(previousPayload, payload, dropdownItems)) {
      exitEditMode(true)
      return
    }

    if (isValid) {
      updateProduct({
        developmentId: product.developmentId,
        productSlug: product.slug,
        patchFields: {
          api: {
            [accessor]: payload.map(({ value }) => value),
          },
          store: {
            [accessor]: payload.map(({ label }) => label),
          },
        },
      })
    }
    exitEditMode(true)
  }

  return editMode ? (
    <MultiDropdown
      name={accessor}
      options={dropdownItems.map(getOptions)}
      selectedOptions={getSelectedOption(values, dropdownItems)}
      mandatory={mandatory}
      onSave={update}
      sort={accessor !== SELLING_PERIOD}
      hasFocus
    />
  ) : (
    <div className={readOnly}>
      {values.map((value: any, index: number) => (
        <span
          key={index}
          className={classNames({
            [deprecatedValue]: isDeprecated(dropdownItems, value),
          })}
        >
          {(index ? ', ' : '') + value}
        </span>
      ))}
    </div>
  )
}
