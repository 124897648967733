import React, { useCallback } from 'react'
import { DropResult, DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DraggableIcon } from './DraggableIcon'
import { DraggableListItem } from 'src/types/Lists'

import { container, draggableIcon, draggableList } from './DraggableList.scss'

type Props = {
  list: DraggableListItem[]
  onDrop: (items: DraggableListItem[]) => void
}

export const DraggableList = (props: Props) => {
  const onDropEnd = useCallback(
    (result: DropResult) => {
      const reorderedList = [...props.list]
      const [removed] = reorderedList.splice(result.source.index, 1)
      // @ts-ignore
      reorderedList.splice(result.destination.index, 0, removed)

      props.onDrop(reorderedList)
    },
    [props.list, props.onDrop],
  )

  return (
    <div data-cy="draggable-list" className={container}>
      <DragDropContext onDragEnd={onDropEnd}>
        <Droppable droppableId="columnsList">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className={draggableList}>
              {props.list.map((item, index) => (
                <Draggable key={item.elementKey} draggableId={item.elementKey} index={index}>
                  {(provided) => (
                    <li
                      data-cy="draggable-list-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DraggableIcon className={draggableIcon} />
                      {item.elementName}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
