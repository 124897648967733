import {
  FETCH_PRODUCT_LINKS,
  FETCH_PRODUCT_LINKS_SUCCESS,
  REMOVE_CROSS_DEPARTMENT_PRODUCT,
  REMOVE_MATCHING_PRODUCT,
} from 'src/actions/grid/links'
import { AppState } from 'src/reducers/root'
import { UPDATE_PRODUCT_SUCCESS } from 'src/actions/grid/product/update'
import { FETCHING_STATUS, READY_STATUS, UNINITIALISED_STATUS } from 'src/constants/requests'
import { LinkMember, ProductLinksWithStatus } from 'src/types/Links'
import { SVPAction } from 'actions/svp'

export type ProductLinksState = {
  [key: string]: ProductLinksWithStatus
}

const defaultLinks: ProductLinksWithStatus = {
  crossDepartmentProducts: [],
  matchingProducts: [],
  status: UNINITIALISED_STATUS,
}

const updateProductLink = (productSlug: string, images: any) => (productLink: LinkMember) =>
  productLink.productSlug === productSlug ? { ...productLink, images } : productLink

const updateProductLinks = (
  productLinks: ProductLinksWithStatus,
  productSlug: string,
  images: any,
) => ({
  ...productLinks,
  matchingProducts: productLinks.matchingProducts.map(updateProductLink(productSlug, images)),
  crossDepartmentProducts: productLinks.crossDepartmentProducts.map(
    updateProductLink(productSlug, images),
  ),
})

export const getLinksForSlug = (state: AppState, slug: string) => {
  const productLinks = state.injectorUI.sidePanel.productLinks
  return productLinks[slug] ? productLinks[slug] : defaultLinks
}

export const productLinks = (state: ProductLinksState = {}, action: SVPAction) => {
  switch (action.type) {
    case FETCH_PRODUCT_LINKS_SUCCESS:
      return {
        [action.productSlug]: {
          ...action.links,
          status: READY_STATUS,
        },
      }
    case REMOVE_MATCHING_PRODUCT:
    case REMOVE_CROSS_DEPARTMENT_PRODUCT:
      return {
        ...state,
        [action.productSlug]: {
          ...state[action.productSlug],
          status: FETCHING_STATUS,
        },
      }
    case FETCH_PRODUCT_LINKS:
      return {
        ...state,
        [action.productSlug]: {
          ...defaultLinks,
          status: FETCHING_STATUS,
        },
      }
    case UPDATE_PRODUCT_SUCCESS:
      return action.patchFields.images
        ? Object.keys(state).reduce(
            (acc, key) => ({
              ...acc,
              [key]: updateProductLinks(state[key], action.productSlug, action.patchFields.images),
            }),
            {},
          )
        : state
    default:
      return state
  }
}
