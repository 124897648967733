import { AppState } from '../../reducers/root'
import {
  Attribute,
  AttributeValue,
  RequestStatus,
  ColumnValue,
  AttributeValueMap,
  AttributeWithColumnNameMap,
} from 'src/types/index'

export const getAttributeValues = (state: AppState): AttributeValueMap =>
  state.injectorUI.referenceData.attributes.values

const withoutDeprecatedValues = (values: AttributeValue[]): AttributeValue[] =>
  values && values.filter(({ deprecated }) => !deprecated)

export const getAttributeNameMap = (state: AppState): Record<string, string> => {
  const result: Record<string, string> = {}

  state.injectorUI.referenceData.columns.map((column) => {
    if (column.attributeName) {
      result[column.attributeName] = column.displayName
    }
  })

  return result
}

export const getNonDeprecatedAttributeValues = (state: AppState): AttributeValueMap => {
  const result: AttributeValueMap = {}

  Object.keys(state.injectorUI.referenceData.attributes.values).forEach((attributeName) => {
    result[attributeName] = withoutDeprecatedValues(
      state.injectorUI.referenceData.attributes.values[attributeName],
    )
  })

  return result
}

export const getAttributes = (state: AppState): Attribute[] =>
  Object.values(state.injectorUI.referenceData.attributes.list)

export const getListAttributesWithColumnName = (state: AppState): AttributeWithColumnNameMap => {
  const { list } = state.injectorUI.referenceData.attributes
  const attributesWithColumnName = {}
  state.injectorUI.referenceData.columns.forEach((column) => {
    const columnAttribute = list[column.attributeName]
    if (
      columnAttribute &&
      (columnAttribute.type === 'list' || columnAttribute.type === 'multiList')
    ) {
      attributesWithColumnName[column.attributeName] = {
        displayName: column.displayName,
        name: column.attributeName,
        slug: columnAttribute.slug,
        type: columnAttribute.type,
      }
    }
  })

  return attributesWithColumnName
}

export const getAttributesMap = (state: AppState): Record<string, Attribute> =>
  state.injectorUI.referenceData.attributes.list

export const getSupplierByCode = (state: AppState, wantedCode?: string): AttributeValue =>
  state.injectorUI.referenceData.attributes.values.supplier.find(({ code }) => code === wantedCode)

export const getAttributeSavingStatus = (state: AppState): RequestStatus =>
  state.injectorUI.referenceData.attributes.savingStatus

export const getColumns = (state: AppState): Array<ColumnValue> =>
  state.injectorUI.referenceData.columns

export const getAttributeValuesReferenceData = (
  state: AppState,
  accessor: string,
): AttributeValue[] => {
  return getAttributeValues(state)[accessor]
}
