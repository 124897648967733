import {
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATION,
  NotificationActions,
} from 'src/actions/notification'
import { AppState } from '../root'

export type NotificationState = {
  type: string
  message: string
}

const initialState = {
  type: '',
  message: '',
}

export const getNotification = (state: AppState) => state.injectorUI.notification

export default (state: NotificationState = initialState, action: NotificationActions) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return action.payload
    case CLEAR_NOTIFICATION:
      return null
    default:
      return state
  }
}
