import { AttributeValue, AttributeValues, CellType, IntentionalAny, Product } from 'src/types/index'
import {
  isEmptyCurrency,
  isEmptyImage,
  isEmptyList,
  isEmptyNumber,
  isEmptyString,
  isEmptyValue,
  isEmptyPrice,
  neverEmpty,
} from '../service/product/validateUtils'
import {
  booleanApiToStateMapper,
  currencyApiToStateMapper,
  defaultApiToStateMapper,
  listApiToStateMapper,
  multiListApiToStateMapper,
  nullApiToStateMapper,
} from '../service/mappers/attributeValueMappers'
import {
  booleanExcelMapper,
  countryCodeExcelMapper,
  currencyExcelMapper,
  dateExcelMapper,
  defaultExcelMapper,
  hierarchyExcelMapper,
  imagesExcelMapper,
  listExcelMapper,
  noMapping,
  numericExcelMapper,
  productNumberExcelMapper,
  shiftedNumericExcelMapper,
  supplierExcelMapper,
  sizesExcelMapper,
  wsReadyWeekExcelMapper,
  sizeGroupPriceExcelMapper,
  styleIdExcelMapper,
} from '../service/excel/exporter/valueMappers'
import { LookupHierarchyState } from '../reducers/referenceData/hierarchy/lookupTable'
import { AppState } from '../reducers/root'
import { getAttributeValuesReferenceData } from '../selectors/referenceData/attributes'
import {
  attributeValuesMapper,
  booleanMapper,
  countriesMapper,
  seasonsMapper,
  statusMapper,
  suppliersMapper,
  userNameMapper,
} from '../service/mappers/dropdownItems'
import { getCountries } from '../selectors/referenceData/countries'
import { getSeasons } from '../selectors/referenceData/seasons'
import { getSuppliers } from '../selectors/referenceData/suppliers'
import {
  LIST_CELL,
  MULTI_LIST_CELL,
  TEXT_CELL,
  LONG_TEXT_CELL,
  DATE_CELL,
  BOOLEAN_CELL,
  CURRENCY_CELL,
  ACTIONS_CELL,
  DEVELOPMENT_ID_CELL,
  PRODUCT_NUMBER_CELL,
  HIERARCHY_SLUG_CELL,
  IMAGES_CELL,
  STATUS_CELL,
  SUPPLIER_CELL,
  USERNAME_CELL,
  SELLING_PRICE_CELL,
  CURRENT_PULL_PRICE_CELL,
  PROMO_PRICE_CELL,
  COUNTRY_CODE_CELL,
  SEASON_CODE_CELL,
  ERROR_CELL,
  NO_TYPE_CELL,
  SIZE_CELL,
  WS_READY_WEEK,
  SUPPLIER_COST_CELL,
  LANDED_COST_CELL,
  SELLING_PRICE_VIEW_CELL,
  PROMO_PRICE_VIEW_CELL,
  CURRENT_PULL_PRICE_VIEW_CELL,
  STYLE_ID_CELL,
  DATE_TODAY_CELL,
} from '../constants/attributes'

export type ExcelMapperContext = {
  product: Product
  hierarchyLookupTable: LookupHierarchyState
}

export type MapperValue = {
  value: string | boolean
  label: string
  deprecated?: boolean
}

export type StrategyRegistryEntry = {
  type: CellType
  isEmpty: (apiValue: IntentionalAny) => boolean
  apiToStateMapper: (
    apiValue: IntentionalAny,
    apiName: string,
    attributeValues: AttributeValues,
  ) => IntentionalAny
  stateToExcelMapper: (
    apiValue: IntentionalAny,
    context: ExcelMapperContext,
    property: string,
  ) => string
  referenceDataSelector: (state: AppState, accessor?: string) => AttributeValue[] | null
  apiIdentifiersToDropdownItemsMapper: (
    identifiers: string[],
    referenceData: IntentionalAny,
  ) => MapperValue[]
}

const listCellRegistryEntry = {
  type: LIST_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: listApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: getAttributeValuesReferenceData,
  apiIdentifiersToDropdownItemsMapper: attributeValuesMapper,
}

const multiListCellRegistryEntry = {
  type: MULTI_LIST_CELL,
  isEmpty: isEmptyList,
  apiToStateMapper: multiListApiToStateMapper,
  stateToExcelMapper: listExcelMapper,
  referenceDataSelector: getAttributeValuesReferenceData,
  apiIdentifiersToDropdownItemsMapper: attributeValuesMapper,
}

const textCellRegistryEntry = {
  type: TEXT_CELL,
  isEmpty: isEmptyString,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const longTextCellRegistryEntry = {
  type: LONG_TEXT_CELL,
  isEmpty: isEmptyString,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const dateCellRegistryEntry = {
  type: DATE_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: dateExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const booleanCellRegistryEntry = {
  type: BOOLEAN_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: booleanApiToStateMapper,
  stateToExcelMapper: booleanExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: booleanMapper,
}

const currencyCellRegistryEntry = {
  type: CURRENCY_CELL,
  isEmpty: isEmptyCurrency,
  apiToStateMapper: currencyApiToStateMapper,
  stateToExcelMapper: currencyExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const actionsCellRegistryEntry = {
  type: ACTIONS_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: noMapping,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const developmentIdCellRegistryEntry = {
  type: DEVELOPMENT_ID_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const styleIdCellRegistryEntry = {
  type: STYLE_ID_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: styleIdExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const productNumberCellRegistryEntry = {
  type: PRODUCT_NUMBER_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: productNumberExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const hierarchySlugCellRegistryEntry = {
  type: HIERARCHY_SLUG_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: hierarchyExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const imagesCellRegistryEntry = {
  type: IMAGES_CELL,
  isEmpty: isEmptyImage,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: imagesExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const statusCellRegistryEntry = {
  type: STATUS_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: statusMapper,
}

const supplierCellRegistryEntry = {
  type: SUPPLIER_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: supplierExcelMapper,
  referenceDataSelector: getSuppliers,
  apiIdentifiersToDropdownItemsMapper: suppliersMapper,
}

const userNameCellRegistryEntry = {
  type: USERNAME_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: userNameMapper,
}

const sellingPriceCellRegistryEntry = {
  type: SELLING_PRICE_CELL,
  isEmpty: isEmptyNumber,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: shiftedNumericExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const currentFullPriceCellRegistryEntry = {
  type: CURRENT_PULL_PRICE_CELL,
  isEmpty: isEmptyNumber,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: numericExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const promoPriceCellRegistryEntry = {
  type: PROMO_PRICE_CELL,
  isEmpty: isEmptyNumber,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: numericExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const countryCodeCellRegistryEntry = {
  type: COUNTRY_CODE_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: countryCodeExcelMapper,
  referenceDataSelector: getCountries,
  apiIdentifiersToDropdownItemsMapper: countriesMapper,
}

const seasonCellRegistryEntry = {
  type: SEASON_CODE_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: defaultExcelMapper,
  referenceDataSelector: getSeasons,
  apiIdentifiersToDropdownItemsMapper: seasonsMapper,
}

const sizeCellRegistryEntry = {
  type: SIZE_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: sizesExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const nullCellRegistryEntry = {
  type: ERROR_CELL,
  isEmpty: neverEmpty,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: noMapping,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const wsReadyWeekRegistryEntry = {
  type: WS_READY_WEEK,
  isEmpty: isEmptyValue,
  apiToStateMapper: nullApiToStateMapper,
  stateToExcelMapper: wsReadyWeekExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const supplierCostCellRegistryEntry = {
  type: SUPPLIER_COST_CELL,
  isEmpty: isEmptyPrice,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: sizeGroupPriceExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const landedCostRegistryEntry = {
  type: LANDED_COST_CELL,
  isEmpty: isEmptyPrice,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: sizeGroupPriceExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const sellingPriceViewRegistryEntry = {
  type: SUPPLIER_COST_CELL,
  isEmpty: isEmptyPrice,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: sizeGroupPriceExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const promoPriceViewRegistryEntry = {
  type: SUPPLIER_COST_CELL,
  isEmpty: isEmptyPrice,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: sizeGroupPriceExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const currentFullPriceViewRegistryEntry = {
  type: SUPPLIER_COST_CELL,
  isEmpty: isEmptyPrice,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: sizeGroupPriceExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

const dateTodayRegistryEntry = {
  type: DATE_TODAY_CELL,
  isEmpty: isEmptyValue,
  apiToStateMapper: defaultApiToStateMapper,
  stateToExcelMapper: dateExcelMapper,
  referenceDataSelector: (): null => null,
  apiIdentifiersToDropdownItemsMapper: (): [] => [],
}

export const strategyRegistryLookup = (type: CellType): StrategyRegistryEntry => {
  const dynabuteCellRegistry = {
    [LIST_CELL]: listCellRegistryEntry,
    [MULTI_LIST_CELL]: multiListCellRegistryEntry,
    [TEXT_CELL]: textCellRegistryEntry,
    [LONG_TEXT_CELL]: longTextCellRegistryEntry,
    [DATE_CELL]: dateCellRegistryEntry,
    [BOOLEAN_CELL]: booleanCellRegistryEntry,
    [CURRENCY_CELL]: currencyCellRegistryEntry,
    [DATE_TODAY_CELL]: dateTodayRegistryEntry,
  }

  const entityCellRegistry = {
    [ACTIONS_CELL]: actionsCellRegistryEntry,
    [DEVELOPMENT_ID_CELL]: developmentIdCellRegistryEntry,
    [STYLE_ID_CELL]: styleIdCellRegistryEntry,
    [PRODUCT_NUMBER_CELL]: productNumberCellRegistryEntry,
    [HIERARCHY_SLUG_CELL]: hierarchySlugCellRegistryEntry,
    [IMAGES_CELL]: imagesCellRegistryEntry,
    [STATUS_CELL]: statusCellRegistryEntry,
    [SUPPLIER_CELL]: supplierCellRegistryEntry,
    [USERNAME_CELL]: userNameCellRegistryEntry,
    [SELLING_PRICE_CELL]: sellingPriceCellRegistryEntry,
    [CURRENT_PULL_PRICE_CELL]: currentFullPriceCellRegistryEntry,
    [PROMO_PRICE_CELL]: promoPriceCellRegistryEntry,
    [COUNTRY_CODE_CELL]: countryCodeCellRegistryEntry,
    [SEASON_CODE_CELL]: seasonCellRegistryEntry,
    [SIZE_CELL]: sizeCellRegistryEntry,
    [WS_READY_WEEK]: wsReadyWeekRegistryEntry,
    [SUPPLIER_COST_CELL]: supplierCostCellRegistryEntry,
    [LANDED_COST_CELL]: landedCostRegistryEntry,
    [SELLING_PRICE_VIEW_CELL]: sellingPriceViewRegistryEntry,
    [PROMO_PRICE_VIEW_CELL]: promoPriceViewRegistryEntry,
    [CURRENT_PULL_PRICE_VIEW_CELL]: currentFullPriceViewRegistryEntry,
  }

  const errorCellRegistry = {
    [ERROR_CELL]: nullCellRegistryEntry,
    [NO_TYPE_CELL]: nullCellRegistryEntry,
  }

  const completeRegistry = {
    ...dynabuteCellRegistry,
    ...entityCellRegistry,
    ...errorCellRegistry,
  }

  // @ts-ignore
  return completeRegistry[type] || nullCellRegistryEntry
}
