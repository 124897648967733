import React from 'react'

interface Props {
  className?: string
  width?: string
  height?: string
}

export const ImagePlaceholder = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect width="160" height="160" fill="#E5E5E5" />
    <g id="RiverIsland_UploadImage_Issue">
      <rect width="1963" height="1876" transform="translate(-152 -191)" fill="white" />
      <rect id="image 24" x="-101" y="-88" width="561" height="763" fill="url(#pattern0)" />
      <rect id="Rectangle 581" width="160" height="160" rx="8" fill="white" />
      <g id="Group">
        <path
          id="Vector"
          d="M97.5307 61H61.6123C60.1697 61 59 62.1696 59 63.6122V90.3879C59 91.8304 60.1697 93 61.6123 93H97.5307C98.9734 93 100.143 91.8304 100.143 90.3878V63.6122C100.143 62.1696 98.9734 61 97.5307 61ZM97.5307 63.6122V82.6104L92.3826 77.9299C91.6088 77.2266 90.4189 77.2547 89.6796 77.9946L84.4693 83.204L74.1962 70.933C73.4196 70.0057 71.9972 69.9965 71.209 70.9128L61.6123 82.0658V63.6122H97.5307ZM86.4287 70.4694C86.4287 68.4854 88.0364 66.8775 90.0204 66.8775C92.0045 66.8775 93.6123 68.4854 93.6123 70.4694C93.6123 72.4534 92.0046 74.0612 90.0204 74.0612C88.0365 74.0612 86.4287 72.4534 86.4287 70.4694Z"
          fill="#9F9F9F"
        />
      </g>
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0" transform="translate(-0.688937) scale(0.00900709 0.00662252)" />
      </pattern>
      <image
        id="image0"
        data-name="image.png"
        width="264"
        height="151"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAACXCAYAAADpscXdAAAEoUlEQVR4Ae3WsQ0AIAwEMfbfNhOA6HMbuKBJiazTn5m5nj9ggIHNwNmObrAwwMA3IBAWlAXJQBoQCDgShxVhRQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKQBgYAjcVgQFoRACIRAMJAGBAKOxGFBWBACIRACwUAaEAg4EocFYUEIhEAIBANpQCDgSBwWhAUhEAIhEAykAYGAI3FYEBaEQAiEQDCQBgQCjsRhQVgQAiEQAsFAGhAIOBKHBWFBCIRACAQDaUAg4EgcFoQFIRACIRAMpAGBgCNxWBAWhEAIhEAwkAYEAo7EYUFYEAIhEALBQBoQCDgShwVhQQiEQAgEA2lAIOBIHBaEBSEQAiEQDKSBB3jnB86YNVSWAAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
)
