import {
  CLOSE_STYLE_MODAL,
  OPEN_STYLE_MODAL,
  SET_DEPARTMENT,
  SET_PRODUCT_PRINT,
  SET_STYLE_DESCRIPTION,
  SET_STYLE_GROUP,
  SET_STYLE_ID,
  SET_STYLE_NAME,
  SET_VIEW_RESULTS,
} from 'actions/grid/modal/styleModal'
import {
  CONFIRM_PRODUCT_STYLE_REQUEST,
  SEARCH_STYLE_REQUEST,
  SEARCH_STYLE_SUCCESS,
} from 'actions/grid/style'
import { SHOW_NOTIFICATION } from 'actions/notification'
import { SVPAction } from 'actions/svp'
import { DropdownOption } from 'types/Dropdowns'
import { Product } from 'types/Product'
import { StyleResult } from 'types/Style'

export interface StyleModalState {
  open: boolean
  hierarchySlug: string
  styleResults: StyleResult[]
  viewResults: boolean
  department: DropdownOption
  styleGroup: DropdownOption
  styleDescription: string
  styleID: string
  styleName: string
  productPrint: DropdownOption
  isSearchStyleLoading: boolean
  isConfirmStyleRequesting: boolean
  product?: Product
}

const defaultStyleModalState: StyleModalState = {
  open: false,
  isSearchStyleLoading: false,
  isConfirmStyleRequesting: false,
  hierarchySlug: '',
  styleResults: [],
  viewResults: false,
  department: {
    value: '',
    label: '',
  },
  styleGroup: {
    value: '',
    label: '',
  },
  productPrint: {
    value: '',
    label: '',
  },
  styleID: '',
  styleName: '',
  styleDescription: '',
}

export default (
  state: StyleModalState = defaultStyleModalState,
  action: SVPAction,
): StyleModalState => {
  switch (action.type) {
    case CONFIRM_PRODUCT_STYLE_REQUEST:
      return {
        ...state,
        isConfirmStyleRequesting: true,
      }
    case SEARCH_STYLE_REQUEST:
      return {
        ...state,
        isSearchStyleLoading: true,
      }
    case OPEN_STYLE_MODAL:
      return {
        ...state,
        open: true,
        product: action.product,
        hierarchySlug: action.hierarchySlug,
      }
    case CLOSE_STYLE_MODAL:
      return {
        ...state,
        ...defaultStyleModalState,
      }
    case SEARCH_STYLE_SUCCESS:
      return {
        ...state,
        styleResults: action.results,
        isSearchStyleLoading: false,
      }
    case SET_VIEW_RESULTS:
      return {
        ...state,
        viewResults: action.viewResults,
      }
    case SET_STYLE_DESCRIPTION:
      return {
        ...state,
        styleDescription: action.value,
      }
    case SET_STYLE_ID:
      return {
        ...state,
        styleID: action.value,
      }
    case SET_STYLE_NAME:
      return {
        ...state,
        styleName: action.value,
      }
    case SET_STYLE_GROUP:
      return {
        ...state,
        styleGroup: action.option,
      }
    case SET_DEPARTMENT:
      return {
        ...state,
        department: action.option,
      }
    case SET_PRODUCT_PRINT:
      return {
        ...state,
        productPrint: action.option,
      }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        isConfirmStyleRequesting: false,
      }
    default:
      return state
  }
}
