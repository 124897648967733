import restClient, { Header } from 'src/service/restClient/restClient'
import getAuthHeader from '../auth/headers'
import { getBaseInjectorApiUrl, getBaseInjectorApiV2Url, getBaseUrl } from './url'
import * as middleware from './middleware/root'
import { addBreadcrumb } from '../../helpers/sentry'
import { ResponseType } from 'axios'

interface RequestOptions {
  v2Endpoint?: boolean
  rootEndpoint?: boolean
  headers?: Header
  responseType?: ResponseType
}

async function getHeader(additionalHeaders: Header = {}) {
  const header = await getAuthHeader()

  if (!header) {
    throw new Error('No authentication header')
  }

  return {
    ...additionalHeaders,
    ...header,
  }
}

function getUrl(path: string, opts?: RequestOptions) {
  if (opts && opts.rootEndpoint) {
    return `${getBaseUrl()}/${path}`
  }

  return opts && opts.v2Endpoint
    ? `${getBaseInjectorApiV2Url()}/${path}`
    : `${getBaseInjectorApiUrl()}/${path}`
}

export const get = async (path: string, params?: Record<string, any>, opts?: RequestOptions) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing GET request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.get(url, params, header, opts?.responseType)

  middleware.execute(result)

  return result.data
}

export const getWithHeaders = async (
  path: string,
  params?: Record<string, any>,
  opts?: RequestOptions,
) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing GET (With headers) request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.getWithHeaders(url, params, header, opts?.responseType)

  middleware.execute(result)

  return result
}

export const getWithoutAuth = async (
  path: string,
  params?: Record<string, any>,
  opts?: RequestOptions,
) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing GET (Without auth) request to ${url}`)
  const result = await restClient.get(url, params)

  middleware.execute(result)

  return result
}

export const post = async (path: string, data?: any, opts?: RequestOptions) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing POST request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.post(url, data, header)

  middleware.execute(result)

  return result.data
}

export const patch = async (path: string, data?: any, opts?: RequestOptions) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing PATCH request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.patch(url, data, header)

  middleware.execute(result)

  return result.data
}

export const put = async (path: string, data?: any, opts?: RequestOptions) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing PUT request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.put(url, data, header)

  middleware.execute(result)

  return result.data
}

export const doDelete = async (path: string, opts?: RequestOptions) => {
  const url = getUrl(path, opts)

  addBreadcrumb('request', `preparing DELETE request to ${url}`)
  const header = await getHeader(opts?.headers)

  const result = await restClient.delete(url, header)

  middleware.execute(result)

  return result.data
}

export const injectorService = {
  get,
  getWithHeaders,
  getWithoutAuth,
  post,
  patch,
  put,
  delete: doDelete,
}
