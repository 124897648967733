import React from 'react'

interface Props {
  className?: string
  title?: string
  color?: string
}

export const CloseCross = ({ className, title, color = '#505050' }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 863.02 853.81">
    <title>{`${title || 'close'}`}</title>
    <line
      x1="304.27"
      y1="301.2"
      x2="558.73"
      y2="555.65"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
    <line
      x1="304.27"
      y1="555.65"
      x2="558.73"
      y2="301.2"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="50"
    />
  </svg>
)
