import { IntentionalAny } from 'types'

type HTMLInputs = HTMLInputElement | null | undefined
type HTMLButtons = HTMLButtonElement | null | undefined
type HTMLList = HTMLLIElement | null | undefined
type HTMLDiv = HTMLDivElement | null | undefined
type HTMLElements = HTMLInputs | HTMLButtons | HTMLList | HTMLDiv

export const setFocus = (ref?: HTMLElements): void => ref && ref.focus()

export const setSelected = (ref: HTMLInputs): void => ref && ref.select()

export const addEventListener = (fn: IntentionalAny, type: string, ref?: HTMLElements): void =>
  fn && ref && ref.addEventListener(type, fn)

export const removeEventListener = (fn: IntentionalAny, type: string, ref?: HTMLElements): void =>
  fn && ref && ref.removeEventListener(type, fn)

export const scrollTo = (ref: HTMLElements, scrollToTop: boolean): number | undefined => {
  if (!ref) {
    return
  }

  ref.scrollTop = scrollToTop ? 0 : ref.scrollHeight
}

export const copyToClipboard = (str: string): void => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
