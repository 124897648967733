import {
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  createAttributeSuccess,
  createAttributeFailure,
  updateAttributeSuccess,
  updateAttributeFailure,
} from 'src/actions/referenceData/attributes'
import { call, put, takeEvery, all } from 'redux-saga/effects'
import { injectorService } from 'src/service/injector/injectorService'
import { errorAndNotification } from './error'
import { fetchReferenceData } from 'src/actions/referenceData/referenceData'
import { APIError } from 'src/types/Api'
import { trackEvent } from 'src/service/analytics/analytics'
import { SVPAction } from 'actions/svp'
import { SVPSaga } from 'sagas'

export function* createAttribute(action: SVPAction<typeof CREATE_ATTRIBUTE>): SVPSaga {
  trackEvent('admin', 'Attribute Created')
  try {
    yield call(injectorService.post, 'attributes', action.payload)
    yield put(createAttributeSuccess())
    yield put(fetchReferenceData())
  } catch (error) {
    yield put(createAttributeFailure())
    yield* errorAndNotification(
      error as Error,
      'Sorry your attribute has failed to save. Please retry',
    )
  }
}

export function* updateAttribute(action: SVPAction<typeof UPDATE_ATTRIBUTE>): SVPSaga {
  trackEvent('admin', 'Attribute Updated')

  try {
    const apiPayload = {
      duplicable: action.payload.duplicable,
      newAttributeValues: action.payload.newAttributeValues,
      existingAttributeValues: action.payload.existingAttributeValues.map((value) => {
        return {
          name: value.value,
          deprecated: value.deprecated,
          slug: value.slug,
        }
      }),
    }

    yield call(injectorService.patch, `attributes/${action.slug}`, apiPayload)

    if (!action.payload.columnSlug) {
      const err = 'column slug not found'
      yield call(errorAndNotification, err, err)
      return
    }

    const columnsPayload = {
      sortable: action.payload.sortable,
      width: action.payload.width,
      name: action.payload.name,
    }

    const atLeastOneChanged =
      columnsPayload.sortable !== undefined ||
      columnsPayload.width !== undefined ||
      columnsPayload.name !== undefined

    if (atLeastOneChanged) {
      yield call(injectorService.patch, `columns/${action.payload.columnSlug}`, columnsPayload)
    }

    yield put(updateAttributeSuccess())
    yield put(fetchReferenceData())
  } catch (error) {
    yield put(updateAttributeFailure())
    const apiError = error as APIError
    if (isPartialOrdsFailure(apiError)) {
      yield* errorAndNotification(apiError, apiError?.response?.data?.error?.message || '')
    } else {
      yield* errorAndNotification(apiError, 'Attributes cannot be saved. Please try again later.')
    }
  }
}

const isPartialOrdsFailure = (error: APIError): boolean => error?.response?.data?.error?.code === 15

export default function* rootSaga(): SVPSaga {
  yield all([
    takeEvery(CREATE_ATTRIBUTE, createAttribute),
    takeEvery(UPDATE_ATTRIBUTE, updateAttribute),
  ])
}
