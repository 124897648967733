import { SVPAction } from 'actions/svp'
import { RequestStatus } from 'src/types/index'
import {
  FETCH_REFERENCE_DATA,
  FETCH_REFERENCE_DATA_SUCCESS,
} from '../../actions/referenceData/referenceData'

export const status = (
  state: RequestStatus = 'uninitialised',
  action: SVPAction,
): RequestStatus => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA:
      return 'fetching'
    case FETCH_REFERENCE_DATA_SUCCESS:
      return 'ready'
    default:
      return state
  }
}
