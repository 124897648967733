import { SVPAction } from 'actions/svp'
import {
  CLEAR_CLIPBOARD_GRID_DESTINATION,
  CLEAR_CLIPBOARD_GRID_SOURCE,
  SET_CLIPBOARD_GRID_DESTINATION,
  SET_CLIPBOARD_GRID_SOURCE,
} from 'actions/clipboard'
import { ClipboardGridDestination, ClipboardGridSource } from 'types/Clipboard'

export type ClipboardState = {
  grid: {
    source: ClipboardGridSource
    destination: ClipboardGridDestination
  }
}

const defaultState: ClipboardState = {
  grid: {
    source: {
      product: null,
      property: '',
    },
    destination: {
      productSlug: '',
      property: '',
    },
  },
}

export const clipboard = (
  state: ClipboardState = defaultState,
  action: SVPAction,
): ClipboardState => {
  switch (action.type) {
    case SET_CLIPBOARD_GRID_SOURCE:
      return {
        ...state,
        grid: {
          ...state.grid,
          source: {
            product: action.product,
            property: action.property,
          },
        },
      }
    case CLEAR_CLIPBOARD_GRID_SOURCE:
      return {
        ...state,
        grid: {
          ...state.grid,
          source: { ...defaultState.grid.source },
        },
      }
    case SET_CLIPBOARD_GRID_DESTINATION:
      return {
        ...state,
        grid: {
          ...state.grid,
          destination: {
            productSlug: action.productSlug,
            property: action.property,
          },
        },
      }
    case CLEAR_CLIPBOARD_GRID_DESTINATION:
      return {
        ...state,
        grid: {
          ...state.grid,
          destination: { ...defaultState.grid.destination },
        },
      }
    default:
      return state
  }
}
