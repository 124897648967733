import React from 'react'

export type TableHeadContextType = {
  isActive: boolean
  setActive: (active: boolean) => void
}

export const TableHeadContext = React.createContext({
  isActive: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActive: (_active: boolean) => {
    // noop
  },
})
