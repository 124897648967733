export const UPDATE_COUNTRY = 'UPDATE_COUNTRY'
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS'
export const UPDATE_COUNTRY_ERROR = 'UPDATE_COUNTRY_ERROR'

export type UpdateCountryActions = ReturnType<
  typeof updateCountry | typeof updateCountrySuccess | typeof updateCountryError
>
export type CountryPayload = {
  deprecated?: boolean
  enabled?: boolean
}

export const updateCountry = (code: string, payload: CountryPayload) =>
  <const>{
    type: UPDATE_COUNTRY,
    code,
    payload,
  }

export const updateCountrySuccess = (code: string, payload: CountryPayload) =>
  <const>{
    type: UPDATE_COUNTRY_SUCCESS,
    code,
    payload,
  }

export const updateCountryError = () =>
  <const>{
    type: UPDATE_COUNTRY_ERROR,
  }
