export const LIST_CELL = 'list'
export const MULTI_LIST_CELL = 'multiList'
export const TEXT_CELL = 'text'
export const LONG_TEXT_CELL = 'longText'
export const DATE_CELL = 'date'
export const BOOLEAN_CELL = 'boolean'
export const CURRENCY_CELL = 'currency'
export const ERROR_CELL = 'error'
export const ACTIONS_CELL = 'actions'
export const DEVELOPMENT_ID_CELL = 'developmentId'
export const STYLE_ID_CELL = 'styleID'
export const PRODUCT_NUMBER_CELL = 'productNumber'
export const HIERARCHY_SLUG_CELL = 'hierarchySlug'
export const IMAGES_CELL = 'images'
export const STATUS_CELL = 'status'
export const SUPPLIER_CELL = 'supplier'
export const USERNAME_CELL = 'userName'
export const SELLING_PRICE_CELL = 'sellingPrice'
export const CURRENT_PULL_PRICE_CELL = 'currentFullPrice'
export const PROMO_PRICE_CELL = 'promoPrice'
export const COUNTRY_CODE_CELL = 'countryCode'
export const SEASON_CODE_CELL = 'seasonCode'
export const NO_TYPE_CELL = 'noType'
export const SIZE_CELL = 'sizes'
export const SUPPLIER_COST_CELL = 'supplierCostView'
export const LANDED_COST_CELL = 'landedCostView'
export const SELLING_PRICE_VIEW_CELL = 'sellingPriceView'
export const PROMO_PRICE_VIEW_CELL = 'promoPriceView'
export const CURRENT_PULL_PRICE_VIEW_CELL = 'currentFullPriceView'
export const ODBMS_ATTRIBUTE_SOURCE = 'odbms'
export const INJECTOR_ATTRIBUTE_SOURCE = 'injector'
export const PRODUCT_NUMBER_VIEW_CELL = 'productNumberView'
export const COMPANY_WIDE_DISPLAY_NAME = 'All Company'
export const COMPANY_WIDE_SLUG = 'company-wide'
export const WS_READY_WEEK = 'wsReadyWeek'
export const SELLING_PERIOD = 'sellingPeriod'
export const DATE_TODAY_CELL = 'dateToday'
