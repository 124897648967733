import React from 'react'
import classNames from 'classnames'

import { addEventListener, setFocus } from 'src/service/ref/index'
import { CheckIcon } from '../../assets/icons'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { Link } from '../Link/Link'

import {
  checkboxChecked,
  checkboxNotChecked,
  active,
  checkBoxLink,
  checkboxDisabled,
  addBorder,
  checkBoxLinkDisabled,
} from './Checkbox.scss'
import { IntentionalAny } from 'types'

type Props = {
  checked: boolean
  hasFocus?: boolean
  disabled?: boolean
  withBorder?: boolean
  onClick?: (val: boolean) => void
  onBlur?: (e: React.FocusEvent<HTMLAnchorElement>) => void
}

const green = '#41B97A'
const grey = '#EEF0EF'

export class Checkbox extends React.Component<Props> {
  inputRef: React.RefObject<HTMLInputElement> | undefined

  componentDidMount(): void {
    addEventListener(this.onEnter, 'keydown', this.inputRef?.current)
  }

  componentDidUpdate(): void {
    if (this.props.hasFocus) {
      setFocus(this.inputRef?.current)
    }
  }

  render(): JSX.Element {
    const { onBlur, checked, hasFocus, disabled, withBorder } = this.props

    const checkboxIconClass = classNames({
      [checkboxChecked]: checked,
      [checkboxNotChecked]: !checked,
      [active]: hasFocus,
      [checkboxDisabled]: disabled,
      [addBorder]: withBorder,
    })

    const checkboxLinkClass = classNames(checkBoxLink, {
      [checkBoxLinkDisabled]: disabled,
    })

    return (
      <Link
        className={checkboxLinkClass}
        onClick={this.onClick}
        setRef={(node: IntentionalAny): void => (this.inputRef = node)}
        onBlur={onBlur}
      >
        <CheckIcon className={checkboxIconClass} color={`${checked ? green : grey}`} />
      </Link>
    )
  }

  onClick = (): void => {
    const { onClick, disabled, checked } = this.props

    if (!disabled && onClick) {
      onClick(!checked)
    }
  }

  onEnter = (e: KeyboardEvent): void => {
    const { onClick, disabled, checked } = this.props

    if (e.key === ENTER_KEY && onClick && !disabled) {
      onClick(!checked)
    }
  }
}
