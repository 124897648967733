import { FETCH_CONFIG, fetchConfigFailure, fetchConfigSuccess } from 'src/actions/config'
import { call, put, takeEvery } from 'redux-saga/effects'
import { logError } from '../service/errors'
import { injectorService } from '../service/injector/injectorService'
import { setTag } from '../helpers/sentry'
import { Config } from 'src/types/Config'

export function* fetchConfig() {
  try {
    const config: Config = yield call(injectorService.getWithoutAuth, 'config')
    if (config) {
      setTag('api-version', (config.version || '') as string)
    }
    yield put(fetchConfigSuccess(config))
  } catch (err) {
    const error = err as Error
    yield call(logError, error)
    yield put(fetchConfigFailure(error.toString()))
  }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_CONFIG, fetchConfig)
}
