import React, { Component } from 'react'

import Column from 'src/components/SizeModal/Column/Column'
import Section from 'src/components/SizeModal/Section/Section'
import SizeChip from 'src/components/SizeModal/SizeChip/SizeChip'
import { Size } from '../../../../../../types/Size'

type Props = {
  sizes: Size[]
  group: string
  showColumnTitle?: boolean
  isLastSection?: boolean
  isProductReadyToBuy?: boolean
  onSizeChipClicked: (size: Size) => void
}

export class SizesColumn extends Component<Props> {
  render(): JSX.Element {
    const { showColumnTitle } = this.props

    return (
      <Column title="Size" showHeader={showColumnTitle} testId="size_column">
        {this.section()}
      </Column>
    )
  }

  section = (): JSX.Element => {
    const { sizes = [], group, isLastSection, onSizeChipClicked, isProductReadyToBuy } = this.props

    if (!sizes.length) {
      return <></>
    }

    return (
      <Section
        key={group}
        sizeGroup={group}
        isLastSection={isLastSection}
        testId={`size_column_${group}_section`}
      >
        {sizes.map((size: Size) => {
          const { name } = size

          return (
            <SizeChip
              key={name}
              size={size}
              isProductReadyToBuy={isProductReadyToBuy}
              onSizeChipClicked={onSizeChipClicked}
            />
          )
        })}
      </Section>
    )
  }
}
