import { DropdownFilter } from './DropdownFilter'
import { connect } from 'react-redux'
import { fetchDropdownItems, Filter } from 'src/actions/grid/exactFilters'
import { AppState } from 'src/reducers/root'
import { GridType } from 'src/types/index'
import { getDeprecatedPropertyValues, getExactFiltersForProperty } from 'src/selectors/product'
import { getUniqueFilterValues } from 'src/selectors/filter'
import { addExactFilter, removeExactFilter } from '../../../../../actions/grid/exactFilters'

type OwnProps = {
  property: string
  gridName: GridType
  onAdd: () => void
}

const mapStateToProps = (state: AppState, { property, gridName }: OwnProps) => ({
  filters: getExactFiltersForProperty(state, property),
  dropdownItems: getUniqueFilterValues(state, property, gridName),
  deprecatedItemValues: getDeprecatedPropertyValues(state, property),
})

const mapDispatchToProps = (dispatch: any, { gridName, onAdd }: OwnProps) => ({
  addExactFilter: (filter: Filter) => {
    onAdd()
    dispatch(addExactFilter(filter, gridName))
  },
  removeExactFilter: (filter: Filter) => dispatch(removeExactFilter(filter, gridName)),
  fetchDropdownItems: (columnName: string, gridName: GridType) =>
    dispatch(fetchDropdownItems(columnName, gridName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DropdownFilter)
