// @ts-nocheck as the country value vs dropdown options differences
import { connect } from 'react-redux'
import DropdownCellRenderer from '../DropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { getCountries } from '../../../../selectors/referenceData/countries'
import { Country } from 'src/types/index'

type OwnProps = {
  accessor: string
}

const mapStateToProps = (state: AppState) => ({
  dropdownItems: getCountries(state)
    .filter(({ enabled }) => enabled)
    .map((country) => ({ ...country, value: country })),
})
const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  createOption: (item: Country) => ({
    value: item.code,
    label: item.name,
    data: item,
  }),
  createPatchItem: (item: Country) => ({
    apiValue: item.code,
    storeValue: item,
  }),
  displayFormatter: (item: Country) => (item ? item.name : ''),
})
export const CountryCodeDropdownCellRendererContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(DropdownCellRenderer)
