import { SVPAction } from 'actions/svp'
import {
  CLOSE_COPY_TO_NAVIGATION,
  DISPLAY_COPY_TO_NAVIGATION_CHOICE,
} from '../../actions/grid/product/copy'

export type CopyToModalState = {
  productSlug: string
  hierarchySlug: string
  hasWholesaleImages: boolean
  open: boolean
}

const defaultCopyToState: CopyToModalState = {
  hierarchySlug: '',
  productSlug: '',
  hasWholesaleImages: false,
  open: false,
}

export default (
  state: CopyToModalState = defaultCopyToState,
  action: SVPAction,
): CopyToModalState => {
  switch (action.type) {
    case DISPLAY_COPY_TO_NAVIGATION_CHOICE:
      return {
        ...state,
        hierarchySlug: action.hierarchySlug,
        productSlug: action.productSlug,
        hasWholesaleImages: action.hasWholesaleImages,
        open: true,
      }
    case CLOSE_COPY_TO_NAVIGATION:
      return {
        ...state,
        open: false,
      }
    default:
      return state
  }
}
