import { connect } from 'react-redux'
import { MultiSelectCell } from './MultiSelectCell'
import { GridType, Product } from 'src/types/index'
import { updateMultiSelect } from '../../../../actions/grid/multiSelect'
import { AppState } from 'src/reducers/root'
import { getFilteredSelectedIds } from '../../../../selectors/multiSelect'
import { isProductCurrentlyPolling } from 'src/selectors/polling'

type OwnProps = {
  gridName: GridType
  product: Product
}

const mapStateToProps = (state: AppState, { product }: OwnProps) => ({
  productIsCurrentlyPolling: isProductCurrentlyPolling(product.slug, state),
  isSelected: getFilteredSelectedIds(state).includes(product.slug),
})

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  updateMultiSelect: (slug: string, developmentId: string, isSelected: boolean) =>
    dispatch(updateMultiSelect({ productSlug: slug, developmentId, isSelected }, gridName)),
})

export const MultiSelectCellContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiSelectCell)
