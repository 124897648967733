import React from 'react'
import { AttributeDisplay } from './AttributeDisplay/AttributeDisplay'
import { SideList } from 'src/components/SideList/SideList'
import { AttributeRenderSpec } from 'src/types/index'
import { ADMIN_PATH, ATTRIBUTES_LIST } from 'src/constants/sideList'

interface Props {
  attributes: AttributeRenderSpec[]
  currentAttribute?: string
}

export const Attributes = ({ attributes, currentAttribute }: Props): JSX.Element => {
  return (
    <>
      <SideList
        list={attributes.map(({ property, displayName }) => ({
          key: property,
          displayName,
        }))}
        type={ATTRIBUTES_LIST}
        rootPath={ADMIN_PATH}
      />
      <AttributeDisplay attribute={attributes.find((a) => a.property === currentAttribute)} />
    </>
  )
}
