import React, { Component } from 'react'

import { Product, DEV_TRACKER, OPTION_LIBRARY } from 'src/types/index'
import Link from '../../Link/index'
import { Modal } from '../../Modal/Modal'
import { UpdateProductStatusPayload } from 'src/actions/grid/product/update'
import { icon, status, trigger } from './StatusCellRenderer.scss'
import { GridType } from 'src/types/index'
import { EditIcon } from 'src/assets/icons/index'
import { UploadSpinner } from '../../UploadSpinner/index'
import { ProductStatusLabel } from './ProductStatusLabel/index'
import {
  CANCELLED,
  DEVELOPMENT,
  PENDING_ODBMS,
  PROPOSED,
  READY_TO_BUY,
} from 'src/constants/productStatus'
import { ChangeStatusModal } from './ChangeStatusModal/ChangeStatusModal'
import { RetryButton } from './RetryButton'

interface Props {
  grid: GridType
  product: Product
  locked: boolean
  editMode: boolean
  updateStatus: (payload: UpdateProductStatusPayload, grid: GridType) => void
  exitEditMode: (exit: boolean) => void
  enterEditMode: () => void
}

export class StatusCellRenderer extends Component<Props> {
  componentDidUpdate = (): void => {
    const { product, grid, editMode, exitEditMode } = this.props

    if (!this.retryReadyToBuy()) {
      return
    }

    this.props.updateStatus(
      {
        product,
        newStatus: READY_TO_BUY,
      },
      grid,
    )

    if (editMode) {
      exitEditMode(true)
    }
  }

  render(): JSX.Element {
    const { product, updateStatus, editMode, enterEditMode, exitEditMode, grid, locked } =
      this.props

    if (product.statusUpdating) {
      return <UploadSpinner />
    }

    if (product.status === PENDING_ODBMS) {
      return (
        <div className={status}>
          <RetryButton onClick={enterEditMode} />
        </div>
      )
    }

    return (
      <>
        <div className={status}>
          <ProductStatusLabel status={product.status} />
          {this.showModal(product.status, grid, locked) && (
            <Link onClick={enterEditMode} className={trigger}>
              <EditIcon className={icon} />
            </Link>
          )}
        </div>
        {editMode && this.showModal(product.status, grid, locked) && (
          <Modal onCloseFn={(): void => exitEditMode(true)}>
            <ChangeStatusModal
              onClose={(): void => exitEditMode(true)}
              updateStatus={updateStatus}
              product={product}
              grid={grid}
            />
          </Modal>
        )}
      </>
    )
  }

  retryReadyToBuy = (): boolean =>
    this.props.editMode && this.props.product.status === 'Pending Odbms'

  showModal = (status: string, grid: GridType, locked: boolean): boolean =>
    ((grid === OPTION_LIBRARY && status !== CANCELLED) ||
      (grid === DEV_TRACKER && [DEVELOPMENT, PROPOSED].includes(status))) &&
    !locked
}
