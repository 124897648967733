import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  ADD_CROSS_DEPARTMENT_LINK,
  ADD_MATCHING_PRODUCT_LINK,
  FETCH_PRODUCT_LINKS,
  fetchProductLinks,
  fetchProductLinksSuccess,
  REMOVE_CROSS_DEPARTMENT_PRODUCT,
  REMOVE_MATCHING_PRODUCT,
  LINK_CROSS_DEPT_PRODUCTS,
  LINK_MATCHING_PRODUCTS,
} from '../actions/grid/links'
import { injectorService } from '../service/injector/injectorService'
import { errorAndNotification } from './error'
import { clearMultiSelect } from '../actions/grid/multiSelect'
import { getSelectedProducts } from '../selectors/multiSelect'
import { showNotification } from '../actions/notification'
import { getProductsMap } from '../selectors/product'
import { OPTION_LIBRARY } from 'src/types/index'
import { getLinksForSlug } from '../reducers/sidePanel/links'
import { apiProductLinksToProductLinks } from '../service/mappers/productLinks'
import { LinkGroups, Product, ProductMap, ProductWithDynabutes } from '../types/Product'
import {
  CANCELLED,
  CANCELLED_IN_DEVELOPMENT,
  CANCELLED_IN_PROPOSED,
  CANCELLED_IN_READY_TO_BUY,
} from '../constants/productStatus'
import { ProductLinksWithStatus } from 'src/types/Links'
import { trackEvent } from '../service/analytics/analytics'
import { APIError } from 'src/types/Api'
import { ApiProductLinks } from 'src/types/responses/links'
import { SVPAction } from 'actions/svp'
import { CROSS_DEPARTMENT_LINK, MATCHING_PRODUCT_LINK } from 'constants/links'
import { updateProductSuccess } from 'actions/grid/product/update'

const GENERIC_ERROR_MESSAGE =
  "Sorry something has broken in the tech and we couldn't create the link, Please retry."

const rejectedStatuses = [
  CANCELLED,
  CANCELLED_IN_DEVELOPMENT,
  CANCELLED_IN_PROPOSED,
  CANCELLED_IN_READY_TO_BUY,
]
const CANCELLED_LINKING_VALIDATION_ERROR_MESSAGE = 'Cancelled products can not be linked'

export function* matchingProductsLinkSaga({ grid }: SVPAction<typeof MATCHING_PRODUCT_LINK>) {
  try {
    const products: Array<ProductWithDynabutes> = yield select(getSelectedProducts)

    if (anyProductIsInCancelledState(products)) {
      yield call(
        errorAndNotification,
        '🤦: Unable to create a link with cancelled products',
        'Unable to create a link with cancelled products',
      )
      return
    }

    trackEvent('product', 'Matching Products Linked')

    const groupSlug: string = yield call(
      injectorService.post,
      'matching-products',
      products.map(({ slug }) => slug),
    )
    yield put(
      showNotification({
        type: 'success',
        message: `Matching products link created successfully for ${products.length} products! View links in the side panel`,
      }),
    )

    yield put(clearMultiSelect(grid))

    yield all(
      products.map(({ slug, groups }) =>
        put(
          updateProductSuccess(
            slug,
            {
              groups: { ...groups, matchingProductGroupSlug: groupSlug },
            },
            grid,
          ),
        ),
      ),
    )
  } catch (error) {
    const apiError = error as APIError
    const apiMessage: string | undefined = apiError?.response?.data?.error?.friendlyMessage
    const message = apiMessage
      ? `${apiMessage} Please amend in the side panel.`
      : GENERIC_ERROR_MESSAGE
    yield call(errorAndNotification, apiError, message)
  }
}

export function* crossDepartmentProductsLinkSaga({
  grid,
}: SVPAction<typeof CROSS_DEPARTMENT_LINK>) {
  try {
    const products: Array<Product> = yield select(getSelectedProducts)
    if (anyProductIsInCancelledState(products)) {
      yield call(
        errorAndNotification,
        '🤦: Unable to create a link with cancelled products',
        'Unable to create a link with cancelled products',
      )
      return
    }

    trackEvent('product', 'Cross Department Products Linked')

    const groupSlug: string = yield call(
      injectorService.post,
      'cross-department-products',
      products.map(({ slug }) => slug),
    )
    yield put(
      showNotification({
        type: 'success',
        message: `Cross department link created successfully for ${products.length} products! View links in the side panel`,
      }),
    )
    yield put(clearMultiSelect(grid))
    yield all(
      products.map(({ slug, groups }) =>
        put(
          updateProductSuccess(
            slug,
            {
              groups: { ...groups, crossDepartmentGroupSlug: groupSlug },
            },
            grid,
          ),
        ),
      ),
    )
  } catch (error) {
    const apiError = error as APIError
    const apiMessage: string | undefined = apiError?.response?.data?.error?.friendlyMessage
    const message = apiMessage
      ? `${apiMessage} Please amend in the side panel.`
      : GENERIC_ERROR_MESSAGE
    yield call(errorAndNotification, apiError, message)
  }
}

export function* fetchProductLinksSaga(action: SVPAction<typeof FETCH_PRODUCT_LINKS>) {
  try {
    const apiProductLinks: ApiProductLinks = yield call(
      injectorService.get,
      `products/${action.productSlug}/linked-products`,
    )
    const productLinks = apiProductLinksToProductLinks(apiProductLinks)
    yield put(fetchProductLinksSuccess(action.productSlug, productLinks))
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't get product links. Please retry.",
    )
  }
}

export function* removeMatchingProductLinkSaga(action: SVPAction<typeof REMOVE_MATCHING_PRODUCT>) {
  try {
    trackEvent('product', 'Matching Products Link Removed')

    yield call(injectorService.delete, `matching-products/${action.productToRemove}`)
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't delete the link, Please retry.",
    )
    return
  }
  const products: ProductMap = yield select(getProductsMap)
  const { matchingProducts }: ProductLinksWithStatus = yield select(
    getLinksForSlug,
    action.productSlug,
  )
  const productsToRemove =
    matchingProducts.length === 1
      ? [action.productSlug, matchingProducts[0].productSlug]
      : [action.productToRemove]
  const successMessage =
    productsToRemove.length === 2
      ? 'Matching Products link removed successfully!'
      : 'Product removed from Matching Products link successfully!'
  yield put(
    showNotification({
      type: 'success',
      message: successMessage,
    }),
  )
  yield all(
    productsToRemove
      .filter((productSlug) => !!products[productSlug])
      .map((productSlug) =>
        put(
          updateProductSuccess(
            productSlug,
            {
              groups: {
                ...products[productSlug].groups,
                matchingProductGroupSlug: null,
              },
            },
            OPTION_LIBRARY,
          ),
        ),
      ),
  )
  yield put(fetchProductLinks(action.productSlug))
}

export function* removeCrossDepartmentProductLinkSaga(
  action: SVPAction<typeof REMOVE_CROSS_DEPARTMENT_PRODUCT>,
) {
  try {
    trackEvent('product', 'Cross Department Products Link Removed')

    yield call(injectorService.delete, `cross-department-products/${action.productToRemove}`)
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      "Sorry something has broken in the tech and we couldn't delete the link, Please retry.",
    )
    return
  }
  const products: ProductMap = yield select(getProductsMap)
  const { crossDepartmentProducts } = yield select(getLinksForSlug, action.productSlug)
  const productsToRemove =
    crossDepartmentProducts.length === 1
      ? [action.productSlug, crossDepartmentProducts[0].productSlug]
      : [action.productToRemove]
  const successMessage =
    productsToRemove.length === 2
      ? 'Cross Department link removed successfully!'
      : 'Product removed from Cross Department link successfully!'
  yield put(
    showNotification({
      type: 'success',
      message: successMessage,
    }),
  )
  yield all(
    productsToRemove
      .filter((productSlug) => !!products[productSlug])
      .map((productSlug) =>
        put(
          updateProductSuccess(
            productSlug,
            {
              groups: {
                ...products[productSlug].groups,
                crossDepartmentGroupSlug: null,
              },
            },
            OPTION_LIBRARY,
          ),
        ),
      ),
  )
  yield put(fetchProductLinks(action.productSlug))
}

const createGroups = (
  property: string,
  groupSlug: string,
  { crossDepartmentGroupSlug, matchingProductGroupSlug }: LinkGroups,
) =>
  property === 'matchingProductGroupSlug'
    ? {
        matchingProductGroupSlug: groupSlug,
        crossDepartmentGroupSlug,
      }
    : {
        matchingProductGroupSlug,
        crossDepartmentGroupSlug: groupSlug,
      }

function* updateRetrievedProduct(retrievedProduct: Product, groupSlug: string, property: string) {
  const productsMap: ProductMap = yield select(getProductsMap)

  if (productsMap[retrievedProduct.slug]) {
    yield put(
      updateProductSuccess(
        retrievedProduct.slug,
        {
          groups: createGroups(property, groupSlug, productsMap[retrievedProduct.slug].groups),
        },
        OPTION_LIBRARY,
      ),
    )
  }
}

const anyProductIsInCancelledState = (products: ProductWithDynabutes[]): boolean =>
  products.some(({ status }) => rejectedStatuses.includes(status))

const errorAddingProductToLink = (
  property: string,
  currentProduct: ProductWithDynabutes,
  retrievedProduct: ProductWithDynabutes,
) =>
  [
    {
      rule: (retrievedProduct: Product) => anyProductIsInCancelledState([retrievedProduct]),
      cause: () => CANCELLED_LINKING_VALIDATION_ERROR_MESSAGE,
    },
    {
      rule: (retrievedProduct: Product, currentProduct: Product) =>
        retrievedProduct.slug === currentProduct.slug,
      cause: (displayName: string) => `Can not create a ${displayName} Link with itself`,
    },
    {
      rule: (retrievedProduct: Product, currentProduct: Product, property: string) =>
        retrievedProduct.groups[property],
      cause: (displayName: string) => `Product already exists in a ${displayName} Link`,
    },
  ].find((validator) => validator.rule(retrievedProduct, currentProduct, property))

export function* addLinkSaga(
  identifier: string,
  currentProduct: ProductWithDynabutes,
  endpoint: string,
  displayName: string,
  property: string,
) {
  try {
    const retrievedProduct: ProductWithDynabutes = yield call(
      injectorService.get,
      `products/${identifier}`,
    )
    const error = errorAddingProductToLink(property, currentProduct, retrievedProduct)
    if (error) {
      yield call(errorAndNotification, `🤦: ${error.cause(displayName)}`, error.cause(displayName))
      return
    }
    let groupSlug: string
    if (!currentProduct.groups[property]) {
      groupSlug = yield call(injectorService.post, endpoint, [
        currentProduct.slug,
        retrievedProduct.slug,
      ])
      yield put(
        updateProductSuccess(
          currentProduct.slug,
          {
            groups: createGroups(property, groupSlug, currentProduct.groups),
          },
          OPTION_LIBRARY,
        ),
      )
    } else {
      groupSlug = currentProduct.groups[property] || ''
      yield call(injectorService.patch, `products/${currentProduct.slug}/${endpoint}`, {
        productToAssociate: retrievedProduct.slug,
      })
    }
    yield* updateRetrievedProduct(retrievedProduct, groupSlug, property)
    yield put(
      showNotification({
        type: 'success',
        message: `Product added to ${displayName} link successfully!`,
      }),
    )
    yield put({
      type: FETCH_PRODUCT_LINKS,
      productSlug: currentProduct.slug,
    })
  } catch (error) {
    const errorMessage: string =
      (error as APIError)?.response?.data?.error?.code === 3
        ? 'Dev ID/ Prod Number not found'
        : GENERIC_ERROR_MESSAGE
    yield call(errorAndNotification, error as APIError, errorMessage)
  }
}

export function* addCrossDepartmentLinkSaga(action: SVPAction<typeof ADD_CROSS_DEPARTMENT_LINK>) {
  yield* addLinkSaga(
    action.identifier,
    action.currentProduct,
    'cross-department-products',
    'Cross Department',
    'crossDepartmentGroupSlug',
  )
}

export function* addMatchingProductLinkSaga(action: SVPAction<typeof ADD_MATCHING_PRODUCT_LINK>) {
  yield* addLinkSaga(
    action.identifier,
    action.currentProduct,
    'matching-products',
    'Matching Products',
    'matchingProductGroupSlug',
  )
}

export default function* () {
  yield all([
    takeEvery(LINK_MATCHING_PRODUCTS, matchingProductsLinkSaga),
    takeEvery(LINK_CROSS_DEPT_PRODUCTS, crossDepartmentProductsLinkSaga),
    takeEvery(FETCH_PRODUCT_LINKS, fetchProductLinksSaga),
    takeEvery(REMOVE_MATCHING_PRODUCT, removeMatchingProductLinkSaga),
    takeEvery(REMOVE_CROSS_DEPARTMENT_PRODUCT, removeCrossDepartmentProductLinkSaga),
    takeEvery(ADD_MATCHING_PRODUCT_LINK, addMatchingProductLinkSaga),
    takeEvery(ADD_CROSS_DEPARTMENT_LINK, addCrossDepartmentLinkSaga),
  ])
}
