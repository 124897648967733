import { DepartmentsWithChildrenState } from '../../reducers/referenceData/hierarchy/departmentsWithChildren'
import * as Hierachies from '../../constants/hierachy'
import { HierachyLevels, TreeHierarchyItem } from 'src/types/Hierarchy'

const selectableLevelNames: HierachyLevels[] = [
  Hierachies.DepartmentHierachyLevel,
  Hierachies.DPTHierachyLevel,
  Hierachies.RangeHierachyLevel,
  Hierachies.RangeGroupHierachyLevel,
  Hierachies.SubRangeGroupHierachyLevel,
]

export const isSelectableHierarchyLevel = (node?: TreeHierarchyItem): boolean => {
  if (node == null) {
    return false
  } else {
    return selectableLevelNames.includes(node.levelName)
  }
}

export const getDepartmentByHierarchy = (
  hierarchySlug: string,
  departmentsWithChildren: DepartmentsWithChildrenState,
): string =>
  Object.keys(departmentsWithChildren).find((slug) =>
    departmentsWithChildren[slug].includes(hierarchySlug),
  ) || ''

export const getDepartmentByNode = (
  node: TreeHierarchyItem,
  departmentsWithChildren: DepartmentsWithChildrenState,
): string => getDepartmentByHierarchy(node.slug, departmentsWithChildren)

const departmentContainsSlug = (department: Array<string>, itemSlug: string): boolean =>
  department.includes(itemSlug)

export const isWithinDepartment = (
  node: TreeHierarchyItem,
  departmentSlug: string,
  departmentsWithChildren: DepartmentsWithChildrenState,
): boolean => {
  return departmentContainsSlug(departmentsWithChildren[departmentSlug], node.slug)
}

export const isInCurrentDepartment = (
  node: TreeHierarchyItem,
  selectedNode: TreeHierarchyItem,
  departmentsWithChildren: DepartmentsWithChildrenState,
): boolean => {
  const currentDepartmentSlug = getDepartmentByNode(selectedNode, departmentsWithChildren)
  return (
    !!currentDepartmentSlug &&
    isWithinDepartment(node, currentDepartmentSlug, departmentsWithChildren)
  )
}

export const getDepartmentSlugsForHierarchies = (
  hierarchySlugs: Array<string>,
  departmentsWithChildren: DepartmentsWithChildrenState,
): Array<string> => [
  ...new Set(
    hierarchySlugs.map((slug) => getDepartmentByHierarchy(slug, departmentsWithChildren) || ''),
  ),
]

export const isHierarchyNodeAtRangeLevel = (node: TreeHierarchyItem): boolean =>
  !node?.children?.length && node?.levelName === Hierachies.RangeHierachyLevel
