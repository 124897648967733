import React from 'react'
import { preview, outerBoundary, previewText } from './AttributeHeaderPreview.scss'

type Props = {
  name: string
  width: number
}

export const AttributeHeaderPreview = ({ name, width }: Props) => (
  <div data-cy="attribute-header-preview-outer" className={outerBoundary}>
    <div data-cy="attribute-header-preview-inner" className={preview} style={{ width }}>
      <div className={previewText}>{name}</div>
    </div>
  </div>
)
