import React from 'react'
import { wrapper, container, title } from './Rules.scss'
import { RuleValue } from 'src/types/DepartmentSpec'
import { SimpleDropdown } from 'src/components/Dropdown/SimpleDropdown/SimpleDropdown'
import { NONE } from 'src/service/product/status'
import { PROPOSED, READY_TO_BUY } from 'src/constants/productStatus'
import { SMALL } from 'src/types/ComponentSizes'
import { ApiRule } from 'src/types/responses/departmentSpec'

type Props = {
  rules: ApiRule
  changeRules: (rule: ApiRule) => void
}

const options = [
  { value: '', label: NONE },
  { value: PROPOSED, label: PROPOSED },
  { value: READY_TO_BUY, label: READY_TO_BUY },
]

export const Rules = ({ rules, changeRules }: Props): JSX.Element => (
  <div className={wrapper} data-cy="attribute-rules">
    <div data-cy="rules-container" className={container}>
      <h5 className={title}>mandatory from</h5>
      <SimpleDropdown
        name="mandatory"
        // @ts-ignore once SimpleDropdown converted
        selectedOption={{
          value: rules.mandatory ? rules.mandatory : '',
          label: rules.mandatory ? rules.mandatory : NONE,
        }}
        options={options}
        onChange={(selectedOption): void =>
          changeRules({
            locked: '',
            mandatory: selectedOption.value as RuleValue,
          })
        }
        size={SMALL}
        rounded
      />
    </div>
    <div className={container}>
      <h5 className={title}>locked at</h5>
      <SimpleDropdown
        name="locked"
        // @ts-ignore once SimpleDropdown converted
        selectedOption={{
          value: rules.locked ? rules.locked : '',
          label: rules.locked ? rules.locked : NONE,
        }}
        options={options}
        onChange={(selectedOption): void =>
          changeRules({
            ...rules,
            locked: selectedOption.value as RuleValue,
          })
        }
        size={SMALL}
        rounded
      />
    </div>
  </div>
)
