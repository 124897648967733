import omit from 'lodash/omit'
import {
  LIST_CELL,
  MULTI_LIST_CELL,
  SELLING_PRICE_CELL,
  SUPPLIER_CELL,
} from '../../constants/attributes'
import { POUND_KEY } from '../../constants/currency'
import { Attributes, AttributeValue, CellType, DiscreteValue } from 'src/types/index'
import { ApiAttribute } from '../../types/responses/attributes'
import { ApiSellingPrice } from '../../types/responses/sellingPrice'
import { ApiSupplier } from '../../types/responses/supplier'
import { PRICE_MULTIPLIER } from '../../constants/price'
import { DropdownOption } from 'types/Dropdowns'

const removeUnwantedKeys = (supplier: AttributeValue): Partial<ApiSupplier> =>
  omit(supplier, ['name'])

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: POUND_KEY,
  minimumFractionDigits: 2,
})

const apiSupplierToReferenceData = (supplier: ApiSupplier): AttributeValue =>
  removeUnwantedKeys({
    ...supplier,
    value: `${supplier.name}`,
    deprecated: false,
  }) as AttributeValue

const apiSellingPricesToReferenceData = (sellingPrice: ApiSellingPrice) => {
  const sellingPriceString = currencyFormatter.format(sellingPrice.value / PRICE_MULTIPLIER)
  return {
    value: sellingPrice.value,
    label: `${sellingPriceString}${sellingPrice.isBrand ? ' - Brands Only' : ''}`,
  }
}

export const apiDiscreteValueToSellingPriceOptions = (discreteValues: DiscreteValue) => {
  return {
    value: discreteValues.value / PRICE_MULTIPLIER,
    label: discreteValues.label,
  }
}

// TODO https://trello.com/c/tE0jMRzf [supplier reference data should not be in attribute values state]
export const apiAttributesToAttributes = (
  apiAttributes: ApiAttribute[],
  suppliers: ApiSupplier[],
  sellingPrices: ApiSellingPrice[],
): Attributes => {
  const filteredApiAttributes = apiAttributes
    .filter(({ name }) => name !== SUPPLIER_CELL)
    .filter(({ name }) => name !== SELLING_PRICE_CELL)
  return {
    list: {
      ...filteredApiAttributes.reduce(
        (acc, cv) => ({
          ...acc,
          [cv.name]: {
            name: cv.name,
            type: cv.type,
            source: cv.source,
            slug: cv.slug,
            companyWide: cv.companyWide,
            currencies: cv.currencies,
            duplicable: cv.duplicable,
          },
        }),
        {},
      ),
    },
    values: {
      ...filteredApiAttributes.reduce((acc, cv) => {
        if (cv.type === LIST_CELL || cv.type === MULTI_LIST_CELL) {
          acc[cv.name] = cv.values || []
        }
        return acc
      }, {} as Record<string, AttributeValue[]>),
      supplier: suppliers.map(apiSupplierToReferenceData),
    },
    discreteValues: {
      sellingPrice: sellingPrices.map(apiSellingPricesToReferenceData),
    },
  }
}

export const attributeToDropdownOption = (attribute: AttributeValue): DropdownOption => ({
  value: attribute.slug || '',
  label: attribute.value,
  data: {
    value: attribute.slug,
    label: attribute.value,
  },
})

export const referenceAttributeToApiAttribute = (
  referenceAttributeValues: AttributeValue[],
  productValue: string | string[],
  type: CellType,
): string | string[] => {
  let attributeValue: string | string[] = productValue
  switch (type) {
    case LIST_CELL:
      attributeValue = referenceAttributeValues.find(
        (attributeValueItem) => attributeValueItem.value === productValue,
      )?.slug
      break
    case MULTI_LIST_CELL:
      attributeValue = (productValue as string[]).map(
        (pv) =>
          referenceAttributeValues.find((attributeValue) => attributeValue.value === pv)?.slug,
      )
    default:
      break
  }

  return attributeValue
}
