import { connect } from 'react-redux'
import { PersistentSideList } from './PersistentSideList'
import { AppState } from 'src/reducers/root'
import { getAdminDepartments } from 'src/selectors/hierarchy'
import { getPersistentSideListSelectedSlug } from 'selectors/userSettings'
import { setPersistentSideListItem } from 'actions/persistentSideList'

const mapStateToProps = (state: AppState) => ({
  selectedSideListSlug: getPersistentSideListSelectedSlug(state),
  departments: getAdminDepartments(state).sort((a, b) => a.title.localeCompare(b.title)),
})

const mapDispatchToProps = {
  setPersistentSideListItem,
}

export const PersistentSideListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersistentSideList)
