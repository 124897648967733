import React from 'react'

import { disabled as disabledClass } from './Link.scss'
import classNames from 'classnames'

interface Props {
  onClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLAnchorElement>) => void
  setRef?: (node: any) => void
  className?: string
  tabIndex?: number
  children?: React.ReactNode | string | undefined | null
  disabled?: boolean
  dataCy?: string
}

export const Link = ({
  onClick,
  onBlur,
  children,
  setRef,
  tabIndex,
  className,
  disabled,
  dataCy,
}: Props): JSX.Element => {
  const linkClass = classNames(className, {
    [disabledClass]: disabled,
  })

  return (
    <a
      className={linkClass}
      tabIndex={tabIndex || 0}
      href=""
      onBlur={onBlur}
      ref={setRef}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault()
        if (!disabled) {
          onClick(e)
        }
      }}
      data-cy={dataCy ? dataCy : `link-${linkClass}`}
    >
      {children}
    </a>
  )
}
