/* eslint-disable camelcase */

import React from 'react'
import classNames from 'classnames'

import { setFocus, addEventListener, removeEventListener } from 'src/service/ref'
import { ComponentSize, MEDIUM, SMALL } from '../../types/ComponentSizes'

import { textField, input, small, medium, input_hasError } from './TextField.scss'

type Props = {
  name: string
  value: string
  hasFocus?: boolean
  placeholder?: string
  hasError?: boolean
  disabled?: boolean
  size?: ComponentSize
  onChange?: (val: string, name: string) => void
  onBlur?: (name: string) => void
  onKeyDown?: (e: KeyboardEvent) => void
  dataCy?: string
}

const getSizeClass = (size?: ComponentSize): string => {
  switch (size) {
    case SMALL:
      return small
    case MEDIUM:
      return medium
    default:
      return medium
  }
}

export class TextField extends React.Component<Props> {
  inputRef: React.RefObject<HTMLInputElement>

  constructor(props: Props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount(): void {
    this.props.hasFocus && setFocus(this.inputRef.current)
    addEventListener(this.props.onKeyDown, 'keydown', this.inputRef.current)
  }

  componentWillUnmount(): void {
    removeEventListener(this.props.onKeyDown, 'keydown', this.inputRef.current)
  }

  componentDidUpdate(): void {
    this.props.hasFocus && setFocus(this.inputRef.current)
  }

  render(): JSX.Element {
    const { name, value, hasError, placeholder, disabled, size } = this.props
    const classes = classNames(input, getSizeClass(size), {
      [input_hasError]: hasError,
    })

    return (
      <div className={`${textField} ${name}`}>
        <input
          data-cy={this.props.dataCy ? this.props.dataCy : 'textfield'}
          className={classes}
          name={name}
          value={value}
          placeholder={placeholder}
          type="text"
          autoComplete="off"
          onBlur={this.onBlur}
          onChange={this.onChange}
          ref={this.inputRef}
          disabled={disabled}
        />
      </div>
    )
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    this.props.onChange && this.props.onChange(value, this.props.name)
  }

  onBlur = (): void => {
    this.props.onBlur && this.props.onBlur(this.props.name)
  }
}
