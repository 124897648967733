import param from 'can-param'
import deparam from 'can-deparam'

export function toQueryString(params) {
  return param(params)
}

export function getParamsFromQueryString() {
  return deparam(window.location.search.substring(1))
}
