import isEqual from 'lodash/isEqual'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  CLEAR_AND_SELECT_HIERARCHY,
  DESELECT_HIERARCHY,
  SELECT_HIERARCHIES,
  SELECT_HIERARCHY,
} from 'src/actions/grid/hierarchy'

import {
  fetchDepartmentSpecs,
  REFRESH_DEPARTMENT_SPECS,
  updateDepartmentSpecs,
} from 'src/actions/grid/departmentSpec'

import { getDepartmentsWithChildren, getSelectedHierarchySlugs } from 'src/selectors/hierarchy'
import { getDepartmentSlugsForHierarchies } from 'src/service/hierarchy/utils'
import { getDepartmentSpecs } from 'src/selectors/departmentSpecs'
import { logError } from 'src/service/errors'
import { DepartmentSpecs, GridType } from 'src/types/index'
import { injectorService } from 'src/service/injector/injectorService'
import { fetchDepartmentSpecsFailure } from 'src/actions/grid/departmentSpec'
import { getViewsReferenceData } from 'src/selectors/referenceData/views'
import { convertApiSpecs } from 'src/service/mappers/departmentSpecs'
import { ApiDepartmentSpec, ApiProperties } from 'src/types/responses/departmentSpec'
import { DepartmentSpec, DepartmentViews } from 'src/types/DepartmentSpec'
import { DepartmentsWithChildrenState } from 'src/reducers/referenceData/hierarchy/departmentsWithChildren'
import { SVPAction } from 'actions/svp'
import { fetchFirstProductsPage } from 'actions/grid/product/fetch'

export function* fetchDepartmentSpec(slug: string, grid: GridType) {
  yield put(fetchDepartmentSpecs(grid))

  try {
    const apiSpec: ApiDepartmentSpec = yield call(
      injectorService.get,
      `department-specifications/${slug}`,
    )

    const viewsReferenceData: DepartmentViews = yield select(getViewsReferenceData)

    return convertApiSpecs(
      apiSpec.attributes,
      apiSpec.properties as ApiProperties[],
      viewsReferenceData,
    )
  } catch (err) {
    const error = err as Error
    yield call(logError, error)
    yield put(fetchDepartmentSpecsFailure(error.toString(), grid))
    return undefined
  }
}

function* getDepartmentSlugs() {
  const departmentsWithChildren: DepartmentsWithChildrenState = yield select(
    getDepartmentsWithChildren,
  )
  const currentHierarchySlugs: string[] = yield select(getSelectedHierarchySlugs)
  const departmentSlugsForHierarchies: string[] = yield call(
    getDepartmentSlugsForHierarchies,
    currentHierarchySlugs,
    departmentsWithChildren,
  )
  return departmentSlugsForHierarchies
}

export function* removeDepartmentSpecsSaga({ grid }: SVPAction<typeof DESELECT_HIERARCHY>) {
  const departmentSlugs: Array<string> = yield* getDepartmentSlugs()
  const currentDepartmentSpec: DepartmentSpecs = yield select(getDepartmentSpecs)
  const newDepartmentSpecs = departmentSlugs.reduce(
    (acc, slug) => ({ ...acc, [slug]: currentDepartmentSpec[slug] }),
    {},
  )

  if (!isEqual(newDepartmentSpecs, currentDepartmentSpec)) {
    yield put(updateDepartmentSpecs(newDepartmentSpecs, grid))
  }
}

export function* addMultiDepartmentsSpecsSaga({ grid }: SVPAction<typeof SELECT_HIERARCHIES>) {
  const departmentSlugs: Array<string> = yield* getDepartmentSlugs()
  const currentDepartmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecs)
  const departmentSpecs: DepartmentSpecs = {}
  for (const slugIndex in departmentSlugs) {
    const slug = departmentSlugs[slugIndex]
    const departmentSpec: any = yield* fetchDepartmentSpec(slug, grid)
    if (departmentSpec) {
      departmentSpecs[slug] = departmentSpec
    }
  }
  yield put(
    updateDepartmentSpecs(
      // @ts-ignore
      {
        ...currentDepartmentSpecs,
        ...departmentSpecs,
      },
      grid,
    ),
  )
  yield put(fetchFirstProductsPage(grid))
}

export function* addDepartmentSpecsSaga({ grid }: SVPAction<typeof SELECT_HIERARCHY>) {
  const departmentSlugs: Array<string> = yield* getDepartmentSlugs()
  const currentDepartmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecs)

  const newDepartmentSlug = departmentSlugs.find(
    (departmentSlug) => currentDepartmentSpecs[departmentSlug] === undefined,
  )

  if (newDepartmentSlug) {
    const newDepartmentSpec = yield* fetchDepartmentSpec(newDepartmentSlug, grid)

    if (newDepartmentSpec) {
      yield put(
        updateDepartmentSpecs(
          // @ts-ignore
          {
            ...currentDepartmentSpecs,
            [newDepartmentSlug]: newDepartmentSpec,
          },
          grid,
        ),
      )
    }
  }
}

export function* clearAndAddNewHierarchySaga({
  grid,
}: SVPAction<typeof CLEAR_AND_SELECT_HIERARCHY>) {
  const [departmentSlug] = yield* getDepartmentSlugs()
  const currentDepartmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecs)

  const requiredDepartmentSpec = currentDepartmentSpecs[departmentSlug]
    ? currentDepartmentSpecs[departmentSlug]
    : yield* fetchDepartmentSpec(departmentSlug, grid)

  if (requiredDepartmentSpec) {
    yield put(
      updateDepartmentSpecs(
        {
          [departmentSlug]: requiredDepartmentSpec as DepartmentSpec,
        },
        grid,
      ),
    )
  }
}

export function* refreshDepartmentSpecs({
  selectedHierarchy,
  grid,
}: SVPAction<typeof REFRESH_DEPARTMENT_SPECS>) {
  yield* fetchDepartmentSpec(selectedHierarchy, grid)
  const currentDepartmentSpecs: DepartmentSpecs = yield select(getDepartmentSpecs)
  let refreshedDepartmentSpecs = {}

  for (const slug in currentDepartmentSpecs) {
    if (Object.prototype.hasOwnProperty.call(currentDepartmentSpecs, slug)) {
      const departmentSpec: DepartmentSpec = yield call(fetchDepartmentSpec, slug, grid)
      refreshedDepartmentSpecs = {
        ...refreshedDepartmentSpecs,
        [slug]: departmentSpec,
      }
    }
  }

  yield put(updateDepartmentSpecs(refreshedDepartmentSpecs, grid))
}

export default function* rootSaga() {
  yield all([
    takeEvery(SELECT_HIERARCHY, addDepartmentSpecsSaga),
    takeEvery(SELECT_HIERARCHIES, addMultiDepartmentsSpecsSaga),
    takeEvery(DESELECT_HIERARCHY, removeDepartmentSpecsSaga),
    takeEvery(CLEAR_AND_SELECT_HIERARCHY, clearAndAddNewHierarchySaga),
    takeEvery(REFRESH_DEPARTMENT_SPECS, refreshDepartmentSpecs),
  ])
}
