import { connect } from 'react-redux'
import { AppState } from 'reducers/root'
import {
  getCopiedAttribute,
  getDefaultAttributesTableData,
  getIsFetchingAttributes,
  getPersistentSideListSelectedSlug,
  getUnsetDefaultAttributesDropdownOptions,
} from 'selectors/userSettings'
import { DefaultAttributes } from './DefaultAttributes'
import {
  addDefaultAttributeToTable,
  getDepartmentRangesWithDefaultAttributes,
  setClipboardAttributeSource,
  updateDefaultAttributeValues,
} from 'actions/userSettings/defaultAttributes'
import { DropdownOption } from 'types/Dropdowns'
import { getNonDeprecatedAttributeValues } from 'selectors/referenceData/attributes'
import { AttributeValueMap } from 'types/Attributes'
import { DefaultAttributesTableData, SelectedDefaultAttribute } from 'types/UserSettings'

interface StateProps {
  isFetchingAttributes: boolean
  selectedSideListSlug: string
  listAttributes: DropdownOption[]
  allAttributeValues: AttributeValueMap
  tableData: DefaultAttributesTableData[][]
  copiedAttribute?: SelectedDefaultAttribute
}

interface DispatchProps {
  getDepartmentRangesWithDefaultAttributes: typeof getDepartmentRangesWithDefaultAttributes
  addDefaultAttributeToTable: typeof addDefaultAttributeToTable
  updateDefaultAttributeValues: typeof updateDefaultAttributeValues
  setClipboardAttributeSource: typeof setClipboardAttributeSource
}

export interface DefaultAttributesProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  isFetchingAttributes: getIsFetchingAttributes(state),
  selectedSideListSlug: getPersistentSideListSelectedSlug(state),
  listAttributes: getUnsetDefaultAttributesDropdownOptions(state),
  allAttributeValues: getNonDeprecatedAttributeValues(state),
  tableData: getDefaultAttributesTableData(state),
  copiedAttribute: getCopiedAttribute(state),
})

const mapDispatchToProps: DispatchProps = {
  getDepartmentRangesWithDefaultAttributes,
  addDefaultAttributeToTable,
  updateDefaultAttributeValues,
  setClipboardAttributeSource,
}

export const DefaultAttributesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultAttributes)
