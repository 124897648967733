import { ApiDepartmentSpec, ApiRule } from 'src/types/responses/departmentSpec'

export const GET_ADMIN_DEPARTMENTS_SPECS = 'GET_ADMIN_DEPARTMENTS_SPECS'
export const ADD_DEPARTMENT_ATTRIBUTE = 'ADD_DEPARTMENT_ATTRIBUTE'
export const UPDATE_DEPARTMENT_ATTRIBUTE_RULES = 'UPDATE_DEPARTMENT_ATTRIBUTE_RULES'
export const UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS = 'UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS'
export const UPDATE_ADMIN_DEPARTMENT_SPECS = 'UPDATE_ADMIN_DEPARTMENT_SPECS'
export const UPDATE_SELECTED_ATTRIBUTE = 'UPDATE_SELECTED_ATTRIBUTE'
export const UPDATE_DEPARTMENTS_READY_STATUS = 'UPDATE_DEPARTMENTS_READY_STATUS'

export type AdminDepartmentsActions = ReturnType<
  | typeof updateAdminDepartmentSpecs
  | typeof updateSelectedAttribute
  | typeof updateDepartmentsReadyStatus
>

export const getAdminDepartmentsSpecs = (departmentSlug: string) =>
  <const>{
    type: GET_ADMIN_DEPARTMENTS_SPECS,
    departmentSlug,
  }

export const addDepartmentAttribute = (departmentSlug: string, attributeName: string) =>
  <const>{
    type: ADD_DEPARTMENT_ATTRIBUTE,
    departmentSlug,
    attributeName,
  }

export const updateDepartmentAttributeRules = (
  departmentSlug: string,
  attributeName: string,
  rules: ApiRule,
) =>
  <const>{
    type: UPDATE_DEPARTMENT_ATTRIBUTE_RULES,
    departmentSlug,
    attributeName,
    rules,
  }

export const updateDepartmentAttributeViews = (
  departmentSlug: string,
  attributeName: string,
  views: string[],
) =>
  <const>{
    type: UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS,
    departmentSlug,
    attributeName,
    views,
  }

export const updateAdminDepartmentSpecs = (departmentSpecification: ApiDepartmentSpec) =>
  <const>{
    type: UPDATE_ADMIN_DEPARTMENT_SPECS,
    departmentSpecification,
  }

export const updateSelectedAttribute = (attributeName: string) =>
  <const>{
    type: UPDATE_SELECTED_ATTRIBUTE,
    attributeName,
  }

export const updateDepartmentsReadyStatus = (ready: boolean) =>
  <const>{
    type: UPDATE_DEPARTMENTS_READY_STATUS,
    ready,
  }
