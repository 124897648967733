import React, { Component } from 'react'

import { HierarchyDrawer } from '../../HierarchyDrawer/HierarchyDrawer'
import { GridType, OPTION_LIBRARY, TreeHierarchyItem } from 'src/types/index'
import MoveToModal from './MoveToModal/MoveToModalContainer'
import { ImportErrorModal } from './ImportErrorsModal/ImportErrorsModal'
import { PROPOSED, READY_TO_BUY } from 'src/constants/productStatus'
import { CheckboxButton } from '../../CheckboxButton/CheckboxButton'
import { ProductStatus } from 'src/types/ProductStatus'
import BulkChangeStatusModal from './BulkChangeStatusModal/BulkChangeStatusModal'
import MultiSelectActions from './MultiSelectActions/MultiSelectActions'
import SingleSelectActions from './SingleSelectActions/SingleSelectActions'

import { actionButtonContainer, linksList } from '../GridControls.scss'

type Props = {
  exportButtonEnabled: boolean
  isSelectedIdsEmpty: boolean
  gridName: GridType
  multiSelect: boolean
  isSelected: boolean
  isAdmin: boolean
  selectedIds: string[]
  selectAll: boolean
  importErrors?: Error[]
  onExport: () => void
  onRefresh: () => void
  linkMatchingProducts: (grid: GridType) => void
  linkCrossDepartmentProducts: (grid: GridType) => void
  onSelectAllClick: (selected: boolean) => void
  linkMultiSupplierProducts: (grid: GridType) => void
  linkColourProducts: (grid: GridType) => void
  bulkUpdateStatus: (status: ProductStatus, grid: GridType) => void
  importProducts: (file: File | null) => void
  clearErrors: () => void
  exportErrors: (errors: Error[]) => void
}

type State = {
  isHierarchyDrawerOpen: boolean
  isReclassModalOpen: boolean
  isBulkStatusChangeModalOpen: boolean
  hierarchyNode?: TreeHierarchyItem
}

const isOptionLibrary = (gridName: GridType): boolean => gridName === OPTION_LIBRARY

export class ActionButtons extends Component<Props, State> {
  state = {
    isHierarchyDrawerOpen: false,
    hierarchyNode: undefined,
    isReclassModalOpen: false,
    isBulkStatusChangeModalOpen: false,
  }

  render(): JSX.Element {
    const {
      onExport,
      exportButtonEnabled,
      onRefresh,
      multiSelect,
      selectedIds,
      selectAll,
      isSelectedIdsEmpty,
      gridName,
      importProducts,
      isAdmin,
      importErrors,
      onSelectAllClick,
      isSelected,
      clearErrors,
      linkMatchingProducts,
      linkCrossDepartmentProducts,
      linkMultiSupplierProducts,
      linkColourProducts,
    } = this.props

    const optionLibray = isOptionLibrary(this.props.gridName)

    return (
      <div className={actionButtonContainer}>
        {multiSelect && (
          <CheckboxButton
            withBorder
            checked={isSelected}
            onClick={onSelectAllClick}
            checkedLabel="Deselect all"
            uncheckedLabel="Select all"
            dataCy="toggleSelectAll"
          />
        )}

        <ul className={linksList}>
          {multiSelect ? (
            <MultiSelectActions
              isOptionLibrary={optionLibray}
              isSelectedIdsEmpty={isSelectedIdsEmpty}
              isAdmin={isAdmin}
              gridName={gridName}
              disabled={selectedIds.length < 2 && !selectAll}
              openBulkStatusChangeModal={this.openBulkStatusChangeModal}
              openHierachyDrawer={this.openHierachyDrawer}
              linkMatchingProducts={linkMatchingProducts}
              linkCrossDepartmentProducts={linkCrossDepartmentProducts}
              linkColourProducts={linkColourProducts}
              linkMultiSupplierProducts={linkMultiSupplierProducts}
            />
          ) : (
            <SingleSelectActions
              isOptionLibrary={optionLibray}
              isExportButtonEnabled={exportButtonEnabled}
              isAdmin={isAdmin}
              importProducts={importProducts}
              onExport={onExport}
              onRefresh={onRefresh}
            />
          )}
        </ul>
        {this.state.isHierarchyDrawerOpen && (
          <HierarchyDrawer
            onClose={this.closeHierachyDrawer}
            onNodeSelected={this.onHierarchyDrawerNodeSelected}
            gridName={gridName}
          />
        )}
        {this.state.isReclassModalOpen && this.state.hierarchyNode && (
          <MoveToModal
            gridName={gridName}
            // @ts-ignore as value cant be undefined due to check
            hierarchyNode={this.state.hierarchyNode}
            onClose={this.closeMoveToModal}
          />
        )}
        {this.state.isBulkStatusChangeModalOpen && (
          <BulkChangeStatusModal
            selectedIDs={this.props.selectedIds}
            selectedAll={this.props.selectAll}
            isOptionLibrary={optionLibray}
            onConfirmClick={this.bulkUpdateStatus}
            onCloseModalClick={this.closeBulkStatusChangeModal}
          />
        )}
        {importErrors && (
          <ImportErrorModal downloadAndClose={this.onInportErrorModalClose} onClose={clearErrors} />
        )}
      </div>
    )
  }

  bulkUpdateStatus = (): void => {
    const newStatus = isOptionLibrary(this.props.gridName) ? READY_TO_BUY : PROPOSED
    this.props.bulkUpdateStatus(newStatus, this.props.gridName)
    this.closeBulkStatusChangeModal()
  }

  closeBulkStatusChangeModal = (): void => this.setState({ isBulkStatusChangeModalOpen: false })

  openBulkStatusChangeModal = (): void => this.setState({ isBulkStatusChangeModalOpen: true })

  closeMoveToModal = (): void =>
    this.setState({
      isHierarchyDrawerOpen: false,
      hierarchyNode: undefined,
      isReclassModalOpen: false,
    })

  onHierarchyDrawerNodeSelected = (node: TreeHierarchyItem): void =>
    this.setState({
      hierarchyNode: node,
      isReclassModalOpen: true,
      isHierarchyDrawerOpen: false,
    })

  openHierachyDrawer = (): void => this.setState({ isHierarchyDrawerOpen: true })
  closeHierachyDrawer = (): void => this.setState({ isHierarchyDrawerOpen: false })

  onInportErrorModalClose = (): void => {
    const { importErrors, clearErrors, exportErrors } = this.props

    if (!importErrors) {
      return
    }

    exportErrors(importErrors)
    clearErrors()
  }
}
