import { AppState } from 'src/reducers/root'
import { ApiDepartmentSpec } from 'src/types/responses/departmentSpec'

export const areAdminDepartmentSpecsReady = (state: AppState): boolean =>
  !!state.injectorUI.admin.departments.ready

export const getSelectedAttributeForDepartment = (state: AppState): string | undefined =>
  state.injectorUI.admin.departments.selectedAttribute

export const getDepartmentSpecifications = (state: AppState): ApiDepartmentSpec | undefined =>
  state.injectorUI.admin.departments.departmentSpecification
