import { Comment, NewComment, GridType } from 'src/types/index'

export const FETCH_PRODUCT_COMMENTS = 'FETCH_PRODUCT_COMMENTS'
export const FETCH_PRODUCT_COMMENTS_SUCCESS = 'FETCH_PRODUCT_COMMENTS_SUCCESS'
export const FETCH_PRODUCT_COMMENTS_FAILURE = 'FETCH_PRODUCT_COMMENTS_FAILURE'
export const POST_PRODUCT_COMMENT = 'POST_PRODUCT_COMMENT'
export const POST_PRODUCT_COMMENT_SUCCESS = 'POST_PRODUCT_COMMENT_SUCCESS'
export const POST_PRODUCT_COMMENT_FAILURE = 'POST_PRODUCT_COMMENT_FAILURE'

export type CommentActions = ReturnType<
  | typeof fetchProductComments
  | typeof fetchProductCommentsSuccess
  | typeof fetchProductCommentsFailure
  | typeof postProductComment
  | typeof postProductCommentSuccess
  | typeof postProductCommentFailure
>

export const fetchProductComments = (productSlug: string, grid: GridType) =>
  <const>{
    type: FETCH_PRODUCT_COMMENTS,
    productSlug,
    grid,
  }

export const fetchProductCommentsSuccess = (
  productSlug: string,
  comments: Comment[],
  grid: GridType,
) =>
  <const>{
    type: FETCH_PRODUCT_COMMENTS_SUCCESS,
    productSlug,
    comments,
    grid,
  }

export const fetchProductCommentsFailure = (productSlug: string, error: string) =>
  <const>{
    type: FETCH_PRODUCT_COMMENTS_FAILURE,
    productSlug,
    error,
  }

export const postProductComment = (comment: NewComment, productSlug: string, grid: GridType) =>
  <const>{
    type: POST_PRODUCT_COMMENT,
    productSlug,
    comment,
    grid,
  }

export const postProductCommentSuccess = (productSlug: string) =>
  <const>{
    type: POST_PRODUCT_COMMENT_SUCCESS,
    productSlug,
  }

export const postProductCommentFailure = (productSlug: string, error: string) =>
  <const>{
    type: POST_PRODUCT_COMMENT_FAILURE,
    productSlug,
    error,
  }
