import { SearchStylePayload } from 'actions/grid/style'
import React from 'react'
import { StyleResult } from 'types/Style'
import { StyleModalSearchFormContainer } from './StyleModalSearchForm/StyleModalSearchFormContainer'
import { StyleModalSearchResults } from './StyleModalSearchResults/StyleModalSearchResults'

interface Props {
  styleResults?: StyleResult[]
  viewResults: boolean
  isSearchStyleLoading: boolean
  isConfirmStyleRequesting: boolean
  onSearch: (payload: SearchStylePayload) => void
  onConfirmStyle: (styleSlug: string) => void
  setViewResults: (viewResults: boolean) => void
}

export const StyleModalSearchView = (props: Props): JSX.Element => {
  const onSearch = (payload: SearchStylePayload): void => {
    props.onSearch(payload)
  }

  const onConfirmStyle = (selectedResult: string): void => props.onConfirmStyle(selectedResult)

  const onBack = (): void => props.setViewResults(false)
  const onResults = (): void => props.setViewResults(true)

  return props.styleResults?.length && props.viewResults ? (
    <StyleModalSearchResults
      isConfirmStyleRequesting={props.isConfirmStyleRequesting}
      results={props.styleResults}
      onConfirm={onConfirmStyle}
      onBack={onBack}
    />
  ) : (
    <StyleModalSearchFormContainer
      onSearch={onSearch}
      hasResults={!!props.styleResults?.length}
      onResults={onResults}
    />
  )
}
