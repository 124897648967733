// @ts-nocheck due issues with react-dates TODO

import React from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { Moment } from 'moment'
import 'react-dates/lib/css/_datepicker.css'
import { textField, encapsulatedDatePicker } from './DatePicker.scss'

type Props = {
  hasFocus: boolean
  name: string
  value: string
  onChange: (date: Date) => void
  onKeyDown: (e: KeyboardEvent) => void
  className: string
}

class DateField extends React.Component<Props> {
  static defaultProps = {
    value: moment(),
    className: '',
    onKeyDown: () => {
      // noop
    },
  }

  componentDidMount() {
    const node = document.querySelector('.DayPicker .CalendarDay__selected')
    setTimeout(() => {
      node && node.focus()
    }, 0)
    const calendar = document.querySelector('.DayPicker')
    if (calendar) {
      calendar.addEventListener('keydown', this.props.onKeyDown)
    }
  }

  onChange = (date: Moment) => {
    this.props.onChange(date.toDate())
  }

  render() {
    const { className, value, hasFocus } = this.props
    return (
      <div className={encapsulatedDatePicker}>
        <div className={`${textField} ${className}  ${this.props.name}`}>
          <DayPickerSingleDateController
            focused={hasFocus}
            onDateChange={this.onChange}
            date={value ? moment(value) : moment()}
            onFocusChange={() => {
              // noop
            }}
            onOutsideClick={() => {
              // noop
            }}
          />
        </div>
      </div>
    )
  }
}

export default DateField
