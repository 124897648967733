import { AppState } from '../reducers/root'
import { isProductsLoading, productsHaveError } from './product'
import { departmentSpecsHaveError, isDepartmentSpecsLoading } from './departmentSpecs'
import { RequestStatus } from '../types/Api'

export type Status = 'ready' | 'notReady' | 'error'

const reduceStatuses = (statuses: Array<RequestStatus>) =>
  statuses.reduce((acc: string, currentStatus: RequestStatus) => {
    if (acc === 'error' || currentStatus === 'error') {
      return 'error'
    }

    if (acc !== 'ready' || currentStatus !== 'ready') {
      return 'notReady'
    }

    return 'ready'
  }, 'ready')

export const getPageStatus = (state: AppState): Status => {
  const referenceDataStatus = state.injectorUI.referenceData.status

  return reduceStatuses([referenceDataStatus]) as Status
}

export const dependenciesHaveError = (state: AppState) =>
  departmentSpecsHaveError(state) || productsHaveError(state)

export const areDependenciesLoading = (state: AppState) =>
  isDepartmentSpecsLoading(state) || isProductsLoading(state)
