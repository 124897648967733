import { AttributeValue } from 'src/types/index'

export const CREATE_ATTRIBUTE = 'CREATE_ATTRIBUTE'
export const CREATE_ATTRIBUTE_SUCCESS = 'CREATE_ATTRIBUTE_SUCCESS'
export const CREATE_ATTRIBUTE_FAILURE = 'CREATE_ATTRIBUTE_FAILURE'
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'
export const UPDATE_ATTRIBUTE_SUCCESS = 'UPDATE_ATTRIBUTE_SUCCESS'
export const UPDATE_ATTRIBUTE_FAILURE = 'UPDATE_ATTRIBUTE_FAILURE'
export type CreateAttributeAction = ReturnType<
  typeof createAttribute | typeof createAttributeSuccess | typeof createAttributeFailure
>

export type UpdateAttributeAction = ReturnType<
  typeof updateAttribute | typeof updateAttributeSuccess | typeof updateAttributeFailure
>

export type AttributePayload = {
  displayName: string
  type: string
  sortable: boolean
  columnWidth: number
  values?: string[]
  duplicable?: boolean
  companyWide?: boolean
}

export type UpdateAttributePayload = {
  newAttributeValues: string[]
  existingAttributeValues: AttributeValue[]
  sortable?: boolean
  width?: number
  name?: string
  columnSlug?: string
  duplicable?: boolean
}

export const createAttribute = (payload: AttributePayload) =>
  <const>{
    type: CREATE_ATTRIBUTE,
    payload,
  }

export const createAttributeSuccess = () =>
  <const>{
    type: CREATE_ATTRIBUTE_SUCCESS,
  }

export const createAttributeFailure = () =>
  <const>{
    type: CREATE_ATTRIBUTE_FAILURE,
  }

export const updateAttribute = (slug: string, payload: UpdateAttributePayload) =>
  <const>{
    type: UPDATE_ATTRIBUTE,
    payload,
    slug,
  }

export const updateAttributeSuccess = () =>
  <const>{
    type: UPDATE_ATTRIBUTE_SUCCESS,
  }

export const updateAttributeFailure = () =>
  <const>{
    type: UPDATE_ATTRIBUTE_FAILURE,
  }
