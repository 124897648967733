import { ProductSizeGroups } from 'src/types/ProductSizes'
import { PRICE_MULTIPLIER } from '../../constants/price'
import { PartialSizeAttributions, SizeAttribution } from 'src/types/index'

export const definedSizeAttributions = (sizeAttributions: PartialSizeAttributions = {}) => {
  const definedSizeAttributions: PartialSizeAttributions = {}
  Object.keys(sizeAttributions).forEach((sizeGroup) => {
    const sizeGroupKey = sizeGroup as ProductSizeGroups
    if (Object.keys(sizeAttributions[sizeGroupKey] || {}).length) {
      definedSizeAttributions[sizeGroupKey] = sizeAttributions[sizeGroupKey]
    }
  })
  return definedSizeAttributions
}

export const mapApiSizeAttributionToSizeAttribution = (
  sizeAttributions: PartialSizeAttributions,
): PartialSizeAttributions => {
  if (sizeAttributions) {
    const localSizeAttribution: PartialSizeAttributions = {
      ...sizeAttributions,
    }
    Object.keys(localSizeAttribution).forEach((sizeGroup) => {
      const sizeGroupKey = sizeGroup as ProductSizeGroups
      localSizeAttribution[sizeGroupKey] = {
        ...priceDivider(localSizeAttribution[sizeGroupKey] as SizeAttribution),
      }
    })
    return localSizeAttribution
  }
  return sizeAttributions
}

const priceDivider = (sizeAttribution: SizeAttribution) => {
  const sizeAttributionCopy: SizeAttribution = {
    ...sizeAttribution,
  }

  if (sizeAttributionCopy.sellingPrice) {
    sizeAttributionCopy.sellingPrice = sizeAttributionCopy.sellingPrice / PRICE_MULTIPLIER
  }
  if (sizeAttributionCopy.supplierCost) {
    sizeAttributionCopy.supplierCost = sizeAttributionCopy.supplierCost / PRICE_MULTIPLIER
  }
  if (sizeAttributionCopy.landedCost) {
    sizeAttributionCopy.landedCost = sizeAttributionCopy.landedCost / PRICE_MULTIPLIER
  }
  if (sizeAttributionCopy.currentFullPrice) {
    sizeAttributionCopy.currentFullPrice = sizeAttributionCopy.currentFullPrice / PRICE_MULTIPLIER
  }
  if (sizeAttributionCopy.promoPrice) {
    sizeAttributionCopy.promoPrice = sizeAttributionCopy.promoPrice / PRICE_MULTIPLIER
  }
  return sizeAttributionCopy
}
