import { connect } from 'react-redux'
import { isAuthenticated } from '@ri-digital/auth0-authenticator'

import { AppState } from 'src/reducers/root'
import { UtilityNavigation } from './UtilityNavigation'
import { userName } from '../../selectors/userProfile'

const mapStateToProps = (state: AppState) => ({
  pathname: state.router.location.pathname,
  name: userName(state),
  isLoggedIn: isAuthenticated(state.authentication),
})

export default connect(mapStateToProps)(UtilityNavigation)
