import { GridType, DEV_TRACKER } from 'src/types/index'
import { ImageTypes } from '../types/Images'

export const UPDATE_IMAGES = 'UPDATE_IMAGES'
export const UPDATE_IMAGES_SUCCESS = 'UPDATE_IMAGES_SUCCESS'
export const UPDATE_IMAGES_FAILURE = 'UPDATE_IMAGES_FAILURE'
export const SAVE_SKETCH = 'SAVE_SKETCH'

export type ImagesActions = ReturnType<
  typeof updateImages | typeof updateImagesSuccess | typeof updateImagesFailure | typeof saveSketch
>

export type UpdateImagesPayload = {
  type: ImageTypes
  file: string
  index: number
}

export type SaveSketchPayload = {
  developmentId: string
  productSlug: string
  sketch: string
  redirect?: string
}

export const updateImages = (
  updateImagesPayload: UpdateImagesPayload,
  productSlug: string,
  grid: GridType,
) =>
  <const>{
    type: UPDATE_IMAGES,
    payload: {
      productSlug,
      ...updateImagesPayload,
    },
    grid,
  }

export const updateImagesSuccess = (index: number, productSlug: string) =>
  <const>{
    type: UPDATE_IMAGES_SUCCESS,
    productSlug,
    index,
  }

export const updateImagesFailure = (index: number, productSlug: string) =>
  <const>{
    type: UPDATE_IMAGES_FAILURE,
    productSlug,
    index,
  }

export const saveSketch = (payload: SaveSketchPayload, grid: GridType = DEV_TRACKER) =>
  <const>{
    type: SAVE_SKETCH,
    payload: {
      ...payload,
    },
    grid,
  }
