import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
  ADD_DEPARTMENT_ATTRIBUTE,
  GET_ADMIN_DEPARTMENTS_SPECS,
  updateAdminDepartmentSpecs,
  updateDepartmentsReadyStatus,
  updateSelectedAttribute,
  UPDATE_DEPARTMENT_ATTRIBUTE_RULES,
  UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS,
} from 'src/actions/admin/departments'
import { trackEvent } from 'src/service/analytics/analytics'
import { injectorService } from 'src/service/injector/injectorService'
import { ApiDepartmentSpec } from 'src/types/responses/departmentSpec'
import { errorAndNotification } from '../error'
import * as Columns from 'src/constants/columns'
import { COMPANY_WIDE_SLUG } from 'src/constants/attributes'
import { APIError } from 'src/types/Api'
import { SVPAction } from 'actions/svp'

function filteredAttributes(departmentSlug: string, departmentSpecification: ApiDepartmentSpec) {
  const filtered = departmentSpecification.attributes.filter(
    (attribute) =>
      attribute.name !== Columns.SAMPLE_URL_COLUMN && attribute.name !== Columns.SKETCH_URL_COLUMN,
  )

  return departmentSlug === COMPANY_WIDE_SLUG
    ? { attributes: filtered }
    : { attributes: filtered.filter(({ companyWide }) => !companyWide) }
}

export function* updateReadyStatus(ready: boolean) {
  yield put(updateDepartmentsReadyStatus(ready))
}

export function* performFetchDepartmentSpecs(departmentSlug: string) {
  const response: ApiDepartmentSpec = yield call(
    injectorService.get,
    `department-specifications/${departmentSlug}`,
  )
  const filtered = filteredAttributes(departmentSlug, response)
  yield put(updateAdminDepartmentSpecs(filtered))
}

export function* getAdminDepartmentsSpecsSaga(
  action: SVPAction<typeof GET_ADMIN_DEPARTMENTS_SPECS>,
) {
  try {
    const { departmentSlug } = action
    yield call(updateReadyStatus, false)
    yield call(performFetchDepartmentSpecs, departmentSlug)
    yield call(updateReadyStatus, true)
  } catch (error) {
    yield call(
      errorAndNotification,
      error as APIError,
      'Unable to retrieve admin department specifications',
    )
  }
}

export function* addDepartmentAttributeSaga(action: SVPAction<typeof ADD_DEPARTMENT_ATTRIBUTE>) {
  trackEvent('admin', 'Added department attribute')
  try {
    const { departmentSlug, attributeName } = action
    yield call(updateReadyStatus, false)
    yield call(injectorService.put, `department-specifications/${departmentSlug}/attributes`, {
      name: attributeName,
    })
    yield call(performFetchDepartmentSpecs, departmentSlug)
    yield put(updateSelectedAttribute(attributeName))
    yield call(updateReadyStatus, true)
  } catch (error) {
    yield call(errorAndNotification, error as APIError, 'Unable to add department attribute')
    yield put(updateDepartmentsReadyStatus(true))
  }
}

export function* updateDepartmentAttributeRulesSaga(
  action: SVPAction<typeof UPDATE_DEPARTMENT_ATTRIBUTE_RULES>,
) {
  try {
    const { departmentSlug, attributeName, rules } = action
    yield call(
      injectorService.patch,
      `department-specifications/${departmentSlug}/attributes/${attributeName}`,
      { rule: rules },
    )
    yield call(performFetchDepartmentSpecs, departmentSlug)
  } catch (error) {
    yield call(errorAndNotification, error as APIError, 'Unable to update rules')
  }
}

export function* updateDepartmentAttributeViewsSaga(
  action: SVPAction<typeof UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS>,
) {
  try {
    const { departmentSlug, attributeName, views } = action
    yield call(
      injectorService.patch,
      `department-specifications/${departmentSlug}/attributes/${attributeName}`,
      { views },
    )
    yield call(performFetchDepartmentSpecs, departmentSlug)
  } catch (error) {
    yield call(errorAndNotification, error as APIError, 'Unable to update views')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_ADMIN_DEPARTMENTS_SPECS, getAdminDepartmentsSpecsSaga),
    takeEvery(ADD_DEPARTMENT_ATTRIBUTE, addDepartmentAttributeSaga),
    takeEvery(UPDATE_DEPARTMENT_ATTRIBUTE_RULES, updateDepartmentAttributeRulesSaga),
    takeEvery(UPDATE_DEPARTMENT_ATTRIBUTE_VIEWS, updateDepartmentAttributeViewsSaga),
  ])
}
