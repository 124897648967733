import { connect } from 'react-redux'
import {
  getDeprecatedProductFields,
  getHiddenColumns,
  getInvalidProductFields,
  getSortedColumns,
} from 'src/selectors/product'
import { GridAndControls } from 'src/components/GridAndControls'
import { getSelectedHierarchies } from 'src/selectors/hierarchy'
import { AppState } from 'src/reducers/root'
import { ExportPayload, GridType, IntentionalAny, Product } from 'src/types/index'
import { areDependenciesLoading, dependenciesHaveError } from '../../selectors/status'
import { getDepartmentSpecsForGrid, getViews } from '../../selectors/departmentSpecs'
import { getDepartmentsWithChildren } from '../../selectors/hierarchy'
import { getColumnRenderSpecs } from '../../selectors/referenceData/columns'
import { refreshDepartmentSpecs } from '../../actions/grid/departmentSpec'
import { importProducts } from '../../actions/grid/product/import'
import {
  getAllProducts,
  getLastPage,
  getLastPageLoaded,
  getPageLoading,
} from '../../selectors/product'
import { SortedColumn, updateSortedColumns } from '../../actions/grid/sortedColumns'
import { fetchFirstProductsPage, fetchNextProductsPage } from '../../actions/grid/product/fetch'
import { isGridLocked } from '../../selectors/grid'
import { getPollingProductSlugs } from '../../selectors/polling'
import { PatchPayload, updateProduct } from '../../actions/grid/product/update'
import { exportProducts } from 'actions/grid/product/export'
import { showErrorNotification } from 'actions/notification'
import { getAttributeValues } from 'selectors/referenceData/attributes'
import { setClipboardGridDestination, setClipboardGridSource } from 'actions/clipboard'
import { getClipboardGridDestination, getClipboardGridSource } from 'selectors/clipboard'
import { addExactFilter, Filter } from 'actions/grid/exactFilters'

type OwnProps = {
  gridName: GridType
}

const mapStateToProps = (state: AppState) => ({
  products: getAllProducts(state),
  sortedColumns: getSortedColumns(state),
  hiddenColumns: getHiddenColumns(state),
  invalidProductFields: getInvalidProductFields(state),
  deprecatedProductFields: getDeprecatedProductFields(state),
  isLoading: areDependenciesLoading(state),
  hasError: dependenciesHaveError(state),
  selectedHierarchy: getSelectedHierarchies(state)[0],
  gridViews: getViews(state),
  departmentsWithChildren: getDepartmentsWithChildren(state),
  departmentSpecs: getDepartmentSpecsForGrid(state),
  columnSpec: getColumnRenderSpecs(state),
  lastPage: getLastPage(state),
  lastPageLoaded: getLastPageLoaded(state),
  pageLoading: getPageLoading(state),
  isGridLocked: isGridLocked(state),
  pollingProductSlugs: getPollingProductSlugs(state),
  attributeValueMap: getAttributeValues(state),
  clipboardGridSource: getClipboardGridSource(state),
  clipboardGridDestination: getClipboardGridDestination(state),
})

const mapDispatchToProps = (dispatch: any, { gridName }: OwnProps) => ({
  updateProduct: (payload: PatchPayload): void => dispatch(updateProduct(payload, gridName)),
  fetchProducts: (): void => dispatch(fetchFirstProductsPage(gridName)),
  exportProducts: (payload: ExportPayload): void => dispatch(exportProducts(payload, gridName)),
  importProducts: (file: IntentionalAny): void => dispatch(importProducts(file)),
  updateSortedColumns: (payload: SortedColumn[]): void =>
    dispatch(updateSortedColumns(payload, gridName)),
  refreshDepartmentSpecs: (selectedHierarchy: string): void =>
    dispatch(refreshDepartmentSpecs(selectedHierarchy, gridName)),
  loadNextPage: (): void => dispatch(fetchNextProductsPage(gridName)),
  showErrorNotification: (message: string): void => dispatch(showErrorNotification(message)),
  setClipboardSource: (product: Product, property: string): void =>
    dispatch(setClipboardGridSource(product, property)),
  setClipboardDestination: (productSlug: string, property: string): void =>
    dispatch(setClipboardGridDestination(productSlug, property)),
  addExactFilter: (filter: Filter): void => dispatch(addExactFilter(filter, gridName)),
})

export const GridAndControlsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridAndControls)
