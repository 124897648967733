import React from 'react'
import Dropdown from 'components/Dropdown'
import { TextFormField } from 'components/TextFormField'
import { DropdownOption } from 'types/Dropdowns'
import PrimaryButton from 'components/PrimaryButton'
import {
  formItem,
  formRow,
  primaryButton,
  searchButton,
  searchFormContainer,
  resultsAvailable,
} from './StyleModalSearchForm.scss'
import classNames from 'classnames'
import { StyleModalSearchFormProps } from './StyleModalSearchFormContainer'

export const StyleModalSearchForm = (props: StyleModalSearchFormProps): JSX.Element => {
  const [styleName, setStyleName] = React.useState<string>(props.styleName)
  const [styleDescription, setStyleDescription] = React.useState<string>(props.styleDescription)
  const [styleID, setStyleID] = React.useState<string>(props.styleID)

  const onSearch = (): void => {
    props.onSearch({
      departmentSlug: (props.department.value || '') as string,
      styleGroupSlug: (props.styleGroup.value || '') as string,
      styleID: props.styleID,
      styleDescription: props.styleDescription,
      styleName: props.styleName,
      productPrint: (props.productPrint.value || '') as string,
    })
  }

  const customControlStyles = {
    border: '1px solid #eef0ef',
    '&:hover': {
      border: '1px solid #26B3AD',
    },
    height: '3rem',
  }

  const onChangeStyleName = (value: string): void => setStyleName(value)
  const onBlurStyleName = (): void => {
    props.setStyleName(styleName)
  }
  const onChangeStyleDescription = (value: string): void => setStyleDescription(value)
  const onBlurStyleDescription = (): void => {
    props.setStyleDescription(styleDescription)
  }
  const onChangeStyleID = (value: string): void => setStyleID(value)
  const onBlurStyleID = (): void => {
    props.setStyleId(styleID)
  }

  return (
    <>
      <div className={searchFormContainer}>
        <div className={formRow}>
          <div className={formItem}>
            <Dropdown
              isValid={true}
              name="department"
              selectedItem={props.department}
              required={false}
              header="Department"
              items={props.departments}
              createOption={(item: DropdownOption): DropdownOption => item}
              onItemSelected={props.setDepartment}
              disabled={false}
              customControlStyles={customControlStyles}
            />
          </div>
          <div className={formItem}>
            <Dropdown
              isValid={true}
              name="styleGroup"
              selectedItem={props.styleGroup}
              required={false}
              header="Style Group"
              items={props.styleGroups}
              createOption={(item: DropdownOption): DropdownOption => item}
              onItemSelected={props.setStyleGroup}
              disabled={false}
              customControlStyles={customControlStyles}
            />
          </div>
        </div>
        <div className={formRow}>
          <div className={formItem}>
            <TextFormField
              name="styleName"
              value={styleName}
              onBlur={onBlurStyleName}
              onChange={onChangeStyleName}
              hasFocus={false}
              header="Style Name"
              required={false}
              validationErrors={[]}
              disabled={false}
            />
          </div>
          <div className={formItem}>
            <Dropdown
              isValid={true}
              name="prints"
              selectedItem={props.productPrint}
              required={false}
              header="Print"
              items={props.prints}
              createOption={(item: DropdownOption): DropdownOption => item}
              onItemSelected={props.setProductPrint}
              disabled={false}
              customControlStyles={customControlStyles}
            />
          </div>
        </div>
        <div className={formRow}>
          <div className={formItem}>
            <TextFormField
              name="styleDescription"
              value={styleDescription}
              onBlur={onBlurStyleDescription}
              onChange={onChangeStyleDescription}
              hasFocus={false}
              header="Style Description"
              required={false}
              validationErrors={[]}
              disabled={false}
            />
          </div>
          <div className={formItem}>
            <TextFormField
              name="styleID"
              value={styleID}
              onBlur={onBlurStyleID}
              onChange={onChangeStyleID}
              hasFocus={false}
              header="Style ID"
              required={false}
              validationErrors={[]}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <div className={classNames(searchButton, { [resultsAvailable]: props.hasResults })}>
        {props.hasResults && (
          <PrimaryButton
            disabled={false}
            className={primaryButton}
            onClick={props.onResults}
            colour="black"
            dataCy="showResultsButton"
          >
            Results
          </PrimaryButton>
        )}
        <PrimaryButton
          disabled={props.isSearchStyleLoading}
          className={primaryButton}
          onClick={onSearch}
          colour="green"
          dataCy="searchStylesButton"
        >
          Search
        </PrimaryButton>
      </div>
    </>
  )
}
