import { GridType } from 'src/types/index'

export const UPDATE_SORTED_COLUMNS = 'UPDATE_SORTED_COLUMNS'
export const UPDATE_SORTED_COLUMNS_SUCCESS = 'UPDATE_SORTED_COLUMNS_SUCCESS'

export type SortedColumnsActions = ReturnType<
  typeof updateSortedColumns | typeof updateSortedColumnsSuccess
>

export type SortedColumn = {
  id: string
  desc: boolean
}

export const updateSortedColumns = (payload: SortedColumn[], grid: GridType) =>
  <const>{
    type: UPDATE_SORTED_COLUMNS,
    payload,
    grid,
  }

export const updateSortedColumnsSuccess = (payload: SortedColumn[], grid: GridType) =>
  <const>{
    type: UPDATE_SORTED_COLUMNS_SUCCESS,
    payload,
    grid,
  }
