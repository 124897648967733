// @ts-nocheck as the discrete value vs dropdown options differences
import { connect } from 'react-redux'
import DropdownCellRenderer from '../DropdownCellRenderer'
import { AppState } from 'src/reducers/root'
import { DiscreteValue } from '../../../../types/Attributes'

type OwnProps = {
  accessor: string
}

const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
})

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  dropdownItems: state.injectorUI.referenceData.attributes.discreteValues[ownProps.accessor],
})

const mergeProps = (stateProps: AppState, dispatchProps: any, ownProps: OwnProps) => ({
  ...stateProps,
  ...ownProps,
  createOption: (item: DiscreteValue) => ({
    ...item,
    data: item,
  }),
  createPatchItem: (item: DiscreteValue) => {
    return {
      apiValue: item.value,
      storeValue: item.value,
    }
  },
  noSort: true,
  displayFormatter: (value: number) => currencyFormatter.format(value / 1000),
  customFilterOption: ({ value }: DiscreteValue, searchString: string) =>
    (value / 1000).toString().startsWith(searchString),
})

export const CurrencyDropdownCellRendererContainer = connect(
  mapStateToProps,
  null,
  mergeProps,
)(DropdownCellRenderer)
