import { getCommsPdfMeta, uploadCommsPdf } from 'actions/admin/commsScreen'
import { showErrorNotification } from 'actions/notification'
import { connect } from 'react-redux'
import { AppState } from 'reducers/root'
import { getCommsFileMeta } from 'selectors/commsScreen'
import { CommsScreenTool } from './CommsScreenTool'

const mapStateToProps = (state: AppState) => ({
  fileMeta: getCommsFileMeta(state),
})

const mapDispatchToProps = {
  getCommsPdfMeta,
  uploadCommsPdf,
  showErrorNotification,
}

export const CommsScreenToolContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommsScreenTool)
