import { connect } from 'react-redux'
import { ImagesSection } from './ImagesSection'
import { AppState } from '../../reducers/root'

import {
  updateImages,
  UpdateImagesPayload,
  saveSketch,
  SaveSketchPayload,
} from 'src/actions/images'
import { getIndexesOfSavingImages } from 'src/selectors/images'
import { showErrorNotification } from 'src/actions/notification'
import { GridType } from 'src/types/index'

type OwnProps = {
  developmentId: string
  productSlug: string
  gridName: GridType
}
type MapStateType = { indexesOfSavingImages: number[] }
type MapPropsType = {
  updateImages: (updateImagesPayload: UpdateImagesPayload) => void
  showErrorNotification: (message: string) => void
  saveSketch: (saveSketchPayload: SaveSketchPayload) => void
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): MapStateType => ({
  indexesOfSavingImages: getIndexesOfSavingImages(state, ownProps.productSlug),
})

const mapDispatchToProps = (dispatch: any, { gridName, productSlug }: OwnProps): MapPropsType => ({
  saveSketch: (saveSketchPayload: SaveSketchPayload): void =>
    dispatch(saveSketch(saveSketchPayload, gridName)),
  updateImages: (updateImagesPayload: UpdateImagesPayload): void =>
    dispatch(updateImages(updateImagesPayload, productSlug, gridName)),
  showErrorNotification: (message: string): void => dispatch(showErrorNotification(message)),
})

export const ImagesSectionContainer = connect(mapStateToProps, mapDispatchToProps)(ImagesSection)
