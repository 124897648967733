import React, { Component } from 'react'
import classnames from 'classnames'
import { ColumnHeader } from './ColumnHeader/ColumnHeader'
import { CurrencyKeys, IntentionalAny } from 'src/types/index'

import { column } from './Column.scss'

type Props = {
  title: string
  currency?: CurrencyKeys
  children?: IntentionalAny
  columnStyle?: IntentionalAny
  titleStyle?: string
  showHeader?: boolean
  testId?: string
  onCurrencyChange?: (currency: CurrencyKeys) => void
}

export default class Column extends Component<Props> {
  render(): JSX.Element {
    const { title, children, columnStyle, titleStyle, showHeader, testId, onCurrencyChange } =
      this.props

    const mergedColumnStyle = classnames(columnStyle, column)

    return (
      <div className={mergedColumnStyle} data-cy={testId}>
        <ColumnHeader
          title={title}
          className={titleStyle}
          hide={!showHeader}
          currency={this.props.currency}
          onCurrencyChange={onCurrencyChange}
        />

        {children}
      </div>
    )
  }
}
