import * as React from 'react'
import { TAB_KEY, DOWN_KEY, UP_KEY, LEFT_KEY, RIGHT_KEY, NAV_KEYS } from '../../constants/keyCodes'
import { keyboardNavigation } from './KeyboardNavigation.scss'
import {
  KeyboardNavigationContext,
  SelectedCell,
  RITableContext,
} from './KeyboardNavigationContext'

type State = RITableContext

type Props = {
  children: React.ReactNode
  numberOfRows: number
  numberOfColumns: number
  className?: string
}

type ConnectedProps = Props & {
  highlightedRows: string[]
  clearHighlightedRows: () => void
}

export class KeyboardNavigation extends React.Component<ConnectedProps, State> {
  node: any

  constructor(props: ConnectedProps) {
    super(props)
    this.state = {
      selected: {
        row: 0,
        column: 0,
      },
      updatingSelected: false,
      resetSelection: this.resetSelection,
      selectCell: this.selectCell,
      focusInGrid: false,
      leaveGrid: this.leaveGrid,
      allowFocusInGrid: this.allowFocusInGrid,
      focusAllowedInGrid: true,
    }
  }

  selectCell = (selectedCell: SelectedCell) => {
    if (
      selectedCell.row !== this.state.selected.row ||
      selectedCell.column !== this.state.selected.column
    ) {
      this.setState({
        selected: selectedCell,
      })
    }
  }

  clearHighlighted = () => {
    if (this.props.highlightedRows.length > 0) {
      this.props.clearHighlightedRows()
    }
  }

  resetSelection = () => {
    this.setState({ selected: { row: 0, column: 0 } })
  }

  enterGrid = () => {
    this.setState({ focusInGrid: true })
  }

  leaveGrid = () => {
    this.clearHighlighted()
    this.setState({ focusInGrid: false })
  }

  allowFocusInGrid = (focusAllowedInGrid: boolean) => {
    this.setState({ focusAllowedInGrid })
  }

  onFocus = () => {
    if (!this.state.focusInGrid && this.state.focusAllowedInGrid) {
      this.enterGrid()
    }
  }

  onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (e.currentTarget === this.node && this.node && !this.node.contains(e.relatedTarget)) {
      this.leaveGrid()
    }
  }

  componentDidMount = () => {
    this.node && this.node.addEventListener('keydown', this.handleKeyPress)
  }

  moveRight = () => {
    const { numberOfColumns } = this.props
    const {
      selected: { row, column },
    } = this.state
    this.setState({
      updatingSelected: true,
      selected: {
        row,
        column: Math.min(numberOfColumns - 1, column + 1),
      },
    })
    this.setState({
      updatingSelected: false,
    })
  }

  moveLeft = () => {
    const {
      selected: { row, column },
    } = this.state
    this.setState({
      updatingSelected: true,
      selected: {
        row,
        column: Math.max(0, column - 1),
      },
    })
    this.setState({
      updatingSelected: false,
    })
  }

  moveUp = () => {
    const {
      selected: { row, column },
    } = this.state
    this.setState({
      updatingSelected: true,
      selected: {
        row: Math.max(0, row - 1),
        column,
      },
    })
    this.setState({
      updatingSelected: false,
    })
  }

  moveDown = () => {
    const { numberOfRows } = this.props
    const {
      selected: { row, column },
    } = this.state
    this.setState({
      updatingSelected: true,
      selected: {
        row: Math.min(numberOfRows - 1, row + 1),
        column,
      },
    })
    this.setState({
      updatingSelected: false,
    })
  }

  onClick = () => {
    this.clearHighlighted()
  }

  handleKeyPress = (event: KeyboardEvent) => {
    this.clearHighlighted()

    const eventTargetIsCell =
      event.target instanceof HTMLDivElement && event.target.classList.contains('rt-td')
    if (!eventTargetIsCell) {
      return
    }

    if (NAV_KEYS.includes(event.key) && eventTargetIsCell) {
      event.preventDefault()
    }
    switch (event.key) {
      case TAB_KEY:
        if (event.shiftKey) {
          this.moveLeft()
        } else {
          this.moveRight()
        }
        break
      case RIGHT_KEY: {
        this.moveRight()
        break
      }
      case DOWN_KEY: {
        this.moveDown()
        break
      }
      case UP_KEY: {
        this.moveUp()
        break
      }
      case LEFT_KEY: {
        this.moveLeft()
        break
      }
    }
  }

  componentWillUnmount() {
    this.node && this.node.removeEventListener('keydown', this.handleKeyPress, false)
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (
      this.props.highlightedRows !== prevProps.highlightedRows &&
      this.props.highlightedRows.length > 0
    ) {
      this.setState((prevState) => ({
        selected: {
          row: prevState.selected.row + 1,
          column: prevState.selected.column + 1,
        },
      }))
    }
  }

  render() {
    const className = `${keyboardNavigation} ${this.props.className || ''}`.trim()
    return (
      <KeyboardNavigationContext.Provider value={this.state}>
        <div
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onClick={this.onClick}
          tabIndex={-1}
          ref={(n) => (this.node = n)}
          className={className}
        >
          {this.props.children}
        </div>
      </KeyboardNavigationContext.Provider>
    )
  }
}
