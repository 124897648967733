import { RangeWithSize, SizesDeprecationMap } from 'types/Hierarchy'

export const GET_DEPARTMENT_RANGES_WITH_SIZES = 'GET_DEPARTMENT_RANGES_WITH_SIZES'
export const GET_DEPARTMENT_RANGES_WITH_SIZES_SUCCESS = 'GET_DEPARTMENT_RANGES_WITH_SIZES_SUCCESS'
export const UPDATE_DEPARTMENT_RANGE_SIZE = 'UPDATE_DEPARTMENT_RANGE_SIZE'
export const UPDATE_DEPARTMENT_RANGE_SIZE_SUCCESS = 'UPDATE_DEPARTMENT_RANGE_SIZE_SUCCESS'
export const GET_DEPARTMENT_RANGES_WITH_SIZES_FETCHING = 'GET_DEPARTMENT_RANGES_WITH_SIZES_FETCHING'
export const GET_DEPARTMENT_RANGES_WITH_SIZES_FETCH_DONE =
  'GET_DEPARTMENT_RANGES_WITH_SIZES_FETCH_DONE'

export type UserSettingsSizesActions = ReturnType<
  | typeof getDepartmentRangesWithSizes
  | typeof getDepartmentRangesWithSizesSuccess
  | typeof updateDepartmentRangeSize
  | typeof updateDepartmentRangeSizeSuccess
  | typeof getDepartmentRangesWithSizesFetching
  | typeof getDepartmentRangesWithSizesFetchDone
>

export const getDepartmentRangesWithSizesFetching = () =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_SIZES_FETCHING,
  }

export const getDepartmentRangesWithSizesFetchDone = () =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_SIZES_FETCH_DONE,
  }

export const getDepartmentRangesWithSizes = (slug: string) =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_SIZES,
    slug,
  }

export const getDepartmentRangesWithSizesSuccess = (
  rangesWithSizes: RangeWithSize[],
  sizesDeprecationMap: SizesDeprecationMap,
) =>
  <const>{
    type: GET_DEPARTMENT_RANGES_WITH_SIZES_SUCCESS,
    rangesWithSizes,
    sizesDeprecationMap,
  }

export const updateDepartmentRangeSize = (idSizeDenominations: number, isDeprecated: boolean) =>
  <const>{
    type: UPDATE_DEPARTMENT_RANGE_SIZE,
    idSizeDenominations,
    isDeprecated,
  }

export const updateDepartmentRangeSizeSuccess = (
  idSizeDenominations: number,
  isDeprecated: boolean,
) =>
  <const>{
    type: UPDATE_DEPARTMENT_RANGE_SIZE_SUCCESS,
    idSizeDenominations,
    isDeprecated,
  }
