import { getWsReadyWeekValues } from 'src/service/product'
import { DropdownOption } from 'src/types/Dropdowns'
import { AttributeValue } from 'src/types/index'

type UiMapper = {
  getValues: () => DropdownOption[]
  getProps: () => void
}

const wsReadyWeekMapper = (): DropdownOption[] =>
  getWsReadyWeekValues().map((item: AttributeValue) => ({
    value: item.value,
    label: item.value,
    deprecated: item.deprecated,
  }))

const priceStatusMapper = (): DropdownOption[] => [
  {
    value: 'mo',
    label: 'Markdown Only',
  },
  {
    value: 'im',
    label: 'Include Markdown',
  },
  {
    value: 'em',
    label: 'Exclude Markdown',
  },
]

const uiDropdownLookup: Record<string, UiMapper> = {
  wsReadyWeek: {
    getValues: wsReadyWeekMapper,
    getProps: () => ({
      noSort: true,
    }),
  },
  priceStatus: {
    getValues: priceStatusMapper,
    getProps: () => ({
      noSort: true,
    }),
  },
}

export const createEmptyPatchItem = (): { apiValue: string; storeValue: string } => ({
  apiValue: '',
  storeValue: '',
})

export const uiDropdownItemsMapper = (columnName: string): UiMapper => uiDropdownLookup[columnName]
