import React from 'react'
import {
  exportIcon,
  icon,
  link,
  multiSelectButton,
  selected,
} from '../../../GridControls/GridControls.scss'
import classNames from 'classnames'
import { MultiSelectIcon } from '../../../../assets/icons'
import { GridType } from 'src/types/index'
import Link from '../../../Link'

type Props = {
  multiSelect: boolean
  gridName: GridType
  updateMultiSelectMode: (type: GridType) => void
  locked: boolean
}

export const ActionsHeader = ({ multiSelect, updateMultiSelectMode, gridName, locked }: Props) => {
  const multiSelectClass = classNames(link, icon, multiSelectButton, {
    [selected]: multiSelect,
  })

  return (
    <Link
      className={multiSelectClass}
      onClick={() => updateMultiSelectMode(gridName)}
      dataCy="multiSelectButton"
      disabled={locked}
    >
      <MultiSelectIcon className={exportIcon} selected={multiSelect} />
    </Link>
  )
}
