import { FETCH_REFERENCE_DATA_SUCCESS } from '../../actions/referenceData/referenceData'

import { Season } from 'src/types/index'
import { SVPAction } from 'actions/svp'

const defaultState: Season[] = []

export const seasons = (state: Season[] = defaultState, action: SVPAction) => {
  switch (action.type) {
    case FETCH_REFERENCE_DATA_SUCCESS:
      return action.seasons
    default:
      return state
  }
}
