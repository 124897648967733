import React, { Component } from 'react'

import { Product, GridType, OPTION_LIBRARY } from 'src/types/index'
import { CANCELLED } from 'src/constants/productStatus'
import { DEFAULT_GROUP, MINI_GROUP, MINI_EXT_GROUP, TEEN_GROUP } from 'src/constants/productSizes'
import { ProductPrice } from 'src/types/Product'

import { priceCell } from './PriceCellRenderer.scss'
interface Props {
  grid: GridType
  accessor: string
  product: Product
  locked: boolean
  currency: string
  editMode: boolean
  exitEditMode: (exit: boolean) => void
  viewSizes: (hierachy: string, slug: string) => void
}

export class PriceCellRenderer extends Component<Props> {
  componentDidUpdate(): void {
    const { editMode, exitEditMode, product, grid, locked, viewSizes } = this.props
    if (editMode && this.shouldShowModal(product.status, grid, locked)) {
      viewSizes(product.hierarchySlug, product.slug)
      exitEditMode(true)
    }
  }

  shouldShowModal(status: string, grid: GridType, locked: boolean): boolean {
    return grid === OPTION_LIBRARY && status !== CANCELLED && !locked
  }

  render(): JSX.Element {
    const { product, accessor, currency } = this.props
    const price: ProductPrice = product[accessor] || {}
    return (
      <>
        <div className={priceCell}>
          {price[DEFAULT_GROUP] && (
            <div data-cy={`${accessor}-default`}>{`${currency}${price[DEFAULT_GROUP]}`}</div>
          )}
          {price[MINI_GROUP] && (
            <div data-cy={`${accessor}-mini`}>{`${currency}${price[MINI_GROUP]}`}</div>
          )}
          {price[MINI_EXT_GROUP] && (
            <div data-cy={`${accessor}-mini-ext`}>{`${currency}${price[MINI_EXT_GROUP]}`}</div>
          )}
          {price[TEEN_GROUP] && (
            <div data-cy={`${accessor}-teen`}>{`${currency}${price[TEEN_GROUP]}`}</div>
          )}
        </div>
      </>
    )
  }
}
