import { GridType } from 'src/types/index'
import { AppState } from '../root'

// NOTE this function deliberately filters out any actions that dont have the corresponding grid name in the body
// If you see actions not getting through to specific reducers, this is why
export const createGridSpecificReducer =
  (reducerFunction: any, gridName: GridType) => (state: AppState, action: any) => {
    const isInitializationCall = state === undefined
    if (action.grid !== gridName && !isInitializationCall) return state

    return reducerFunction(state, action)
  }
