import React from 'react'
import { active, link, linksList, linksListItem } from '../GridControls.scss'
import Link from '../../Link'
import { GridViews, View } from 'src/types/index'

type Props = {
  views: GridViews
  onViewChange: (view: View) => void
  selectedView: View
}

export const ViewButtons = ({ views, selectedView, onViewChange }: Props): JSX.Element => (
  <ul className={linksList}>
    {Object.keys(views).map((viewName, index) => {
      const view = views[viewName]
      return (
        <li
          key={index}
          className={`${linksListItem} ${selectedView.slug === view.slug ? active : ''}`}
        >
          <Link className={link} onClick={(): void => onViewChange(view)}>
            {view.displayName}
          </Link>
        </li>
      )
    })}
  </ul>
)
