import { all, call, put, takeEvery } from 'redux-saga/effects'
import injectorService from 'src/service/injector/index'
import {
  FETCH_PRODUCT_COMMENTS,
  fetchProductComments,
  fetchProductCommentsFailure,
  fetchProductCommentsSuccess,
  POST_PRODUCT_COMMENT,
  postProductCommentFailure,
  postProductCommentSuccess,
} from 'src/actions/comments'
import { logError } from 'src/service/errors/index'
import { apiCommentsToComments } from 'src/service/mappers'
import { errorAndNotification } from './error'
import { ApiComment } from 'src/types/responses/comments'
import { trackEvent } from 'src/service/analytics/analytics'
import { APIError } from 'src/types/Api'
import { SVPAction } from 'actions/svp'

export function* fetchProductCommentsSaga(action: SVPAction<typeof FETCH_PRODUCT_COMMENTS>) {
  try {
    const apiComments: ApiComment[] = yield call(
      injectorService.get,
      `comments/${action.productSlug}`,
    )
    const comments = apiComments.map(apiCommentsToComments)

    yield put(fetchProductCommentsSuccess(action.productSlug, comments, action.grid))
  } catch (err) {
    const error = err as Error
    yield call(logError, error)
    yield put(fetchProductCommentsFailure(action.productSlug, error?.message?.toString()))
  }
}

export function* postProductCommentSaga(action: SVPAction<typeof POST_PRODUCT_COMMENT>) {
  try {
    trackEvent('product', 'Comment Added For Product')

    yield call(injectorService.post, `comments/${action.productSlug}`, action.comment)
    yield put(postProductCommentSuccess(action.productSlug))
    yield put(fetchProductComments(action.productSlug, action.grid))
  } catch (err) {
    const error = err as APIError
    yield put(postProductCommentFailure(action.productSlug, error?.message?.toString()))
    yield* errorAndNotification(error, 'Sorry your comment has failed to save. Please retry')
  }
}

export default function* () {
  yield all([
    takeEvery(FETCH_PRODUCT_COMMENTS, fetchProductCommentsSaga),
    takeEvery(POST_PRODUCT_COMMENT, postProductCommentSaga),
  ])
}
