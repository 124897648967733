import { GridType } from 'src/types/index'

export const SELECT_HIERARCHY = 'SELECT_HIERARCHY'
export const SELECT_HIERARCHIES = 'SELECT_HIERARCHIES'
export const CLEAR_AND_SELECT_HIERARCHY = 'CLEAR_AND_SELECT_HIERARCHY'
export const DESELECT_HIERARCHY = 'DESELECT_HIERARCHY'
export const UPDATE_EXPANDED_HIERARCHY_NODES = 'UPDATE_EXPANDED_HIERARCHY_NODES'

export type HierarchyActions = ReturnType<
  | typeof updateExpandedHierarchyNodes
  | typeof selectHierarchy
  | typeof selectHierarchies
  | typeof clearAndSelectHierarchy
  | typeof deselectHierarchy
>

export const updateExpandedHierarchyNodes = (payload: string[], grid: GridType) =>
  <const>{
    type: UPDATE_EXPANDED_HIERARCHY_NODES,
    payload,
    grid,
  }

export const selectHierarchy = (slug: string, grid: GridType) =>
  <const>{
    type: SELECT_HIERARCHY,
    slug,
    grid,
  }

export const selectHierarchies = (slugs: string[], grid: GridType) =>
  <const>{
    type: SELECT_HIERARCHIES,
    slugs,
    grid,
  }

export const clearAndSelectHierarchy = (slug: string, grid: GridType) =>
  <const>{
    type: CLEAR_AND_SELECT_HIERARCHY,
    slug,
    grid,
  }

export const deselectHierarchy = (slug: string, grid: GridType) =>
  <const>{
    type: DESELECT_HIERARCHY,
    slug,
    grid,
  }
