import React, { useEffect, useState } from 'react'
import { WholesaleImage, WholesaleImageResequencePayload } from 'src/types/WholesaleImages'
import {
  wsImagesSectionContainer,
  imagePlaceholderContainer,
  dropzone,
  wsImagesContainer,
  disabledDropzone,
  wholesaleImagesSpinner,
  wsImagesScrollArea,
} from './WholesaleImagesSection.scss'
import { FileError, useDropzone } from 'react-dropzone'
import classnames from 'classnames'
import { Image } from './Image/Image'
import {
  validateFilesCount,
  validateWholesaleImage,
  getLocalFile,
  LocalFile,
} from 'src/service/product/wholesaleImages'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'
import { UploadSpinner } from 'src/components/UploadSpinner'
import { WHOLESALE_IMAGES_SLOTS } from 'src/constants/errorCodes'

interface WholesaleImagesSectionProps {
  productSlug: string
  developmentId: string
  wholesaleImages: WholesaleImage[]
  isLoading: boolean
  isWsReadyWeekSet: boolean
  uploadWholesaleImageFile: (
    productSlug: string,
    developmentId: string,
    files: File[],
    sequence: number,
  ) => void
  getWholesaleImages: (productSlug: string, imagesToRefresh: number[]) => void
  showErrorNotification: (message: string) => void
  clearWholesaleImages: () => void
  updateWholesaleImagesSequence: (
    productSlug: string,
    payload: WholesaleImageResequencePayload[],
  ) => void
  replaceWholesaleImageFile: (
    slug: string,
    productSlug: string,
    sequence: number,
    file: File,
  ) => void
  removeWholesaleImageFile: (slug: string, productSlug: string) => void
}

interface ReplaceWsImage {
  slug: string
  sequence: number
}

export const WholesaleImagesSection = (props: WholesaleImagesSectionProps): JSX.Element => {
  const disabledUpload = props.wholesaleImages.length === WHOLESALE_IMAGES_SLOTS
  const [dataSequence, setDataSequence] = useState<string | null>(null)
  const [replaceWsImage, setReplaceWsImage] = useState<ReplaceWsImage | null>(null)
  const [removeWsImageSlug, setRemoveWsImageSlug] = useState<string>('')
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpg, image/png, image/jpeg',
    onDrop: async (files) => await onDrop(files),
    noClick: true,
    noKeyboard: true,
  })

  async function onDrop(acceptedFiles: File[]): Promise<void | undefined> {
    const availableSlots = WHOLESALE_IMAGES_SLOTS - props.wholesaleImages.length
    let validationError: FileError | null = await validateFilesCount(
      acceptedFiles,
      replaceWsImage ? 1 : availableSlots,
    )
    if (validationError !== null) {
      props.showErrorNotification(validationError.message)
      return
    }

    const localFiles: Array<LocalFile> = []
    await getLocalFile(acceptedFiles)
      .then((result: LocalFile[]) => {
        result.forEach((localFile) => {
          localFiles.push(localFile)
        })
      })
      .catch((error) => {
        validationError = error
      })

    if (validationError !== null) {
      props.showErrorNotification((validationError as FileError)?.message)
      return
    }

    for (const image of localFiles) {
      const error: FileError | null = validateWholesaleImage(image)
      if (error !== null) {
        props.showErrorNotification(error.message)
        return
      }
    }

    if (replaceWsImage) {
      props.replaceWholesaleImageFile(
        replaceWsImage.slug,
        props.productSlug,
        replaceWsImage.sequence,
        acceptedFiles[0],
      )
      setReplaceWsImage(null)
    } else {
      const lastAvailableSequence: number =
        (props.wholesaleImages.sort((a, b) => a.sequence - b.sequence)[
          props.wholesaleImages.length - 1
        ]?.sequence || 0) + 1
      props.uploadWholesaleImageFile(
        props.productSlug,
        props.developmentId,
        acceptedFiles,
        lastAvailableSequence,
      )
    }
  }

  useEffect(() => {
    props.getWholesaleImages(props.productSlug, [])

    return (): void => props.clearWholesaleImages()
  }, [props.developmentId])

  const handleDrag = (ev: React.DragEvent<HTMLDivElement>): void =>
    setDataSequence(ev.currentTarget.getAttribute('data-sequence'))

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>): void => {
    const dragImage = props.wholesaleImages.find(
      (wsImage) => wsImage.sequence === parseInt(dataSequence as string),
    )
    const dropImage = props.wholesaleImages.find(
      (wsImage) =>
        wsImage.sequence === parseInt(ev.currentTarget.getAttribute('data-sequence') as string),
    )
    if (dragImage && dropImage) {
      if (dragImage.sequence === dropImage.sequence) {
        return
      }

      const updatedImages: WholesaleImageResequencePayload[] = [
        {
          slug: dragImage.slug,
          sequence: dropImage.sequence,
        },
        {
          slug: dropImage.slug,
          sequence: dragImage.sequence,
        },
      ]

      props.updateWholesaleImagesSequence(props.productSlug, updatedImages)
      setDataSequence(null)
    }
  }

  const handleReplace = (wsImageSlug: string, wsImageSequence: number): void => {
    setReplaceWsImage({
      slug: wsImageSlug,
      sequence: wsImageSequence,
    })
    setTimeout(open)
  }

  const handleOpen = (): void => {
    if (disabledUpload) {
      return
    }
    setTimeout(open)
  }

  const handleShouldRemove = (slug: string): void => {
    setShowConfirmationModal(true)
    setRemoveWsImageSlug(slug)
  }

  const dismissConfirmationModal = (): void => {
    setRemoveWsImageSlug('')
    setShowConfirmationModal(false)
  }

  const handleRemove = (): void => {
    props.removeWholesaleImageFile(removeWsImageSlug, props.productSlug)
    setRemoveWsImageSlug('')
    setShowConfirmationModal(false)
  }

  return (
    <>
      {props.isWsReadyWeekSet && (
        <div data-cy="ws-images-ready-week-text">
          WS Ready Week has been entered, you will need to inform the wholesale team if you delete
          any images
        </div>
      )}
      <div className={wsImagesScrollArea}>
        <div className={wsImagesSectionContainer}>
          <div className={wsImagesContainer} data-cy="ws-images-container">
            {[...Array(WHOLESALE_IMAGES_SLOTS).keys()].map((imageIndex: number) => (
              <div className={imagePlaceholderContainer} key={`image-container-${imageIndex}`}>
                <Image
                  wsImage={props.wholesaleImages.find(
                    (wsImage) => wsImage.sequence === imageIndex + 1,
                  )}
                  draggedImageSequence={dataSequence || ''}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  handleReplace={handleReplace}
                  handleRemove={handleShouldRemove}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          {...getRootProps({
            className: classnames(dropzone, { [disabledDropzone]: disabledUpload }),
          })}
          onClick={handleOpen}
          data-cy="ws-images-dropzone-container"
        >
          <input {...getInputProps()} />
          <span>Drag and drop image(s) here, or click to browse</span>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            primaryButtonDisabled={false}
            text="Are you sure you want to delete this image?"
            onCloseModalClick={dismissConfirmationModal}
            onConfirmClick={handleRemove}
          />
        )}
        {props.isLoading && (
          <div className={wholesaleImagesSpinner} data-cy="ws-images-loading-spinner">
            <UploadSpinner />
          </div>
        )}
      </div>
    </>
  )
}
