import React from 'react'
import { Carousel } from '../Carousel'
import { imagesSection, buttons, title, dropzone } from './ImagesSection.scss'
import { ImageInput } from '../ImageInput/ImageInput'
import { SaveSketchPayload, UpdateImagesPayload } from 'src/actions/images'
import { Image, ImageTypes } from '../../types/Images'
import Dropzone from 'react-dropzone'
import { extractImage } from 'src/service/image/processImage'

type Props = {
  indexesOfSavingImages: number[]
  images: Image[]
  developmentId: string
  productSlug: string
  updateImages: (payload: UpdateImagesPayload) => void
  showErrorNotification: (message: string) => void
  saveSketch: (payload: SaveSketchPayload) => void
}

type State = {
  currentIndex: number
  indexesOfParsingImages: number[]
}

const getImageType = (index: number): ImageTypes => (index > 0 ? 'sample' : 'sketch')

const getIndexesThatAreParsing = (parsing: boolean, prevState: State, index?: number): number[] =>
  parsing
    ? ([...prevState.indexesOfParsingImages, index] as number[])
    : (prevState.indexesOfParsingImages.filter((i) => i !== index) as number[])

export class ImagesSection extends React.Component<Props, State> {
  state = {
    indexesOfParsingImages: [],
    currentIndex: this.props.images.length - 1,
  }

  onDrop = async (acceptedFiles: File[]): Promise<void> => {
    const file = await extractImage(acceptedFiles[0])
    if (this.props.images.length === 0) {
      this.props.saveSketch({
        developmentId: this.props.developmentId,
        productSlug: this.props.productSlug,
        sketch: file,
      })
    } else {
      this.props.updateImages({
        file,
        type: getImageType(this.state.currentIndex),
        index: this.state.currentIndex,
      })
    }
  }
  onCarouselChange = ({ currentIndex }: { currentIndex: number }): void => {
    this.setState({ currentIndex })
  }

  onInputChange =
    (index: number) =>
    (file?: string): void => {
      file &&
        this.props.updateImages({
          file,
          type: getImageType(index),
          index,
        })
    }

  updateParsingIndexes = (parsing: boolean, index?: number): void => {
    this.setState((prevState: State) => ({
      indexesOfParsingImages: getIndexesThatAreParsing(parsing, prevState, index),
    }))
  }

  render(): JSX.Element {
    const { currentIndex, indexesOfParsingImages } = this.state
    const { images, showErrorNotification, indexesOfSavingImages } = this.props

    const imageCount = images.length
    const handleChange = this.onInputChange(currentIndex)
    const indexesThatAreLoading = [
      ...new Set([...indexesOfParsingImages, ...indexesOfSavingImages]),
    ]

    const getDragAndDropMessage = (): string => {
      if (imageCount === 0) {
        return 'Upload Sketch - Drag and drop image or click to browse'
      }
      if (imageCount === 1) {
        if (this.state.currentIndex === 0) {
          return 'Replace Sketch - Drag and drop image or click to browse'
        }
        if (this.state.currentIndex === 1) {
          return 'Upload Sample - Drag and drop image or click to browse'
        }
      }
      if (imageCount === 2) {
        if (this.state.currentIndex === 0) {
          return 'Replace Sketch - Drag and drop image or click to browse'
        }
        if (this.state.currentIndex === 1) {
          return 'Replace Sample - Drag and drop image or click to browse'
        }
      }
    }

    return (
      <div className={imagesSection}>
        {currentIndex >= 0 && imageCount > 0 && (
          <div className={title}>
            <h4>{currentIndex === 0 ? 'Sketch' : 'Sample'}</h4>
          </div>
        )}

        <Carousel
          images={images}
          initialIndex={currentIndex}
          onChange={this.onCarouselChange}
          indexesThatAreLoading={indexesThatAreLoading}
        />
        <div className={buttons}>
          <ImageInput
            id="editImage"
            // @ts-ignore
            onChange={handleChange}
            onParsing={this.updateParsingIndexes}
            onError={showErrorNotification}
            currentIndex={currentIndex}
            render={(): JSX.Element => (
              <Dropzone onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }): JSX.Element => (
                  <section>
                    <div {...getRootProps({ className: dropzone })}>
                      <input data-cy="dropzone-area" {...getInputProps()} />
                      <span data-cy="message-area">{getDragAndDropMessage()}</span>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          />
        </div>
      </div>
    )
  }
}
