import React from 'react'
import { validationMessage, textAreaWrapper, textAreaHasError } from './TextArea.scss'
import { setFocus } from 'src/service/ref'
import { ENTER_KEY } from 'src/constants/keyCodes'
import { getTextValidationErrors } from '../../service/product/validateUtils'

type Props = {
  name: string
  onSave: (value: string, isValid: boolean) => void
  value?: string
  isMandatory: boolean
  validator?: (value: string) => string[]
}

type State = {
  value: string
  validationError: string[]
}

const selectText = (ref: any) => ref && ref.select()

const validate = (
  value: string,
  isMandatory: boolean,
  validator?: (value: string) => string[],
): string[] => (validator ? validator(value) : getTextValidationErrors(value, isMandatory))

const sanitize = (str: string): string => str.replace(/\s+/g, ' ').trim()

class TextArea extends React.Component<Props, State> {
  textArea: any
  valueHasChange: boolean
  initialValue: string

  constructor(props: Props) {
    super(props)
    this.initialValue = props.value || ''
    this.valueHasChange = false
    this.state = {
      value: this.initialValue,
      validationError: validate(this.initialValue, props.isMandatory, props.validator),
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    this.valueHasChange =
      this.state.value !== prevState.value && this.state.value !== this.initialValue
  }

  componentDidMount(): void {
    setFocus(this.textArea)
    selectText(this.textArea)
  }

  onChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value
    const validationError = validate(value, this.props.isMandatory, this.props.validator)
    this.setState({ value, validationError })
  }

  isValid = (): boolean => this.state.validationError.length === 0

  onBlur = (): void => {
    this.valueHasChange && this.props.onSave(sanitize(this.state.value), this.isValid())
  }

  onKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === ENTER_KEY) {
      this.valueHasChange && this.props.onSave(sanitize(this.state.value), this.isValid())
    }
  }

  render(): JSX.Element {
    const { name } = this.props
    const { validationError, value } = this.state
    return (
      <div className={`${textAreaWrapper} ${this.isValid() ? '' : textAreaHasError} ${name}`}>
        <textarea
          className={`${this.isValid() ? '' : textAreaHasError}`}
          name={name}
          value={value}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.onKeydown}
          ref={(node): void => {
            this.textArea = node
          }}
        />
        {!this.isValid() && (
          <div className={validationMessage}>{validationError.join(' and ')}</div>
        )}
      </div>
    )
  }
}

export default TextArea
