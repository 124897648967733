import { ProductPrice } from 'src/types/Product'
import { ProductSizeGroups } from 'src/types/ProductSizes'

export const isEmptyCurrency = (value: any) => {
  if (typeof value === 'number') {
    return isEmptyNumber(value)
  }

  // Check if it is an object but not null
  if (Object(value) === value) {
    return isEmptyCompleteCurrency(value)
  }

  return isEmptyValue(value)
}

export const neverEmpty = () => false

export const isEmptyString = (s: any): boolean => isEmptyValue(s) || s.trim() === ''

export const isEmptyImage = (value: any): boolean =>
  isEmptyValue(value) || value.length < 1 || isEmptyString(value[0].url)

export const isEmptyNumber = (value: any) => isEmptyValue(value) || value <= 0

export const isEmptyList = (l: any) => isEmptyValue(l) || (Array.isArray(l) && l.length === 0)

export const isEmptyValue = (v: any) => v === null || v === undefined || v === ''

// TODO workout the original type
const isEmptyCompleteCurrency = ({ value, currency }: any) => isEmptyNumber(value) || !currency

// -------- Text validation

const isLongEnough = (value: string, mandatory: boolean) => !mandatory || !isEmptyString(value)

const isOnlyAlphanumeric = (s: string): boolean => s.match(/[^a-zA-Z0-9\s]/g) === null

const isMaxCharsOrLess = (s: string, max: number): boolean => s.length <= max

const textValidationErrors = (value: string, mandatory: boolean, max: number): string[] => {
  const validationError = []

  if (!isLongEnough(value, mandatory)) {
    validationError.push('this field is mandatory')
  }

  if (!isMaxCharsOrLess(value, max)) {
    validationError.push('maximum length ' + max + ' characters')
  }

  if (!isOnlyAlphanumeric(value)) {
    validationError.push('only letters and numbers allowed')
  }

  return validationError
}

export const getTextValidationErrors = (value: string, mandatory: boolean): string[] => {
  return textValidationErrors(value, mandatory, 30)
}

export const getLongTextValidationErrors = (value: string, mandatory: boolean): string[] => {
  return textValidationErrors(value, mandatory, 60)
}

export const isEmptyPrice = (productPrice: ProductPrice = {}) =>
  !!(
    !Object.keys(productPrice).length ||
    Object.keys(productPrice).some((price) => {
      const sizeGroupPrice = price as ProductSizeGroups
      return !productPrice[sizeGroupPrice] || !parseFloat(productPrice[sizeGroupPrice] || '')
    })
  )
