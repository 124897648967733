import React from 'react'

import { GridType } from 'src/types/GridTypes'
import Link from '../../../Link'
import { Popover } from '../../../Popover'
import MenuItems from './MenuItems'

import { link, linksListItem, moveToButton, popoverContainer } from '../../GridControls.scss'
import { TOP_RIGHT } from '../../../../constants/popover'

interface Props {
  gridName: GridType
  isAdmin: boolean
  disabled: boolean
  isOptionLibrary: boolean
  isSelectedIdsEmpty: boolean
  openBulkStatusChangeModal: () => void
  openHierachyDrawer: () => void
  linkMatchingProducts: (grid: GridType) => void
  linkCrossDepartmentProducts: (grid: GridType) => void
  linkColourProducts: (grid: GridType) => void
  linkMultiSupplierProducts: (grid: GridType) => void
}

const moveToRTB = 'Move to Ready to Buy'
const moveToProposed = 'Move to Proposed'

export default function MultiSelectActions({
  isOptionLibrary,
  isSelectedIdsEmpty,
  isAdmin,
  openHierachyDrawer,
  openBulkStatusChangeModal,
  gridName,
  disabled,
  linkMatchingProducts,
  linkCrossDepartmentProducts,
  linkColourProducts,
  linkMultiSupplierProducts,
}: Props): JSX.Element {
  return (
    <>
      <li id="bulkUpdateStatusButton" className={linksListItem}>
        <Link
          className={`${link} ${moveToButton}`}
          onClick={openBulkStatusChangeModal}
          disabled={isSelectedIdsEmpty}
        >
          {isOptionLibrary ? moveToRTB : moveToProposed}
        </Link>
      </li>
      {isAdmin && (
        <li id="reclassButton" className={linksListItem}>
          <Link
            className={`${link} ${moveToButton}`}
            onClick={openHierachyDrawer}
            disabled={isSelectedIdsEmpty}
          >
            Reclass
          </Link>
        </li>
      )}
      {isOptionLibrary && (
        <li id="linkProductsButton" className={linksListItem}>
          <Popover
            triggerClassName={`${link} ${moveToButton}`}
            popoverContainerClass={popoverContainer}
            PopoverItem={
              <MenuItems
                gridName={gridName}
                disabled={disabled}
                linkMatchingProducts={linkMatchingProducts}
                linkCrossDepartmentProducts={linkCrossDepartmentProducts}
                linkColourProducts={linkColourProducts}
                linkMultiSupplierProducts={linkMultiSupplierProducts}
              />
            }
            position={TOP_RIGHT}
            disabled={isSelectedIdsEmpty}
          >
            Create Link
          </Popover>
        </li>
      )}
    </>
  )
}
