import { TreeHierarchyItem } from 'src/types/index'

export const visit = (tree: TreeHierarchyItem, f: (tree: TreeHierarchyItem) => void) => {
  if (tree) {
    f(tree)

    if (tree.children) {
      tree.children.forEach((node: any) => {
        visit(node, f)
      })
    }
  }
}
