import { FETCH_CONFIG, FETCH_CONFIG_FAILURE, FETCH_CONFIG_SUCCESS } from 'src/actions/config'
import { UNINITIALISED_STATUS } from '../constants/requests'
import { Config, RequestStatus } from 'src/types/index'
import { SVPAction } from 'actions/svp'

export type ConfigState = {
  status: RequestStatus
  payload: Config
}

const defaultConfig: ConfigState = {
  status: UNINITIALISED_STATUS,
  payload: {
    environment: '',
    sketchesApiUrl: '',
  },
}

export const config = (state: ConfigState = defaultConfig, action: SVPAction) => {
  switch (action.type) {
    case FETCH_CONFIG:
      return { ...state, status: 'fetching' }
    case FETCH_CONFIG_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    case FETCH_CONFIG_FAILURE:
      return { ...state, status: 'error', error: action.error }
    default:
      return state
  }
}
