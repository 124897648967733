import { EnrichedViewProduct } from 'types/Product'
import { sanitizeProduct } from '../../../service/product/create'
import { RangeDefaultAttribute } from 'types/UserSettings'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export type CreateProductAction = ReturnType<
  typeof createProduct | typeof getRangeDefaultAttributes | typeof getRangeDefaultAttributesSuccess
>
export const createProduct = (payload: EnrichedViewProduct) =>
  <const>{
    type: CREATE_PRODUCT,
    payload: sanitizeProduct(payload),
  }

export const GET_RANGE_DEFAULT_ATTRIBUTES = 'GET_RANGE_DEFAULT_ATTRIBUTES'
export const getRangeDefaultAttributes = (rangeSlug: string) =>
  <const>{
    type: GET_RANGE_DEFAULT_ATTRIBUTES,
    rangeSlug,
  }

export const GET_RANGE_DEFAULT_ATTRIBUTES_SUCCESS = 'GET_RANGE_DEFAULT_ATTRIBUTES_SUCCESS'
export const getRangeDefaultAttributesSuccess = (attributes: RangeDefaultAttribute[]) =>
  <const>{
    type: GET_RANGE_DEFAULT_ATTRIBUTES_SUCCESS,
    attributes,
  }
