import React from 'react'
import { Country } from 'src/types/index'
import {
  countryContainer,
  title,
  availableCountriesContainer,
  newCountriesContainer,
  availableCountriesList,
  countryTag,
  dropdownWrapper,
  addCountryButton,
} from './CountryOfOriginTool.scss'
import { EditTag } from 'src/components/EditTag'
import { CountryPayload } from 'src/actions/referenceData/countries'
import Dropdown from 'src/components/Dropdown'
import PrimaryButton from 'src/components/PrimaryButton/PrimaryButton'
import { TagElement } from '../../../../EditTag/EditTag'

type Props = {
  countries: Country[]
  updateCountry: (val: string, payload: CountryPayload) => void
}
type State = {
  selectedCountry?: string
}
export class CountryOfOriginTool extends React.Component<Props, State> {
  state = {
    selectedCountry: '',
  }

  createOption = (item: { code: string; name: string }) => ({
    value: item.code,
    label: `${item.name} - ${item.code}`,
    data: item,
  })

  onDeprecate = ({ identifier, deprecated }: TagElement) => {
    this.props.updateCountry(identifier as string, { deprecated: !deprecated })
  }

  onAdd = () => {
    const selectedCountry = this.getSelectedCountry()
    if (selectedCountry) {
      this.props.updateCountry(selectedCountry.code, { enabled: true })
      this.setState({ selectedCountry: '' })
    }
  }

  onCountrySelected = (event?: { name: string }) => {
    const { name } = event || {}
    this.setState({
      selectedCountry: name,
    })
  }

  getSelectedCountry = () =>
    this.props.countries.find((country) => country.name === this.state.selectedCountry)

  render() {
    return (
      <div className={countryContainer}>
        <div className={newCountriesContainer}>
          <h3 className={title}>Add a new country</h3>
          <div className={dropdownWrapper}>
            <Dropdown
              name="countries"
              required
              // @ts-ignore
              items={this.props.countries.filter((country) => !country.enabled)}
              // @ts-ignore
              createOption={this.createOption}
              isValid
              // @ts-ignore
              selectedItem={this.getSelectedCountry()}
              onItemSelected={this.onCountrySelected}
            />
            <PrimaryButton name="Add country" className={addCountryButton} onClick={this.onAdd}>
              Add
            </PrimaryButton>
          </div>
        </div>
        {this.props.countries.length > 0 ? (
          <div className={availableCountriesContainer}>
            <h3 className={title}>Available countries</h3>
            <div className={availableCountriesList}>
              {this.props.countries
                .filter((country) => country.enabled)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ code, name, deprecated }, index) => (
                  <EditTag
                    className={countryTag}
                    key={index}
                    tag={{
                      identifier: code,
                      value: `${code} - ${name}`,
                      deprecated,
                    }}
                    onClick={this.onDeprecate}
                  />
                ))}
            </div>
          </div>
        ) : (
          'No countries enabled'
        )}
      </div>
    )
  }
}
