import React from 'react'

interface Props {
  className: string
}

export const EditIcon = ({ className }: Props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.2 26.6" className={className}>
    <title>edit</title>
    <path
      d="M18.6,9.2a.6.6,0,0,1-.4-.1L12.8,4.9c-.1-.1-.2-.3-.3-.4a.75.75,0,0,1,.1-.5L15.2.7A.65.65,0,0,1,16,.5,7.24,7.24,0,0,1,21.8,5a.61.61,0,0,1-.1.6L19.2,9A1.42,1.42,0,0,1,18.6,9.2Z"
      fill="#1d1d1b"
    />
    <polygon points="0.7 26 6.6 24.1 1 19.7 0.7 26" fill="#fff" />
    <path
      d="M.7,26.5a.37.37,0,0,1-.3-.1A.76.76,0,0,1,.2,26l.3-6.3c0-.2.1-.4.3-.4s.4-.1.5.1l5.6,4.3a.76.76,0,0,1,.2.5c0,.2-.2.3-.3.4l-5.9,2C.8,26.5.8,26.5.7,26.5Zm.8-5.8-.2,4.6,4.3-1.4Z"
      fill="#1d1d1b"
    />
    <polygon points="17.7 9.7 12.3 5.5 1.2 19.7 6.6 23.9 17.7 9.7" fill="#41b97a" />
    <path
      d="M6.6,24.6a.6.6,0,0,1-.4-.1L.8,20.3c-.1-.1-.2-.3-.3-.4s0-.4.1-.5L11.8,5.1a.63.63,0,0,1,.9-.1l5.4,4.2c.1.1.2.3.3.4a.75.75,0,0,1-.1.5L7.1,24.3A.55.55,0,0,1,6.6,24.6Z"
      fill="#1d1d1b"
    />
    <polygon points="19.2 9 12.7 4 11.8 5.1 18.3 10.1 19.2 9" fill="#fff" />
    <circle cx="12.6" cy="24.6" r="1.7" fill="#1d1d1b" />
    <circle cx="20.1" cy="24.6" r="1.7" fill="#1d1d1b" />
    <circle cx="27.7" cy="24.6" r="1.7" fill="#1d1d1b" />
  </svg>
)
