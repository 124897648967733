import React from 'react'
import { DepartmentsContainer } from 'src/components/Admin/Departments/DepartmentsContainer'

type Props = {
  currentDepartmentSlug?: string
}

export const DepartmentsPage = ({ currentDepartmentSlug }: Props) => (
  <DepartmentsContainer currentDepartmentSlug={currentDepartmentSlug} />
)
