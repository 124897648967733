import * as EventErrors from 'src/constants/errorCodes'

import { WholesaleEventErrorCodes } from 'src/types/WholesaleEventError'

type errorFormatter = () => string

const itemMessageMap: Record<WholesaleEventErrorCodes, errorFormatter> = {
  [EventErrors.ERROR_CODE_UPLOAD_REQUEST_BODY]: () => {
    return 'Image upload failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_MIME_TYPE]: () => {
    return 'Image upload failed. Image type is invalid. Please upload .jpg or .png files.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_MIN_SIZE]: () => {
    return 'Image upload failed because some file is too small. Image must be a minimum of 500 x 500 pixels'
  },
  [EventErrors.ERROR_CODE_UPLOAD_MAX_SIZE]: () => {
    return 'Image upload failed because some file is too large. Image must not exceed a file size of 5 MB.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_PNG_TO_JPEG_CONVERT]: () => {
    return 'Image upload failed. Could not convert file to jpeg type.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_CREATE_THUMBNAIL]: () => {
    return 'Image upload failed. Could not create a thumbnail for the image.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_UPSERT]: () => {
    return 'Image upload failed due to a database connection issue. Please try again later.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_DB_CONSTRAINT]: () => {
    return 'It looks like something might have changed. Please check the updated images.'
  },
  [EventErrors.ERROR_CODE_UPLOAD_UPLOADING_TO_S3]: () => {
    return 'Image upload failed due to a problem saving the image. Please try again later.'
  },
  [EventErrors.ERROR_CODE_DELETE_REQUEST]: () => {
    return 'Image delete failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_DELETE_SVP]: () => {
    return 'Image delete failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_DELETE_LAST_IMAGE_OF_WS_READY_PRODUCT]: () => {
    return 'This image cannot be deleted, it can only be replaced. This product has already been sent on to our wholesale partners so at least one image must exist.'
  },
  [EventErrors.ERROR_CODE_DELETE_FROM_DB]: () => {
    return 'Image delete failed due to a database connection issue. Please try again later.'
  },
  [EventErrors.ERROR_CODE_REPLACE_REQUEST_PATH]: () => {
    return 'Image replace failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_REPLACE_REQUEST_SVP]: () => {
    return 'Image replace failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_REPLACE_REQUEST_BODY]: () => {
    return 'Image replace failed. Please try again later.'
  },
  [EventErrors.ERROR_CODE_REPLACE_MIME_TYPE]: () => {
    return 'Image replace failed. Image type is invalid. Please upload .jpg or .png files.'
  },
  [EventErrors.ERROR_CODE_REPLACE_MIN_SIZE]: () => {
    return 'Image replace failed because file XXX is too small. Image must be a minimum of 500 x 500 pixels'
  },
  [EventErrors.ERROR_CODE_REPLACE_MAX_SIZE]: () => {
    return 'Image replace failed because file XXX is too large. Image must not exceed a file size of 5 MB.'
  },
  [EventErrors.ERROR_CODE_REPLACE_PNG_TO_JPG_CONVERT]: () => {
    return 'Image replace failed. Could not convert file to .jpg type. Please contact service desk.'
  },
  [EventErrors.ERROR_CODE_REPLACE_CREATE_THUMBNAIL]: () => {
    return 'Image replace failed. Could not create a thumbnail for the image. Please contact service desk.'
  },
  [EventErrors.ERROR_CODE_REPLACE_REPLACING_IN_S3]: () => {
    return 'Image replace failed due to a problem saving the image. Please try again later.'
  },
}

export function getEventErrorMessage(error: WholesaleEventErrorCodes): string {
  return itemMessageMap[error]()
}
