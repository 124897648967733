import React from 'react'

interface Props {
  title?: string
  colour?: string
}

export const RIIcon = ({ title, colour }: Props) => (
  <svg data-cy="spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.06 24">
    <title>{title || 'River Island'}</title>
    <title>Monogram</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={colour || '#252525'}
          d="M16.82,24A9.83,9.83,0,0,1,11,22.62a6.2,6.2,0,0,1-2.56-5.36C8.46,13.11,11,10.72,11,6.94c0-2.49-1-4.58-5.32-4.58H3.54V24H0V0H6.24A9.83,9.83,0,0,1,12,1.38,6.2,6.2,0,0,1,14.6,6.74c0,4.08-2.53,6.71-2.53,10.31,0,2.53,1,4.58,5.32,4.58h2.12V0h3.54V24Z"
        />
      </g>
    </g>
  </svg>
)
