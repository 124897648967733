import { SortedColumn, UPDATE_SORTED_COLUMNS_SUCCESS } from 'src/actions/grid/sortedColumns'
import { CLEAR_AND_SELECT_HIERARCHY } from 'src/actions/grid/hierarchy'
import { SVPAction } from 'actions/svp'

export type SortedColumnState = SortedColumn[]
const defaultState: SortedColumnState = []

export const sortedColumns = (state: SortedColumnState = defaultState, action: SVPAction) => {
  switch (action.type) {
    case UPDATE_SORTED_COLUMNS_SUCCESS:
      return action.payload
    case CLEAR_AND_SELECT_HIERARCHY:
      return defaultState
  }
  return state
}
