import {
  CellType,
  ExportPayload,
  GridType,
  OPTION_LIBRARY,
  Product,
  RenderSpec,
} from 'src/types/index'
import { LookupHierarchyState } from 'src/reducers/referenceData/hierarchy/lookupTable'
import { strategyRegistryLookup } from 'src/registry/behaviourRegistry'
import {
  codesToPrefixes,
  extractProductNumber,
  extractProductNumberExt1,
  PriceAttributionWithCurrency,
  sizeGroupPriceExcelMapperExt1,
} from 'src/service/excel/exporter/valueMappers'
import { DEFAULT_GROUP } from 'src/constants/productSizes'
import {
  ACTIONS_CELL,
  CURRENT_PULL_PRICE_VIEW_CELL,
  LANDED_COST_CELL,
  PROMO_PRICE_VIEW_CELL,
  SELLING_PRICE_VIEW_CELL,
  SUPPLIER_COST_CELL,
  PRODUCT_NUMBER_CELL,
  STYLE_ID_CELL,
} from 'src/constants/attributes'
import {
  EXPORT_DEV_ID_COLUMN_NAME,
  EXPORT_EVENT_COLUMN_NAME,
  STYLE_ID_COLUMN_NAME,
} from 'src/constants/columns'
import { POUND_KEY } from 'src/constants/currency'

type excelFormat = string[][]

const getValue = (
  product: Product,
  property: string,
  type: CellType,
  hierarchyLookupTable: LookupHierarchyState,
): string =>
  strategyRegistryLookup(type).stateToExcelMapper(
    product[property],
    {
      product,
      hierarchyLookupTable,
    },
    property,
  )

export const mapToExcelFormat = (
  exportPayload: ExportPayload,
  hierarchyLookupTable: LookupHierarchyState,
  columnSpec: RenderSpec[],
  grid: GridType,
): excelFormat => {
  if (exportPayload.products.length === 0) {
    return []
  }

  const extProductNumbers = ['Prod No', 'Prod No Ext 1']
  const extPrices = [
    'Supplier Cost',
    'Supplier Cost Ext 1',
    'Landed Cost',
    'Landed Cost Ext 1',
    'Selling Price',
    'Selling Price Ext 1',
    'Current Full Price',
    'Current Full Price Ext 1',
    'Promo Price',
    'Promo Price Ext 1',
  ]

  const columnData: RenderSpec[] = exportPayload.columns
    .filter(
      (c) =>
        ![
          ACTIONS_CELL,
          PRODUCT_NUMBER_CELL,
          SUPPLIER_COST_CELL,
          LANDED_COST_CELL,
          SELLING_PRICE_VIEW_CELL,
          PROMO_PRICE_VIEW_CELL,
          CURRENT_PULL_PRICE_VIEW_CELL,
        ].includes(c),
    )
    .map((c) => columnSpec.find(({ property }) => c === property))
  const headers = columnData.map((c) => (c.type === STYLE_ID_CELL ? 'Style ID' : c.displayName))
  headers.push('Matching Products Link')
  headers.push('Cross Department Link')
  headers.push('Supplier Variant')
  headers.push('Colour Variant')
  headers.push('slug')

  //Column custom mapping
  headers.splice(getNextColumnPosition(headers, EXPORT_DEV_ID_COLUMN_NAME), 0, ...extProductNumbers)
  if (grid === OPTION_LIBRARY) {
    headers.splice(getNextColumnPosition(headers, EXPORT_EVENT_COLUMN_NAME), 0, ...extPrices)
  }
  headers.splice(getNextColumnPosition(headers, STYLE_ID_COLUMN_NAME), 0, 'Style Description')

  //Rows custom mapping according to new columns
  const devIdPosition = getNextColumnPosition(headers, EXPORT_DEV_ID_COLUMN_NAME)
  const eventPosition = getNextColumnPosition(headers, EXPORT_EVENT_COLUMN_NAME)
  const styleDescriptionPosition = getNextColumnPosition(headers, STYLE_ID_COLUMN_NAME)

  const products = exportPayload.products.map((product: Product) => {
    const mappedColumnData = columnData.map((cd) =>
      getValue(product, cd.property, cd.type, hierarchyLookupTable),
    )
    mappedColumnData.push(product.groups.matchingProductGroupSlug || '')
    mappedColumnData.push(product.groups.crossDepartmentGroupSlug || '')
    mappedColumnData.push(product.hasSupplierLink ? product.optionSlug : '')
    mappedColumnData.push(product.hasColourLink ? product.styleSlug : '')
    mappedColumnData.push(product.slug)

    const extensionProductNumbers: string[] = [
      product.productNumberView && product.productNumberView[DEFAULT_GROUP]
        ? extractProductNumber(product)
        : '',
      extractProductNumberExt1(product),
    ]

    const pricesExtOne: PriceAttributionWithCurrency = sizeGroupPriceExcelMapperExt1(product)

    const extensionPrices: string[] = [
      product.supplierCostView && product.supplierCostView[DEFAULT_GROUP]
        ? `${codesToPrefixes[product.supplierCostCurrency || POUND_KEY]}${
            product.supplierCostView[DEFAULT_GROUP]
          }`
        : '',
      pricesExtOne.supplierCost || '',
      product.landedCostView && product.landedCostView[DEFAULT_GROUP]
        ? `${codesToPrefixes[POUND_KEY]}${product.landedCostView[DEFAULT_GROUP]}`
        : '',
      pricesExtOne.landedCost || '',
      product.sellingPriceView && product.sellingPriceView[DEFAULT_GROUP]
        ? `${codesToPrefixes[POUND_KEY]}${product.sellingPriceView[DEFAULT_GROUP]}`
        : '',
      pricesExtOne.sellingPrice || '',
      product.currentFullPriceView && product.currentFullPriceView[DEFAULT_GROUP]
        ? `${codesToPrefixes[POUND_KEY]}${product.currentFullPriceView[DEFAULT_GROUP]}`
        : '',
      pricesExtOne.currentFullPrice || '',
      product.promoPriceView && product.promoPriceView[DEFAULT_GROUP]
        ? `${codesToPrefixes[POUND_KEY]}${product.promoPriceView[DEFAULT_GROUP]}`
        : '',
      pricesExtOne.promoPrice || '',
    ]

    // Insert new data according to header position.
    // Should be inserted in ascending column order, e.g if devId column is before style description
    // make the splice of devId before styleDescription
    mappedColumnData.splice(devIdPosition, 0, ...extensionProductNumbers)
    if (styleDescriptionPosition) {
      mappedColumnData.splice(styleDescriptionPosition, 0, product.styleDescription)
    }
    if (grid === OPTION_LIBRARY) {
      mappedColumnData.splice(eventPosition, 0, ...extensionPrices)
    }

    return mappedColumnData
  })

  return [headers, ...products]
}

const getNextColumnPosition = (data: string[], name: string): number =>
  data.findIndex((el) => el === name) + 1
