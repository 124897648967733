import React from 'react'
import classNames from 'classnames'

import { Checkbox } from '../../../Checkbox/Checkbox'
import { Product } from 'src/types/index'
import { UploadSpinner } from '../../../UploadSpinner/UploadSpinner'
import { spinnerContainer, hasError, actionCell } from './MultiSelectCell.scss'

type Props = {
  productIsCurrentlyPolling: boolean
  product: Product
  isSelected: boolean
  isLocked?: boolean
  hasProductError?: boolean
  updateMultiSelect: (slug: string, developmentId: string, isSelected: boolean) => void
}

export const MultiSelectCell = ({
  updateMultiSelect,
  productIsCurrentlyPolling,
  product,
  isSelected,
  isLocked,
  hasProductError,
}: Props) => {
  const activeClass = classNames(actionCell, {
    [hasError]: hasProductError,
  })

  return productIsCurrentlyPolling ? (
    <div className={spinnerContainer}>
      <UploadSpinner />
    </div>
  ) : (
    <div className={activeClass}>
      <Checkbox
        onClick={(checked) => updateMultiSelect(product.slug, product.developmentId, checked)}
        checked={isSelected}
        disabled={isLocked}
        withBorder
      />
    </div>
  )
}
