import { DepartmentSpecs, GridType } from 'src/types/index'

export const UPDATE_DEPARTMENT_SPECS = 'UPDATE_DEPARTMENT_SPECS'
export const FETCH_DEPARTMENT_SPECS = 'FETCH_DEPARTMENT_SPECS'
export const FETCH_DEPARTMENT_SPECS_FAILURE = 'FETCH_DEPARTMENT_SPECS_FAILURE'
export const REFRESH_DEPARTMENT_SPECS = 'REFRESH_DEPARTMENT_SPECS'

export type DepartmentSpecsActions = ReturnType<
  | typeof fetchDepartmentSpecsFailure
  | typeof updateDepartmentSpecs
  | typeof fetchDepartmentSpecs
  | typeof refreshDepartmentSpecs
>

export const updateDepartmentSpecs = (departmentSpecs: DepartmentSpecs, grid: GridType) =>
  <const>{
    type: UPDATE_DEPARTMENT_SPECS,
    departmentSpecs,
    grid,
  }

export const fetchDepartmentSpecs = (grid: GridType) =>
  <const>{
    type: FETCH_DEPARTMENT_SPECS,
    grid,
  }

export const fetchDepartmentSpecsFailure = (error: string, grid: GridType) =>
  <const>{
    type: FETCH_DEPARTMENT_SPECS_FAILURE,
    error,
    grid,
  }

export const refreshDepartmentSpecs = (selectedHierarchy: string, grid: GridType) =>
  <const>{
    type: REFRESH_DEPARTMENT_SPECS,
    grid,
    selectedHierarchy,
  }
