import { fetchReferenceData } from 'actions/referenceData/referenceData'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { AppState } from 'reducers/root'
import { referenceDataStatus } from 'selectors/referenceData/status'
import { UserSettingsPage } from './UserSettingsPage'
import { RequestStatus } from 'types'

interface StateProps {
  dependenciesStatus: RequestStatus
}

interface DispatchProps {
  fetchReferenceData: typeof fetchReferenceData
}

export interface UserSettingsPageProps extends StateProps, DispatchProps {}

const mapStateToProps = (state: AppState): StateProps => ({
  dependenciesStatus: referenceDataStatus(state),
})

const mapDispatchToProps: DispatchProps = {
  fetchReferenceData,
}
export const ConnectedUserSettingsPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserSettingsPage)

export const ConnectedUserSettingsContainerWithRouter = withRouter(
  ConnectedUserSettingsPageContainer,
)
