import React from 'react'
import { SimpleDropdown } from '../SimpleDropdown/SimpleDropdown'
import { DropdownCustomStyles, DropdownOption } from 'src/types/Dropdowns'
import {
  multiDropdownWrapper,
  multiDropdownHasError,
  validationMessage,
} from './MultiDropdown.scss'
import { alphabeticallySortString } from 'src/service/attributes/attributes'
import { IntentionalAny } from 'types'

type Props = DropdownCustomStyles & {
  name: string
  options: DropdownOption[]
  selectedOptions: DropdownOption[]
  mandatory: boolean
  onSave: (
    payload: IntentionalAny[],
    isValid: IntentionalAny,
    previousPayload: IntentionalAny,
  ) => void
  hasFocus?: boolean
  sort?: boolean
}

type State = {
  currentOptions: DropdownOption[]
}

export class MultiDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentOptions: props.selectedOptions,
    }
  }

  isValid = (): boolean =>
    (Array.isArray(this.state.currentOptions) && this.state.currentOptions.length > 0) ||
    !this.props.mandatory

  onChange = (newPayload: DropdownOption[]): void => {
    this.setState({
      currentOptions: this.props.sort
        ? newPayload.sort((a, b) => alphabeticallySortString(a.label, b.label))
        : newPayload,
    })
  }

  onBlur = (): void =>
    this.props.onSave(this.state.currentOptions, this.isValid(), this.props.selectedOptions)

  render(): JSX.Element {
    return (
      <div
        className={`${multiDropdownWrapper} ${this.isValid() ? '' : multiDropdownHasError} ${
          this.props.name
        }`}
      >
        {!this.isValid() && <div className={validationMessage}>This field is mandatory</div>}
        <SimpleDropdown
          name={this.props.name}
          options={this.props.options}
          multi
          onChangeMulti={this.onChange}
          hasFocus={this.props.hasFocus}
          selectedOptionMulti={this.state.currentOptions}
          size="small"
          onBlur={this.onBlur}
          customControlStyles={this.props.customControlStyles}
        />
      </div>
    )
  }
}
