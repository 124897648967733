import React from 'react'

import { container, label } from './SliderFormField.scss'

type Props = {
  name: string
  header: string
  onValueChange: (val: number) => void
  min: number
  max: number
  value: number
  disabled?: boolean
}

export const SliderFormField = ({ min, max, value, header, onValueChange, disabled }: Props) => (
  <div data-cy="slider-form-field" className={container}>
    <label className={label}>{header}</label>
    <input
      type="range"
      max={max}
      min={min}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(+event.currentTarget.value)
      }}
      disabled={disabled}
    />
  </div>
)
